// @ts-nocheck
import { Container, Typography } from "@mui/material";

import { withRouter } from "react-router-dom";
import React from "react";

const ZapierEmbed = (props: any) => {
  return (
    <Container>
      <Typography variant="h400" paragraph>
        Zapier
      </Typography>

      <div>
        <zapier-full-experience
          client-id="VclVFeLVayX3PZkTjwCF7BCYI8fqqP5B1VsZ0RYP"
          theme="light"
          intro-copy-display="hide"
          app-search-bar-display="show"
          app-limit={2}
          app-exclusions="storychief,buffer"
          app-categories="forms,-email-newsletters,cms"
          zap-limit={3}
          zap-style="card"
          zap-call-to-action-display="show"
          zap-create-from-scratch-display="hide"
        />
      </div>
    </Container>
  );
};

export default withRouter(ZapierEmbed);
