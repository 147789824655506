// @ts-nocheck
import { MenuItem, Pagination, Select, Typography } from "@mui/material";
import MuiPaginationItem from "@mui/material/PaginationItem";
import withStyles from "@mui/styles/withStyles";
import { designColors } from "../../Themes/Colors";
import { useMediaQuery } from "../utils/MediaQueryUtils";

const PaginationItem = withStyles({
  root: {
    borderRadius: "6px",
    "&.Mui-selected": {
      background: designColors.primary[50],
      color: designColors.white,
      "&:hover": {
        background: designColors.primary[50],
        color: designColors.white
      }
    },
    "&:hover": {
      background: designColors.primary[0],
      color: designColors.grayScale[100]
    }
  },
  sizeSmall: {
    fontSize: "12px",
    width: "32px",
    height: "32px",
    "&.MuiPaginationItem-ellipsis": {
      lineHeight: "1.7"
    }
  },
  sizeMedium: {
    fontSize: "14px",
    width: "40px",
    height: "40px",
    "&.MuiPaginationItem-ellipsis": {
      lineHeight: "2.4"
    }
  },
  sizeLarge: {
    fontSize: "16px",
    width: "48px",
    height: "48px",
    "&.MuiPaginationItem-ellipsis": {
      lineHeight: "2.7"
    }
  },
  previousNext: {
    display: "none"
  }
})(MuiPaginationItem);
function StyledPagination({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  size,
  ...others
}: any) {
  // push rowsPerPage into options if the value is not available
  if (rowsPerPageOptions.indexOf(rowsPerPage) === -1) {
    rowsPerPageOptions.push(rowsPerPage);
    rowsPerPageOptions.sort(function (a: any, b: any) {
      return a - b;
    });
  }

  const isLargeScreen = useMediaQuery("(min-width: 1150px)");

  if (count === 0 || rowsPerPage === 0) {
    return <></>;
  }

  let totalPages = Math.ceil(count / rowsPerPage);

  let fontSize = "12px";
  if (size === "medium") {
    fontSize = "14px";
  } else if (size === "large") {
    fontSize = "16px";
  }

  return (
    <div
      style={{
        display: isLargeScreen ? "flex" : "grid",

        margin: "10px auto",

        justifyContent: "center",

        position: "relative",

        alignItems: "center"
      }}
      {...others}
    >
      <div style={{ display: "inline-flex", justifyContent: "center" }}>
        <Typography
          size={size}
          style={{
            fontSize: fontSize,

            margin: "auto 10px",

            userSelect: "none",

            color: designColors.grayScale[60]
          }}
        ></Typography>
        <Pagination
          shape="rounded"
          size={size}
          count={totalPages}
          style={{ marginLeft: 10 }}
          onChange={(event) => {
            onPageChange(null, parseInt(event.target.innerText) - 1);
          }}
          page={page + 1}
          defaultPage={page + 1}
          renderItem={(item) => {
            return <PaginationItem {...item} style={{ marginRight: 5 }} />;
          }}
        />
      </div>

      <div
        style={{
          position: isLargeScreen ? "absolute" : "relative",

          right: "0px",

          margin: isLargeScreen ? "auto 0px" : "10px auto",

          display: "inline-flex",

          justifyContent: "center"
        }}
      >
        <Typography
          style={{
            fontSize: fontSize,

            margin: "auto 30px auto 0px",

            userSelect: "none",

            color: designColors.grayScale[60]
          }}
        ></Typography>
        <Select
          variant="standard"
          value={rowsPerPage}
          sx={{ border: 0, fontSize: fontSize }}
          onChange={onRowsPerPageChange}
        >
          {rowsPerPageOptions.map((rows: any) => {
            return <MenuItem value={rows}>{rows}</MenuItem>;
          })}
        </Select>
      </div>
    </div>
  );
}

StyledPagination.defaultProps = {
  size: "medium",
  rowsPerPageOptions: [10, 50, 100],
  rowsPerPage: 10
};

export default StyledPagination;
