// @ts-nocheck
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import styles from "./styles/PostBulkActionStyle";
import React from "react";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import { postBulkActionTypes, postStatus } from "../Utils/Types";
import Api from "../Services/Api";

import TagsInput from "./TagsInput";
import { notify } from "./CustomNotifications";
import StyledButton from "../design/components/StyledButton";

import _ from "lodash";

const api = Api.create();
class PostBulkAction extends React.Component {
  constructor(props: any) {
    super(props);

    this.state = {
      posts: _.cloneDeep(props.posts),
      saving: false,
      takenUrls: []
    };
  }

  //Saving
  handlePostBulkAction = () => {
    let { posts, saving } = this.state;

    if (saving) {
      return;
    }

    let { type, publicationId } = this.props;

    //Validation
    if (
      type === postBulkActionTypes.UPDATE_TITLE &&
      // check if any of the posts has empty title
      posts.some((post: any) => {
        //If post was published don't allow empty title
        if (post.status === postStatus.PUBLISHED && !post.scheduledOn) {
          return !post.title;
        }

        //Else allow
        return false;
      })
    ) {
      notify.show("Title your blog posts.", "error");
      return;
    }

    if (
      type === postBulkActionTypes.UPDATE_URL &&
      (this.doesPostHaveDuplicateUrl(posts).length ||
        posts.some((post: any) => {
          //Don't allow empty slug
          return !post.url?.url?.trim();
        }))
    ) {
      notify.show("Each blog post needs a unique URL.", "error");
      return;
    }

    //Trim values
    posts = posts.map((post: any) => {
      if (type === postBulkActionTypes.UPDATE_URL) {
        post.url.url = post.url.url.trim();
      }

      if (type === postBulkActionTypes.UPDATE_TITLE && post.title) {
        post.title = post.title.trim();
      }
      if (
        type === postBulkActionTypes.UPDATE_DESCRIPTION &&
        post.metaDescription
      ) {
        post.metaDescription = post.metaDescription.trim();
      }

      return post;
    });

    this.setState({ saving: true }, () => {
      api.postBulkAction(publicationId, posts, type, (res: any) => {
        if (res.status === 200) {
          if (!res.data.success) {
            this.setState({
              saving: false,
              takenUrls: res.data.takenUrls || []
            });
            if (!res.data.takenUrls?.length) {
              notify.show("Oops, something went wrong.", "error");
            }
            return;
          }

          this.props.onBulkActionSuccess();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Oops, something went wrong.",
            "error"
          );
          this.setState({
            saving: false
          });
        }
      });
    });
  };

  //Check for duplicate urls
  doesPostHaveDuplicateUrl = () => {
    let { posts } = this.state;
    let urls = posts.map((post: any) => post.url?.url?.trim());

    let duplicateUrl = urls.filter(
      (item: any, index: any) => urls.indexOf(item) !== index
    );

    return duplicateUrl;
  };

  //Check if url is duplicate or already taken
  validateUrl = (urlIndex: any) => {
    let { posts, takenUrls } = this.state;

    let { type } = this.props;
    let url = posts[urlIndex].url?.url?.trim() || "";

    if (!url) {
      return "";
    }

    if (type !== postBulkActionTypes.UPDATE_URL) {
      return "";
    }

    let duplicateUrl = this.doesPostHaveDuplicateUrl();

    if (duplicateUrl.includes(url)) {
      return "Your blog post required a unique url.";
    }

    if (takenUrls.includes(url)) {
      return "This URL slug is already taken. Try another one.";
    }

    return "";
  };

  getFieldTitle = () => {
    let { type } = this.props;

    if (type === postBulkActionTypes.UPDATE_TITLE) {
      return "Title";
    }

    if (type === postBulkActionTypes.UPDATE_TAGS) {
      return "Tags";
    }

    if (type === postBulkActionTypes.UPDATE_DESCRIPTION) {
      return "Description";
    }

    if (type === postBulkActionTypes.UPDATE_URL) {
      return "URL";
    }
    return "";
  };

  //Handle value change
  handleValueChange = (event: any, index: any) => {
    let { posts } = this.state;

    let { type } = this.props;
    let value = event.target.value;

    if (type === postBulkActionTypes.UPDATE_TITLE) {
      posts[index].title = value;
    }

    if (type === postBulkActionTypes.UPDATE_DESCRIPTION) {
      posts[index].metaDescription = value;
      posts[index].description = value;
    }

    if (type === postBulkActionTypes.UPDATE_URL) {
      value = value.toLowerCase();
      // Regex for anything not lowercase alphabets, numbers, hyphens
      let regexp = /[^a-z0-9-]/;
      value = value.replace(regexp, "").trim();

      let url = posts[index].url || {};
      url.url = value;
      posts[index].url = url;
    }

    this.setState({ posts });
  };

  //Using same input for title, url and description  - fetch value based on type
  getValue = (post: any) => {
    let { type } = this.props;
    if (type === postBulkActionTypes.UPDATE_TITLE) {
      return post.title || "";
    }

    if (type === postBulkActionTypes.UPDATE_URL) {
      return post.url?.url || "";
    }

    if (type === postBulkActionTypes.UPDATE_DESCRIPTION) {
      return post.metaDescription || "";
    }
    return "";
  };

  addTag = (event: any, value: any, index: any) => {
    let { posts } = this.state;
    let tags = posts[index].tags || [];
    let newValues = [...tags, value];
    this.handleTagsChange(event, newValues, index);
  };

  handleTagsChange = (event: any, newValues: any, index: any) => {
    let { posts } = this.state;
    let tags = posts[index].tags || [];
    if (tags.length < newValues.length) {
      // added a new chip
      if (tags.length === 3) {
        notify.show("You can use up to 3 tags", "warning");
        return;
      }
    }

    posts[index].tags = newValues;
    this.setState({ posts });
  };

  render() {
    let { classes, type, open, tagSuggestions } = this.props;

    let { posts, saving } = this.state;

    return (
      <StyledDialog
        open={open}
        title="Bulk action"
        maxWidth="md"
        fullWidth
        body={
          <div>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    {type !== postBulkActionTypes.UPDATE_TITLE && (
                      <TableCell
                        className={classes.tableCell}
                        style={{ width: "50%" }}
                      >
                        Title
                      </TableCell>
                    )}

                    <TableCell
                      className={classes.tableCell}
                      style={{ width: "50%" }}
                    >
                      {this.getFieldTitle()}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {posts.map((post: any, index: any) => {
                    return (
                      <TableRow>
                        {type !== postBulkActionTypes.UPDATE_TITLE && (
                          <TableCell className={classes.tableCell}>
                            <Typography variant="bodym">
                              {post.title || "Untitled"}
                            </Typography>
                          </TableCell>
                        )}

                        <TableCell className={classes.tableCell}>
                          {[
                            postBulkActionTypes.UPDATE_TITLE,
                            postBulkActionTypes.UPDATE_DESCRIPTION,
                            postBulkActionTypes.UPDATE_URL
                          ].includes(type) && (
                            <StyledInput
                              multiline
                              minRows={2}
                              maxRows={5}
                              value={this.getValue(post)}
                              helperText={this.validateUrl(index)}
                              error={this.validateUrl(index) ? true : false}
                              fullWidth
                              onChange={(event: any) => {
                                this.handleValueChange(event, index);
                              }}
                            />
                          )}

                          {type === postBulkActionTypes.UPDATE_TAGS && (
                            <TagsInput
                              value={post.tags || []}
                              suggestions={tagSuggestions || []}
                              placeholder="Add Tags"
                              addTag={(e: any, value: any) =>
                                this.addTag(e, value, index)
                              }
                              size="large"
                              handleChange={(e: any, value: any) =>
                                this.handleTagsChange(e, value, index)
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        }
        successButtonName="Done"
        additionalButtons={
          <>
            <StyledButton
              variant="secondary"
              size="medium"
              onClick={() => {
                if (saving) {
                  return;
                }

                this.props.onCloseDialog();
              }}
            >
              Cancel
            </StyledButton>

            <StyledButton
              variant="primary"
              size="medium"
              onClick={this.handlePostBulkAction}
            >
              {saving && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ marginRight: 10 }}
                />
              )}
              {saving ? "Saving" : "Save"}
            </StyledButton>
          </>
        }
      />
    );
  }
}

export default withStyles(styles)(PostBulkAction);
