// @ts-nocheck
import colors, { designColors } from "../../Themes/Colors";
import { shadow, space } from "../../Config/theme";

const styles = (theme: any) => ({
  container: {
    paddingLeft: "10%",
    paddingRight: "10%",
    color: colors.fontPrimary,
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10%",
      paddingRight: "10%"
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "20%",
      paddingRight: "20%"
    }
  },

  maxWidthContainer: {
    width: "100%",
    margin: "0px auto",
    maxWidth: 1280,
    padding: "0px 24px"
  },

  cropDialog: {
    padding: 200
  },

  cropContainer: {
    position: "relative",
    width: "100%",
    height: 200,
    marginTop: space.SMALL,
    [theme.breakpoints.up("sm")]: {
      height: 400,
      position: "relative",
      width: "100%"
    }
  },

  disabledSettings: {
    color: colors.disabled,
    margin: "auto 0px",
    marginRight: 15,
    cursor: "pointer",
    position: "relative",
    padding: "6px 0 7px;",
    [theme.breakpoints.down("md")]: {
      marginRight: 10
    },
    paddingBottom: 10,
    borderRadius: 0
  },

  sectionHeading: {
    margin: "auto 0px",
    marginRight: 15,
    cursor: "pointer",
    position: "relative",
    padding: "6px 0 7px;",
    [theme.breakpoints.down("md")]: {
      marginRight: 10
    },
    paddingBottom: 10,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: `${colors.white} !important`
    }
  },

  unselectedStatus: {
    "&::after": {
      background: ["none repeat scroll 0 0 transparent", "#000"],
      bottom: "0",
      content: '""',
      display: "block",
      height: "2px",
      left: "50%",
      position: "absolute",
      transition: "width 0.3s ease 0s, left 0.3s ease 0s",
      width: "0"
    },
    "&:hover::after": {
      borderBottom: "2px solid " + colors.selectHover,
      width: "100%",
      left: 0
    }
  },

  selectedStatus: {
    borderBottom: "2px solid " + colors.selectedItem
  },

  selectedButton: {
    boxShadow: "0 3px 8px 1px rgba(0,0,0,0.2)",
    transition: "0.4s ease"
  },

  maxWidth100: {
    padding: 0,
    maxWidth: "100%",
    paddingBottom: 100
  },

  padding24: {
    padding: "0px 24px"
  },

  padding35: {
    padding: 35
  },

  divider: {
    marginBottom: space.LARGE
  },

  headerContainer: {
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 1000,
    marginBottom: 40,
    boxShadow: "0 4px 5px -3px rgb(0 0 0 / 20%)",
    width: "100%"
  },

  reviewTitleContainer: {
    flexDirection: "column",
    display: "flex"
  },

  reviewTitle: {
    marginBottom: space.XS,
    fontSize: 24,
    fontWeight: 600,
    fontFamily: "Poppins"
  },

  buttonGroupContainer: {
    flexDirection: "row",
    marginBottom: 30
  },

  marginBottom15: {
    marginBottom: 15
  },

  fontSize14: {
    fontSize: 14
  },

  tabFont: {
    fontSize: 16
  },

  settingsTabContainer: {
    marginBottom: 30,
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    justifyContent: "left"
  },

  marginBottom20: {
    marginBottom: 20
  },

  postType: {
    fontSize: 16,
    marginBottom: space.SMALL,
    color: colors.fontSecondary
  },

  tabButton: {
    width: "100%",
    "border-radius": "0px !important",
    "border-color": `${designColors.grayScale[40]} !important`,
    minHeight: 40
  },

  blackText: {
    color: "black !important"
  },
  socialIcon: {
    marginRight: space.SMALL,
    width: "20px !important",
    height: "20px !important"
  },
  twitter: {
    color: colors.twitter
  },

  linkedin: {
    color: colors.linkedin
  },

  facebook: {
    color: colors.facebook
  },

  reddit: {
    color: colors.reddit
  }
});
export default styles;
