// @ts-nocheck
import { useState, useEffect } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import Campaign from "@mui/icons-material/Campaign";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { Avatar, Button, Grid, Tooltip, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";

import MenuSearch from "../design/components/MenuSearch";

import { ReactComponent as MessageIcon } from "../Images/icon24/message.svg";

import { ReactComponent as NotificationIcon } from "../Images/icon24/notification.svg";
import useLocalStorage from "../Utils/useLocalStorage";

import { useHistory } from "react-router-dom";
import BallotIcon from "@mui/icons-material/Ballot";
import firebase from "firebase/app";
import { setItemInLocalStorage } from "../Utils/StorageHelper";
import { PROFILE_PIC } from "../Utils/Types";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { grey } from "@mui/material/colors";

import { StyledMenuItem } from "../design/components/StyledSelect";
import colors from "../Themes/Colors";
import API from "../Services/Api";

import { useParams } from "react-router-dom";

import AppNotification from "./AppNotification";
import { CometChat } from "@cometchat-pro/chat";
import StyledPopper from "../design/components/StyledPopper";
import { TranscriptsDialog } from "./TranscriptsDialog";

const api = API.create();
const REFRESH_INTERVAL = 600000; //refresh notification count every 10 mins
const COMETCHAT_APP_ID = process.env.REACT_APP_COMETCHAT_APP_ID;
const COMETCHAT_AUTH_KEY = process.env.REACT_APP_COMETCHAT_AUTH_KEY;

const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion("us")
  .build();

const menuId = "primary-search-account-menu";
const subMenuId = "help-sub-menu";

export default function TopBar(props: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [profilePic, setProfilePic] = useLocalStorage("profile_pic", "");
  const [isTranscriptOpen, setIsTranscriptOpen] =
    React.useState<boolean>(false);
  const [transcriptDetails, setTranscriptDetails] = React.useState<{
    type: string;
    url: string;
  }>({});
  const history = useHistory();
  const params = useParams();
  const [publicationId, setPublicationId] = useState(
    params.id ? params.id : null
  );
  const [cometChatUnreadMessages, setCometChatUnreadMessages] = React.useState(
    []
  );
  const [count, setCount] = useState(0);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showAppNotification, setShowAppNotification] = useState(false);
  const [appNotificationElement, setAppNotificationElement] = useState(null);
  const [cometchatId, setCometchatId] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    // Canny initialization

    window.Canny("initChangelog", {
      appID: "63867bc6dcb44d5e4518d35d",
      position: "bottom",
      align: "right"
    });

    updateNotificationCount();
    const timer = setInterval(updateNotificationCount, REFRESH_INTERVAL);
    return () => clearInterval(timer);
  }, []);

  async function updateNotificationCount() {
    api.getAppNotificationCount(publicationId, async (res: any) => {
      if (res.status === 200) {
        setCount(res.data.count);
        await getUnreadMessages(
          res.data.cometchatId,
          res.data.teammates,
          res.data.count
        );
      }
    });
  }

  async function getUnreadMessages(
    cometchatId: any,
    teammates: any,
    notificationCount: any
  ) {
    let unreadCount = 0;

    let unreadChats = [];
    try {
      if (!cometchatId) {
        return;
      }

      if (!CometChat.isInitialized()) {
        CometChat.init(COMETCHAT_APP_ID, appSetting).then(
          () => {},
          (error) => {
            return;
          }
        );
      }
      try {
        await CometChat.login(cometchatId, COMETCHAT_AUTH_KEY);
      } catch (err) {
        return;
      }

      // get all publication client's cometchat ids
      let clients = new Set();
      teammates.map(async (teammate: any) => {
        if (teammate?.client?.cometchatId) {
          clients.add(teammate.client);
        }
      });

      // get unread messages from each teammate
      for (let client of clients) {
        let messages = await CometChat.getUnreadMessageCountForUser(
          client.cometchatId
        );

        if (messages[`${client.cometchatId}`] > 0) {
          unreadChats.push({
            count: messages[client.cometchatId],

            name: client.name
          });
          unreadCount++;
        }
      }

      setCometChatUnreadMessages(unreadChats);
    } catch (error) {}
    setCount(notificationCount + unreadCount);
  }

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.localStorage.clear();
        setItemInLocalStorage(PROFILE_PIC, "");
        history.push("/login");
      });
  };

  const gotoAccounts = () => {
    history.push("/account");
  };

  const resetGetStarted = () => {
    setShowSubMenu(false);
    api.resetGetStarted((res: any) => {
      if (res.status === 200) {
        //reload the page
        window.location.href = `/${publicationId}/overview`;
      }
    });
  };

  const showHelpSubMenu = (event: any) => {
    setSubmenuAnchorEl(event.currentTarget);
    setShowSubMenu(true);
  };

  const hideHelpSubMenu = () => {
    setShowSubMenu(false);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "10px"
        },
        "& .MuiMenu-list": { padding: "10px !important" },
        "& .MuiMenuItem-root.Mui-focusVisible": {
          backgroundColor: "transparent"
        },
        "& .MuiMenuItem-root": {
          "&:hover": {
            backgroundColor: colors.buttonHoverBG
          }
        }
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <StyledMenuItem onClick={gotoAccounts} selected={false}>
        <AccountCircle
          style={{
            color: grey[700],
            height: "18px",
            margin: "auto 0px",
            marginRight: "7px"
          }}
        />

        <Typography>My Account</Typography>
      </StyledMenuItem>

      <StyledMenuItem onClick={logout} selected={false}>
        <ExitToAppIcon
          style={{
            color: grey[700],
            height: "18px",
            margin: "auto 0px",
            marginRight: "7px"
          }}
        />

        <Typography>Logout</Typography>
      </StyledMenuItem>
    </Menu>
  );

  const renderHelpMenu = (
    <Menu
      anchorEl={submenuAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      id={subMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "10px"
        },
        "& .MuiMenu-list": { padding: "10px !important" },
        "& .MuiMenuItem-root.Mui-focusVisible": {
          backgroundColor: "transparent"
        },
        "& .MuiMenuItem-root": {
          "&:hover": {
            backgroundColor: colors.buttonHoverBG
          }
        }
      }}
      open={showSubMenu}
      onClose={hideHelpSubMenu}
    >
      <StyledMenuItem onClick={resetGetStarted} selected={false}>
        <Typography>See tutorials</Typography>
      </StyledMenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <StyledMenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MessageIcon />
          </Badge>
        </IconButton>

        <p>Messages</p>
      </StyledMenuItem>

      <StyledMenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationIcon />
          </Badge>
        </IconButton>

        <p>Notifications</p>
      </StyledMenuItem>

      <StyledMenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar src={profilePic} />
        </IconButton>

        <p>Profile</p>
      </StyledMenuItem>
    </Menu>
  );

  const closeAppNotification = () => {
    setAppNotificationElement(null);
    setShowAppNotification(false);
  };

  function openTranscript(type: string, url: string) {
    setTranscriptDetails({ type, url });
    setIsTranscriptOpen(true);
  }

  function closeTranscript() {
    setTranscriptDetails({});
    setIsTranscriptOpen(false);
  }

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#FCFCFC",
          boxShadow: "inset 1px 0px 0px #F4F4F4"
        }}
      >
        <Toolbar
          sx={{
            "&.MuiToolbar-root": {
              padding: "24px 40px"
            }
          }}
        >
          {/* <MenuSearch /> */}

          <Box sx={{ flexGrow: 1 }} />

          <Box style={{ display: "flex" }}>
            <Tooltip title={"Updates"}>
              <IconButton size="large" data-canny-changelog>
                <Campaign />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Ask for help"}>
              <IconButton size="large" onClick={showHelpSubMenu}>
                <HelpOutline />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={
                count > 0 ? "You have unread notifications" : "Notifications"
              }
            >
              <IconButton
                size="large"
                onClick={(event) => {
                  setAppNotificationElement(event.currentTarget);
                  setShowAppNotification(true);
                }}
              >
                <Badge badgeContent={count} color="error">
                  <NotificationIcon />
                </Badge>
              </IconButton>
            </Tooltip>

            <Avatar
              src={profilePic}
              onClick={handleProfileMenuOpen}
              sx={{ width: 48, height: 48, marginLeft: "20px" }}
            />
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderHelpMenu}
      {showAppNotification && (
        <AppNotification
          messages={cometChatUnreadMessages}
          showAppNotification={showAppNotification}
          setShowAppNotification={setShowAppNotification}
          publicationId={publicationId}
          appNotificationElement={appNotificationElement}
          closeAppNotification={closeAppNotification}
          setCount={setCount}
          updateNotificationCount={updateNotificationCount}
          openTranscript={openTranscript}
        />
      )}
      {isTranscriptOpen && (
        <TranscriptsDialog
          type={transcriptDetails.type}
          url={transcriptDetails.url}
          onClose={closeTranscript}
        />
      )}
    </Box>
  );
}
