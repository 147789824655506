// @ts-nocheck
import { FileCopy } from "@mui/icons-material";
import WestIcon from "@mui/icons-material/West";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Typography,
  Tooltip
} from "@mui/material";
import validator from "validator";
import { styled, withStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { space } from "../Config/theme";
import styles from "../Containers/styles/LinkedInShareStyle";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import StyledTab from "../design/components/StyledTab";
import API from "../Services/Api";
import colors, { designColors } from "../Themes/Colors";
import LinkedInTemplateDropdown from "./LinkedInTemplateDropdown";
import { notify } from "./CustomNotifications";
import StyledButton from "../design/components/StyledButton";
import { channelTypes, generateSocialPostStatus } from "../Utils/Types";

const api = API.create();

function PostFromURL({ samples, classes, onInsertPost, publicationId }) {
  const [sourceLink, setSourceLink] = useState("");
  const [body, setBody] = useState("");
  const [generatingTopics, setGeneratingTopics] = useState(false);
  const [generatingPost, setGeneratingPost] = useState(false);
  const apiCallStartTimeRef = useRef(null);
  const mountedRef = useRef(true);
  const [topics, setTopics] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [linkedinPost, setLinkedinPost] = useState(null);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  function onGenerateTopic(regenerate = false) {
    setGeneratingTopics(true);
    setTopics(null);
    if (!apiCallStartTimeRef.current) {
      apiCallStartTimeRef.current = Date.now();
    }

    api.generateSocialPostFromLink(
      publicationId,
      sourceLink,
      regenerate,
      generateFrom.URL,
      "",
      "",
      [],
      (res) => {
        if (!mountedRef.current) return;

        if (res.status === 200) {
          let { socialPost } = res.data;
          if (socialPost.status === generateSocialPostStatus.COMPLETED) {
            // Handle completed post
            setGeneratingTopics(false);
            setTopics(socialPost.generatedTopics || []);
            setBody(socialPost.body);
            apiCallStartTimeRef.current = null;
          } else {
            // Check if it's been less than 15 minutes since we started
            const elapsedTime = Date.now() - apiCallStartTimeRef.current;
            if (elapsedTime < 15 * 60 * 1000) {
              // Call onGenerate again after 5 seconds
              setTimeout(() => {
                if (mountedRef.current) {
                  onGenerateTopic();
                }
              }, 5000);
            } else {
              // Stop retrying after 15 minutes
              setGeneratingTopics(false);
              apiCallStartTimeRef.current = null;
            }
          }
        } else {
          setGeneratingTopics(false);
          setTopics(null);
          apiCallStartTimeRef.current = null;
        }
      }
    );
  }

  function onGeneratePost(topic) {
    setSelectedTopic(topic);
    setGeneratingPost(true);
    api.generateLinkedinPostFromTopic(publicationId, "", body, topic, (res) => {
      if (!mountedRef.current) return;

      if (res.status === 200) {
        let { linkedinPost } = res.data;
        setLinkedinPost(linkedinPost.linkedin_post || "");
        if (onInsertPost && linkedinPost.linkedin_post) {
          onInsertPost(linkedinPost.linkedin_post);
        }
      }
      setGeneratingPost(false);
    });
  }

  if (generatingPost) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={space.SMALL}
        height="200px"
      >
        <CircularProgress
          size={20}
          style={{ color: designColors.primary[50] }}
        />
        <Typography variant="bodym" paragraph style={{ margin: 0 }}>
          Generating post...
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="bodym" paragraph>
        Draft a post from a blog post or YouTube. Paste the link to them below.
      </Typography>

      <StyledInput
        fullWidth
        value={sourceLink}
        size="large"
        placeholder="https://acme.com/blog/linkedin-prospecting-tips"
        onChange={(event) => {
          setSourceLink(event.target.value);
        }}
      />
      <Box
        sx={{
          marginTop: space.MEDIUM,
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <StyledButton
          variant="primary"
          size="medium"
          disabled={
            generatingTopics ||
            !sourceLink?.trim() ||
            !validator.isURL(sourceLink?.trim())
          }
          onClick={() => onGenerateTopic(true)}
          style={{
            display: "flex",
            alignItems: "center",
            gap: space.SMALL
          }}
        >
          {generatingTopics && (
            <CircularProgress size={20} style={{ color: designColors.white }} />
          )}
          {generatingTopics ? "Generating ideas..." : "Generate ideas"}
        </StyledButton>
      </Box>
      {topics && (
        <>
          <Divider sx={{ my: space.MEDIUM }} />
          {topics.length > 0 ? (
            <Box>
              <Typography
                variant="bodym"
                style={{ marginBottom: space.MEDIUM }}
                component="p"
              >
                Select one of the ideas to generate a LinkedIn post
              </Typography>
              {topics.map((topic) => (
                <Box
                  className={`
                    ${classes.suggestion} 
                    ${
                      selectedTopic?._id !== topic._id &&
                      classes.suggestionHover
                    }
                  `}
                  sx={{
                    marginBottom: space.XXS,
                    background:
                      selectedTopic?._id === topic._id
                        ? designColors.grayScale[5]
                        : "inherit"
                  }}
                  key={topic.topic}
                  onClick={() => {
                    onGeneratePost(topic);
                  }}
                >
                  <Typography
                    variant="bodyl"
                    style={{
                      marginBottom: space.XS,
                      fontWeight: 600
                    }}
                  >
                    {topic.topic}
                  </Typography>
                  <Typography
                    variant="bodym"
                    style={{ marginBottom: space.XS }}
                  >
                    {topic.explanation}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Box sx={{ textAlign: "center" }}>No ideas found</Box>
          )}
        </>
      )}
    </Box>
  );
}

export const generateFrom = {
  SOURCE: "source",
  URL: "url"
};

export type Sample = {
  _id: string;
  publication?: string;
  label: string;
  sample: string;
  channel?: string;
};

type GeneratedPost = {
  _id: string;
  topic: string;
  content: string;
  linkedinPost: string;
  sample: string;
  suggestedSamples: string[];
};

type AIGeneratePostParams = {
  index: number;
  freeInputSource: string;
  sourceLink: string;
  showAIGeneratePostDialog: boolean;
  setTemplate: (template: string, value: string) => void;
  onAIPostGenerate: (index: number, value: string) => void;
  closeAIGeneratePostDialog: () => void;
  regenerateLinkedInPost: () => void;
  onGeneratedPostSampleChange: () => void;
  publicationId: string;
  saveSamples: (index: number, samples: Sample[]) => void;
  selectedSampleIds: string[];
  type: string;
  socialPost: {
    generatedPosts: GeneratedPost[];
    link: string;
  };
  classes: Record<keyof ReturnType<typeof styles>, string>;
  linkedinPostSuggestedTemplateIds?: string[];
  setLinkedinPostSuggestedTemplateIds?: (ids: string[]) => void;
};

function AIGeneratePost({
  index,
  freeInputSource,
  sourceLink,
  showAIGeneratePostDialog,
  generatingLinkedInPost,
  socialPost,
  updatePostContent,
  setTemplate,
  onAIPostGenerate,
  closeAIGeneratePostDialog,
  regenerateLinkedInPost,
  onGeneratedPostSampleChange,
  publicationId,
  saveSamples,
  selectedSampleIds,
  linkedInPost,
  type,
  classes,
  linkedinPostSuggestedTemplateIds = [],
  setLinkedinPostSuggestedTemplateIds = () => {}
}: AIGeneratePostParams) {
  let [currentTab, setCurrentTab] = useState(
    sourceLink ? generateFrom.URL : generateFrom.SOURCE
  );
  const getTabs = () => {
    let tabs = [
      { label: "Source", value: generateFrom.SOURCE },
      { label: "URL", value: generateFrom.URL }
    ];
    return tabs;
  };

  let [selectedSampleId, setSelectedSampleId] = useState("");
  let [selectedSample, setSelectedSample] = useState("");
  let [selectedSampleLabel, setSelectedSampleLabel] = useState("");
  let [samples, setSamples] = useState([]);
  let [loadingSuggestedTemplates, setLoadingSuggestedTemplates] =
    useState(false);

  function getFormatedSample(sample: string, length = 140) {
    return sample?.length < length ? sample : sample?.slice(0, length) + "...";
  }

  useEffect(() => {
    getAllSamples();
  }, []);

  useEffect(() => {
    let selected = samples.find((s) => s._id === selectedSampleId);
    setSelectedSampleLabel(selected?.label || "");
  }, [selectedSampleId, samples]);

  const onTemplateChange = (event: React.SyntheticEvent, samples: Sample[]) => {
    setSelectedSampleId(event.target.value);
    setSelectedSample(
      samples?.find(
        (sample: Sample) => sample?._id?.toString() === event.target.value
      )?.sample || ""
    );
    saveSamples(
      index,
      samples.filter(
        (sample: Sample) => sample?._id?.toString() === event.target.value
      )
    );
  };

  const setSampleObjFromId = (sampleId: string) => {
    const sampleObj = samples?.find(
      (sample: Sample) => sample?._id?.toString() === String(sampleId)
    );

    setSelectedSampleId(sampleId);
    setSelectedSample(sampleObj?.sample || "");
    saveSamples(index, sampleObj ? [sampleObj] : []);
  };

  function getSelectedSample(generatedPost: GeneratedPost) {
    if (!generatedPost?.newSample) {
      return "";
    }
    return (
      samples?.find((sample) => {
        return sample.sample === generatedPost.newSample;
      }) || ""
    );
  }

  function getAllSamples() {
    api.getSamples(publicationId, type, (res: AxiosResponse) => {
      if (res.status === 200) {
        let allSamples = res?.data || [];
        setSamples(allSamples);
        let selectedSampleObj = allSamples.filter((sample: Sample) =>
          selectedSampleIds?.some(
            (sampleId) => sampleId?.toString() === sample?._id?.toString()
          )
        )?.[0];

        setSelectedSample(selectedSampleObj?.sample || "");
        setSelectedSampleId(selectedSampleObj?._id?.toString() || "");
      }
    });
  }

  function suggestLinkedinTemplatesFromPost() {
    setLoadingSuggestedTemplates(true);
    api.suggestLinkedinTemplatesFromPost(
      {
        publicationId,
        postContent: freeInputSource
      },
      (res: AxiosResponse) => {
        setLoadingSuggestedTemplates(false);
        if (res.status === 200 && res.data?.success) {
          const suggestedSamples = res.data.suggestedTemplates || [];
          setLinkedinPostSuggestedTemplateIds(suggestedSamples);
          if (!selectedSampleId) {
            setSampleObjFromId(suggestedSamples[0] || "");
          }
        } else {
          setLinkedinPostSuggestedTemplateIds([]);
        }
      }
    );
  }

  function isSuccessButtonDisabled() {
    return (
      (currentTab === generateFrom.SOURCE && !freeInputSource) ||
      (currentTab === generateFrom.URL &&
        (!sourceLink ||
          (socialPost?.generatedPosts?.length &&
            socialPost?.link === sourceLink)))
    );
  }

  return (
    <StyledDialog
      open={showAIGeneratePostDialog}
      title={"Generate LinkedIn post"}
      body={
        <Grid>
          <Grid item xs={10}>
            <StyledTab
              value={currentTab}
              tabs={getTabs()}
              onChange={(event: React.SyntheticEvent, newValue: string) => {
                setCurrentTab(newValue);
              }}
              style={{ marginBottom: space.MEDIUM }}
              size="large"
              exposure="high"
            />
          </Grid>

          <Grid
            style={{
              display: currentTab === generateFrom.SOURCE ? "block" : "none"
            }}
          >
            <Typography className={classes.sectionHelper}>
              Draft a post by applying your template to this text
            </Typography>
            <Grid
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "stretch"
              }}
            >
              <StyledInput
                fullWidth
                value={freeInputSource}
                multiline={true}
                rows={5}
                size="large"
                onChange={(event: React.SyntheticEvent) =>
                  setTemplate("freeInputSource", event.target.value)
                }
              />
            </Grid>
          </Grid>

          <Grid
            style={{
              display: currentTab === generateFrom.URL ? "block" : "none"
            }}
          >
            <PostFromURL
              samples={samples}
              classes={classes}
              onInsertPost={(post) => {
                console.log("### onInsertPost", post);
                setCurrentTab(generateFrom.SOURCE);
                setTemplate("freeInputSource", post);
              }}
              publicationId={publicationId}
            />
          </Grid>

          {(type === channelTypes.TWITTER ||
            (type === channelTypes.LINKEDIN &&
              currentTab !== generateFrom.URL)) && (
            <>
              <Grid item>
                <Divider style={{ margin: `${space.MEDIUM} 0px` }} />
              </Grid>
              {linkedInPost ? (
                <Grid item>
                  <LinkedInTemplateDropdown
                    publicationId={publicationId}
                    description={"Use a template to format your post"}
                    linkedInAccountName={linkedInPost.accountName}
                    linkedInAccountPicture={linkedInPost.accountPicture}
                    initialSelectedSampleId={selectedSampleId || ""}
                    initialSelectedSample={selectedSample || ""}
                    onChange={onTemplateChange}
                    showSuggestTemplateButton={true}
                    disableSuggestTemplateButton={!freeInputSource}
                    loadingSuggestedTemplates={loadingSuggestedTemplates}
                    suggestLinkedinTemplatesFromPost={
                      suggestLinkedinTemplatesFromPost
                    }
                    suggestedSampleIds={linkedinPostSuggestedTemplateIds}
                  />
                </Grid>
              ) : (
                <Grid item>
                  <div style={{ margin: `${space.MEDIUM} 0px` }}>
                    <Typography variant="bodyl">Template</Typography>
                    <br />
                    <Typography className={classes.sectionHelper}>
                      Use a template to format your post
                    </Typography>
                  </div>
                  <div>
                    <FormControl
                      variant="outlined"
                      className={classes.accountDropDown}
                      fullWidth
                    >
                      <StyledSelect
                        name={"sample"}
                        value={selectedSampleId}
                        defaultValue={selectedSampleId}
                        renderValue={() => selectedSampleLabel}
                        onChange={(event) => onTemplateChange(event, samples)}
                        size="large"
                        placeholder="Choose a sample"
                        className={classes.input}
                        disabled={!Boolean(samples.length)}
                      >
                        {samples.map((sample: Sample, index) => {
                          return (
                            <StyledMenuItem
                              key={index}
                              value={sample?._id?.toString()}
                              style={{ width: "100%" }}
                            >
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: 650
                                }}
                              >
                                <Typography
                                  className={classes.itemName}
                                  variant="bodym"
                                  style={{
                                    margin: 0
                                  }}
                                >
                                  {sample?.label || sample?.sample}
                                </Typography>
                                <br />
                                <Typography
                                  variant="bodys"
                                  style={{
                                    color: designColors.grayScale[60],
                                    marginTop: space.XXS
                                  }}
                                >
                                  {getFormatedSample(sample?.sample, 140)}
                                </Typography>
                              </div>
                            </StyledMenuItem>
                          );
                        })}
                      </StyledSelect>
                    </FormControl>
                  </div>
                </Grid>
              )}
            </>
          )}
        </Grid>
      }
      closeCallback={() => closeAIGeneratePostDialog(index)}
      disableSuccessButton={isSuccessButtonDisabled()}
      showSuccessButtonLoading={generatingLinkedInPost}
      successButtonName={generatingLinkedInPost ? "Generating..." : "Generate"}
      successCallback={
        type === channelTypes.LINKEDIN && currentTab === generateFrom.URL
          ? null
          : () => {
              if (generatingLinkedInPost) {
                return "";
              }
              onAIPostGenerate(index, currentTab);
            }
      }
    />
  );
}

export default withStyles(styles)(AIGeneratePost);
