// @ts-nocheck
import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { customFieldDataType, datePickerDefault } from "../Utils/Types";
import {
  formatForDisplayDateTime,
  formatForDisplayDateTimeString
} from "../Utils/TimeUtils";
import { designColors } from "../Themes/Colors";
import {
  Avatar,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Typography
} from "@mui/material";

import StyledChip from "../design/components/StyledChip";
import { replaceAll } from "../Utils/UserUtils";
import { space } from "../Config/theme";
import styles from "./styles/TopicAssigneesAndDeadlinesStyle";

import StyledInput from "../design/components/StyledInput";

import StyledCheckbox from "../design/components/StyledCheckbox";
import { AvatarSize } from "../Config/theme";

import { ReactComponent as SearchIcon } from "../Images/icon20/search.svg";

import { ReactComponent as CalendarIcon } from "../Images/icon16/calendarColorPrimary.svg";

import CustomFlatPicker from "./CustomFlatPicker";
import moment from "moment";

import StyledSelectPopover from "../design/StyledSelectPopover";

import { ReactComponent as FaArrowUpRightFromSquareIcon } from "../Images/faArrowUpRightFromSquare.svg";

import styled from "styled-components";
const StyledSVG = styled.svg`
  cursor: pointer;
  & path {
    fill: ${(props: any) => props.color};
  }
  &:hover path {
    fill: ${(props: any) => props.hoverColor};
  }
`;

function CustomProjectFieldsView({
  customFields,
  onCustomFieldChange,
  isEditAllowed,
  classes
}: any) {
  const [toggleSingleSelectPicker, setToggleSingleSelectPicker] =
    useState(false);
  const [toggleDatePicker, setToggleDatePicker] = useState(false);
  const [isMultiSelector, setIsMultiSelector] = useState(false);
  const [eventElement, setEventElement] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [allowedValues, setAllowedValues] = useState([]);
  const [filteredAllowedValues, setFilteredAllowedValues] = useState([]);
  const [selectedCustomFieldIndex, setSelectedCustomFieldIndex] = useState(0);

  const [date, setDate] = useState(moment());

  const showSelectDialog = (
    eventElement: any,
    options: any,
    index: any,
    multiSelector = false,
    selectedValues = []
  ) => {
    setToggleDatePicker(false);
    setToggleSingleSelectPicker(true);
    setIsMultiSelector(multiSelector);
    setEventElement(eventElement);
    setAllowedValues(options);
    setFilteredAllowedValues(options);
    setSelectedCustomFieldIndex(index);
    setSelectedValues(selectedValues);
  };

  const showDatePicker = (eventElement: any, date: any, index: any) => {
    setToggleSingleSelectPicker(false);
    setToggleDatePicker(true);
    setEventElement(eventElement);
    setDate(date);
    setSelectedCustomFieldIndex(index);
  };

  const selectValueChange = (event: any, value: any) => {
    if (isMultiSelector) {
      let index = selectedValues.findIndex(
        (selectedValue) => selectedValue === value
      );

      if (index === -1) {
        selectedValues.push(value);
      } else {
        selectedValues.splice(index, 1);
      }
      setSelectedValues(selectedValues);
    } else {
      customFields[selectedCustomFieldIndex].value = value;
    }
    onCustomFieldChange(customFields);
  };

  const updateCustomFieldValue = (value: any, customFieldIndex: any) => {
    customFields[customFieldIndex].value = value;
    onCustomFieldChange(customFields);
  };

  const validateAndSave = (value: any, customFieldIndex: any, errDiv: any) => {
    if (value < customFields[customFieldIndex].minimumValue) {
      return;
    } else if (value > customFields[customFieldIndex].maximumValue) {
      return;
    } else {
      updateCustomFieldValue(value, customFieldIndex);
    }
  };

  const isCheckboxChecked = (value: any) => {
    let isChecked = selectedValues?.find(
      (selectedValue) => selectedValue === value
    );
    if (isChecked) {
      return true;
    }

    return false;
  };

  const getInputBasedOnCustomFieldType = (customField: any, index: any) => {
    let value = (
      <div
        typography="bodym"
        dangerouslySetInnerHTML={{
          __html: replaceAll(customField.value, "\n", "<br/>")
        }}
        className={classes.hover}
        contentEditable={isEditAllowed.toString()}
        onBlur={(event) =>
          updateCustomFieldValue(event.target.innerText, index)
        }
      />
    );
    if (customField.type === customFieldDataType.NUMBER) {
      value = (
        <div
          variant="bodym"
          className={classes.hover}
          contentEditable={isEditAllowed.toString()}
          onBlur={(event) => validateAndSave(event.target.innerText, index)}
        >
          {customField.value}
        </div>
      );
    } else if (customField.type === customFieldDataType.SINGLE_SELECT_LIST) {
      value = (
        <div
          style={{ display: "flex" }}
          className={classes.hover}
          onClick={(event) => {
            if (isEditAllowed) {
              showSelectDialog(
                event.currentTarget,
                customField.allowedValue,
                index
              );
            }
          }}
        >
          <Typography variant="bodym">{customField.value}</Typography>
        </div>
      );
    } else if (customField.type === customFieldDataType.MULTI_SELECT_LIST) {
      value = (
        <div
          style={{ display: "flex" }}
          className={classes.hover}
          onClick={(event) => {
            if (isEditAllowed) {
              showSelectDialog(
                event.currentTarget,
                customField.allowedValue,
                index,
                true,
                customField.value
              );
            }
          }}
        >
          <Typography variant="bodym">
            {customField.value.map((label: any, key: any) => (
              <StyledChip variant="gray" key={key} label={label} />
            ))}
          </Typography>
        </div>
      );
    } else if (customField.type === customFieldDataType.URL) {
      value = (
        <div className={classes.linkContainer}>
          <div
            variant="bodym"
            className={`${classes.hover} ${classes.linkInput}`}
            contentEditable={isEditAllowed.toString()}
            onBlur={(event) =>
              updateCustomFieldValue(event.target.innerText, index)
            }
          >
            {customField.value}
          </div>

          <StyledSVG
            color={designColors.grayScale[40]}
            hoverColor={designColors.primary[100]}
            width="16"
            height="16"
            viewBox="0 0 10 10"
            onClick={() => {
              window.open(customField.value, "_blank");
            }}
          >
            <FaArrowUpRightFromSquareIcon />
          </StyledSVG>
        </div>
      );
    } else if (customField.type === customFieldDataType.DATE) {
      value = (
        <div className={classes.hover}>
          <IconButton style={{ padding: 0, marginRight: 8 }}>
            <CalendarIcon alt="calendarIcon" />
          </IconButton>

          <Typography
            variant="bodys"
            onClick={(event: any) => {
              if (isEditAllowed) {
                showDatePicker(event.currentTarget, customField.value, index);
              }
            }}
          >
            {formatForDisplayDateTimeString(customField.value)}
          </Typography>
        </div>
      );
    } else if (customField.type === customFieldDataType.BOOLEAN) {
      value = (
        <StyledCheckbox
          size="small"
          checked={customField.value}
          disabled={!isEditAllowed}
          onChange={(event: any) =>
            updateCustomFieldValue(event.target.checked, index)
          }
          style={{ marginLeft: space.SMALL }}
        />
      );
    }
    return value;
  };

  return (
    <Grid
      container
      style={{
        paddingLeft: space.L,
        paddingRight: space.L,
        marginTop: space.L,
        paddingTop: space.L,
        borderTop: `1px solid ${designColors.grayScale[5]}`
      }}
    >
      {customFields.map((customField: any, index: any) => {
        return (
          <Grid container sm={12} style={{ paddingBottom: 10 }}>
            <Grid
              item
              sm={4}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <Typography
                variant="bodys"
                style={{
                  color: designColors.grayScale[60],
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {customField.name}
              </Typography>
            </Grid>

            <Grid item sm={8}>
              {getInputBasedOnCustomFieldType(customField, index)}
            </Grid>
          </Grid>
        );
      })}

      <StyledSelectPopover
        open={toggleSingleSelectPicker}
        anchorEl={eventElement}
        variant="bodym"
        onClose={() => {
          setToggleSingleSelectPicker(false);
          setEventElement(null);
        }}
        options={filteredAllowedValues}
        onClick={(event: any, value: any) => {
          selectValueChange(event, value);
        }}
        displayValue={(value: any) => value}
        onSearch={(event: any) => {
          let searchKeyword = event.target.value.toLowerCase();
          let filteredAllowedValues = allowedValues.filter((allowedValue) =>
            allowedValue?.toLowerCase().includes(searchKeyword)
          );
          setFilteredAllowedValues(filteredAllowedValues || []);
        }}
        showAvatar={false}
        isMultiSelector={isMultiSelector}
        isCheckboxChecked={isCheckboxChecked}
      />

      <CustomFlatPicker
        anchorEl={eventElement}
        open={toggleDatePicker}
        value={moment(date)}
        disablePast={true}
        onClose={(date) => {
          setToggleDatePicker(false);
          setEventElement(null);
          updateCustomFieldValue(date, selectedCustomFieldIndex);
        }}
        type={datePickerDefault.DEADLINE}
      />
    </Grid>
  );
}

export default withStyles(styles)(CustomProjectFieldsView);
