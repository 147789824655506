// @ts-nocheck
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  input: {
      width: "100%",
      backgroundColor: colors.inputBackground,
      border: "none",
      color: colors.fontInput,
      "&:focus": {
        backgroundColor: colors.inputFocusBackground,
        border: "none",
        color: colors.fontInput
      }
    }
});

export default styles;