// @ts-nocheck
import AddIcon from "@mui/icons-material/Add";
import ArchiveIcon from "@mui/icons-material/Archive";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { ReactComponent as Search } from "../Images/icon20/search.svg";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  Collapse,
  Container,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Popover,
  Popper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import { grey } from "@mui/material/colors";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import firebase from "firebase";
import qs from "query-string";
import React, { Suspense } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import { notify } from "../Components/CustomNotifications";

import Loading from "../Components/Loading";

import TopicComments from "../Components/TopicComments";

import TopicsTableView from "../Components/TopicsTableView";

import Vote from "../Components/Vote";

import SearchBar from "../design/components/SearchBar";
import StyledButton from "../design/components/StyledButton";

import StyledChip from "../design/components/StyledChip";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import StyledUserChip from "../design/components/StyledUserChip";
import StyledTab from "../design/components/StyledTab";
import Api from "../Services/Api";
import colors, { designColors } from "../Themes/Colors";
import images from "../Themes/Images";
import PageAccessContext from "../Utils/PageAccessContext";
import { uploadCSVFile } from "../Utils/Storage";
import {
  getItemFromLocalStorage,
  getItemFromSessionStorage,
  setItemInLocalStorage,
  setItemInSessionStorage
} from "../Utils/StorageHelper";
import {
  formatForDisplayDateTime,
  formatForDisplayDateTimeString
} from "../Utils/TimeUtils";
import {
  channelTypes,
  pageAccess,
  postStatus,
  PROFILE_PIC,
  TITLE_LENGTH,
  topicStatus
} from "../Utils/Types";
import {
  formatNumber,
  truncateStringAndAddEllipses,
  handleSingular,
  isAccessDisabled,
  multiSelect,
  redirectToLoginPage
} from "../Utils/UserUtils";
import ChangingProgressProvider from "./ChangingProgressProvider";

import Page404 from "./Page404";
import styles from "./styles/TopicBoardStyle";

import Topic from "./Topic";

import { ReactComponent as AssignIcon } from "../Images/assign.svg";

import { ReactComponent as MessageBubbleIcon } from "../Images/icon16/messagebubble.svg";

import { ReactComponent as FilterIcon } from "../Images/icon20/filter.svg";

import { ReactComponent as ListIcon } from "../Images/icon20/listbold.svg";

import { ReactComponent as TableIcon } from "../Images/icon20/table.svg";

import styled from "styled-components";
import { AvatarSize, space } from "../Config/theme";

import StyledCheckbox from "../design/components/StyledCheckbox";

import BulkAssignTopics from "../Components/BulkAssignTopics";
import "react-loading-skeleton/dist/skeleton.css";

import UpgradePlanDialog from "../design/components/UpgradePlanDialog";

import BulkCreateTopics from "../Components/BulkCreateTopics";
import moment from "moment";

import DemoVideoDialog from "../design/components/DemoVideoDialog";
import { uuid } from "uuidv4";

const StyledSVG = styled.svg`
  & path {
    fill: ${(props: any) => props.color};
  }
`;

const api = Api.create();
var allowedExtensions = /(\.csv)$/i;

const PAGE_LIMIT = { tableView: 50, agendaView: 20 };
const FILTERS = {
  CREATEDBY: "Created by",
  ASSIGNEDTO: "Assigned to",
  UPVOTEDBY: "Upvoted by",
  LABEL: "Label"
};

export const ANONYMOUS_CLIENT_OBJECT = "anonymousClientObject";

const FILTERVALUESCONTENTTYPE = {
  TEAMMATES: "teammates",
  TAGS: "tag"
};

const SORTBY = {
  POSITION: "Position",
  UPVOTES: "Upvotes",
  DATE: "Date",
  SEARCHVOLUME: "Search volume",
  COMPETITION: "Competition"
};
const Accordion = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    flexDirection: "row-reverse",
    display: "inline-flex",
    marginBottom: -1,
    alignItems: "center",
    padding: "0px",
    minHeight: 45,
    "&$expanded": {
      minHeight: 45
    },
    "& .MuiAccordionSummary-content": {
      display: "flex",
      alignItems: "center",
      margin: "auto"
    }
  },

  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expandIcon: {
    margin: "0 0 0 -12px"
  },
  expanded: {}
})(MuiAccordionSummary);

const MaskedAssignedStatuses = [
  topicStatus.FIRST_DRAFT,
  topicStatus.CONTENT_MAP_DRAFT,
  topicStatus.CONTENT_MAP_IN_REVIEW,
  topicStatus.IN_REVIEW
];

const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

const topicIdeasViewType = {
  TABLE_VIEW: "table_view",
  AGENDA_VIEW: "agenda_view"
};

export const ANONYMOUS = "Anonymous";

const PREFIX = "letterdrop_topicboard";
class Ideas extends React.Component {
  static contextType = PageAccessContext;
  fileUploadRef: any;
  topicRef: any;
  constructor(props: any) {
    super(props);
    let params = props.match.params;
    let location = props.location;
    let qsParse = qs.parse(location.search);

    this.state = {
      loading: true,
      fetchingTopics: true,
      error: false,
      topics: [],
      topic: null,
      publicationId: params.id ? params.id : "",
      publication: null,
      profilePic: getItemFromLocalStorage(PROFILE_PIC),
      showTopic: params.topicid ? true : false,
      status:
        qsParse.status && qsParse.status
          ? qsParse.status
          : topicStatus.PROPOSED,
      proposedCount: 0,
      assignedCount: 0,
      publishedCount: 0,
      archivedCount: 0,
      inReviewCount: 0,
      topicId: params.topicid,
      duplicateTopic: null,
      anchorEl: null,
      showMoreOptions: false,
      hasNextPage: false,
      page: 0,
      rowsPerPage: PAGE_LIMIT.agendaView,
      searchKeyword: "",
      replaceTopics: false,
      haveAccess: false,
      teammates: [],
      filterBy: "",
      filterByValue: [],
      sortBy: SORTBY.POSITION,
      customSortByField: "",
      customSortDirection: "",
      showFilterDiv: false,
      labelSuggestions: [],
      marketingCampaignSuggestion: [],
      filterOptions: [],
      filterValueContentType: FILTERVALUESCONTENTTYPE.TEAMMATES,
      file: null,
      topicIdeasView: topicIdeasViewType.AGENDA_VIEW,
      totalTopics: 0,
      showSearchBar: false,
      difficultySort: "",
      createdOnSort: "desc",
      thumbsUpCountSort: "",
      searchVolumeSort: "",
      domainAuthoritySort: "",
      searchIntentSort: "",
      funnelStageSort: "",
      conversionLikelihoodSort: "",
      topFormatsSort: "",
      topicTitle: "",
      topicIndex: null,
      showBulkAssignDialog: false,
      selectedTopics: [],
      topicSelectionIndex: null,
      haveAssignPermission: false,
      showArchiveDialog: false,
      showUnarchiveDialog: false,
      savingFiles: false,
      contentStagesConfig: [],
      customStages: [],
      ideaTemplates: [],
      defaultIdeaTemplate: {},
      selectedTemplate: {},
      prevSelectedTopicIndex: -1,
      currentSelectedTopicIndex: -1,
      showBulkCreate: false,
      minDate: moment(),
      topicDetails: {},
      showDeleteTopicConfirmation: false,
      expandMore: false,
      isIdeasPublic: false,
      eventElement: null,
      isUserLoggedIn: firebase.auth().currentUser ? true : false,
      showAnonymousUsernameDialog: false,
      anonymousUsername: "",
      anonymousEmail: "",
      anonymousClientObject: JSON.parse(
        getItemFromLocalStorage(ANONYMOUS_CLIENT_OBJECT)
      ),
      deleteTopicId: null
    };
    this.topicRef = React.createRef();
    this.fileUploadRef = React.createRef();
  }

  componentDidMount = () => {
    this.loadWithStorageKeys();
  };

  loadWithStorageKeys = () => {
    let { publicationId } = this.state;

    let key = `${PREFIX}_${publicationId}_${firebase.auth().currentUser?.uid}`;
    if (getItemFromLocalStorage(key)) {
      let value = getItemFromLocalStorage(key);
      try {
        let topicBoardDefaults = JSON.parse(value);
        this.setState(topicBoardDefaults, this.load);
      } catch (e) {
        this.load();
      }
      return;
    }

    this.load();
  };

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      let params = this.props.match.params;

      let location = this.props.location;
      let qsParse = qs.parse(location.search);

      this.setState(
        {
          loading: true,
          fetchingTopics: true,
          error: false,
          topics: [],
          topic: null,
          publicationId: params.id ? params.id : "",
          publication: null,
          profilePic: getItemFromLocalStorage(PROFILE_PIC),
          showTopic: params.topicid ? true : false,
          status:
            qsParse.status && qsParse.status
              ? qsParse.status
              : topicStatus.PROPOSED,
          proposedCount: 0,
          assignedCount: 0,
          publishedCount: 0,
          archivedCount: 0,
          inReviewCount: 0,
          topicId: params.topicid,
          anchorEl: null,
          showMoreOptions: false,
          hasNextPage: false,
          page: 0,
          rowsPerPage: PAGE_LIMIT.agendaView,
          searchKeyword: "",
          replaceTopics: false,
          haveAccess: false,
          teammates: [],
          filterBy: "",
          filterByValue: [],
          sortBy: SORTBY.POSITION,
          showFilterDiv: false,
          labelSuggestions: [],
          marketingCampaignSuggestion: [],
          filterOptions: [],
          filterValueContentType: FILTERVALUESCONTENTTYPE.TEAMMATES,
          file: null,
          topicIdeasView: topicIdeasViewType.AGENDA_VIEW,
          totalTopics: 0,
          showSearchBar: false,
          selectedTopics: [],
          topicSelectionIndex: null,
          haveAssignPermission: false,
          contentStagesConfig: [],
          expandMore: false,
          isIdeasPublic: false,
          eventElement: null,
          minDate: moment()
        },
        this.loadWithStorageKeys
      );
    }
  }

  toggleSearch = () => {
    this.setState(
      (prevState, props) => ({
        showSearchBar: !prevState.showSearchBar
      }),
      this.saveDataToSessionStorage
    );
  };

  load = () => {
    let {
      publicationId,

      status,

      page,

      rowsPerPage,

      searchKeyword,

      topics,

      replaceTopics,

      sortBy,

      filterBy,

      filterByValue,

      difficultySort,

      createdOnSort,

      thumbsUpCountSort,

      searchVolumeSort,

      domainAuthoritySort,

      searchIntentSort,

      funnelStageSort,

      conversionLikelihoodSort,

      topFormatsSort,

      customSortByField,

      customSortDirection,

      topicDetails
    } = this.state;

    filterByValue = filterByValue.map((value: any) => {
      return value.client ? value.client._id : value.name;
    });

    api.getTopics(
      publicationId,
      status,
      rowsPerPage,
      page * rowsPerPage,
      searchKeyword,
      sortBy,
      filterBy,
      filterByValue,
      replaceTopics,
      difficultySort,
      createdOnSort,
      thumbsUpCountSort,
      searchVolumeSort,
      domainAuthoritySort,
      searchIntentSort,
      funnelStageSort,
      conversionLikelihoodSort,
      topFormatsSort,
      customSortByField,
      customSortDirection,
      (res: any) => {
        if (res.status === 200 && status === res.data.status) {
          if (res.data?.loginRequired) {
            redirectToLoginPage();
            return;
          }
          if (res.data.upgradeRequired) {
            this.setState({
              publication: res.data.publication,
              haveAccess: false,
              loading: false
            });
            return;
          }
          if (res.data.replaceTopics) {
            topics = [];
          }
          this.setState(
            {
              publication: res.data.publication,
              topics: [...topics, ...res.data.topics.docs],
              totalTopics: res.data.topics.totalDocs,
              proposedCount: res.data.proposedCount,
              assignedCount: res.data.assignedCount,
              publishedCount: res.data.publishedCount,
              archivedCount: res.data.archivedCount,
              inReviewCount: res.data.inReviewCount,
              hasNextPage: res.data.topics.hasNextPage,
              minDate: res.data.minDate,
              page: page,
              replaceTopics: false,
              fetchingTopics: false,
              showMoreOptions: false,
              loading: false,
              haveAccess: true,
              teammates: res.data.teammates,
              labelSuggestions: res.data.labelSuggestions,
              marketingCampaignSuggestion:
                res.data.marketingCampaignSuggestion || [],
              haveAssignPermission: !isAccessDisabled(
                res.data.publication._id,
                this.context,
                pageAccess.ASSIGN_TOPIC
              ),
              contentStagesConfig: res.data.contentStagesConfig,
              isIdeasPublic: res.data.publication?.isIdeasPublic,
              customStages: res.data.customStages
            },
            () => {
              this.getIdeaTemplates();
              if (
                !this.state.topics.length &&
                (status === topicStatus.ARCHIVED ||
                  status === topicStatus.FIRST_DRAFT)
              ) {
                this.setStatus(topicStatus.PROPOSED);
              }
            }
          );
        } else {
          this.setState({
            loading: false,
            error: true
          });
        }
      }
    );

    api.getTopicsDetail(
      publicationId,
      status,
      rowsPerPage,
      page * rowsPerPage,
      searchKeyword,
      sortBy,
      filterBy,
      filterByValue,
      replaceTopics,
      difficultySort,
      createdOnSort,
      thumbsUpCountSort,
      searchVolumeSort,
      domainAuthoritySort,
      searchIntentSort,
      funnelStageSort,
      conversionLikelihoodSort,
      topFormatsSort,
      customSortByField,
      customSortDirection,
      (res: any) => {
        if (res.status === 200) {
          if (res.data?.loginRequired) {
            redirectToLoginPage();
            return;
          }
          this.setState({
            topicDetails: { ...topicDetails, ...res.data.topicDetails }
          });
        } else {
          this.setState({
            loading: false,
            error: true
          });
        }
      }
    );
  };

  saveDataToSessionStorage = () => {
    let {
      publicationId,

      status,

      topicIdeasView,

      sortBy,

      filterBy,

      filterByValue,

      filterOptions,

      filterValueContentType,

      searchKeyword,

      showFilterDiv,

      showSearchBar
    } = this.state;

    let key = `${PREFIX}_${publicationId}_${firebase.auth().currentUser?.uid}`;
    setItemInLocalStorage(
      key,
      JSON.stringify({
        status,
        topicIdeasView,
        sortBy,
        filterBy,
        filterOptions,
        filterValueContentType,
        filterByValue,
        searchKeyword,
        showFilterDiv,
        showSearchBar
      })
    );
  };

  toggleBulkCreate = (state: any) => {
    this.setState({ showBulkCreate: state });
  };

  toggleShowTopic = (topic: any, editTopicFlag: any, reload: any) => {
    let { showTopic, editTopic } = this.state;
    if (editTopic && !editTopicFlag && topic) {
      this.setState({
        editTopic: editTopicFlag
      });
      return;
    }
    this.setState({
      showTopic: !showTopic,
      topicId: topic?._id,
      topicTitle: topic?.title,
      editTopic: editTopicFlag,
      duplicateTopic: null,
      showMoreOptions: -1
    });
    if (reload) {
      this.refetchData();
    }
  };

  saving = () => {
    this.setState({ savingFiles: !this.state.savingFiles });
  };

  duplicateTopic = (topic: any) => {
    this.setState({
      showTopic: true,
      editTopic: true,
      duplicateTopic: topic,
      showMoreOptions: -1
    });
  };

  changeToEditMode = () => {
    this.setState({ editTopic: true });
  };

  assignTopic = (topicId: any, type: any) => {
    let { publicationId } = this.state;

    let url = `/${publicationId}/calendar?topicId=${topicId}&type=${type}&backpage=${
      [channelTypes.TWITTER, channelTypes.LINKEDIN].includes(type)
        ? "topics"
        : "projects"
    }`;

    this.props.history.push(url);
  };

  setStatus = (status: any) => {
    this.setState({ status: status, fetchingTopics: true }, () => {
      this.saveDataToSessionStorage();
      this.refetchData();
    });
  };

  archiveTopic = (event: any, topicId: any) => {
    api.archiveTopic(topicId, (res: any) => {
      if (res.status === 200) {
        this.closeMoreOptions();
        this.refetchData();
      }
    });
  };

  unarchiveTopic = (event: any, topicId: any) => {
    api.unarchiveTopic(topicId, (res: any) => {
      if (res.status === 200) {
        this.closeMoreOptions();
        this.refetchData();
      }
    });
  };

  deleteTopic = () => {
    api.deleteTopic(this.state.deleteTopicId, (res: any) => {
      if (res.status === 200) {
        this.setState({
          showDeleteTopicConfirmation: false,
          deleteTopicId: null
        });
        this.refetchData();
      } else {
        notify.show(
          "Unable to delete idea. Please contact support@letterdrop.com",
          "error"
        );
        this.setState({
          showDeleteTopicConfirmation: false,
          deleteTopicId: null
        });
      }
    });
  };

  toggleDeleteTopicConfirmation(topicId: any) {
    this.setState(
      {
        showDeleteTopicConfirmation: true,
        deleteTopicId: topicId
      },
      this.closeMoreOptions
    );
  }

  openMoreOptions = (event: any, index: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      showMoreOptions: index,
      anchorEl: event ? event.currentTarget : null
    });
  };

  closeMoreOptions = () => {
    this.setState({
      showMoreOptions: -1
    });
  };

  drawButtonsGrid = (index: any, topic: any) => {
    let { classes } = this.props;
    return (
      <Grid container direction="column" alignItems="flex-start">
        <div
          className={classes.menuItem}
          onClick={() => {
            this.toggleShowTopic(topic, true);
          }}
        >
          <EditIcon className={classes.menuIcon} />

          <Typography className={classes.menuText}>Edit </Typography>
        </div>

        <div
          className={classes.menuItem}
          onClick={() => {
            this.duplicateTopic(topic);
          }}
        >
          <ContentCopyIcon className={classes.menuIcon} />

          <Typography className={classes.menuText}>Duplicate </Typography>
        </div>

        {topic.status !== topicStatus.ARCHIVED &&
          topic.status !== topicStatus.PUBLISHED && (
            <div
              className={classes.menuItem}
              onClick={(event) => {
                this.archiveTopic(event, topic._id);
              }}
            >
              <ArchiveIcon className={classes.menuIcon} />

              <Typography className={classes.menuText}>Archive </Typography>
            </div>
          )}
        {topic.status === topicStatus.ARCHIVED && (
          <>
            <div
              className={classes.menuItem}
              onClick={(event) => {
                this.unarchiveTopic(event, topic._id);
              }}
            >
              <UnarchiveIcon className={classes.menuIcon} />

              <Typography className={classes.menuText}>Unarchive </Typography>
            </div>

            <div
              className={classes.menuItem}
              onClick={() => this.toggleDeleteTopicConfirmation(topic._id)}
            >
              <DeleteIcon className={classes.menuIcon} />

              <Typography className={classes.menuText}>Delete </Typography>
            </div>
          </>
        )}
      </Grid>
    );
  };

  loadMoreInScroll = () => {
    let { page } = this.state;
    this.setState(
      {
        replaceTopics: false,
        page: page + 1
      },
      this.load
    );
  };

  loadMore = () => {
    this.setState(
      {
        replaceTopics: true,
        page: 0,
        rowsPerPage: PAGE_LIMIT.agendaView,
        fetchingTopics: true
      },
      () => {
        this.saveDataToSessionStorage();
        this.load();
      }
    );
  };

  refetchData = () => {
    this.setState(
      {
        page: 0,
        replaceTopics: true,
        searchKeyword: "",
        fetchingTopics: true,
        showBulkAssignDialog: false,
        selectedTopics: [],
        showArchiveDialog: false,
        showUnarchiveDialog: false
      },
      this.load
    );
  };

  onCancelSearch = () => {
    this.setState(
      { searchKeyword: "", page: 0, replaceTopics: true, fetchingTopics: true },
      () => {
        this.saveDataToSessionStorage();
        this.load();
      }
    );
  };

  openPost = (postId: any, status: any) => {
    if (status === postStatus.DELETED) {
      notify.show("This post was deleted.", "error");
      return;
    }

    let { publicationId } = this.state;

    this.props.history.push(`/${publicationId}/posts/${postId}`);
  };

  openCalender = (eventId: any, createdOn: any, topicId: any) => {
    let { publicationId } = this.state;
    let url = `/${publicationId}/calendar?eventId=${eventId}&createdOn=${createdOn}&topicId=${topicId}&backpage=projects`;

    this.props.history.push(url);
  };

  callSaveTopic = () => {
    this.topicRef.saveTopicData(true);
  };

  formatNumber = (totalSubscribers: any) => {
    return totalSubscribers.toLocaleString();
  };

  getIdeaTemplates = () => {
    let { publicationId } = this.state;
    api.getIdeaTemplates(publicationId, (res: any) => {
      if (res.status === 200) {
        let { ideaTemplates } = res.data;

        let index = ideaTemplates.findIndex(
          (ideaTemplate: any) => ideaTemplate.isDefault
        );
        let defaultIdeaTemplate = ideaTemplates[index];

        this.setState({
          ideaTemplates,
          defaultIdeaTemplate
        });
      }
    });
  };

  handleCommentCountChange = (count: any, index: any) => {
    let { topics } = this.state;
    if (!topics?.[index]) {
      return;
    }
    topics[index].commentsCount = count || 0;
    this.setState({ topics });
  };

  generateKeywordAnalytics = (topic: any) => {
    let { classes } = this.props;

    if (
      !topic.keywordSearchResult.result ||
      (topic.keywordSearchResult.result.relatedKeywordsData &&
        topic.keywordSearchResult.result.relatedKeywordsData.length === 0)
    ) {
      return;
    }

    let difficulty = Math.round(
      topic.keywordSearchResult?.result?.keywordDifficulty / 10
    );
    return (
      <div className={classes.progressBar}>
        <a data-tip data-for={topic._id}>
          <ChangingProgressProvider values={[0, difficulty]}>
            {(percentage: any) => (
              <CircularProgressbar
                value={percentage}
                text={percentage}
                minValue={0}
                maxValue={10}
                strokeWidth={10}
                styles={buildStyles({
                  textColor: colors.circularBarColor,
                  textSize: "34px",
                  pathColor: colors.circularBarColor,
                  strokeLinecap: "butt",
                  pathTransitionDuration: 2,
                  trailColor: colors.white
                })}
              />
            )}
          </ChangingProgressProvider>
        </a>

        <ReactTooltip
          id={topic._id}
          place="bottom"
          type="light"
          effect="solid"
          border={true}
          borderColor={grey[100]}
          backgroundColor={colors.white}
          className={classes.tooltip}
          clickable={true}
        >
          <Grid container direction="column" className={classes.tootltipData}>
            {topic.keywordSearchResult?.result && (
              <>
                <Grid
                  container
                  item
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.lineItem}
                >
                  <Grid item className={classes.statMinWidth}>
                    <Typography variant="body2">
                      <strong>
                        {formatNumber(
                          topic.keywordSearchResult.result.searchVolume
                            ? topic.keywordSearchResult.result.searchVolume
                            : 0
                        )}
                      </strong>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="body2" className={classes.text}>
                      monthly searches
                    </Typography>
                  </Grid>
                </Grid>
                {topic.keywordSearchResult.result.keywordDifficulty && (
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.lineItem}
                  >
                    <Grid item className={classes.statMinWidth}>
                      <Typography variant="body2">
                        <strong>
                          {formatNumber(
                            topic.keywordSearchResult.result.keywordDifficulty
                          )}
                        </strong>
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="body2" className={classes.text}>
                        SEO difficulty
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                <Grid
                  container
                  item
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.lineItem}
                >
                  <Grid item className={classes.statMinWidth}>
                    <Typography variant="body2">
                      <strong>
                        {Math.round(
                          topic.keywordSearchResult.result.competition * 100
                        )}
                      </strong>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="body2" className={classes.text}>
                      paid difficulty
                    </Typography>
                  </Grid>
                </Grid>
                {topic.keywordSearchResult?.result?.cpc > 0 && (
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.lineItem}
                  >
                    <Grid item className={classes.statMinWidth}>
                      <Typography variant="body2">
                        <strong>
                          ${topic.keywordSearchResult.result.cpc.toFixed(2)}
                        </strong>
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="body2" className={classes.text}>
                        to buy a click
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                <Grid item>
                  <hr color={colors.horizontalRule}></hr>
                </Grid>
              </>
            )}
            {this.drawRelatedKeywordSection(
              topic.keywordSearchResult.result.relatedKeywordsData
            )}
            {this.drawOrganicPostsSection(
              topic.keywordSearchResult.result.organicPosts
            )}
          </Grid>
        </ReactTooltip>
      </div>
    );
  };

  drawRelatedKeywordSection = (relatedKeyword: any) => {
    if (!relatedKeyword || relatedKeyword.length === 0) {
      return;
    }

    let { classes } = this.props;
    relatedKeyword =
      relatedKeyword.length > 2 ? relatedKeyword.slice(0, 3) : relatedKeyword;
    return (
      <div>
        <Grid item>
          <Typography variant="body1" className={classes.pageTitle}>
            Suggested keywords
          </Typography>
        </Grid>

        {relatedKeyword.map((data: any, index: any) => {
          return (
            <Grid
              container
              item
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className={classes.topSection}
            >
              <Grid item>
                <Typography variant="body2">{data.keyword}</Typography>
              </Grid>

              <Grid item className={classes.marginLeft10}>
                <Typography variant="body2">
                  <strong>
                    {formatNumber(data.searchVolume ? data.searchVolume : 0)}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </div>
    );
  };

  drawOrganicPostsSection = (organicPosts: any) => {
    if (!organicPosts || organicPosts.length === 0) {
      return;
    }

    let { classes } = this.props;
    organicPosts =
      organicPosts.length > 2 ? organicPosts.slice(0, 3) : organicPosts;
    return (
      <div style={{ maxWidth: 300 }}>
        <Grid item className={classes.topSection}>
          <hr color={colors.horizontalRule}></hr>
        </Grid>

        <Grid item>
          <Typography variant="body1" className={classes.pageTitle}>
            Top performing posts for this search
          </Typography>
        </Grid>

        {organicPosts.map((data: any, index: any) => {
          return (
            <Grid item className={classes.topSection}>
              <a href={data.url} target="_blank" className={classes.link}>
                <Typography variant="body2">
                  {data.title ? data.title : data.url}
                </Typography>
              </a>
            </Grid>
          );
        })}
      </div>
    );
  };

  openContentMap = (topic: any) => {
    let { publicationId } = this.state;
    window.open(
      `/${publicationId}/topics/${topic.contentMap}/contentmap`,
      "_blank"
    );
  };

  handleFilterFieldChange = (event: any) => {
    let { teammates, labelSuggestions } = this.state;

    if (event.target.value === FILTERS.LABEL) {
      this.setState(
        {
          filterOptions: labelSuggestions,
          filterBy: event.target.value,
          filterByValue: [],
          filterValueContentType: FILTERVALUESCONTENTTYPE.TAGS
        },
        this.saveDataToSessionStorage
      );
    } else {
      this.setState(
        {
          filterOptions: teammates,
          filterBy: event.target.value,
          filterByValue: [],
          filterValueContentType: FILTERVALUESCONTENTTYPE.TEAMMATES
        },
        this.saveDataToSessionStorage
      );
    }
  };

  handleFilterByChipChange = (event: any, value: any) => {
    this.setState(
      {
        filterByValue: value
      },
      () => {
        this.saveDataToSessionStorage();
        this.refetchData();
      }
    );
  };

  handleSortByChange = (event: any) => {
    this.setState({ sortBy: event.target.value }, () => {
      this.saveDataToSessionStorage();
      this.refetchData();
    });
  };

  toggleMoreOptions = (event = null) => {
    let { expandMore } = this.state;
    this.setState({
      expandMore: !expandMore,

      eventElement: event ? event.currentTarget : null
    });
  };

  togglePublicAccess = () => {
    let { publicationId, isIdeasPublic, updatingIdeasAccessConfig } =
      this.state;

    if (updatingIdeasAccessConfig) {
      return;
    }

    this.setState(
      {
        updatingIdeasAccessConfig: true
      },
      () => {
        api.updateIdeasAccessConfig(
          publicationId,
          !isIdeasPublic,
          (res: any) => {
            if (res.status === 200) {
              this.setState(
                {
                  isIdeasPublic: !isIdeasPublic,
                  updatingIdeasAccessConfig: false
                },
                this.load
              );
            } else {
              this.setState({
                updatingIdeasAccessConfig: false
              });
              notify.show("Oops, something went wrong.", "error");
            }
          }
        );
      }
    );
  };

  toggleFilterSortDiv = (event: any) => {
    let { showFilterDiv } = this.state;
    this.setState(
      {
        showFilterDiv: !showFilterDiv
      },
      this.saveDataToSessionStorage
    );
  };

  toggleTopicIdeaUploadConfirmation = () => {
    let { showTopicIdeaUploadConfirmation } = this.state;
    this.setState({
      showTopicIdeaUploadConfirmation: !showTopicIdeaUploadConfirmation
    });
  };

  openTopicIdeaFileUpload = () => {
    if (this.state.uploading) {
      notify.show("Already a file is uploading", "error");
      return;
    }
    this.toggleTopicIdeaUploadConfirmation();
    this.fileUploadRef.current.click();
  };

  calculateFileSize = (file: any) => {
    return file.size / (1024 * 1024);
  };

  onFileChange = (event: any) => {
    let file = event.target.files[0];

    if (!allowedExtensions.exec(file.name)) {
      notify.show("Invalid file type", "error");
      return;
    }

    if (this.calculateFileSize(file) > 5) {
      notify.show("Upload file size limit is 5 mb", "error");
      return;
    }

    this.setState(
      {
        uploading: true,
        file: file
      },
      () => {
        this.uploadIdeas();
        event.target.value = "";
      }
    );
  };

  uploadIdeas = async () => {
    let { publicationId, file } = this.state;

    let response = await uploadCSVFile(file, "ideas");

    let url = response.url;

    let fileName = response.fileName;

    api.importTopicIdeas(file, url, fileName, publicationId, (res: any) => {
      if (res.status === 200) {
        notify.show(res.data.message, res.data.success ? "success" : "error");
        this.setState({ uploading: false });
      } else {
        notify.show("Oops, something went wrong.", "error");
        this.setState({ uploading: false });
      }
    });
  };

  toggleTopicIdeasView = (view: any) => {
    if (view === this.state.topicIdeasView) {
      return;
    }
    if (view === topicIdeasViewType.TABLE_VIEW) {
      this.setState(
        {
          topicIdeasView: view,
          replaceTopics: true,
          page: 0,
          rowsPerPage: PAGE_LIMIT.tableView,
          fetchingTopics: true
        },
        () => {
          this.saveDataToSessionStorage();
          this.load();
        }
      );
      return;
    }
    this.setState(
      {
        topicIdeasView: view,
        replaceTopics: true,
        page: 0,
        rowsPerPage: PAGE_LIMIT.agendaView,
        fetchingTopics: true
      },
      () => {
        this.saveDataToSessionStorage();
        this.load();
      }
    );
  };

  handleChangePage = (event: any, newPage: any) => {
    this.setState(
      { page: newPage, replaceTopics: true, fetchingTopics: true },
      this.load
    );
  };

  reloadWithSort = (sortByField: any, direction: any) => {
    let empty = {
      difficultySort: "",
      createdOnSort: "",
      thumbsUpCountSort: "",
      searchVolumeSort: "",
      domainAuthoritySort: "",
      searchIntentSort: "",
      funnelStageSort: "",
      conversionLikelihoodSort: "",
      topFormatsSort: "",
      sortBy: "",
      customSortByField: "",
      customSortDirection: ""
    };

    let response = { ...empty, [sortByField]: direction };
    let newState = { ...this.state, ...response };
    this.setState(newState, () => this.refetchData());
  };

  customFieldSort = (customSortByField: any, customSortDirection: any) => {
    this.setState(
      {
        difficultySort: "",
        createdOnSort: "",
        thumbsUpCountSort: "",
        searchVolumeSort: "",
        domainAuthoritySort: "",
        searchIntentSort: "",
        funnelStageSort: "",
        conversionLikelihoodSort: "",
        topFormatsSort: "",
        sortBy: "",
        customSortByField,
        customSortDirection
      },
      () => this.refetchData()
    );
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState(
      {
        page: 0,
        rowsPerPage: event.target.value,
        replaceTopics: true,
        fetchingTopics: true
      },
      this.load
    );
  };

  handleSearchChange = (value: any) => {
    this.setState({ searchKeyword: value });
  };

  CopyToClipboard = () => {
    let { publicationId, topicId } = this.state;
    let text = `${FRONTEND_URL}/${publicationId}/topics/${topicId}`;
    navigator.clipboard.writeText(text);
    notify.show("Copied to clipboard", "success");
    this.toggleShowTopic(null, false, false);
  };

  getTabs = () => {
    let { proposedCount, assignedCount, publishedCount, archivedCount } =
      this.state;

    let tabs = [
      {
        label: "Proposed",
        count: formatNumber(proposedCount),
        value: topicStatus.PROPOSED
      }
    ];

    if (assignedCount > 0) {
      tabs.push({
        label: "Assigned",
        count: formatNumber(assignedCount),
        value: topicStatus.FIRST_DRAFT
      });
    }

    if (publishedCount > 0) {
      tabs.push({
        label: "Published",
        count: formatNumber(publishedCount),
        value: topicStatus.PUBLISHED
      });
    }
    if (archivedCount > 0) {
      tabs.push({
        label: "Archived",
        count: formatNumber(archivedCount),
        value: topicStatus.ARCHIVED
      });
    }
    return tabs;
  };

  handleCommentColorChange = (topicIndex: any) => {
    this.setState({
      topicIndex
    });
  };

  askAnonymousUsername = () => {
    let { anonymousClientObject, isUserLoggedIn } = this.state;
    if (isUserLoggedIn || anonymousClientObject) {
      return;
    }

    this.setState({ showAnonymousUsernameDialog: true });
  };

  onAnonymousUsernameChange = (event: any) => {
    this.setState({ anonymousUsername: event.target.value });
  };
  onAnonymousEmailChange = (event: any) => {
    this.setState({ anonymousEmail: event.target.value });
  };

  saveAnonoymousUsername = () => {
    let { anonymousUsername, anonymousEmail } = this.state;

    //create random id
    let anonymousClientObject = {
      name: anonymousUsername,
      email: anonymousEmail,
      id: uuid()
    };
    setItemInLocalStorage(
      ANONYMOUS_CLIENT_OBJECT,
      JSON.stringify(anonymousClientObject)
    );
    this.setState({
      anonymousClientObject,
      showAnonymousUsernameDialog: false
    });
  };

  createDefaultAnonoymousUsername = () => {
    //create random id
    let anonymousClientObject = {
      name: ANONYMOUS,
      email: "",
      id: uuid()
    };
    setItemInLocalStorage(
      ANONYMOUS_CLIENT_OBJECT,
      JSON.stringify(anonymousClientObject)
    );
    this.setState({
      anonymousClientObject,
      showAnonymousUsernameDialog: false
    });
  };

  filterContainer = () => {
    let { classes } = this.props;
    let {
      fetchingTopics,

      filterBy,

      filterByValue,

      filterOptions,

      filterValueContentType
    } = this.state;

    return (
      <Grid
        item
        container
        direction="row"
        name="filterSortDiv"
        className={classes.filterContainer}
        style={{
          pointerEvents: fetchingTopics ? "none" : "auto"
        }}
      >
        <Typography variant="body1" className={classes.filterLabel}>
          FILTERS
        </Typography>

        <Grid container direction="row">
          <Grid item md={4} className={classes.arrangeItems}>
            <FormControl fullWidth>
              <StyledSelect
                fullWidth
                onChange={this.handleFilterFieldChange}
                value={filterBy}
                size="large"
              >
                {Object.keys(FILTERS).map((key, index) => (
                  <StyledMenuItem value={FILTERS[key]} key={index}>
                    {FILTERS[key]}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Grid>

          <Grid item md={8}>
            {filterValueContentType === FILTERVALUESCONTENTTYPE.TEAMMATES ? (
              <Autocomplete
                multiple
                limitTags={3}
                fullWidth
                value={filterByValue}
                onChange={(event, newValue) =>
                  this.handleFilterByChipChange(event, newValue)
                }
                options={filterOptions}
                getOptionDisabled={(option) => {
                  let index = filterByValue.findIndex((item: any) => {
                    return option._id === item._id;
                  });
                  if (index === -1) {
                    return false;
                  } else {
                    return true;
                  }
                }}
                getOptionLabel={(option) =>
                  option.client && option.client.name
                    ? option.client.name
                    : option.email
                }
                renderTags={
                  filterByValue
                    ? (tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <StyledUserChip
                            label={
                              option.client && option.client.name
                                ? option.client.name
                                : option.email
                            }
                            {...getTagProps({ index })}
                            avatar={
                              <Avatar
                                src={option.client && option.client.profilePic}
                                className={classes.autompleteAvatar}
                              />
                            }
                          />
                        ))
                    : ""
                }
                renderInput={(params) => (
                  <StyledInput {...params} variant="outlined" />
                )}
                renderOption={(props, option, { selected }) => {
                  return option ? (
                    <div {...props}>
                      <Avatar
                        src={option.client && option.client.profilePic}
                        className={classes.autocompleteAvatar}
                      />

                      <label style={{ marginLeft: 10 }}>
                        {option.client && option.client.name
                          ? option.client.name
                          : option.email}
                      </label>
                    </div>
                  ) : (
                    ""
                  );
                }}
                className={classes.filterAutocomplete}
              />
            ) : (
              <Autocomplete
                multiple
                limitTags={3}
                fullWidth
                value={filterByValue}
                onChange={(event, newValue) =>
                  this.handleFilterByChipChange(event, newValue)
                }
                options={filterOptions}
                getOptionDisabled={(option) => {
                  let index = filterByValue.findIndex((item: any) => {
                    return option.name === item.name;
                  });
                  if (index === -1) {
                    return false;
                  } else {
                    return true;
                  }
                }}
                getOptionLabel={(option) => option.name}
                renderTags={
                  filterByValue
                    ? (tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <StyledChip
                            label={option.name}
                            {...getTagProps({ index })}
                            variant="gray"
                          />
                        ))
                    : ""
                }
                renderInput={(params) => (
                  <StyledInput {...params} variant="outlined" />
                )}
                renderOption={(props, option, { selected }) => {
                  return (
                    option && (
                      <div {...props}>
                        <label>{option.name}</label>
                      </div>
                    )
                  );
                }}
                className={classes.filterAutocomplete}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  sectionHeadingSkeleton = () => {
    let { classes } = this.props;
    return (
      <Grid
        item
        xs={12}
        container
        direction="row"
        className={classes.topSection}
        style={{ marginTop: 24 }}
      >
        <Grid item container direction="row">
          <Grid item className={classes.headerSkeleton}>
            <Skeleton height={24} width={80} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  topicIdeaSkeleton = () => {
    let { classes } = this.props;
    return (
      <Grid item xs={12} container direction="row" className={classes.item}>
        <Grid item xs={2} sm={2} md={1} lg={1}>
          <Skeleton height={60} width={60} />
        </Grid>

        <Grid
          item
          container
          direction="row"
          xs={7}
          className={classes.authorContainer}
        >
          <Grid item xs={12}>
            <Skeleton height={20} />
          </Grid>

          <Grid item xs={12}>
            <Skeleton height={15} />
          </Grid>

          <Grid item container direction="row">
            <Grid item>
              <Skeleton circle={true} width={30} height={30} />
            </Grid>

            <Grid item xs={2} className={classes.commentSkeleton}>
              <Skeleton height={24} />
            </Grid>

            <Grid item xs={2} className={classes.labelSkeleton}>
              <Skeleton height={24} />
            </Grid>

            <Grid item xs={2} className={classes.labelSkeleton}>
              <Skeleton height={24} />
            </Grid>

            <Grid item xs={2} className={classes.labelSkeleton}>
              <Skeleton height={24} />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row-reverse"
          xs={3}
          sm={3}
          md={3}
          lg={3}
          className={classes.paddingRight15}
        >
          <Grid item style={{ marginTop: 2 }}>
            <Skeleton height={30} width={10} />
          </Grid>

          <Grid item style={{ paddingRight: 30 }}>
            <Skeleton height={34} width={80} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  openProjectsWithTopic = (topicId: any) => {
    let { publicationId } = this.state;

    this.props.history.push(`/${publicationId}/projects?topicid=${topicId}`);
  };

  generateTopicValue(topic: any) {
    return topic;
  }

  handleTopicSelection = (event: any, topic: any, index: any) => {
    let {
      topics,

      selectedTopics,

      prevSelectedTopicIndex,

      currentSelectedTopicIndex
    } = this.state;
    if (event.shiftKey) {
      if (prevSelectedTopicIndex === -1) {
        prevSelectedTopicIndex = index;
        this.setState({ prevSelectedTopicIndex });
      } else {
        currentSelectedTopicIndex = index;
        let selectedValue = multiSelect(
          topics,
          selectedTopics,
          prevSelectedTopicIndex,
          currentSelectedTopicIndex,
          event.target.checked,
          this.generateTopicValue
        );
        this.setState({
          selectedTopics: selectedValue,
          prevSelectedTopicIndex: currentSelectedTopicIndex,
          currentSelectedTopicIndex: -1
        });
        return;
      }
    } else {
      this.setState({
        prevSelectedTopicIndex: index,
        currentSelectedTopicIndex: -1
      });
    }
    if (event.target.checked && !selectedTopics.includes(topic)) {
      selectedTopics.push(topic);
    }
    if (!event.target.checked && selectedTopics.includes(topic)) {
      let index = selectedTopics.findIndex(
        (selectedTopic: any) => selectedTopic._id === topic._id
      );
      if (index >= 0) {
        selectedTopics.splice(index, 1);
      }
    }
    this.setState({
      selectedTopics
    });
  };

  toggleBulkAssignDialog = () => {
    this.setState((prevState, props) => ({
      showBulkAssignDialog: !prevState.showBulkAssignDialog
    }));
  };

  cancelSelection = () => {
    this.setState({
      selectedTopics: []
    });
  };

  isTopicSelected = (topicId: any) => {
    let { selectedTopics } = this.state;

    if (selectedTopics.some((topic: any) => topic._id === topicId)) {
      return true;
    }
    return false;
  };

  toggleArchiveConfirmDialog = () => {
    this.setState((prevState, props) => ({
      showArchiveDialog: !prevState.showArchiveDialog
    }));
  };

  toggleUnarchiveConfirmDialog = () => {
    this.setState((prevState, props) => ({
      showUnarchiveDialog: !prevState.showUnarchiveDialog
    }));
  };

  getCommentText = (commentsCount: any) => {
    if (commentsCount) {
      if (commentsCount > 1) {
        return commentsCount + " Comments";
      } else {
        return commentsCount + " Comment";
      }
    } else {
      return "Comment";
    }
  };

  handleBulkArchive = () => {
    let topicIds = this.state.selectedTopics.map((topic: any) => topic._id);
    api.archiveBulkTopics(topicIds, (res: any) => {
      if (res.status === 200) {
        this.refetchData();
      } else {
        notify.show(
          res.status === 400 ? res.data : "Unable to archive your ideas.",
          "error"
        );
      }
    });
  };
  handleBulkUnarchive = () => {
    let topicIds = this.state.selectedTopics.map((topic: any) => topic._id);
    api.unarchiveBulkTopics(topicIds, (res: any) => {
      if (res.status === 200) {
        this.refetchData();
      } else {
        notify.show(
          res.status === 400 ? res.data : "Unable to unarchive your ideas.",
          "error"
        );
      }
    });
  };

  render() {
    let { classes } = this.props;
    let {
      loading,

      fetchingTopics,

      error,

      topics,

      publicationId,

      publication,

      profilePic,

      showTopic,

      status,

      topicId,

      duplicateTopic,

      editTopic,

      savingFiles,

      anchorEl,

      showMoreOptions,

      hasNextPage,

      searchKeyword,

      sortBy,

      haveAccess,

      showFilterDiv,

      labelSuggestions,

      topicIdeasView,

      page,

      rowsPerPage,

      totalTopics,

      showTopicIdeaUploadConfirmation,

      showSearchBar,

      topicTitle,

      topicIndex,

      difficultySort,

      createdOnSort,

      thumbsUpCountSort,

      searchVolumeSort,

      domainAuthoritySort,

      searchIntentSort,

      funnelStageSort,

      conversionLikelihoodSort,

      topFormatsSort,

      selectedTopics,

      showBulkAssignDialog,

      teammates,

      topicSelectionIndex,

      haveAssignPermission,

      showArchiveDialog,

      showUnarchiveDialog,

      customSortByField,

      customSortDirection,

      contentStagesConfig,

      customStages,

      ideaTemplates,

      defaultIdeaTemplate,
      showBulkCreate,

      minDate,

      topicDetails,

      expandMore,

      eventElement,

      isIdeasPublic,

      isUserLoggedIn,

      showAnonymousUsernameDialog,

      anonymousUsername,

      anonymousEmail,

      anonymousClientObject,

      showDeleteTopicConfirmation,
      marketingCampaignSuggestion
    } = this.state;

    let showSelection =
      (haveAssignPermission && status === topicStatus.PROPOSED) ||
      status === topicStatus.FIRST_DRAFT ||
      status === topicStatus.ARCHIVED;
    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 />
        </Suspense>
      );
    }

    if (!loading && !haveAccess) {
      return (
        <React.Suspense fallback={<div />}>
          <Page404 errorMessage="Upgrade to a higher plan to access ideas dashboard" />

          <UpgradePlanDialog
            open={true}
            body="Upgrade to a higher plan to access ideas dashboard."
          />
        </React.Suspense>
      );
    }
    // fetchingTopics = true;
    return (
      <div className={classes.container}>
        <Helmet>
          <title>
            Ideas {`${publication && "- " + publication.name}`} | Letterdrop
          </title>
        </Helmet>

        <Container style={{ paddingTop: 24 }}>
          <Grid container direction="column" className={classes.grid}>
            <Grid
              container
              item
              sm={12}
              direction="row"
              justifyContent="space-between"
              className={classes.width100}
            >
              <Grid item>
                <Typography variant="h600" className={classes.pageTitle}>
                  Ideas
                </Typography>
              </Grid>
              {!loading ? (
                <Grid item>
                  {isUserLoggedIn && (
                    <>
                      <input
                        id="myInput"
                        type="file"
                        ref={this.fileUploadRef}
                        style={{ display: "none" }}
                        accept=".csv"
                        onChange={this.onFileChange}
                      />

                      <StyledButton
                        variant="textprimary"
                        size="medium"
                        className={classes.textButton}
                        onClick={this.toggleTopicIdeaUploadConfirmation}
                      >
                        Upload
                      </StyledButton>

                      <StyledButton
                        variant="textprimary"
                        size="medium"
                        className={classes.newTopicButton}
                        onClick={() => {
                          this.toggleBulkCreate(true);
                        }}
                      >
                        Bulk Create
                      </StyledButton>
                    </>
                  )}

                  <StyledButton
                    variant="primary"
                    size="medium"
                    startIcon={<AddIcon />}
                    className={classes.newTopicButton}
                    onClick={() => {
                      !isUserLoggedIn && !anonymousClientObject
                        ? this.askAnonymousUsername()
                        : this.toggleShowTopic(null, true);
                    }}
                  >
                    New idea
                  </StyledButton>
                </Grid>
              ) : (
                <Grid item container direction="row-reverse">
                  <Grid item style={{ paddingRight: 20 }}>
                    <Skeleton height={34} width={80} />
                  </Grid>

                  <Grid item style={{ paddingRight: 20 }}>
                    <Skeleton height={34} width={80} />
                  </Grid>

                  <Grid item style={{ paddingRight: 20 }}>
                    <Skeleton height={34} width={80} />
                  </Grid>

                  <Grid item style={{ paddingRight: 20 }}>
                    <Skeleton height={34} width={80} />
                  </Grid>
                </Grid>
              )}
            </Grid>
            {loading ? (
              this.sectionHeadingSkeleton()
            ) : (
              <Grid
                id="status-tabs-container"
                item
                xs={12}
                container
                direction="row"
                justifyContent="space-between"
                className={classes.topSection}
                style={{ marginTop: 30 }}
              >
                <StyledTab
                  id="status-tabs"
                  tabs={this.getTabs()}
                  size="large"
                  value={status}
                  exposure="low"
                  onChange={(event: any, newValue: any) => {
                    this.setStatus(newValue);
                  }}
                />
              </Grid>
            )}

            <Grid
              id="filter-container"
              item
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
              className={classes.filterButtonSection}
            >
              <StyledButton
                id="filter"
                variant="tertiary"
                size="medium"
                onClick={this.toggleFilterSortDiv}
                startIcon={<FilterIcon />}
              >
                Filters
              </StyledButton>

              <div className={classes.searchBar}>
                {!showSearchBar && (
                  <Tooltip title="Search">
                    <IconButton
                      onClick={this.toggleSearch}
                      style={{ marginRight: 16 }}
                    >
                      <Search />
                    </IconButton>
                  </Tooltip>
                )}
                {showSearchBar && (
                  <SearchBar
                    size="medium"
                    value={searchKeyword}
                    onChange={this.handleSearchChange}
                    onCancelSearch={this.onCancelSearch}
                    onRequestSearch={this.loadMore}
                    toggleSearch={this.toggleSearch}
                    style={{ marginRight: 16 }}
                  />
                )}

                <FormControl style={{ marginRight: 16 }}>
                  <StyledSelect
                    id="sort-ideas"
                    onChange={this.handleSortByChange}
                    className={clsx(classes.input)}
                    value={sortBy}
                    style={{ minWidth: 220 }}
                    renderValue={(option: any) => {
                      if (!option) {
                        return <span>Select an option...</span>;
                      }
                      return (
                        <Typography variant="bodym">
                          Sort by {option.toLowerCase()}
                        </Typography>
                      );
                    }}
                  >
                    {Object.keys(SORTBY).map((key, index) => (
                      <StyledMenuItem value={SORTBY[key]} key={index}>
                        {SORTBY[key]}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>

                <ToggleButtonGroup
                  id="ideas-views"
                  size="medium"
                  value={topicIdeasView}
                >
                  <ToggleButton
                    variant="iconOnly"
                    value={topicIdeasViewType.AGENDA_VIEW}
                    onClick={() =>
                      this.toggleTopicIdeasView(topicIdeasViewType.AGENDA_VIEW)
                    }
                  >
                    <Tooltip title="Agenda view">
                      <StyledSVG
                        color={
                          topicIdeasView === topicIdeasViewType.AGENDA_VIEW
                            ? colors.white
                            : designColors.grayScale[80]
                        }
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <ListIcon />
                      </StyledSVG>
                    </Tooltip>
                  </ToggleButton>

                  <ToggleButton
                    variant="iconOnly"
                    value={topicIdeasViewType.TABLE_VIEW}
                    onClick={() =>
                      this.toggleTopicIdeasView(topicIdeasViewType.TABLE_VIEW)
                    }
                  >
                    <Tooltip title="Table view">
                      <StyledSVG
                        color={
                          topicIdeasView === topicIdeasViewType.TABLE_VIEW
                            ? colors.white
                            : designColors.grayScale[80]
                        }
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <TableIcon />
                      </StyledSVG>
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
                {isUserLoggedIn && (
                  <div style={{ marginLeft: space.XS }}>
                    <IconButton onClick={this.toggleMoreOptions}>
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            </Grid>

            <Collapse in={showFilterDiv}>{this.filterContainer()}</Collapse>

            {loading || fetchingTopics ? (
              <div>
                {this.topicIdeaSkeleton()}
                {this.topicIdeaSkeleton()}
                {this.topicIdeaSkeleton()}
                {this.topicIdeaSkeleton()}
                {this.topicIdeaSkeleton()}
              </div>
            ) : (
              <Grid item>
                {status === topicStatus.PROPOSED &&
                  topics.length === 0 &&
                  !searchKeyword && (
                    <div>
                      <Typography
                        variant="bodyl"
                        paragraph={true}
                        className={classes.errorMessage}
                        style={{ marginTop: 50 }}
                      >
                        You don't have any idea suggestions.
                      </Typography>

                      <Typography
                        variant="bodym"
                        paragraph={true}
                        className={classes.errorMessage}
                      >
                        Click on New idea to get started.
                      </Typography>

                      <img
                        src={images.getStarted}
                        className={classes.getStarted}
                        alt="get started"
                      />
                    </div>
                  )}
                {topics.length === 0 && searchKeyword && (
                  <Typography
                    paragraph={true}
                    variant="bodyl"
                    className={classes.errorMessage}
                    style={{ marginTop: 50 }}
                  >
                    {`No suggestions found for "${searchKeyword}" in ${status}
                  topics`}
                  </Typography>
                )}
                {topics.length > 0 &&
                topicIdeasView === topicIdeasViewType.TABLE_VIEW ? (
                  <div className={classes.topicTable}>
                    <TopicsTableView
                      topics={topics}
                      topicDetails={topicDetails}
                      publicationId={publicationId}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      totalTopics={totalTopics}
                      difficultySort={difficultySort}
                      createdOnSort={createdOnSort}
                      thumbsUpCountSort={thumbsUpCountSort}
                      searchVolumeSort={searchVolumeSort}
                      domainAuthoritySort={domainAuthoritySort}
                      funnelStageSort={funnelStageSort}
                      conversionLikelihoodSort={conversionLikelihoodSort}
                      searchIntentSort={searchIntentSort}
                      topFormatsSort={topFormatsSort}
                      handleSort={this.reloadWithSort}
                      customFieldSort={this.customFieldSort}
                      customSortByField={customSortByField}
                      customSortDirection={customSortDirection}
                      handleChangePage={this.handleChangePage}
                      handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </div>
                ) : (
                  <InfiniteScroll
                    className={classes.scrollHeight}
                    dataLength={topics.length}
                    next={this.loadMoreInScroll}
                    hasMore={hasNextPage}
                    loader={<Loading />}
                  >
                    <Grid container direction="column">
                      {topics.map((topic: any, index: any) => {
                        return (
                          <Grid
                            item
                            container
                            direction="row"
                            className={classes.item}
                            key={topic._id}
                            xs={12}
                            onMouseEnter={() => {
                              this.setState({
                                topicSelectionIndex: index
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({
                                topicSelectionIndex: null
                              });
                            }}
                          >
                            <Grid item xs={2} sm={2} md={1} lg={1}>
                              {topicDetails[topic._id]?.upvotedNames ? (
                                <Vote
                                  id={topic._id}
                                  count={topicDetails[topic._id]?.thumbsUpCount}
                                  alreadyVoted={
                                    topicDetails[topic._id]?.alreadyVoted
                                  }
                                  viewOnly={
                                    topic.status === topicStatus.PROPOSED
                                      ? false
                                      : true
                                  }
                                  upvoted={
                                    topicDetails[topic._id]?.thumbsUpDetails
                                  }
                                  upvotedNames={
                                    topicDetails[topic._id]?.upvotedNames
                                  }
                                  askAnonymousUsername={
                                    this.askAnonymousUsername
                                  }
                                  anonymousClient={anonymousClientObject}
                                  isUserLoggedIn={isUserLoggedIn}
                                />
                              ) : (
                                <Grid item xs={2} sm={2} md={1} lg={1}>
                                  <Skeleton height={60} width={60} />
                                </Grid>
                              )}
                            </Grid>

                            <Grid
                              item
                              container
                              direction="column"
                              xs={10}
                              sm={10}
                              md={11}
                              lg={11}
                            >
                              <Grid item container direction="row">
                                <Grid
                                  item
                                  container
                                  direction="column"
                                  xs={showSelection ? 6 : 7}
                                  sm={showSelection ? 6 : 7}
                                  md={
                                    MaskedAssignedStatuses.includes(
                                      topic.status
                                    ) || topic.status === topicStatus.PUBLISHED
                                      ? showSelection
                                        ? 7
                                        : 8
                                      : showSelection
                                      ? 8
                                      : 9
                                  }
                                  lg={
                                    MaskedAssignedStatuses.includes(
                                      topic.status
                                    ) || topic.status === topicStatus.PUBLISHED
                                      ? showSelection
                                        ? 7
                                        : 8
                                      : showSelection
                                      ? 8
                                      : 9
                                  }
                                  className={classes.authorContainer}
                                >
                                  <Grid
                                    item
                                    container
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Typography
                                      variant="h300"
                                      className={classes.topicTitle}
                                      style={{
                                        maxWidth: topic.keywordSearchResult
                                          ?.result
                                          ? "85%"
                                          : "100%",
                                        cursor: "pointer"
                                      }}
                                      onClick={() => {
                                        this.toggleShowTopic(topic, false);
                                      }}
                                    >
                                      {topic.title ? topic.title : "Untitled"}
                                    </Typography>
                                    {/* {topic.keywordSearchResult?.result &&
                                      this.generateKeywordAnalytics(topic)} */}
                                  </Grid>

                                  <Grid
                                    item
                                    onClick={() => {
                                      this.toggleShowTopic(topic, false);
                                    }}
                                  >
                                    <Typography
                                      variant="bodym"
                                      className={classes.description}
                                    >
                                      {topic.status === topicStatus.SCHEDULED
                                        ? `Scheduled for ${formatForDisplayDateTimeString(
                                            topic.publishDate
                                          )}`
                                        : topic.descriptionText}
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  container
                                  direction="row-reverse"
                                  xs={showSelection ? 6 : 5}
                                  sm={showSelection ? 6 : 5}
                                  md={
                                    MaskedAssignedStatuses.includes(
                                      topic.status
                                    ) || topic.status === topicStatus.PUBLISHED
                                      ? showSelection
                                        ? 5
                                        : 4
                                      : showSelection
                                      ? 4
                                      : 3
                                  }
                                  lg={
                                    MaskedAssignedStatuses.includes(
                                      topic.status
                                    ) || topic.status === topicStatus.PUBLISHED
                                      ? showSelection
                                        ? 5
                                        : 4
                                      : showSelection
                                      ? 4
                                      : 3
                                  }
                                >
                                  {isUserLoggedIn && (
                                    <div className={classes.topicActionSection}>
                                      <div
                                        style={{
                                          display: showSelection
                                            ? "block"
                                            : "none",
                                          width: 40
                                        }}
                                      >
                                        {showSelection &&
                                          (selectedTopics.length ||
                                            topicSelectionIndex === index) && (
                                            <StyledCheckbox
                                              checked={this.isTopicSelected(
                                                topic._id
                                              )}
                                              onClick={(event: any) =>
                                                this.handleTopicSelection(
                                                  event,
                                                  topic,
                                                  index
                                                )
                                              }
                                              labelStyle={{
                                                margin: 0
                                              }}
                                            />
                                          )}
                                      </div>

                                      <Tooltip title="Options">
                                        <IconButton
                                          onClick={(event) =>
                                            this.openMoreOptions(event, index)
                                          }
                                          size="large"
                                          style={{ marginLeft: 10 }}
                                        >
                                          <MoreVertIcon />
                                        </IconButton>
                                      </Tooltip>
                                      {!isAccessDisabled(
                                        publicationId,
                                        this.context,
                                        pageAccess.ASSIGN_TOPIC
                                      ) &&
                                        topic.status ===
                                          topicStatus.PROPOSED && (
                                          <StyledButton
                                            variant="secondary"
                                            size="small"
                                            onClick={() =>
                                              this.assignTopic(
                                                topic._id,
                                                topic.channel?.type ||
                                                  channelTypes.BLOG
                                              )
                                            }
                                            startIcon={<AssignIcon />}
                                          >
                                            Assign
                                          </StyledButton>
                                        )}

                                      <Popover
                                        id={"moreOption_" + index}
                                        open={showMoreOptions === index}
                                        anchorEl={anchorEl}
                                        onClose={this.closeMoreOptions}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "center"
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "center"
                                        }}
                                        PaperProps={{
                                          className: classes.popover
                                        }}
                                      >
                                        {this.drawButtonsGrid(index, topic)}
                                      </Popover>
                                      {(MaskedAssignedStatuses.includes(
                                        topic.status
                                      ) ||
                                        topic.status ===
                                          topicStatus.PUBLISHED ||
                                        customStages?.includes(
                                          topic.status
                                        )) && (
                                        <StyledButton
                                          variant="secondary"
                                          onClick={() => {
                                            this.openProjectsWithTopic(
                                              topic._id
                                            );
                                          }}
                                        >
                                          Post Status
                                        </StyledButton>
                                      )}
                                    </div>
                                  )}
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                container
                                className={classes.commentsContainer}
                              >
                                <Accordion
                                  TransitionProps={{
                                    timeout: 250,
                                    unmountOnExit: true
                                  }}
                                >
                                  <AccordionSummary expandIcon={<div />}>
                                    <Tooltip
                                      title={
                                        "Suggested by " +
                                        (topicDetails[topic._id]?.suggestedBy
                                          ?.name ||
                                          topicDetails[topic._id]?.suggestedBy
                                            ?.email ||
                                          topicDetails[topic._id]
                                            ?.anonymousClient?.name ||
                                          topicDetails[topic._id]
                                            ?.anonymousClient?.email ||
                                          ANONYMOUS) +
                                        " on " +
                                        formatForDisplayDateTime(
                                          topic.createdOn
                                        )
                                      }
                                    >
                                      <Avatar
                                        sx={{
                                          ...AvatarSize.xs
                                        }}
                                        className={classes.authorAvatar}
                                        src={
                                          topicDetails[topic._id]?.suggestedBy
                                            ?.profilePic
                                        }
                                        alt="logo"
                                      />
                                    </Tooltip>

                                    <StyledSVG
                                      color={
                                        index === topicIndex
                                          ? designColors.primary[50]
                                          : designColors.grayScale[80]
                                      }
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                    >
                                      <MessageBubbleIcon />
                                    </StyledSVG>

                                    <Typography
                                      variant="bodys"
                                      className={classes.commentsHeading}
                                      onClick={this.askAnonymousUsername}
                                      onMouseEnter={() => {
                                        this.handleCommentColorChange(index);
                                      }}
                                      onMouseLeave={() => {
                                        this.handleCommentColorChange(null);
                                      }}
                                    >
                                      {this.getCommentText(
                                        topicDetails[topic._id]?.commentsCount
                                      )}
                                    </Typography>

                                    <Grid item>
                                      {topic.labels?.map(
                                        (label: any, key: any) => {
                                          return (
                                            <StyledChip
                                              variant="gray"
                                              key={key}
                                              label={label}
                                            />
                                          );
                                        }
                                      )}
                                    </Grid>
                                  </AccordionSummary>

                                  <AccordionDetails>
                                    <TopicComments
                                      publicationId={publicationId}
                                      comments={topic.comments}
                                      topicId={topic._id}
                                      profilePic={profilePic}
                                      onCommentCountChange={(count: any) =>
                                        this.handleCommentCountChange(
                                          count,
                                          index
                                        )
                                      }
                                      anonymousClientObject={
                                        anonymousClientObject
                                      }
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </InfiniteScroll>
                )}
              </Grid>
            )}
          </Grid>
        </Container>
        {/* make public popover  */}
        {expandMore && (
          <Popover
            id="moreOption_ideas_"
            open={expandMore}
            anchorEl={eventElement}
            onClose={this.toggleMoreOptions}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            PaperProps={{
              className: classes.morePopover
            }}
            size="small"
            style={{
              padding: 5
            }}
          >
            <div onClick={this.togglePublicAccess} className={classes.menuItem}>
              <Typography variant="bodym">
                {isIdeasPublic ? "Make Private" : "Make Public"}
              </Typography>
            </div>
          </Popover>
        )}
        {showTopicIdeaUploadConfirmation && (
          <StyledDialog
            open={showTopicIdeaUploadConfirmation}
            title="Is your CSV formatted right?"
            body={
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    "We accept CSVs with these column headers:<br></br>“Title” or “H1”: The working title of your post. If left blank but a keyword is provided, we’ll pull the title from the top post for that search.<br></br>“Description” or “Brief”: The description of talking points of your post. (optional)<br></br>“Suggested By”: The email of the Letterdrop user who suggested the idea. If left blank, we’ll use your email.<br></br>“Keyword”: The keyword for the post. (optional unless title is blank)<br></br>“Label”: A label to categorize your post and keep things organized. (optional)<br></br>“Position”: We’ll order your ideas by this value if provided. (optional)"
                }}
              />
            }
            successButtonName="Upload file"
            successCallback={this.openTopicIdeaFileUpload}
            cancelCallback={this.toggleTopicIdeaUploadConfirmation}
          />
        )}

        {showDeleteTopicConfirmation && (
          <StyledDialog
            open={showDeleteTopicConfirmation}
            title="Are you sure?"
            body="Once deleted, you can't recover your idea"
            successButtonName="Yes, I'm sure "
            cancelButtonName="Cancel"
            successCallback={this.deleteTopic}
            cancelCallback={() => {
              this.setState({
                showDeleteTopicConfirmation: false,
                deleteTopicId: null
              });
            }}
          />
        )}
        {showBulkCreate && (
          <BulkCreateTopics
            open={showBulkCreate}
            onCloseDialog={() => this.toggleBulkCreate(false)}
            teammates={teammates}
            onBulkCreate={this.refetchData}
            publication={publication}
            minDate={minDate}
          />
        )}
        {showTopic && (
          <StyledDialog
            maxWidth="md"
            open={showTopic}
            title={
              editTopic ? (
                " "
              ) : (
                <Typography variant="h500">
                  {truncateStringAndAddEllipses(topicTitle, TITLE_LENGTH)}
                </Typography>
              )
            }
            body={
              <Topic
                ref={(el: any) => {
                  this.topicRef = el;
                }}
                topicId={topicId}
                publicationId={publicationId}
                closeCallBack={this.toggleShowTopic}
                loadingCallBack={this.saving}
                viewOnly={editTopic ? false : true}
                labelSuggestions={labelSuggestions}
                marketingCampaignSuggestion={marketingCampaignSuggestion}
                duplicateTopic={duplicateTopic}
                fromIdeasPage={true}
                ideaTemplates={ideaTemplates}
                defaultIdeaTemplate={defaultIdeaTemplate}
                isUserLoggedIn={isUserLoggedIn}
                anonymousClientObject={anonymousClientObject}
              />
            }
            successButtonName={
              editTopic ? "Save" : isUserLoggedIn ? "Edit" : ""
            }
            successCallback={
              editTopic ? this.callSaveTopic : this.changeToEditMode
            }
            cancelButtonName={!editTopic && "Share"}
            closeCallback={() => {
              this.toggleShowTopic(null, false, false);
            }}
            cancelCallback={!editTopic && this.CopyToClipboard}
          />
        )}
        {!showBulkAssignDialog &&
          !showTopic &&
          topicIdeasView === topicIdeasViewType.AGENDA_VIEW &&
          selectedTopics.length > 0 &&
          showSelection && (
            <Popper open={true} anchorEl={null} className={classes.popper}>
              <Paper className={classes.bulkAssignIdeasPaper}>
                <Typography variant="bodym">
                  {`${selectedTopics.length} idea${
                    selectedTopics.length === 1 ? "" : "s"
                  } selected`}
                </Typography>

                <div>
                  {status === topicStatus.PROPOSED && (
                    <StyledButton
                      variant="contrastsecondary"
                      onClick={this.toggleBulkAssignDialog}
                    >
                      Assign
                    </StyledButton>
                  )}
                  {(status === topicStatus.FIRST_DRAFT ||
                    status === topicStatus.PROPOSED) && (
                    <StyledButton
                      variant="contrastsecondary"
                      onClick={this.toggleArchiveConfirmDialog}
                      style={{ marginLeft: 10 }}
                    >
                      Archive
                    </StyledButton>
                  )}

                  {status === topicStatus.ARCHIVED && (
                    <StyledButton
                      variant="contrastsecondary"
                      onClick={this.toggleUnarchiveConfirmDialog}
                    >
                      Unarchive
                    </StyledButton>
                  )}

                  <StyledButton
                    variant="textcontrastprimary"
                    onClick={this.cancelSelection}
                    style={{ marginLeft: 10 }}
                  >
                    Cancel
                  </StyledButton>
                </div>
              </Paper>
            </Popper>
          )}

        {showBulkAssignDialog && (
          <BulkAssignTopics
            open={showBulkAssignDialog}
            topics={selectedTopics}
            onCloseDialog={this.toggleBulkAssignDialog}
            teammates={teammates}
            onBulkAssign={this.refetchData}
            publicationId={publication._id}
            contentStagesConfig={contentStagesConfig}
          />
        )}
        {showArchiveDialog && (
          <StyledDialog
            open={showArchiveDialog}
            title="Are you sure?"
            body={`These ${selectedTopics.length} idea${handleSingular(
              selectedTopics.length
            )} will be archived. You can access them in archived tab`}
            successButtonName="Archive"
            cancelButtonName="Cancel"
            successCallback={this.handleBulkArchive}
            cancelCallback={() => {
              this.setState({
                showArchiveDialog: false
              });
            }}
          />
        )}
        {showAnonymousUsernameDialog && (
          <StyledDialog
            open={showAnonymousUsernameDialog}
            title="Who are you?"
            body={
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="h300"
                    paragraph={true}
                    className={classes.inputLabel}
                  >
                    Name
                  </Typography>
                </Grid>

                <Grid item>
                  <StyledInput
                    autoFocus
                    fullWidth
                    size="medium"
                    placeholder="Richard Hendricks"
                    name="anonymousUsername"
                    value={anonymousUsername}
                    onChange={this.onAnonymousUsernameChange}
                  />
                </Grid>

                <Grid item style={{ marginTop: space.SMALL }}>
                  <Typography
                    variant="h300"
                    paragraph={true}
                    className={classes.inputLabel}
                  >
                    Email
                  </Typography>
                </Grid>

                <Grid item>
                  <StyledInput
                    autoFocus
                    fullWidth
                    size="medium"
                    placeholder="richard@piedpiper.com"
                    name="anonymousEmail"
                    value={anonymousEmail}
                    onChange={this.onAnonymousEmailChange}
                  />
                </Grid>
              </Grid>
            }
            successButtonName="Continue"
            cancelButtonName="Cancel"
            successCallback={this.saveAnonoymousUsername}
            cancelCallback={this.createDefaultAnonoymousUsername}
          />
        )}
        {showUnarchiveDialog && (
          <StyledDialog
            open={showUnarchiveDialog}
            title="Are you sure?"
            body={`These ${selectedTopics.length} idea${handleSingular(
              selectedTopics.length
            )} will be unarchived.`}
            successButtonName="Unarchive"
            cancelButtonName="Cancel"
            successCallback={this.handleBulkUnarchive}
            cancelCallback={() => {
              this.setState({
                showUnarchiveDialog: false
              });
            }}
          />
        )}

        <DemoVideoDialog location={this.props.location} />
      </div>
    );
  }
}

export default withStyles(styles)(Ideas);
