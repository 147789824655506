import React, { useEffect, useRef, useState } from "react";
import { designColors } from "../../Themes/Colors";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { space } from "../../Config/theme";
import Close from "@mui/icons-material/Close";

// 4 minutes
const MAX_TIME_IN_SECONDS = 4 * 60;
const FINAL_TIME = "00:00";

function formatTime(timeLeft: number) {
  const minutes = Math.floor(timeLeft / 60);
  const seconds = Math.floor(timeLeft % 60);
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
}

export default function LinkedInPostDraftTimer() {
  const [timeLeft, setTimeLeft] = useState(formatTime(MAX_TIME_IN_SECONDS));
  const [isVisible, setIsVisible] = useState(true);
  const startTime = useRef(Date.now());

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    const interval = setInterval(() => {
      const elapsedTime = (Date.now() - startTime.current) / 1000;
      const timeLeft = MAX_TIME_IN_SECONDS - elapsedTime;
      if (timeLeft <= 0) {
        clearInterval(interval);
        setTimeLeft(FINAL_TIME);
        return;
      }
      setTimeLeft(formatTime(timeLeft));
    }, 1000);

    return () => clearInterval(interval);
  }, [isVisible]);

  const handleCloseClick = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Paper
      sx={{
        backgroundColor:
          timeLeft == FINAL_TIME
            ? designColors.alert[100]
            : designColors.primary[50],
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1200,
        borderRadius: 0,
        color: designColors.white
      }}
    >
      <Box
        maxWidth="560px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={space.MEDIUM}
        margin="auto"
        height="60px"
      >
        {timeLeft === FINAL_TIME ? (
          <Typography variant="bodym">
            Time’s up. Done is better than perfect. Wrap it up and post.
          </Typography>
        ) : (
          <>
            <Typography
              component="p"
              sx={{ fontSize: 40, fontWeight: 600, minWidth: 120 }}
            >
              {timeLeft}
            </Typography>
            <Typography variant="bodym">
              Use AI to get a first draft. Make some quick edits and post.
            </Typography>
          </>
        )}
      </Box>
      <IconButton
        onClick={handleCloseClick}
        sx={{
          position: "absolute",
          top: "50%",
          right: space.LARGE,
          transform: "translateY(-50%)"
        }}
      >
        <Close htmlColor={designColors.white} />
      </IconButton>
    </Paper>
  );
}
