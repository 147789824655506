import { Grid, Typography } from "@mui/material";
import React from "react";
import { space } from "../Config/theme";
import StyledButton from "../design/components/StyledButton";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";

import Api from "../Services/Api";
import { notify } from "./CustomNotifications";

import StyledSwitch from "../design/components/StyledSwitch";
import { AxiosResponse } from "axios";
import { SalesCallIntegrationEnum } from "../Utils/Types";

const api = Api.create();

interface SalesCallConnectDialogProps {
  open: boolean;
  closeSalesCallConnectDialog: () => void;
  isSalesCallConnected: boolean;
  integrationType: SalesCallIntegrationEnum;
  disconnectSalesCall: () => void;
  publicationId: string;
  sendCallAnalysisMail: boolean;
  showConnectedAccount: boolean;
}

interface SalesCallConnectDialogState {
  isSalesCallConnected: boolean;
  sendCallAnalysisMail: boolean;
  apiKey: string;
  apiKeyError: string;
  accessKey: string;
  accessKeyError: string;
  accessKeySecret: string;
  accessKeySecretError: string;
  showConnectedAccount: boolean;
  fetching: boolean;
}
class SalesCallConnectDialog extends React.Component<
  SalesCallConnectDialogProps,
  SalesCallConnectDialogState
> {
  constructor(props: SalesCallConnectDialogProps) {
    super(props);
    this.state = {
      isSalesCallConnected: props.isSalesCallConnected || false,
      sendCallAnalysisMail: props.sendCallAnalysisMail || false,
      apiKey: "",
      apiKeyError: "",
      accessKey: "",
      accessKeyError: "",
      accessKeySecret: "",
      accessKeySecretError: "",
      showConnectedAccount: props.showConnectedAccount || false,
      fetching: false
    };
  }

  /**
   * Connect to gong account
   * @param {*} isDisconnect
   * @returns
   */
  connectOrDisconnectSalesCallIntegration = (isDisconnect: boolean = false) => {
    let { apiKey, accessKey, accessKeySecret } = this.state;

    let { publicationId, integrationType } = this.props;

    //validation
    if (!isDisconnect) {
      if (
        [
          SalesCallIntegrationEnum.GONG,
          SalesCallIntegrationEnum.CLARI
        ].includes(integrationType)
      ) {
        let accessKeyError = "";
        let accessKeySecretError = "";

        if (!accessKey) {
          accessKeyError = "Provide your access key.";
        }
        if (!accessKeySecret) {
          accessKeySecretError = "Provide your access key secret.";
        }

        this.setState({
          accessKeyError,
          accessKeySecretError
        });
        if (accessKeyError || accessKeySecretError) {
          return;
        }
      }
      if (
        [
          SalesCallIntegrationEnum.FIREFLIES,
          SalesCallIntegrationEnum.CHORUS
        ].includes(integrationType)
      ) {
        let apiKeyError = "";
        if (!apiKey) {
          apiKeyError = "Provide your access key.";
        }

        //check if apiKey is in format 7f31fd0a-c76e-438c-91a6-4a6d03h56c1f
        let apiKeyPattern = new RegExp(
          "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
        );
        //check if apiKey is in format 1423d22822b34080bca5586f9e03b5aa
        if (integrationType === SalesCallIntegrationEnum.CHORUS) {
          apiKeyPattern = new RegExp("^[0-9a-f]{32}$");
        }
        if (!apiKeyPattern.test(apiKey)) {
          apiKeyError = "Invalid API key format.";
        }

        this.setState({
          apiKeyError
        });
        if (apiKeyError) {
          return;
        }
      }
    }

    api.connectToSalesCall(
      publicationId,
      apiKey || accessKey,
      accessKeySecret || "",
      integrationType,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          if (isDisconnect) {
            this.props.disconnectSalesCall();
            return;
          }

          this.setState({
            isSalesCallConnected: !isDisconnect,
            apiKey: "",
            accessKey: "",
            accessKeySecret: ""
          });
        } else {
          notify.show(
            res.status === 400
              ? res.data
              : "Oops! We couldn't connect to your account. Try again..",
            "error"
          );
        }
      }
    );
  };

  toggleSalesCallAnalysisMail = () => {
    let { publicationId } = this.props;
    api.toggleSalesCallAnalysisMail(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        this.setState({
          sendCallAnalysisMail: res.data
        });
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Oops! We couldn't connect to your account. Try again..",
          "error"
        );
      }
    });
  };

  onAPIKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      apiKey: event.target.value
    });
  };

  onAccessKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      accessKey: event.target.value
    });
  };

  onAccessKeySecretChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      accessKeySecret: event.target.value
    });
  };

  /**
   * Show settings and ask button based on the current view
   * @returns
   */
  getAdditionalButtons = () => {
    let { isSalesCallConnected, showConnectedAccount } = this.state;

    if (!isSalesCallConnected) {
      return (
        <StyledButton
          size="medium"
          onClick={() => this.connectOrDisconnectSalesCallIntegration(false)}
        >
          Connect
        </StyledButton>
      );
    }

    if (isSalesCallConnected && showConnectedAccount) {
      return (
        <StyledButton
          size="medium"
          variant="secondary"
          style={{ paddingLeft: "40px", paddingRight: "40px" }}
          onClick={() => {
            this.props.closeSalesCallConnectDialog();
          }}
        >
          OK
        </StyledButton>
      );
    }

    return null;
  };

  showGongConnectedAccount = () => {
    this.setState({
      showConnectedAccount: true
    });
  };

  getDialogTitle = (integrationType: SalesCallIntegrationEnum) => {
    switch (integrationType) {
      case SalesCallIntegrationEnum.GONG:
        return "Connect to Gong";
      case SalesCallIntegrationEnum.FIREFLIES:
        return "Connect to Fireflies";
      case SalesCallIntegrationEnum.CHORUS:
        return "Connect to Chorus";
      case SalesCallIntegrationEnum.CLARI:
        return "Connect to Clari";
      default:
        return "Connect";
    }
  };

  getDialogBody = (integrationType: SalesCallIntegrationEnum) => {
    let {
      apiKey,
      apiKeyError,
      accessKey,
      accessKeyError,
      accessKeySecret,
      accessKeySecretError
    } = this.state;
    switch (integrationType) {
      case SalesCallIntegrationEnum.GONG:
        return (
          <Grid item>
            <Typography
              variant="bodyl"
              paragraph
              style={{ marginBottom: space.MEDIUM }}
            >
              Create keys at{" "}
              <a
                href="https://app.gong.io/company/api"
                rel="noreferrer"
                target="_blank"
              >
                https://app.gong.io/company/api
              </a>
              . You need to be a Technical Administrator in Gong to access
              these.
            </Typography>

            <div
              style={{
                width: "100%",
                marginBottom: space.L,
                display: "block"
              }}
            >
              <Typography
                variant="bodym"
                style={{ marginBottom: space.XS, display: "flex" }}
              >
                Access Key
              </Typography>

              <StyledInput
                autoFocus
                value={accessKey}
                fullWidth
                size="medium"
                name="accessKey"
                helperText={accessKeyError}
                error={!!accessKeyError}
                onChange={this.onAccessKeyChange}
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "block"
              }}
            >
              <Typography
                variant="bodym"
                style={{ marginBottom: space.XS, display: "flex" }}
              >
                Access Key Secret
              </Typography>

              <StyledInput
                value={accessKeySecret}
                fullWidth
                size="medium"
                name="accessKeySecret"
                helperText={accessKeySecretError}
                error={!!accessKeySecretError}
                onChange={this.onAccessKeySecretChange}
              />
            </div>
          </Grid>
        );
      case SalesCallIntegrationEnum.FIREFLIES:
        return (
          <Grid item>
            <Typography
              variant="bodyl"
              paragraph
              style={{ marginBottom: space.MEDIUM }}
            >
              Copy API key under Developer settings at{" "}
              <a
                href="https://app.fireflies.ai/settings"
                rel="noreferrer"
                target="_blank"
              >
                https://app.fireflies.ai/settings
              </a>
              .
            </Typography>

            <div
              style={{
                width: "100%",
                marginBottom: space.L,
                display: "block"
              }}
            >
              <Typography
                variant="bodym"
                style={{ marginBottom: space.XS, display: "flex" }}
              >
                API Key
              </Typography>

              <StyledInput
                autoFocus
                value={apiKey}
                fullWidth
                size="medium"
                name="apiKey"
                helperText={apiKeyError}
                error={!!apiKeyError}
                onChange={this.onAPIKeyChange}
              />
            </div>
          </Grid>
        );
      case SalesCallIntegrationEnum.CHORUS:
        return (
          <Grid item>
            <Typography
              variant="bodyl"
              paragraph
              style={{ marginBottom: space.MEDIUM }}
            >
              Copy API key under Developer settings.
            </Typography>

            <div
              style={{
                width: "100%",
                marginBottom: space.L,
                display: "block"
              }}
            >
              <Typography
                variant="bodym"
                style={{ marginBottom: space.XS, display: "flex" }}
              >
                API Key
              </Typography>

              <StyledInput
                autoFocus
                value={apiKey}
                fullWidth
                size="medium"
                name="apiKey"
                helperText={apiKeyError}
                error={!!apiKeyError}
                onChange={this.onAPIKeyChange}
              />
            </div>
          </Grid>
        );
      case SalesCallIntegrationEnum.CLARI:
        return (
          <Grid item>
            <Typography
              variant="bodyl"
              paragraph
              style={{ marginBottom: space.MEDIUM }}
            >
              {`You'll find API key and secret in workspace settings > integrations > Clari Copilot API`}
            </Typography>

            <div
              style={{
                width: "100%",
                marginBottom: space.L,
                display: "block"
              }}
            >
              <Typography
                variant="bodym"
                style={{ marginBottom: space.XS, display: "flex" }}
              >
                Access Key
              </Typography>

              <StyledInput
                autoFocus
                value={accessKey}
                fullWidth
                size="medium"
                name="accessKey"
                helperText={accessKeyError}
                error={!!accessKeyError}
                onChange={this.onAccessKeyChange}
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "block"
              }}
            >
              <Typography
                variant="bodym"
                style={{ marginBottom: space.XS, display: "flex" }}
              >
                Access Key Secret
              </Typography>

              <StyledInput
                value={accessKeySecret}
                fullWidth
                size="medium"
                name="accessKeySecret"
                helperText={accessKeySecretError}
                error={!!accessKeySecretError}
                onChange={this.onAccessKeySecretChange}
              />
            </div>
          </Grid>
        );
      default:
        return "Connect";
    }
  };

  render() {
    let { open, closeSalesCallConnectDialog, integrationType } = this.props;
    let { showConnectedAccount, isSalesCallConnected, sendCallAnalysisMail } =
      this.state;

    return (
      <StyledDialog
        open={open}
        title={this.getDialogTitle(integrationType)}
        body={
          <Grid container direction="column">
            {!isSalesCallConnected && this.getDialogBody(integrationType)}

            {showConnectedAccount && isSalesCallConnected && (
              <>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Typography variant="bodyl">
                    We can send an email and Slack DM after a sales call with
                    questions from the call as well as links to relevant
                    collateral from your site and social media.
                  </Typography>
                </Grid>

                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: space.MEDIUM
                  }}
                >
                  <StyledSwitch
                    checked={sendCallAnalysisMail}
                    onChange={this.toggleSalesCallAnalysisMail}
                  />

                  <Typography variant="bodyl">
                    Send content suggestions to sales reps after calls
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: space.MEDIUM
                  }}
                >
                  <StyledButton
                    size="small"
                    variant="destructivesecondary"
                    onClick={() => {
                      this.connectOrDisconnectSalesCallIntegration(true);
                    }}
                  >
                    Disconnect
                  </StyledButton>
                </Grid>
              </>
            )}
          </Grid>
        }
        fullWidth
        closeCallback={closeSalesCallConnectDialog}
        additionalButtons={this.getAdditionalButtons()}
        alignOptionAndCancelButtonToLeft={
          isSalesCallConnected && !showConnectedAccount
        }
      />
    );
  }
}

export default SalesCallConnectDialog;
