// @ts-nocheck
import React, { useState } from "react";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import { Typography, Grid } from "@mui/material";
import { designColors } from "../Themes/Colors";
import API from "../Services/Api";

const DevtoConnect = ({
  devtoTokenDetail,
  publicationId,
  onClose,
  onSave
}: any) => {
  const api = API.create();
  const [apiKey, setApiKey] = useState(
    devtoTokenDetail?.integrationToken
      ? devtoTokenDetail.integrationToken
      : null
  );

  const tokenInput = () => {
    return (
      <>
        <Typography
          variant="bodym"
          style={{ color: designColors.grayScale[60] }}
        >
          Go to your Dev.to settings and copy or generate a new token, then
          paste it here
        </Typography>

        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Typography variant="h200">API Key</Typography>

          <StyledInput
            type="text"
            size="medium"
            fullWidth
            value={apiKey}
            placeholder="WkQQhn0E3qD8MWmTTCL4Z5kR"
            style={{ marginTop: 8 }}
            name="integration_token"
            onChange={(e: any) => setApiKey(e.target.value)}
          />
        </Grid>
      </>
    );
  };

  function saveAndClose() {
    api.saveDevtoDetails(publicationId, apiKey, (res: any) => {
      if (res.status === 200) {
        onSave();
      }
    });
  }

  return (
    <StyledDialog
      open={true}
      maxWidth="md"
      body={tokenInput()}
      title={<Typography variant="h400">Connecting to Dev.to</Typography>}
      successButtonName={"Save"}
      successCallback={saveAndClose}
      cancelCallback={onClose}
    />
  );
};

export default DevtoConnect;
