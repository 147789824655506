// @ts-nocheck
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  navigationContainer: {
    marginTop: 5
  },

  LinearProgress: {
    marginTop: 20
  },

  heading: {
    fontWeight: "bolder",
    fontSize: 16
  },

  logoImage: {
    width: 100,
    height: 100
  },

  backButton: {
    marginTop: "10px"
  },

  nextButton: {
    marginTop: "10px"
  },

  navIcon: {
    color: colors.fontPrimary
  },

  form: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }
});
export default styles;
