// @ts-nocheck
import { Container, Divider, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { Component, Suspense } from "react";
import { notify } from "../Components/CustomNotifications";

import { withRouter } from "react-router-dom";

import EmailDetails from "../Components/EmailDetails";

import Loading from "../Components/Loading";
import API from "../Services/Api";
import styles from "./styles/SettingsStyles";

import EmailTemplate from "../Components/EmailTemplate";

import EmailDomain from "../Components/EmailDomain";
import "firebase/auth";

import FeedbackSettings from "../Components/FeedbackSettings";

import PostFeedbackSettings from "../Components/PostFeedbackSettings";
import qs from "query-string";
import { pageAccess } from "../Utils/Types";
import PageAccessContext from "../Utils/PageAccessContext";

import { Helmet } from "react-helmet";
import { isAccessDisabled } from "../Utils/UserUtils";

import DoubleOptIn from "../Components/DoubleOptIn";
import { space } from "../Config/theme";

import NewsletterSettings from "../Components/NewsletterSettings";
const api = API.create();

const Page404 = React.lazy(() => import("./Page404"));
class Settings extends Component {
  static contextType = PageAccessContext;
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      publication: null,
      emailDetails: null,
      publicationId: props.match.params.id ? props.match.params.id : "",
      error: false,
      errorMessage: "",
      haveAccessToEdit: false
    };
  }

  componentDidMount() {
    let qsParse = qs.parse(this.props.location.search);
    if (qsParse.message && qsParse.type) {
      notify.show(qsParse.message, qsParse.type);
    }
    this.load();
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(
        {
          loading: true,
          publication: null,
          emailDetails: null,

          publicationId: this.props.match.params.id
            ? this.props.match.params.id
            : "",
          error: false,
          errorMessage: "",
          haveAccessToEdit: false
        },
        this.load
      );
    }
  }

  load = () => {
    let { publicationId } = this.state;
    api.getPublicationById(publicationId, (res: any) => {
      if (res.status === 200) {
        let publication = res.data.publication;
        this.setState({
          loading: false,
          emailDetails: publication.emailDetails,
          publication: publication,
          haveAccessToEdit: !isAccessDisabled(
            publicationId,
            this.context,
            pageAccess.EDIT_SETTINGS
          )
        });
      } else {
        this.setState({
          error: true,
          errorMessage: res.data
        });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const {
      loading,

      emailDetails,

      error,

      errorMessage,

      publication,

      haveAccessToEdit
    } = this.state;

    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage={errorMessage} />
        </Suspense>
      );
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <div className={classes.container}>
        <Helmet>
          <title>
            Email Settings {publication && "- " + publication.name} | Letterdrop
          </title>
        </Helmet>

        <Container>
          <Typography
            variant="h600"
            paragraph
            style={{ paddingTop: space.LARGE }}
          >
            Email Settings
          </Typography>

          <div className={classes.innerContainer}>
            <Grid container direction="row" justify="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
              >
                <EmailDomain publication={publication} />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
              >
                <EmailTemplate publication={publication} />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
              >
                <DoubleOptIn publication={publication} />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
              >
                <EmailDetails
                  emailDetails={emailDetails}
                  id={publication._id}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
              >
                <FeedbackSettings publication={publication} />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
              >
                <NewsletterSettings publication={publication} />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Settings));
