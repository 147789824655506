// @ts-nocheck
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Avatar,
  Box,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";

import _ from "lodash";
import moment from "moment";
import React, { Fragment } from "react";

import StyledCheckbox from "../design/components/StyledCheckbox";

import StyledChip from "../design/components/StyledChip";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import StyledUserChip from "../design/components/StyledUserChip";

import { ReactComponent as CalendarIcon } from "../Images/icon20/calendarColorGray40.svg";
import Api from "../Services/Api";
import colors from "../Themes/Colors";
import { formatForHistoryPanel, isAfter, isBefore } from "../Utils/TimeUtils";
import {
  assigneeSelectionTypes,
  ASSIGNEE_NOT_REQUIRED_STAGES,
  channelTypes,
  datePickerDefault,
  teamRoles,
  TITLE_LENGTH,
  topicStatus
} from "../Utils/Types";
import {
  getDateFromDeadlineDays,
  handleSingular,
  truncateStringAndAddEllipses
} from "../Utils/UserUtils";

import CustomFlatPicker from "./CustomFlatPicker";
import { notify } from "./CustomNotifications";

import InviteFreelancerDialog from "./InviteFreelancerDialog";

import Loading from "./Loading";
import styles from "./styles/BulkAssignTopicsStyle";
import { space } from "../Config/theme";
import {
  features,
  getFeatureLimit,
  isLimitExceeded
} from "../Utils/AccessUtils";
import PageAccessContext from "../Utils/PageAccessContext";

import UpgradePlanDialog from "../design/components/UpgradePlanDialog";
const api = Api.create();

class BulkAssignTopics extends React.Component {
  static contextType = PageAccessContext;
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      topics: [],
      showInviteFreelancer: false,
      topicId: null,
      headers: [],
      topicIndex: null,
      stageIndex: null,
      showEditors: false,
      eventElement: null,
      deadline: null,
      assigneeErrors: [],
      deadlineErrors: [],
      excludeStages: [],
      showUpgradeDialog: false
    };
  }

  componentDidMount = () => {
    this.formatTopics();
  };

  // Format and add stages to each topic
  formatTopics = () => {
    let { topics, contentStagesConfig, teammates } = this.props;

    // Creating topic stages from configuration
    let stages = contentStagesConfig.map((contentStage: any) => {
      let {
        name,
        internalContentStageName,
        defaultAssignees,
        deadlineDays,
        assigneeSelectionType
      } = contentStage;

      // Default assignees are client reference
      // Our autocomplete components uses Teammates as options
      // Finding and creating default assignees from teammates.
      defaultAssignees = teammates.filter((teammate: any) => {
        return (
          defaultAssignees?.some(
            (defaultAssignee: any) => defaultAssignee === teammate.client._id
          ) || false
        );
      });

      return {
        name,
        internalContentStageName,
        assignedTo: defaultAssignees || [],
        deadline: null,
        deadlineDays,
        assigneeSelectionType
      };
    });

    // Adding stages and filtering required fields from topic
    topics =
      topics?.map((topic: any) => {
        return {
          topicId: topic._id,
          title: topic.title,
          postId: topic.postId,
          author: null,
          freelancerInvited: false,
          stages: _.cloneDeep(stages),
          isSocial: [channelTypes.TWITTER, channelTypes.LINKEDIN].includes(
            topic.channel?.type
          )
        };
      }) || [];

    this.setState({
      loading: false,
      topics
    });
  };

  // Assigning topics
  validateAndAssignTopics = () => {
    let { topics } = this.state;

    let assigneeErrors = []; // Contains assignee errors, [{topicIndex: {"field_name": "Error message" }}, ...]
    let deadlineErrors = []; // Contains deadline errors, [{topicIndex: {"field_name": "Error message" }}, ...]
    // Looping all topics
    for (let topicIndex = 0; topicIndex < topics.length; topicIndex++) {
      assigneeErrors[topicIndex] = {};
      deadlineErrors[topicIndex] = {};
      // Looping through all stages to make sure the necessary deadlines and assignees are filled
      for (let index = 0; index < topics[topicIndex].stages.length; index++) {
        let { internalContentStageName, assignedTo, deadline } =
          topics[topicIndex].stages[index];

        // If Approvers are not selected, push an error to assigneeErrors with stage name
        if (
          internalContentStageName === topicStatus.IN_REVIEW &&
          !assignedTo?.length &&
          !topics[topicIndex].isSocial
        ) {
          assigneeErrors[topicIndex][topicStatus.IN_REVIEW] =
            "Approvers are required to approve your post.";
        }

        // *** Deadlines validation ***
        // If Deadlines are not mentioned, push an error to deadlineErrors with stage name
        // Review deadline validation
        if (
          internalContentStageName === topicStatus.IN_REVIEW &&
          (!deadline || !moment(deadline).isValid()) &&
          !topics[topicIndex].isSocial
        ) {
          deadlineErrors[topicIndex][topicStatus.IN_REVIEW] =
            "Please enter a review deadline before the targeted publish date.";
        }

        // Publish deadline validation
        if (
          internalContentStageName === topicStatus.PUBLISHED &&
          (!deadline || !moment(deadline).isValid())
        ) {
          deadlineErrors[topicIndex][topicStatus.PUBLISHED] =
            "Please enter a target publish date.";
        }
      }
    }

    this.setState({
      assigneeErrors,
      deadlineErrors
    });

    // Return if any required deadline or approvers missing
    if (
      assigneeErrors.some(
        (assigneeError) => Object.keys(assigneeError || {}).length
      ) ||
      deadlineErrors.some(
        (deadlineError) => Object.keys(deadlineError || {}).length
      )
    ) {
      return;
    }

    // Otherwise Save
    this.assignTopics();
  };

  assignTopics = () => {
    let { topics } = this.state;

    // Deep cloning all topics to modify author and assignees
    // Author and assignee contain team references, we require client references for storage.
    let topicsCopy = _.cloneDeep(topics);
    topicsCopy = topicsCopy.map((topic: any) => {
      topic.author = topic.author?.client || null;
      topic.stages = topic.stages.map((stage: any) => {
        stage.assignedTo = stage.assignedTo?.map(
          (assignee: any) => assignee.client
        );
        return stage;
      });

      return topic;
    });

    api.assignBulkTopics(this.props.publicationId, topicsCopy, (res: any) => {
      if (res.status === 200) {
        this.props.onBulkAssign();
      } else {
        notify.show(
          res.status === 400 ? res.data : "Unable to assign your ideas",
          "error"
        );
      }
    });
  };

  topicAssigneeChange = (value: any, topicIndex: any) => {
    let { publicationId, teammates } = this.props;

    if (value && value._id === -99) {
      let freelancersSize = teammates.filter(
        (teammate: any) => teammate.role === teamRoles.FREELANCER
      ).length;

      if (
        isLimitExceeded(
          features.FREELANCERS,
          this.context,
          publicationId,
          freelancersSize
        )
      ) {
        this.setState({ showUpgradeDialog: true });
        return;
      }

      //Freelancer
      this.setState({
        showInviteFreelancer: true,
        topicIndex: topicIndex
      });
      return;
    }

    let { topics } = this.state;
    topics[topicIndex].author = value;
    topics[topicIndex].freelancerInvited = false;
    this.setState({
      topics
    });
  };

  setFreelancerInvited = () => {
    let { topics, topicIndex } = this.state;
    topics[topicIndex].freelancerInvited = true;
    this.setState({
      showInviteFreelancer: false,
      topics
    });
  };

  sentFreelancerInvite = (freelancer: any) => {
    let { topics, topicIndex } = this.state;
    topics[topicIndex].author = freelancer;

    this.setState({
      showInviteFreelancer: false,
      topics
    });
  };

  cancelInviteFreelancer = () => {
    this.setState({
      showInviteFreelancer: false
    });
  };

  // Changing stage assignees
  stageOwnerChanged = (value: any, topicIndex: any, stageIndex: any) => {
    let { topics, assigneeErrors } = this.state;

    // Delete field error
    delete assigneeErrors[topicIndex]?.[
      topics[topicIndex].stages[stageIndex].internalContentStageName
    ];

    topics[topicIndex].stages[stageIndex].assignedTo = value;
    this.setState({ topics, assigneeErrors });
  };

  showDatePickerDialog = (
    event: any,
    topicIndex: any,
    stageIndex: any,
    deadline: any
  ) => {
    this.setState({
      showDatePicker: true,
      eventElement: event.currentTarget,
      topicIndex,
      deadline: deadline,
      stageIndex
    });
  };

  // Changing stage deadline
  stageDeadlineChanged = (date: any) => {
    let { topics, topicIndex, stageIndex, deadlineErrors, excludeStages } =
      this.state;
    if (topics[topicIndex].isSocial) {
      topics[topicIndex].stages[stageIndex].deadline = date;
      this.setState({
        topics,
        showDatePicker: false
      });
      return;
    }

    let stages = topics[topicIndex].stages;

    // Current stage deadline should be after previous stage deadline and before next stage deadline
    let nextStage = this.getNextStage(stages, stageIndex); //Fetching previous stage for deadline validation
    let previousStage = this.getPreviousStage(stages, stageIndex); // Fetching next stage for deadline validation

    // Current stage deadline should be before next stage deadline
    if (nextStage?.deadline && isAfter(date, nextStage.deadline)) {
      notify.show(
        `Please enter a ${stages[stageIndex].name} deadline before the ${nextStage.name} deadline.`,
        "error"
      );
      this.setState({
        showDatePicker: false
      });
      return;
    }

    // Current stage deadline should be after previous stage deadline
    if (previousStage?.deadline && isBefore(date, previousStage.deadline)) {
      notify.show(
        `Please enter a ${stages[stageIndex].name} deadline after the ${previousStage.name} deadline.`,
        "error"
      );
      this.setState({
        showDatePicker: false
      });
      return;
    }

    // Update deadline
    topics[topicIndex].stages[stageIndex].deadline = date;

    //If publish date is update, auto update all other deadlines
    if (stageIndex === stages.length - 1) {
      let contentStages = topics[topicIndex].stages;
      let nextStageDeadline = date;
      for (let index = contentStages.length - 1; index >= 0; index--) {
        let { deadlineDays, internalContentStageName } = contentStages[index];
        if (excludeStages.includes(internalContentStageName)) {
          continue;
        }
        if (
          internalContentStageName === topicStatus.SCHEDULED ||
          stageIndex === index
        ) {
          continue;
        }
        // Generate deadline from next stage deadline
        nextStageDeadline = getDateFromDeadlineDays(
          nextStageDeadline,
          deadlineDays
        );

        topics[topicIndex].stages[index].deadline = nextStageDeadline;
      }
    }
    // Remove if any errors
    delete deadlineErrors[topicIndex]?.[
      topics[topicIndex].stages[stageIndex].internalContentStageName
    ];

    this.setState({
      topics,
      showDatePicker: false,
      deadlineErrors
    });
  };

  // Returns the stage after the current stage.
  getNextStage = (stages: any, stageIndex: any) => {
    // If its is a last stage
    if (stageIndex === stages?.length - 1) {
      return null;
    }

    // Looping and checking next stages
    for (let index = stageIndex + 1; index < stages.length; index++) {
      // If currents stage is not required, move on to next stage
      if (
        !stages[index]?.deadline ||
        stages[index].internalContentStageName === topicStatus.SCHEDULED
      ) {
        continue;
      }

      return stages[index];
    }

    return null;
  };

  // Returns the stage before the current stage.
  getPreviousStage = (stages: any, stageIndex: any) => {
    // If its is a first stage
    if (stageIndex === 0) {
      return null;
    }

    // Looping and checking previous stages
    for (let index = stageIndex - 1; index >= 0; index--) {
      // If currents stage is not required, move on to previous stage
      if (
        !stages[index]?.deadline ||
        stages[index].internalContentStageName === topicStatus.SCHEDULED
      ) {
        continue;
      }
      return stages[index];
    }

    return null;
  };

  // Returns final review approvers picker
  getFinalReviewApprovers = (topicIndex: any) => {
    let { classes, teammates } = this.props;

    let { topics, assigneeErrors } = this.state;

    let stageIndex = topics[topicIndex].stages.findIndex(
      (stage: any) => stage.internalContentStageName === topicStatus.IN_REVIEW
    );

    let stage = topics[topicIndex].stages[stageIndex];

    return (
      <TableCell className={classes.tableCell}>
        <Autocomplete
          disabled={topics[topicIndex].isSocial}
          multiple
          value={stage.assignedTo || []}
          onChange={(event, newValue) =>
            this.stageOwnerChanged(newValue, topicIndex, stageIndex)
          }
          options={teammates}
          getOptionLabel={(option) =>
            typeof option === "string"
              ? option
              : option.client.name || option.client.email
          }
          limitTags={2}
          renderTags={(options, getTagProps) =>
            options.map((option, index) => (
              <StyledUserChip
                avatar={<Avatar src={option.client?.profilePic} />}
                label={
                  typeof option === "string"
                    ? option
                    : option.client.name || option.client.email
                }
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <StyledInput
              {...params}
              variant="outlined"
              disabled={topics[topicIndex].isSocial}
            />
          )}
          renderOption={(props, option, { selected }) => {
            return (
              <Box key={option._id} {...props}>
                <Avatar
                  src={option.client?.profilePic}
                  className={classes.autoCompleteAvatar}
                />

                <Typography className={classes.name}>
                  {option.client.name || option.email}
                </Typography>
                {option.role === teamRoles.FREELANCER && (
                  <StyledChip
                    variant="gray"
                    label="freelancer"
                    className={classes.name}
                  />
                )}
              </Box>
            );
          }}
        />

        <FormHelperText
          style={{
            marginLeft: 14,
            color: colors.helperText,
            margin: 0,
            padding: "0px 14px"
          }}
        >
          {assigneeErrors[topicIndex]?.[stage.internalContentStageName] || ""}
        </FormHelperText>
      </TableCell>
    );
  };

  // Returns Final/Published deadline picker
  getFinalAndPublishedDeadline = (topicIndex: any, status: any) => {
    let { classes } = this.props;

    let { topics, deadlineErrors } = this.state;

    let stageIndex = topics[topicIndex].stages.findIndex(
      (stage: any) => stage.internalContentStageName === status
    );

    let stage = topics[topicIndex].stages[stageIndex];

    return (
      <TableCell
        className={
          status === topicStatus.PUBLISHED
            ? classes.verticalSeparator
            : classes.tableCell
        }
      >
        <StyledInput
          fullWidth
          disabled={
            topics[topicIndex].isSocial && status === topicStatus.IN_REVIEW
          }
          size="large"
          readOnly={true}
          error={!!deadlineErrors[topicIndex]?.[stage.internalContentStageName]}
          helperText={
            deadlineErrors[topicIndex]?.[stage.internalContentStageName] || ""
          }
          value={stage.deadline ? formatForHistoryPanel(stage.deadline) : ""}
          InputProps={{
            endAdornment: <CalendarIcon alt="calendarIcon" />
          }}
          onClick={(event: any) => {
            this.showDatePickerDialog(
              event,
              topicIndex,
              stageIndex,
              stage.deadline
            );
          }}
        />
      </TableCell>
    );
  };

  // Toggle Optional stage option
  toggleOptionalCheckboxes = (
    includeStage: any,
    stagename: any,
    stageIndex: any
  ) => {
    let { excludeStages, topics } = this.state;

    // If stage is not required, remove deadline and assignees
    if (!includeStage) {
      excludeStages.push(stagename);
      for (let topic of topics) {
        topic.stages[stageIndex].deadline = null;
        topic.stages[stageIndex].assignedTo = null;
      }
    } else {
      let index = excludeStages.indexOf(stagename);
      if (index > -1) {
        excludeStages.splice(index, 1);
      }
    }

    this.setState({ excludeStages });
  };

  getUpgradeMessage = () => {
    let { publicationId } = this.state;

    let limit = getFeatureLimit(
      features.FREELANCERS,
      this.context,
      publicationId
    );

    if (limit === 0) {
      return `Upgrade your plan to invite freelancers to your workspace.`;
    }

    return `Your workspace exceeded the limit of ${limit} freelancer${handleSingular(
      limit
    )}. Upgrade to a higher plan to invite more team members.`;
  };

  render() {
    let { classes, open, onCloseDialog, teammates, publicationId } = this.props;
    let {
      loading,

      topics,

      showInviteFreelancer,

      topicIndex,

      deadline,

      showDatePicker,

      eventElement,

      assigneeErrors,

      deadlineErrors,

      excludeStages,

      showUpgradeDialog
    } = this.state;

    return (
      <Fragment>
        <StyledDialog
          open={open}
          maxWidth="lg"
          title="Assign owners and dates to these ideas"
          contentStyle={{ paddingTop: 0 }}
          body={
            <div>
              {loading && <Loading />}
              {!loading && (
                <TableContainer className={classes.tableContainer}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: 250 }}>Idea</TableCell>

                        <TableCell style={{ minWidth: 250 }}>Owner</TableCell>

                        <TableCell style={{ minWidth: 250 }}>
                          Final Review Owner
                          <span className={classes.mandatory}>*</span>
                        </TableCell>

                        <TableCell
                          style={{
                            minWidth: 250,
                            borderRight: " 1px solid #BEBEC5"
                          }}
                        >
                          Published Deadline
                          <span className={classes.mandatory}>*</span>
                        </TableCell>

                        <TableCell style={{ minWidth: 250 }}>
                          Final Review Deadline
                          <span className={classes.mandatory}>*</span>
                        </TableCell>
                        {topics[0].stages.map((stage: any) => {
                          if (
                            [
                              topicStatus.IN_REVIEW,
                              ...ASSIGNEE_NOT_REQUIRED_STAGES
                            ].includes(stage.internalContentStageName) ||
                            [
                              assigneeSelectionTypes.SAME_AS_STAGE,
                              assigneeSelectionTypes.PROJECT_OWNER
                            ].includes(stage.assigneeSelectionType)
                          ) {
                            return;
                          }
                          return (
                            <TableCell
                              key={stage.internalContentStageName}
                              style={{ minWidth: 250, lineHeight: 1 }}
                            >
                              {`${stage.name} Owner`}

                              <br />

                              <span className={classes.optional}>Optional</span>
                            </TableCell>
                          );
                        })}

                        {topics[0].stages.map((stage: any, stageIndex: any) => {
                          if (
                            [
                              topicStatus.IN_REVIEW,
                              topicStatus.SCHEDULED,
                              topicStatus.PUBLISHED
                            ].includes(stage.internalContentStageName)
                          ) {
                            return "";
                          }

                          return (
                            <TableCell
                              key={stage.internalContentStageName}
                              style={{ minWidth: 250, lineHeight: 1 }}
                            >
                              <StyledCheckbox
                                style={{ margin: `0px 0px 0px ${space.XS}` }}
                                checked={
                                  !excludeStages.includes(
                                    stage.internalContentStageName
                                  )
                                }
                                onChange={(event: any) =>
                                  this.toggleOptionalCheckboxes(
                                    event.target.checked,
                                    stage.internalContentStageName,
                                    stageIndex
                                  )
                                }
                              />
                              {`${stage.name} Deadline`}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {topics.map((eachTopic: any, topicIndex: any) => {
                        return (
                          <TableRow key={topicIndex}>
                            <TableCell className={classes.tableCell}>
                              <Typography>
                                {truncateStringAndAddEllipses(
                                  eachTopic.title,
                                  TITLE_LENGTH
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              <Autocomplete
                                disabled={eachTopic.isSocial}
                                onChange={(event, newValue) => {
                                  this.topicAssigneeChange(
                                    newValue,
                                    topicIndex
                                  );
                                }}
                                options={[
                                  {
                                    _id: -99,
                                    email: "Invite freelancer"
                                  },
                                  ...teammates
                                ]}
                                value={
                                  eachTopic.freelancerInvited
                                    ? { email: "Freelancer with invite link" }
                                    : eachTopic.author
                                }
                                getOptionLabel={(option) =>
                                  typeof option === "string"
                                    ? option
                                    : option.email
                                }
                                renderInput={(params) => (
                                  <StyledInput
                                    fullWidth
                                    size="medium"
                                    variant="outlined"
                                    placeholder="Owner"
                                    {...params}
                                  />
                                )}
                                renderOption={(props, option, { selected }) => {
                                  if (option._id === -99) {
                                    return (
                                      <div
                                        className={
                                          classes.inviteButtonContainer
                                        }
                                        {...props}
                                      >
                                        <AddIcon color={"primary"} />

                                        <Typography
                                          className={classes.inviteLabel}
                                        >
                                          {option.email}
                                        </Typography>
                                      </div>
                                    );
                                  }
                                  return (
                                    <Box key={option._id} {...props}>
                                      <Avatar
                                        src={option.client?.profilePic}
                                        className={classes.autoCompleteAvatar}
                                      />

                                      <Typography className={classes.name}>
                                        {option.client.name || option.email}
                                      </Typography>
                                      {option.role === teamRoles.FREELANCER && (
                                        <StyledChip
                                          size="medium"
                                          label="freelancer"
                                          className={classes.name}
                                        />
                                      )}
                                    </Box>
                                  );
                                }}
                              />
                            </TableCell>
                            {this.getFinalReviewApprovers(topicIndex)}
                            {this.getFinalAndPublishedDeadline(
                              topicIndex,
                              topicStatus.PUBLISHED
                            )}
                            {this.getFinalAndPublishedDeadline(
                              topicIndex,
                              topicStatus.IN_REVIEW
                            )}

                            {eachTopic.stages.map(
                              (stage: any, stageIndex: any) => {
                                if (
                                  [
                                    topicStatus.IN_REVIEW,
                                    ...ASSIGNEE_NOT_REQUIRED_STAGES
                                  ].includes(stage.internalContentStageName) ||
                                  [
                                    assigneeSelectionTypes.SAME_AS_STAGE,
                                    assigneeSelectionTypes.PROJECT_OWNER
                                  ].includes(stage.assigneeSelectionType)
                                ) {
                                  return;
                                }
                                return (
                                  <TableCell className={classes.tableCell}>
                                    <Autocomplete
                                      disabled={eachTopic.isSocial}
                                      multiple
                                      value={stage.assignedTo || []}
                                      onChange={(event, newValue) =>
                                        this.stageOwnerChanged(
                                          newValue,
                                          topicIndex,
                                          stageIndex
                                        )
                                      }
                                      options={teammates}
                                      getOptionLabel={(option) =>
                                        typeof option === "string"
                                          ? option
                                          : option.client.name ||
                                            option.client.email
                                      }
                                      limitTags={2}
                                      renderTags={(options, getTagProps) =>
                                        options.map((option, index) => (
                                          <StyledUserChip
                                            avatar={
                                              <Avatar
                                                src={option.client?.profilePic}
                                              />
                                            }
                                            label={
                                              typeof option === "string"
                                                ? option
                                                : option.client.name ||
                                                  option.client.email
                                            }
                                            {...getTagProps({ index })}
                                          />
                                        ))
                                      }
                                      renderInput={(params) => (
                                        <StyledInput
                                          {...params}
                                          variant="outlined"
                                        />
                                      )}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => {
                                        return (
                                          <Box key={option._id} {...props}>
                                            <Avatar
                                              src={option.client?.profilePic}
                                              className={
                                                classes.autoCompleteAvatar
                                              }
                                            />

                                            <Typography
                                              className={classes.name}
                                            >
                                              {option.client.name ||
                                                option.email}
                                            </Typography>
                                            {option.role ===
                                              teamRoles.FREELANCER && (
                                              <StyledChip
                                                variant="gray"
                                                label="freelancer"
                                                className={classes.name}
                                              />
                                            )}
                                          </Box>
                                        );
                                      }}
                                    />

                                    <FormHelperText
                                      style={{
                                        marginLeft: 14,
                                        color: colors.helperText,
                                        margin: 0,
                                        padding: "0px 14px"
                                      }}
                                    >
                                      {assigneeErrors[topicIndex]?.[
                                        stage.internalContentStageName
                                      ] || ""}
                                    </FormHelperText>
                                  </TableCell>
                                );
                              }
                            )}

                            {eachTopic.stages.map(
                              (stage: any, stageIndex: any) => {
                                if (
                                  [
                                    topicStatus.IN_REVIEW,
                                    topicStatus.SCHEDULED,
                                    topicStatus.PUBLISHED
                                  ].includes(stage.internalContentStageName)
                                ) {
                                  return "";
                                }
                                return (
                                  <TableCell className={classes.tableCell}>
                                    <StyledInput
                                      disabled={eachTopic.isSocial}
                                      fullWidth
                                      size="large"
                                      readOnly={true}
                                      error={
                                        !!deadlineErrors[topicIndex]?.[
                                          stage.internalContentStageName
                                        ]
                                      }
                                      helperText={
                                        deadlineErrors[topicIndex]?.[
                                          stage.internalContentStageName
                                        ] || ""
                                      }
                                      value={
                                        stage.deadline
                                          ? formatForHistoryPanel(
                                              stage.deadline
                                            )
                                          : ""
                                      }
                                      InputProps={{
                                        endAdornment: (
                                          <CalendarIcon alt="calendarIcon" />
                                        )
                                      }}
                                      onClick={(event: any) => {
                                        this.showDatePickerDialog(
                                          event,
                                          topicIndex,
                                          stageIndex,
                                          stage.deadline
                                        );
                                      }}
                                    />
                                  </TableCell>
                                );
                              }
                            )}

                            <CustomFlatPicker
                              publicationId={publicationId}
                              anchorEl={eventElement}
                              open={showDatePicker}
                              value={deadline}
                              disablePast={true}
                              onClose={(date) => {
                                this.stageDeadlineChanged(date);
                              }}
                              type={datePickerDefault.DEADLINE}
                            />
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {showInviteFreelancer && (
                <InviteFreelancerDialog
                  showInvite={showInviteFreelancer}
                  handleSucess={this.sentFreelancerInvite}
                  handleCancel={this.cancelInviteFreelancer}
                  publicationId={publicationId}
                  topicId={topics[topicIndex].topicId}
                  setFreelancerInvited={this.setFreelancerInvited}
                  teammates={teammates}
                />
              )}
            </div>
          }
          successButtonName="Bulk Assign"
          successCallback={this.validateAndAssignTopics}
          cancelCallback={onCloseDialog}
        />
        {showUpgradeDialog && (
          <UpgradePlanDialog
            open={showUpgradeDialog}
            body={this.getUpgradeMessage()}
            cancelCallback={() => {
              this.setState({ showUpgradeDialog: false });
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(BulkAssignTopics);
