// @ts-nocheck
import { grey } from "@mui/material/colors";
import colors from "../../Themes/Colors";
import { space } from "../../Config/theme";

const styles = (theme: any) => ({
  paper: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    boxShadow: "none",
    marginTop: space.L
  },

  reducedPaperMargin: {
    boxShadow: "none"
  },

  section: {
    padding: "30px 0px 0px 0px",
    justifyContent: "space-between"
  },

  sectionReviewScreen: {
    paddingRight: 60
  },

  headerTitle: {
    width: "100%",
    marginBottom: space.SMALL,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  header: {
    width: "100%",
    marginBottom: space.SMALL,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  headerText: {
    fontWeight: "bold",
    color: colors.black,
    marginLeft: 10
  },

  draftDiv: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 10
    }
  },

  facebookDraft: {
    position: "absolute"
  },

  accountSection: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 50
  },

  cardTitle: {
    fontWeight: "900",
    fontSize: 16,
    fontFamily: "Poppins"
  },

  cardSubtitle: {
    color: colors.fontSecondary,
    marginBottom: space.MEDIUM,
    fontSize: 14,
    fontFamily: "Poppins"
  },

  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    marginTop: 0,
    padding: 0,
    width: "100%",
    color: colors.fontPrimary,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontPrimary
    }
  },

  mentionText: {
    color: colors.primary,
    textDecoration: "none"
  },

  textContainer: {
    marginTop: 10
  },

  postUrl: {
    backgroundColor: grey[100],
    color: colors.fontSecondary,
    borderRadius: 5,
    padding: 10,
    paddingRight: 3,
    fontFamily: "Inconsolata",
    width: "100%",
    minHeight: 50,
    fontSize: 18
  },

  anotherAccount: {
    padding: "30px 0px"
  },

  imageIconContainer: {
    background: "white",
    padding: 0,
    marginTop: 10
  },

  closeIcon: {
    position: "absolute",
    top: -15,
    right: 5,
    background: colors.primary,
    color: colors.white,
    padding: 4,
    "&:hover": {
      backgroundColor: colors.primary,
      color: colors.white
    }
  },

  dialogCloseIcon: {
    top: 8,
    right: 8,
    position: "absolute"
  },

  facebookPostCloseIcon: {
    right: 0,
    position: "absolute"
  },

  connectAccountSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px 20px",
    flexDirection: "column"
  },

  connectAccountText: {
    color: colors.fontSecondary
  },

  connectAccountImage: {
    width: 250,
    height: 250,
    marginBottom: 50
  },

  shareToFacebook: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 10
    }
  },

  shareToFacebookButton: {
    position: "absolute",
    right: 5,
    background: colors.primary,
    color: colors.white
  },

  facebookIcon: {
    color: colors.facebook,
    fontSize: 24,
    margin: "auto 0px"
  },

  facebookPostImageContainer: {
    display: "inline-flex",
    position: "relative",
    marginTop: 20,
    paddingRight: 20,
    width: 200,
    height: 200
  },

  facebookPostImage: {
    width: "100%",
    objectFit: "cover"
  },

  textAreaOutline: {
    width: "100%",
    resize: "vertical",
    marginTop: 10
  },

  editableLabel: {
    color: colors.fontSecondary,
    fontSize: 14
  },

  editableLabelDiv: {
    padding: "20px 40px"
  }
});

export default styles;
