// @ts-nocheck
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  paper: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    padding: 20,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    marginBottom: 10,
    marginTop: 10
  },

  margin10: {
    margin: 10
  },

  marginRight10: {
    marginRight: 10
  },

  cardTitle: {
    fontWeight: "bold",
    fontSize: 20
  },

  headerStyle: {
    height: "10%",
    [theme.breakpoints.up("xs")]: {
      padding: 16,
      marginTop: 10
    },
    fontWeight: "900"
  },

  exportButton: {
    marginRight: 15
  },

  text: {
    color: colors.fontSecondary
  },

  searchBar: {
    borderRadius: 7,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    width: "100%"
  },

  photographer: {
    color: colors.fontSecondary,
    marginBottom: 0
  },

  link: {
    textDecoration: "none",
    color: colors.fontSecondary,
    "&:hover": {
      color: colors.primary,
      textDecoration: "underline"
    }
  },

  uploadingText: {
    textAlign: "center",
    color: colors.primary
  },

  image: {
    width: "100%",
    height: 100,
    backgroundSize: "cover",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: 80
    },
    [theme.breakpoints.down("sm")]: {
      height: 60
    }
  }
});
export default styles;
