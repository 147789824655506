// @ts-nocheck
import colors from "../../Themes/Colors";
import { space } from "../../Config/theme";

const styles = (theme: any) => ({
  container: {
    padding: 0
  },

  marginTop10: {
    marginTop: 10
  },

  cardSubtitle: {
    color: colors.fontSecondary
  },

  subTitle: {
    marginTop: 10,
    marginBottom: 10,
    color: colors.fontSecondary
  },

  subtitleDescription: {
    margin: "10px 0px 20px 0px"
  },

  defaultAuthorAvatar: {
    marginLeft: space.SMALL
  },

  defaultAuthorAdornment: {
    marginRight: 0
  },

  instruction: {
    width: "100%",
    marginTop: 10,
    color: colors.fontSecondary
  },

  width100: {
    width: "100%"
  },

  marginTop20: {
    marginTop: 20
  },

  horizontalSpacingL: {
    marginTop: 24
  },

  marginTop30: {
    marginTop: 30
  },

  dialogBody: {
    margin: "20px 0px"
  },

  marginTop8: {
    marginTop: 8
  },

  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    width: "100%",
    color: colors.fontInput,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontInput
    }
  },

  saveGrid: {
    [theme.breakpoints.down("md")]: {
      marginTop: 10
    },
    margin: "auto auto"
  },

  closeIcon: {
    color: colors.fontSecondary
  },

  secondaryButtons: {
    marginTop: 20,
    textAlign: "center"
  },

  closeButtonContainer: {
    paddingLeft: 40
  },

  draggable: {
    color: colors.fontSecondary,
    "&:hover": {
      cursor: "move"
    }
  },

  instructionChip: {
    backgroundColor: colors.writeIconBackground,
    color: colors.writeIconFont,
    fontSize: 11,
    marginLeft: 20,
    marginTop: -10
  },

  inputChip: {
    backgroundColor: colors.greenChipBackground,
    color: colors.greenChipFont,
    fontSize: 11,
    marginLeft: 20,
    marginTop: -10
  },

  chipAvatar: {
    width: 20,
    height: 20,
    margin: 5
  },

  deadlinesGrid: {
    display: "flex"
  },

  deadlineText: {
    margin: "auto 0px"
  }
});
export default styles;
