// @ts-nocheck
import { FormControl, Typography } from "@mui/material";
import { useState } from "react";
import { space } from "../Config/theme";
import StyledDialog from "../design/components/StyledDialog";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import Api from "../Services/Api";
import colors from "../Themes/Colors";
import { notify } from "./CustomNotifications";

const api = Api.create();

export default function HubSpotConnect({
  open,
  publicationId,
  blogs,
  hubspotId,
  onSuccess,
  onClose,
  ...props
}: any) {
  let [selectedBlog, setSelectedBlog] = useState(
    props.selectedBlog
      ? blogs.find((blog: any) => blog.id === parseInt(props.selectedBlog.id))
      : ""
  );
  let [saveInprogress, setSaveInprogress] = useState(false);

  /**
   * Validate and save HubSpot config
   * @returns
   */
  function saveHubspotConfig() {
    if (saveInprogress) {
      return;
    }

    if (!selectedBlog) {
      notify.show("Select a blog", "error");
      return;
    }

    setSaveInprogress(true);

    api.saveHubspotIntegrationDetails(
      publicationId,
      selectedBlog,
      hubspotId,
      (res: any) => {
        if (res.status === 200) {
          setSaveInprogress(false);
          onSuccess();
        } else {
          setSaveInprogress(false);
          notify.show("Oops, We were unable to save your changes.", "error");
        }
      }
    );
  }

  return (
    <StyledDialog
      open={open}
      title="Set up your HubSpot integration"
      body={
        <div style={{ padding: `0px ${space.MEDIUM}` }}>
          <Typography
            variant="bodyl"
            paragraph
            style={{ marginTop: space.SMALL, color: colors.fontSecondary }}
          >
            BlOG
          </Typography>

          <FormControl variant="outlined" size="medium" fullWidth>
            <StyledSelect
              value={selectedBlog}
              variant="outlined"
              onChange={(event: any) => {
                setSelectedBlog(event.target.value);
              }}
            >
              {blogs?.map((blog: any, index: any) => (
                <StyledMenuItem value={blog} key={index}>
                  {blog.name}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </div>
      }
      successButtonName="Done"
      successCallback={saveHubspotConfig}
      cancelCallback={onClose}
    />
  );
}
