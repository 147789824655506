// @ts-nocheck
import { Component } from "react";

import OnboardingBusinessPublication from "./OnboardingBusinessPublication";

import OnboardingPayment from "./OnboardingPayment";

const steps = {
  PAYMENT: "payment",
  WORKSPACE: "workspace"
};

class CreateWorkspaceDialog extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      currentStep: steps.PAYMENT
    };
  }

  gotoWorkSpaceCreation = () => {
    this.setState({
      currentStep: steps.WORKSPACE
    });
  };

  render() {
    let { currentStep } = this.state;

    if (currentStep === steps.PAYMENT) {
      return (
        <OnboardingPayment
          hideStepper={true}
          next={this.gotoWorkSpaceCreation}
        />
      );
    }

    return (
      <OnboardingBusinessPublication
        isDialog={true}
        onboarding={false}
        next={this.props.publicationSaved}
      />
    );
  }
}

export default CreateWorkspaceDialog;
