// @ts-nocheck
import { grey } from "@mui/material/colors";
import colors from "../../Themes/Colors";
import { space } from "../../Config/theme";

const styles = (theme: any) => ({
  errorSection: {
    padding: "0px 10px",
    width: "60%",
    margin: "auto"
  },

  sectionHeading: {
    fontWeight: "900",
    fontSize: 16,
    fontFamily: "Poppins"
  },

  sectionHelper: {
    color: colors.fontSecondary,
    marginBottom: space.MEDIUM,
    fontSize: 14,
    fontFamily: "Poppins"
  },

  accountDropDown: {
    display: "block",
    width: "70%"
  },

  paper: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    boxShadow: "none",
    marginTop: space.L
  },

  reducedPaperMargin: {
    boxShadow: "none"
  },

  headerTitle: {
    width: "100%",
    marginBottom: space.MEDIUM,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  header: {
    width: "100%",
    marginBottom: space.MEDIUM,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  headerText: {
    fontWeight: "bold",
    color: colors.black,
    margin: "5px 10px auto 10px"
  },

  draftDiv: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 10
    }
  },

  instagramDraft: {
    position: "absolute"
  },

  section: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 50
  },

  mentionText: {
    color: colors.primary,
    textDecoration: "none"
  },

  postUrl: {
    backgroundColor: grey[100],
    color: colors.fontSecondary,
    borderRadius: 5,
    padding: 10,
    paddingRight: 3,
    fontFamily: "Inconsolata",
    width: "100%",
    minHeight: 50,
    fontSize: 18,
    marginTop: space.SMALL
  },

  anotherAccount: {
    padding: "30px 0px"
  },

  avatar: {
    width: 30,
    height: 30,
    display: "inline-flex"
  },

  itemName: {
    marginLeft: 10,
    display: "inline-flex",
    verticalAlign: "bottom",
    margin: "10px"
  },

  threadImageContainer: {
    display: "inline-flex",
    position: "relative",
    marginTop: 20,
    paddingRight: 20,
    marginLeft: 10
  },

  threadImage: {
    width: 200,
    objectFit: "cover",
    position: "relative",
    borderRadius: 4
  },

  closeIcon: {
    position: "absolute",
    top: -10,
    right: -10,
    background: colors.primary,
    color: colors.white,
    padding: 4,
    zIndex: 10,
    "&:hover": {
      backgroundColor: colors.primary,
      color: colors.white
    }
  },

  connectAccountImage: {
    width: 250,
    height: 250,
    marginBottom: 50
  },

  charCount: {
    textAlign: "right",
    color: colors.fontSecondary,
    marginTop: 10,
    fontSize: 14
  },

  marginAuto: {
    margin: "auto"
  },

  connectMessage: {
    color: colors.fontSecondary,
    margin: "auto",
    marginBottom: 15
  },

  headerIcon: {
    color: colors.linkedin,
    fontSize: 26
  },

  postButtonSection: {
    display: "flex",
    alignItems: "center"
  },

  postButton: {
    position: "absolute",
    right: 5
  },

  marginRight10: {
    marginRight: 10
  },

  menuItem: {
    display: "inline-flex",
    width: "100%"
  },

  mentions: {
    color: colors.primary
  },

  hashtags: {
    color: colors.red300,
    padding: "0px 2px",
    backgroundColor: colors.hover
  },

  contentBody: {
    display: "block"
  },

  imagePickerIcon: {
    fontSize: 20,
    color: colors.fontSecondary
  },

  comments: {
    display: "block"
  },

  scheduleDropDown: {
    width: "70%"
  },

  resetIcon: {
    float: "right"
  },

  input: {
    width: "70%",
    backgroundColor: colors.inputBackground,
    border: "none",
    marginTop: 0,
    padding: 0,
    color: colors.fontPrimary,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontPrimary
    }
  },

  selectedAccount: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    height: 20
  },

  userName: {
    marginLeft: 10
  },

  textAreaOutline: {
    width: "100%",
    resize: "vertical",
    display: "block"
  },

  imagesGrid: {
    height: 240,
    width: "100%",
    display: "inline-flex"
  },

  postImageContainer: {
    height: 240,
    width: "100%",
    display: "inline-flex",
    flexDirection: "row",
    overflow: "overlay"
  },

  postImagePicker: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 200,
    minWidth: 200,
    height: 200,
    flexDirection: "column",
    backgroundColor: grey[200],
    borderRadius: 4,
    margin: "auto 0px",
    outline: "1px dashed " + grey[600],
    "&:hover": {
      cursor: "pointer",
      backgroundColor: grey[300]
    }
  },

  addIcon: {
    color: colors.dimGrey,
    margin: "auto"
  },

  uploadImageText: {
    color: colors.dimGrey,
    margin: "auto",
    width: 50,
    textAlign: "center"
  },

  postImage: {
    display: "inline-flex",
    position: "relative",
    width: 200,
    height: 200,
    margin: "auto 0px"
  },

  errorList: {
    backgroundColor: colors.errorBackground,
    borderRadius: 4
  },

  errorText: {
    color: colors.darkRed
  },

  circularProgress: {
    margin: "5%"
  },

  editableLabel: {
    color: colors.fontSecondary,
    fontSize: 14
  },

  editableLabelDiv: {
    padding: 20
  }
});

export default styles;
