// @ts-nocheck
import isImage from "is-image";

import isAbsoluteUrl from "is-absolute-url";
import path from "path";
import { notify } from "../Components/CustomNotifications";
import Compressor from "compressorjs";
import gifsicle from "gifsicle-wasm-browser";

const request = require("sync-request");
const urlParse = require("url").parse;
const IMAGE_MAX_WIDTH = 1920;

const PICTURES = {
  UNSPLASH_DOMAIN: "https://images.unsplash.com",
  FIREBASE_DOMAIN: "https://firebasestorage.googleapis.com",
  LETTERDROP_CDN_DOMAIN: "https://cdn.letterdrop.co",
  PUBLICATION_PICTURE: "publicationPicture",
  PROFILE_PICTURE: "profilePicture",
  POST_PICTURES: "postPictures",
  PICTURES: "pictures"
};

export function handleImageLoadError(event: any, picture: any) {
  if (event.target && !event.target.getAttribute("data-processed")) {
    event.target.setAttribute("data-processed", true);
    event.target.setAttribute("src", picture);
    event.target.setAttribute("srcSet", "");
  }
}

export function isFirebaseImage(imageUrl: any) {
  if (!imageUrl) {
    return false;
  }

  return (
    imageUrl.startsWith(PICTURES.FIREBASE_DOMAIN) ||
    imageUrl.startsWith(PICTURES.LETTERDROP_CDN_DOMAIN)
  );
}

export function isImageCompatible(filePath: any) {
  let extensions = new Set(["jpg", "jpeg", "gif", "webp", "png"]);
  if (extensions.has(path.extname(filePath).slice(1).toLowerCase())) {
    return true;
  } else {
    notify.show("Upload a PNG, JPG, WEBP or GIF", "error");
    return false;
  }
}

export function isImageTypeValid(contentType: any) {
  return (
    contentType.search("jpg") !== -1 ||
    contentType.search("jpeg") !== -1 ||
    contentType.search("png") !== -1 ||
    contentType.search("gif") !== -1 ||
    contentType.search("webp") !== -1
  );
}

export function isImageUrl(url: any) {
  if (!url) return false;
  const http = url.lastIndexOf("http");
  if (http !== -1) url = url.substring(http);
  if (!isAbsoluteUrl(url)) return isImage(url);
  let pathname = urlParse(url).pathname;
  if (!pathname) return false;
  const last = pathname.search(/[:?&]/);
  if (last !== -1) pathname = pathname.substring(0, last);
  if (isImage(pathname)) return isImageCompatible(pathname);
  if (/styles/i.test(pathname)) return false;
  try {
    let timeout = 60000;
    const res = request("GET", url, { timeout });
    if (!res) return false;
    const headers = res.headers;
    if (!headers) return false;
    const contentType = headers["content-type"];
    if (!contentType) return false;
    if (contentType.search(/^image\//) != -1) {
      if (!isImageTypeValid(contentType)) {
        notify.show("Upload a PNG, JPG, WEBP or GIF", "error");
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } catch (e) {
    console.log(e.message);
    return false;
  }
}

export function base64toBlob(base64Image: any) {
  // Split into two parts
  const parts = base64Image.split(";base64,");

  // Hold the content type
  const imageType = parts[0].split(":")[1];

  // Decode Base64 string
  const decodedData = window.atob(parts[1]);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB image after conversion

  return new Blob([uInt8Array], { type: imageType });
}

/**
 * @param file
 * @param quality - 0 to 1, 0 means less quality and 1 means high quality
 * @param convertTypes
 * @returns
 */
export async function compressImage(
  file: File,
  mimeType: string = "image/webp"
) {
  try {
    if (file?.type?.includes("gif")) {
      return (await compressGifImage(file)) || file;
    }

    let config = await getCompressionConfiguration(file, mimeType);

    return new Promise((resolve) => {
      try {
        new Compressor(file, {
          ...config,
          success(result) {
            resolve(result);
          },
          error(err) {
            resolve(file);
          }
        });
      } catch (error) {
        resolve(file);
      }
    });
  } catch (error) {
    return file;
  }
}

async function getCompressionConfiguration(file: File, mimeType: String = "") {
  let size = file.size ? file.size / (1024 * 1024) : null;

  // I image is under 1 MB, no need to compress
  // Its already less pixelated and if we compress it, it will lose quality
  if (size && size < 1) {
    return {
      quality: 0.99,
      mimeType,
      maxWidth: IMAGE_MAX_WIDTH
    };
  }
  let width = await getImageWidth(file);

  if (width > 2250) {
    // All images greater than 1920 pixels are compressed to 1920 pixels
    // And if the image is greater than 2250 and compressing it to 1920 will reduce lot of size so no need high compression
    // Selecting 0.9 quality (1 means no compression and 0 means high compression)
    return {
      quality: 0.9,
      mimeType,
      maxWidth: IMAGE_MAX_WIDTH
    };
  }

  return {
    quality: 0.8,
    mimeType,
    maxWidth: IMAGE_MAX_WIDTH
  };
}

async function getImageWidth(file: File) {
  try {
    return new Promise((resolve, reject) => {
      try {
        const img = new Image();
        // Set the source to the file object
        img.src = URL.createObjectURL(file);

        // Wait for the image to load
        img.onload = function () {
          let width = img.width || 0;
          // Release the object URL
          URL.revokeObjectURL(img.src);
          resolve(width);
        };
      } catch (error) {
        resolve(0);
      }
    });
  } catch (error) {}
  return 0;
}
export async function compressGifImage(file: File) {
  try {
    return new Promise((resolve, reject) => {
      if (file?.name) {
        file.name = file.name.replace(/\s+/g, "_");
      }
      setTimeout(() => {
        resolve(file);
      }, 30 * 1000); // Wait 30 sec for compress, if no response return original file
      gifsicle
        .run({
          input: [
            {
              file: file,
              name: file.name
            }
          ],
          command: [`-O2 --lossy=40 ${file.name}  -o /out/${file.name}`]
        })
        .then((out) => {
          resolve(out[0] || file);
        });
    });
  } catch (error) {
    return file;
  }
}
