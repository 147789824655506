// @ts-nocheck
import colors from "../../Themes/Colors";
import { grey } from "@mui/material/colors";
import { shadow, space } from "../../Config/theme";

const styles = (theme: any) => ({
  commentLogoImage: {
    marginRight: 10
  },

  userName: {
    wordBreak: "break-all",
    marginTop: 0
  },

  timestamp: {
    wordBreak: "break-all"
  },

  marginTop30: {
    marginTop: 30
  },

  commentText: {
    whiteSpace: "break-spaces",
    wordBreak: "break-word",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.002em",
    width: "100%",
    padding: "6px 0px",
    "& iframe": {
      width: "100%",
      height: "auto",
      aspectRatio: 16 / 9,
      padding: `${space.SMALL} 0px}`
    },
    "& p": {
      margin: "6px 0px 0px 0px"
    }
  },

  container: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: space.XS,
    marginBottom: space.XS
  },

  selfComment: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: space.XS,
    marginBottom: space.XS
  },

  menuTooltip: {
    color: colors.fontSecondary,
    textAlign: "right",
    paddingRight: space.XXS,
    verticalAlign: "middle",
    display: "block",
    width: "100%"
  },

  menuItem: {
    display: "flex",
    color: "black",
    width: "100%",
    padding: "10px 2px 10px 2px",
    borderRadius: 5,
    "&:hover": {
      background: colors.hover,
      cursor: "pointer"
    }
  },

  menuIcon: {
    height: 18,
    margin: "auto 0px",
    marginRight: space.XS,
    color: grey[700]
  },

  popover: {
    boxShadow: shadow.SMALL,
    padding: space.XS,
    borderRadius: 10,
    marginTop: space.XS
  },

  commentViewSection: {
    padding: `${space.MEDIUM} 20px`,
    display: "flex",
    flexDirection: "column"
  },

  commentHover: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: colors.planSelectBackgroundColor
    }
  }
});

export default styles;
