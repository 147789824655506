// @ts-nocheck
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  container: {
    padding: 0
  },

  marginAuto: {
    margin: "auto 0px"
  },

  margin10: {
    marginTop: 10
  },

  cardSubtitle: {
    color: colors.fontSecondary
  },

  subtitleDescription: {
    color: colors.fontSecondary,
    margin: "5px 0px 10px 0px"
  },

  width100: {
    width: "100%"
  },

  headerStyles: {
    height: "10%",
    [theme.breakpoints.up("xs")]: {
      padding: 10,
      paddingLeft: 25,
      marginTop: 10
    }
  },

  button: {
    color: colors.white
  },

  marginTop20: {
    marginTop: 20
  },

  marginTop12: {
    [theme.breakpoints.down("md")]: {
      marginTop: 12,
      marginBottom: 10
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 12
    }
  },

  marginRight40: {
    [theme.breakpoints.down("md")]: {
      marginRight: 0
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: 40
    }
  },

  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    width: "100%",
    color: colors.fontInput,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontInput
    }
  },

  logoImage: {
    width: 150,
    height: 150
  },

  saveGrid: {
    [theme.breakpoints.down("md")]: {
      marginTop: 10
    },
    margin: "auto auto"
  },

  editTemplateButton: {
    paddingLeft: 15
  },

  editor: {
    height: 500,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%"
    }
  },

  switch: {
    margin: "auto",
    textAlign: "center"
  }
});
export default styles;
