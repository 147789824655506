// @ts-nocheck
import colors from "../../Themes/Colors";
const styles = (theme: any) => ({
  container: {
  padding: 0
},

  cardTitle: {
    marginTop: 10,
    fontWeight: "bold",
    fontSize: 20
  },

  marginTop20: {
    marginTop: 20
  },

  paddingLeft10: {
    paddingLeft: 10
  },

  select: {
    width: "29%",
    marginLeft: "1%"
  },

  delete: {
    width: "9%",
    marginLeft: "1%"
  },

  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    width: "100%",
    color: colors.fontInput,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontInput
    }
  },

  width100: {
    width: "100%"
  },

  justifyContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },

  width60: {
    width: "60%"
  }
});

export default styles;
