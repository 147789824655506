import { Theme } from "@mui/material";
import { shadow, space } from "../../Config/theme";
import colors, { designColors } from "../../Themes/Colors";
import { createStyles } from "@mui/styles";

const styles = (theme: Theme) =>
  createStyles({
    gridContainer: {
      paddingLeft: "20%",
      paddingTop: "5%",
      paddingRight: "20%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "5%",
        paddingRight: "5%"
      }
    },

    dialogContainer: {
      padding: 10
    },

    LinearProgress: {
      marginTop: 20
    },

    navigationContainer: {
      marginTop: 5
    },

    marginTop20: {
      marginTop: 20
    },

    avatarContainer: {
      marginTop: 44
    },

    dataContainer: {
      marginTop: 44,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-around" // Center the cards horizontally
    },

    heading: {
      fontWeight: "bolder",
      fontSize: 16
    },

    logoImage: {
      width: 66,
      height: 66
    },

    backButton: {
      marginTop: "10px"
    },

    nextButton: {
      marginTop: "10px"
    },

    navIcon: {
      color: colors.fontPrimary
    },

    selectedCard: {
      backgroundColor: `${designColors.grayScale[5]} !important`,
      border: `2px solid ${colors.primary} !important` // Use the primary color for the border
    },

    pageTitle: {
      color: colors.fontPrimary
    },

    paper: {
      backgroundColor: colors.card,
      borderRadius: 5,
      border: `1px solid ${colors.paperBorder}`
    },

    cardContainer: {
      position: "relative",
      transition: "transform 0.5s, box-shadow 0.5s",
      cursor: "pointer",
      marginTop: "20px",
      borderRadius: 5,
      padding: space.SMALL,
      overflow: "hidden", // Hide any overflowing content
      "&:hover": {
        boxShadow: shadow.MEDIUM
      }
    },

    image: {
      width: "100%",
      opacity: 0.9,
      objectFit: "cover",
      borderRadius: 24
    },

    textContainer: {
      width: "100%",
      height: "20%",
      display: "flex",
      flexDirection: "column",
      gap: space.XS,
      boxSizing: "border-box" // Ensure padding is included in the defined width/height
    },

    textWrapper: {
      display: "flex",
      alignItems: "center",
      padding: `0 ${space.SMALL}`,
      width: "100%",
      textAlign: "center"
    },

    cardText: {
      color: "#000000",
      wordBreak: "break-word"
    },

    accountContainer: {
      margin: "auto 0"
    },
    section: {
      marginBottom: 10,
      alignItems: "center",
      display: "flex"
    },
    socialIcon: {
      marginRight: 10,
      width: "28px !important",
      height: "28px !important"
    },
    slack: {},
    cardSubtitle: {
      color: colors.fontSecondary
    },
    connectButtonContainer: {
      margin: "auto 0",
      justifyContent: "flex-end",
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    textButton: {
      margin: space.XS
    },
    input: {},
    label: {}
  });

export default styles;
