// @ts-nocheck
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import MentionBlot from "../EditorComponents/quillmention/blots/mention";
import firebase from "firebase/app";
import Mention from "../EditorComponents/quillmention/quill.mention";
import colors, { designColors } from "../Themes/Colors";

import { ReactComponent as SendSolidIcon } from "../Images/icon24/send-solid.svg";
import "../quill.bubble.comment.css";
import { notify } from "./CustomNotifications";
import { uploadFiles } from "../Utils/Storage";

import FilePreview from "./FilePreview";
import { acceptedFileUploadTypes, commentStatus } from "../Utils/Types";

import _ from "lodash";
import { isJSON, parseJson } from "../Utils/UserUtils";

import { ReactComponent as AttachmentIcon } from "../Images/icon16/attachment.svg";

import { ReactComponent as VideoIcon } from "../Images/icon16/video.svg";
import { space } from "../Config/theme";

import styled from "styled-components";
import StyledChip from "../design/components/StyledChip";

Quill.register("formats/mention", MentionBlot, true); // Formats - inline attributes
Quill.register("modules/mention", Mention); // Modules - plugins

const EDITOR_MAX_AUTO_RESIZE_HEIGHT = 300;

const StyledSVG = styled.svg`
  & path {
    fill: ${(props: any) => props.color};
  }
`;

// We need to register a block for the @mentions ops to appear in delta
let Block = Quill.import("blots/block");
let Delta = Quill.import("delta");
// p tag is used for the main editor and in comment input.
// We are using div for linkedin editor and rest other editors.
Block.tagName = "P";
Quill.register(Block, true);

const ARROW = "\u2192";

class CommentsInput extends Component {
  reactQuillRef: any;
  saveCommentDebounce: any;
  uploadFileRef: any;
  constructor(props: any) {
    super(props);
    let comment =
      props.comment && isJSON(props.comment)
        ? parseJson(props.comment)
        : props.comment || "";
    this.state = {
      comment,
      mentions: this.getUsersForMentionsAutocomplete(),
      previewFiles: props.files || [],

      addCommentInProgress: this.props.addCommentInProgress,
      isUserLoggedIn: firebase.auth().currentUser ? true : false,
      showAttachAndVideoIcon: false,
      borderColor: designColors.grayScale[40],
      contentLoaded: false
    };
    this.reactQuillRef = React.createRef();
    this.uploadFileRef = React.createRef();
  }

  componentDidMount() {
    this.saveCommentDebounce = _.debounce(this.props.onAddComment, 1000);

    // For comment input, we use different styles than the main editor..
    // If you change the styles in the main CSS, it will affect the main editor.
    // So we developed a new CSS style for comment input,
    // Replacing the default editor class name with the class name of the comments input.
    this.reactQuillRef
      .getEditor()
      .root.setAttribute("class", "ql-editor-comment ql-blank");

    // If its from editor comment, we will set the height of the editor to 45px as per the mocks.

    if (this.props.isFromEditor) {
      if (this.reactQuillRef.getEditor().root) {
        this.reactQuillRef.getEditor().root.style.height = "45px";
      }
    }

    if (this.props.autoFocus) {
      this.reactQuillRef.getEditor()?.focus();
    }
    if (this.reactQuillRef.getEditor().getText()?.trim()) {
      this.showIconButtons();
    }
  }

  componentDidUpdate = (prevProps: any) => {
    // We will initially load comment input with empty teammates from Topic comments.
    // We will update whenever we receive teammates from the backend.
    // This method will be called whenever the state or props  changes. This condition avoids looping.

    if (!prevProps.teammates?.length && this.props.teammates?.length) {
      this.setState({
        mentions: this.getUsersForMentionsAutocomplete()
      });
    }

    // Update comment on comment prop change

    if (prevProps.comment !== this.props.comment) {
      let { comment } = this.props;

      comment = comment && isJSON(comment) ? parseJson(comment) : comment || "";
      this.setState({ comment }, () => {
        if (this.reactQuillRef.getEditor().getText()?.trim()) {
          this.showIconButtons();
        }
      });
    }
  };

  componentWillUnmount() {
    this.savePendingComment();
  }

  getUsersForMentionsAutocomplete = () => {
    let { teammates = [] } = this.props;
    return teammates.map((teammate: any) => {
      return {
        id: teammate.client._id,
        value: teammate.client.name || teammate.client.email
      };
    });
  };

  getEditModules = () => {
    return this.modules;
  };

  // When the user types @searchterm, this function is called.
  // Users will be filtered and shown depending on their search terms.
  mention = (searchTerm: any, renderList: any, mentionChar: any) => {
    if (!searchTerm) {
      return;
    }
    //Filtering users by user input

    let filteredMentions = this.state.mentions.filter((mention: any) => {
      return mention.value.toLowerCase().includes(searchTerm.toLowerCase());
    });
    renderList(filteredMentions, searchTerm);
  };

  bindings = {
    insertArrow: {
      key: 190, // Keycode for '.' key, as Shift+'.' = '>'
      shiftKey: true,
      collapsed: true,
      handler: (range: any, context: any) => {
        let lastCharacter = this.reactQuillRef
          .getEditor()
          .getText(range.index - 1, 1);
        if (lastCharacter === "-") {
          let range = this.reactQuillRef.getEditor().getSelection(true);
          this.reactQuillRef.getEditor().updateContents(
            new Delta()
              .retain(range.index - 1)
              .delete(1)
              .insert(ARROW)
          );
        } else {
          this.reactQuillRef
            .getEditor()
            .updateContents(new Delta().retain(range.index).insert(">"));
          this.reactQuillRef.getEditor().setSelection(range.index + 1);
        }
      }
    }
  };

  // Specifying allowed modules
  // We are only allowing mention here.
  modules = {
    clipboard: {
      matchVisual: false
    },
    toolbar: false,
    keyboard: { bindings: this.bindings },
    mention: {
      allowedChars: /^[a-zA-Z0-9:]*$/,
      mentionDenotationChars: ["@"],
      source: this.mention,
      defaultMenuOrientation: "bottom"
    }
  };

  // Handle shortcut to save comment
  // Supported shortcut ctrl/cmd+enter
  textKeyPress = (event: any) => {
    if (
      this.state.isUserLoggedIn &&
      !this.reactQuillRef.getEditor().getText()?.trim() &&
      event.code === "ArrowUp"
    ) {
      this.props.onUpArrow();
      return;
    }
    let enterPressed = event.keyCode === 10 || event.keyCode === 13; // ENTER is key code 10 or 13 depending on browser/OS
    let ctrlOrCmdPressed = event.ctrlKey || event.metaKey; // meta key is command on Mac
    if (enterPressed && ctrlOrCmdPressed) {
      this.handleAddComment();
      return;
    }
  };

  addAttachment = (event: any) => {
    // Prevent files from opening in Browser
    event.preventDefault();
    event.stopPropagation();

    let { previewFiles } = this.state;
    if (event.type === "drop") {
      // If the event is drop, the files are stored in 'event.dataTransfer.files'
      for (let file of event.dataTransfer.files) {
        previewFiles.push(file);
      }
    } else {
      // If the event is manual upload by user, the files are stored in 'event.target.files'
      for (let file of event.target.files) {
        previewFiles.push(file);
      }
    }
    this.setState({
      previewFiles
    });
  };

  removeAttachment = (index: any) => {
    let { previewFiles } = this.state;
    previewFiles.splice(index, 1);
    this.setState({ previewFiles });
  };

  //Validate and save comment
  handleAddComment = async () => {
    let { previewFiles } = this.state;
    // Use plain text instead of HTML/Delta in the editor to see if a comment is valid and does not contain any empty spaces.
    // If we get the comment as HTML, it will look like this: p>br>.../p> if the comment just contains spaces or new lines. Delta is the same.
    // As a result, we are unable to validate this. That is why a plain text must be verified to check for empty spaces or new lines.
    // Mentions will not be displayed in plain text, if a comment has only a mention, the plain text will be empty, so, checking whether the html contains mentions or not.
    if (
      !this.reactQuillRef.getEditor().getText()?.trim() &&
      !this.state.comment.includes("mention") &&
      !previewFiles.length
    ) {
      notify.show("You need to type a comment first to send it.", "error");
      return;
    }
    this.setState({
      addCommentInProgress: true
    });
    let filesUrl = await uploadFiles(previewFiles, "comment-files");
    this.saveCommentDebounce?.cancel?.();

    this.props.onAddComment(
      JSON.stringify(this.reactQuillRef.getEditor().getContents()),
      filesUrl,
      commentStatus.PUBLISHED,
      this.reactQuillRef.getEditor()?.getText()?.trim()
    );
  };

  getContents = async () => {
    let { previewFiles } = this.state;
    let fileUrls = previewFiles?.length
      ? await uploadFiles(previewFiles, "comment-files")
      : [];

    return {
      delta: JSON.stringify(this.reactQuillRef.getEditor?.()?.getContents?.()),
      text:
        !this.reactQuillRef.getEditor().getText()?.trim() &&
        !this.state.comment.includes("mention")
          ? ""
          : this.reactQuillRef.getEditor().getText().trim(),
      fileUrls
    };
  };

  clearText = () => {
    this.setState({
      comment: "",
      previewFiles: [],
      addCommentInProgress: false
    });
  };

  savePendingComment = () => {
    this.saveCommentDebounce?.flush?.();
  };

  setAddCommentInProgress = (addCommentInProgress: boolean) => {
    this.setState({ addCommentInProgress });
  };

  onEditorChange = (comment: any) => {
    if (this.props.autoFocus && this.state.comment) {
      this.setCursorPositionToEndOfTheEditor();
    }

    let isCommentInstanceOfDelta = this.state.comment instanceof Delta;

    this.setState({ comment, contentLoaded: true }, async () => {
      this.resizeEditor();

      // isCommentInstanceOfDelta is true means the change is not triggered by user input.
      // Its triggered because we inserted draft comment text to editor
      if (!this.state.isUserLoggedIn || isCommentInstanceOfDelta) {
        return;
      }
      let ref = this.reactQuillRef.getEditor();
      this.saveCommentDebounce(
        JSON.stringify(ref.getContents()),
        [],
        commentStatus.DRAFT,
        ref.getText()?.trim()
      );
    });
  };

  getEditorText = () => {
    try {
      return this.reactQuillRef?.getEditor?.().getText?.();
    } catch (error) {}
  };

  resizeEditor = () => {
    if (!this.props.isFromEditor) {
      return;
    }

    let editor = this.reactQuillRef.getEditor().root;
    if (
      editor &&
      editor.scrollHeight > editor.offsetHeight &&
      editor.offsetHeight < EDITOR_MAX_AUTO_RESIZE_HEIGHT
    ) {
      editor.style.height = `${Math.min(
        editor.scrollHeight,
        EDITOR_MAX_AUTO_RESIZE_HEIGHT
      )}px`;
    }
  };

  // Set cursor position to end of the editor
  setCursorPositionToEndOfTheEditor = () => {
    let { contentLoaded } = this.state;

    // We need to set the cursor position to the end of the editor when the editor content is loaded.
    // This is called from on change function means data loaded,
    // first time only we need to set position to end of the editor
    // contentLoaded true means its not first time
    if (contentLoaded) {
      return;
    }

    this.reactQuillRef
      ?.getEditor?.()
      ?.setSelection(this.reactQuillRef.getEditor().getLength());
  };

  showIconButtons = () => {
    if (this.state.showAttachAndVideoIcon) {
      this.setState({
        borderColor: designColors.primary[100]
      });
      return;
    }

    this.setState({
      showAttachAndVideoIcon: true,
      borderColor: designColors.primary[100]
    });

    this.props.onEditorFocused?.();
  };

  render() {
    let {
      loomButtonId,

      loomInitialized,

      isFromEditor = false,

      hideLoomButton = false,

      placeholder
    } = this.props;
    let {
      comment,

      previewFiles,

      addCommentInProgress,

      showAttachAndVideoIcon,

      borderColor
    } = this.state;

    return (
      <div
        onDrop={this.addAttachment}
        style={{
          display: "inline-flex",
          flexDirection: "column",
          borderRadius: 6,
          width: "100%",
          border: `1px solid ${borderColor}`,
          alignItems: "stretch",
          overflow: "hidden"
        }}
      >
        <ReactQuill
          ref={(el) => (this.reactQuillRef = el)}
          defaultValue={comment}
          theme="bubble"
          value={comment}
          onChange={this.onEditorChange}
          style={{
            padding: 0,
            width: "100%",
            background: colors.white
          }}
          placeholder={comment ? "" : placeholder || "Add comment"}
          modules={this.getEditModules()}
          formats={["mention"]}
          onKeyDown={this.textKeyPress}
          onFocus={this.showIconButtons}
          onBlur={() => {
            this.setState({
              borderColor: designColors.grayScale[40]
            });
          }}
        />

        <div
          style={{
            background: colors.white,
            paddingBottom: previewFiles?.length ? space.SMALL : 0,
            paddingTop: showAttachAndVideoIcon ? space.MEDIUM : "0px"
          }}
        >
          <FilePreview
            previewFiles={previewFiles}
            removeAttachment={this.removeAttachment}
          />
        </div>
        {showAttachAndVideoIcon && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "10px",
              backgroundColor: colors.white
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              {(showAttachAndVideoIcon || !isFromEditor) && (
                <>
                  <input
                    id="add-attachment-button"
                    style={{ display: "none" }}
                    type="file"
                    onChange={this.addAttachment}
                    ref={this.uploadFileRef}
                    accept={acceptedFileUploadTypes}
                    multiple
                  />

                  <Tooltip title="Attachments">
                    <IconButton
                      onClick={() => this.uploadFileRef.current.click()}
                      size="large"
                      style={{
                        width: 32,
                        height: 32,
                        marginRight: space.XS,
                        marginLeft: space.MEDIUM,
                        borderRadius: 6,
                        padding: 0,
                        border: `1px solid ${designColors.grayScale[40]}`,
                        background: "transparent"
                      }}
                    >
                      <AttachmentIcon />
                    </IconButton>
                  </Tooltip>
                  {Boolean(!hideLoomButton) && (
                    <Tooltip title="Record video">
                      <IconButton
                        id={loomButtonId}
                        disabled={!loomInitialized}
                        style={{
                          background: "transparent",
                          width: 32,
                          height: 32,
                          borderRadius: 6,
                          padding: 0,
                          border: `1px solid ${designColors.grayScale[40]}`,
                          color: loomInitialized
                            ? ""
                            : designColors.grayScale[40]
                        }}
                      >
                        <VideoIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              {Boolean(
                this.reactQuillRef?.getEditor?.()?.getText?.()?.trim()
              ) && (
                <StyledChip
                  label="Draft"
                  style={{ marginRight: space.XS, marginTop: 0 }}
                />
              )}
              {addCommentInProgress && (
                <IconButton size="large">
                  <CircularProgress size={20} />
                </IconButton>
              )}
              {!addCommentInProgress && (
                <IconButton
                  onClick={this.handleAddComment}
                  size="medium"
                  style={{
                    width: 48,
                    height: 48,
                    padding: space.XS
                  }}
                >
                  <StyledSVG
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    color={designColors.grayScale[60]}
                  >
                    <SendSolidIcon />
                  </StyledSVG>
                </IconButton>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CommentsInput;
