// @ts-nocheck
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import SendIcon from "@mui/icons-material/Send";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Popover,
  Tooltip,
  Typography
} from "@mui/material";
import firebase from "firebase/app";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";

import validator from "validator";
import { nanoid } from "nanoid";
import React, { Fragment } from "react";

import CopyToClipboard from "react-copy-to-clipboard";

import { Helmet } from "react-helmet";

import isURL from "validator/lib/isURL";

import CustomFlatPicker from "../Components/CustomFlatPicker";
import { notify } from "../Components/CustomNotifications";

import ImagePicker from "../Components/ImagePicker";

import Loading from "../Components/Loading";
import StyledButton from "../design/components/StyledButton";

import StyledCheckbox from "../design/components/StyledCheckbox";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import StyledUserChip from "../design/components/StyledUserChip";
import API from "../Services/Api";
import colors, { designColors } from "../Themes/Colors";
import ImagesList from "../Themes/Images";
import PageAccessContext from "../Utils/PageAccessContext";
import { uploadBlobImage } from "../Utils/Storage";
import { formatForTimeWithTimeZone } from "../Utils/TimeUtils";
import {
  datePickerDefault,
  pageAccess,
  postStatus,
  socialMediaType,
  socialMediaStatus,
  tweetPublishType,
  tweetStatus,
  generateSocialPostStatus,
  URL_REPLACE_TEXT,
  channelTypes
} from "../Utils/Types";
import {
  getQueryParamsFromURL,
  getScheduleDateFromURL,
  hideOtherChatWidgets,
  isAccessDisabled,
  showOtherChatWidgets,
  splitTwitterPost,
  textToThreads
} from "../Utils/UserUtils";
import styles from "./styles/TwitterShareStyle";
import { replaceAll } from "../Utils/UserUtils";
import { space } from "../Config/theme";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import AIInstructionsDialog from "../Components/AIInstructionsDialog";
import AIGeneratePost, { generateFrom } from "../Components/AIGeneratePost";

const Page404 = React.lazy(() => import("../Containers/Page404"));

const _ = require("lodash");
const api = API.create();
const DEFAULT_INDEX = -1;

class TwitterShare extends React.Component {
  static contextType = PageAccessContext;
  fetchSocialPost: any;
  fetchSocialPostFromLinkInterval: any;
  imagePickerRef: any;
  saveTweetDetailsDebounce: any;
  constructor(props: any) {
    super(props);
    let { backpage, agencyView } = getQueryParamsFromURL(
      window.location.search,
      "twitter/drafts"
    );
    this.state = {
      id:
        props.match?.params?.tweetid || props.match?.params?.id || props.postId,
      tweetId: null,
      loading: true,
      error: false,
      errorMessage: "",
      tweets: [],
      openImagePicker: false,
      twitters: null,
      postUrl: null,
      tweetIndex: null,
      publication: null,
      post: null,
      shareInitialized: false,
      showDeleteConfirmDialog: false,
      deleteInitialized: false,
      backpage,
      agencyView,
      showDateTimePicker: false,
      scheduledOn: getScheduleDateFromURL(window.location.search, null),
      draftPostsCount: 0,
      tweetApprovers: [],
      teammembers: [],
      showApproverDialog: false,
      approvalRequested: false,
      isApprover: false,
      socialPostText: "",
      prevSocialPostText: [],
      showCopyConfirmation: false,
      copyIndex: DEFAULT_INDEX,
      scheduledPostDates: [],
      instructions: "",
      showInstructionDialog: false,
      instructionDialogIndex: 0,
      sourceLink: "",
      showAIGeneratePostDialog: false
    };
    this.imagePickerRef = React.createRef();
    this.fetchSocialPostFromLinkInterval = [];
    this.fetchSocialPost = [];
  }

  componentDidMount = () => {
    this.load();
    this.saveTweetDetailsDebounce = _.debounce(this.saveTweetDetails, 1000);
    setTimeout(() => {
      hideOtherChatWidgets();
    }, 3000);
  };

  componentWillUnmount() {
    showOtherChatWidgets();
    this.fetchSocialPostFromLinkInterval?.forEach((interval: any) => {
      this.clearFetchSocialPostFromLinkInterval(interval);
    });

    this.fetchSocialPost?.forEach((interval: any) => {
      this.clearIntervalToFetchSocialPost(interval);
    });
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (this.props.match?.params.id !== prevProps.match?.params.id) {
      let { backpage, agencyView } = getQueryParamsFromURL(
        window.location.search,
        "posts"
      );
      this.setState(
        {
          id: this.props.match?.params?.id || this.props.postId,
          tweetId: null,
          loading: true,
          error: false,
          errorMessage: "",
          tweets: [],
          openImagePicker: false,
          twitters: null,
          postUrl: null,
          tweetIndex: null,
          publication: null,
          post: null,
          shareInitialized: false,
          showDeleteConfirmDialog: false,
          deleteInitialized: false,
          backpage,
          agencyView,
          showDateTimePicker: false,
          scheduledOn: getScheduleDateFromURL(window.location.search, null),
          instructions: "",
          showInstructionDialog: false,
          showAIGeneratePostDialog: false
        },
        this.load
      );
    }
  }

  load = () => {
    let { id } = this.state;
    api.getTwitterDetails(id, (res: any) => {
      if (res.status === 200) {
        let {
          post,
          tweets,
          postUrl,
          publication,
          twitters,
          tweetId,
          draftPostsCount,
          contentApprovers,
          teammembers,
          clientId
        } = res.data;

        tweets = this.getFormattedTweets(
          tweets,
          post,
          twitters,
          postUrl,
          publication
        );

        let isApprover =
          contentApprovers.some((approver: any) => {
            return approver?.client?._id === clientId;
          }) || false;

        this.setState({
          loading: false,
          postUrl,
          post,
          publication,
          twitters,
          tweetId,
          tweets,
          draftPostsCount,
          tweetApprovers: contentApprovers,
          teammembers,
          approvalRequested: tweets[0]?.approvers?.length ? true : false,
          isApprover,
          instructions: publication?.twitterCustomPrompt || ""
        });
      } else {
        this.setState({
          loading: false,
          error: true,
          errorMessage: res.data
        });
      }
    });
  };

  getFormattedTweets = (
    tweets: any,
    post: any,
    twitters: any,
    postUrl: any,
    publication: any
  ) => {
    if (!tweets || !tweets.length) {
      let { scheduledOn, socialPostText } = this.state;
      let utm = nanoid(10);
      let tweet =
        splitTwitterPost(socialPostText).join(" [...] ") ||
        this.getTweetText(post, postUrl, publication);

      return [
        {
          text: tweet,
          approvers: [],
          approvedBy: null,
          threadImages: [],
          scheduledDate: scheduledOn,
          selectedAccount: null,
          threads: textToThreads(tweet),
          threadIndex: null,
          publishType: scheduledOn
            ? tweetPublishType.LATER
            : post
            ? tweetPublishType.WITH_POST
            : tweetPublishType.NOW,
          utm,
          isDirty: false,
          samples: []
        }
      ];
    }

    let formattedTweets: any = [];
    tweets.forEach((eachTweet: any) => {
      let tweet = {
        tweetId: eachTweet._id,
        text: eachTweet.text,
        approvers: eachTweet.approvers,
        approvedBy: eachTweet.approvedBy,
        approvalRequestedBy: eachTweet.approvalRequestedBy,
        threadImages: eachTweet.images || [],
        scheduledDate: eachTweet.scheduledOn || null,
        selectedAccount: this.findTwitterAccountByUserId(twitters, eachTweet),
        threads: textToThreads(eachTweet.text),
        threadIndex: null,
        publishType: eachTweet.publishType,
        status: eachTweet.status,
        utm: eachTweet.utm || nanoid(10),
        samples: eachTweet?.samples || []
      };
      formattedTweets.push(tweet);
    });
    return formattedTweets;
  };

  setIntervalToFetchSocialPost = (
    index: any,
    isFreeFormInput: boolean = false
  ) => {
    if (this.fetchSocialPost[index]) {
      return;
    }
    this.fetchSocialPost[index] = setInterval(() => {
      this.generateSocialPost(index, false, isFreeFormInput);
    }, 5000);
  };

  clearIntervalToFetchSocialPost = (index: any) => {
    clearInterval(this.fetchSocialPost[index]);
    this.fetchSocialPost[index] = null;
  };

  generateSocialPost = (
    index: any,
    regenerate = true,
    isFreeFormInput: boolean = false
  ) => {
    let {
      post,
      tweets,
      prevSocialPostText,
      instructions,
      publication,
      freeInputSource
    } = this.state;

    tweets[index].socialPostLoading = true;
    let socialPostId = isFreeFormInput
      ? tweets[index]?._id || tweets[index]?.tweetId
      : post?._id;
    let socialPostSourceBody = isFreeFormInput
      ? freeInputSource
      : post?.htmlText;

    this.setState({ tweets }, () => {
      api.generateSocialPostFromBlog(
        publication?._id,
        socialPostId,
        socialPostSourceBody,
        regenerate,
        socialMediaType.TWITTER,
        instructions,
        tweets[index]?.selectedAccount?.userId || "",
        tweets[index]?.samples || [], // samples
        (res: any) => {
          if (res.status === 200) {
            let socialPost = res.data.socialPost;

            // If not completed, create a interval to fetch the social post
            if (socialPost?.status !== generateSocialPostStatus.COMPLETED) {
              this.setIntervalToFetchSocialPost(index, isFreeFormInput);
              return;
            }

            this.clearIntervalToFetchSocialPost(index);
            if (!socialPost.body) {
              tweets[index].socialPostLoading = false;
              this.setState({
                tweets
              });
              return;
            }

            prevSocialPostText.push(tweets[index]?.text || "");
            tweets[index].socialPostLoading = false;
            this.setState({
              prevSocialPostText,
              socialPostText: socialPost.body,
              tweets
            });

            this.handleTweetChange(socialPost.body, index);
          } else {
            tweets[index].socialPostLoading = false;
            this.clearIntervalToFetchSocialPost(index);
            this.setState({
              tweets
            });
            notify.show(
              res.status === 400
                ? res.data
                : "Oops, something went wrong. Try again.",
              "error"
            );
          }
        }
      );
    });
  };

  getTweetText = (post: any, postUrl: any, publication: any) => {
    if (post && postUrl) {
      return `We just published a blog post on ${post.title} at ${publication.name}.\n[...]\nRead the full post here: ${URL_REPLACE_TEXT}`;
    }
    return "";
  };

  findTwitterAccountByUserId = (twitters: any, tweet: any) => {
    if (tweet.status === tweetStatus.COMPLETED) {
      return {
        userId: tweet.userId,
        profilePicture: tweet.twitterProfilePic,
        fullName: tweet.twitterUserName,
        screenName: tweet.twitterScreenName
      };
    }
    if (!twitters || !twitters.length) {
      return "";
    }
    let index = twitters.findIndex((twitter: any) => {
      return twitter.userId === tweet.userId;
    });
    return index === -1 ? "" : twitters[index];
  };

  handleAccountChange = (event: any, tweetIndex: any) => {
    let { tweets } = this.state;
    tweets[tweetIndex].selectedAccount = event.target.value;
    this.setState({ tweets }, () => {
      this.saveTweetDetails(tweetIndex);
    });
  };

  handleTweetChange = (value: any, tweetIndex: any) => {
    let { tweets, postUrl } = this.state;
    let blogPostLink = "";
    if (postUrl) {
      blogPostLink = postUrl + "?utm=" + tweets[tweetIndex].utm;
    }
    tweets[tweetIndex].text = value;
    tweets[tweetIndex].threads = textToThreads(value, blogPostLink);
    tweets[tweetIndex]["isDirty"] = true;
    tweets[tweetIndex].socialPostLoading = false;
    //stop AI post generation if user types
    this.setState({ tweets }, () => {
      this.saveTweetDetailsDebounce(tweetIndex);
    });
  };

  handlePublishTypeChange = (event: any, tweetIndex: any) => {
    if (event.target.value === tweetPublishType.LATER) {
      // handled by onClick - handlePublishLater
      return;
    }

    let { tweets } = this.state;
    tweets[tweetIndex].publishType = event.target.value;
    tweets[tweetIndex].scheduledDate = null;
    this.setState({ tweets, tweetIndex }, () => {
      this.saveTweetDetails(tweetIndex);
    });
  };

  getScheduledPostDates = () => {
    let { tweets, publication, tweetIndex } = this.state;
    api.getScheduledPostDates(
      publication._id,
      tweets[tweetIndex]?.tweetId,
      tweets[tweetIndex]?.selectedAccount?.userId,
      socialMediaType.TWITTER,
      (res: any) => {
        if (res.status === 200) {
          this.setState(
            { scheduledPostDates: res.data?.scheduledPostDates || [] },
            () => this.setState({ showDateTimePicker: true })
          );
        }
      }
    );
  };

  handlePublishLater = (index: any) => {
    let { tweets } = this.state;
    this.setState({ tweetIndex: index }, () =>
      this.getScheduledPostDates(index)
    );
  };

  toggleImagePicker = (tweetIndex = null, threadIndex = null) => {
    let { openImagePicker, tweets } = this.state;
    tweets[tweetIndex].threadIndex = threadIndex;
    this.setState({
      tweets,
      tweetIndex,
      openImagePicker: !openImagePicker
    });
  };

  onImageSelected = (imageUrl: any) => {
    let { tweets, tweetIndex } = this.state;
    let threadImages = tweets[tweetIndex].threadImages;
    let images = threadImages[tweets[tweetIndex].threadIndex] || [];
    images.push(imageUrl);
    threadImages[tweets[tweetIndex].threadIndex] = images;
    tweets[tweetIndex].threadImages = threadImages;
    tweets[tweetIndex].threadIndex = null;

    this.setState({ tweets, tweetIndex: null, openImagePicker: false }, () => {
      this.saveTweetDetails(tweetIndex);
    });
  };

  onSelectFile = (file: any) => {
    const IMAGE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB Limit for image
    const GIF_SIZE_LIMIT = 15 * 1024 * 1024; // 15MB Limit for image
    if (!file.type.includes("image")) {
      notify.show("Only images are allowed", "error");
    }
    if (file.type === "image/gif" && file.size > GIF_SIZE_LIMIT) {
      notify.show("Upload a gif image that's smaller than 15 MB", "error");
      return;
    }
    if (file.size > IMAGE_SIZE_LIMIT) {
      notify.show("Upload a image that's smaller than 5 MB", "error");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      let imageUrl = "";
      if (
        reader.result?.startsWith("blob:") ||
        reader.result?.startsWith("data:")
      ) {
        imageUrl = await uploadBlobImage(
          reader.result,

          this.state.publication.domain
        );
      }
      if (!imageUrl) {
        return;
      }

      let { tweets, tweetIndex } = this.state;
      let threadIndex = tweets[tweetIndex].threadIndex;
      let threadImages = tweets[tweetIndex].threadImages;
      let images = threadImages[threadIndex] || [];
      images.push(imageUrl);
      threadImages[threadIndex] = images;
      tweets[tweetIndex].threadImages = threadImages;
      tweets[tweetIndex].threadIndex = null;
      this.setState(
        { tweets, openImagePicker: false, tweetIndex: null },
        () => {
          this.saveTweetDetails(tweetIndex);
        }
      );
    });
    reader.readAsDataURL(file);
  };

  removeImageFromThread = (
    tweetIndex: any,
    threadIndex: any,
    imageIndex: any
  ) => {
    let { tweets } = this.state;
    tweets[tweetIndex].threadImages[threadIndex].splice(imageIndex, 1);
    this.setState({ tweets }, () => this.saveTweetDetails(tweetIndex));
  };

  connectToTwitter = () => {
    let { publication, id, scheduledOn } = this.state;
    let fallbackPage = window.location.href.includes("review")
      ? `posts/${id}/review`
      : "twitter";
    api.connectToTwitter(publication._id, fallbackPage, {
      scheduledOn: scheduledOn
    });
  };

  saveTweetDetails = (tweetIndex = null) => {
    let { tweets, post, publication } = this.state;
    if (!tweetIndex && tweetIndex !== 0) {
      return;
    }

    let {
      tweetId = "",
      text,
      threadImages,
      selectedAccount,
      publishType,
      scheduledDate,
      saveInitialized,
      utm,
      samples = []
    } = tweets[tweetIndex];

    if (!tweetId && saveInitialized) {
      return;
    }
    tweets[tweetIndex].saveInitialized = !tweetId ? true : false;
    this.setState({ tweets }, () => {
      api.saveTweetDetails(
        tweetId,
        publication._id,
        post?._id || "",
        text,
        threadImages,
        selectedAccount?.userId,
        selectedAccount?.profilePicture,
        selectedAccount?.fullName,
        selectedAccount?.screenName,
        publishType,
        scheduledDate,
        utm,
        samples,
        (res: any) => {
          if (res.status === 200) {
            tweets[tweetIndex].tweetId = res.data.tweetId;
            this.setState({ tweets, saveInitialized: false });
          } else {
            this.setState({ saveInitialized: false });
          }
        }
      );
    });
  };

  handleShareToTwitter = () => {
    if (this.state.shareInitialized) {
      return;
    }
    this.setState({ shareInitialized: true }, () => {
      let { tweets } = this.state;
      let valid = true;
      for (let tweet of tweets) {
        if (!tweet.selectedAccount) {
          valid = false;
          notify.show("Select Twitter account to share", "error");
          break;
        }

        if (!tweet.text) {
          valid = false;
          notify.show("Write something to share", "error");
          break;
        }

        if (tweet.scheduledDate && moment().isAfter(tweet.scheduledDate)) {
          valid = false;
          notify.show("Scheduled date is in the past.", "error");
          break;
        }
      }
      if (!valid) {
        this.setState({
          shareInitialized: false
        });
        return;
      }
      this.scheduleTweet();
    });
  };

  scheduleTweet = () => {
    let { tweets, post, publication } = this.state;
    api.scheduleTweet(publication._id, post?._id || "", tweets, (res: any) => {
      if (res.status === 200) {
        let tweetsResponse = res.data;
        let hasScheduled = false;
        let approvalRequested = false;
        for (let tweetResponse of tweetsResponse) {
          if (
            tweetResponse.status === socialMediaStatus.IN_REVIEW &&
            !approvalRequested
          ) {
            approvalRequested = true;
          }
          if (tweetResponse?.scheduledDate && !hasScheduled) {
            hasScheduled = true;
          }
          if (tweetResponse?.tweetURL) {
            setTimeout(window.open(tweetResponse.tweetURL), 1000);
          }
        }

        if (approvalRequested) {
          notify.show("Notifying approvers about the request.", "success");

          this.props.history.push(
            `/${publication._id}/twitter/drafts?tab=in_review`
          );
          return;
        }

        if (hasScheduled) {
          notify.show("Your tweet was scheduled", "success");
        }

        this.gotoBackPageORClearFields(tweetsResponse);
      } else {
        this.setState({
          shareInitialized: false
        });
        notify.show("Oops, something went wrong.", "error");
      }
    });
  };

  requestTweetReview = () => {
    let { tweets, publication } = this.state;
    let { isReviewScreen = false } = this.props;

    api.requestTweetReview(publication._id, tweets, (res: any) => {
      if (res.status === 200) {
        notify.show("Notified approvers about the request.", "success");
        if (!isReviewScreen) {
          this.props.history.push(
            `/${publication._id}/twitter/drafts?tab=in_review`
          );
        }
      }
      if (res.status === 400) {
        notify.show(
          "Problem while notifying approvers. Please try again.",
          "error"
        );
      }
    });
  };

  gotoBackPageORClearFields = (tweetsResponse = []) => {
    let { isReviewScreen = false } = this.props;

    let { publication, backpage, tweetId, agencyView } = this.state;

    if (agencyView) {
      window.location.href = `/${backpage}`;
      return;
    }

    // open twitter event in calendar once posted when in dedicated share screen

    let scheduledPosts = tweetsResponse.filter((post) => post.scheduledDate);

    if (scheduledPosts[0]?._id && !isReviewScreen) {
      let toUrl = `/${publication._id}/calendar/?mediaId=${
        scheduledPosts[0]._id
      }&createdOn=${new Date(scheduledPosts[0].scheduledDate).toISOString()}`;

      this.props.history.push(toUrl);
      return;
    }

    if (tweetId) {
      this.props.history.push(`/${publication._id}/${backpage}`);
      return;
    }

    this.setState({ shareInitialized: false }, this.load);
  };

  getScheduleDate = (scheduledDate: any) => {
    let formattedScheduledDate = "";
    if (scheduledDate) {
      formattedScheduledDate = formatForTimeWithTimeZone(moment(scheduledDate));
    }
    //Reason to use &nbsp; instead of space
    //If we use space its getting trimmed in dropdown menu
    return formattedScheduledDate ? (
      <>
        on&nbsp;<b>{formattedScheduledDate.split("at")[0]}</b>&nbsp;at&nbsp;
        <b>{formattedScheduledDate.split("at")[1]}</b>
      </>
    ) : (
      ""
    );
  };

  onScheduleDateChange = (date: any) => {
    let { tweets, tweetIndex } = this.state;
    let currentDate = new Date();
    if (date > currentDate) {
      tweets[tweetIndex].scheduledDate = date;
      tweets[tweetIndex].publishType = tweetPublishType.LATER;
      this.setState({ tweets }, () => this.saveTweetDetails(tweetIndex));
    }
  };

  handlePostToAnotherAccount = () => {
    let { tweets, post, postUrl, twitters, publication } = this.state;
    if (tweets.length && tweets[0]) {
      let lastPostIndex = tweets.length - 1; //Fetch details from last post
      let utm = nanoid(10);
      let tweet = { ...tweets[lastPostIndex] };
      let tweetText =
        tweets[lastPostIndex].text ||
        this.getTweetText(post, postUrl, publication);
      tweet.threadImages = tweets[lastPostIndex]?.threadImages
        ? JSON.parse(JSON.stringify(tweets[lastPostIndex].threadImages))
        : [];
      tweet.text = tweetText;
      tweet.threads = textToThreads(tweetText);
      tweet.tweetId = null;
      tweet.selectedAccount = null;
      tweet.status = socialMediaStatus.DRAFT;
      tweets.push(tweet);
      tweet.utm = utm;
    } else {
      tweets = this.getFormattedTweets(
        tweets,
        post,
        twitters,
        postUrl,
        publication
      );
    }
    this.setState({
      tweets
    });
  };

  showConfirmDialog = (tweetIndex = null) => {
    let { showDeleteConfirmDialog } = this.state;
    this.setState({
      tweetIndex,
      showDeleteConfirmDialog: !showDeleteConfirmDialog
    });
  };

  deleteTweet = () => {
    //Handles double click

    if (this.state.deleteInitialized) {
      return;
    }

    this.setState({ deleteInitialized: true }, () => {
      let { tweets, publication, post, tweetIndex, tweetId, backpage } =
        this.state;
      if (!tweets[tweetIndex].tweetId) {
        tweets.splice(tweetIndex, 1);
        this.setState({
          tweets,
          showDeleteConfirmDialog: false,
          deleteInitialized: false
        });
        return;
      }
      api.deleteTweet(
        publication._id,
        tweets[tweetIndex].tweetId,
        post?._id || "",
        (res: any) => {
          if (res.status === 200) {
            tweets.splice(tweetIndex, 1);
            this.setState({
              tweets,
              showDeleteConfirmDialog: false,
              deleteInitialized: false
            });
            if (tweetId) {
              this.props.history.push(`/${publication._id}/${backpage}`);
              return;
            }
            return;
          }
          if (res.status === 400) {
            this.setState({
              showDeleteConfirmDialog: false,
              deleteInitialized: false
            });
            notify.show(res.data, "error");
            return;
          }
          this.setState({
            showDeleteConfirmDialog: false,
            deleteInitialized: false
          });
          notify.show("Oops, something went wrong.", "error");
        }
      );
    });
  };

  handleDialogClose = () => {
    this.setState({
      showDeleteConfirmDialog: false,
      tweetIndex: null
    });
  };

  copyEverywhere = () => {
    let { tweets, copyIndex } = this.state;
    if (!tweets[copyIndex]) {
      this.toggleShowCopyConfirmation(DEFAULT_INDEX);
      return;
    }
    for (let index = 0; index < tweets.length; index++) {
      if (
        tweets[index].status !== socialMediaStatus.COMPLETED &&
        index !== copyIndex
      ) {
        tweets[index].text = tweets[copyIndex].text;
        tweets[index].threads = tweets[copyIndex].threads;
        tweets[index].threadImages = tweets[copyIndex].threadImages;
        tweets[index].publishType = tweets[copyIndex].publishType;
        tweets[index].scheduledDate = tweets[copyIndex].scheduledDate;
        this.setState({ tweets }, () => {
          this.saveTweetDetails(index);
        });
      }
    }

    this.toggleShowCopyConfirmation(DEFAULT_INDEX);
  };

  toggleShowCopyConfirmation = (index: any) => {
    let { showCopyConfirmation } = this.state;
    this.setState({
      showCopyConfirmation: !showCopyConfirmation,
      copyIndex: index
    });
  };

  showInstruction = (showInstructionDialog: any, index = 0) => {
    this.setState({
      showInstructionDialog,
      instructionDialogIndex: index
    });
  };

  onSaveInstructions = (instructions: any) => {
    this.setState({
      instructions: instructions.trim(),
      showInstructionDialog: false
    });
  };

  clearFetchSocialPostFromLinkInterval = (index: any) => {
    clearInterval(this.fetchSocialPostFromLinkInterval[index]);
    this.fetchSocialPostFromLinkInterval[index] = null;
  };

  setIntervalToFetchSocialPostFromLink = (index: any) => {
    if (this.fetchSocialPostFromLinkInterval[index]) {
      return;
    }

    this.fetchSocialPostFromLinkInterval[index] = setInterval(() => {
      this.getSocialPostFromLink(index, false);
    }, 5000);

    setTimeout(() => {
      this.clearFetchSocialPostFromLinkInterval(index);
    }, 10 * 60 * 1000); // clear the interval after 10 minutes if the response is not received
  };

  // regenerate defines whether to regenerate the social post or just check the status
  getSocialPostFromLink = (index: any, regenerate = true) => {
    let { sourceLink, publication, tweets, prevSocialPostText } = this.state;

    let { postId } = this.props;

    if (tweets[index].fetchingSocialPostFromLink && regenerate) {
      return;
    }
    tweets[index].fetchingSocialPostFromLink = true;
    this.setState({ tweets }, () => {
      api.generateSocialPostFromLink(
        publication._id,
        sourceLink,
        regenerate,
        socialMediaType.TWITTER,
        postId || "",
        tweets[index]?.selectedAccount?.userId || "",
        tweets[index]?.samples || [], // samples
        (res: any) => {
          if (res.status === 200) {
            let { socialPost } = res.data;

            // Create interval to fetch the social post from link
            if (socialPost?.status !== generateSocialPostStatus.COMPLETED) {
              this.setIntervalToFetchSocialPostFromLink(index);
              return;
            }

            tweets[index].fetchingSocialPostFromLink = false;
            this.setState({ tweets });

            this.clearFetchSocialPostFromLinkInterval(index);

            if (!socialPost?.body) {
              notify.show(
                "Oops, we couldn't generate the social post for you. Please try again later.",
                "error"
              );
              return;
            }

            prevSocialPostText.push(tweets[index]?.text || "");
            this.setState({
              prevSocialPostText,
              socialPostText: socialPost.body
            });

            this.handleTweetChange(socialPost.body, index);
          } else {
            notify.show(
              res.status === 400 ? res.data : "Something went wrong",
              "error"
            );
            tweets[index].fetchingSocialPostFromLink = false;
            this.setState({ tweets });
            this.clearFetchSocialPostFromLinkInterval(index);
          }
        }
      );
    });
  };

  saveSamples = (index: any, samples: any) => {
    let { tweets } = this.state;
    tweets[index].samples = samples?.map((sample: any) => sample?._id);
    this.setState({ tweets }, () => {
      this.saveTweetDetailsDebounce(index);
    });
  };

  onSourceLinkSubmit = (index: any) => {
    let { sourceLink, tweets } = this.state;

    if (tweets[index].fetchingSocialPostFromLink) {
      return;
    }

    if (!validator.isURL(sourceLink)) {
      notify.show("Please provide valid URL", "error");
      return;
    }

    this.getSocialPostFromLink(index);
  };

  setTemplate = (inputField: string, value: string) => {
    this.setState({ [inputField]: value });
  };

  showAIGenerateDialog = () => {
    this.setState({
      showAIGeneratePostDialog: true
    });
  };

  closeAIGeneratePostDialog = () => {
    this.setState({
      showAIGeneratePostDialog: false
    });
  };

  onAIPostGenerate = (index: number, value: string) => {
    if (value === generateFrom.URL) {
      this.onSourceLinkSubmit(index);
    } else {
      this.generateSocialPost(index, true, true);
    }

    this.closeAIGeneratePostDialog();
  };

  onSourceLinkSubmit = (index: any) => {
    let { sourceLink, tweets } = this.state;
    if (tweets[index].fetchingSocialPostFromLink) {
      return;
    }

    if (!validator.isURL(sourceLink)) {
      notify.show("Please provide valid URL", "error");
      return;
    }

    this.getSocialPostFromLink(index);
  };

  saveSamples = (index: any, samples: any) => {
    let { tweets } = this.state;
    tweets[index].samples = samples?.map((sample: any) => sample?._id);
    this.setState({ tweets }, () => {
      this.saveTweetDetailsDebounce(index);
    });
  };

  renderTweet = (tweet: any, tweetIndex: any) => {
    let { classes, isReviewScreen = false } = this.props;
    let { approvalRequested, post } = this.state;

    return (
      <Fragment key={tweetIndex}>
        <div
          style={{
            marginTop: 40,
            width: "100%",
            display: "flex-end",
            textAlign: "right"
          }}
        >
          {tweetIndex !== 0 && <Divider style={{ margin: 20 }} />}
          {tweet.status === tweetStatus.COMPLETED && (
            <div className={classes.editableLabelDiv}>
              <Typography variant="bodys" className={classes.editableLabel}>
                This post is published and can’t be updated
              </Typography>
            </div>
          )}
        </div>

        <Grid
          container
          direction="row"
          className={classes.section}
          style={{ marginTop: tweetIndex !== 0 ? 30 : 20 }}
        >
          {this.renderTweetSection(tweet, tweetIndex)}
          {this.renderTweetPreviewSection(tweet, tweetIndex)}
          {tweet.status !== tweetStatus.COMPLETED && (
            <Grid
              container
              style={{
                marginBottom: 20,
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid>
                <StyledButton
                  variant="destructivesecondary"
                  onClick={() => this.showConfirmDialog(tweetIndex)}
                  color="secondary"
                >
                  Delete draft
                </StyledButton>
                {(isReviewScreen || post) && (
                  <StyledButton
                    variant="textsecondary"
                    onClick={this.showApprove}
                    style={{ marginLeft: space.SMALL }}
                  >
                    {approvalRequested ? "Change Approver" : "Get Approved"}
                  </StyledButton>
                )}
              </Grid>
              <Tooltip title="Copy everywhere">
                <IconButton
                  onClick={() => this.toggleShowCopyConfirmation(tweetIndex)}
                  style={{ marginRight: `${space.SMALL}` }}
                  size="large"
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  };

  renderTweetSection = (tweet: any, tweetIndex: any) => {
    let { classes, isReviewScreen } = this.props;
    let {
      postUrl,
      twitters,
      post,
      publication,
      freeInputSource,
      sourceLink,
      showAIGeneratePostDialog
    } = this.state;

    let showAdditionalPublishType =
      post &&
      ((post.status && post.status !== postStatus.PUBLISHED) ||
        post.scheduledOn);
    return (
      <Grid
        id="account_and_editor"
        container
        item
        xs={6}
        className={
          isReviewScreen
            ? classes.tweetSectionReviewScreen
            : classes.tweetSection
        }
      >
        <Grid
          container
          item
          id="Account"
          xs={12}
          className={classes.accountSection}
        >
          <Typography className={classes.cardTitle}>Account</Typography>

          <Typography className={classes.cardSubtitle}>
            Which account do you want to post to?
          </Typography>

          <Grid item>
            <FormControl
              variant="outlined"
              size="medium"
              fullWidth
              className={classes.input}
            >
              <StyledSelect
                value={tweet.selectedAccount || ""}
                onChange={(event: any) =>
                  this.handleAccountChange(event, tweetIndex)
                }
                size="large"
                disabled={tweet.status === tweetStatus.COMPLETED}
                className={classes.input}
                renderValue={() => {
                  return (
                    <div className={classes.selectedAccount}>
                      <Avatar
                        variant="circular"
                        src={tweet.selectedAccount?.profilePicture}
                        alt="Twitter profile picture"
                        className={classes.avatar}
                      />

                      <Typography className={classes.userName}>
                        {tweet.selectedAccount?.fullName}
                      </Typography>
                    </div>
                  );
                }}
              >
                {twitters.map((twitter: any) => {
                  return (
                    <StyledMenuItem value={twitter} key={twitter}>
                      <Avatar
                        variant="circular"
                        src={twitter.profilePicture}
                        alt="Twitter profile picture"
                        className={classes.avatar}
                      />

                      <Typography className={classes.userName}>
                        {twitter.fullName}
                      </Typography>
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          item
          id="Tweet"
          xs={12}
          className={classes.accountSection}
        >
          <Typography className={classes.cardTitle}>Twitter Thread</Typography>

          <Typography className={classes.cardSubtitle}>
            Type <span className={classes.tweetSplitter}>{"[...]"}</span> to
            separate tweets. You can{" "}
            <span className={classes.mentionText}>{" @mention "}</span> accounts
            and use <span className={classes.mentionText}>{" #hashtags "}</span>
          </Typography>

          <Grid item>
            <div className={classes.urlContainer}>
              <div style={{ width: "100%" }}>
                <StyledInput
                  placeholder="Start writing your Twitter thread. Use [...] to start a new tweet in the thread."
                  multiline
                  disabled={tweet.status === tweetStatus.COMPLETED}
                  maxRows={8}
                  minRows={5}
                  name="title"
                  value={tweet.text || ""}
                  onChange={(event: any) =>
                    this.handleTweetChange(event.target.value, tweetIndex)
                  }
                  className={classes.textAreaOutline}
                  onKeyDown={(e: any) => {
                    // if ctrl + z pressed
                    if (e.ctrlKey && e.keyCode === 90) {
                      let { prevSocialPostText } = this.state;
                      if (prevSocialPostText.length) {
                        let oldValue = prevSocialPostText.pop();
                        this.setState({ prevSocialPostText });
                        this.handleTweetChange(oldValue, tweetIndex);
                      }
                    }
                  }}
                />
              </div>

              <div>
                {(tweet?.socialPostLoading ||
                  tweet?.fetchingSocialPostFromLink) && (
                  <CircularProgress
                    style={{ marginLeft: 20, marginRight: -60 }}
                    size={25}
                  />
                )}
              </div>
            </div>

            <div
              style={{
                marginLeft: space.SMALL,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: space.SMALL
              }}
            >
              <StyledButton
                startIcon={<AutoFixHighOutlinedIcon />}
                variant="primary"
                size="small"
                onClick={() =>
                  isReviewScreen
                    ? this.generateSocialPost(tweetIndex)
                    : this.showAIGenerateDialog()
                }
              >
                Generate Social post
              </StyledButton>
              {showAIGeneratePostDialog && (
                <AIGeneratePost
                  index={tweetIndex}
                  freeInputSource={freeInputSource}
                  sourceLink={sourceLink}
                  showAIGeneratePostDialog={showAIGeneratePostDialog}
                  setTemplate={this.setTemplate}
                  onAIPostGenerate={this.onAIPostGenerate}
                  closeAIGeneratePostDialog={this.closeAIGeneratePostDialog}
                  publicationId={publication._id}
                  saveSamples={this.saveSamples}
                  selectedSampleIds={tweet?.samples || []}
                  type={channelTypes.TWITTER}
                />
              )}

              <StyledButton
                startIcon={<PeopleOutlineIcon />}
                variant="textsecondary"
                size="small"
                onClick={() => this.showInstruction(true, tweetIndex)}
              >
                Personalize with template
              </StyledButton>
            </div>
          </Grid>
        </Grid>

        {postUrl && (
          <Grid
            container
            item
            id="Post"
            xs={12}
            className={classes.accountSection}
          >
            <Typography className={classes.cardTitle}>
              Link to your post
            </Typography>

            <Typography className={classes.cardSubtitle}>
              Copy this link to your last tweet in thread, or use{" "}
              {URL_REPLACE_TEXT}, and we'll replace it with the final URL.
            </Typography>

            <Grid item>
              <InputBase
                className={classes.postUrl}
                value={postUrl + "?utm=" + tweet.utm}
                multiline={true}
                contentEditable={false}
                onFocus={(event) => event.target.select()}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <CopyToClipboard
                      onCopy={() =>
                        notify.show("Copied to clipboard", "success")
                      }
                      text={postUrl + "?utm=" + tweet.utm}
                    >
                      <IconButton size="large">
                        <FileCopyOutlinedIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        )}

        <Grid
          container
          item
          id="Post"
          xs={12}
          className={classes.accountSection}
          style={{ marginBottom: 20 }}
        >
          <Typography className={classes.cardTitle}>When</Typography>

          <Typography className={classes.cardSubtitle}>
            Schedule when your tweet should go out{" "}
            {post && (
              <Tooltip title="Scheduled time change will only reflect after post is updated">
                <InfoIcon className={classes.errorIcon} />
              </Tooltip>
            )}
          </Typography>

          <Grid item>
            <FormControl
              variant="outlined"
              size="medium"
              fullWidth
              className={classes.input}
            >
              <StyledSelect
                value={tweet.publishType}
                disabled={tweet.status === tweetStatus.COMPLETED}
                onChange={(event: any) =>
                  this.handlePublishTypeChange(event, tweetIndex)
                }
                size="large"
                className={classes.input}
              >
                {!post && (
                  <StyledMenuItem value={tweetPublishType.NOW}>
                    Now
                  </StyledMenuItem>
                )}

                <StyledMenuItem
                  value={tweetPublishType.LATER}
                  onClick={() => {
                    this.handlePublishLater(tweetIndex);
                  }}
                >
                  {tweet.publishType === tweetPublishType.LATER &&
                  tweet.scheduledDate
                    ? this.getScheduleDate(tweet.scheduledDate)
                    : "Later"}
                </StyledMenuItem>
                {post && (
                  <StyledMenuItem value={tweetPublishType.WITH_POST}>
                    Same time as the blog post
                  </StyledMenuItem>
                )}
                {(showAdditionalPublishType ||
                  tweet.publishType === tweetPublishType.TEN_MINUTES_LATER) && (
                  <StyledMenuItem value={tweetPublishType.TEN_MINUTES_LATER}>
                    10 minutes later
                  </StyledMenuItem>
                )}
                {(showAdditionalPublishType ||
                  tweet.publishType ===
                    tweetPublishType.THIRTY_MINUTES_LATER) && (
                  <StyledMenuItem value={tweetPublishType.THIRTY_MINUTES_LATER}>
                    30 minutes later
                  </StyledMenuItem>
                )}
                {(showAdditionalPublishType ||
                  tweet.publishType === tweetPublishType.HOUR_LATER) && (
                  <StyledMenuItem value={tweetPublishType.HOUR_LATER}>
                    1 hour later
                  </StyledMenuItem>
                )}
                {(showAdditionalPublishType ||
                  tweet.publishType === tweetPublishType.DAY_LATER) && (
                  <StyledMenuItem value={tweetPublishType.DAY_LATER}>
                    A day later
                  </StyledMenuItem>
                )}
              </StyledSelect>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  getURLWithProtocol = (url: any) => {
    const URL_REGEX = /^https?:\/\//;
    if (!URL_REGEX.test(url)) {
      url = "https://" + url;
    }
    return url;
  };

  /**
   * Formats a URL for Twitter preview by extracting the hostname and path,
   * truncating the path to a maximum length of 15 characters, and replacing the rest with "..." to mimic Twitter's behavior.
   * Please note that this method is based on observation and may become inaccurate if Twitter changes its display logic.
   *
   * @param {string} url - The input URL to be formatted.
   * @returns {string|null} - The formatted URL or null if the input URL is invalid.
   */
  formatURLForTwitterPreview(url: any) {
    try {
      url = this.getURLWithProtocol(url); // twitter supports URL with/witout protocol

      let urlObj = new URL(url);
      let { hostname, pathname } = urlObj;
      let maxLength = 15;

      let truncatedPath = pathname;
      if (pathname.length > maxLength) {
        truncatedPath = pathname.substring(0, maxLength) + "...";
      }

      if (hostname.startsWith("www.")) {
        hostname = hostname.substring(4); // remove www prefix
      }

      if (truncatedPath === "/") {
        truncatedPath = ""; // if pathname does not exist, remove the slash
      }

      return `${hostname}${truncatedPath}`;
    } catch (error) {
      console.error("Invalid URL:", error);
      return url;
    }
  }

  renderTweetPreviewSection = (tweet: any, tweetIndex: any) => {
    let { classes, isReviewScreen = false } = this.props;

    return (
      <Grid
        id="thread_preview"
        container
        item
        xs={6}
        className={classes.previewSection}
      >
        <div className={classes.previewWrapper}>
          {tweet.threads.map((thread: any, threadIndex: any) => {
            let showImageIcon =
              tweet.threads[threadIndex] &&
              tweet.status !== tweetStatus.COMPLETED &&
              (!tweet.threadImages[threadIndex] ||
                tweet.threadImages[threadIndex].length < 4)
                ? true
                : false;

            let showRuler =
              tweet.threads.length > 1 &&
              tweet.threads.length - 1 !== threadIndex;
            return (
              <Grid
                key={threadIndex}
                container
                direction="row"
                className={classes.threadSection}
              >
                <Grid
                  id="avatar_and_ruler"
                  item
                  className={classes.avatarContainer}
                >
                  <Avatar
                    variant="circular"
                    src={tweet.selectedAccount?.profilePicture}
                    alt="Twitter profile picture"
                    className={classes.previewAvatar}
                  />
                  {showRuler && (
                    <div className={classes.rulerContainer}>
                      <div className={classes.verticalRuler} />
                    </div>
                  )}
                </Grid>

                <Grid
                  id="thread_and_images"
                  item
                  sm={isReviewScreen ? 6 : 8}
                  md={isReviewScreen ? 8 : 10}
                  className={classes.threadText}
                >
                  <Typography style={{ marginBottom: 2 }} variant="bodyl">
                    <span style={{ fontWeight: "700" }}>
                      {tweet.selectedAccount?.fullName}
                    </span>
                    {
                      tweet.selectedAccount?.screenName && (
                        <span style={{ color: colors.fontSecondary }}>
                          {" "}
                          @{tweet.selectedAccount.screenName} &#8226; 1m
                        </span>
                      ) // &#8226; - Dot symbol
                    }
                  </Typography>

                  <div
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "break-spaces",
                      color:
                        tweet.threads.length <= 1 && !thread
                          ? colors.fontSecondary
                          : colors.black
                    }}
                  >
                    {this.renderThread(tweet, thread)}
                  </div>

                  {tweet.threadImages[threadIndex]?.map(
                    (image: any, imageIndex: any) => {
                      //Displaying images if exists
                      return (
                        <div
                          className={classes.threadImageContainer}
                          key={imageIndex}
                        >
                          <img
                            alt="thread pic"
                            src={image}
                            className={classes.threadImage}
                          />
                          {tweet.status !== tweetStatus.COMPLETED && (
                            <IconButton
                              className={classes.closeIcon}
                              onClick={() =>
                                this.removeImageFromThread(
                                  tweetIndex,
                                  threadIndex,
                                  imageIndex
                                )
                              }
                              size="large"
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </div>
                      );
                    }
                  )}
                  {showImageIcon && (
                    <Tooltip title="Upload image">
                      <IconButton
                        className={classes.imageIconContainer}
                        onClick={() =>
                          this.toggleImagePicker(tweetIndex, threadIndex)
                        }
                        size="large"
                      >
                        <ImageIcon
                          disabled={tweet.status === tweetStatus.COMPLETED}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </div>
      </Grid>
    );
  };

  renderThread = (tweet: any, thread: any, threadIndex: any) => {
    let { classes } = this.props;

    let { postUrl } = this.state;
    if (thread.trim()) {
      return thread
        .trim()
        .split("\n")
        .map((eachParaGraph: any, index: any, paragraphs: any) => {
          if (eachParaGraph) {
            if (postUrl && eachParaGraph.indexOf(URL_REPLACE_TEXT) > -1) {
              eachParaGraph = replaceAll(
                eachParaGraph,
                URL_REPLACE_TEXT,
                postUrl + "?utm=" + tweet.utm
              );
            }
            let mentionAndUrls = eachParaGraph
              .split(" ")
              .map((eachWord: any) => {
                if (
                  (eachWord.startsWith("@") && eachWord !== "@") ||
                  isURL(eachWord.replace("\n"), { require_protocol: false }) // twitter supports URL with/witout protocol
                ) {
                  let href = eachWord.startsWith("@")
                    ? `https://www.twitter.com/${eachWord}`
                    : eachWord;
                  return (
                    <>
                      <a
                        href={href}
                        target="_blank"
                        className={classes.mentionText}
                        rel="noreferrer"
                      >
                        {
                          eachWord.startsWith("@")
                            ? eachWord // It's a mention
                            : this.formatURLForTwitterPreview(eachWord) // It's a URL
                        }
                      </a>{" "}
                    </>
                  );
                }
                return eachWord + " ";
              });

            return <p className={classes.noMargins}>{mentionAndUrls}</p>;
          }
          return paragraphs?.length - 1 === index ? (
            ""
          ) : (
            <p className={classes.noMargins}>&nbsp;</p>
          );
        });
    }

    if (tweet.threads.length <= 1 && !tweet.threadImages[threadIndex]) {
      return "Select an account and start writing to see a preview of your thread";
    }
  };

  handleImagePickerClose = () => {
    if (this.imagePickerRef?.checkImageUploading?.()) {
      return;
    }
    this.setState({
      openImagePicker: false
    });
  };

  showApprove = () => {
    let { showApproverDialog } = this.state;
    this.setState({ showApproverDialog: !showApproverDialog });
  };

  onApproverChange = (e: any, values: any) => {
    this.setState({ tweetApprovers: values });
  };

  setApprovers = () => {
    let { tweets, tweetApprovers } = this.state;

    if (tweetApprovers.length === 0) {
      notify.show("Select one or more approvers", "error");
      return;
    }

    tweets.forEach((tweet: any) => {
      tweet.approvers = tweetApprovers;
    });

    this.setState(
      {
        tweets,
        showApproverDialog: false,
        approvalRequested: true
      },
      this.requestTweetReview
    );
  };

  doesApprovalRequested = (incomingApprover: any) => {
    let { tweets } = this.state;
    return (
      tweets[0]?.approvers?.some(
        (selectedApprover: any) => selectedApprover._id === incomingApprover._id
      ) || false
    );
  };

  approveAndShareToTwitter = () => {
    let { publication, tweets } = this.state;

    let valid = true;
    for (let tweet of tweets) {
      if (tweet.scheduledDate && moment().isAfter(tweet.scheduledDate)) {
        valid = false;
        notify.show("Scheduled date is in the past.", "error");
        break;
      }
    }

    if (!valid) {
      return;
    }

    this.setState({ shareInitialized: true }, () => {
      api.approveTweet(publication._id, tweets, (res: any) => {
        if (res.status === 200) {
          notify.show(
            "We're uploading your post to twitter. Please wait a few seconds.",
            "success"
          );
          let toUrl = `/${publication._id}/twitter/drafts`;

          this.props.history.push(toUrl);
        } else {
          this.setState({
            shareInitialized: false
          });
          notify.show("Oops, something went wrong.", "error");
        }
      });
    });
  };

  generateActionButtons = () => {
    let { classes } = this.props;
    let {
      isApprover,

      shareInitialized,

      tweets,

      approvalRequested,

      publication
    } = this.state;
    let accountAndTextAdded = true;

    for (let tweet of tweets) {
      if (!tweet.selectedAccount || tweet.text.length === 0) {
        accountAndTextAdded = false;
        break;
      }
    }

    if (!accountAndTextAdded) {
      return <></>;
    }

    let isPublishAccessDisabled = isAccessDisabled(
      publication._id,
      this.context,
      pageAccess.PUBLISH_POST
    );

    let approved = tweets[0]?.approvedBy || false;
    let displayReviewDetails =
      !approved && tweets[0]?.approvalRequestedBy?.name;
    let showGetApprovalButtonPrimary = isPublishAccessDisabled && !approved; // if I can't publish, I'm forced to wait for a review
    let showGetApprovalButtonSecondary = !isPublishAccessDisabled && !approved; // if I can publish but have requested a review

    return (
      <div>
        {approved && ( // check if it has already been approved, in which case we show the label
          <Typography variant="bodys" style={{ marginRight: space.SMALL }}>
            Approved by {tweets[0]?.approvedBy?.name}
          </Typography>
        )}
        {displayReviewDetails && (
          <Typography variant="bodys" style={{ marginRight: space.SMALL }}>
            Review requested by {tweets[0]?.approvalRequestedBy?.name}
          </Typography>
        )}
        {showGetApprovalButtonSecondary && (
          <StyledButton
            variant="textsecondary"
            onClick={this.showApprove}
            style={{ marginRight: space.SMALL }}
          >
            {approvalRequested ? "Change Approver" : "Get Approved"}
          </StyledButton>
        )}

        {showGetApprovalButtonPrimary && (
          <StyledButton onClick={this.showApprove}>
            {approvalRequested ? "Change Approver" : "Get Approved"}
          </StyledButton>
        )}

        {!isPublishAccessDisabled && ( // if user can publish themselves or approve something for publishing
          <StyledButton
            onClick={this.approveAndShareToTwitter}
            startIcon={shareInitialized ? "" : <SendIcon size="small" />}
          >
            {shareInitialized && (
              <CircularProgress
                size={20}
                color="inherit"
                style={{ marginRight: 10 }}
              />
            )}
            {shareInitialized
              ? "Publishing..."
              : approved || !approvalRequested
              ? "Publish"
              : "Approve and Publish"}
          </StyledButton>
        )}
      </div>
    );
  };

  render() {
    let { classes, isReviewScreen = false, postId } = this.props;
    let {
      loading,

      error,

      errorMessage,

      publication,

      tweets,

      openImagePicker,

      twitters,

      showDateTimePicker,

      showDeleteConfirmDialog,

      tweetId,

      tweetIndex,

      draftPostsCount,

      tweetApprovers,

      teammembers,

      showApproverDialog,

      showCopyConfirmation,

      scheduledPostDates,

      showInstructionDialog,

      instructions,

      instructionDialogIndex
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Page404 errorMessage={errorMessage} />;
    }

    return (
      <Container
        style={{
          padding: isReviewScreen ? 0 : "24px 24px 100px 24px"
        }}
      >
        {!isReviewScreen && (
          <>
            <Helmet>
              <title>
                Twitter {publication && "- " + publication.name} | Letterdrop
              </title>
            </Helmet>
          </>
        )}

        <Paper
          className={clsx(
            isReviewScreen ? classes.paper : classes.reducedPaperMargin
          )}
        >
          {!isReviewScreen && (
            <>
              <div className={classes.header}>
                <IconButton disabled size="large">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className={classes.twitterIcon}
                  />

                  <Typography variant="h500" className={classes.headerText}>
                    Create a Twitter Thread
                  </Typography>
                </IconButton>
              </div>

              <div className={classes.headerBar}>
                <div className={classes.shareToTwitter}>
                  <StyledButton
                    className={classes.twitterDraft}
                    variant="textsecondary"
                    size="small"
                    onClick={() => {
                      this.props.history.push(
                        `/${publication._id}/twitter/drafts`
                      );
                    }}
                  >
                    Drafts{" "}
                    <Typography
                      variant="h100"
                      style={{
                        marginLeft: 5,
                        color: designColors.grayScale[80]
                      }}
                    >
                      {draftPostsCount}
                    </Typography>
                  </StyledButton>
                </div>

                {twitters && twitters.length > 0 && tweets?.length > 0 && (
                  <div className={classes.shareToTwitter}>
                    {this.generateActionButtons()}
                  </div>
                )}
              </div>
            </>
          )}
          {!twitters || !twitters.length ? (
            <Grid
              container
              direction="row"
              className={classes.connectAccountSection}
            >
              <img
                src={ImagesList.socialMedia}
                className={classes.connectAccountImage}
                alt="Connect twitter account"
              />

              <Typography className={classes.connectAccountText}>
                Connect a Twitter account to start posting
              </Typography>

              <StyledButton
                onClick={this.connectToTwitter}
                style={{
                  background: colors.twitter,
                  color: colors.white,
                  marginTop: 25
                }}
                disabled={firebase.auth().currentUser ? false : true}
                startIcon={
                  <FontAwesomeIcon
                    icon={faTwitter}
                    title="Twitter"
                    style={{
                      color: colors.white
                    }}
                  />
                }
              >
                Connect to Twitter
              </StyledButton>
            </Grid>
          ) : (
            <Fragment>
              {tweets.map((tweet: any, tweetIndex: any) => {
                return this.renderTweet(tweet, tweetIndex);
              })}

              <Divider />
              {!tweetId && (
                <Grid
                  id="add_another_account"
                  container
                  justifyContent="space-between"
                  className={classes.anotherAccount}
                >
                  <StyledButton
                    variant="secondary"
                    onClick={this.handlePostToAnotherAccount}
                    startIcon={<AddCircleIcon />}
                  >
                    {tweets.length
                      ? "Post to another account"
                      : "Post to account"}
                  </StyledButton>
                </Grid>
              )}
            </Fragment>
          )}

          <Popover
            id="thread_image_picker"
            open={openImagePicker}
            anchorEl={<div />}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center"
            }}
            onClose={this.handleImagePickerClose}
          >
            <ImagePicker
              unsplashRef={(ref: any) => {
                this.imagePickerRef = ref;
              }}
              onImageSelect={this.onImageSelected}
              onImageUpload={this.onSelectFile}
              postPictures={[]}
              showUnsplashPicker={true}
            />
          </Popover>

          <CustomFlatPicker
            publicationId={publication._id}
            open={showDateTimePicker}
            value={tweets[tweetIndex]?.scheduledDate}
            disablePast={true}
            onClose={(date) => {
              this.setState(
                {
                  showDateTimePicker: false
                },

                this.onScheduleDateChange(date)
              );
            }}
            type={datePickerDefault.SOCIAL}
            scheduledDates={scheduledPostDates.map(
              (date: any) => new Date(date)
            )}
          />
        </Paper>

        <StyledDialog
          open={showDeleteConfirmDialog}
          title="Are you sure?"
          body="Once deleted, you can't recover your post"
          successButtonName="Yes, I'm sure"
          successCallback={this.deleteTweet}
          cancelCallback={() => {
            this.setState({
              showDeleteConfirmDialog: false
            });
          }}
        />

        <StyledDialog
          open={showApproverDialog}
          title="Who needs to approve this?"
          body={
            <Autocomplete
              fullWidth
              value={tweetApprovers}
              multiple
              options={teammembers}
              disableCloseOnSelect
              getOptionDisabled={(option) => {
                return this.doesApprovalRequested(option);
              }}
              getOptionLabel={(option) => option?.client?.name}
              isOptionEqualToValue={(option, value) => {
                return option._id === value._id;
              }}
              onChange={this.onApproverChange}
              renderOption={(props, option, { selected }) => {
                return (
                  <div key={option._id} {...props}>
                    <StyledCheckbox
                      checked={selected}
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <Avatar
                            src={option?.client?.profilePic}
                            alt="profile pic"
                            style={{
                              width: 20,
                              height: 20,
                              margin: "auto 10px"
                            }}
                          />

                          <Typography>{option?.client?.name}</Typography>
                        </div>
                      }
                    />
                  </div>
                );
              }}
              renderInput={(params) => (
                <StyledInput
                  autoFocus
                  {...params}
                  variant="outlined"
                  size="large"
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  return (
                    <StyledUserChip
                      avatar={
                        <Avatar
                          src={option?.client?.profilePic}
                          alt="profile pic"
                        />
                      }
                      label={option?.client?.name}
                      {...getTagProps({ index })}
                      disabled={this.doesApprovalRequested(option)}
                    />
                  );
                })
              }
            />
          }
          successButtonName="Request review"
          cancelButtonName="Cancel"
          successCallback={this.setApprovers}
          cancelCallback={() => {
            this.setState({
              showApproverDialog: false
            });
          }}
        />

        <StyledDialog
          open={showCopyConfirmation}
          title="Do you want to copy this to other accounts?"
          body="We'll overwrite the body, time, and other details of other accounts."
          closeCallback={() => this.toggleShowCopyConfirmation(DEFAULT_INDEX)}
          cancelCallback={() => this.toggleShowCopyConfirmation(DEFAULT_INDEX)}
          successCallback={() => this.copyEverywhere()}
          successButtonName="OK"
          maxWidth="sm"
          fullWidth
        />
        {showInstructionDialog && (
          <AIInstructionsDialog
            open={showInstructionDialog}
            value={instructions}
            onSaveInstructions={this.onSaveInstructions}
            onClose={() => this.showInstruction(false)}
            publicationId={publication._id}
            postId={postId}
            type={channelTypes.TWITTER}
            index={instructionDialogIndex}
            saveSamples={this.saveSamples}
            selectedSampleIds={tweets?.[instructionDialogIndex]?.samples || []}
          />
        )}
      </Container>
    );
  }
}

export default withStyles(styles)(TwitterShare);
