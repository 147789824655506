// @ts-nocheck
import apisauce from "apisauce";
import firebase from "firebase/app";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;

const create = (baseURL = SERVER_URL) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": SERVER_URL,
      "Access-Control-Allow-Methods": "GET,HEAD,PUT,POST,DELETE,PATCH,OPTIONS"
    },
    timeout: 180000
  });

  const createApiCall = (endpoint: any, params: any, callback: any) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        params["idToken"] = idToken;
        api.post("/google/" + endpoint, params).then((res) => {
          callback(res);
        });
      });
  };

  const getOAuthDetails = (
    publicationId: any,
    redirectURI: any,
    callback: any
  ) => {
    createApiCall("getoauthdetails", { publicationId, redirectURI }, callback);
  };

  const getGAOAuthDetails = (
    publicationId: any,
    redirectURI: any,
    callback: any
  ) => {
    createApiCall(
      "getgaoauthdetails",
      { publicationId, redirectURI },
      callback
    );
  };

  const getYoutubeOAuthDetails = (
    publicationId: string,
    redirectURI: string,
    callback: any
  ) => {
    createApiCall(
      "getyoutubeauthdetails",
      { publicationId, redirectURI },
      callback
    );
  };

  const savePropertyIdGA = (
    publicationId: any,
    propertyId: any,
    callback: any
  ) => {
    createApiCall("savegapropertyid", { publicationId, propertyId }, callback);
  };

  const createGSCAccessToken = (
    code: any,
    publicationId: any,
    redirectURI: any,
    callback: any
  ) => {
    createApiCall(
      "creategscaccesstoken",
      { code, publicationId, redirectURI },
      callback
    );
  };

  const createGAAccessToken = (
    code: any,
    publicationId: any,
    redirectURI: any,
    callback: any
  ) => {
    createApiCall(
      "creategaaccesstoken",
      { code, publicationId, redirectURI },
      callback
    );
  };

  const createYoutubeAccessToken = (
    code: string,
    publicationId: string,
    redirectURI: string,
    callback: any
  ) => {
    createApiCall(
      "createyoutubeaccesstoken",
      { code, publicationId, redirectURI },
      callback
    );
  };

  const clearGSCDetails = (publicationId: any, callback: any) => {
    createApiCall("cleargscdetails", { publicationId }, callback);
  };

  const saveSiteUrl = (siteURL: any, publicationId: any, callback: any) => {
    createApiCall("savesiteurl", { siteURL, publicationId }, callback);
  };

  const getGSCStats = (
    publicationId: any,
    searchQuery: any,
    limit: any,
    offset: any,
    dimensionSort: any,
    clickSort: any,
    impressionSort: any,
    avgCTRSort: any,
    avgPositionSort: any,
    selectedTab: any,
    callback: any
  ) => {
    createApiCall(
      "getgscstats",
      {
        publicationId,
        searchQuery,
        limit,
        offset,
        dimensionSort,
        clickSort,
        impressionSort,
        avgCTRSort,
        avgPositionSort,
        selectedTab
      },
      callback
    );
  };

  const getSiteAnalytics = (
    publicationId: any,
    statsType: any,
    startDate: any,
    endDate: any,
    callback: any
  ) => {
    createApiCall(
      "getsiteanalytics",
      {
        publicationId,
        statsType,
        startDate,
        endDate
      },
      callback
    );
  };

  return {
    getOAuthDetails,
    getGAOAuthDetails,
    getYoutubeOAuthDetails,
    createYoutubeAccessToken,
    savePropertyIdGA,
    createGSCAccessToken,
    createGAAccessToken,
    getGSCStats,
    saveSiteUrl,
    clearGSCDetails,
    getSiteAnalytics
  };
};

export default {
  create
};
