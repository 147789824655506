// @ts-nocheck
const styles = (theme: any) => ({
  listSection: {
    height: "calc(100% - 50px)",
    padding: 24,
    overflowX: "hidden",
    overflowY: "auto"
  },

  listItem: {
    marginBottom: "7px",
    display: "flex",
    padding: 0,
    marginLeft: 3
  },

  navigationItemWrapper: {
    display: "flex",
    justifyContent: "center"
  },

  naviItemText: {
    transition: "0.7s ease",
    opacity: 1,
    visibility: "visible",
    width: "auto",
    marginLeft: 12
  },

  collapsedNaviItemText: {
    transition: "0.7s ease",
    opacity: 0,
    visibility: "hidden",
    width: "0px",
    marginLeft: 0
  },

  collapseIconSection: {
    height: 50,
    marginBottom: "7px",
    display: "flex",
    padding: "0px 24px",
    paddingLeft: 0,
    marginTop: "16px",
    flexShrink: 0,
    marginLeft: 12,
    transition: "0.7s ease"
  },

  collapseIcon: {
    width: 48,
    height: 48,
    background: "transparent",
    borderRadius: "12px"
  }
});

export default styles;
