// @ts-nocheck
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  fileContainer: {
    position: "relative",
    width: 50,
    height: 60,
    margin: 10,
    borderRadius: 5
  },

  removeIcon: {
    position: "absolute",
    right: -10,
    top: -12,
    backgroundColor: colors.white,
    display: "flex",
    borderRadius: "50%",
    cursor: "pointer"
  },

  fileName: {
    textOverflow: "clip",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },

  imageStyle: { 
    borderRadius: 5 
  }
});

export default styles;
