// @ts-nocheck
import ReactQuill, { Quill } from "react-quill";
import React from "react";
import { emojis, Emoji } from "@nutrify/quill-emoji-mart-picker";
import "@nutrify/quill-emoji-mart-picker/emoji.quill.css";
import "emoji-mart/css/emoji-mart.css";
import {
  stringToColor,
  getAvatarName,
  processEmojiCompletion,
  getTextLengthOfHTML
} from "../Utils/UserUtils";
import Mention from "../EditorComponents/quillmention/quill.mention";
import MentionBlot from "../EditorComponents/quillmention/blots/mention";
import API from "../Services/Api";
import "../quill.bubble.linkedin.css";
import { debounce } from "lodash";
import bussinessImage from "../Images/icon24/business.svg";

const LINKEDIN_PEOPLE_FETCH_DEBOUNCE_INTERVAL = 300;

const api = API.create();

Quill.register("modules/emoji-module", Emoji);
Quill.register("modules/mention", Mention);
Quill.register("formats/mention", MentionBlot, true);
const NEWLINE_REGEX = /\n/g;
const BR_TAG = "<br>";
const LINKEDIN_COMMENT_CHAR_LIMIT = 1250;

class LinkedInInputBox extends React.Component {
  commentQuillRef: any;
  constructor(props: any) {
    super(props);

    this.state = {};
    this.commentQuillRef = React.createRef();
    this.debouncedGetLinkedInAccounts = debounce(
      api.getLinkedInAccounts,
      LINKEDIN_PEOPLE_FETCH_DEBOUNCE_INTERVAL
    );
  }

  componentDidMount = () => {
    if (
      this.commentQuillRef?.getEditor?.()?.root?.getAttribute("class") !==
      "linkedin-input-box"
    ) {
      this.commentQuillRef
        ?.getEditor?.()
        ?.root?.setAttribute("class", "linkedin-input-box");

      this.addOnChangeListener();
    }
  };

  addOnChangeListener = () => {
    try {
      let { characterLimit } = this.props;

      // If character limit is not set, return early.
      if (!characterLimit && characterLimit !== 0) {
        return;
      }

      this.commentQuillRef
        .getEditor()
        .on("text-change", (delta: any, oldDelta: any, source: any) => {
          try {
            let text = this.commentQuillRef.getEditor().root.innerHTML;
            let contentLength = getTextLengthOfHTML(text);

            // If character limit is exceeded, revert the change.
            if (contentLength > characterLimit) {
              this.commentQuillRef.getEditor().setContents(oldDelta);
            }
          } catch (error) {}
        });
    } catch (error) {}
  };

  modules = {
    toolbar: ["bold", "italic"],
    "emoji-module": {
      emojiData: emojis,
      preventDrag: true,
      showTitle: true,
      indicator: "*",
      convertEmoticons: true,
      convertShortNames: true,
      set: () => "google"
    },
    mention: {
      /**
       * The regular expression pattern /^[a-zA-Z0-9\s:@\-'.]*$/ matches any string that contains
       * only alphanumeric characters (a-z, A-Z, 0-9) or the characters :, @, ', . and -. The ^ and $ characters
       * at the beginning and end of the pattern, respectively,
       * ensure that the pattern matches the entire string and not just a substring.
       */
      allowedChars: /^[a-zA-Z0-9\s:@\-'.]*$/,
      mentionDenotationChars: [":", "@"],
      source: (searchTerm: any, renderList: any, mentionChar: any) => {
        if (mentionChar === ":") {
          processEmojiCompletion(searchTerm, renderList, mentionChar);
        } else if (mentionChar === "@") {
          this.processNameCompletion(searchTerm, renderList, mentionChar);
        }
      },
      renderItem: (item: any, searchTerm: any) => {
        if (item.emoji) {
          return item.value;
        } else {
          const imageUrl = item.image;
          if (imageUrl) {
            return `<span><img src="${imageUrl}" style="width: 25px; height: 25px; vertical-align: middle; border-radius: 50%;margin-right:8px">${
              item.name
            } ${
              item.type === "org"
                ? `• <img src="${bussinessImage}" style="width:18px;vertical-align:sub;" />`
                : ""
            }</span>`;
          } else {
            return `<span><span  style="display:inline-flex;align-items:center;width: 25px; height: 25px; vertical-align: middle; border-radius: 50%;background:${stringToColor(
              item.name
            )};color: white;padding: 4px;margin-right:8px;font-size:10px"><div style="width:100%;text-align: center;padding-top:1px;">${getAvatarName(
              item.name
            )}</div> </span>${item.name} ${
              item.type === "org"
                ? `• <img src="${bussinessImage}" style="width:18px;vertical-align:sub;" />`
                : ""
            }</span>`;
          }
        }
      },
      dataAttributes: ["type"]
    }
  };
  /**
   * Processes the name completion for mentions.
   * @param {string} searchTerm - The search term entered by the user.
   * @param {function} renderList - The function to render the list of names.
   * @param {string} mentionChar - The character used to denote a mention.
   */
  processNameCompletion = (
    searchTerm: any,
    renderList: any,
    mentionChar: any,
    onChange: any
  ) => {
    // If the search term is empty, return early.
    if (!searchTerm) {
      return;
    }

    try {
      let { publication } = this.props;

      // Call the API to fetch LinkedIn people data that match the search term.
      this.debouncedGetLinkedInAccounts(
        publication?._id || publication,
        searchTerm,
        this.props.account,
        (res: any) => {
          // If the response data is not an array, return early.
          if (!Array.isArray(res?.data)) return;

          const ids = new Set();
          const nameList = res.data
            // Fiter out duplicate items
            .filter((item) => {
              const id = item.id.toString();
              if (ids.has(id)) {
                return false;
              }
              ids.add(id);
              return true;
            })
            // Map the response data to an array of name objects.
            .map((item: any) => ({
              id: item.id,
              value: item.name,
              name: item.name,
              image: item.image,
              type: item.type || "person"
            }));

          // Render the list of names using the renderList function.
          renderList(nameList, searchTerm);
        }
      );
    } catch (error) {
      console.error("Error fetching names:", error);
    }
  };

  getModules = () => {
    return this.modules;
  };

  getQuillRef = () => {
    return this.commentQuillRef;
  };

  render() {
    let {
      value,
      publication,
      isPostBody,
      readOnly,
      onChange,
      placeholder,
      onKeyDown,
      isValueProp
    } = this.props;
    let inputProps = {};
    if (isValueProp) {
      inputProps.value = value?.replace(NEWLINE_REGEX, BR_TAG);
    }
    return (
      <ReactQuill
        ref={(el) => {
          this.commentQuillRef = el;
        }}
        className="linkedin-input-box"
        defaultValue={value?.replace(NEWLINE_REGEX, BR_TAG)}
        {...inputProps}
        theme="bubble"
        modules={this.getModules()}
        formats={["bold", "italic", "emoji", "mention"]}
        placeholder={placeholder || ""}
        scrollingContainer={"body"}
        onChange={(content) => {
          if (content === "<p><br></p>") {
            content = "";
          }
          if (
            isPostBody ||
            this.commentQuillRef?.getEditor?.()?.getText()?.trim()?.length <=
              LINKEDIN_COMMENT_CHAR_LIMIT
          ) {
            onChange?.(content);
          }
        }}
        readOnly={readOnly}
        onKeyDown={onKeyDown}
      />
    );
  }
}

export default LinkedInInputBox;
