// @ts-nocheck
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

import { withRouter } from "react-router";
import styles from "./styles/FilePreviewStyle";
import { uploadFileTypes, defaultImageUrl } from "../Utils/Types";

class FilePreview extends React.Component {
  render() {
    let { previewFiles, removeAttachment, fromTopicPage, classes } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: fromTopicPage ? 10 : 0
        }}
      >
        {previewFiles.map((previewFile: any, index: any) => {
          let srcUrl = defaultImageUrl.DOCS;
          if (previewFile.type.includes(uploadFileTypes.IMAGE)) {
            srcUrl = previewFile.url
              ? previewFile.url
              : URL.createObjectURL(previewFile);
          } else if (previewFile.type.includes(uploadFileTypes.VIDEO)) {
            srcUrl = defaultImageUrl.VIDEO;
          } else if (previewFile.type.includes(uploadFileTypes.PDF)) {
            srcUrl = defaultImageUrl.PDF;
          } else if (
            previewFile.type.includes(uploadFileTypes.CSV) ||
            previewFile.type.includes(uploadFileTypes.EXCEL)
          ) {
            srcUrl = defaultImageUrl.EXCEL;
          }
          return (
            <Tooltip title={previewFile.name} key={index}>
              <div className={classes.fileContainer}>
                <div
                  className={classes.removeIcon}
                  onClick={() => removeAttachment(index)}
                >
                  <CancelIcon />
                </div>

                <img
                  width="50"
                  height="50"
                  className={classes.imageStyle}
                  src={srcUrl}
                  alt={previewFile.name}
                />

                <Typography variant="bodys" className={classes.fileName}>
                  {previewFile.name}
                </Typography>
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(FilePreview));
