// @ts-nocheck
import colors, { designColors } from "../../Themes/Colors";

const styles = (theme: any) => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 10
  },

  profilePicture: {
    marginTop: 3,
    marginRight: 15
  },

  input: {
    width: "100%",
    border: "none",
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none"
    }
  },

  commentButton: {
    marginRight: -5,
    backgroundColor: "transparent",
    "&:hover": {
      color: designColors.primary[50],
      backgroundColor: "transparent"
    }
  },

  disabledVideoIcon: {
    color: designColors.grayScale[40],
    width: 24,
    height: 24
  },

  videoIcon: {
    width: 24,
    height: 24
  }
});

export default styles;
