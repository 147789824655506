// @ts-nocheck
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import ReorderIcon from "@mui/icons-material/Reorder";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Autocomplete,
  Popover,
  FormControlLabel
} from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import React, { Component, Suspense } from "react";
import Loading from "./Loading";
import StyledButton from "../design/components/StyledButton";
import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import API from "../Services/Api";
import {
  customFieldDataType,
  apiFetchStatus,
  InputFieldTypes,
  publicationType,
  customFieldsTypes
} from "../Utils/Types";
import { notify } from "./CustomNotifications";
import styles from "./styles/CustomFieldsStyle";
import EditIcon from "@mui/icons-material/Edit";
import StyledDialog from "../design/components/StyledDialog";
import moment from "moment";
import TagsInput from "./TagsInput";
import { arrayMove } from "react-movable";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import colors from "../Themes/Colors";
import { formatForDisplayDate } from "../Utils/TimeUtils";
import { space } from "../Config/theme";
import { loadInstagramScript, loadTikTokScript } from "../Utils/UserUtils";
import ImagePicker from "./ImagePicker";
import ImageIcon from "@mui/icons-material/Image";
import { uploadBlobImage } from "../Utils/Storage";
import StyledSwitch from "../design/components/StyledSwitch";

const Editor = React.lazy(() => import("../Components/Editor"));

const api = API.create();

class CustomFields extends Component {
  handleImagePickerClose: any;
  imagePickerRef: any;
  constructor(props: any) {
    super(props);

    let publication = props.publication;
    this.state = {
      publicationId: publication?._id || "",
      publication: "",
      openAddEditCustomFieldDialog: false,
      selectedCustomFieldId: null,
      name: "",
      type: "",
      helpText: "",
      requiredField: false,
      defaultValue: "",
      htmlText: "",
      allowedValue: [],
      maximumValue: null,
      minimumValue: null,
      minimumValueError: "",
      defaultValueError: "",
      selectedIndex: null,
      nameError: "",
      typeError: "",
      listOfDestinations: [],
      selectedDestination: "",
      showDestinations: false,
      openImagePicker: false,
      imageURL: "",
      defaultSyntaxHighlightingLanguage:
        publication?.defaultSyntaxHighlightingLanguage
    };
    this.imagePickerRef = React.createRef();
  }

  componentDidMount() {
    loadInstagramScript();
    loadTikTokScript();

    let { publicationId } = this.state;

    api.getConnectedBlogs(publicationId, (res: any) => {
      if (res.status === 200) {
        this.setState({
          listOfDestinations: res.data
        });
      }
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.fetchedCustomFields !== prevProps.fetchedCustomFields) {
      if (this.props.fetchedCustomFields === apiFetchStatus.FAIL) {
        notify.show("Oops, something went wrong.", "error");
      }
    }

    if (this.props.savedCustomFields !== prevProps.savedCustomFields) {
      if (this.props.savedCustomFields === apiFetchStatus.SUCCESS) {
        this.setState({
          nameError: "",
          typeError: "",
          minimumValueError: "",
          defaultValueError: ""
        });
      }

      if (this.props.savedCustomFields === apiFetchStatus.FAIL) {
        notify.show("Oops, something went wrong.", "error");
      }
    }
  }

  addCustomField = () => {
    this.setState({
      openAddEditCustomFieldDialog: true,
      selectedIndex: null,
      nameError: "",
      typeError: "",
      minimumValueError: "",
      defaultValueError: "",
      name: "",
      helpText: "",
      requiredField: false,
      type: "",
      defaultValue: "",
      selectedDestination: "",
      allowedValue: [],
      maximumValue: null,
      minimumValue: null
    });
  };

  deleteCustomField = (index: any) => {
    let { customFields } = this.props;
    customFields.splice(index, 1);

    this.props.saveCustomFields?.(customFields);
  };

  handleDestinationValueChange = (newValue: any) => {
    this.setState({
      selectedDestination: newValue
    });
  };

  editCustomField = (index: any) => {
    let { customFields, showDestinationSelection } = this.props;

    let { listOfDestinations, selectedDestination } = this.state;
    let {
      name,
      type,
      defaultValue,
      htmlText = "",
      allowedValue,
      minimumValue,
      maximumValue,
      helpText,
      required: requiredField
    } = customFields[index] || {};

    // Populate Destinations only in CustomFields dialog, not in CustomFieldProjects one
    if (showDestinationSelection) {
      selectedDestination = listOfDestinations.find((destination: any) => {
        return customFields[index]?.destination === destination?.id;
      });
    }

    // If type is rich text editor parse to object
    if (defaultValue && type === customFieldDataType.RICH_TEXT) {
      try {
        defaultValue = JSON.parse(defaultValue);
      } catch (error) {}
    }

    this.setState({
      openAddEditCustomFieldDialog: true,
      selectedIndex: index,
      name,
      helpText,
      requiredField,
      type,
      defaultValue,
      htmlText,
      allowedValue,
      minimumValue,
      maximumValue,
      nameError: "",
      typeError: "",
      selectedDestination,
      selectedCustomFieldId: customFields[index]?._id,
      minimumValueError: "",
      defaultValueError: ""
    });
  };

  fieldsRearranged = (customFields: any) => {
    this.setState(
      { customFields },

      this.props.saveCustomFields?.(customFields)
    );
  };

  changeInputValue = (event: any) => {
    let { name, value, type } = event.target;

    if (value && type === InputFieldTypes.NUMBER) {
      value = Number(value);
    }

    this.setState({
      [name]: value
    });
  };

  onRequiredFieldChange = (event: any) => {
    this.setState({ requiredField: event.target.checked });
  };

  changeFieldType = (event: any) => {
    this.setState({ type: event.target.value, defaultValue: "" });
  };

  closeAddEditCustomFieldDialog = () => {
    this.setState({
      openAddEditCustomFieldDialog: false
    });
  };

  saveOrUpdate = () => {
    let {
      selectedIndex,
      name,
      type,
      defaultValue,
      helpText,
      requiredField,
      htmlText,
      allowedValue,
      selectedDestination,
      minimumValue,
      maximumValue
    } = this.state;

    let { customFields } = this.props;

    if (!name) {
      this.setState({
        nameError: "Name your field."
      });
      return;
    }

    // If customField Data Type is Number
    if (
      [
        customFieldDataType.NUMBER,
        customFieldDataType.TEXT,
        customFieldDataType.RICH_TEXT
      ].includes(type)
    ) {
      // If user enters both min and max values
      if (
        !isNaN(parseInt(minimumValue)) &&
        !isNaN(parseInt(maximumValue)) &&
        minimumValue > maximumValue
      ) {
        this.setState({
          minimumValueError:
            "The lower limit needs to be smaller than the upper limit"
        });
        return;
      }

      // If user enters default value
      if (!isNaN(parseInt(defaultValue))) {
        // if min value exist, Compare with min value
        if (!isNaN(parseInt(minimumValue)) && defaultValue < minimumValue) {
          this.setState({
            defaultValueError:
              "The default needs to be greater than the lower limit"
          });
          return;
        }

        // if max value exist, Compare with max value
        if (!isNaN(parseInt(maximumValue)) && defaultValue > maximumValue) {
          this.setState({
            defaultValueError:
              "The default needs to be smaller than the upper limit"
          });
          return;
        }
      }
    }

    if (
      customFields.some(
        (customField: any, index: number) =>
          selectedIndex !== index &&
          customField.name.toLowerCase() === name.trim().toLowerCase() &&
          customField.destination === (selectedDestination?.id || "")
      )
    ) {
      this.setState({
        nameError:
          "There's another field with the same name in this destination. Try a different one."
      });
      return;
    }

    if (!type) {
      this.setState({
        typeError: "Choose a type for your field."
      });
      return;
    }

    //Set default values
    if (!defaultValue) {
      if (type === customFieldDataType.BOOLEAN) {
        defaultValue = false;
      }
      if (
        [customFieldDataType.TEXT, customFieldDataType.RICH_TEXT].includes(type)
      ) {
        defaultValue = "";
      }
      if (type === customFieldDataType.DATE) {
        defaultValue = "";
      }
      if (type === customFieldDataType.NUMBER) {
        defaultValue = 0;
        // if min value exist and default is less than min value
        if (!isNaN(parseInt(minimumValue)) && defaultValue < minimumValue) {
          defaultValue = minimumValue;
        } else if (
          !isNaN(parseInt(maximumValue)) &&
          defaultValue > maximumValue
        ) {
          //if max value exist and default is greater than max value
          defaultValue = maximumValue;
        }
      }
      if (type === customFieldDataType.MULTI_SELECT_LIST) {
        defaultValue = [];
      }
      if (type === customFieldDataType.SINGLE_SELECT_LIST) {
        defaultValue = [];
      }
    }

    // Trim the value if type is text
    if (type === customFieldDataType.TEXT) {
      defaultValue = defaultValue.trim();
    } else if (type === customFieldDataType.RICH_TEXT) {
      // Stringify the value if type is rich text
      defaultValue = JSON.stringify(defaultValue);
    }

    let fieldData = {
      name: name.trim(),
      type,
      helpText,
      required: requiredField,
      allowedValue,
      minimumValue,
      maximumValue,
      defaultValue,
      htmlText,
      destination: selectedDestination?.id || ""
    };

    // If index exist modify the custom field
    if (selectedIndex !== null && selectedIndex > -1) {
      customFields[selectedIndex] = fieldData;
    } else {
      // Otherwise add it as new custom field
      customFields.push(fieldData);
    }
    this.setState({
      selectedIndex: null,
      openAddEditCustomFieldDialog: false
    });

    this.props.saveCustomFields?.(customFields);
  };

  changeListDefaultValue = (newValues: any) => {
    this.setState({ defaultValue: newValues });
  };

  addListDefaultValues = (value: any) => {
    let { defaultValue } = this.state;
    this.changeListDefaultValue([...defaultValue, value]);
  };

  changeListAllowedValue = (newValues: any) => {
    this.setState({ allowedValue: newValues });
  };

  addListAllowedValues = (value: any) => {
    let { allowedValue } = this.state;
    this.changeListAllowedValue([...allowedValue, value]);
  };

  // On editor change
  onRichTextDefaultValueChange = (defaultValue: any, htmlText: any) => {
    this.setState({
      defaultValue,
      htmlText
    });
  };

  setDefaultSyntaxHighlightingLanguage = (
    defaultSyntaxHighlightingLanguage: any
  ) => {
    let { publicationId } = this.state;
    api.saveDefaultSyntaxHighlightingLanguage(
      publicationId,
      defaultSyntaxHighlightingLanguage,
      null,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            defaultSyntaxHighlightingLanguage: res.data
          });
        }
      }
    );
  };

  // Returns formatted value based on type
  getFieldValue = (field: any) => {
    let { htmlText, defaultValue, type } = field;
    if (!defaultValue) {
      return defaultValue;
    }

    if (type === customFieldDataType.MULTI_SELECT_LIST) {
      return defaultValue.join(", ");
    }

    if (type === customFieldDataType.DATE) {
      // Date type custom field don't have default value, showing current date as default
      return formatForDisplayDate(moment());
    }

    // Handling Rich text, returning readonly editor
    if (type === customFieldDataType.RICH_TEXT) {
      return <div dangerouslySetInnerHTML={{ __html: htmlText }} />;
    }

    return defaultValue;
  };

  toggleImagePicker = () => {
    let { openImagePicker } = this.state;
    this.setState({
      openImagePicker: !openImagePicker
    });
  };

  removeDefaultImage = () => {
    this.setState({ defaultValue: "" });
  };

  onImageSelected = (imageUrl: any) => {
    this.setState({ defaultValue: imageUrl, openImagePicker: false });
  };

  onSelectFile = (file: any) => {
    const MEGA_BYTE = 1024 * 1024;
    const FILE_SIZE_LIMIT = 4 * MEGA_BYTE; // 4MB Limit for custom image

    if (!file.type.includes("image")) {
      notify.show("That's not an image", "error");
    }
    if (file.size > FILE_SIZE_LIMIT) {
      notify.show("Upload an image that's smaller than 4 MB", "error");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      let imageUrl = "";
      if (
        reader.result?.startsWith("blob:") ||
        reader.result?.startsWith("data:")
      ) {
        imageUrl = await uploadBlobImage(
          reader.result,

          this.state.publication.domain
        );
      }
      if (!imageUrl) {
        notify.show("We couldn't upload your image, try again", "error");
        return;
      }
      this.setState({ defaultValue: imageUrl, openImagePicker: false });
    });
    reader.readAsDataURL(file);
  };

  getDestinationName = (destinationId) => {
    if (!destinationId) {
      return "";
    }

    let { listOfDestinations } = this.state;

    return (
      listOfDestinations.find((destination) => destination.id === destinationId)
        ?.name || "Unknown"
    );
  };

  render() {
    let {
      classes,
      fetchedCustomFields,
      customFields,
      showDestinationSelection,
      isBlogCustomFields = false,
      showRichTextType,
      customFieldsType,
      publication
    } = this.props;
    let loading = fetchedCustomFields !== apiFetchStatus.SUCCESS;
    let {
      publicationId,
      openAddEditCustomFieldDialog,
      selectedCustomFieldId,
      name,
      type,
      helpText,
      requiredField,
      defaultValue,
      allowedValue,
      nameError,
      typeError,
      selectedDestination,
      listOfDestinations,
      minimumValue,
      maximumValue,
      minimumValueError,
      defaultValueError,
      defaultSyntaxHighlightingLanguage,
      openImagePicker
    } = this.state;

    return (
      <Grid item xs={12} sm={12} md={12}>
        <Grid container direction="column">
          {loading && <Loading />}

          {!loading && customFields?.length > 0 && (
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>

                    <TableCell>Name</TableCell>

                    <TableCell>Type</TableCell>

                    {isBlogCustomFields && <TableCell>Destination</TableCell>}

                    <TableCell>Default Value</TableCell>

                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>

                <DragDropContext
                  onDragEnd={(result: any) =>
                    this.fieldsRearranged(
                      arrayMove(
                        customFields,
                        result.source.index,
                        result.destination.index
                      )
                    )
                  }
                >
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: any) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {customFields.map((value: any, index: any) => {
                          return (
                            <Draggable
                              key={value.name}
                              draggableId={value.name}
                              index={index}
                            >
                              {(draggableProvided: any, snapshot: any) => {
                                return (
                                  <TableRow
                                    width="100%"
                                    key={index}
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                  >
                                    <TableCell style={{ width: "4%" }}>
                                      <div
                                        {...draggableProvided.dragHandleProps}
                                      >
                                        <ReorderIcon
                                          fontSize="small"
                                          style={{
                                            paddingTop: 5,
                                            color: colors.fontSecondary
                                          }}
                                        />
                                      </div>
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        width: "26%"
                                      }}
                                    >
                                      {value.name}
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        width: isBlogCustomFields
                                          ? "20%"
                                          : "30%",
                                        wordBreak: "break-all"
                                      }}
                                    >
                                      {value.type}
                                    </TableCell>

                                    {isBlogCustomFields && (
                                      <TableCell style={{ width: "20%" }}>
                                        {this.getDestinationName(
                                          value.destination
                                        )}
                                      </TableCell>
                                    )}

                                    <TableCell
                                      style={{
                                        width: isBlogCustomFields
                                          ? "20%"
                                          : "30%"
                                      }}
                                    >
                                      {value.type !==
                                      customFieldDataType.IMAGE ? (
                                        this.getFieldValue(value)
                                      ) : value.defaultValue ? (
                                        <img
                                          alt="post pic"
                                          src={value.defaultValue}
                                          className={
                                            classes.defaultImageContainer
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        width: "10%",
                                        display: snapshot.isDragging
                                          ? "none"
                                          : "revert"
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row"
                                        }}
                                      >
                                        <div className={classes.actionButton}>
                                          <Tooltip title="Edit">
                                            <IconButton
                                              onClick={() => {
                                                this.editCustomField(index);
                                              }}
                                              size="large"
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </div>

                                        <div className={classes.actionButton}>
                                          <Tooltip title="Delete">
                                            <IconButton
                                              onClick={() =>
                                                this.deleteCustomField(index)
                                              }
                                              size="large"
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          )}

          <Grid
            item
            container
            direction="row"
            className={clsx(classes.marginTop20, classes.justifyContent)}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <StyledButton
                variant="textprimary"
                className={classes.paddingLeft10}
                onClick={this.addCustomField}
                startIcon={<AddCircleIcon />}
              >
                Add Field
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
        {openAddEditCustomFieldDialog && (
          <StyledDialog
            open={openAddEditCustomFieldDialog}
            title={selectedCustomFieldId ? "Update field" : "Add field"}
            body={
              <div style={{ padding: `0 ${space.LARGE}` }}>
                {showDestinationSelection &&
                  listOfDestinations &&
                  listOfDestinations.length > 0 && (
                    <div style={{ marginBottom: space.MEDIUM }}>
                      <Typography
                        variant="bodym"
                        paragraph
                        style={{ marginBottom: space.SMALL }}
                      >
                        Display this field when publishing to this destination
                      </Typography>

                      <Autocomplete
                        value={selectedDestination || ""}
                        onChange={(event, newValue) =>
                          this.handleDestinationValueChange(newValue)
                        }
                        size="small"
                        options={listOfDestinations}
                        getOptionLabel={(option) => option?.name || ""}
                        renderInput={(params) => (
                          <StyledInput {...params} variant="outlined" />
                        )}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <StyledMenuItem
                              value={`${option.name || ""} (${
                                option.destination
                              })`}
                              key={option.id}
                              {...props}
                            >
                              {`${option.name || ""} (${option.destination})`}
                            </StyledMenuItem>
                          );
                        }}
                      />
                    </div>
                  )}

                <div style={{ marginBottom: space.MEDIUM }}>
                  <Typography
                    variant="bodym"
                    paragraph
                    style={{ marginBottom: space.SMALL }}
                  >
                    Name
                  </Typography>

                  <StyledInput
                    autoFocus
                    size="large"
                    name="name"
                    fullWidth
                    value={name}
                    helperText={nameError}
                    error={!!nameError}
                    onChange={this.changeInputValue}
                  />
                </div>

                <div style={{ marginBottom: space.MEDIUM }}>
                  <Typography
                    variant="bodym"
                    paragraph
                    style={{ marginBottom: space.SMALL }}
                  >
                    Type
                  </Typography>

                  <StyledSelect
                    value={type}
                    name="type"
                    size="large"
                    fullWidth
                    onChange={(event: any) => this.changeFieldType(event)}
                  >
                    {Object.keys(customFieldDataType).map((key, index) => {
                      if (
                        (customFieldDataType[key] ===
                          customFieldDataType.RICH_TEXT &&
                          !showRichTextType) ||
                        (customFieldDataType[key] ===
                          customFieldDataType.IMAGE &&
                          customFieldsType !==
                            customFieldsTypes.BLOG_CUSTOM_FIELDS)
                      ) {
                        return "";
                      }
                      return (
                        <StyledMenuItem
                          value={customFieldDataType[key]}
                          key={index}
                        >
                          {customFieldDataType[key]}
                        </StyledMenuItem>
                      );
                    })}
                  </StyledSelect>

                  <FormHelperText style={{ marginLeft: 14, color: "#d32f2f" }}>
                    {typeError}
                  </FormHelperText>
                </div>

                {isBlogCustomFields && (
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <Typography
                      variant="bodym"
                      paragraph
                      style={{ marginBottom: space.SMALL }}
                    >
                      Helper Text
                    </Typography>

                    <StyledInput
                      autoFocus
                      size="large"
                      name="helpText"
                      fullWidth
                      value={helpText}
                      onChange={this.changeInputValue}
                    />
                  </div>
                )}

                {isBlogCustomFields && (
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <FormControlLabel
                      control={
                        <StyledSwitch
                          name={"assignIdeas"}
                          checked={requiredField}
                          onChange={this.onRequiredFieldChange}
                        />
                      }
                      label="Required"
                      componentsProps={{
                        typography: {
                          variant: "bodym"
                        }
                      }}
                    />
                  </div>
                )}

                {(type === customFieldDataType.MULTI_SELECT_LIST ||
                  type === customFieldDataType.SINGLE_SELECT_LIST) && (
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <Typography
                      variant="bodym"
                      paragraph
                      style={{ marginBottom: space.SMALL }}
                    >
                      Allowed Values
                    </Typography>

                    <TagsInput
                      value={allowedValue}
                      name="allowedValue"
                      size="medium"
                      suggestions={[]}
                      className={classes.width100}
                      addTag={(event: any, newValue: any) =>
                        this.addListAllowedValues(newValue)
                      }
                      handleChange={(event: any, newValues: any) =>
                        this.changeListAllowedValue(newValues)
                      }
                    />
                  </div>
                )}
                {type !== customFieldDataType.DATE && (
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <Typography
                      variant="bodym"
                      paragraph
                      style={{ marginBottom: space.SMALL }}
                    >
                      Default
                    </Typography>

                    {type === customFieldDataType.NUMBER && (
                      <StyledInput
                        name="defaultValue"
                        size="medium"
                        type="number"
                        placeholder="Default value"
                        InputProps={{
                          inputProps: {
                            max: maximumValue ?? null,
                            min: minimumValue ?? null
                          }
                        }}
                        helperText={defaultValueError}
                        error={defaultValueError}
                        value={defaultValue}
                        className={classes.width100}
                        onChange={this.changeInputValue}
                      />
                    )}

                    {(!type || type === customFieldDataType.TEXT) && (
                      <StyledInput
                        name="defaultValue"
                        size="medium"
                        placeholder="Default value"
                        value={defaultValue}
                        minRows={3}
                        multiline
                        className={classes.width100}
                        onChange={this.changeInputValue}
                      />
                    )}
                    {type === customFieldDataType.URL && (
                      <StyledInput
                        name="defaultValue"
                        size="large"
                        placeholder="Default value"
                        value={defaultValue}
                        className={classes.width100}
                        onChange={this.changeInputValue}
                      />
                    )}
                    {type === customFieldDataType.IMAGE &&
                      (defaultValue ? (
                        <Grid item className={classes.imageContainer}>
                          <img
                            alt="post pic"
                            src={defaultValue}
                            className={classes.imageFit}
                          />

                          <IconButton
                            className={classes.closeIcon}
                            onClick={() => this.removeDefaultImage()}
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      ) : (
                        <>
                          <Grid item className={classes.imageIconContainer}>
                            <Tooltip title="Upload image">
                              <IconButton
                                onClick={() => this.toggleImagePicker()}
                                size="large"
                              >
                                <ImageIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Popover
                            id="default_image_picker"
                            open={openImagePicker}
                            anchorEl={<div />}
                            anchorOrigin={{
                              vertical: "center",
                              horizontal: "center"
                            }}
                            transformOrigin={{
                              vertical: "center",
                              horizontal: "center"
                            }}
                            onClose={this.handleImagePickerClose}
                          >
                            <ImagePicker
                              unsplashRef={(ref: any) => {
                                this.imagePickerRef = ref;
                              }}
                              onCloseButtonClick={() =>
                                this.toggleImagePicker()
                              }
                              onImageSelect={this.onImageSelected}
                              onImageUpload={this.onSelectFile}
                              postPictures={[]}
                              showUnsplashPicker={true}
                            />
                          </Popover>
                        </>
                      ))}
                    {type === customFieldDataType.BOOLEAN && (
                      <FormControl
                        variant="outlined"
                        size="small"
                        className={classes.width100}
                      >
                        <StyledSelect
                          name="defaultValue"
                          value={defaultValue}
                          size="large"
                          fullwidth
                          onChange={this.changeInputValue}
                        >
                          <StyledMenuItem aria-label="Select true" value={true}>
                            True
                          </StyledMenuItem>

                          <StyledMenuItem
                            aria-label="Select false"
                            value={false}
                          >
                            False
                          </StyledMenuItem>
                        </StyledSelect>
                      </FormControl>
                    )}
                    {type === customFieldDataType.MULTI_SELECT_LIST && (
                      <TagsInput
                        value={defaultValue || []}
                        name="defaultValue"
                        size="medium"
                        suggestions={allowedValue || []}
                        className={classes.width100}
                        addTag={(event: any, newValue: any) =>
                          this.addListDefaultValues(newValue)
                        }
                        handleChange={(event: any, newValues: any) =>
                          this.changeListDefaultValue(newValues)
                        }
                      />
                    )}
                    {type === customFieldDataType.SINGLE_SELECT_LIST && (
                      <StyledSelect
                        value={defaultValue}
                        name="defaultValue"
                        size="large"
                        fullWidth
                        onChange={(event: any) => {
                          this.setState({
                            defaultValue: event.target.value
                          });
                        }}
                      >
                        {allowedValue.map((item: any) => (
                          <StyledMenuItem value={item}>{item}</StyledMenuItem>
                        ))}
                      </StyledSelect>
                    )}
                    {type === customFieldDataType.RICH_TEXT && (
                      <Suspense fallback={<Loading />}>
                        <div>
                          <Editor
                            value={defaultValue}
                            isFromCustomField={true}
                            handleEditorChange={
                              this.onRichTextDefaultValueChange
                            }
                            isCollaborativePost={false}
                            background={
                              publication.theme?.color || colors.primary
                            }
                            showCustomWidgetOption={true}
                            publicationId={publicationId}
                            fromPost={false}
                            rtl={false}
                            isPaidPublication={
                              publication.type === publicationType.PAID
                            }
                            publicationDomain={publication.domain}
                            id={publication._id}
                            isAnchorSupported={false}
                            defaultSyntaxHighlightingLanguage={
                              defaultSyntaxHighlightingLanguage
                            }
                            setDefaultSyntaxHighlightingLanguage={
                              this.setDefaultSyntaxHighlightingLanguage
                            }
                          />
                        </div>
                      </Suspense>
                    )}
                    {(type === customFieldDataType.NUMBER ||
                      (isBlogCustomFields &&
                        [
                          customFieldDataType.TEXT,
                          customFieldDataType.RICH_TEXT
                        ].includes(type))) && (
                      <>
                        <Typography
                          variant="bodym"
                          paragraph
                          style={{ marginTop: space.SMALL }}
                        >
                          {`Minimum${
                            type === customFieldDataType.NUMBER
                              ? ""
                              : " character count"
                          }`}
                        </Typography>

                        <StyledInput
                          name="minimumValue"
                          size="medium"
                          type="number"
                          placeholder="0"
                          helperText={minimumValueError}
                          error={minimumValueError}
                          value={minimumValue}
                          className={classes.width100}
                          onChange={this.changeInputValue}
                        />

                        <Typography
                          variant="bodym"
                          paragraph
                          style={{ marginTop: space.SMALL }}
                        >
                          {`Maximum${
                            type === customFieldDataType.NUMBER
                              ? ""
                              : " character count"
                          }`}
                        </Typography>

                        <StyledInput
                          name="maximumValue"
                          size="medium"
                          type="number"
                          placeholder="100"
                          value={maximumValue}
                          className={classes.width100}
                          onChange={this.changeInputValue}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            }
            successButtonName={selectedCustomFieldId ? "Update" : "Add"}
            successCallback={this.saveOrUpdate}
            cancelCallback={this.closeAddEditCustomFieldDialog}
          />
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(CustomFields);
