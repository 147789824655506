import React, { useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import StyledButton from "../design/components/StyledButton";
import { notify } from "./CustomNotifications";
import { uploadFile } from "../Utils/Storage";
import StyledDialog from "../design/components/StyledDialog";
import API from "../Services/Api";

type KeywordForPagesProps = {
  publicationId: string;
  domain: string;
};

const KeywordForPages: React.FC<KeywordForPagesProps> = (props) => {
  const api = API.create();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const uploadFileRef = useRef<HTMLInputElement>(null);
  const [showAutoLoadKeywordConfirmation, setShowAutoLoadKeywordConfirmation] =
    useState<boolean>(false);
  useEffect(() => {
    if (selectedFile) {
      validateFile(selectedFile);
    }
  }, [selectedFile]);

  const toggleAutoLoadKeywordConfirmation = () => {
    setShowAutoLoadKeywordConfirmation(!showAutoLoadKeywordConfirmation);
  };
  const validateFile = (file: File) => {
    const fileReader = new FileReader();
    fileReader.onload = async (event: ProgressEvent<FileReader>) => {
      const { result } = event.target as FileReader;
      const lines = result?.toString().split("\n");

      if (lines) {
        const headers = lines[0].split(",");
        const expectedHeaders = ["keyword", "slug"];
        const lowercaseHeaders = headers.map((header) =>
          header.toLowerCase().trim()
        );
        if (
          !expectedHeaders.every((header) => lowercaseHeaders.includes(header))
        ) {
          notify.show(
            "Invalid data! Please ensure the file has 2 columns: keyword and slug.",
            "error"
          );
          // Clear the file input
          if (uploadFileRef.current) {
            uploadFileRef.current.value = "";
          }

          setSelectedFile(null);
          return;
        }
        const tempURL = await uploadFile(selectedFile, props.domain, null);
        let csvURL: string | null =
          typeof tempURL === "string" ? tempURL : null;
        api.assignPostKeywords(
          csvURL,
          props.publicationId,
          true,
          false,
          (res: any) => {
            if (res.status === 200) {
              notify.show("Keywords embedding started.", "success");
              setSelectedFile(null);
              toggleAutoLoadKeywordConfirmation();
            } else {
              notify.show(
                res.status === 400
                  ? res.data
                  : "Error while updating Keyword details.",
                "error"
              );
            }
          }
        );
      }
    };
    fileReader.readAsText(file);
  };

  const initiatePostKeywordAutoGeneration = () => {
    toggleAutoLoadKeywordConfirmation();
    api.assignPostKeywordFromSlug(
      props.publicationId,
      true,
      false,
      (res: any) => {
        if (res.status === 200) {
          notify.show("Keyword generation and embedding started.", "success");
        } else {
          notify.show(
            res.status === 400
              ? res.data
              : "Error while updating Keyword details.",
            "error"
          );
        }
      }
    );
    toggleAutoLoadKeywordConfirmation();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.name.endsWith(".csv")) {
      setSelectedFile(file);
    } else {
      notify.show("Invalid file format! Please select a .csv file.", "error");
      setSelectedFile(null);
    }

    if (uploadFileRef.current) {
      uploadFileRef.current.value = "";
    }
  };

  const handleFileUpload = () => {
    if (uploadFileRef.current) {
      uploadFileRef.current.click();
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h400" paragraph>
        Keywords For Pages
      </Typography>
      <Typography variant="bodym" paragraph>
        Upload a CSV with two columns: keyword, slug. This will help find better
        internal linking opportunities.
      </Typography>
      <input
        id="add-attachment-button"
        style={{ display: "none" }}
        type="file"
        ref={uploadFileRef}
        accept=".csv"
        onChange={handleFileChange}
      />
      <StyledButton style={{ width: "max-content" }} onClick={handleFileUpload}>
        Upload
      </StyledButton>
      <StyledDialog
        open={showAutoLoadKeywordConfirmation}
        title="What about pages without keywords?"
        body="If there are any pages without keywords in this file, we can infer the target keyword from the slug. Would you like us to do that?"
        successButtonName="Yes"
        cancelButtonName="No"
        successCallback={() => initiatePostKeywordAutoGeneration()}
        cancelCallback={() => setShowAutoLoadKeywordConfirmation(false)}
      />
    </Box>
  );
};

export default KeywordForPages;
