// @ts-nocheck
import { grey } from "@mui/material/colors";
import { shadow, space } from "../../Config/theme";
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  section: {
    justifyContent: "space-between"
  },

  paper: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    boxShadow: "none",
    marginTop: space.L
  },

  reducedPaperMargin: {
    boxShadow: "none"
  },

  headerBar: {
    width: "100%",
    marginBottom: space.SMALL,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  header: {
    width: "100%",
    marginBottom: space.SMALL,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  shareToTwitter: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: space.SMALL
    }
  },

  twitterDraft: {
    position: "absolute"
  },

  shareToTwitterButton: {
    position: "absolute",
    right: 5
  },

  getApproveButton: {
    position: "absolute",
    right: 150
  },

  twitterIcon: {
    color: colors.twitter,
    fontSize: 30
  },

  headerText: {
    fontWeight: "bold",
    color: colors.black,
    marginLeft: space.SMALL
  },

  tweetSection: {
    paddingRight: space.L,
    display: "block",
    height: "max-content"
  },

  tweetSectionReviewScreen: {
    paddingRight: 45,
    display: "block",
    height: "max-content"
  },

  previewSection: {
    paddingLeft: 30,
    display: "block"
  },

  threadSection: {
    justifyContent: "center"
  },

  previewWrapper: {
    paddingTop: space.MEDIUM,
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    alignItems: "center",
    border: `1px solid ${colors.inActive}`,
    marginTop: 50,
    overflowY: "auto"
  },

  accountSection: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 50
  },

  cardTitle: {
    fontWeight: "900",
    fontSize: 16,
    fontFamily: "Poppins"
  },

  cardSubtitle: {
    color: colors.fontSecondary,
    marginBottom: space.MEDIUM,
    fontSize: 14,
    fontFamily: "Poppins"
  },

  editableLabel: {
    color: colors.fontSecondary,
    fontSize: 14
  },

  editableLabelDiv: {
    padding: "20px 40px"
  },

  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    marginTop: 0,
    padding: 0,
    width: "100%",
    color: colors.fontPrimary,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontPrimary
    }
  },

  mentionText: {
    color: colors.primary,
    textDecoration: "none"
  },

  tweetSplitter: {
    color: colors.tweetSplitter,
    background: colors.whiteTheme,
    borderRadius: 2
  },

  postUrl: {
    backgroundColor: grey[100],
    color: colors.fontSecondary,
    borderRadius: 5,
    padding: 10,
    paddingRight: 3,
    fontFamily: "Inconsolata",
    width: "100%",
    minHeight: 50,
    fontSize: 18
  },

  anotherAccount: {
    padding: "30px 0px"
  },

  avatar: {
    width: 30,
    height: 30
  },

  userName: {
    marginLeft: 10
  },

  selectedAccount: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    height: 20
  },

  previewAvatar: {
    width: 50,
    height: 50
  },

  threadText: {
    paddingLeft: 20,
    marginBottom: 40
  },

  avatarContainer: {
    alignItems: "center",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column"
  },

  rulerContainer: {
    display: "flex",
    justifyContent: "center",
    height: "100%"
  },

  verticalRuler: {
    width: 3,
    marginTop: 5,
    marginBottom: 5,
    background: colors.horizontalRule
  },

  imageIconContainer: {
    background: "white",
    padding: 0,
    marginTop: 10
  },

  threadImageContainer: {
    display: "inline-flex",
    position: "relative",
    marginTop: 20,
    paddingRight: 20
  },

  threadImage: {
    width: "100%",
    objectFit: "cover"
  },

  closeIcon: {
    position: "absolute",
    top: -15,
    right: 5,
    background: colors.primary,
    color: colors.white,
    padding: 4,
    "&:hover": {
      backgroundColor: colors.primary,
      color: colors.white
    }
  },

  connectedAccountsSection: {
    margin: "20px 0px"
  },

  connectedAccount: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center"
  },

  dialogCloseIcon: {
    top: 8,
    right: 8,
    position: "absolute"
  },

  connectAccountSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px 20px",
    flexDirection: "column"
  },

  connectAccountText: {
    color: colors.fontSecondary
  },

  tweetCloseIcon: {
    right: 20,
    position: "absolute"
  },

  connectAccountImage: {
    width: 250,
    height: 250,
    marginBottom: 50
  },

  errorIcon: {
    height: 18,
    width: 20,
    verticalAlign: "middle"
  },

  charCount: {
    textAlign: "right",
    color: colors.fontSecondary,
    marginTop: 10,
    fontSize: 14
  },

  textAreaOutline: {
    width: "100%",
    resize: "vertical"
  },

  urlContainer: {
    display: "flex",
    marginBottom: space.SMALL,
    marginTop: space.SMALL,
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },

  noMargins: {
    margin: 0
  }
});

export default styles;
