// @ts-nocheck
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import {
  Grid,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { parseDomain } from "parse-domain";
import React, { Component, Fragment } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { withRouter } from "react-router";

import isEmail from "validator/lib/isEmail";

import isURL from "validator/lib/isURL";
import StyledButton from "../design/components/StyledButton";

import StyledInput from "../design/components/StyledInput";
import API from "../Services/Api";
import { CustomDomainStatus } from "../Utils/Types";
import { notify } from "./CustomNotifications";
import styles from "./styles/CustomDomainStyle";

const api = API.create();
const DNS_PROBE_INTERVAL = 5000;
const USERNAME_REGEX = /^([A-Za-z0-9\.]+)$/; // only accepts characters, period and numbers
const DEFAULT_EMAIL_DOMAIN = process.env.REACT_APP_DEFAULT_EMAIL_DOMAIN;

class EmailDomain extends Component {
  checkDNSProbeInterval: any;
  constructor(props: any) {
    super(props);

    let { publication } = props;

    this.state = {
      emailDomain: publication.emailDomain,
      username: publication.emailDomain.username
        ? publication.emailDomain.username
        : "",
      senderDomain: publication.emailDomain.senderDomain
        ? publication.emailDomain.senderDomain
        : "",
      publicationDomain: publication.domain
    };
  }

  componentDidMount() {
    this.setDNSInterval();
  }

  componentWillUnmount() {
    this.clearDNSInterval();
  }

  clearDNSInterval = () => {
    if (this.checkDNSProbeInterval) {
      clearInterval(this.checkDNSProbeInterval);
    }
  };

  setDNSInterval = () => {
    if (this.checkDNSProbeInterval) {
      return;
    }

    this.checkDNSProbeInterval = setInterval(
      this.checkDNSProbe,
      DNS_PROBE_INTERVAL
    );
  };

  checkDNSProbe = () => {
    let { publication } = this.props;
    api.getEmailDomainStatus(publication._id, (res: any) => {
      if (res.status === 200) {
        if (
          res.data.emailDomain.status === CustomDomainStatus.SUCCESS ||
          res.data.emailDomain.status === CustomDomainStatus.FAILED ||
          res.data.emailDomain.status === CustomDomainStatus.NOT_CONNECTED
        ) {
          clearInterval(this.checkDNSProbeInterval);
          delete this.checkDNSProbeInterval;
        }
        this.setState({
          emailDomain: res.data.emailDomain
        });
      }
    });
  };

  handleChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  validateDomain() {
    let domain = this.state.senderDomain.trim();

    if (!domain.trim()) {
      notify.show(
        "Enter your domain from which you want to send email",
        "error"
      );
      return;
    }

    if (
      domain.startsWith("http://") ||
      domain.startsWith("https://") ||
      domain.startsWith("www.")
    ) {
      notify.show(
        "Just enter your mail domain. For example, stratechery.com",
        "error"
      );
      return false;
    }

    if (!isURL(domain, { require_protocol: false, require_host: true })) {
      notify.show("You entered an invalid URL", "error");
      return false;
    }

    return true;
  }

  validateUserName = () => {
    let { username, senderDomain } = this.state;
    username = username.trim();
    senderDomain = senderDomain.trim();

    if (!username) {
      notify.show("Username is required to send email", "error");
      return false;
    }

    if (!USERNAME_REGEX.test(username)) {
      notify.show(
        "Just enter your username. For example, ben as in ben@stratechery.com",
        "error"
      );
      return false;
    }

    if (!isEmail(username + "@" + senderDomain)) {
      notify.show(
        username + "@" + senderDomain + " is not a valid email address",
        "error"
      );
      return false;
    }

    return true;
  };

  saveEmailDomain = (event: any) => {
    let { username, senderDomain } = this.state;

    let { publication } = this.props;

    username = username.trim();
    senderDomain = senderDomain.trim();

    // if validation fails don't proceed
    if (!this.validateDomain()) {
      return;
    }

    if (!this.validateUserName()) {
      return;
    }

    api.saveEmailDomain(publication._id, username, senderDomain, (res: any) => {
      if (res.status === 200) {
        notify.show(
          "Saved! It might take up to 48 hours for your records to propagate",
          "success"
        );

        let { emailDomain } = res.data;
        let { username, senderDomain } = emailDomain;
        this.setState(
          {
            emailDomain,
            username,
            senderDomain
          },
          () => {
            this.setDNSInterval();
          }
        );
      } else {
        notify.show(res.data, "error");
      }
    });
  };

  resetEmailDomain = () => {
    let { publication } = this.props;
    api.saveEmailDomain(publication._id, "", "", (res: any) => {
      if (res.status === 200) {
        this.setState({
          emailDomain: res.data.emailDomain,
          username: "",
          senderDomain: ""
        });
      } else {
        notify.show(res.data, "error");
      }
    });
  };

  onCopy = () => {
    notify.show("Copied to clipboard", "success");
  };

  normalizeHost = (host: any, domainFromDNS: any) => {
    const { subDomains, domain, topLevelDomains } = parseDomain(domainFromDNS);
    if (!host) {
      return host;
    }

    return host.replace("." + domain + "." + topLevelDomains, "");
  };

  render() {
    let { classes } = this.props;

    let { emailDomain, username, senderDomain, publicationDomain } = this.state;
    let dnsRecords = [
      {
        type: "TXT",
        host: this.normalizeHost(
          emailDomain.postmark.host,
          emailDomain.senderDomain
        ),
        value: emailDomain.postmark.value,
        verified: emailDomain.postmark.status
      },
      {
        type: "CNAME",
        host: this.normalizeHost(
          emailDomain.postmark.returnPathHost,
          emailDomain.senderDomain
        ),
        value: emailDomain.postmark.returnPathValue,
        verified: emailDomain.postmark.returnPathStatus
      }
    ];

    let dedicatedIP_CNAMERecords = [
      {
        type: "CNAME",
        host: this.normalizeHost(
          emailDomain.sendGrid.cname1.host,
          emailDomain.senderDomain
        ),
        value: emailDomain.sendGrid.cname1.value,
        verified: emailDomain.sendGrid.cname1.status
      },
      {
        type: "CNAME",
        host: this.normalizeHost(
          emailDomain.sendGrid.cname2.host,
          emailDomain.senderDomain
        ),
        value: emailDomain.sendGrid.cname2.value,
        verified: emailDomain.sendGrid.cname2.status
      },
      {
        type: "CNAME",
        host: this.normalizeHost(
          emailDomain.sendGrid.cname3.host,
          emailDomain.senderDomain
        ),
        value: emailDomain.sendGrid.cname3.value,
        verified: emailDomain.sendGrid.cname3.status
      }
    ];

    return (
      <Container className={classes.container}>
        <Grid container direction="column">
          <Grid item container direction="row" justifyContent="space-between">
            <Typography variant="h400" paragraph>
              Send email from your domain
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" className={classes.marginTop12}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="bodyl" paragraph className={classes.subtitle}>
              EMAIL
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={3} className={classes.marginTop12}>
            <Typography variant="bodym" paragraph>
              Sender Domain
            </Typography>
          </Grid>

          <Grid item xs={11} sm={11} md={7}>
            <StyledInput
              className={classes.input}
              type="text"
              placeholder="newsletter.acme.com"
              name="senderDomain"
              value={senderDomain}
              size="medium"
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" className={classes.marginTop12}>
          <Grid item xs={12} sm={12} md={3} className={classes.marginTop12}>
            <Typography variant="bodym" paragraph>
              Sender Username
            </Typography>
          </Grid>

          <Grid item xs={11} sm={11} md={7}>
            <StyledInput
              className={classes.input}
              type="text"
              placeholder="ben"
              name="username"
              value={username}
              size="medium"
              onChange={this.handleChange}
            />

            <Typography variant="subtitle2" className={classes.subtext}>
              Your email will be sent from {}
              {username &&
                senderDomain &&
                `${username}@${senderDomain}, once verified.`}
              {(!username || !senderDomain) &&
                `${publicationDomain}@${DEFAULT_EMAIL_DOMAIN}`}
            </Typography>
          </Grid>
        </Grid>
        {emailDomain.status !== CustomDomainStatus.NOT_CONNECTED && (
          <Fragment>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              className={classes.marginTop12}
            >
              <Grid item container xs={12} className={classes.marginTop20}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="bodyl"
                    paragraph
                    className={classes.marginTop12}
                  >
                    DNS
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.subtext}
                  >
                    Add these CNAME records to your DNS hosting provider
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="center">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TableContainer className={classes.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          TYPE
                        </TableCell>

                        <TableCell className={classes.tableHeader}>
                          HOST
                        </TableCell>

                        <TableCell className={classes.tableHeader}>
                          VALUE
                        </TableCell>

                        <TableCell className={classes.tableHeader}>
                          VERIFIED
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {dnsRecords.map((dnsRecord, index) => {
                        if (dnsRecord.host) {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                className={classes.subscriberTableCell}
                                width="10%"
                              >
                                {dnsRecord.type}
                              </TableCell>

                              <TableCell
                                className={classes.subscriberTableCell}
                                size="small"
                                width="30%"
                              >
                                <CopyToClipboard
                                  onCopy={this.onCopy}
                                  text={dnsRecord.host}
                                >
                                  <Tooltip title="Copy" placement="right">
                                    <div className={classes.overflowHidden}>
                                      {dnsRecord.host}
                                    </div>
                                  </Tooltip>
                                </CopyToClipboard>
                              </TableCell>

                              <TableCell
                                className={classes.subscriberTableCell}
                                width="50%"
                              >
                                <CopyToClipboard
                                  onCopy={this.onCopy}
                                  text={dnsRecord.value}
                                >
                                  <Tooltip title="Copy" placement="right">
                                    <div className={classes.overflowHidden}>
                                      {dnsRecord.value}
                                    </div>
                                  </Tooltip>
                                </CopyToClipboard>
                              </TableCell>

                              <TableCell
                                width="10%"
                                className={classes.subscriberTableCell}
                              >
                                {dnsRecord.verified && (
                                  <CheckIcon className={classes.checkIcon} />
                                )}
                                {!dnsRecord.verified &&
                                  emailDomain.status ===
                                    CustomDomainStatus.IN_PROGRESS && (
                                    <HourglassEmptyRoundedIcon
                                      className={classes.inProgressIcon}
                                    />
                                  )}
                                {!dnsRecord.verified &&
                                  emailDomain.status ===
                                    CustomDomainStatus.FAILED && (
                                    <BlockIcon className={classes.failedIcon} />
                                  )}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                      {dedicatedIP_CNAMERecords.map((cnameRecord, index) => {
                        if (cnameRecord.host) {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                className={classes.subscriberTableCell}
                              >
                                {cnameRecord.type}
                              </TableCell>

                              <TableCell
                                className={classes.subscriberTableCell}
                                size="small"
                              >
                                <CopyToClipboard
                                  onCopy={this.onCopy}
                                  text={cnameRecord.host}
                                >
                                  <Tooltip title="Copy" placement="right">
                                    <div className={classes.overflowHidden}>
                                      {cnameRecord.host}
                                    </div>
                                  </Tooltip>
                                </CopyToClipboard>
                              </TableCell>

                              <TableCell
                                className={classes.subscriberTableCell}
                              >
                                <CopyToClipboard
                                  onCopy={this.onCopy}
                                  text={cnameRecord.value}
                                >
                                  <Tooltip title="Copy" placement="right">
                                    <div className={classes.overflowHidden}>
                                      {cnameRecord.value}
                                    </div>
                                  </Tooltip>
                                </CopyToClipboard>
                              </TableCell>

                              <TableCell
                                className={classes.subscriberTableCell}
                              >
                                {cnameRecord.verified && (
                                  <CheckIcon className={classes.checkIcon} />
                                )}
                                {!cnameRecord.verified &&
                                  emailDomain.status ===
                                    CustomDomainStatus.IN_PROGRESS && (
                                    <HourglassEmptyRoundedIcon
                                      className={classes.inProgressIcon}
                                    />
                                  )}
                                {!cnameRecord.verified &&
                                  emailDomain.status ===
                                    CustomDomainStatus.FAILED && (
                                    <BlockIcon className={classes.failedIcon} />
                                  )}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Fragment>
        )}

        <Grid container direction="row" style={{ marginTop: 20 }}>
          <Typography variant="bodym">
            <a
              className={classes.helpLink}
              href="mailto:support@letterdrop.com"
            >
              Confused about set up? Let us help
            </a>
          </Typography>
        </Grid>

        <Grid container direction="row" justifyContent="space-around">
          <Grid item container xs={12} spacing={1} style={{ marginTop: 10 }}>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item>
                <StyledButton
                  variant="textsecondary"
                  style={{ marginRight: 10 }}
                  onClick={this.resetEmailDomain}
                >
                  Reset
                </StyledButton>

                <StyledButton
                  className={classes.adjacentButton}
                  onClick={this.saveEmailDomain}
                >
                  Save
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(EmailDomain));
