// @ts-nocheck
import { space } from "../../Config/theme";
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  container: {
    padding: 0
  },

  socialAccount: {
    margin: "auto 20px"
  },

  accountsContainer: {
    padding: "20px 0px"
  },

  fontSecondary: {
    color: colors.fontSecondary
  },

  section: {
    marginBottom: 10,
    alignItems: "center",
    display: "flex"
  },

  socialIcon: {
    marginRight: 10,
    width: "28px !important",
    height: "28px !important"
  },

  accountUserName: {
    margin: "auto 10px"
  },

  twitter: {
    color: colors.twitter
  },

  linkedin: {
    color: colors.linkedin
  },

  facebook: {
    color: colors.facebook
  },

  reddit: {
    color: colors.reddit
  },

  youtube: {
    width: "initial !important",
    height: "20px !important"
  },

  twitterLabel: {
    display: "flex",
    alignItems: "center"
  },

  linkedInContainer: {
    margin: "auto 0",
    display: "inline-flex"
  },

  linkedInName: {
    margin: "auto 10px"
  },

  accountContainer: {
    margin: "auto 0"
  },

  cardSubtitle: {
    color: colors.fontSecondary
  },

  connectButtonContainer: {
    margin: "auto 0",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },

  autoPostToggle: {
    display: "flex",
    alignItems: "center",
    marginTop: 30
  },

  connectedAccount: {
    color: colors.fontSecondary,
    margin: "auto 0px"
  },

  tokenExpired: {
    color: colors.darkRed
  },

  hubspot: {
    color: colors.hubspot
  },

  salesforce: {
    color: colors.salesforce
  },

  subTitle: {
    marginTop: 10,
    color: colors.fontSecondary
  },

  twitterAvatar: {
    width: 25,
    height: 25
  },

  addTwitterButton: {
    marginLeft: -5,
    marginTop: 20
  },

  textButton: {
    margin: space.XS
  },

  closeIcon: {
    color: colors.fontSecondary,
    cursor: "pointer"
  },

  errorIcon: {
    color: colors.darkRed,
    margin: "auto 10px",
    fontSize: 16
  },

  connectedAccountsContainer: {
    marginTop: 20,
    marginBottom: 10
  },

  linkedinOrgContainer: {
    marginTop: space.MEDIUM,
    marginBottom: space.MEDIUM,
    justifyContent: "flex-end"
  },

  accountNameContainer: {
    display: "flex",
    margin: "0 auto",
    alignItems: "center"
  },

  marginTop20: {
    marginTop: 20
  },

  fontAwesomeIcon: {
    fontSize: 14,
    marginRight: space.MEDIUM
  },

  orgIcon: {
    color: colors.iconGrey,
    marginBottom: space.XXS,
    marginRight: space.XS
  }
});
export default styles;
