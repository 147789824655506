import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import firebase from "firebase";
import qs from "query-string";
import React, { Component, Suspense } from "react";
import { RouteComponentProps } from "react-router";
import Loading from "../Components/Loading";
import { space } from "../Config/theme";
import { ReactComponent as Logo } from "../Images/letterdrop_logo_with_text.svg";
import API from "../Services/Api";
import PageAccessContext from "../Utils/PageAccessContext";
import { setItemInLocalStorage } from "../Utils/StorageHelper";
import { PROFILE_PIC, teamRoles } from "../Utils/Types";
import StyledButton from "../design/components/StyledButton";

const api = API.create();

const Page404 = React.lazy(() => import("../Containers/Page404"));

type ClassProps = {} & RouteComponentProps<{ id: string }>;

type ClassState = {
  token: string;
  email: string;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  teamInviteExisits: boolean;
  publicationName: string;
};

class AcceptTeamInvite extends Component<ClassProps, ClassState> {
  static contextType = PageAccessContext;
  loginRedirect: any;
  constructor(props: ClassProps) {
    super(props);

    const qsParse = qs.parse(this.props.location.search);
    this.state = {
      token: props.match.params.id ? props.match.params.id : "",
      email: String(qsParse.email || "").toLowerCase(),
      loading: true,
      error: false,
      errorMessage: "",
      teamInviteExisits: true,
      publicationName: ""
    };
  }

  componentDidMount() {
    this.checkTeamInviteExists();
  }

  async checkTeamInviteExists() {
    const { token, email } = this.state;

    this.setState({ loading: true });
    api.teamInviteExists(token, (res) => {
      if (res.status === 200) {
        const inviteExists = res.data?.inviteExists || false;
        this.setState({
          loading: false,
          error: false,
          errorMessage: "",
          teamInviteExisits: inviteExists,
          publicationName: res.data?.publicationName || ""
        });
      } else {
        this.setState({
          loading: false,
          error: true,
          errorMessage: res.data?.message || ""
        });
        this.loginRedirect = setTimeout(() => {
          this.props.history.push("/login?email=" + email);
        }, 3000);
      }
    });
  }

  acceptTeamInvite = async () => {
    let { token, email } = this.state;

    const currentUser = firebase.auth().currentUser;
    const idToken = currentUser ? await currentUser.getIdToken() : null;

    this.setState({ loading: true });
    api.acceptTeamInvite(token, email, idToken, (res) => {
      let { response, pageAccess } = res.data || {};

      if (res.status === 200) {
        if (response.customToken) {
          firebase
            .auth()
            .signInWithCustomToken(response.customToken)
            .then((user) => {
              setItemInLocalStorage(PROFILE_PIC, res.data.profilePic);
              this.redirectUser(response, pageAccess);
            })
            .catch((err) => {
              this.props.history.push(
                "/login?email=" + email + "&next_url=" + window.location.href
              );
            });
          return;
        }
        this.redirectUser(response, pageAccess);
      } else {
        if (response?.requiredLogin) {
          this.props.history.push(
            "/login?email=" + email + "&next_url=" + window.location.href
          );
          return;
        }
        if (
          response &&
          response.message &&
          response.message === "Invite doesn't exist"
        ) {
          //Freelancer flow
          this.setState({
            loading: false,
            error: false,
            errorMessage: "",
            teamInviteExisits: false
          });
        } else {
          this.setState({
            loading: false,
            error: true,
            errorMessage: response?.message || ""
          });
          this.loginRedirect = setTimeout(() => {
            this.props.history.push("/login?email=" + email);
          }, 3000);
        }
      }
      return;
    });
  };

  redirectUser = (response: any, pageAccess: any) => {
    this.context.setContextData(pageAccess, () => {});

    this.props.history.push(
      response.role === teamRoles.FREELANCER
        ? "/agency/writer-projects"
        : "/workspaces"
    );
  };

  componentWillUnmount = () => {
    clearTimeout(this.loginRedirect);
  };

  render() {
    const { loading, error, errorMessage, teamInviteExisits, publicationName } =
      this.state;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage={errorMessage} />
        </Suspense>
      );
    }

    if (!teamInviteExisits) {
      return (
        <Container
          maxWidth="lg"
          style={{ marginTop: "30vh", textAlign: "center" }}
        >
          <Typography variant="h5">
            Looks like your invite was revoked. Reach out to the person who
            originally invited you.
          </Typography>
        </Container>
      );
    }

    return (
      <Container
        maxWidth="lg"
        style={{ marginTop: "10vh", textAlign: "center" }}
      >
        <Box style={{ marginTop: space.LARGE }}>
          <Logo
            style={{ height: 40, width: 177, cursor: "pointer" }}
            onClick={() => {
              this.props.history.push("/");
            }}
          />
        </Box>
        <Typography my={"64px"}>
          Hello there, you've been invited to join{" "}
          {publicationName ? publicationName : "workspace"}
          {"."}
          <br />
          Click accept to join their team.
        </Typography>
        <StyledButton
          variant="primary"
          size="large"
          onClick={this.acceptTeamInvite}
        >
          Accept
        </StyledButton>
      </Container>
    );
  }
}

export default AcceptTeamInvite;
