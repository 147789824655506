// @ts-nocheck
import LockIcon from "@mui/icons-material/Lock";
import { Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import styles from "./styles/StripeWatermarkStyle";
import images from "../Themes/Images";

function StripeWatermark({ classes }: any) {
  function openLink(link: any) {
    window.open(link, "_blank");
  }

  return (
    <div className={classes.root}>
      <div className={classes.cardFormContainer}>
        <div className={classes.innerContainer}>
          <span className={classes.text}>
            <LockIcon className={classes.lockIcon} />

            <Typography className={classes.text}>
              &nbsp; Secure payments powered by{" "}
            </Typography>
          </span>

          <img
            src={images.stripe}
            className={classes.image}
            alt="stripe logo"
          />

          <Typography className={classes.divider}>&nbsp;&nbsp;|</Typography>

          <div className={classes.text}>
            <Typography
              className={classes.link}
              onClick={() => openLink("https://stripe.com/checkout/terms")}
            >
              &nbsp;&nbsp;Terms
            </Typography>

            <Typography
              className={classes.link}
              onClick={() => openLink("https://stripe.com/privacy")}
            >
              &nbsp;&nbsp;Privacy
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(StripeWatermark);
