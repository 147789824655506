import axios from "axios";
import React, { useEffect, useState } from "react";
import { getShortVersionOfText } from "../Utils/UserUtils";

const UrlPreview: React.FC<{
  url: string;
}> = ({ url }) => {
  const [title, setTitle] = useState("");

  const getTitle = async () => {
    try {
      if (!url) return;

      const { data } = await axios.get(url);

      const parser = new DOMParser();
      const doc = parser.parseFromString(data, "text/html");
      const title = doc.querySelector("title")?.textContent || "";
      if (title) setTitle(title);
    } catch (e) {}
  };

  useEffect(() => {
    getTitle();
  }, [url]);

  return (
    <a href={url} rel={"noreferrer"} target="_blank">
      {getShortVersionOfText(title || url || "", 55)}
    </a>
  );
};

export default UrlPreview;
