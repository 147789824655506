// @ts-nocheck
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Grid,
  Container,
  Typography,
  FormHelperText,
  Divider
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import _ from "lodash";
import React, { Component } from "react";
import { withRouter } from "react-router";
import isURL from "validator/lib/isURL";
import StyledButton from "../design/components/StyledButton";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import Api from "../Services/Api";
import { modulesAccess } from "../Utils/Types";
import { addHTTPSToURL, trimURL } from "../Utils/UserUtils";
import { notify } from "./CustomNotifications";

import ExternalSitesList from "./ExternalSitesList";
import styles from "./styles/ExternalSitesStyle";
import { space } from "../Config/theme";
import { designColors } from "../Themes/Colors";
import { isModuleDisabled } from "../Utils/AccessUtils";
import PageAccessContext from "../Utils/PageAccessContext";

const api = Api.create();

class ExternalSites extends Component {
  static contextType = PageAccessContext;
  constructor(props: any) {
    super(props);
    let letterdropTracking =
      "<script id='letterdrop_tracking' publicationId='" +
      props.publication._id +
      "' src='https://letterdrop.com/sdk/letterdrop-tracking.js' async></script>";

    let { blogs = [], sitemap = "" } = props.publication.externalSite;
    this.state = {
      blogs, //Configured external sites
      sitemap,
      prevSitemap: sitemap,
      publicationId: props.publication._id,
      letterdropTracking,
      openAddEditBlogDialog: false,
      id: "",
      name: "",
      destination: "",
      destinationLabel: "",
      blogHomePath: "",
      blogPostBasePath: "",
      selectedBlogId: null,
      destinationError: "",
      blogHomePathError: "",
      blogPostBasePathError: "",
      connectedBlogs: [], //Connected blog accounts
      url: ""
    };
  }

  componentDidMount() {
    let { publicationId } = this.state;
    api.getConnectedBlogs(publicationId, (res: any) => {
      if (res.status === 200) {
        this.setState({
          connectedBlogs: res.data
        });
      }
    });
  }

  handleChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  saveExternalSitemap = () => {
    let { publicationId, sitemap } = this.state;

    sitemap = sitemap.trim();

    if (sitemap && !isURL(sitemap)) {
      notify.show("That doesn't look like a valid URL", "error");
      return;
    }

    api.saveExternalSitemap(publicationId, sitemap, (res: any) => {
      if (res.status === 200) {
        this.setState({ prevSitemap: sitemap, sitemap });
        return;
      }

      notify.show(
        res.status === 400 ? res.data : "Oops, something went wrong.",
        "error"
      );
      return;
    });
  };

  revertSitemapChange = () => {
    this.setState((prevState, props) => ({
      sitemap: prevState.prevSitemap
    }));
  };

  generateSaveButton = () => {
    let { classes } = this.props;
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        container
        justifyContent="flex-end"
        spacing={1}
        direction="row"
        className={classes.saveGrid}
      >
        <Grid item>
          <StyledButton onClick={() => this.saveExternalSitemap()}>
            Save
          </StyledButton>
        </Grid>

        <Grid item>
          <StyledButton
            variant="tertiary"
            onClick={() => this.revertSitemapChange()}
          >
            Cancel
          </StyledButton>
        </Grid>
      </Grid>
    );
  };

  handleAddBlogClicked = () => {
    this.setState({
      openAddEditBlogDialog: true,
      selectedBlogId: null,
      id: "",
      name: "",
      destination: "",
      destinationLabel: "",
      blogHomePath: "",
      blogPostBasePath: "",
      destinationError: "",
      blogHomePathError: "",
      blogPostBasePathError: ""
    });
  };

  handleEditBlogClicked = (selectedBlogId: any) => {
    let { blogs } = this.state;
    let selectedBlogIndex = blogs.findIndex(
      (blog: any) => blog.id === selectedBlogId
    );
    let {
      destination = "",
      destinationLabel = "",
      blogHomePath = "",
      blogPostBasePath = "",
      id = "",
      name = ""
    } = blogs[selectedBlogIndex] || {};

    this.setState({
      openAddEditBlogDialog: true,
      selectedBlogId,
      id,
      name,
      destination,
      destinationLabel,
      blogHomePath,
      blogPostBasePath,
      destinationError: "",
      blogHomePathError: "",
      blogPostBasePathError: ""
    });
  };

  handleDestinationChange = (event: any) => {
    let { connectedBlogs, id, name, destination, destinationLabel } =
      this.state;
    let index = connectedBlogs.findIndex(
      (account: any) => account.id === event.target.value
    );

    if (index >= 0) {
      id = connectedBlogs[index].id;
      name = connectedBlogs[index].name;
      destination = connectedBlogs[index].destination;
      destinationLabel = connectedBlogs[index]?.destinationLabel || "";
    }

    this.setState({ id, name, destination, destinationLabel });
  };

  handleSaveOrUpdate = () => {
    let {
      publicationId,

      blogs,

      destination,

      destinationLabel,

      blogHomePath,

      blogPostBasePath,

      selectedBlogId,

      id,

      name
    } = this.state;
    let destinationError = "";
    let blogHomePathError = "";
    let blogPostBasePathError = "";
    blogPostBasePath = blogPostBasePath.trim();

    //validation
    if (!destination) {
      destinationError = "Select your blog destination";
    }

    if (!blogHomePath) {
      blogHomePathError = "Enter the blog home page URL";
    }

    if (!blogPostBasePath) {
      blogPostBasePathError = "Enter the blog post base path URL";
    }

    if (blogPostBasePath && !isURL(blogPostBasePath)) {
      blogPostBasePathError = "That doesn't look like a valid URL";
    }

    if (blogHomePath && !isURL(blogHomePath)) {
      blogHomePathError = "That doesn't look like a valid URL";
    }

    if (
      blogs.some((blog: any) => blog.id !== selectedBlogId && blog.id === id)
    ) {
      destinationError = "You've already added this blog.";
    }

    if (
      blogPostBasePath &&
      !blogPostBasePathError &&
      (!blogHomePath.trim() || !isURL(blogHomePath.trim()))
    ) {
      blogPostBasePathError = "Set the blog home page URL first";
    }

    this.setState({
      destinationError,
      blogHomePathError,
      blogPostBasePathError
    });
    if (destinationError || blogHomePathError || blogPostBasePathError) return;

    if (blogHomePath) {
      blogHomePath = addHTTPSToURL(blogHomePath);
    }

    if (blogPostBasePath) {
      blogPostBasePath = addHTTPSToURL(blogPostBasePath);
    }
    blogHomePath = trimURL(blogHomePath);
    blogPostBasePath = trimURL(blogPostBasePath);

    let selectedBlogIndex = blogs.findIndex(
      (blog: any) => blog.id === selectedBlogId
    );

    //If we make changes to blogs and they are reflected in the UI without using setState
    let newBlogs = _.cloneDeep(blogs);
    if (selectedBlogIndex < 0) {
      newBlogs.push({
        destination,
        destinationLabel,
        blogHomePath,
        blogPostBasePath,
        id,
        name
      });
    } else {
      newBlogs[selectedBlogIndex] = {
        destination,
        destinationLabel,
        blogHomePath,
        blogPostBasePath,
        id,
        name
      };
    }

    api.saveExternalSite(publicationId, newBlogs, (res: any) => {
      if (res.status === 200) {
        this.setState(
          {
            blogs: res.data.externalSite.blogs,
            openAddEditBlogDialog: false,
            destination: "",
            destinationLabel: "",
            blogHomePath: "",
            blogPostBasePath: ""
          },
          () => {
            this.props.updateDetails(res.data);
          }
        );
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Oops, we couldn't save your new blog destination. Contact support@letterdrop.com.",
          "error"
        );
      }
    });
  };

  deleteBlogAddress = (blogAddressId: any) => {
    let { publicationId } = this.state;
    api.deleteExternalSite(publicationId, blogAddressId, (res: any) => {
      if (res.status === 200) {
        this.setState({ blogs: res.data });
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Oops, we couldn't remove your blog destination. Contact support@letterdrop.com.",
          "error"
        );
      }
    });
  };

  importPostBySitemap = () => {
    let { publication, next } = this.props;

    let { url, sitemap } = this.state;
    sitemap = sitemap?.toLowerCase().trim();
    url = url?.toLowerCase().trim();
    if (!isURL(url)) {
      notify.show("That doesn't seem like a valid URL", "error");
      return;
    }
    const URL_REGEX = /^https?:\/\//;
    if (!URL_REGEX.test(url)) {
      url = "https://" + url;
    }
    if (url.endsWith("/")) {
      url = url.slice(0, -1);
    }

    api.importFromSitemap(sitemap, url, publication._id, (res: any) => {
      if (res.status === 200) {
        this.setState({ url: "" });
        notify.show(res.data, "success");
        if (next) {
          next();
        }
      } else if (res.status === 400) {
        notify.show(res.data, "error");
      }
    });
  };

  closeAddEditBlogDialog = () => {
    this.setState({
      selectedBlogId: null,
      openAddEditBlogDialog: false,
      destination: "",
      destinationLabel: "",
      blogHomePath: "",
      blogPostBasePath: ""
    });
  };

  render() {
    let { classes } = this.props;
    let {
      sitemap,

      openAddEditBlogDialog,

      blogs,

      id,

      blogHomePath,

      blogPostBasePath,

      selectedBlogId,

      connectedBlogs,

      destinationError,

      blogHomePathError,

      blogPostBasePathError,

      prevSitemap,

      url,
      publicationId
    } = this.state;

    return (
      <Container className={classes.container}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.inputContainer}
        >
          <Grid item xs={12}>
            <Typography variant="h400" paragraph>
              Sitemap
            </Typography>

            <Typography
              variant="bodym"
              paragraph
              className={classes.cardSubtitle}
            >
              We can submit your sitemap to Google on a monthly basis.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{ marginBottom: space.MEDIUM }}
          >
            <StyledInput
              size="medium"
              name="sitemap"
              className={classes.input}
              value={sitemap}
              placeholder="https://acme.com/sitemap.xml"
              onChange={this.handleChange}
            />
          </Grid>
          {prevSitemap !== sitemap && this.generateSaveButton()}
        </Grid>

        {!isModuleDisabled(this.context, publicationId, modulesAccess.SEO) && (
          <>
            <div>
              <Divider className={classes.divider} />

              <Typography variant="h400" paragraph>
                Import and Destinations
              </Typography>
            </div>

            <Grid container direction="row" alignItems="center">
              <Grid
                item
                container
                direction="column"
                justifyContent="space-between"
                style={{ marginBottom: 10 }}
              >
                <Typography
                  variant="bodyl"
                  paragraph
                  className={classes.subTitle}
                >
                  IMPORT EXISTING BLOG
                </Typography>

                <Typography
                  variant="bodym"
                  paragraph
                  className={classes.cardSubtitle}
                >
                  We can attempt an automatic import of your posts from your
                  sitemap. After entering your sitemap above, enter the base
                  path to your content below.
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <StyledInput
                  size="medium"
                  name="url"
                  value={url}
                  fullWidth
                  placeholder="https://acme.com/resources"
                  onChange={this.handleChange}
                  disabled={!sitemap}
                />
              </Grid>

              <Grid
                item
                xs={4}
                sm={12}
                md={3}
                lg={3}
                container
                justifyContent="flex-end"
                spacing={1}
                direction="row"
                className={classes.saveGrid}
              >
                <StyledButton onClick={this.importPostBySitemap}>
                  Import
                </StyledButton>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="bodyl"
                paragraph
                className={classes.subTitle}
                style={{ marginTop: space.LARGE }}
              >
                DESTINATIONS
              </Typography>
            </Grid>
            {blogs.length > 0 && (
              <ExternalSitesList
                blogs={blogs}
                onEdit={this.handleEditBlogClicked}
                onDelete={this.deleteBlogAddress}
              />
            )}

            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.inputContainer}
            >
              <StyledButton
                variant="textprimary"
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  this.handleAddBlogClicked();
                }}
                disabled={!connectedBlogs.length}
                style={{ marginTop: 16 }}
              >
                Add destination
              </StyledButton>
            </Grid>
            {openAddEditBlogDialog && (
              <StyledDialog
                open={openAddEditBlogDialog}
                title={
                  selectedBlogId ? "Update destination" : "Add destination"
                }
                body={
                  <div>
                    <div style={{ marginBottom: 20 }}>
                      <Typography
                        variant="bodym"
                        paragraph
                        style={{ marginBottom: 10 }}
                      >
                        Destination
                      </Typography>

                      <StyledSelect
                        value={id}
                        fullWidth
                        size="xxl"
                        onChange={this.handleDestinationChange}
                        name="selectedFrequency"
                        error={!!destinationError}
                      >
                        {connectedBlogs.map((account: any, index: any) => {
                          return (
                            <StyledMenuItem
                              value={account.id}
                              key={account.id}
                              style={{ display: "block" }}
                            >
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: 500
                                }}
                              >
                                {account.name || ""}
                              </div>

                              <div style={{ textTransform: "capitalize" }}>
                                <Typography
                                  variant="bodys"
                                  style={{ color: designColors.grayScale[60] }}
                                >
                                  {account?.destinationLabel ||
                                    account?.destination}
                                </Typography>
                              </div>
                            </StyledMenuItem>
                          );
                        })}
                      </StyledSelect>

                      <FormHelperText
                        style={{ marginLeft: 14, color: "#d32f2f" }}
                      >
                        {destinationError}
                      </FormHelperText>
                    </div>

                    <div style={{ marginBottom: 20 }}>
                      <Typography
                        variant="bodym"
                        paragraph
                        style={{ marginBottom: 10 }}
                      >
                        Path to your blog's home page
                      </Typography>

                      <StyledInput
                        size="medium"
                        name="blogHomePath"
                        className={classes.input}
                        value={blogHomePath}
                        placeholder="https://acme.com/blog"
                        helperText={blogHomePathError}
                        error={!!blogHomePathError}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div style={{ marginBottom: 20 }}>
                      <Typography
                        variant="bodym"
                        paragraph
                        style={{ marginBottom: 10 }}
                      >
                        Base path to a blog post
                      </Typography>

                      <StyledInput
                        size="medium"
                        name="blogPostBasePath"
                        className={classes.input}
                        value={blogPostBasePath}
                        placeholder="https://acme.com/blog"
                        helperText={blogPostBasePathError}
                        error={!!blogPostBasePathError}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                }
                successButtonName={selectedBlogId ? "Update" : "Add"}
                successCallback={this.handleSaveOrUpdate}
                cancelCallback={this.closeAddEditBlogDialog}
              />
            )}
          </>
        )}
      </Container>
    );
  }
}
export default withRouter(withStyles(styles)(ExternalSites));
