// @ts-nocheck
import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import style from "../Containers/styles/KeywordResearchStyle";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableContainer
} from "@mui/material";
import MuiTableCell from "@mui/material/TableCell";
import Skeleton from "@mui/material/Skeleton";
import { formatNumber } from "../Utils/UserUtils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const TableBodyCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

class OrganicPosts extends Component {
  cardSkeleton = () => {
    let { classes } = this.props;

    return <Skeleton variant="rectangular" className={classes.cardSkeleton} />;
  };

  render() {
    let { classes, organicPosts, organicPostsLoading, fetchingBacklinks } =
      this.props;
    return (
      <div className={classes.keywordIdeasCard}>
        <Typography variant="body1" className={classes.heading}>
          Top Pages on Google
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.firstCell}>Rank</TableCell>

                <TableCell>Title</TableCell>
                {/* <TableCell>
                    Estimated Visits
                    <InfoOutlinedIcon
                      className={classes.infoIcon}
                      fontSize="small"
                      onClick={this.props.showEstimatedVisits}
                    />
                </TableCell> */}

                <TableCell>
                  Domain Authority
                  <InfoOutlinedIcon
                    className={classes.infoIcon}
                    fontSize="small"
                    onClick={this.props.showDomainAuthority}
                  />
                </TableCell>

                <TableCell>
                  Referring Domains
                  <InfoOutlinedIcon
                    className={classes.infoIcon}
                    fontSize="small"
                    onClick={this.props.showReferringDomain}
                  />
                </TableCell>

                <TableCell>
                  Backlinks
                  <InfoOutlinedIcon
                    className={classes.infoIcon}
                    fontSize="small"
                    onClick={this.props.showBacklinks}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            {organicPosts && organicPosts.length > 0 ? (
              <TableBody>
                {organicPosts?.map((post: any, index: any) => {
                  return (
                    <TableRow scope="row" key={index}>
                      <TableBodyCell>{index + 1}</TableBodyCell>

                      <TableBodyCell>
                        <a
                          href={post.url}
                          target="_blank"
                          className={classes.link}
                          rel="noreferrer"
                        >
                          <Typography variant="body2">
                            {post.title ? post.title : post.url}
                          </Typography>
                          {post.title && (
                            <Typography
                              variant="caption"
                              className={classes.pageURL}
                            >
                              {post.url}
                            </Typography>
                          )}
                        </a>
                      </TableBodyCell>
                      {/* <TableBodyCell>
                        {(post.metricsUpdated &&
                          (post.estimatedVisits
                            ? formatNumber(Math.ceil(post.estimatedVisits))
                            : "N/A")) ||
                          this.cardSkeleton()}
                      </TableBodyCell> */}

                      <TableBodyCell>
                        {(post.domainAuthority &&
                          post.metricsUpdated &&
                          (post.domainAuthority
                            ? formatNumber(post.domainAuthority)
                            : "N/A")) ||
                          this.cardSkeleton()}
                      </TableBodyCell>

                      <TableBodyCell>
                        {(post.metricsUpdated &&
                          (post.referringDomains
                            ? formatNumber(post.referringDomains)
                            : "N/A")) ||
                          this.cardSkeleton()}
                      </TableBodyCell>

                      <TableBodyCell>
                        {fetchingBacklinks
                          ? this.cardSkeleton()
                          : post.backlinksCount
                          ? formatNumber(post.backlinksCount)
                          : "N/A"}
                      </TableBodyCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : organicPostsLoading ? (
              <TableBody>
                <TableRow scope="row">
                  <TableBodyCell colSpan={5} className={classes.firstCell}>
                    {this.cardSkeleton()}
                  </TableBodyCell>
                </TableRow>

                <TableRow scope="row">
                  <TableBodyCell colSpan={5} className={classes.firstCell}>
                    {this.cardSkeleton()}
                  </TableBodyCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <TableRow scope="row">
                  <TableBodyCell colSpan={5}>
                    <Typography
                      variant="body1"
                      className={classes.errorMessage}
                    >
                      We couldn't find any posts on Google. Try another keyword.
                    </Typography>
                  </TableBodyCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default withStyles(style)(OrganicPosts);
