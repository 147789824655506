// @ts-nocheck
import React, { useEffect, useState } from "react";

import StyledDialog from "../design/components/StyledDialog";
import { FormControl, Typography } from "@mui/material";

import StyledInput from "../design/components/StyledInput";
import { space } from "../Config/theme";
import Api from "../Services/Api";
import { withStyles } from "@mui/styles";
import { notify } from "./CustomNotifications";
import { channelTypes } from "../Utils/Types";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import styles from "./styles/AIInstructionsDialogStyle";
import { designColors } from "../Themes/Colors";

export const AUTOCOMPLETE_ALL_OPTION_TEXT = "All";

const api = Api.create();

function AIInstructionsDialog({
  classes,
  value,
  open,
  onSaveInstructions,
  onClose,
  publicationId,
  postId,
  // We use LinkedIn instructions for all social media types except Twitter
  type = channelTypes.LINKEDIN,
  identifier = "",
  index = 0,
  selectedSampleIds = [],
  saveSamples = () => {}
}: any) {
  let [instructions, setInstructions] = useState(value);
  let [selectedSampleId, setSelectedSampleId] = useState("");
  let [selectedSample, setSelectedSample] = useState("");
  let [selectedSampleLabel, setSelectedSampleLabel] = useState("");
  let [samples, setSamples] = useState([]);

  function getFormatedSample(sample: any, length = 140) {
    return sample?.length < length ? sample : sample?.slice(0, length) + "...";
  }

  function onSave() {
    saveSamples(
      index,
      samples.filter((sample) => sample?._id?.toString() === selectedSampleId)
    );
    saveInstructions(instructions);
  }

  function onReset() {
    saveSamples(index, []);
    saveInstructions("");
  }

  useEffect(() => {
    getAllSamples();
  }, [identifier]);

  useEffect(() => {
    let selected = samples.find((s) => s._id === selectedSampleId);
    setSelectedSampleLabel(selected?.label || "");
  }, [selectedSampleId, samples]);

  function getAllSamples() {
    let channelType = type || channelTypes.LINKEDIN;
    let channelIdentifier = identifier || "";

    api.getSamples(publicationId, channelType, (res: any) => {
      if (res.status === 200) {
        let allSamples = res?.data || [];
        setSamples(allSamples);
        let selectedSampleObj = allSamples.filter((sample: any) =>
          selectedSampleIds?.some(
            (sampleId) => sampleId?.toString() === sample?._id?.toString()
          )
        )?.[0];

        setSelectedSample(
          selectedSampleObj?.sample || allSamples?.[0]?.sample || ""
        );
        setSelectedSampleId(
          selectedSampleObj?._id?.toString() ||
            allSamples?.[0]?._id?.toString() ||
            ""
        );
      }
    });
  }

  function saveInstructions(instructions: any) {
    api.saveInstructions(
      publicationId,
      postId || "",
      type,
      instructions?.trim() || "",
      (res: any) => {
        if (res.status === 200) {
          onSaveInstructions(instructions);
        } else {
          notify.show("Oops, something went wrong.", "error");
        }
      }
    );
  }

  return (
    <StyledDialog
      open={open}
      title="Custom Instructions"
      body={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: `0px ${space.MEDIUM}`
          }}
        >
          <Typography variant="bodyl" style={{ marginBottom: space.MEDIUM }}>
            Add a few sentences with custom instructions. Speak directly.
          </Typography>

          <StyledInput
            placeholder="Only use emojis in lists. Add new lines after each sentence."
            value={instructions}
            minRows={5}
            maxRows={10}
            multiline
            onChange={(event: any) => setInstructions(event.target.value)}
          />

          <Typography
            variant="bodyl"
            style={{ marginBottom: space.MEDIUM, marginTop: space.MEDIUM }}
          >
            Select sample
          </Typography>

          <div>
            <FormControl
              variant="outlined"
              className={classes.accountDropDown}
              fullWidth
            >
              <StyledSelect
                name={"sample"}
                value={selectedSampleId}
                defaultValue={selectedSampleId}
                renderValue={() => selectedSampleLabel}
                onChange={(event: any) => {
                  setSelectedSampleId(event.target.value);
                  setSelectedSample(
                    samples.find(
                      (sample) => sample?._id?.toString() === event.target.value
                    )?.sample || ""
                  );
                }}
                size="large"
                placeholder="Choose a sample"
                className={classes.input}
                disabled={!Boolean(samples.length)}
              >
                {samples.map((sample, index) => {
                  return (
                    <StyledMenuItem
                      key={index}
                      value={sample?._id?.toString()}
                      className={classes.menuItem}
                    >
                      <Typography className={classes.itemName} variant="bodym">
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: 650
                          }}
                        >
                          {sample?.label || sample?.sample}
                        </div>

                        <div style={{ textTransform: "capitalize" }}>
                          <Typography
                            variant="bodys"
                            style={{ color: designColors.grayScale[60] }}
                          >
                            {getFormatedSample(sample?.sample, 140)}
                          </Typography>
                        </div>
                      </Typography>
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </div>
        </div>
      }
      successButtonName="Save"
      secondaryButtonName="Reset"
      optionCallback={onReset}
      cancelCallback={onClose}
      successCallback={onSave}
    />
  );
}

export default withStyles(styles)(React.forwardRef(AIInstructionsDialog));
