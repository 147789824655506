// @ts-nocheck
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  paper: {
    color: colors.fontPrimary,
    padding: 20,
    marginBottom: 15,
    marginTop: 10
  },

  cardTitle: {
    fontWeight: "bold",
    fontSize: 20
  },

  cardSubtitle: {
    color: colors.fontSecondary,
    marginBottom: 20
  },

  width100: {
    width: "100%"
  },

  headerStyles: {
    [theme.breakpoints.up("xs")]: {
      padding: 10,
      paddingLeft: 25,
      marginTop: 10
    }
  },

  marginTop20: {
    marginTop: 20,
    width: 70
  },

  saveButton: {
    color: colors.white,
    [theme.breakpoints.down("md")]: {
      marginTop: 12,
      marginBottom: 10
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 15
    }
  },

  marginTop12: {
    [theme.breakpoints.down("md")]: {
      marginTop: 12,
      marginBottom: 10
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 12
    }
  },

  marginRight40: {
    [theme.breakpoints.down("md")]: {
      marginRight: 0
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: 40
    }
  },

  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    width: "100%",
    color: colors.fontInput,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontInput
    }
  },

  logoImage: {
    width: 150,
    height: 150
  },

  saveGrid: {
    [theme.breakpoints.down("md")]: {
      marginTop: 10
    },
    margin: "auto auto"
  },

  timeZoneSelect: {
    width: "312px",
    borderRadius: "6px"
  }
});
export default styles;
