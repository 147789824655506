// @ts-nocheck
import {
  Avatar,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography
} from "@mui/material";
import Select from "@mui/material/Select";
import { AvatarSize } from "../../Config/theme";

import { ReactComponent as CheckIcon } from "../../Images/icon24/check.svg";

import { ReactComponent as ChevronDownIcon } from "../../Images/icon24/chevron_down.svg";
import { designColors } from "../../Themes/Colors";
import StyledButton from "./StyledButton";

export default function WorkspaceSelector({
  currentPublicationId,
  publications,
  collapse,
  showPublicationCreate,
  ...other
}: any) {
  if (collapse) {
    let index = publications.findIndex(
      (publication: any) => publication._id === currentPublicationId
    );
    if (index === -1) {
      return "";
    }

    return (
      <div style={{ height: 64 }}>
        <Avatar
          src={publications[index].logo}
          style={{
            ...AvatarSize.m,

            marginTop: 12
          }}
        />
      </div>
    );
  }

  function renderSelectedPublication(option: any) {
    let publication = option;
    if (typeof option === "string") {
      let index = publications.findIndex(
        (publication: any) => publication._id === option
      );
      if (index === -1) {
        return "";
      }

      publication = publications[index];
    }

    return (
      <div
        style={{
          display: "flex",

          marginRight: "12px"
        }}
      >
        <Avatar
          src={publication.logo}
          style={{
            ...AvatarSize.m,

            marginRight: "12px"
          }}
        />

        <Typography
          variant="h200"
          style={{
            marginBottom: "1px",

            textOverflow: "ellipsis",

            overflow: "hidden",

            whiteSpace: "nowrap",

            margin: "auto 0px"
          }}
        >
          {publication.name}
        </Typography>
      </div>
    );
  }

  function renderOptions(publication: any) {
    return (
      <>
        <ListItemIcon>
          <Avatar
            src={publication.logo}
            style={{
              ...AvatarSize.m,

              marginRight: "12px"
            }}
          />
        </ListItemIcon>
        <ListItemText
          style={{
            marginBottom: "1px",

            textOverflow: "ellipsis",

            overflow: "hidden",

            whiteSpace: "nowrap",

            margin: "auto 0px"
          }}
          primary={
            <Typography
              variant="h200"
              sx={{
                color: designColors.grayScale[60]
              }}
            >
              {publication.name}
            </Typography>
          }
        />

        {publication._id === currentPublicationId && <CheckIcon />}
      </>
    );
  }

  return (
    <Select
      disableUnderline
      variant="standard"
      IconComponent={ChevronDownIcon}
      renderValue={renderSelectedPublication}
      MenuProps={{
        PaperProps: {
          style: {
            borderRadius: "12px",

            width: "300px",

            marginTop: "4px",

            padding: "12px"
          }
        },
        MenuListProps: {
          style: {
            padding: "0px"
          }
        }
      }}
      sx={{
        "&:hover": {
          borderRadius: "12px",

          backgroundColor: designColors.grayScale.select
        },
        "& .MuiInput-input:focus": {
          borderRadius: "12px"
        },
        "& .MuiInputBase-input:focus": {
          borderRadius: "12px"
        },
        "&.MuiInput-root": {
          borderRadius: "12px"
        },
        "& .MuiInput-input": {
          paddingRight: "34px"
        },
        "& .MuiInputBase-input": {
          paddingRight: "34px",

          borderRadius: "12px"
        },
        "& .MuiSelect-select": {
          padding: "12px",

          borderRadius: "12px"
        },
        "& .MuiSelect-icon": {
          marginRight: "12px",
          top: "auto"
        }
      }}
      {...other}
    >
      {publications.map((publication: any, index: any) => {
        return (
          <MenuItem
            sx={{
              padding: "12px",

              borderRadius: "12px",

              marginBottom: "3px"
            }}
            value={publication._id}
            key={index}
          >
            {renderOptions(publication)}
          </MenuItem>
        );
      })}
      <Divider
        style={{
          marginTop: 9,

          marginBottom: 12,

          borderTop: "1px solid " + designColors.grayScale[5]
        }}
      />

      <StyledButton fullWidth size="large" onClick={showPublicationCreate}>
        Workspace
      </StyledButton>
    </Select>
  );
}
