// @ts-nocheck
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  dropzone: {
    margin: 10
  },

  uploadingText: {
    textAlign: "center",
    color: colors.primary
  }
});
export default styles;
