// @ts-nocheck
import { Popover } from "@mui/material";
import firebase from "firebase/app";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import ReactTooltip from "react-tooltip";

import Flatpickr from "react-flatpickr";
import "../../src/flatpicker.css";
import API from "../Services/Api";
import PageAccessContext from "../Utils/PageAccessContext";
import { datePickerDefault } from "../Utils/Types";

import _ from "lodash";

require("flatpickr/dist/flatpickr.css");
const api = API.create();
const DEBOUNCE_TIME = 1000;
function CustomFlatPicker(props: any) {
  let context = useContext(PageAccessContext);
  let {
    open,
    value,
    onClose,
    showInput = false,
    anchorEl,
    disablePast = true,
    scheduledDates = []
  } = props;

  let flatpickrRef = useRef(null);

  // Create a new Set of scheduled dates, to ensure that duplicates are removed
  const scheduledDatesSet = useMemo(() => {
    return new Set(
      scheduledDates.map((date: any) => new Date(date).toDateString())
    );
  }, [scheduledDates]);

  // Set the style and tooltip for each day that matches a scheduled date
  const setHighlightedDates = (instance: any) => {
    // If there are no scheduled dates, return
    if (!scheduledDatesSet.size) return;

    // Get all the day nodes of the current month
    const days = instance?.days?.childNodes;

    days.forEach((day: any) => {
      const dateStr = day.dateObj.toDateString();
      // If the day's date matches a scheduled date, set the style and tooltip
      if (scheduledDatesSet.has(dateStr)) {
        day.classList.add("highlighted");
        day.setAttribute(
          "data-tip",
          "You already scheduled a different post on this date"
        );
        day.setAttribute("data-for", "occupied-date");
        day.addEventListener("mouseenter", () => ReactTooltip.show(day));
        day.addEventListener("mouseleave", () => ReactTooltip.hide(day));
      }
    });
  };

  const handleMonthYearChange = (
    selectedDates: any,
    dateStr: any,
    instance: any
  ) => {
    requestAnimationFrame(() => setHighlightedDates(instance));
  };

  /**To generate selected date for date picker
  1. If date value is sent from parent - return value
  2. Else if picker type = social/deadline - return current date with default time from publication data
  3. Else return current date**/
  function applyWorkspaceDefaultTimeToDate(existingDate: any) {
    if (existingDate) {
      return existingDate.toString();
    }
    let { publicationId, type } = props;
    if (publicationId && type) {
      let currentPublication = context.publications?.filter(
        (publication: any) => publication._id == publicationId
      );
      let time = currentPublication?.[0]?.defaultCalendarTime || null;
      if (time) {
        if (type === datePickerDefault.SOCIAL) {
          let hours = time?.social?.hour || 12;
          let minutes = time?.social?.minute ? time.social.minute : 0;
          existingDate = new Date();
          existingDate.setHours(hours, minutes, 0);
          return existingDate.toString();
        } else if (type === datePickerDefault.DEADLINE) {
          let hours = time?.deadline?.hour || 12;
          let minutes = time?.deadline?.minute ? time.deadline.minute : 0;
          existingDate = new Date();
          existingDate.setHours(hours, minutes, 0);
          return existingDate.toString();
        }
      }
    }
    return new Date().toString();
  }

  let [changedDate, setDateChange] = useState(
    applyWorkspaceDefaultTimeToDate(value)
  );

  //To reload picker with selected value
  useEffect(() => {
    setDateChange(applyWorkspaceDefaultTimeToDate(value));
  }, [value]);

  let updateDefaultTime = (date: any) => {
    if (!firebase.auth().currentUser) {
      return;
    }
    let { publicationId, type } = props;
    if (publicationId && type) {
      let selectedDate = new Date(date);
      api.setDefaultCalendarTime(
        publicationId,
        selectedDate.getHours(),
        selectedDate.getMinutes(),
        type,
        (res: any) => {
          if (res.status === 200) {
          }
        }
      );
    }
  };

  let debouncedChangeDate = _.debounce(
    (date: any) => updateDefaultTime(date),
    DEBOUNCE_TIME
  );

  function callDateChange(date: any, dateStr: any, instance: any) {
    setDateChange(date);
    debouncedChangeDate(date);
    requestAnimationFrame(() => setHighlightedDates(instance));
  }
  return (
    <Popover
      id="date_time_picker"
      open={open}
      anchorEl={anchorEl || <div />}
      anchorOrigin={{
        vertical: anchorEl ? "bottom" : "center",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: anchorEl ? "top" : "center",
        horizontal: "center"
      }}
      onClose={() => {
        changedDate = changedDate || new Date();
        onClose(new Date(changedDate));
      }}
    >
      <div style={{ overflowY: "hidden" }}>
        <Flatpickr
          ref={flatpickrRef}
          value={new Date(changedDate)}
          onChange={callDateChange}
          onValueUpdate={callDateChange}
          onReady={handleMonthYearChange}
          onMonthChange={handleMonthYearChange}
          onYearChange={handleMonthYearChange}
          options={{
            minDate: disablePast ? "today" : "",
            inline: showInput ? false : true,
            enableTime: true
          }}
          render={({ defaultValue }: any, ref: any) => {
            return (
              <div>
                <input
                  defaultValue={defaultValue}
                  ref={ref}
                  style={{ display: showInput ? "block" : "none" }}
                />
              </div>
            );
          }}
        />

        <ReactTooltip id="occupied-date" globalEventOff="click" />
      </div>
    </Popover>
  );
}

CustomFlatPicker.propTypes = {
  open: PropTypes.bool,
  readOnly: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

CustomFlatPicker.defaultProps = {
  open: false,
  anchorEl: null
};

export default CustomFlatPicker;
