// @ts-nocheck
import colors, { designColors } from "../../Themes/Colors";
import { grey } from "@mui/material/colors";
import { shadow, space } from "../../Config/theme";

const styles = (theme: any) => ({
  container: {
    paddingLeft: 0,
    paddingBottom: 100,
    backgroundColor: colors.backgroundColor,
    color: colors.fontPrimary
  },

  paper: {
    backgroundColor: colors.card,
    borderRadius: 10,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    padding: 20
  },

  grid: {
    // paddingLeft: "2%",
    // paddingRight: "2%",
    // paddingTop: 60,
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: "7%",
    //   paddingRight: "7%"
    // }
  },

  nameDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },

  letterName: {
    color: "white"
  },

  letterNameIcon: {
    fontSize: 20,
    color: grey[300]
  },

  subscribed: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    }
  },

  peopleIcon: {
    fontSize: 20,
    color: "white"
  },

  countStyle: {
    color: "white",
    marginLeft: 10
  },

  descriptionStyle: {
    color: colors.fontPrimary,
    marginTop: 10,
    height: 70,
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical"
  },

  subscribeBtnDiv: {
    display: "flex",
    direction: "row",
    justifyContent: "center"
  },

  searchBar: {
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "80%",
      marginTop: 10
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%"
    }
  },

  normalBtn: {
    width: 120,
    marginTop: 20,
    color: "white",
    backgroundColor: colors.card
  },

  subscribeBtn: {
    width: 120,
    marginTop: 20,
    backgroundColor: colors.primary,
    color: colors.white
  },

  lineItem: {
    color: colors.white
  },

  item: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
    "&:hover": {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 5
    }
  },

  itemSelected: {
    "&:hover": {
      background: colors.hover,
      cursor: "pointer"
    }
  },

  textAlignRight: {
    color: colors.white,
    textAlign: "right"
  },

  errorMessage: {
    paddingTop: 10,
    color: colors.fontSecondary,
    textAlign: "center"
  },

  marginTop30: {
    marginTop: 30
  },

  sectionHeading: {
    margin: "auto 0px",
    paddingBottom: 10,
    marginRight: 15,
    cursor: "pointer",
    position: "relative",
    padding: "6px 0 7px;",
    [theme.breakpoints.down("md")]: {
      marginRight: 10
    }
  },

  unselectedStatus: {
    "&::after": {
      background: ["none repeat scroll 0 0 transparent", "#000"],
      bottom: "0",
      content: '""',
      display: "block",
      height: "2px",
      left: "50%",
      position: "absolute",
      transition: "width 0.3s ease 0s, left 0.3s ease 0s",
      width: "0"
    },
    "&:hover::after": {
      borderBottom: "2px solid " + colors.selectHover,
      width: "100%",
      left: 0
    }
  },

  selectedStatus: {
    borderBottom: "2px solid " + colors.selectedItem
  },

  pageTitle: {
    color: colors.fontPrimary,
    fontWeight: "bolder",
    marginTop: 5
  },

  pageStatistics: {
    color: colors.fontSecondary,
    fontSize: "0.90rem"
  },

  number: {
    color: colors.fontPrimary,
    fontWeight: "bolder"
  },

  postMenuButton: {
    paddingLeft: 15,
    marginTop: 12
  },

  newPostButton: {
    margin: 10
  },

  buttonIcon: {
    height: "0.9em",
    marginRight: 5
  },

  topSection: {
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%"
  },

  sectionSelectorContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 30
  },

  viewButton: {
    color: colors.fontSecondary,
    marginRight: 10
  },

  getStarted: {
    height: 400,
    width: "100%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  },

  postStatusIcon: {
    fontSize: 12,
    marginLeft: 6,
    marginBottom: -1,
    color: grey[600]
  },

  starIcon: {
    fontSize: 16,
    marginLeft: 4,
    marginBottom: -2,
    color: grey[600]
  },

  popover: {
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    padding: 10,
    borderRadius: 10,
    marginTop: 10
  },

  menuText: {
    paddingLeft: 3,
    fontSize: "inherit"
  },

  menuItem: {
    display: "flex",
    color: "black",
    width: "100%",
    padding: "10px 2px 10px 2px",
    borderRadius: 5,
    "&:hover": {
      background: colors.hover,
      cursor: "pointer"
    }
  },

  icon: {
    height: 20
  },

  menuIcon: {
    height: 18,
    margin: "auto 0px",
    marginRight: 7,
    color: grey[700]
  },

  liveIcon: {
    height: 22,
    margin: "0px 10px 0px 3px",
    paddingLeft: "2px",
    color: grey[700]
  },

  logoImage: {
    height: 20,
    width: 20,
    marginRight: 5,
    marginTop: -5
  },

  publicationTitle: {
    color: colors.fontPrimary,
    fontSize: "1.2em",
    overflowWrap: "anywhere",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },

  flexFlow: {
    flexFlow: "row"
  },

  publishedOn: {
    color: colors.fontSecondary
  },

  publicationTitleContainer: {
    marginTop: 8,
    marginBottom: 8
  },

  publicationTitleOverflow: {
    overflow: "hidden",
    marginRight: 20
  },

  overFlow: {
    overflow: "hidden",
    marginBottom: 15
  },

  actionsContainer: {
    margin: "auto 0",
    flexDirection: "row",
    justifyContent: "flex-end"
  },

  postTitle: {
    fontWeight: "bolder",
    overflowWrap: "anywhere",
    display: "flex",
    alignItems: "center",
    gap: space.MEDIUM
  },

  width100: {
    width: "100%"
  },

  scrollHeight: {
    height: "auto",
    overflow: "hidden"
  },

  postAuthorAvatar: {
    width: 16,
    height: 16,
    marginRight: 5
  },

  authorContainer: {
    color: colors.fontSecondary,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },

  labels: {
    color: colors.fontSecondary,
    margin: "auto 0"
  },

  popper: {
    bottom: 0,
    left: "50% !important",
    width: "50%",
    top: "unset !important",
    transform: "translate(-50%, -50%)",
    zIndex: 5000,
    [theme.breakpoints.down("md")]: {
      left: "30% !important",
      width: "70%",
      transform: "translate(-30%, -30%)"
    }
  },

  bulkActionPaper: {
    padding: "10px 16px",
    borderRadius: 6,
    background: designColors.primary[50],
    color: colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  filterPopover: {
    boxShadow: shadow.LARGE,
    padding: space.LARGE,
    borderRadius: 6,
    marginTop: space.SMALL,
    width: 400
  },

  inputSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: space.LARGE
  },

  compositeSeoSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: space.MEDIUM
  }
});
export default styles;
