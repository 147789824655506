// @ts-nocheck
import { oembed } from "@loomhq/loom-embed";
import { setup, isSupported } from "@loomhq/record-sdk";
import Api from "../Services/Api";

const LOOM_URL_MATCH_REGEX = new RegExp(
  "(?:https?://)?((?:stage.loom.com|loom.com|www.loom.com|loomlocal.com:4444)/share/[a-f0-9]+)",
  "g"
);

const api = Api.create();

export async function replaceLoomUrlWithEmbed(
  textString: any,
  options = {},
  isFromCollaboratorChat = false
) {
  let textInput = textString || "";
  // While editing comment the loom url will decoded
  // Replacing encoded front slash
  try {
    textInput = textInput.replaceAll("&#x2F;", "/");
    const loomMatches = textInput.match(LOOM_URL_MATCH_REGEX);
    if (!loomMatches) {
      return textInput;
    }

    let embedPromises: any = [];
    loomMatches.map(async (url: any) => {
      embedPromises.push(
        new Promise(async (resolve, reject) => {
          try {
            const { html } = await oembed(url, options);
            resolve({ url, embedCode: html });
          } catch (err) {
            let errorPage = `<iframe
            src="${url.replace("share", "embed")}"
            frameborder="0"
            
            width="${options.width || 500}"
            style="aspect-ratio:${
              isFromCollaboratorChat ? 16 / 13 : 16 / 9
            };" />`;
            resolve({ url, embedCode: errorPage });
          }
        })
      );
    });

    const embedData = await Promise.all(embedPromises);
    embedData.forEach((data) => {
      const { url, embedCode } = data || {};
      if (url && embedCode) {
        const urlReplaceRegex = new RegExp(url, "g");
        textInput = textInput.replace(urlReplaceRegex, embedCode);
      }
    });
  } catch (error) {}
  return textInput;
}

export function getLoomConfigButton(postId = null) {
  return new Promise(async (resolve, reject) => {
    try {
      api.loomJWS(postId, async (res: any) => {
        if (res.status === 200) {
          const { supported } = await isSupported();
          if (!supported || !res.data) {
            resolve(null);
            return;
          }

          let { configureButton } = await setup({
            jws: res.data
          });

          resolve(configureButton);
        }
      });
    } catch (error) {
      resolve(null);
    }
  });
}
