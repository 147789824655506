// @ts-nocheck
import { withStyles } from "@mui/styles";

import { CardElement, injectStripe } from "react-stripe-elements";
import styles from "./styles/CheckoutFormStyle";
import StyledButton from "../design/components/StyledButton";

import StripeWatermark from "./StripeWatermark";
import { Component } from "react";
import { notify } from "./CustomNotifications";
import Api from "../Services/Api";
import { CircularProgress, Typography } from "@mui/material";

const api = Api.create();

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        fontFamily: "Inter, sans-serif",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#c23d4b"
      }
    }
  };
};

class CheckoutForm extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: ""
    };
  }

  handleChange = ({ error }: any) => {
    if (error) {
      this.setState({
        errorMessage: error.message
      });
    }
  };

  save = async () => {
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true }, async () => {
      let { email } = this.props;

      let { token } = await this.props.stripe.createToken();

      if (token) {
        api.saveCard(token.id, email, (res: any) => {
          if (res.status === 200) {
            this.setState({ loading: false }, () => {
              this.props.onCardSaved();
            });
          } else {
            this.setState({
              loading: false
            });
            notify.show(
              res.status === 400
                ? res.data
                : "We were unable to validate your payment card.",
              "error"
            );
          }
        });
      } else {
        this.setState({
          loading: false
        });
        notify.show(
          "Invalid credit card details. Make sure you entered it right",
          "error"
        );
      }
    });
  };

  render() {
    let { classes } = this.props;

    let { errorMessage, loading } = this.state;
    return (
      <div className={classes.container}>
        <Typography variant="bodym" style={{ marginBottom: 8 }}>
          Enter your payment info and play around with our two week trial. We'll
          reach out to onboard you personally with lots of ❤️ and care. You
          won't be charged.
        </Typography>

        <div className={classes.cardForm}>
          <CardElement onChange={this.handleChange} {...createOptions()} />
        </div>

        <div className={classes.error} role="alert">
          {errorMessage}
        </div>

        <StyledButton
          variant="primary"
          onClick={this.save}
          className={classes.button}
        >
          {loading && (
            <CircularProgress
              size={20}
              color="inherit"
              style={{ marginRight: 10 }}
            />
          )}
          Next
        </StyledButton>

        <StripeWatermark />
      </div>
    );
  }
}

export default injectStripe(withStyles(styles)(CheckoutForm));
