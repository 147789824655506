// @ts-nocheck
const styles = {
  container: {
    height: "100vh"
  },
  error: {
    textAlign: "center",
    marginTop: 50
  },
  main: {
    textAlign: "center"
  },
  title: {
    marginBottom: 30
  },
  animation: {
    maxWidth: 300,
    margin: "auto"
  },
  back: {
    marginTop: 30
  }
};

export default styles;
