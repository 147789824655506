// @ts-nocheck
import colors from "../../Themes/Colors";

const useStyles = (theme: any) => ({
  root: {
    width: "100%"
  },

  cardFormContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 0
  },

  innerContainer: {
    display: "flex",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    },
    marginBottom: 20
  },

  text: {
    display: "flex",
    flexDirection: "row",
    color: colors.fontSecondary,
    margin: "0 auto",
    fontSize: 12
  },

  divider: {
    fontSize: 12,
    color: colors.fontSecondary,
    [theme.breakpoints.down("sm")]: {
      visibility: "hidden"
    },
    [theme.breakpoints.up("md")]: {
      visibility: "visible"
    }
  },

  lockIcon: {
    fontSize: 14,
    marginTop: 1,
    color: colors.fontSecondary
  },

  link: {
    color: colors.fontSecondary,
    fontSize: 12,
    cursor: "pointer",
    "&:hover": {
      color: colors.link
    }
  },

  image: {
    marginTop: -1,
    height: 20
  }
});

export default useStyles;
