// @ts-nocheck
import { Typography } from "@mui/material";
import { space } from "../../Config/theme";

export default function StyledInputLabel({ label, helperText, ...props }: any) {
  return (
    <>
      <Typography
        paragraph
        style={{ marginBottom: space.SMALL }}
        {...props}
        variant="h300"
      >
        {label}
      </Typography>

      {helperText && (
        <Typography
          paragraph
          variant="bodym"
          style={{ marginBottom: space.SMALL }}
        >
          {helperText}
        </Typography>
      )}
    </>
  );
}
