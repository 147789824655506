// @ts-nocheck
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

class InfoIcon extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <InfoOutlinedIcon
        style={{
          fontSize: 17,
          marginLeft: 8,
          verticalAlign: "middle",
          cursor: "pointer"
        }}
        {...this.props}
      />
    );
  }
}

export default InfoIcon;
