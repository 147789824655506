import Chip, { ChipProps } from "@mui/material/Chip";
import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as DeleteIcon20 } from "../../Images/icon20/close.svg";
import { designColors } from "../../Themes/Colors";

function StyledUserChip({
  label,
  variant,
  size,
  textColor,
  ...other
}: ChipProps & {
  textColor?: string;
}) {
  return (
    <Chip
      variant={variant}
      label={label}
      size={size}
      deleteIcon={<DeleteIcon20 />}
      sx={{
        "&.MuiChip-root": {
          borderRadius: "5000px",

          margin: "2px",

          backgroundColor: designColors.grayScale[5],

          color: textColor ? textColor : designColors.grayScale[100],
          "&:hover": {
            backgroundColor: designColors.primary[0]
          }
        },
        "&.MuiChip-sizeSmall": {
          typography: "bodys",

          height: "32px"
        },
        "&.MuiChip-sizeMedium": {
          typography: "bodym",

          height: "40px"
        },
        "&.MuiChip-sizeLarge": {
          typography: "bodyl",

          height: "48px"
        },
        "&.Mui-disabled": {
          backgroundColor: designColors.grayScale[5]
        },
        "& .MuiChip-labelSmall": {
          paddingLeft: "12px",

          paddingRight: "12px"
        },
        "& .MuiChip-labelMedium": {
          paddingLeft: "16px",

          paddingRight: "16px"
        },
        "& .MuiChip-labelLarge": {
          paddingLeft: "20px",

          paddingRight: "20px"
        },
        "& .MuiAvatar-root": {
          marginRight: "0px"
        },
        "& .MuiChip-avatar": {
          marginLeft: "4px"
        },
        "& .MuiChip-avatarLarge": {
          width: "40px",

          height: "40px"
        },
        "& .MuiChip-avatarMedium": {
          width: "32px",

          height: "32px"
        },
        "& .MuiChip-avatarSmall": {
          width: "24px",

          height: "24px"
        },
        "& .MuiChip-iconLarge": {
          width: "40px",

          height: "40px"
        },
        "& .MuiChip-iconMedium": {
          width: "32px",

          height: "32px"
        },
        "& .MuiChip-iconSmall": {
          width: "24px",

          height: "24px"
        },

        "& .MuiChip-deleteIconLarge": {
          width: "20px",

          height: "20px",

          marginRight: "20px"
        },
        "& .MuiChip-deleteIconMedium": {
          width: "16px",

          height: "16px",

          marginRight: "16px"
        },
        "& .MuiChip-deleteIconSmall": {
          width: "12px",

          height: "12px",

          marginRight: "12px"
        }
        // "&.Mui-selected": {
        //   backgroundColor: designColors.primary[50]
        // },
        // "&.MuiAutocomplete-tag": {
        //   backgroundColor: designColors.primary[50],
        //   color: designColors.white
        // }
      }}
      {...other}
    />
  );
}

StyledUserChip.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"])
};

StyledUserChip.defaultProps = {
  label: "Avatar",
  size: "small"
};

export default StyledUserChip;
