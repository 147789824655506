// @ts-nocheck
import React, { Component } from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Container,
  Avatar
} from "@mui/material";

import Loading from "../Components/Loading";
import styles from "./styles/BlogPostPreviewDialogStyles";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import API from "../Services/Api";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Toolbar from "@mui/material/Toolbar";
import Images from "../Themes/Images";
import "../quill.bubble.preview.css";
import { DEFAULT_BLOG_FONT, DEFAULT_EDITOR_FONT } from "../Utils/Types";
import { formatForDisplayDateTime } from "../Utils/TimeUtils";
import {
  formatReadTime,
  loadInstagramScript,
  loadTikTokScript,
  loadTwitterScript,
  replaceAll,
  updateCodeSyntaxHighlighting
} from "../Utils/UserUtils";
import CustomInnerHtmlText from "./CustomInnerHtmlText";

const buttonTypes = {
  DESKTOP: "desktop",
  MOBILE: "mobile"
};

const api = API.create();

class BlogPostPreviewDialog extends Component {
  checkScroll: any;
  gotoBlogHome: any;
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      type: buttonTypes.DESKTOP,
      htmlText: "",
      coverImage: "",
      title: "",
      subtitle: "",
      publishedOn: "",
      showPostCoverPhoto: false,
      titleFont: DEFAULT_BLOG_FONT,
      postReadTime: "",
      authorName: "",
      authorProfilePic: "",
      bodyFont: DEFAULT_EDITOR_FONT
    };
  }

  componentDidMount = () => {
    this.load();
  };

  mobileViewClicked = () => {
    this.setState({ type: buttonTypes.MOBILE }, () => {
      let articles = document.getElementsByTagName("article");
      for (let article of articles) {
        article.style.maxWidth = "350px";
      }
    });
  };

  desktopViewClicked = () => {
    this.setState({ type: buttonTypes.DESKTOP }, () => {
      let articles = document.getElementsByTagName("article");
      for (let article of articles) {
        article.style.maxWidth = "100%";
      }
    });
  };
  load = () => {
    let { postId } = this.props;

    api.getBlogPostPreview(postId, (res: any) => {
      if (res.status === 200) {
        let {
          htmlText,
          readTime,
          showPostCoverPhoto,
          coverImage,
          author,
          title,
          subtitle,
          publishedOn
        } = res.data;
        this.setState(
          {
            loading: false,
            htmlText,
            showPostCoverPhoto,
            coverImage,
            title,
            subtitle,
            publishedOn,
            postReadTime: formatReadTime(readTime),
            authorName: author?.name,
            authorProfilePic: author?.profilePic,
            bodyFont: DEFAULT_EDITOR_FONT,
            titleFont: DEFAULT_BLOG_FONT,
            flags: res.data.flags,
            error: false
          },
          () => {
            this.setScriptFlags(res.data.flags);
          }
        );
      } else {
        this.setState({ error: true, loading: false });
      }
    });
  };

  setScriptFlags(flags: any) {
    setTimeout(async () => {
      if (flags && flags.loadInstaScript) {
        await loadInstagramScript();
      }

      if (flags && flags.loadTikTokScript) {
        await loadTikTokScript();
      }

      if (flags && flags.loadTwitterScript) {
        await loadTwitterScript();
      }

      if (flags && flags.initSyntaxHighlighting) {
        updateCodeSyntaxHighlighting();
      }
    }, 3000);
  }

  render() {
    let { showPopup, classes } = this.props;
    let {
      type,

      bodyFont,

      postReadTime,

      authorName,

      authorProfilePic,

      htmlText,

      showPostCoverPhoto,

      coverImage,

      title,

      subtitle,

      publishedOn,

      titleFont,

      loading,

      error
    } = this.state;

    return (
      <Dialog //did not changed to styled dialog because it is having different design
        open={showPopup}
        onClose={this.props.closeDialog}
        maxWidth="md"
        fullScreen
      >
        <Toolbar>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.flexDirection}
          >
            <IconButton
              onClick={this.props.closeDialog}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
            {!error && (
              <ToggleButtonGroup value={type}>
                <ToggleButton
                  value={buttonTypes.DESKTOP}
                  onClick={this.desktopViewClicked}
                  color="primary"
                >
                  Desktop
                </ToggleButton>

                <ToggleButton
                  value={buttonTypes.MOBILE}
                  onClick={this.mobileViewClicked}
                  color="primary"
                >
                  Mobile
                </ToggleButton>
              </ToggleButtonGroup>
            )}

            <div style={{ width: 48 }} />
          </Grid>
        </Toolbar>

        <DialogContent dividers={true}>
          <Container>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <div
                className={
                  type === buttonTypes.DESKTOP
                    ? classes.desktop
                    : classes.mobile
                }
              >
                {loading && <Loading />}

                {!loading && !error && (
                  <>
                    {showPostCoverPhoto && coverImage && (
                      <Grid
                        item
                        className={classes.postTitleContainer}
                        style={{
                          padding:
                            type === buttonTypes.MOBILE ? "0px" : "0px 20%"
                        }}
                      >
                        <img
                          className={classes.coverImage}
                          src={coverImage}
                          alt="post cover"
                          style={{
                            width: "100%",
                            height: "100%",
                            maxHeight: 400
                          }}
                        />
                      </Grid>
                    )}

                    <Grid
                      item
                      className={classes.postTitleContainer}
                      style={{
                        padding: type === buttonTypes.MOBILE ? "0px" : "0px 20%"
                      }}
                    >
                      <Typography
                        variant="h1"
                        className={`title ${classes.title}`}
                        style={{
                          direction: "ltr",
                          textAlign: "left",
                          fontFamily: titleFont
                        }}
                      >
                        {title}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      className={classes.postTitleContainer}
                      style={{
                        padding: type === buttonTypes.MOBILE ? "0px" : "0px 20%"
                      }}
                    >
                      <Typography
                        variant="h2"
                        className={`subtitle ${classes.subtitle}`}
                        style={{
                          direction: "ltr",
                          textAlign: "left",
                          fontFamily: titleFont
                        }}
                      >
                        {subtitle}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.postTitleContainer}
                      style={{
                        padding: type === buttonTypes.MOBILE ? "0px" : "0px 20%"
                      }}
                    >
                      {authorName && (
                        <div className={classes.authorCredits}>
                          <Avatar
                            className={classes.avatar}
                            onClick={this.gotoBlogHome}
                          >
                            <img
                              className={classes.avatar}
                              src={authorProfilePic}
                              alt="logo"
                              height="24px"
                              width="24px"
                            />
                          </Avatar>

                          <Typography
                            variant="h3"
                            className={`authorName ${classes.authorName}`}
                            onClick={this.gotoBlogHome}
                          >
                            {authorName}
                          </Typography>
                        </div>
                      )}

                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        className={classes.readTimeAndDate}
                      >
                        {publishedOn && (
                          <Typography variant="h3" className={classes.date}>
                            {formatForDisplayDateTime(publishedOn)}
                          </Typography>
                        )}

                        <Typography variant="h4" className={classes.readTime}>
                          {postReadTime}
                        </Typography>
                      </Grid>
                    </Grid>

                    <div
                      className={`quill ${classes.postBody}`}
                      style={{
                        padding: type === buttonTypes.MOBILE ? "0px" : "0px 20%"
                      }}
                    >
                      <div
                        className="ql-pv-container ql-pv-bubble ql-pv-disabled"
                        style={{ position: "relative" }}
                      >
                        <CustomInnerHtmlText
                          className="ql-pv-editor"
                          contentEditable="false"
                          onScroll={this.checkScroll}
                          html={replaceAll(htmlText, "ql-", "ql-pv-")} //Changing class names to preview(pv) classes
                          style={{
                            direction: "ltr",
                            textAlign: "left",
                            fontFamily: bodyFont
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                {error && (
                  <Grid container direction="column" justifyContent="center">
                    <img
                      alt="lost"
                      className={classes.errorImage}
                      src={Images.lost}
                    />

                    <Typography className={classes.error}>
                      We couldn't generate a preview 🙁
                    </Typography>
                  </Grid>
                )}
              </div>
            </Grid>
          </Container>
        </DialogContent>
      </Dialog>
    );
  }
}

BlogPostPreviewDialog.propTypes = {
  postId: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  showPopup: PropTypes.bool.isRequired
};

export default withStyles(styles)(BlogPostPreviewDialog);
