// @ts-nocheck
const styles = (theme: any) => ({
  tableViewContainer: {
    maxWidth: "100%",
    display: "inline-grid"
    // marginLeft: "-20%",
    //marginRight: "-20%"
  },

  headerStyleWithNoWrap: {
    whiteSpace: "nowrap"
  },

  dateCell: {
    whiteSpace: "nowrap"
  },

  titleCell: {
    width: 200,
    overflowWrap: "anywhere",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },

  link: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)"
  }
});

export default styles;
