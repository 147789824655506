// @ts-nocheck
import { faVideo } from "@fortawesome/free-solid-svg-icons/faVideo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isSupported, setup } from "@loomhq/record-sdk";
import AddIcon from "@mui/icons-material/Add";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@mui/lab";
import {
  Avatar,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  LinearProgress,
  FormHelperText,
  Autocomplete
} from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";
import React, { ContextType } from "react";
import { notify } from "../Components/CustomNotifications";

import CustomProjectFieldsView from "../Components/CustomProjectFieldsView";

import FilePreview from "../Components/FilePreview";

import Loading from "../Components/Loading";

import ProjectCustomFieldInputs from "../Components/ProjectCustomFieldInputs";

import StyledDialog from "../design/components/StyledDialog";

import TagsInput from "../Components/TagsInput";

import TopicAssigneesAndDeadlines from "../Components/TopicAssigneesAndDeadlines";

import TopicComments from "../Components/TopicComments";

import ViewFileAttachment from "../Components/ViewFileAttachment";
import { AvatarSize, space } from "../Config/theme";
import StyledButton from "../design/components/StyledButton";

import StyledChip from "../design/components/StyledChip";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";

import StyledInput from "../design/components/StyledInput";

import StyledQuillInput from "../Components/StyledQuillInput";
import "../quill.bubble.comment.css";
import UnstyledLink from "../design/components/UnstyledLink";

import { ReactComponent as MessageBubbleIcon } from "../Images/icon16/messagebubble.svg";

import { ReactComponent as CloseIcon } from "../Images/icon24/close.svg";

import { ReactComponent as EditIcon } from "../Images/icon24/edit.svg";
import Api from "../Services/Api";
import colors, { designColors } from "../Themes/Colors";
import { replaceLoomUrlWithEmbed } from "../Utils/LoomUtils";
import PageAccessContext from "../Utils/PageAccessContext";
import { uploadFiles } from "../Utils/Storage";
import { getItemFromLocalStorage } from "../Utils/StorageHelper";
import {
  formatForDisplayDateTime,
  formatForTimeWithTimeZone,
  getTimelineValue
} from "../Utils/TimeUtils";
import {
  acceptedFileUploadTypes,
  customFieldDataType,
  pageAccess,
  PROFILE_PIC,
  ideaTemplateStatus,
  topicEvent,
  topicStatus,
  channelTypes,
  analysisStatus,
  outlineTypes,
  marketingCampaign,
  modulesAccess
} from "../Utils/Types";
import {
  formatNumber,
  getFormattedUserNames,
  handleSingular,
  isAccessDisabled,
  redirectToLoginPage
} from "../Utils/UserUtils";
import styles from "./styles/TopicStyle";
import linkifyHtml from "linkifyjs/lib/linkify-html";
import { decode } from "html-entities";

import KeywordAutocomplete from "../Components/KeywordAutocomplete";
import { Box } from "@mui/system";

import { ANONYMOUS } from "./Ideas";

import { marked } from "marked";
import { isModuleDisabled } from "../Utils/AccessUtils";

const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
const LOOM_BUTTON_ID = "topic_loom_button";

const _ = require("lodash");

const Page404 = React.lazy(() => import("../Containers/Page404"));

const api = Api.create();

const KEYWORD_REGX = /^[a-zA-Z0-9\s-]*$/;

const DEBOUNCE_TIME = 1000; // 1 second

const projectColumnName = {
  proposed: "Proposed",
  draft: "Draft",
  review: "Final Review",
  approved: "Approved",
  outline: "Outline",
  outlineReview: "Outline Review",
  published: "Published",
  scheduled: "Scheduled"
};

// Hyperlink URLs in comments
// Open links in new tab
const linkifyHtmlOptions = {
  rel: "noopener noreferrer",
  target: "_blank"
};
class Topic extends React.Component {
  static contextType = PageAccessContext;
  getOutlineGenerationInterval: any;
  isUnmounted: any;
  keywordInputRef: any;
  labelRef: any;
  quillRef: any;
  reactQuillDescriptionRef: any;
  saveTopicData: any;
  toggleShowTopic: any;
  updateTopicDetails: any;
  uploadFileRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      topicId: props.topicId ? props.topicId : "",
      duplicateTopic: props.duplicateTopic ? props.duplicateTopic : null,
      viewOnly: props.viewOnly,
      editDescription: false,
      loading: true,
      profilePic: getItemFromLocalStorage(PROFILE_PIC),
      error: false,
      errorMessage: "",
      title: props.title ? props.title : "",
      description: props.description ? props.description : "",
      descriptionText: props.descriptionText ? props.descriptionText : "",
      publicationId: props.publicationId,
      topic: null,
      labels: [],
      labelSuggestions: props.labelSuggestions || [],
      marketingCampaignSuggestion: props.marketingCampaignSuggestion || [],
      keyword: props.keyword ? props.keyword : "",
      switchedToEditorMode: false,
      keywordData: null,
      upvotedDetails: [],
      upvotedNames: "",
      topicCommentsCount: 0,
      publishDate: null,
      status: topicStatus.DRAFT,
      titleError: false,
      titleErrorMsg: " ",
      ideaTemplateId: props.ideaTemplateId || "",
      templateNameError: false,
      templateNameErrorMsg: " ",
      templateName: "",
      selectedIdeaTemplate: {},
      teammates: [],
      event: null,
      topicActivity: null,
      videoUrl: "",
      formattedDescription: "",
      loomInitialized: false,
      configureButton: null,
      publication: null,
      previewFiles: [],
      customFields: [],
      contentStages: [],
      showStageDeadlineChange: false,
      showIdeaSwitchConfirmationPrompt: false,
      isSaved: false,
      editMode: false,
      channelList: [],
      selectedChannel: { name: "Website", type: channelTypes.BLOG },
      currentStage: "",
      defaultChannel: { name: "Website", type: channelTypes.BLOG },
      uploading: false,
      uploadProgress: 0,
      uploadedFileName: "",
      areNewFilesAvailableForUpload: false,
      timerId: null,
      showCloseConfirmDialog: false,
      keywordError: "",
      generatingOutline: false
    };
    this.quillRef = null; // Quill API
    this.labelRef = React.createRef();
    this.uploadFileRef = React.createRef();
    this.reactQuillDescriptionRef = React.createRef();
    this.keywordInputRef = React.createRef();
    this.isUnmounted = false;
  }

  componentDidMount = () => {
    this.saveTopicData = _.debounce(this.saveTopic, DEBOUNCE_TIME);

    this.getLoomJWS();
    this.getChannelDetails();

    if (this.state.topicId) {
      // Editing and idea from Ideas page
      this.getTopic();
      return;
    }

    if (this.state.duplicateTopic) {
      this.setState(
        {
          title: this.state.duplicateTopic.title,

          description: this.state.duplicateTopic.description,

          selectedChannel: this.state.duplicateTopic.channel,

          labels: this.state.duplicateTopic.labels || [],
          campaign: this.state.duplicateTopic.marketingCampaign,

          keyword: this.state.duplicateTopic.keyword,

          customFields: this.state.duplicateTopic.projectFields,

          previewFiles: this.state.duplicateTopic.files,
          loading: false
        },
        () => {
          this.setCustomClassToQuill();
        }
      );
      return;
    }

    if (this.state.title) {
      this.setState({
        loading: false
      });
      return;
    }

    this.getCustomFields();

    this.setCustomClassToQuill();

    if (this.props.fromIdeaTemplates) {
      // Adding idea templates from Settings->Blog
      // Get the last saved draft when you're adding a new idea template
      this.getDraftIdeaTemplateDetails();
      return;
    }
    // Adding idea from Ideas page
    // Get the last saved draft
    this.getDraftTopicDetails();
  };

  getChannelDetails = () => {
    api.getChannelDetails(this.state.publicationId, (res: any) => {
      if (res.status === 200) {
        let defaultChannel = { name: "Website", type: channelTypes.BLOG };
        if (res.data.publication.externalSite.blogs.length) {
          defaultChannel = {
            name: res.data.publication.externalSite.blogs[0].name,
            type: res.data.publication.externalSite.blogs[0].destination,

            id: res.data.publication.externalSite.blogs[0].id,
            isBlog: true
          };
        }
        this.setState({
          channelList: getChannelList(res.data.publication, this.context),

          selectedChannel: this.state.selectedChannel || defaultChannel,
          defaultChannel
        });
      } else {
        if (this.props.isUserLoggedIn) {
          this.setState({
            loading: false,
            error: true
          });
        }
      }
    });
  };
  componentWillUnmount = () => {
    this.setState({
      editMode: false
    });

    this.clearGetOutlineGenerationInterval();
    this.isUnmounted = true;
  };

  getLoomJWS = () => {
    api.loomJWS(null, async (res: any) => {
      if (res.status === 200) {
        this.setLoomConfig(res.data);
      }
    });
  };
  setCustomClassToQuill = () => {
    // For Topic description input, we use different styles than the main editor..
    // If you change the styles in the main CSS, it will affect the main editor.
    // So we developed a new CSS style for Topic description input,
    // Replacing the default editor class name with the class name of the Topic description input.

    let { description } = this.state;
    if (typeof this.reactQuillDescriptionRef?.getEditor !== "function") return;
    this.quillRef = this.reactQuillDescriptionRef.getEditor();
    if (description) {
      this.reactQuillDescriptionRef
        .getEditor()
        .root.setAttribute("class", "ql-editor-comment");
    } else {
      this.reactQuillDescriptionRef
        .getEditor()
        .root.setAttribute("class", "ql-editor-comment ql-blank");
    }
  };

  onCustomFieldChange = (customFields: any) => {
    this.setState({ customFields }, this.saveTopicData);
  };

  getTopic = () => {
    let { topicId, defaultChannel } = this.state;
    api.getTopic(topicId, async (res: any) => {
      if (res.status === 200) {
        if (res.data?.loginRequired) {
          redirectToLoginPage();
          return;
        }
        let topic = res.data.topic;

        if (this.props.setTitleCallback) {
          this.props.setTitleCallback(topic.title);
        }
        let formattedDescription = await this.formateDescription(
          topic.description
        );
        this.setState(
          {
            topic: topic,
            topicId: topic._id,
            publicationId: topic.publicationId,
            publication: res.data.publication,
            title: topic.title,
            description: topic.description,
            descriptionText: topic.descriptionText,
            formattedDescription,
            labels: topic.labels || [],
            campaign: topic.marketingCampaign,
            keyword: topic.keyword,
            status: topic.status,
            keywordData: topic.keywordSearchResult?.result,
            loading: false,
            upvotedDetails: res.data.upvotedDetails,
            upvotedNames: res.data.upvotedNames,
            topicCommentsCount: res.data.topicCommentsCount,
            publishDate: topic.publishDate,
            teammates: res.data.teammates,
            event: res.data.event,
            topicActivity: res.data.topicActivity,
            previewFiles: topic.files,
            customFields: topic.projectFields,
            contentStages: res.data.contentStage,
            currentStage:
              res.data.contentStage?.find(
                (contentStage: any) =>
                  contentStage.internalContentStageName == topic.status
              )?.name || "",
            editMode: true,
            selectedIdeaTemplate: topic?.ideaTemplate,
            selectedChannel: topic.channel || defaultChannel
          },
          async () => {
            this.setLoomConfig(res.data.loomJWS);
            this.populateCustomFields(topic.projectFields);
          }
        );
      } else {
        this.setState({
          loading: false,
          error: true,
          errorMessage: res.data
        });
      }
    });
  };

  selectChannel = (event: any) => {
    let { channelList } = this.state;

    let selectedChannel = channelList.find(
      (channel: any) => `${channel.name}-${channel.type}` === event.target.value
    );
    this.setState({ selectedChannel }, () => {
      this.saveTopicData();
    });
  };

  getLabelsInput = (
    classes: any,
    labels: any,
    labelSuggestions: any,
    handleChipChange: any,
    addLabel: any
  ) => {
    return (
      <Grid item xs={12} className={classes.section}>
        <Typography variant="h300">Labels</Typography>

        <div className={classes.marginTop10}>
          <TagsInput
            value={labels}
            suggestions={labelSuggestions}
            handleChange={handleChipChange}
            placeholder="Add Label"
            size="medium"
            addTag={addLabel}
          />
        </div>
      </Grid>
    );
  };

  onCampaignChange = (
    event:
      | React.ChangeEventHandler<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement>,
    value: string
  ) => {
    value = value || event.target.value;
    if (value && value._id) {
      this.setState(
        {
          campaign: value
        },
        () => {
          this.saveTopicData();
        }
      );
      return;
    }
    if (value === "") {
      return;
    }
    api.addMarketingCampaign(value, this.state.publicationId, (res) => {
      let { marketingCampaignSuggestion } = this.state;
      if (res.status === 200) {
        marketingCampaignSuggestion.push(res.data.marketingCampaign);
        this.setState(
          {
            marketingCampaignSuggestion
          },
          () => {
            this.setCampaignValue(res.data.marketingCampaign);
          }
        );
      } else {
        notify.show(
          res.status === 400 ? res.data : "Oops, something went wrong.",
          "error"
        );
      }
    });
  };

  setCampaignValue(campaign: marketingCampaign) {
    this.setState(
      {
        campaign
      },
      () => {
        this.saveTopicData();
      }
    );
  }

  getCampaignInput = () => {
    let { classes } = this.props;
    let { campaign, marketingCampaignSuggestion } = this.state;
    return (
      <Grid item xs={12} className={classes.section}>
        <Typography variant="h300">Campaign</Typography>
        <div className={classes.marginTop10}>
          <Autocomplete
            freeSolo
            fullWidth
            value={campaign || ""}
            onChange={(
              event: React.ChangeEventHandler<HTMLInputElement>,
              newValue: string
            ) => this.onCampaignChange(event, newValue)}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
              this.onCampaignChange(event)
            }
            options={marketingCampaignSuggestion}
            getOptionLabel={(option: marketingCampaign) => option.name || ""}
            renderInput={(
              params: React.InputHTMLAttributes<HTMLInputElement>
            ) => <StyledInput {...params} variant="outlined" />}
          />
        </div>
      </Grid>
    );
  };

  getDraftTopicDetails = () => {
    api.getDraftTopicDetails(this.state.publicationId, (res: any) => {
      if (res.status === 200) {
        let { topic, loomJWS } = res.data;
        if (!topic) {
          this.setState({ loading: false }, () => {
            this.setLoomConfig(loomJWS);
          });
          return;
        }

        this.setState(
          {
            topic: topic,
            topicId: topic._id,
            isTopicChanged: Boolean(topic._id),
            publicationId: topic.publicationId,
            title: topic.title,
            previewFiles: topic.files,
            description: topic.description,
            descriptionText: topic.descriptionText,
            labels: topic.labels || [],
            keyword: topic.keyword,
            status: topic.status,
            selectedChannel: topic.channel,
            selectedIdeaTemplate: topic?.ideaTemplate,
            loading: false
          },
          () => {
            this.populateCustomFields(topic.projectFields);
            this.setLoomConfig(loomJWS);
          }
        );
      } else {
        this.setState({ loading: false });
      }
    });
  };

  getDraftIdeaTemplateDetails = () => {
    this.setState({
      ideaTemplateId: null
    });

    api.getDraftIdeaTemplateDetails(this.state.publicationId, (res: any) => {
      if (res.status === 200) {
        let { ideaTemplate, loomJWS } = res.data;
        if (!ideaTemplate) {
          this.setState({ loading: false });
          return;
        }
        this.setState(
          {
            templateName: ideaTemplate.name,
            ideaTemplateId: ideaTemplate._id,
            publicationId: ideaTemplate.publication,
            title: ideaTemplate.defaultValues.title,
            description: ideaTemplate.defaultValues.description,
            descriptionText: ideaTemplate.defaultValues.descriptionText,
            labels: ideaTemplate.defaultValues.labels || [],
            previewFiles: ideaTemplate.defaultValues.files || [],
            keyword: ideaTemplate.defaultValues.keyword,
            loading: false
          },
          () => {
            this.populateCustomFields(ideaTemplate.defaultValues.projectFields);
            this.setLoomConfig(loomJWS);
          }
        );
      } else {
        this.setState({ loading: false });
      }
    });
  };

  getCustomFields = () => {
    let { publicationId, editMode } = this.state;
    api.getProjectCustomFields(publicationId, (res: any) => {
      if (res.status === 200) {
        let customFields = res.data?.fields ? res.data.fields : [];
        customFields.map((customField: any) =>
          customField.type === customFieldDataType.DATE
            ? (customField.value = moment())
            : (customField.value = customField.defaultValue)
        );
        this.setState(
          {
            customFields
          },
          () => {
            if (!this.state.duplicateTopic) {
              this.populateDefaultTemplatesToIdeaFields();
              if (editMode) {
                this.populateCustomFields(this.state.topic?.projectFields);
              }
              return;
            }

            this.populateCustomFields(this.state.duplicateTopic.projectFields);
          }
        );
      } else {
        notify.show("Oops, something went wrong.", "error");
      }
    });
  };

  setLoomConfig = async (jws: any) => {
    const { supported } = await isSupported();
    if (!supported || !jws) {
      this.setState({
        configureButton: null,
        loomInitialized: false
      });
      return;
    }

    let { configureButton } = await setup({
      jws
    });

    this.setState({ configureButton }, () => {
      this.initializeLoom();
    });
  };

  initializeLoom = async () => {
    let { configureButton } = this.state;
    if (!configureButton) {
      this.setState({
        loomInitialized: false
      });
      return;
    }

    const button = document.getElementById(LOOM_BUTTON_ID);
    if (!button) {
      this.setState({
        loomInitialized: false
      });
      return;
    }

    const sdkButton = configureButton({ element: button });
    this.setState({
      loomInitialized: true
    });

    sdkButton.on("insert-click", async (video: any) => {
      let videoUrl = video.sharedUrl;

      if (this.props.fromIdeaTemplates) {
        api.saveIdeaTemplateVideoUrl(
          this.state.ideaTemplateId,
          videoUrl,
          async (res: any) => {
            if (res.status === 200) {
              try {
                let description = `${this.state.description}\n${videoUrl} `;

                //quill input do not update text from state. so programatically update the video url by using setContents
                let descriptionDelta =
                  this.quillRef?.clipboard.convert(description);
                this.quillRef?.setContents?.(descriptionDelta);

                let formattedDescription = await this.formateDescription(
                  description
                );
                this.setState({
                  description,
                  formattedDescription
                });
              } catch (error) {}
              notify.show(
                "Your Loom video was attached to your topic",
                "success"
              );
            } else {
              if (navigator?.clipboard?.writeText) {
                try {
                  navigator.clipboard.writeText(video.sharedUrl);
                  notify.show(
                    "We were unable to embed your video. We copied the link to your video to the clipboard.",
                    "error"
                  );
                  return;
                } catch (error) {}
              }
              notify.show("We were unable to embed your video.", "error");
            }
          }
        );
        return;
      }

      api.saveTopicVideoUrl(this.state.topicId, videoUrl, async (res: any) => {
        if (res.status === 200) {
          try {
            let description = `${this.state.description}\n${videoUrl} `;

            //quill input do not update text from state. so programatically update the video url by using setContents
            let descriptionDelta =
              this.quillRef?.clipboard.convert(description);
            this.quillRef?.setContents?.(descriptionDelta);

            let formattedDescription = await this.formateDescription(
              description
            );
            this.setState({
              description,
              formattedDescription
            });
          } catch (error) {}
          notify.show("Your Loom video was attached to your topic", "success");
        } else {
          if (navigator?.clipboard?.writeText) {
            try {
              navigator.clipboard.writeText(video.sharedUrl);
              notify.show(
                "We were unable to embed your video. We copied the link to your video to the clipboard.",
                "error"
              );
              return;
            } catch (error) {}
          }
          notify.show("We were unable to embed your video.", "error");
        }
      });
    });
  };

  componentDidUpdate(prevProps: any) {
    let { viewOnly } = this.props;

    if (prevProps.viewOnly !== viewOnly) {
      this.setState({ viewOnly: viewOnly }, () => this.initializeLoom());
    }

    this.setCustomClassToQuill();
  }

  handleChange = (event: any) => {
    let change = { [event.target.name]: event.target.value };
    if (event.target.name === "title") {
      change["titleError"] = false;

      change["titleErrorMsg"] = " ";
    }
    this.setState(change, this.saveTopicData);
  };

  onKeywordChange = (keyword: any) => {
    if (keyword && !KEYWORD_REGX.test(keyword)) {
      return;
    }
    this.setState({
      keyword,
      keywordError: ""
    });
  };

  getTopicActivity = (
    activity: any,
    activityIndex: any,
    activityCount: any
  ) => {
    let { classes } = this.props;
    let isConnectorReq = activityIndex !== activityCount - 1;
    let color = "success";
    let text = "";
    switch (activity.event) {
      case topicEvent.SUGGESTED: {
        color = "success";
        text = "suggested idea.";
        break;
      }
      case topicEvent.ASSIGNED: {
        color = "success";
        if (!activity.data) {
          text = "created project.";
        } else {
          text = this.generateActivityMessage(activity.data);
        }
        break;
      }
      case topicEvent.ASSIGNED_TO: {
        color = "success";
        if (activity?.client?._id !== activity.data.author._id) {
          text = `assigned the project to ${activity.data.author.name}.`;
        } else if (activity?.client?._id === activity.data.author._id) {
          text = "assigned the project to themself.";
        }
        break;
      }
      case topicEvent.APPROVER_CHANGE: {
        color = "success";
        let dataLength = activity.data.approvers?.length;
        if (dataLength > 0) {
          text = `changed approver${handleSingular(
            dataLength
          )} to ${getFormattedUserNames(
            activity.data.approvers,
            activity?.client?._id
          )}.`;
        } else if (dataLength === 0) {
          text = "removed all approvers.";
        }
        break;
      }
      case topicEvent.CONTRIBUTOR_CHANGE: {
        return <></>;
      }
      case topicEvent.CONTENT_MAP_DRAFT_SUBMITTED: {
        color = "success";
        if (!activity.data) {
          text = "submitted outline draft for approval.";
        } else {
          text = this.generateActivityMessage(activity.data);
        }
        break;
      }
      case topicEvent.CONTENT_MAP_DRAFT_CHANGE_REQUEST: {
        color = "warning";
        text = "requested changes in outline.";
        break;
      }
      case topicEvent.CONTENT_MAP_DRAFT_APPROVED: {
        color = "success";
        if (!activity.data) {
          text = "approved outline.";
        } else {
          text = this.generateActivityMessage(activity.data);
        }
        break;
      }
      case topicEvent.CONTENT_MAP_DEADLINE_MODIFIED: {
        color = "success";
        text =
          "changed outline review date to " +
          formatForTimeWithTimeZone(moment(activity.data.contentMapDeadline)) +
          ".";
        break;
      }
      case topicEvent.POST_DRAFT_SUBMITTED: {
        color = "success";
        if (!activity.data) {
          text = "submitted post draft for approval.";
        } else {
          text = this.generateActivityMessage(activity.data);
        }
        break;
      }
      case topicEvent.POST_DRAFT_CHANGE_REQUEST: {
        color = "warning";
        text = "requested changes in post draft.";
        break;
      }
      case topicEvent.POST_DRAFT_APPROVED: {
        color = "success";
        text = "approved post.";
        break;
      }
      case topicEvent.POST_DRAFT_DEADLINE_MODIFIED: {
        color = "success";
        text =
          "changed final draft review date to " +
          formatForTimeWithTimeZone(moment(activity.data.reviewDeadline)) +
          ".";
        break;
      }
      case topicEvent.SCHEDULED: {
        color = "success";
        text =
          "scheduled post on " +
          formatForTimeWithTimeZone(moment(activity.data.scheduledOn)) +
          ".";
        break;
      }
      case topicEvent.PUBLISH_DATE_MODIFIED: {
        color = "success";
        text =
          "changed publish date to " +
          formatForTimeWithTimeZone(moment(activity.data.publishDate)) +
          ".";
        break;
      }
      case topicEvent.PUBLISHED: {
        color = "success";
        text = "published post.";
        break;
      }
      case topicEvent.UPDATED: {
        color = "success";
        text = "updated published post.";
        break;
      }
      case topicEvent.ARCHIVED: {
        color = "grey";
        text = "archived project.";
        break;
      }
      case topicEvent.MOVE_BACK_TO_IDEAS: {
        color = "grey";
        text = "moved back to Ideas.";
        break;
      }
      case topicEvent.UNARCHIVED: {
        color = "success";
        text = "unarchived project.";
        break;
      }
      case topicEvent.STAGE_DEADLINE_MODIFIED: {
        color = "success";
        text = `updated ${activity.data.sourceName} deadline.`;
        break;
      }
      case topicEvent.STAGE_OWNER_MODIFIED: {
        color = "success";
        text = `changed ${activity.data.sourceName} owner.`;
        break;
      }
      default: {
        if (activity.data?.sourceName && activity.data?.destinationName) {
          color = "success";
          text = this.generateActivityMessage(activity.data);
        }
      }
    }
    return (
      <TimelineItem style={{ minHeight: "45px" }}>
        <TimelineOppositeContent className={classes.timeline}>
          {getTimelineValue(activity.updatedOn)}
        </TimelineOppositeContent>

        <TimelineSeparator>
          <TimelineDot
            variant="outlined"
            color={color}
            className={classes.timelineDot}
          />

          {isConnectorReq && <TimelineConnector style={{ width: "1px" }} />}
        </TimelineSeparator>

        <TimelineContent style={{ paddingTop: 0 }}>
          <Typography variant="bodys">
            <b>
              {activity?.client?.name || activity?.client?.email || ANONYMOUS}
            </b>{" "}
            {text}{" "}
          </Typography>
        </TimelineContent>
      </TimelineItem>
    );
  };

  generateActivityMessage = (data: any) => {
    if (data.sourceName && data.destinationName) {
      return `changed status from ${data.sourceName} to ${data.destinationName}.`;
    }

    if (
      (projectColumnName[data.source] || data.sourceName) &&
      (projectColumnName[data.destination] || data.destinationName)
    ) {
      return `changed status from ${
        projectColumnName[data.source] || data.sourceName
      } to ${projectColumnName[data.destination] || data.destinationName}.`;
    }

    return "changed status.";
  };

  handleChipChange = (event: any, newValues: any) => {
    let { labels } = this.state;
    if (labels?.length < newValues?.length) {
      // added a new chip
      if (labels?.length === 3) {
        notify.show("You can use up to 3 labels", "warning");
        return;
      }
    }

    // removed value
    this.setState({ labels: newValues }, () => {
      this.saveTopicData();
    });
  };

  addLabel = (event: any, value: any) => {
    let { labels } = this.state;

    let newValues = [...labels, value];
    this.handleChipChange(event, newValues);
  };

  changeProgress = (uploadProgress: any, uploadedFile: any) => {
    this.setState({ uploadProgress, uploadedFileName: uploadedFile?.name });
  };

  saveTopic = async (isSaveClicked = false) => {
    let {
      topicId,

      title,

      publicationId,

      labels,

      description,

      descriptionText,

      keyword,

      status,

      previewFiles,

      customFields,

      templateName,

      ideaTemplateId,

      selectedIdeaTemplate,

      selectedChannel,

      uploading,
      campaign
    } = this.state;

    if (uploading) {
      this.toggleShowCloseConfirmDialog();
      return;
    }

    let { fromIdeaTemplates, currentIdeaTemplateInEdit } = this.props;
    if (fromIdeaTemplates) {
      // Idea Templates section under Settings -> Blog
      if (!templateName) {
        this.setState({
          templateNameErrorMsg: "Name your template to save it",
          templateNameError: true
        });
        return;
      }
      if (
        customFields?.some(
          (customField: any) =>
            customField.type === customFieldDataType.NUMBER &&
            !isNaN(parseInt(customField.value)) &&
            !isNaN(parseInt(customField.minimumValue)) &&
            customField.value < customField.minimumValue
        )
      ) {
        return;
      }
      if (
        customFields?.some(
          (customField: any) =>
            customField.type === customFieldDataType.NUMBER &&
            !isNaN(parseInt(customField.value)) &&
            !isNaN(parseInt(customField.maximumValue)) &&
            customField.value > customField.maximumValue
        )
      ) {
        return;
      }

      let defaultValues = {
        title,
        description,
        descriptionText,
        labels,
        marketingCampaign: campaign,
        keyword,
        files: previewFiles,
        projectFields: customFields
      };

      let ideaTemplateType;
      if (isSaveClicked || currentIdeaTemplateInEdit) {
        ideaTemplateType = ideaTemplateStatus.ACTIVE;
      } else {
        ideaTemplateType = ideaTemplateStatus.DRAFT;
      }

      api.saveIdeaTemplate(
        publicationId,
        ideaTemplateId,
        templateName,
        ideaTemplateType,
        defaultValues,
        async (res: any) => {
          if (res.status === 200) {
            this.setState(
              {
                ideaTemplateId: res.data?._id
              },
              () => {
                if (isSaveClicked) {
                  this.props.closeCallBack();

                  this.props.refreshIdeaTemplates();
                }
              }
            );
          } else {
            notify.show("Failed to save. Try again.", "error");
          }
        }
      );
      return;
    }

    if (status === topicStatus.DRAFT && isSaveClicked === true) {
      if (!title) {
        this.setState({
          titleErrorMsg: "Title your idea to save it",
          titleError: true
        });
        return;
      }
      if (
        customFields?.some(
          (customField: any) =>
            customField.type === customFieldDataType.NUMBER &&
            !isNaN(parseInt(customField.value)) &&
            !isNaN(parseInt(customField.minimumValue)) &&
            customField.value < customField.minimumValue
        )
      ) {
        return;
      }
      if (
        customFields?.some(
          (customField: any) =>
            customField.type === customFieldDataType.NUMBER &&
            !isNaN(parseInt(customField.value)) &&
            !isNaN(parseInt(customField.maximumValue)) &&
            customField.value > customField.maximumValue
        )
      ) {
        return;
      }
      status = topicStatus.PROPOSED;
    }

    api.saveTopic(
      topicId,
      title,
      description,
      descriptionText,
      labels,
      campaign,
      publicationId,
      keyword,
      status,
      previewFiles,
      customFields,
      selectedChannel,
      selectedIdeaTemplate?._id,

      this.props.anonymousClientObject,
      async (res: any) => {
        if (res.status === 200) {
          if (isSaveClicked) {
            this.props.closeCallBack(null, false, true);
          }

          let { topic } = res.data;

          //reload description text after saving in Topic Dialog
          let formattedDescription = await this.formateDescription(
            topic.description
          );

          this.setState({
            topicId: topic._id,
            isSaved: true,
            formattedDescription
          });

          if (this.props.assignTopic && isSaveClicked) {
            this.props.assignTopic(topic);
          }

          this.props.refreshContentBrief?.();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Failed to save. Try again.",
            "error"
          );
        }
      }
    );
  };

  viewModules = {
    toolbar: false
  };

  editmodules = {
    toolbar: {
      container: [["bold", "italic", "link"]]
    }
  };

  drawRelatedKeywordSection = (relatedKeyword: any) => {
    if (!relatedKeyword?.length) {
      return;
    }

    let { classes } = this.props;
    relatedKeyword =
      relatedKeyword?.length > 2 ? relatedKeyword.slice(0, 3) : relatedKeyword;
    return (
      <div>
        <Grid item>
          <Typography variant="h300">Suggested keywords</Typography>
        </Grid>

        {relatedKeyword.map((data: any, index: any) => {
          return (
            <Grid
              container
              item
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className={classes.topSection}
              key={index}
            >
              <Grid item>
                <Typography variant="h200">{data.keyword}</Typography>
              </Grid>

              <Grid item className={classes.marginLeft10}>
                <Typography variant="bodym" className={classes.text}>
                  {formatNumber(data.searchVolume ? data.searchVolume : 0)}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </div>
    );
  };

  drawOrganicPostsSection = (organicPosts: any) => {
    if (!organicPosts || organicPosts.length === 0) {
      return;
    }

    let { classes } = this.props;
    organicPosts =
      organicPosts?.length > 2 ? organicPosts.slice(0, 3) : organicPosts;
    return (
      <div>
        <Typography variant="h300">Top posts</Typography>
        {organicPosts.map((data: any, index: any) => {
          return (
            <div key={index} className={classes.topSection}>
              <a
                href={data.url}
                rel="noreferrer"
                target="_blank"
                className={classes.link}
              >
                <Typography variant="bodym">
                  {data.title ? data.title : data.url}
                </Typography>
              </a>
              {data.domain && (
                <Typography variant="bodys" className={classes.domainAuthText}>
                  {" "}
                  from {data.domain}{" "}
                  {data.domainAuthority && `(DA ${data.domainAuthority})`}
                </Typography>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  getTopicDates = () => {
    let { classes } = this.props;

    let { topic } = this.state;

    // Getting content map deadline from content stages
    let contentMapDeadline =
      topic.contentStages?.find(
        (stage: any) =>
          stage.internalContentStageName === topicStatus.CONTENT_MAP_IN_REVIEW
      )?.deadline || null;

    // Getting review deadline from content stages
    let reviewDeadline =
      topic.contentStages?.find(
        (stage: any) => stage.internalContentStageName === topicStatus.IN_REVIEW
      )?.deadline || null;

    // Getting published deadline from content stages
    let publishDate =
      topic.contentStages?.find(
        (stage: any) => stage.internalContentStageName === topicStatus.PUBLISHED
      )?.deadline || null;

    return (
      <>
        <Typography variant="bodym" className={classes.dateSubtitle}>
          {`Posted on ${formatForDisplayDateTime(topic.createdOn)}`}
        </Typography>
        {topic.status === topicStatus.PUBLISHED && (
          <>
            {contentMapDeadline && (
              <div style={{ display: "flex", marginTop: 5 }}>
                <Typography variant="bodym" className={classes.dateSubtitle}>
                  {`Outline Deadline ${formatForDisplayDateTime(
                    contentMapDeadline
                  )}`}
                </Typography>
              </div>
            )}

            {reviewDeadline && (
              <div style={{ display: "flex", marginTop: 5 }}>
                <Typography variant="bodym" className={classes.dateSubtitle}>
                  {`Review Deadline ${formatForDisplayDateTime(
                    reviewDeadline
                  )}`}
                </Typography>
              </div>
            )}

            {publishDate && (
              <div style={{ display: "flex", marginTop: 5 }}>
                <Typography variant="bodym" className={classes.dateSubtitle}>
                  {`Target Publish Date ${formatForDisplayDateTime(
                    publishDate
                  )}`}
                </Typography>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  handleLoomVideoRecord = () => {
    if (this.state.topicId) {
      return;
    }
    this.saveTopicData();
  };

  formateDescription = async (description = "") => {
    try {
      description = await replaceLoomUrlWithEmbed(description, { width: 500 });
      description = linkifyHtml(decode(description), linkifyHtmlOptions);
    } catch (error) {}

    return description;
  };

  handleAttachment = (event: any) => {
    let { previewFiles } = this.state;
    for (let file of event.target.files) {
      previewFiles.push(file);
    }
    this.fileUpload();
  };

  fileUpload = async () => {
    let { previewFiles, uploading, timerId } = this.state;
    if (uploading && !timerId) {
      let timerId = setInterval(this.fileUpload, 10000);
      this.setState({
        areNewFilesAvailableForUpload: true,
        timerId
      });
      return;
    }
    if (!uploading && timerId) {
      clearInterval(timerId);
    }
    if (uploading) {
      return;
    }
    if (previewFiles) {
      this.setState({
        uploading: true
      });

      this.props.loadingCallBack?.();
    }
    let filesUrl = await uploadFiles(
      previewFiles,
      "comment-files",

      this.changeProgress
    );
    if (filesUrl) {
      this.props.loadingCallBack?.();
      this.setState(
        {
          uploading: false,
          previewFiles: filesUrl
        },
        this.saveTopicData
      );
    }
  };

  toggleShowCloseConfirmDialog = () => {
    let { showCloseConfirmDialog } = this.state;
    this.setState({ showCloseConfirmDialog: !showCloseConfirmDialog });
  };

  handleTemplateChange = (ideaTemplateId: any) => {
    let { ideaTemplates } = this.props;
    let { status, isSaved, isTopicChanged } = this.state;
    //store selected template in temporary state, because we want to update only if user want to overwrite it
    let selectedTemplateToSwitch = ideaTemplates?.find(
      (template: any) => template._id === ideaTemplateId
    );

    this.setState(
      {
        selectedTemplateToSwitch
      },
      () => {
        //if you are switching to a new template, ask confirmation before clear out the idea fields values

        if (isSaved || isTopicChanged || status !== topicStatus.DRAFT) {
          this.setState({
            showIdeaSwitchConfirmationPrompt: true
          });
          return;
        }

        this.populateTemplateValues();
      }
    );
  };

  populateCustomFields = (givenCustomFields: any) => {
    let { customFields, editMode, topic } = this.state;

    let { viewOnly, forContentBrief } = this.props;

    if (viewOnly || forContentBrief) {
      //in Projects page, do not remove empty customFields

      this.setState({ customFields: topic?.projectFields });
      return;
    }

    // Populating customFields with given custom fields
    customFields?.forEach((customField: any) => {
      let index = givenCustomFields?.findIndex(
        (givenCustomField: any) => customField._id === givenCustomField._id
      );
      if (index < 0) {
        return;
      }
      if (editMode && customField.value) {
        // Editing Idea from Ideas Page
        // If there is a value in the custom field dont change it
        return;
      }
      customField.value = givenCustomFields[index].value;
    });

    this.setState({ customFields });
  };

  populateDefaultTemplatesToIdeaFields = () => {
    let { editMode } = this.state;
    if (
      !editMode &&
      this.props.defaultIdeaTemplate &&
      !_.isEmpty(this.props.defaultIdeaTemplate)
    ) {
      // Opening as default template from Ideas Page
      this.setState(
        {
          selectedIdeaTemplate: this.props.defaultIdeaTemplate,

          ideaTemplateId: this.props.defaultIdeaTemplate._id,

          publicationId: this.props.defaultIdeaTemplate.publication,

          title: this.props.defaultIdeaTemplate.defaultValues?.title,
          description:
            this.props.defaultIdeaTemplate.defaultValues?.description,

          labels: this.props.defaultIdeaTemplate.defaultValues?.labels || [],
          previewFiles:
            this.props.defaultIdeaTemplate.defaultValues?.files || [],

          keyword: this.props.defaultIdeaTemplate.defaultValues?.keyword,
          loading: false
        },
        async () => {
          this.populateCustomFields(
            this.props.defaultIdeaTemplate.defaultValues?.projectFields
          );
        }
      );
    }

    if (
      this.props.currentIdeaTemplateInEdit &&
      !_.isEmpty(this.props.currentIdeaTemplateInEdit)
    ) {
      // Opening from Settings -> Blog
      this.setState(
        {
          templateName: this.props.currentIdeaTemplateInEdit.name,

          ideaTemplateId: this.props.currentIdeaTemplateInEdit._id,

          publicationId: this.props.currentIdeaTemplateInEdit.publication,

          title: this.props.currentIdeaTemplateInEdit.defaultValues?.title,
          description:
            this.props.currentIdeaTemplateInEdit.defaultValues?.description,
          labels:
            this.props.currentIdeaTemplateInEdit.defaultValues?.labels || [],
          previewFiles:
            this.props.currentIdeaTemplateInEdit.defaultValues?.files || [],

          keyword: this.props.currentIdeaTemplateInEdit.defaultValues?.keyword,
          loading: false
        },
        async () => {
          this.populateCustomFields(
            this.props.currentIdeaTemplateInEdit.defaultValues?.projectFields
          );
        }
      );
      return;
    }
  };

  populateTemplateValues = () => {
    let { selectedTemplateToSwitch, editMode } = this.state;
    let templateValues = selectedTemplateToSwitch.defaultValues;

    let { title, description, labels, keyword, files } = templateValues;

    if (editMode) {
      // In edit mode, we will be replacing only fields which are blank

      if (this.state.title) {
        title = this.state.title;
      }

      if (this.state.descriptionText?.trim()) {
        description = this.state.description;
      }

      if (this.state.labels?.length > 0) {
        labels = this.state.labels || [];
      }

      if (this.state.keyword) {
        keyword = this.state.keyword;
      }

      if (this.state.previewFiles?.length > 0) {
        files = this.state.previewFiles || [];
      }
    }

    this.setState(
      {
        isSaved: false,
        title,
        isTopicChanged: false,
        description,
        labels,
        keyword,
        previewFiles: files,
        selectedIdeaTemplate: selectedTemplateToSwitch
      },
      async () => {
        this.populateCustomFields(templateValues.projectFields);
      }
    );
  };

  removeAttachment = (index: any) => {
    let { previewFiles } = this.state;
    previewFiles.splice(index, 1);
    this.setState({ previewFiles }, this.saveTopicData);
  };

  editDescription = () => {
    let { editDescription } = this.state;
    this.setState({ editDescription: !editDescription }, () => {
      this.initializeLoom();
    });
  };

  CopyToClipboard = () => {
    let { publicationId, topicId } = this.state;
    let text = `${FRONTEND_URL}/${publicationId}/topics/${topicId}`;
    navigator.clipboard.writeText(text);
    notify.show("Copied to clipboard", "success");
    this.toggleShowTopic(null, false, false);
  };

  removeLabel = (tag: any, key: any) => {
    let { labels } = this.state;
    labels.splice(key, 1);
    this.setState({ labels }, this.updateTopicDetails);
  };

  getDescriptionModules = () => {
    return this.modules;
  };

  modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic"],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }]
    ],
    clipboard: {
      matchVisual: false //use this option to stop adding unwanted newline while editing or reloading. here reloading happens by rendering (link - https://github.com/quilljs/quill/issues/2905)
    }
  };

  onClickOutline = () => {
    let { keyword } = this.state;

    if (!keyword) {
      this.scrollToKeyword();

      return;
    }
    this.generateOutline();
  };

  scrollToKeyword = () => {
    this.setState(
      { keywordError: "We need a keyword to generate an outline" },
      () => {
        this.keywordInputRef?.current?.scrollIntoView?.({ behavior: "smooth" });
      }
    );
  };

  generateOutline = () => {
    let { keyword, topic, publicationId } = this.state;

    notify.show(
      "We'll scan the web and generate a first draft outline in 10 mins once you save this idea.",
      "success"
    );

    this.setState({ generatingOutline: true }, () => {
      api.initializeTopQuestionsExtraction(
        publicationId,
        topic?.post?._id,
        keyword.trim(),
        outlineTypes.COMPLETE_ARTICLE,
        topic?._id,
        false,
        "",
        "",
        [],
        [],
        [],
        (res: any) => {
          if (res.status === 200) {
            if (this.isUnmounted) return;

            this.createOutlineGenerationInterval();
          } else {
            this.setState({
              generatingOutline: false
            });
          }
        }
      );
    });
  };

  createOutlineGenerationInterval = () => {
    this.clearGetOutlineGenerationInterval();
    this.getOutlineGeneration();
    this.getOutlineGenerationInterval = setInterval(() => {
      this.getOutlineGeneration();
    }, 5000);

    setTimeout(() => {
      this.clearGetOutlineGenerationInterval();
    }, 15 * 60 * 1000); // After 15 minutes it should stop the API calls
  };

  clearGetOutlineGenerationInterval = () => {
    clearInterval(this.getOutlineGenerationInterval);
    this.getOutlineGenerationInterval = null;
  };

  getOutlineGeneration = () => {
    let { keyword, publicationId, topic } = this.state;

    api.getOutlineGeneration(
      keyword,
      outlineTypes.COMPLETE_ARTICLE,
      publicationId,
      topic?._id,
      "",
      "",
      [],
      [],
      [],
      (res: any) => {
        if (res.status === 200) {
          if (
            !res.data?.outline ||
            res.data.outline.status === analysisStatus.PENDING
          ) {
            return;
          }
          this.clearGetOutlineGenerationInterval();

          this.setState({
            generatingOutline: false
          });

          try {
            if (!this.quillRef) {
              return;
            }

            let markdownText = marked.parse(res.data.outline.body);
            markdownText += "<br /><br />";
            this.quillRef.clipboard?.dangerouslyPasteHTML(0, markdownText);
          } catch (error) {}
        }
        this.clearGetOutlineGenerationInterval();
      }
    );
  };

  closeSwitchTemplateDialog = (populateFields: boolean) => {
    this.setState({ showIdeaSwitchConfirmationPrompt: false }, () => {
      if (populateFields) {
        this.populateTemplateValues();
      }
    });
  };

  render() {
    let {
      classes,
      forContentBrief,
      fromIdeaTemplates,
      fromIdeasPage,
      anonymousClientObject,
      ideaTemplates
    } = this.props;
    let {
      loading,
      error,
      errorMessage,
      title,
      description,
      descriptionText,
      viewOnly,
      editDescription,
      labels,
      labelSuggestions,
      keyword,
      topic,
      keywordData,
      teammates,
      profilePic,
      titleError,
      titleErrorMsg,
      topicCommentsCount,
      topicActivity,
      formattedDescription,
      loomInitialized,
      previewFiles,
      customFields,
      contentStages,
      publicationId,
      templateNameError,
      templateNameErrorMsg,
      templateName,
      channelList,
      selectedChannel,
      selectedIdeaTemplate,
      currentStage,
      defaultChannel,
      uploading,
      uploadProgress,
      uploadedFileName,
      showCloseConfirmDialog,
      keywordError,
      generatingOutline,
      campaign,
      showIdeaSwitchConfirmationPrompt
    } = this.state;

    if (loading) {
      return (
        <div className={classes.loadingDiv}>
          <Loading />
        </div>
      );
    }

    if (error) {
      return <Page404 errorMessage={errorMessage} />;
    }
    if (viewOnly || forContentBrief) {
      return (
        <div>
          <Grid container direction="row">
            <Grid
              item
              xs={forContentBrief ? 8 : 12}
              container
              direction="row"
              style={{ height: "max-content" }}
            >
              {forContentBrief ? (
                <Grid
                  item
                  container
                  direction="row"
                  className={clsx(
                    classes.topicContainer,
                    classes.buttonsContainer
                  )}
                >
                  {topic && topic.contentMap && (
                    <UnstyledLink
                      to={`/${topic.publicationId}/topics/${topic.contentMap}/contentmap`}
                    >
                      <StyledButton
                        variant="textprimary"
                        style={{
                          marginLeft: 10,
                          marginRight: 10
                        }}
                      >
                        Go to outline
                      </StyledButton>
                    </UnstyledLink>
                  )}

                  {topic && topic.post && (
                    <UnstyledLink
                      to={`/${topic.publicationId}/posts/${topic.post._id}`}
                    >
                      <StyledButton
                        variant="textprimary"
                        style={{
                          marginRight: 10
                        }}
                      >
                        Go to draft
                      </StyledButton>
                    </UnstyledLink>
                  )}
                </Grid>
              ) : (
                <Grid
                  item
                  container
                  sm={12}
                  className={classes.suggestedAndVotesSection}
                >
                  <Grid item md={8}>
                    <div className={classes.suggested}>
                      <Avatar
                        sx={{
                          ...AvatarSize.sm
                        }}
                        src={topic.suggestedBy?.profilePic}
                        alt="logo"
                      />

                      <Typography variant="h200" className={classes.name}>
                        {topic.suggestedBy?.name ||
                          topic.suggestedBy?.email ||
                          topic.anonymousClient?.name ||
                          topic.anonymousClient?.email ||
                          ANONYMOUS}
                      </Typography>
                    </div>

                    <div className={classes.postedOnSection}>
                      {this.getTopicDates()}

                      <div className={classes.commentsCount}>
                        <Typography
                          variant="bodys"
                          className={classes.commentsHeading}
                        >
                          <MessageBubbleIcon style={{ marginRight: 8 }} />
                          {topicCommentsCount
                            ? `${topicCommentsCount} ${
                                topicCommentsCount === 1
                                  ? "Comment"
                                  : "Comments"
                              }`
                            : "0 Comments"}
                        </Typography>
                        {labels?.length > 0 && (
                          <div style={{ marginLeft: 20 }}>
                            {labels?.map((label: any, key: any) => {
                              return (
                                <StyledChip
                                  variant="gray"
                                  key={key}
                                  label={label}
                                />
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}

              <Grid item sm={keyword && keywordData ? 8 : 12}>
                {![channelTypes.TWITTER, channelTypes.LINKEDIN].includes(
                  selectedChannel?.type
                ) && (
                  <>
                    <div
                      className={clsx(classes.section, classes.topicContainer)}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography variant="h300">What to cover</Typography>
                        {/* Hide edit button if topic opened from Ideas page
                    Hide edit button if client dont have Edit_Topic page access
                    editDescription helps to toggle between edit and view of description */}
                        {!editDescription &&
                          !this.props.fromIdeasPage &&
                          !isAccessDisabled(
                            topic.publicationId,
                            this.context,
                            pageAccess.EDIT_TOPIC
                          ) && (
                            <IconButton onClick={this.editDescription}>
                              <EditIcon />
                            </IconButton>
                          )}
                        {editDescription && (
                          <IconButton
                            style={{ marginBottom: 10, marginRight: 5 }}
                            onClick={this.editDescription}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </Grid>

                      <Typography variant="bodym" className={classes.subtitle}>
                        Make sure to cover these points and follow these
                        instructions when writing your outline and post.
                      </Typography>
                      {!editDescription ? (
                        <>
                          <div
                            typography="bodym"
                            className="ql-editor-comment" // to support indent style
                            style={{
                              height: "auto",
                              marginTop: 10,
                              padding: 0
                            }}
                            dangerouslySetInnerHTML={{
                              __html: formattedDescription
                            }}
                          />

                          <div style={{ marginBottom: space.MEDIUM }}>
                            <ViewFileAttachment files={topic.files} />
                          </div>
                        </>
                      ) : (
                        <>
                          <StyledQuillInput
                            ref={(el) => (this.reactQuillDescriptionRef = el)}
                            value={description}
                            saveInputFunction={(value: any) => {
                              this.setState(
                                {
                                  description: value,
                                  descriptionText: this.quillRef?.getText
                                    ? this.quillRef?.getText?.()
                                    : descriptionText
                                },
                                this.saveTopicData
                              );
                            }}
                            placeholder={
                              "At a high level, there are 3 types of content:\n1.SEO - get organic traffic via Google\n2.thought leadership - share on social media, build brand and trust, go viral\n3.sales collateral - use in sales calls and drip campaigns\n\nDefine each, explain when to use which type of content.\n\nAdd short blurb in conclusion on how Letterdrop can help you produce all three."
                            }
                            outerDivStyle={{
                              minHeight: 180,
                              marginTop: 12
                            }}
                            modules={this.getDescriptionModules()}
                          />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: 8
                            }}
                          >
                            <Tooltip title="Record video">
                              <div>
                                <IconButton
                                  disabled={!loomInitialized}
                                  id={LOOM_BUTTON_ID}
                                  onClick={this.handleLoomVideoRecord}
                                  style={{
                                    background: "transparent"
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faVideo}
                                    style={{
                                      color: loomInitialized
                                        ? ""
                                        : designColors.grayScale[40]
                                    }}
                                  />
                                </IconButton>
                              </div>
                            </Tooltip>
                          </div>
                        </>
                      )}
                    </div>
                    {customFields?.length > 0 && (
                      <CustomProjectFieldsView
                        customFields={customFields}
                        onCustomFieldChange={this.onCustomFieldChange}
                        isEditAllowed={
                          !isAccessDisabled(
                            topic.publicationId,
                            this.context,
                            pageAccess.EDIT_TOPIC
                          )
                        }
                      />
                    )}
                  </>
                )}
                {viewOnly ? (
                  <Grid
                    container
                    sm={12}
                    style={{
                      padding: space.L,
                      borderTop: `1px solid ${designColors.grayScale[5]}`
                    }}
                  >
                    <Grid
                      item
                      sm={4}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center"
                      }}
                    >
                      <Typography variant="bodys">Channel</Typography>
                    </Grid>

                    <Grid item sm={8}>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Typography variant="bodym">
                            {" "}
                            {selectedChannel?.name || defaultChannel.name}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="bodys"
                            style={{
                              color: colors.fontSecondary
                            }}
                          >
                            {selectedChannel?.type || channelTypes.BLOG}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item className={classes.section}>
                    <Typography variant="h300">Channel</Typography>

                    <StyledSelect
                      name="selectedChannel"
                      className={classes.marginTop10}
                      value={`${selectedChannel?.name}-${selectedChannel?.type}`}
                      onChange={this.selectChannel}
                    >
                      {Object.values(channelList).map((channel, index) => {
                        return (
                          <StyledMenuItem
                            value={`${channel.name}-${channel.type}`}
                            key={channel.name}
                          >
                            <Grid container direction="row">
                              <Grid item xs={12}>
                                <Typography variant="bodym">
                                  {" "}
                                  {channel.name}
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <Typography
                                  variant="bodys"
                                  style={{
                                    color: colors.fontSecondary,
                                    textTransform: "capitalize"
                                  }}
                                >
                                  {channel.type}
                                </Typography>
                              </Grid>
                            </Grid>
                          </StyledMenuItem>
                        );
                      })}
                    </StyledSelect>
                  </Grid>
                )}
                {viewOnly && (
                  <Grid
                    container
                    sm={12}
                    style={{
                      paddingLeft: space.L,
                      paddingRight: space.L,
                      paddingTop: space.SMALL
                    }}
                  >
                    <Grid
                      item
                      sm={4}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center"
                      }}
                    >
                      <Typography variant="bodys">Campaign</Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Grid item xs={12}>
                        <Typography variant="bodym">
                          {" "}
                          {campaign?.name || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {topicActivity?.length > 0 &&
                  topic.status !== topicStatus.PROPOSED && (
                    <Grid
                      item
                      className={clsx(
                        classes.topicContainer,
                        classes.activitySection
                      )}
                    >
                      <Typography variant="h300">Activity</Typography>

                      <Timeline style={{ paddingLeft: 0 }}>
                        {topicActivity.map((key: any, index: any) =>
                          this.getTopicActivity(
                            key,
                            index,
                            topicActivity?.length
                          )
                        )}
                      </Timeline>
                    </Grid>
                  )}

                <Grid
                  item
                  className={clsx(
                    classes.topicContainer,
                    classes.commentsSection
                  )}
                >
                  <Typography variant="h300" className={classes.discussionText}>
                    Discussion
                  </Typography>

                  <TopicComments
                    onCommentCountChange={(topicCommentsCount: any) =>
                      this.setState({ topicCommentsCount })
                    }
                    publicationId={topic.publicationId}
                    topicId={topic._id}
                    profilePic={profilePic}
                    anonymousClientObject={anonymousClientObject}
                  />
                </Grid>
              </Grid>

              {keyword && keywordData && (
                <Grid item sm={4} className={classes.keywordSEODetails}>
                  <Typography variant="h300">Keyword</Typography>

                  <Typography variant="h200" className={classes.keyWord}>
                    {keyword}
                  </Typography>

                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.lineItem}
                  >
                    <Grid item>
                      <Typography variant="h200">
                        {formatNumber(
                          keywordData.searchVolume
                            ? keywordData.searchVolume
                            : 0
                        )}
                      </Typography>
                    </Grid>

                    <Grid item className={classes.monthlySearchLabel}>
                      <Typography variant="bodym" className={classes.text}>
                        searches/mo
                      </Typography>
                    </Grid>
                  </Grid>
                  {keywordData.keywordDifficulty && (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      className={classes.lineItem}
                    >
                      <Grid item className={classes.statMinWidth}>
                        <Typography variant="h200">
                          {formatNumber(keywordData.keywordDifficulty)}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="bodym" className={classes.text}>
                          SEO difficulty
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.lineItem}
                  >
                    <Grid item className={classes.statMinWidth}>
                      <Typography variant="h200">
                        {Math.round(keywordData.competition * 100)}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="bodym" className={classes.text}>
                        paid difficulty
                      </Typography>
                    </Grid>
                  </Grid>
                  {keywordData?.cpc > 0 && (
                    <Grid
                      container
                      item
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      className={classes.lineItem}
                    >
                      <Grid item className={classes.statMinWidth}>
                        <Typography variant="h200">
                          ${keywordData.cpc.toFixed(2)}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="bodym" className={classes.text}>
                          to buy click
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  <div className={classes.section} style={{ marginTop: 30 }}>
                    {this.drawRelatedKeywordSection(
                      keywordData.relatedKeywordsData
                    )}
                  </div>

                  <div className={classes.section} style={{ marginTop: 30 }}>
                    {this.drawOrganicPostsSection(keywordData.organicPosts)}
                  </div>
                </Grid>
              )}
            </Grid>
            {forContentBrief &&
              topic.status !== topicStatus.PROPOSED &&
              topic.post && (
                <TopicAssigneesAndDeadlines
                  topic={topic}
                  teammates={teammates}
                  labels={labels}
                  labelSuggestions={labelSuggestions}
                  contentStagesConfig={contentStages}
                  currentStage={currentStage}
                />
              )}
          </Grid>
        </div>
      );
    }

    return (
      <div className={classes.topicContainer}>
        {uploading && uploadProgress >= 0 && (
          <Box
            sx={{
              width: "87.25%",
              alignItems: "center",
              position: "absolute",
              top: 30
            }}
          >
            <Box sm={12} sx={{ marginBottom: `${space.XXS}` }}>
              <Typography variant="body2" color="text.secondary">
                Uploading {uploadedFileName}. {uploadProgress}% complete.
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          </Box>
        )}

        <Grid container direction="row">
          <Grid item sm={12}>
            <Grid item container direction="row">
              <Grid item xs={6}>
                <Typography variant="h400" className={classes.cardTitle}>
                  New Idea {fromIdeaTemplates && "Template"}
                </Typography>
              </Grid>
              {topic && topic.status !== topicStatus.DRAFT && (
                <>
                  <Grid
                    item
                    container
                    direction="column"
                    xs={6}
                    justifyContent="flex-end"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end"
                      }}
                    >
                      <Avatar
                        sx={{
                          ...AvatarSize.xs
                        }}
                        src={topic.suggestedBy?.profilePic}
                        alt="logo"
                      />

                      <Typography variant="h200" style={{ paddingLeft: 10 }}>
                        {topic.suggestedBy?.name ||
                          topic.suggestedBy?.email ||
                          topic?.anonymousClient?.name ||
                          topic?.anonymousClient?.email ||
                          ANONYMOUS}
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        justifyContent: "flex-end"
                      }}
                    >
                      {this.getTopicDates()}
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid item sm={12}>
            {fromIdeaTemplates && (
              <Grid item className={classes.section}>
                <Typography variant="h300">Template Name</Typography>

                <StyledInput
                  error={templateNameError}
                  helperText={templateNameErrorMsg}
                  size="medium"
                  className={classes.titleInput}
                  placeholder="Case Study Template"
                  name="templateName"
                  value={templateName}
                  onChange={this.handleChange}
                  readOnly={viewOnly}
                />
              </Grid>
            )}
            {fromIdeasPage && ideaTemplates?.length > 0 && (
              <Grid item className={classes.section}>
                <Typography>Pick one of the templates</Typography>

                <Typography variant="bodym" className={classes.subtitle}>
                  Automatically fill fields from a template
                </Typography>

                <StyledSelect
                  style={{ marginTop: space.SMALL, marginBottom: space.SMALL }}
                  value={selectedIdeaTemplate?._id || ""}
                  fullWidth
                  size="large"
                  className={classes.input}
                  onChange={(event: any) => {
                    this.handleTemplateChange(event.target.value);
                  }}
                >
                  {ideaTemplates.map((ideaTemplate: any, index: number) => {
                    if (!ideaTemplate) {
                      return "";
                    }
                    return (
                      <StyledMenuItem
                        key={ideaTemplate._id}
                        value={ideaTemplate._id}
                      >
                        {ideaTemplate.name}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </Grid>
            )}

            <Grid item className={classes.section}>
              <Typography variant="h300">Title</Typography>

              <StyledInput
                autoFocus
                error={titleError}
                helperText={titleErrorMsg}
                size="medium"
                className={classes.titleInput}
                placeholder="Case Study: Acme Increases Revenue 30% with PiedPiper"
                name="title"
                value={title}
                onChange={this.handleChange}
                readOnly={viewOnly}
              />
            </Grid>
            {!fromIdeaTemplates && (
              <Grid item className={classes.section}>
                <Typography variant="h300">Channel</Typography>

                <StyledSelect
                  name="selectedChannel"
                  className={classes.marginTop10}
                  value={`${selectedChannel?.name}-${selectedChannel?.type}`}
                  onChange={this.selectChannel}
                  style={{ height: 60 }}
                >
                  {Object.values(channelList).map((channel, index) => {
                    return (
                      <StyledMenuItem
                        value={`${channel.name}-${channel.type}`}
                        key={channel.name}
                      >
                        <Grid container direction="row">
                          <Grid item xs={12}>
                            <Typography variant="bodym">
                              {" "}
                              {channel.name}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography
                              variant="bodys"
                              style={{
                                color: colors.fontSecondary,
                                textTransform: "capitalize"
                              }}
                            >
                              {channel.type}
                            </Typography>
                          </Grid>
                        </Grid>
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </Grid>
            )}
          </Grid>
          {![channelTypes.TWITTER, channelTypes.LINKEDIN].includes(
            selectedChannel?.type
          ) && (
            <>
              <Grid item className={classes.section}>
                <Typography variant="h300">Brief</Typography>

                <Typography variant="bodym" className={classes.subtitle}>
                  Add details on what this piece of content should cover. You
                  can include links, images, files, and Loom videos too.
                </Typography>

                <StyledQuillInput
                  ref={(el) => (this.reactQuillDescriptionRef = el)}
                  value={description}
                  saveInputFunction={(value: any) => {
                    this.setState(
                      {
                        description: value,
                        descriptionText: this.quillRef?.getText
                          ? this.quillRef?.getText?.()
                          : descriptionText
                      },
                      this.saveTopicData
                    );
                  }}
                  placeholder={
                    "At a high level, there are 3 types of content:\n1.SEO - get organic traffic via Google\n2.thought leadership - share on social media, build brand and trust, go viral\n3.sales collateral - use in sales calls and drip campaigns\n\nDefine each, explain when to use which type of content.\n\nAdd short blurb in conclusion on how Letterdrop can help you produce all three."
                  }
                  outerDivStyle={{
                    minHeight: 180,
                    marginTop: 12
                  }}
                  modules={this.getDescriptionModules()}
                />
              </Grid>

              <Grid xs={12} item className={classes.section}>
                <FilePreview
                  previewFiles={previewFiles}
                  removeAttachment={this.removeAttachment}
                  fromTopicPage={true}
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: 8
                  }}
                >
                  <input
                    id="add-attachment-button"
                    style={{ display: "none" }}
                    type="file"
                    onChange={this.handleAttachment}
                    ref={this.uploadFileRef}
                    accept={acceptedFileUploadTypes}
                    multiple
                  />

                  <IconButton
                    onClick={() => this.uploadFileRef.current.click()}
                    size="large"
                  >
                    <AddIcon />
                  </IconButton>

                  <Tooltip title="Record video">
                    <div>
                      <IconButton
                        disabled={!loomInitialized}
                        id={LOOM_BUTTON_ID}
                        onClick={this.handleLoomVideoRecord}
                      >
                        <FontAwesomeIcon
                          icon={faVideo}
                          style={{
                            color: loomInitialized
                              ? ""
                              : designColors.grayScale[40]
                          }}
                        />
                      </IconButton>
                    </div>
                  </Tooltip>
                  {selectedChannel?.isBlog && (
                    <StyledButton
                      variant="textprimary"
                      onClick={this.onClickOutline}
                      style={{ marginLeft: space.SMALL }}
                      disabled={generatingOutline}
                    >
                      Create outline
                    </StyledButton>
                  )}
                </div>
              </Grid>

              {customFields?.length > 0 && (
                <Grid item xs={12} className={classes.section}>
                  <ProjectCustomFieldInputs
                    publicationId={publicationId}
                    customFields={customFields}
                    onCustomFieldChange={this.onCustomFieldChange}
                  />
                </Grid>
              )}
              {this.getLabelsInput(
                classes,
                labels,
                labelSuggestions,
                this.handleChipChange,
                this.addLabel
              )}
              {this.getCampaignInput()}
            </>
          )}
          {selectedChannel?.isBlog && (
            <Grid
              ref={this.keywordInputRef}
              item
              xs={12}
              className={classes.section}
            >
              <Typography variant="h300">Keyword</Typography>

              <Typography variant="bodym" className={classes.subtitle}>
                If this page needs to rank for a keyword, add it here to
                automatically pull in search data.
              </Typography>

              <KeywordAutocomplete
                publicationId={publicationId}
                size="medium"
                className={classes.titleInput}
                value={keyword}
                renderInput={(params: any) => {
                  return (
                    <StyledInput
                      {...params}
                      variant="outlined"
                      placeholder="Enter target keyword or search term…"
                    />
                  );
                }}
                onChange={(event: any, value: any) =>
                  this.onKeywordChange(value?.keyword || value || "")
                }
                onInputChange={this.onKeywordChange}
              />
              {keywordError && (
                <FormHelperText style={{ color: colors.helperText }}>
                  {keywordError}
                </FormHelperText>
              )}
            </Grid>
          )}
          {[channelTypes.TWITTER, channelTypes.LINKEDIN].includes(
            selectedChannel?.type
          ) &&
            this.getLabelsInput(
              classes,
              labels,
              labelSuggestions,
              this.handleChipChange,
              this.addLabel
            )}
        </Grid>
        {showCloseConfirmDialog && (
          <StyledDialog
            open={showCloseConfirmDialog}
            title="Are you sure?"
            body="We need to finish uploading your file first. Do you want to cancel uploading your file."
            successButtonName="Ok"
            cancelButtonName="Cancel"
            successCallback={() => {
              this.props.closeCallBack(null, false, false);
            }}
            cancelCallback={this.toggleShowCloseConfirmDialog}
          />
        )}

        {showIdeaSwitchConfirmationPrompt && (
          <StyledDialog
            open={showIdeaSwitchConfirmationPrompt}
            title="Are you sure?"
            body="We'll replace your idea with the values in the template. You can't undo this action."
            successButtonName="Yes, I'm sure "
            cancelButtonName="Cancel"
            successCallback={() => {
              this.closeSwitchTemplateDialog(true);
            }}
            cancelCallback={() => {
              this.closeSwitchTemplateDialog(false);
            }}
          />
        )}
      </div>
    );
  }
}

export function getChannelList(publication: any, context: ContextType) {
  let channelList = [];
  if (!isModuleDisabled(context, publication?._id, modulesAccess.SEO)) {
    if (publication.externalSite.blogs.length === 0) {
      channelList.push({
        name: "Website",
        type: channelTypes.BLOG,
        isBlog: true
      });
    } else {
      publication.externalSite.blogs.map((blogDestination: any) => {
        channelList.push({
          name: blogDestination.name,
          type: blogDestination.destination,
          id: blogDestination.id,
          isBlog: true
        });
      });
    }
  }
  if (publication?.emailDomain?.status === "success") {
    channelList.push({
      name: "Email Newsletter",
      type: channelTypes.EMAIL
    });
  }

  if (!isModuleDisabled(context, publication?._id, modulesAccess.SOCIAL)) {
    if (publication?.twitter?.length) {
      let twitterAccounts = publication.twitter;
      for (let twitterAccount of twitterAccounts) {
        if (twitterAccount.connected === true) {
          channelList.push({
            name: twitterAccount?.profile?.fullName,
            type: channelTypes.TWITTER,
            accountId: twitterAccount.userId,
            twitterUserName: twitterAccount?.profile?.fullName,
            twitterProfilePic: twitterAccount?.profile?.picture,
            twitterScreenName: twitterAccount.screenName,
            identifier: twitterAccount.userId
          });
        }
      }
    }
    if (publication?.linkedin?.accounts?.length) {
      let linkedinAccounts = publication.linkedin.accounts;
      for (let linkedinAccount of linkedinAccounts) {
        if (linkedinAccount.accessToken) {
          channelList.push({
            name: linkedinAccount.firstName + " " + linkedinAccount.lastName,
            type: channelTypes.LINKEDIN,
            accountId: linkedinAccount.id,
            accountPicture: linkedinAccount.profilePicture,
            identifier: linkedinAccount.id
          });
          if (linkedinAccount.organizations?.length) {
            for (let linkedinOrg of linkedinAccount.organizations) {
              if (linkedinOrg.isActive) {
                channelList.push({
                  name: linkedinOrg.name,
                  type: channelTypes.LINKEDIN,
                  accountId: linkedinOrg.id,
                  accountPicture: linkedinOrg.picture,
                  identifier: linkedinOrg.id
                });
              }
            }
          }
        }
      }
    }
  }
  return channelList;
}

export default withStyles(styles)(Topic);
