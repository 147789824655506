// @ts-nocheck
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Avatar,
  Box,
  FormHelperText,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { arrayMove, List } from "react-movable";

import { withRouter } from "react-router";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import API from "../Services/Api";
import { notify } from "./CustomNotifications";

import Loading from "./Loading";
import styles from "./styles/ContentStagesStyle";
import { generateStatus } from "../Utils/UserUtils";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import {
  assigneeSelectionTypes,
  ASSIGNEE_NOT_REQUIRED_STAGES,
  contentStageSectionNames,
  teamRoles,
  topicStatus
} from "../Utils/Types";

import StyledChip from "../design/components/StyledChip";
import StyledUserChip from "../design/components/StyledUserChip";
import colors from "../Themes/Colors";

import StyledCheckbox from "../design/components/StyledCheckbox";

const _ = require("lodash");

const api = API.create();

class ContentStages extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      publicationId: props.publicationId,
      contentStages: [],
      showAddEditStages: false,
      sectionIndex: null, // Index of top level(Outlining, Drafting, and Completed ) sections, used to insert new stage
      stageIndex: null, // Index of selected stages([Outline, Outline Review], [Draft, Final Review], [Approved, Published]), used in the editing stage
      teammates: [],
      assigneeSelectionType: assigneeSelectionTypes.LET_USER_CHOOSE,
      sameAssigneeAs: "",
      assignees: [],
      isDefaultStage: false,
      stage: null,
      showConfirmDialog: false,
      required: true,
      stageNameError: "",
      stagePickerError: ""
    };
  }

  componentDidMount = () => {
    this.getContentStages();
  };

  // Fetching content stages
  getContentStages = () => {
    let { publicationId } = this.state;
    api.getContentStages(publicationId, (res: any) => {
      if (res.status === 200) {
        this.setState({
          loading: false,
          contentStages: res.data.contentStages,
          teammates: res.data.teammates
        });
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Oops. We had some trouble loading your data. Try again.",
          "error"
        );
        this.setState({
          loading: false
        });
      }
    });
  };

  //Save new content stages
  saveContentStages = () => {
    let { publicationId, contentStages } = this.state;
    api.saveContentStages(publicationId, contentStages, (res: any) => {
      if (res.status === 200) {
        this.setState({
          contentStages: res.data.contentStages,
          showAddEditStages: false,
          sectionIndex: null,
          stageIndex: null,
          stage: null
        });
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Oops. We couldn't save your change. Try again.",
          "error"
        );
      }
    });
  };

  // Handling stages rearranging
  // Saving content stages when user moves a stage up/down
  stagesRearranged = (rearrangedStages: any, sectionIndex: any) => {
    let { contentStages } = this.state;

    let isStageCanBeRearrange = true;
    let contentStage = contentStages[sectionIndex];

    // Checking the default stages order. If the sequence of the default stages is changed, return error.
    if (contentStage.section === contentStageSectionNames.OUTLINING) {
      let outlineIndex = rearrangedStages.findIndex(
        (stage: any) =>
          stage.internalContentStageName === topicStatus.CONTENT_MAP_DRAFT
      );

      let outlineReviewIndex = rearrangedStages.findIndex(
        (stage: any) =>
          stage.internalContentStageName === topicStatus.CONTENT_MAP_IN_REVIEW
      );

      if (outlineIndex > outlineReviewIndex) {
        isStageCanBeRearrange = false;
      }
    }

    if (contentStage.section === contentStageSectionNames.DRAFTING) {
      let draftIndex = rearrangedStages.findIndex(
        (stage: any) =>
          stage.internalContentStageName === topicStatus.FIRST_DRAFT
      );

      let finalReviewIndex = rearrangedStages.findIndex(
        (stage: any) => stage.internalContentStageName === topicStatus.IN_REVIEW
      );

      if (draftIndex > finalReviewIndex) {
        isStageCanBeRearrange = false;
      }
    }

    if (contentStage.section === contentStageSectionNames.COMPLETED) {
      let approvedIndex = rearrangedStages.findIndex(
        (stage: any) => stage.internalContentStageName === topicStatus.SCHEDULED
      );

      let publishedIndex = rearrangedStages.findIndex(
        (stage: any) => stage.internalContentStageName === topicStatus.PUBLISHED
      );

      if (
        approvedIndex > publishedIndex ||
        approvedIndex !== publishedIndex - 1 ||
        publishedIndex !== rearrangedStages.length - 1
      ) {
        isStageCanBeRearrange = false;
      }
    }

    if (!isStageCanBeRearrange) {
      notify.show("This stage can't be rearrange.", "error");
      return;
    }

    contentStages[sectionIndex].stages = rearrangedStages;
    this.setState({ contentStages }, this.saveContentStages);
  };

  // Showing add/edit stage dialog
  showAddEditStagesDialog = (sectionIndex: any, stageIndex = null) => {
    let { contentStages, teammates } = this.state;

    // Set stage name if the action is edit
    let stage = "";
    let assigneeSelectionType = assigneeSelectionTypes.LET_USER_CHOOSE;
    let sameAssigneeAs = "";
    let assignees = [];
    let required = true;

    // If stage index exist means, user updating existing stage
    if (stageIndex || stageIndex === 0) {
      // Finding selected stage, contentStages contains section name and stages
      // { section: "Outlining", stages: []}
      let selectedStage = contentStages[sectionIndex].stages[stageIndex];

      stage = selectedStage.name; // Stage name
      assigneeSelectionType = selectedStage.assigneeSelectionType; // Assignee selection type
      sameAssigneeAs = selectedStage.sameAssigneeAs; // Same as assignee stage name
      required = selectedStage.required;

      // Assignees are client reference
      // Our all autocomplete components uses Teammates as options
      // Finding and creating assignees from teammates.
      assignees = teammates.filter((teammate: any) => {
        if (!selectedStage.defaultAssignees?.length) {
          return false;
        }
        return selectedStage.defaultAssignees.some(
          (defaultAssignee: any) => defaultAssignee._id === teammate.client?._id
        );
      });
    }

    this.setState({
      showAddEditStages: true,
      sectionIndex,
      stageIndex,
      stage,
      assigneeSelectionType,
      sameAssigneeAs,
      assignees,
      stageNameError: "",
      stagePickerError: "",
      required
    });
  };

  toggleShowStageDeleteConfirmation = (stage = null) => {
    this.setState((prevState, props) => ({
      stage,

      showConfirmDialog: !prevState.showConfirmDialog
    }));
  };

  // Deleting a stage
  deleteStage = () => {
    let { publicationId, stage } = this.state;

    // Closing delete confirmation dialog
    this.setState({
      stage: null,
      showConfirmDialog: false
    });

    api.deleteContentStage(publicationId, stage, (res: any) => {
      if (res.status === 200) {
        this.setState({
          contentStages: res.data.contentStages
        });
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "We were unable to delete your changes",
          "error"
        );
      }
    });
  };

  // Closing dialog
  closeDialog = () => {
    this.setState({
      showAddEditStages: false,
      stageIndex: null,
      sectionIndex: null
    });
  };

  // Handling stage name change
  stageChange = (event: any) => {
    this.setState({
      stage: event.target.value
    });
  };

  toggleRequiredOption = (event: any) => {
    this.setState((prevState, props) => ({
      required: !prevState.required
    }));
  };

  // Handling add/update action
  successCallback = () => {
    let {
      contentStages,

      sectionIndex,

      stageIndex,

      stage,

      assigneeSelectionType,

      sameAssigneeAs,

      assignees,

      publicationId,

      required
    } = this.state;

    let stageNameError = "";
    let stagePickerError = "";

    stage = stage?.trim() || "";
    // Validating stage name
    if (!stage) {
      stageNameError = "Name your new content workflow stage.";
    }

    let oldStatus = "";
    let newStatus = generateStatus(stage);
    if (
      // Checking if the stage name is already exist or not
      contentStages.some((contentStage: any, eachSectionIndex: any) => {
        return contentStage.stages.some((eachStage: any, index: any) => {
          // If its is same index returning false
          if (sectionIndex === eachSectionIndex && stageIndex === index) {
            return false;
          }
          return (
            eachStage.name.toLowerCase() === stage.toLowerCase() ||
            eachStage.internalContentStageName === newStatus
          );
        });
      })
    ) {
      stageNameError =
        "There's already a stage with this name. Try something different.";
    }

    // Validating same as assignee selection
    if (
      assigneeSelectionType === assigneeSelectionTypes.SAME_AS_STAGE &&
      !sameAssigneeAs
    ) {
      stagePickerError = "Choose a stage to pick assignees from.";
    }

    // If any errors return
    if (stageNameError || stagePickerError) {
      this.setState({
        stageNameError,
        stagePickerError
      });
      return;
    }

    // Otherwise create/update stage
    // Assignee contains team document reference, changing to client document reference
    assignees = assignees.map((assignee: any) => assignee.client._id);

    let contentStagesClone = _.cloneDeep(contentStages);
    // If stage index exist, update stage
    if (stageIndex || stageIndex === 0) {
      let isDefaultStage =
        contentStagesClone[sectionIndex].stages[stageIndex].isDefaultStage;
      oldStatus =
        contentStagesClone[sectionIndex].stages[stageIndex]
          .internalContentStageName;

      //If its default stage, stage name will be same
      if (isDefaultStage) {
        newStatus = oldStatus;
      }
      contentStagesClone[sectionIndex].stages[stageIndex] = {
        name: stage,
        internalContentStageName: newStatus,
        isDefaultStage,
        assigneeSelectionType: assigneeSelectionType,
        sameAssigneeAs,
        defaultAssignees: assignees,
        required
      };
    } else {
      // Otherwise add new stage
      let newStage = {
        name: stage,
        internalContentStageName: newStatus,
        isDefaultStage: false,
        assigneeSelectionType: assigneeSelectionType,
        sameAssigneeAs,
        defaultAssignees: assignees,
        required
      };
      if (
        contentStagesClone[sectionIndex].section ===
        contentStageSectionNames.COMPLETED
      ) {
        contentStagesClone[sectionIndex].stages.splice(0, 0, newStage);
      } else {
        contentStagesClone[sectionIndex].stages.push(newStage);
      }
    }

    api.updateContentStages(
      publicationId,
      contentStagesClone,
      oldStatus,
      newStatus,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            contentStages: res.data.contentStages,
            showAddEditStages: false,
            sectionIndex: null,
            stageIndex: null,
            stage: null,
            stageNameError: "",
            stagePickerError: ""
          });
        } else {
          notify.show(
            res.status === 400
              ? res.data
              : "Oops. We couldn't save your change. Try again.",
            "error"
          );
          this.setState({
            stageNameError: "",
            stagePickerError: ""
          });
        }
      }
    );
  };

  onInputChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  ownerChipChange = (value: any) => {
    this.setState({
      assignees: value
    });
  };

  render() {
    let { classes } = this.props;
    let {
      loading,

      contentStages,

      showAddEditStages,

      sectionIndex,

      stageIndex,

      stage,

      teammates,

      assigneeSelectionType,

      sameAssigneeAs,

      required,

      assignees,

      stageNameError,

      stagePickerError,

      showConfirmDialog
    } = this.state;
    if (loading) {
      return <Loading marginTop={20} />;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="bodyl" paragraph className={classes.title}>
            CONTENT STAGES
          </Typography>

          <Typography variant="bodym" paragraph className={classes.subtitle}>
            Stages define the type and order of statuses that your projects go
            through from start to finish. You can customize and re-order the
            stages.
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          {contentStages.map((contentStage: any, sectionIndex: any) => {
            let { stages } = contentStage;
            return (
              <div style={{ marginBottom: 32 }}>
                <div className={classes.sectionHeader}>
                  <Typography variant="bodyl">
                    {contentStage.section}
                  </Typography>

                  <Tooltip title="Edit field">
                    <IconButton
                      onClick={() => {
                        this.showAddEditStagesDialog(sectionIndex);
                      }}
                      size="large"
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </div>

                <List
                  values={stages}
                  onChange={({ oldIndex, newIndex }) =>
                    this.stagesRearranged(
                      arrayMove(stages, oldIndex, newIndex),
                      sectionIndex
                    )
                  }
                  renderList={({ children, props }) => (
                    <div {...props}>{children}</div>
                  )}
                  renderItem={({ value: stage, index, props }) => {
                    return (
                      <Grid
                        item
                        container
                        direction="row"
                        key={stage._id}
                        className={classes.draggableItem}
                        {...props}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flex: 1
                          }}
                        >
                          <DragIndicatorIcon className={classes.draggable} />

                          <Typography
                            variant="bodym"
                            className={
                              stage.required
                                ? classes.stageName
                                : classes.disabledStageName
                            }
                          >
                            {stage.name}
                          </Typography>
                        </div>

                        <div>
                          {!ASSIGNEE_NOT_REQUIRED_STAGES.includes(
                            stage.internalContentStageName
                          ) && (
                            <Tooltip title="Edit field">
                              <IconButton
                                onClick={() => {
                                  this.showAddEditStagesDialog(
                                    sectionIndex,

                                    index
                                  );
                                }}
                                size="small"
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          {!stage.isDefaultStage && (
                            <Tooltip title="Delete field">
                              <IconButton
                                onClick={() => {
                                  this.toggleShowStageDeleteConfirmation(stage);
                                }}
                                size="small"
                              >
                                <CloseIcon size="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </Grid>
                    );
                  }}
                />
              </div>
            );
          })}
        </Grid>
        {showAddEditStages && (
          <StyledDialog
            open={showAddEditStages}
            title={
              stageIndex || stageIndex === 0 ? "Update stage" : "New stage"
            }
            body={
              <div style={{ display: "flex", flexDirection: "column" }}>
                {((!stageIndex && stageIndex !== 0) ||
                  !contentStages[sectionIndex].stages[stageIndex]
                    ?.isDefaultStage) && (
                  <div>
                    <Typography
                      paragraph
                      variant="bodym"
                      style={{ marginBottom: 8 }}
                    >
                      Stage
                    </Typography>

                    <StyledInput
                      autoFocus
                      fullWidth
                      value={stage}
                      size="large"
                      onChange={this.stageChange}
                      helperText={stageNameError}
                      error={!!stageNameError}
                    />
                  </div>
                )}

                {stageIndex >= 0 &&
                  [
                    topicStatus.CONTENT_MAP_DRAFT,
                    topicStatus.CONTENT_MAP_IN_REVIEW
                  ].includes(
                    contentStages[sectionIndex].stages[stageIndex]
                      ?.internalContentStageName
                  ) && (
                    <>
                      <StyledCheckbox
                        onChange={this.toggleRequiredOption}
                        checked={required}
                        label={`Require ${contentStages[sectionIndex].stages[stageIndex].name}`}
                      />
                    </>
                  )}
                {required && (
                  <div style={{ marginTop: 16 }}>
                    <Typography
                      paragraph
                      variant="bodym"
                      style={{ marginBottom: 8 }}
                    >
                      Assigned to
                    </Typography>

                    <StyledSelect
                      fullWidth
                      size="large"
                      name="assigneeSelectionType"
                      value={assigneeSelectionType}
                      onChange={this.onInputChange}
                    >
                      <StyledMenuItem
                        value={assigneeSelectionTypes.PROJECT_OWNER}
                      >
                        Project owner
                      </StyledMenuItem>

                      <StyledMenuItem
                        value={assigneeSelectionTypes.LET_USER_CHOOSE}
                      >
                        Let me pick the owner for the stage
                      </StyledMenuItem>

                      <StyledMenuItem
                        value={assigneeSelectionTypes.SAME_AS_STAGE}
                      >
                        Use the same owner as another stage
                      </StyledMenuItem>
                    </StyledSelect>
                  </div>
                )}

                {required &&
                  assigneeSelectionType ===
                    assigneeSelectionTypes.LET_USER_CHOOSE && (
                    <div style={{ marginTop: 16 }}>
                      <Typography
                        paragraph
                        variant="bodym"
                        style={{ marginBottom: 8 }}
                      >
                        Default
                      </Typography>

                      <Autocomplete
                        multiple
                        value={assignees}
                        onChange={(event, newValue) =>
                          this.ownerChipChange(newValue)
                        }
                        size="medium"
                        options={teammates}
                        renderInput={(params) => (
                          <StyledInput
                            {...params}
                            variant="outlined"
                            placeholder={assignees?.length ? "" : "Owner"}
                          />
                        )}
                        renderTags={(options, getTagProps) => {
                          return options.map((option, index) => (
                            <StyledUserChip
                              avatar={
                                <Avatar src={option.client?.profilePic} />
                              }
                              label={
                                typeof option === "string"
                                  ? option
                                  : option.client?.name || option.client?.email
                              }
                              {...getTagProps({ index })}
                            />
                          ));
                        }}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <Box key={option._id} {...props}>
                              <Avatar
                                src={option.client && option.client.profilePic}
                                className={classes.autompleteAvatar}
                              />

                              <Typography style={{ marginLeft: 10 }}>
                                {option.client.name || option.email}
                              </Typography>
                              {option.role === teamRoles.FREELANCER && (
                                <StyledChip
                                  variant="gray"
                                  label="freelancer"
                                  style={{ marginLeft: 10 }}
                                />
                              )}
                            </Box>
                          );
                        }}
                      />
                    </div>
                  )}

                {required &&
                  assigneeSelectionType ===
                    assigneeSelectionTypes.SAME_AS_STAGE && (
                    <div style={{ marginTop: 16 }}>
                      <Typography
                        paragraph
                        variant="bodym"
                        style={{ marginBottom: 8 }}
                      >
                        Use the same owner as
                      </Typography>

                      <StyledSelect
                        fullWidth
                        size="large"
                        name="sameAssigneeAs"
                        value={sameAssigneeAs}
                        error={!!stagePickerError}
                        onChange={this.onInputChange}
                      >
                        {contentStages.map((contentStage: any) => {
                          let stages = contentStage.stages;

                          return stages.map((stage: any, index: any) => {
                            if (
                              sameAssigneeAs !==
                                stage.internalContentStageName &&
                              (!stage.required ||
                                stage.assigneeSelectionType ===
                                  assigneeSelectionTypes.SAME_AS_STAGE ||
                                [
                                  topicStatus.SCHEDULED,
                                  topicStatus.PUBLISHED
                                ].includes(stage.internalContentStageName) ||
                                ((stageIndex || stageIndex === 0) &&
                                  contentStages[sectionIndex].stages[stageIndex]
                                    .internalContentStageName ===
                                    stage.internalContentStageName))
                            ) {
                              return;
                            }

                            return (
                              <StyledMenuItem
                                key={index}
                                value={stage.internalContentStageName}
                              >
                                {stage.name}
                              </StyledMenuItem>
                            );
                          });
                        })}
                      </StyledSelect>

                      <FormHelperText
                        style={{ marginLeft: 14, color: colors.helperText }}
                      >
                        {stagePickerError}
                      </FormHelperText>
                    </div>
                  )}
              </div>
            }
            successButtonName={
              stageIndex || stageIndex === 0 ? "Update" : "Add"
            }
            successCallback={this.successCallback}
            cancelCallback={this.closeDialog}
          />
        )}

        {showConfirmDialog && (
          <StyledDialog
            open={showConfirmDialog}
            title="Are you sure?"
            body="Once deleted, you can't recover your content stage"
            successButtonName="Delete"
            cancelButtonName="Cancel"
            successCallback={this.deleteStage}
            cancelCallback={this.toggleShowStageDeleteConfirmation}
          />
        )}
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(ContentStages));
