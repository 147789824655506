// @ts-nocheck
import colors from "../../Themes/Colors";

const Styles = (theme: any) => ({
  topCardPadding: {
    padding: 10
  },

  zeroState: {
    marginTop: 30,
    marginLeft: "15%",
    marginRight: "15%"
  },

  zeroStateText: {
    margin: "0 auto",
    textAlign: "center",
    paddingTop: 10,
    color: colors.fontSecondary
  },

  selectWidth: {
    width: 200
  },

  selectBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    paddingBottom: 20
  },

  card: {
    marginTop: 10,
    marginRight: 30,
    borderRadius: 7,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)"
  },

  cardSkeleton: {
    height: 20,
    marginTop: 10,
    marginRight: 50
  },

  cardTitle: {
    color: colors.fontSecondary
  },

  cardValue: {
    fontWeight: "bolder"
  },

  infoIcon: {
    marginLeft: 10,
    marginBottom: -5,
    right: 0,
    top: 0,
    cursor: "pointer"
  },

  heading: {
    fontWeight: "bolder"
  },

  keywordIdeasCard: {
    padding: "15px",
    marginTop: 20,
    borderRadius: 7,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)"
  },

  firstCell: {
    // paddingLeft: 1
  },

  link: {
    textDecoration: "none",
    color: colors.secondary,
    wordBreak: "break-word"
  },

  percentage: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    borderRadius: 5,
    height: "fit-content",
    maxWidth: 50
  },

  researchImage: {
    height: 300,
    width: 300,
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  },

  errorMessage: {
    paddingTop: 10,
    color: colors.fontSecondary,
    textAlign: "center"
  },

  pageURL: {
    color: colors.fontSecondary
  },

  topKeyword: {
    cursor: "pointer"
  }
});
export default Styles;
