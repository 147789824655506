// @ts-nocheck
import { designColors } from "../../Themes/Colors";

const styles = (theme: any) => ({
  lineChart: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    minHeight: 150,
    height: 200,
    width: "100%",
    minWidth: "100%"
  },

  tooltipPaper: {
    padding: 10
  },

  tooltipLabel: {
    fontSize: 12,
    color: designColors.primary[100]
  }
});

export default styles;
