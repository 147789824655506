// @ts-nocheck
import React, { useContext, Suspense } from "react";
import Page404 from "./Page404";
import Loading from "../Components/Loading";
import { Helmet } from "react-helmet";
import {
  Autocomplete,
  Box,
  CircularProgress,
  ClickAwayListener,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { shadow, space } from "../Config/theme";
import API from "../Services/Api";
import styles from "./styles/SettingsStyles";
import clsx from "clsx";
import StyledSwitch from "../design/components/StyledSwitch";
import StyledButton from "../design/components/StyledButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { notify } from "../Components/CustomNotifications";

import { ReactComponent as FilterIcon } from "../Images/icon20/filterprojects.svg";
import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";

import BetaText from "../Components/BetaText";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import {
  accountSegmentTypes,
  channelTypes,
  modulesAccess,
  pathOptionsLabel,
  postStatus,
  SortOrderEnum
} from "../Utils/Types";
import colors from "../Themes/Colors";

import DemoVideoDialog from "../design/components/DemoVideoDialog";
import StyledAutocomplete from "../design/components/StyledAutocomplete";
import StyledCheckbox from "../design/components/StyledCheckbox";
import StyledUserChip from "../design/components/StyledUserChip";
import StyledInputLabel from "../design/components/StyledInputLabel";
import { AxiosResponse } from "axios";
import { AUTOCOMPLETE_ALL_OPTION_TEXT } from "./LinkedInShare";
import StyledTab from "../design/components/StyledTab";
import PathSelectionFilter from "../Components/PathSelectionFilter";
import { isModuleDisabled } from "../Utils/AccessUtils";
import PageAccessContext from "../Utils/PageAccessContext";
import TagsInput from "../Components/TagsInput";
import StyledTable from "../design/components/StyledTable";
import StyledPagination from "../design/components/StyledPagination";
import SearchBar from "../design/components/SearchBar";
import { useLocation } from "react-router";
import { uploadFile, uploadMp3Audio, uploadMp4Video } from "../Utils/Storage";
import { optionType } from "./SocialDrafts";

const COMPANY_DETAILS_SAMPLE = `Retool is a powerful low-code platform that allows developers to build internal tools and applications quickly and easily. It is designed to streamline the process of creating user interfaces for accessing and manipulating data from various sources without writing extensive code.

Here are some key features of Retool:

Drag-and-Drop Interface: Retool provides a visual interface where users can drag and drop components like buttons, tables, forms, and charts onto a canvas. This allows for the rapid creation of intuitive user interfaces.

Connectors and Integrations: Retool offers a wide range of pre-built connectors and integrations with popular databases, APIs, and services. These connectors enable seamless communication with data sources such as PostgreSQL, MySQL, MongoDB, Salesforce, Slack, and many others.

Customizable Components: While Retool provides a rich set of pre-built components, developers can also create custom components using JavaScript and React. This allows for greater flexibility in designing and implementing complex UI elements.

Data Manipulation and Transformation: Retool offers a powerful data querying and manipulation interface. Users can write SQL queries, filter and transform data, aggregate results, and perform calculations using JavaScript. This makes it easy to fetch and manipulate data from different sources.

Real-time Collaboration: Retool allows multiple users to collaborate on building applications simultaneously. It supports real-time updates, enabling team members to work together efficiently and see changes reflected instantly.

Security and Permissions: Retool provides robust security features to ensure data protection. It offers granular access controls, allowing administrators to define user roles and permissions for different resources and actions.

Deployment and Hosting: Once an application is built, Retool provides options for deploying it on various platforms. You can host applications on Retool's cloud infrastructure, deploy them on your own servers, or export them as standalone executables.

Version Control: Retool supports version control, enabling developers to track changes, collaborate across teams, and revert to previous versions if needed. This helps maintain code quality and facilitates teamwork.
`;

const STYLE_GUIDE_SAMPLE = `Title: Quick Style Guide for XYZ Company Content Creation

Introduction: This condensed style guide ensures consistency, upholds the brand identity, and maintains quality across XYZ Company's content channels, including website articles, social media posts, and marketing materials.

1. Brand Voice and Tone
   - Voice: Knowledgeable, approachable, and conversational.
   - Tone: Confident and friendly. Use active voice and avoid jargon.

2. Writing Style and Formatting
   - Headings: Use attention-grabbing and descriptive text. Write in title case.
   - Paragraphs: Limit to 3-4 sentences, addressing one main idea.
   - Lists: Use bullet points for simple lists and numbered lists for sequences.
   - Links: Ensure all links are relevant, working, and open in a new tab.

3. Grammar, Punctuation, and Spelling
   - Follow AP style guide rules, with exceptions mentioned below.
   - Use the Oxford comma in lists.
   - Use American spelling and punctuation.

4. Accessibility and Inclusivity
   - Use gender-neutral language and terms.
   - Describe visuals and include alternative text for images.
   - Ensure content is suitable for a broad audience and various cultural backgrounds.

5. Text Formatting
   - Font: Use Arial, size 12 for body text and size 14 or 18 for headings.
   - Bold and italics: Use sparingly and for emphasis only.
   - Colors: Stick to company-approved colors for text and background.

6. Visuals
   - Images: Use high-quality, relevant images with proper permissions.
   - Logos: Use the latest version of the company logo for all materials.
   - Infographics: Maintain brand color palette, fonts, and style.

7. Word and Phrase Guidelines
   - Company name: Always write "XYZ Company" in full when first mentioned. On subsequent mentions, "XYZ" is acceptable.
   - Maintain consistency in terminology and avoid buzzwords or clichés.

8. Fact-checking and Editing
   - Always double-check facts, figures, and sources for accuracy.
   - Edit content for clarity, grammar, punctuation, and overall quality.

9. Approval Process
   - Submit content for approval by the assigned team member or editor, adhering to deadlines.
   `;

const labelTabsValue = {
  SELECT_FROM_BLOG_POSTS: "select_from_blog_posts",
  DEFINE_A_PATH: "define_a_path"
};

const accountSegmentTypeForTabs = {
  [labelTabsValue.SELECT_FROM_BLOG_POSTS]: accountSegmentTypes.POST,
  [labelTabsValue.DEFINE_A_PATH]: accountSegmentTypes.PATH
};

const labelTabs = [
  {
    label: "Select from blog posts",
    value: labelTabsValue.SELECT_FROM_BLOG_POSTS
  },
  {
    label: "Define a path",
    value: labelTabsValue.DEFINE_A_PATH
  }
];

const contentIndexTypes = [
  {
    userId: "url",
    name: "Web Page"
  },
  {
    userId: "pdf",
    name: "PDF"
  },
  {
    userId: "video",
    name: "Video"
  },
  {
    userId: "audio",
    name: "Audio"
  }
];

type IndexedPage = {
  name: string;
  type: string;
};

const socialChannels = [channelTypes.TWITTER, channelTypes.LINKEDIN];
const api = API.create();

function AISettings({ classes, match, location }: any) {
  const DEFAULT_CHANNEL = channelTypes.LINKEDIN;

  const DEFAULT_LIMIT = 20;
  const DEFAULT_OFFSET = 0;

  let context = useContext(PageAccessContext);
  const [loading, setLoading] = React.useState(true);
  const [samplesLoading, setSamplesLoading] = React.useState(false);
  const [accountSegmentsLoading, setAccountSegmentsLoading] =
    React.useState(false);
  const [personasLoading, setPersonasLoading] = React.useState(false);
  const [featuresLoading, setFeaturesLoading] = React.useState(false);
  const [questionsLoading, setQuestionsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [publicationId, setPublicationId] = React.useState(
    match.params.id ? match.params.id : ""
  );
  const [publication, setPublication] = React.useState(null);
  const [generateOutlineAndArticleByAI, setGenerateOutlineAndArticleByAI] =
    React.useState(false);
  const [showAddEditSampleDialog, setShowAddEditSampleDialog] =
    React.useState(false);
  const [showAddEditLabelDialog, setShowAddEditLabelDialog] =
    React.useState(false);
  const [selectedSampleId, setSelectedSampleId] = React.useState(null);
  const [selectedAccountSegmentId, setSelectedAccountSegmentId] =
    React.useState(null);
  const [sample, setSample] = React.useState("");
  const [label, setLabel] = React.useState("");
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [accountSegmentLabel, setAccountSegmentLabel] = React.useState("");
  const [accountSegmentLabelError, setAccountSegmentLabelError] =
    React.useState("");
  const [contents, setContents] = React.useState([]);
  const [channel, setChannel] = React.useState(DEFAULT_CHANNEL);
  const [samples, setSamples] = React.useState([]);
  const [posts, setPosts] = React.useState([]);
  const [isAllPostSelected, setIsAllPostSelected] = React.useState(false);
  const [accountSegments, setAccountSegments] = React.useState([]);
  const [personas, setPersonas] = React.useState([]);
  const [features, setFeatures] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [channelList, setChannelList] = React.useState(socialChannels);
  const [companyDetails, setCompanyDetails] = React.useState("");
  const [lastSavedCompanyDetails, setLastSavedCompanyDetails] =
    React.useState("");

  const [styleGuideRules, setStyleGuideRules] = React.useState("");
  const [lastSavedStyleGuideRules, setLastSavedStyleGuideRules] =
    React.useState("");

  const [buyer, setBuyer] = React.useState("");
  const [priorities, setPriorities] = React.useState([""]);
  const [buyerFeatures, setBuyerFeatures] = React.useState([]);

  const [name, setName] = React.useState("");
  const [problem, setProblem] = React.useState("");
  const [uniqueness, setUniqueness] = React.useState("");
  const [benefit, setBenefit] = React.useState("");

  const [showAddEditPersonaDialog, setShowAddEditPersonaDialog] =
    React.useState("");
  const [selectedPersonaId, setSelectedPersonaId] = React.useState("");
  const [showAddEditFeatureDialog, setShowAddEditFeatureDialog] =
    React.useState("");
  const [selectedFeatureId, setSelectedFeatureId] = React.useState("");
  const [isAllFeatureChecked, setIsAllFeatureChecked] = React.useState(false);
  const [productCategory, setProductCategory] = React.useState("");
  const [selectedTab, setSelectedTab] = React.useState(
    labelTabsValue.SELECT_FROM_BLOG_POSTS
  );
  const [questionReport, setQuestionReport] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [questionReportEmails, setQuestionReportEmails] = React.useState([]);
  const [buyerTitles, setBuyerTitles] = React.useState([]);
  const [baseBuyerTitle, setBaseBuyerTitle] = React.useState("");
  const [loadingBuyerTitle, setLoadingBuyerTitle] = React.useState(false);
  const [indexedPages, setIndexedPages] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [urlSearched, setUrlSearched] = React.useState("");
  const [showFilter, setShowFilter] = React.useState(false);
  const pageLocation = useLocation();
  const [currentLocation, setCurrentLocation] = React.useState(pageLocation);
  const [uploading, setUploading] = React.useState(false);
  const [indexing, setIndexing] = React.useState(false);
  const [selectedTypes, setSelectedTypes] = React.useState(contentIndexTypes);
  const [isAllTypeFilterChecked, setIsAllTypeFilterChecked] =
    React.useState(false);
  const [filterButtonElement, setFilterButtonElement] = React.useState(null);
  const fileUploadRef = React.useRef(null);
  const filterButtonRef = React.useRef(null);
  //allow pdf, mp3 and mp4 files
  const allowedExtensions = /(\.pdf|\.mp3|\.mp4)$/i;

  useEffect(() => {
    if (currentLocation.pathname !== pageLocation.pathname) {
      window?.location?.reload?.();
    }
  }, [pageLocation, currentLocation]);

  const toggleOutlineGenerationAI = () => {
    setGenerateOutlineAndArticleByAI(!generateOutlineAndArticleByAI);
    api.toggleOutlineGenerationAI(
      publicationId,
      !generateOutlineAndArticleByAI,
      (res: AxiosResponse) => {
        if (res.status !== 200) {
          setGenerateOutlineAndArticleByAI(generateOutlineAndArticleByAI);
          notify.show(
            res.status === 400
              ? res.data
              : "Oops. We couldn't save your change. Try again.",
            "error"
          );
        }
      }
    );
  };

  const toggleQuestionNotifications = () => {
    setQuestionReport(!questionReport);
    api.toggleQuestionNotifications(
      publicationId,
      !questionReport,
      (res: AxiosResponse) => {
        if (res.status !== 200) {
          setQuestionReport(questionReport);
          notify.show(
            res.status === 400
              ? res.data
              : "Oops. We couldn't save your change. Try again.",
            "error"
          );
        }
      }
    );
  };

  const deleteIndexedPage = (index: number) => {
    let newIndexedPages = [...indexedPages];
    newIndexedPages.splice(index, 1);
    setIndexedPages(newIndexedPages);
    api.deleteIndexedPage(
      publicationId,
      indexedPages[index]?.name,
      (res: any) => {
        if (res.status === 200) {
          notify.show("Page deleted successfully", "success");
          getContentIndexed();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error deleting page",
            "error"
          );
        }
      }
    );
  };

  const getTableBody = () => {
    return indexedPages.map((indexedPage: IndexedPage, index: number) => {
      let data = [
        { value: indexedPage.name, style: { width: "70%" } },
        { value: indexedPage.type, style: { width: "10%" } },
        {
          value: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
              }}
            >
              <div
                className={classes.actionButton}
                style={{ paddingRight: space.LARGE }}
              >
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => deleteIndexedPage(index)}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          ),
          style: { width: "20%" }
        }
      ];
      return {
        id: index,
        data
      };
    });
  };

  const onPageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
  };

  const onSearchTextChange = (value: string) => {
    setUrlSearched(value);
  };

  const toggleFilterOptions = (event: any, showFilterOptions: boolean) => {
    setFilterButtonElement(event.currentTarget);
    setShowFilter(showFilterOptions);
  };

  const onTypeFiltersChange = (e: any, values: optionType[]) => {
    //check if All option is selected in autocomplete component
    let allFlagSelected = values.find(
      (status: optionType) => status.name === AUTOCOMPLETE_ALL_OPTION_TEXT
    );
    //if All option is present in values
    // decide if you need to populate all account or remove all account
    //else
    // add accounts in values alone
    if (allFlagSelected) {
      //based on flag decide to populate all account or remove all account
      let populateAllAccounts = !isAllTypeFilterChecked;
      //remove or add all accounts
      setSelectedTypes(populateAllAccounts ? contentIndexTypes : []);
      //save flag value
      setIsAllTypeFilterChecked(populateAllAccounts);
    } else {
      //populate account in values
      setSelectedTypes(values);
      //if manually all accounts added enable flag
      setIsAllTypeFilterChecked(values.length === contentIndexTypes.length);
    }
  };

  const isTypeSelected = (option: optionType, optionIndex: number) => {
    if (isAllTypeFilterChecked) {
      return true;
    }

    let exists = selectedTypes.find(
      (account: any) => account.userId === option.userId
    );

    return exists ? true : false;
  };

  const openFileUpload = () => {
    if (uploading) {
      notify.show("Already a file is uploading", "error");
      return;
    }
    fileUploadRef.current.click();
  };

  const contentIndexing = () => {
    if (indexing) {
      notify.show("Already indexing in progress", "error");
      return;
    }
    setIndexing(true);
    api.contentIndex(publicationId, (res: AxiosResponse) => {
      setIndexing(false);
      notify.show("Started indexing your URLs", "success");
    });
  };

  const calculateFileSize = (fileObject: any) => {
    return fileObject.size / (1024 * 1024);
  };

  const onFileChange = (event: any) => {
    setUploading(true);
    let fileObject = event.target.files[0];

    if (!allowedExtensions.exec(fileObject.name)) {
      notify.show("Invalid file type", "error");
      return;
    }

    if (calculateFileSize(fileObject) > 50) {
      notify.show("Upload file size limit is 50 mb", "error");
      return;
    }

    uploadFileForIndexing(fileObject);
    event.target.value = "";
  };

  const uploadFileForIndexing = async (fileObject: any) => {
    let response, url, fileType;
    if (fileObject.name?.includes(".pdf")) {
      response = await uploadFile(fileObject, publication?.domain);
      fileType = "pdf";
    } else if (fileObject.name?.includes(".mp4")) {
      response = await uploadMp4Video(fileObject, publication?.domain);
      fileType = "video";
    } else if (fileObject.name?.includes(".mp3")) {
      response = await uploadMp3Audio(fileObject, publication?.domain);
      fileType = "audio";
    }
    url = response;

    api.importFileForIndexing(
      fileObject,
      url,
      fileObject.name,
      publicationId,
      fileType,
      (res: any) => {
        if (res.status === 200) {
          notify.show(res.data.message, res.data.success ? "success" : "error");
        } else {
          notify.show("Oops, something went wrong.", "error");
        }
        setUploading(false);
      }
    );
  };

  const onSearch = () => {
    setPage(0);
    getContentIndexed();
  };

  const cancelSearch = () => {
    setUrlSearched("");
    getContentIndexed(true);
  };

  const onTabChange = (e: any, tabName: string) => {
    setSelectedTab(tabName);
  };

  const onFilterChange = (filters: any) => {
    setFilters(filters);
  };

  const saveSample = (sample: any) => {
    setSamplesLoading(true);
    sample.isGenericTemplate = false;
    api.saveSample(publicationId, sample, (res: AxiosResponse) => {
      setSamplesLoading(false);
      getSamples();
      if (res.status === 200) {
        notify.show("Sample saved successfully", "success");
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error saving sample",
          "error"
        );
      }
    });
  };

  const saveAccountSegment = (accountSegment: any) => {
    setAccountSegmentsLoading(true);
    api.saveAccountSegment(
      publicationId,
      accountSegment,
      (res: AxiosResponse) => {
        setAccountSegmentsLoading(false);
        getAccountSegments();
        if (res.status === 200) {
          notify.show("Account Segment Label saved successfully", "success");
        } else {
          notify.show(
            res.status === 400
              ? res.data
              : "Error saving Account Segment Label",
            "error"
          );
        }
      }
    );
  };

  const saveFeature = (feature: any) => {
    setFeaturesLoading(true);
    api.saveFeature(publicationId, feature, (res: AxiosResponse) => {
      setFeaturesLoading(false);
      getFeatures();
      if (res.status === 200) {
        notify.show("Feature saved successfully", "success");
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error saving Feature",
          "error"
        );
      }
    });
  };

  const savePersona = (persona: any) => {
    setPersonasLoading(true);
    api.savePersona(publicationId, persona, (res: AxiosResponse) => {
      setPersonasLoading(false);
      getPersonas();
      if (res.status === 200) {
        notify.show("Feature saved successfully", "success");
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error saving Feature",
          "error"
        );
      }
    });
  };

  const isFeatureSelected = (option: any, optionIndex: any) => {
    if (isAllFeatureChecked) {
      return true;
    }

    let exists = buyerFeatures.find(
      (feature: any) => feature.userId === option.userId
    );

    return exists ? true : false;
  };

  const onBuyerFeatureChange = (e: any, values: any) => {
    //check if All option is selected in autocomplete component
    let allFlagSelected = values.find(
      (status: any) => status.name === AUTOCOMPLETE_ALL_OPTION_TEXT
    );
    //if All option is present in values
    // decide if you need to populate all account or remove all account
    //else
    // add accounts in values alone
    if (allFlagSelected) {
      //based on flag decide to populate all account or remove all account
      let populateAllAccounts = !isAllFeatureChecked;
      //remove or add all accounts
      setBuyerFeatures(
        populateAllAccounts
          ? features.map((feature: any) => {
              return { userId: feature._id, name: feature.name };
            })
          : []
      );
      //save flag value
      setIsAllFeatureChecked(populateAllAccounts);
    } else {
      //populate account in values
      setBuyerFeatures(values);
      //if manually all accounts added enable flag
      setIsAllFeatureChecked(values.length === features.length);
    }
  };

  const openAddEditSampleDialog = () => {
    setShowAddEditSampleDialog(true);
  };

  const openAddEditLabelDialog = () => {
    setShowAddEditLabelDialog(true);
  };

  const getCompanyDetails = () => {
    api.getCompanyDetails(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        let details = res.data || "";
        setCompanyDetails(details);
        setLastSavedCompanyDetails(details);
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error getting company details",
          "error"
        );
      }
    });
  };

  const deleteQuestionRow = (index: number) => {
    let questionToDelete = questions[index];
    let newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions([...newQuestions]);

    deleteQuestion(questionToDelete.question?._id);
  };

  const deleteQuestion = (questionId: string) => {
    api.deleteQuestion(publicationId, questionId, (res: AxiosResponse) => {
      if (res.status === 200) {
        notify.show("Question deleted successfully", "success");
        getQuestions();
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error deleting question",
          "error"
        );
      }
    });
  };

  const getProductCategory = () => {
    api.getProductCategory(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        setProductCategory(res.data);
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error getting product category",
          "error"
        );
      }
    });
  };

  const getCheckedFlag = (options: any) => {
    if (isAllPostSelected) {
      return true;
    }
    return contents?.some((account: any) => account._id === options._id);
  };

  const saveCompanyDetails = (companyDetails: any) => {
    api.saveCompanyDetails(
      publicationId,
      companyDetails,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          getCompanyDetails();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error saving company details",
            "error"
          );
        }
      }
    );
  };

  const saveProductCategory = (productCategory: any) => {
    api.saveProductCategory(
      publicationId,
      productCategory,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          getProductCategory();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error saving product category",
            "error"
          );
        }
      }
    );
  };

  const getSaveCompanyDetailsButton = () => {
    if (lastSavedCompanyDetails !== companyDetails) {
      return (
        <Grid item className={classes.saveCompanyDetailsButton}>
          <StyledButton onClick={() => saveCompanyDetails(companyDetails)}>
            Save
          </StyledButton>
        </Grid>
      );
    }
    return "";
  };

  const getStyleGuideRules = () => {
    api.getStyleGuideRules(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        let details = res.data || "";
        setStyleGuideRules(details);
        setLastSavedStyleGuideRules(details);
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error getting company details",
          "error"
        );
      }
    });
  };

  const saveStyleGuideRules = (styleGuideRules: any) => {
    api.saveStyleGuideRules(
      publicationId,
      styleGuideRules,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          getStyleGuideRules();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error saving company details",
            "error"
          );
        }
      }
    );
  };

  const getSaveStyleGuideRulesButton = () => {
    if (lastSavedStyleGuideRules === styleGuideRules) return "";
    return (
      <Grid item className={classes.saveCompanyDetailsButton}>
        <StyledButton onClick={() => saveStyleGuideRules(styleGuideRules)}>
          Save
        </StyledButton>
      </Grid>
    );
  };

  const closeAddEditSampleDialog = () => {
    setShowAddEditSampleDialog(false);
    setSelectedSampleId(null);
    setSample("");
    setLabel("");
    setChannel(DEFAULT_CHANNEL);
  };

  const closeAddEditLabelDialog = () => {
    setShowAddEditLabelDialog(false);
    setSelectedAccountSegmentId(null);
    setContents([]);
    setAccountSegmentLabel("");
    setAccountSegmentLabelError("");
  };

  const closeAddEditFeatureDialog = () => {
    setShowAddEditFeatureDialog(false);
    setSelectedFeatureId(null);
    setName("");
    setProblem("");
    setUniqueness("");
    setBenefit("");
  };

  const closeAddEditPersonaDialog = () => {
    setShowAddEditPersonaDialog(false);
    setSelectedPersonaId(null);
    setBuyer("");
    setPriorities([""]);
    setBuyerFeatures([]);
    setBuyerTitles([]);
    resetBaseBuyerTitle();
    setIsAllFeatureChecked(false);
  };

  const saveOrUpdateSample = () => {
    if (!label) {
      notify.show("Please enter label", "error");
      return;
    }
    if (!sample || !channel) {
      notify.show("Please enter sample and channel", "error");
      return;
    }

    if (selectedSampleId) {
      let sampleIndex = samples.findIndex(
        (sample) => sample._id === selectedSampleId
      );
      let editedSample = {
        ...samples[sampleIndex],
        sample: sample?.trim(),
        channel,
        label
      };

      samples[sampleIndex] = editedSample;
      saveSample(editedSample);
    } else {
      let newSample = {
        sample: sample?.trim(),
        channel,
        label
      };
      if (!samples) {
        samples = [];
      }

      samples.push(newSample);
      saveSample(newSample);
    }

    setSamples(samples);
    setShowAddEditSampleDialog(false);
    setSelectedSampleId(null);
    setSample("");
    setLabel("");
    setChannel(DEFAULT_CHANNEL);
  };

  const saveOrUpdateAccountSegment = () => {
    if (!accountSegmentLabel) {
      notify.show("Please enter label", "error");
      return;
    }
    if (accountSegmentLabelError) {
      notify.show(accountSegmentLabelError, "error");
      return;
    }
    if (
      selectedTab === labelTabsValue.SELECT_FROM_BLOG_POSTS &&
      !contents.length
    ) {
      notify.show("Please select post content", "error");
      return;
    }

    if (selectedTab === labelTabsValue.DEFINE_A_PATH && !filters.length) {
      notify.show("You should have atleast one path", "error");
      return;
    }

    if (selectedAccountSegmentId) {
      let accountSegmentIndex = accountSegments.findIndex(
        (accountSegment) => accountSegment._id === selectedAccountSegmentId
      );
      let editedAccountSegment = {
        ...accountSegments[accountSegmentIndex],
        contents:
          selectedTab === labelTabsValue.SELECT_FROM_BLOG_POSTS
            ? contents.map((content: any) => content._id)
            : [],
        label: accountSegmentLabel,
        type: accountSegmentTypeForTabs[selectedTab],
        filters: selectedTab === labelTabsValue.DEFINE_A_PATH ? filters : []
      };

      accountSegments[accountSegmentIndex] = editedAccountSegment;
      setAccountSegments(accountSegments);
      saveAccountSegment(editedAccountSegment);
    } else {
      let newAccountSegment = {
        contents:
          selectedTab === labelTabsValue.SELECT_FROM_BLOG_POSTS
            ? contents.map((content: any) => content._id)
            : [],
        label: accountSegmentLabel,
        type: accountSegmentTypeForTabs[selectedTab],
        filters: selectedTab === labelTabsValue.DEFINE_A_PATH ? filters : []
      };
      if (!accountSegments) {
        setAccountSegments([newAccountSegment]);
      } else {
        setAccountSegments([...accountSegments, newAccountSegment]);
      }

      saveAccountSegment(newAccountSegment);
    }

    setShowAddEditLabelDialog(false);
    setSelectedAccountSegmentId(null);
    setAccountSegmentLabel("");
    setContents([]);
  };

  const saveOrUpdateFeature = () => {
    if (!name) {
      notify.show("Please enter name", "error");
      return;
    }
    if (!problem) {
      notify.show("Please enter problem", "error");
      return;
    }
    if (!uniqueness) {
      notify.show("Please enter uniqueness", "error");
      return;
    }
    if (!benefit) {
      notify.show("Please enter benefit", "error");
      return;
    }

    if (selectedFeatureId) {
      let featureIndex = features.findIndex(
        (feature) => feature._id === selectedFeatureId
      );
      let editedFeature = {
        ...features[featureIndex],
        name,
        problem,
        uniqueness,
        benefit
      };

      features[featureIndex] = editedFeature;
      setFeatures(features);
      saveFeature(editedFeature);
    } else {
      let newFeature = {
        name,
        problem,
        uniqueness,
        benefit
      };
      if (!features) {
        setFeatures([newFeature]);
      } else {
        setFeatures([...features, newFeature]);
      }
      saveFeature(newFeature);
    }

    setShowAddEditFeatureDialog(false);
    setSelectedFeatureId(null);
    setName("");
    setProblem("");
    setUniqueness("");
    setBenefit("");
  };

  const saveOrUpdatePersona = () => {
    if (!buyer) {
      notify.show("Please enter buyer", "error");
      return;
    }
    if (!priorities?.length) {
      notify.show("Please enter atleast one priority", "error");
      return;
    }

    if (selectedPersonaId) {
      let personaIndex = personas.findIndex(
        (persona) => persona._id === selectedPersonaId
      );
      let editedPersona = {
        ...personas[personaIndex],
        buyer,
        priorities,
        features: buyerFeatures.map((feature: any) => feature.userId),
        buyerTitles
      };

      personas[personaIndex] = editedPersona;
      setPersonas(personas);
      savePersona(editedPersona);
    } else {
      let newPersona = {
        buyer,
        priorities,
        features: buyerFeatures.map((feature: any) => feature.userId),
        buyerTitles
      };
      if (!personas) {
        setPersonas([newPersona]);
      } else {
        setPersonas([...personas, newPersona]);
      }
      savePersona(newPersona);
    }

    setShowAddEditPersonaDialog(false);
    setSelectedPersonaId(null);
    setBuyer("");
    setPriorities([""]);
    setBuyerFeatures([]);
    setBuyerTitles([]);
    resetBaseBuyerTitle();
  };

  const getSamples = () => {
    api.getSamples(publicationId, null, (res: AxiosResponse) => {
      if (res.status === 200) {
        setSamples(res.data);
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error getting samples",
          "error"
        );
      }
    });
  };

  const getAccountSegments = () => {
    api.getAccountSegments(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        setAccountSegments(res.data);
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Error getting Account Segment Labels",
          "error"
        );
      }
    });
  };

  const getFeatures = () => {
    api.getFeatures(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        setFeatures(res.data);
        setIsAllFeatureChecked(false);
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error getting Features",
          "error"
        );
      }
    });
  };

  const getContentIndexed = (cancelSearch: boolean = false) => {
    api.getContentIndexed(
      publicationId,
      selectedTypes,
      rowsPerPage,
      urlSearched ? 0 : page * rowsPerPage,
      cancelSearch ? "" : urlSearched,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          setIndexedPages(res.data.indexedPages);
          setTotalCount(res.data.totalCount);
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error getting indexed pages",
            "error"
          );
        }
      }
    );
  };

  const getQuestions = () => {
    api.getQuestions(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        setQuestions(res.data);
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error getting Questions",
          "error"
        );
      }
    });
  };

  const getPersonas = () => {
    api.getPersonas(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        setPersonas(res.data);
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error getting Personas",
          "error"
        );
      }
    });
  };

  const deleteSample = (sampleId: string) => {
    api.deleteSample(publicationId, sampleId, (res: AxiosResponse) => {
      if (res.status === 200) {
        notify.show("Sample deleted successfully", "success");
        getSamples();
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error deleting sample",
          "error"
        );
      }
    });
  };

  const deleteAccountSegment = (accountSegmentId: string) => {
    api.deleteAccountSegment(
      publicationId,
      accountSegmentId,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          notify.show("Account Segment Label deleted successfully", "success");
          getAccountSegments();
        } else {
          notify.show(
            res.status === 400
              ? res.data
              : "Error deleting Account Segment Label",
            "error"
          );
        }
      }
    );
  };

  const deleteFeature = (featureId: string) => {
    api.deleteFeature(publicationId, featureId, (res: AxiosResponse) => {
      if (res.status === 200) {
        notify.show("Feature deleted successfully", "success");
        getFeatures();
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error deleting feature",
          "error"
        );
      }
    });
  };

  const deletePersona = (personaId: string) => {
    api.deletePersona(publicationId, personaId, (res: AxiosResponse) => {
      if (res.status === 200) {
        notify.show("Persona deleted successfully", "success");
        getPersonas();
      } else {
        notify.show(
          res.status === 400 ? res.data : "Error deleting persona",
          "error"
        );
      }
    });
  };

  const onSampleChange = (e: any) => {
    setSample(e.target.value);
  };

  const onLabelChange = (e: any) => {
    setLabel(e.target.value);
  };

  const onAccountSegmentLabelChange = (e: any) => {
    //only accept the following chars and validate that the label only consists of: [a-z,0-9,_]
    const regex = /^[a-zA-Z0-9_]*$/;
    setAccountSegmentLabelError("");
    if (e.target.value.length > 0 && !regex.test(e.target.value)) {
      setAccountSegmentLabelError(
        "Label Name can only contain letters, numbers and underscores"
      );
    }
    setAccountSegmentLabel(e.target.value);
  };

  const getChannelDetails = () => {
    api.getChannelDetails(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        setPublication(res.data.publication);
        setQuestionReport(
          res.data.publication?.emailNotifications?.salesCallQuestionReport
        );
        setQuestionReportEmails(res.data.publication?.questionReportEmails);
        setGenerateOutlineAndArticleByAI(
          res.data.publication?.generateOutlineAndArticleByAI
        );
        setLoading(false);
      } else {
        setError(true);
        setErrorMessage(
          res.status === 400 ? res.data : "Oops, Something went wrong."
        );
      }
    });
  };

  const onChannelChange = (e: any) => {
    setChannel(e.target.value);
  };

  const editSampleRow = (index: number) => {
    let sample = samples[index];

    setSample(sample.sample);
    setLabel(sample.label);
    setChannel(sample.channel);
    setShowAddEditSampleDialog(true);

    setSelectedSampleId(sample._id);
  };

  const editAccountSegmentRow = (index: number) => {
    let accountSegment = accountSegments[index];

    setContents(accountSegment.contents);
    setAccountSegmentLabel(accountSegment.label);
    setFilters(accountSegment.filters);
    setSelectedTab(
      accountSegment.type === accountSegmentTypes.POST
        ? labelTabsValue.SELECT_FROM_BLOG_POSTS
        : labelTabsValue.DEFINE_A_PATH
    );
    setShowAddEditLabelDialog(true);

    setSelectedAccountSegmentId(accountSegment._id);
  };

  const editPersonaRow = (index: number) => {
    let persona = personas[index];

    setBuyer(persona.buyer);
    setPriorities(persona.priorities);
    setBuyerFeatures(
      persona.features.map((feature: any) => {
        return { userId: feature._id, name: feature.name };
      })
    );
    setBuyerTitles(persona?.buyerTitles || []);
    resetBaseBuyerTitle();
    setShowAddEditPersonaDialog(true);

    setSelectedPersonaId(persona._id);
  };

  const resetBaseBuyerTitle = () => {
    setBaseBuyerTitle("");
    setLoadingBuyerTitle(false);
  };

  const suggestBuyerTitles = () => {
    setLoadingBuyerTitle(true);
    api.suggestBuyerTitles(
      publicationId,
      baseBuyerTitle,
      (res: AxiosResponse) => {
        let suggestedTitles = res.data?.suggestedTitles || [];
        if (!suggestedTitles.length) {
          suggestedTitles = [baseBuyerTitle];
        }
        resetBaseBuyerTitle();
        setBuyerTitles([...buyerTitles, ...suggestedTitles]);
      }
    );
  };

  const editFeatureRow = (index: number) => {
    let feature = features[index];

    setName(feature.name);
    setProblem(feature.problem);
    setUniqueness(feature.uniqueness);
    setBenefit(feature.benefit);
    setShowAddEditFeatureDialog(true);

    setSelectedFeatureId(feature._id);
  };

  const deleteSampleRow = (index: number) => {
    let sampleToDelete = samples[index];
    samples.splice(index, 1);
    setSamples([...samples]);

    deleteSample(sampleToDelete._id);
  };

  const deleteAccountSegmentRow = (index: number) => {
    let accountSegmentToDelete = accountSegments[index];
    accountSegments.splice(index, 1);
    setAccountSegments([...accountSegments]);

    deleteAccountSegment(accountSegmentToDelete._id);
  };

  const deletePersonaRow = (index: number) => {
    let personaToDelete = personas[index];
    personas.splice(index, 1);
    setPersonas([...personas]);

    deletePersona(personaToDelete._id);
  };

  const deleteFeatureRow = (index: number) => {
    let featureToDelete = features[index];
    features.splice(index, 1);
    setFeatures([...features]);

    deleteFeature(featureToDelete._id);
  };

  const getPosts = () => {
    api.getPosts(
      postStatus.PUBLISHED,
      publicationId,
      DEFAULT_LIMIT,
      DEFAULT_OFFSET,
      searchKeyword, //searchKeyword
      [], //destinationList
      [], //compositeSEORating
      SortOrderEnum.ASC,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          setPosts(res.data.posts.docs);
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error getting posts",
            "error"
          );
        }
      }
    );
  };

  const getPostDebounce = _.debounce(getPosts, 1000);

  const saveQuestionReportEmails = (emails: string[]) => {
    api.saveQuestionReportEmails(
      publicationId,
      emails,
      (res: AxiosResponse) => {
        if (res.status !== 200) {
          notify.show(
            res.status === 400
              ? res.data
              : "Oops. We couldn't save your change. Try again.",
            "error"
          );
        }
      }
    );
  };

  useEffect(() => {
    getChannelDetails();
    getSamples();
    getPosts();
    getAccountSegments();
    getProductCategory();
    getFeatures();
    getPersonas();
    getQuestions();
    getCompanyDetails();
    getStyleGuideRules();
    getContentIndexed();
  }, []);

  useEffect(() => {
    getContentIndexed();
  }, [page, rowsPerPage, selectedTypes]);

  useEffect(() => {
    if (searchKeyword.length > 2) {
      getPostDebounce();
    }
  }, [searchKeyword]);

  useEffect(() => {
    let hash = window.location.hash;
    if (hash === "#companydetails") {
      let element = document.getElementById("companydetails");
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [document.getElementById("companydetails")]);

  if (error) {
    return (
      <Suspense fallback={<div />}>
        <Page404 errorMessage={errorMessage} />
      </Suspense>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.container}>
      <Helmet>
        <title>
          AI Settings {publication && "- " + publication.name} | Letterdrop
        </title>
      </Helmet>

      <Container>
        <Typography variant="h600" paragraph style={{ paddingTop: space.L }}>
          AI Settings
        </Typography>

        <div className={classes.innerContainer}>
          <Grid container direction="row" justify="center">
            <Grid item xs={12} style={{ marginTop: space.LARGE }}>
              <div
                className={classes.heading}
                style={{ marginTop: space.LARGE }}
              >
                <Typography variant="h400" paragraph>
                  AI Outline and Draft Generation
                </Typography>
              </div>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                marginTop={space.SMALL}
              >
                <Grid item>
                  <StyledSwitch
                    checked={generateOutlineAndArticleByAI}
                    name="aiOutlineGeneration"
                    onChange={toggleOutlineGenerationAI}
                  />
                </Grid>

                <Grid item>
                  <Typography variant="bodym">
                    Automatically generate an outline and draft from briefs when
                    assigned
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {!isModuleDisabled(
              context,
              publicationId,
              modulesAccess.SOCIAL
            ) && (
              <Grid item xs={12} style={{ marginTop: space.LARGE }}>
                <hr color={colors.horizontalRule} />

                <div
                  className={classes.heading}
                  style={{ marginTop: space.LARGE }}
                >
                  <Typography variant="h400" paragraph>
                    Samples
                  </Typography>

                  <Typography variant="bodym" className={classes.cardSubtitle}>
                    Add samples to match your tone and voice
                  </Typography>
                </div>

                <Grid container direction="column">
                  {samplesLoading && <Loading />}

                  {!samplesLoading && samples?.length > 0 && (
                    <TableContainer>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Label</TableCell>
                            <TableCell>Sample</TableCell>

                            <TableCell>Channel</TableCell>

                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {samples.map((value, index) => {
                            return (
                              <TableRow width="100%" key={index}>
                                <TableCell
                                  style={{
                                    width: "20%"
                                  }}
                                >
                                  <Grid
                                    style={{
                                      lineHeight: "20px",
                                      maxHeight: "40px",
                                      overflow: "hidden"
                                    }}
                                  >
                                    {value.label}
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "40%"
                                  }}
                                >
                                  <Grid
                                    style={{
                                      lineHeight: "20px",
                                      maxHeight: "40px",
                                      overflow: "hidden"
                                    }}
                                  >
                                    {value.sample}
                                  </Grid>
                                </TableCell>

                                <TableCell style={{ width: "30%" }}>
                                  <Grid container direction="row">
                                    <Grid item xs={12}>
                                      <Typography variant="bodym">
                                        {" "}
                                        {value.channel}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </TableCell>

                                <TableCell
                                  style={{
                                    width: "10%"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row"
                                    }}
                                  >
                                    <div className={classes.actionButton}>
                                      <Tooltip title="Edit">
                                        <IconButton
                                          onClick={() => {
                                            editSampleRow(index);
                                          }}
                                          size="large"
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </div>

                                    <div className={classes.actionButton}>
                                      <Tooltip title="Delete">
                                        <IconButton
                                          onClick={() => deleteSampleRow(index)}
                                          size="large"
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                  <Grid
                    item
                    container
                    direction="row"
                    className={clsx(
                      classes.marginTop20,
                      classes.justifyContent
                    )}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <StyledButton
                        variant="textprimary"
                        className={classes.paddingLeft10}
                        onClick={openAddEditSampleDialog}
                        startIcon={<AddCircleIcon />}
                      >
                        Add Sample
                      </StyledButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} style={{ marginTop: space.LARGE }}>
              <hr color={colors.horizontalRule} />

              <section id="companydetails">
                <div
                  className={classes.heading}
                  style={{ marginTop: space.LARGE }}
                >
                  <Typography variant="h400" paragraph>
                    Company Details
                  </Typography>

                  <Grid container direction="column" style={{ paddingTop: 10 }}>
                    <Typography variant="bodym">
                      Describe what your company does, who you serve, and it's
                      offering/features. This will help our AI understand what
                      content ideas are relevant to you.
                    </Typography>

                    <StyledInput
                      multiline
                      rows={5}
                      size="medium"
                      placeholder={COMPANY_DETAILS_SAMPLE}
                      name="companyDetails"
                      className={classes.companyDetails}
                      value={companyDetails}
                      onChange={(event: any) => {
                        setCompanyDetails(event.target.value);
                      }}
                    />
                    {getSaveCompanyDetailsButton()}
                  </Grid>
                </div>
              </section>
            </Grid>

            <Grid item xs={12} style={{ marginTop: space.LARGE }}>
              <hr color={colors.horizontalRule} />

              <div
                className={classes.heading}
                style={{ marginTop: space.LARGE }}
              >
                <Typography variant="h400">
                  Company Style Guide <BetaText />
                </Typography>

                <Grid container direction="column" style={{ paddingTop: 10 }}>
                  <StyledInput
                    multiline
                    rows={5}
                    size="medium"
                    placeholder={STYLE_GUIDE_SAMPLE}
                    name="styleGuideRules"
                    className={classes.companyDetails}
                    value={styleGuideRules}
                    onChange={(event: any) => {
                      setStyleGuideRules(event.target.value);
                    }}
                  />
                  {getSaveStyleGuideRulesButton()}
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} style={{ marginTop: space.LARGE }}>
              <hr color={colors.horizontalRule} />

              <div
                className={classes.heading}
                style={{ marginTop: space.LARGE }}
              >
                <Typography variant="h400" paragraph>
                  Account Segmentation
                </Typography>

                <Typography variant="bodym" className={classes.cardSubtitle}>
                  We'll use these to label accounts based on what content they
                  consume. Example: We have a feature around "internal linking".
                  We can categorize accounts as interesting in "internal
                  linking" if they engage with content around the topic.
                </Typography>
              </div>

              <Grid container direction="column">
                {accountSegmentsLoading && <Loading />}

                {!accountSegmentsLoading && accountSegments?.length > 0 && (
                  <TableContainer>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Label</TableCell>
                          <TableCell>Pages</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {accountSegments.map((value, index) => {
                          return (
                            <TableRow width="100%" key={index}>
                              <TableCell
                                style={{
                                  width: "30%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.label}
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "60%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.type === accountSegmentTypes.POST
                                    ? value.contents.map(
                                        (content: any, index: number) => {
                                          return (
                                            <>
                                              {index === 0 ? "" : ", "}
                                              <a
                                                href={`posts/${content._id}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {content.title}
                                              </a>
                                            </>
                                          );
                                        }
                                      )
                                    : value.filters.map(
                                        (filter: any, index: number) => {
                                          return (
                                            <>
                                              {index === 0 ? "" : ", "}
                                              {`${
                                                pathOptionsLabel[filter.option]
                                              }: ${filter.path}`}
                                            </>
                                          );
                                        }
                                      )}
                                </Grid>
                              </TableCell>

                              <TableCell
                                style={{
                                  width: "10%"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <div className={classes.actionButton}>
                                    <Tooltip title="Edit">
                                      <IconButton
                                        onClick={() => {
                                          editAccountSegmentRow(index);
                                        }}
                                        size="large"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>

                                  <div className={classes.actionButton}>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        onClick={() =>
                                          deleteAccountSegmentRow(index)
                                        }
                                        size="large"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                <Grid
                  item
                  container
                  direction="row"
                  className={clsx(classes.marginTop20, classes.justifyContent)}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <StyledButton
                      variant="textprimary"
                      className={classes.paddingLeft10}
                      onClick={openAddEditLabelDialog}
                      startIcon={<AddCircleIcon />}
                    >
                      Add Label
                    </StyledButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: space.LARGE }}>
              <hr color={colors.horizontalRule} />

              <div
                className={classes.heading}
                style={{ marginTop: space.LARGE }}
              >
                <Typography variant="h400" paragraph>
                  Personas and Features <BetaText />
                </Typography>

                <Typography variant="bodym" className={classes.cardSubtitle}>
                  Describe who you sell to and what you sell to give our AI
                  context on your business
                </Typography>
              </div>

              <Grid container direction="column">
                <StyledInputLabel
                  style={{
                    marginTop: space.MEDIUM
                  }}
                  label="Product Category"
                />
                <Grid
                  container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Grid item xs={9}>
                    <StyledInput
                      fullWidth
                      type="text"
                      size="large"
                      placeholder="Content Marketing Platform"
                      name="productCategory"
                      value={productCategory}
                      onChange={(event: any) => {
                        setProductCategory(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <StyledButton
                      variant="primary"
                      size="medium"
                      onClick={() => saveProductCategory(productCategory)}
                      style={{
                        marginRight: space.MEDIUM
                      }}
                    >
                      Save
                    </StyledButton>

                    <StyledButton
                      variant="secondary"
                      size="medium"
                      onClick={() => getProductCategory()}
                    >
                      Cancel
                    </StyledButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                style={{
                  marginTop: space.L
                }}
              >
                <Typography variant="h300" paragraph>
                  Personas
                </Typography>
              </Grid>
              <Grid container direction="column">
                {personasLoading && <Loading />}

                {!personasLoading && personas?.length > 0 && (
                  <TableContainer>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Persona</TableCell>
                          <TableCell>Priorities</TableCell>
                          <TableCell>Features</TableCell>
                          <TableCell>Buyer Titles</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {personas.map((value, index) => {
                          return (
                            <TableRow width="100%" key={index}>
                              <TableCell
                                style={{
                                  width: "20%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.buyer}
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "30%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.priorities?.join(", ")}
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "20%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.features
                                    ?.map((feature) => feature?.name)
                                    ?.join(", ")}
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "20%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.buyerTitles?.join(", ")}
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "10%"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <div className={classes.actionButton}>
                                    <Tooltip title="Edit">
                                      <IconButton
                                        onClick={() => {
                                          editPersonaRow(index);
                                        }}
                                        size="large"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>

                                  <div className={classes.actionButton}>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        onClick={() => deletePersonaRow(index)}
                                        size="large"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                <Grid
                  item
                  container
                  direction="row"
                  className={clsx(classes.marginTop20, classes.justifyContent)}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <StyledButton
                      variant="textprimary"
                      className={classes.paddingLeft10}
                      onClick={() => setShowAddEditPersonaDialog(true)}
                      startIcon={<AddCircleIcon />}
                    >
                      Add Buyer
                    </StyledButton>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                direction="column"
                style={{
                  marginTop: space.L
                }}
              >
                <Typography variant="h300" paragraph>
                  Features
                </Typography>
              </Grid>
              <Grid container direction="column">
                {featuresLoading && <Loading />}

                {!featuresLoading && features?.length > 0 && (
                  <TableContainer>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Problem</TableCell>
                          <TableCell>Uniqueness</TableCell>
                          <TableCell>Benefit</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {features.map((value, index) => {
                          return (
                            <TableRow width="100%" key={index + 1}>
                              <TableCell
                                style={{
                                  width: "20%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.name}
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "20%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.problem}
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "25%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.uniqueness}
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "25%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.benefit}
                                </Grid>
                              </TableCell>

                              <TableCell
                                style={{
                                  width: "10%"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <div className={classes.actionButton}>
                                    <Tooltip title="Edit">
                                      <IconButton
                                        onClick={() => {
                                          editFeatureRow(index);
                                        }}
                                        size="large"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>

                                  <div className={classes.actionButton}>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        onClick={() => deleteFeatureRow(index)}
                                        size="large"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                <Grid
                  item
                  container
                  direction="row"
                  className={clsx(classes.marginTop20, classes.justifyContent)}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <StyledButton
                      variant="textprimary"
                      className={classes.paddingLeft10}
                      onClick={() => setShowAddEditFeatureDialog(true)}
                      startIcon={<AddCircleIcon />}
                    >
                      Add Feature
                    </StyledButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: space.LARGE }}>
              <hr color={colors.horizontalRule} />

              <div
                className={classes.heading}
                style={{ marginTop: space.LARGE }}
              >
                <Typography variant="h400" paragraph>
                  Call Analysis Report <BetaText />
                </Typography>

                <Typography variant="bodym" className={classes.cardSubtitle}>
                  Get a weekly report with answers to your saved questions from
                  your customer calls
                </Typography>
              </div>

              <Grid item container xs={12} style={{ marginTop: space.MEDIUM }}>
                <FormControlLabel
                  control={
                    <StyledSwitch
                      name={"sentQuestionReport"}
                      checked={questionReport}
                      onChange={toggleQuestionNotifications}
                    />
                  }
                  label="Weekly report"
                  componentsProps={{
                    typography: {
                      variant: "bodym"
                    }
                  }}
                />
              </Grid>
              <Grid style={{ marginTop: space.MEDIUM }}>
                <Typography
                  variant="bodym"
                  paragraph
                  style={{ marginBottom: space.SMALL }}
                >
                  Emails
                </Typography>

                <TagsInput
                  value={questionReportEmails}
                  name="questionReportEmails"
                  size="medium"
                  suggestions={[]}
                  className={classes.width100}
                  addTag={(event: any, newValue: string) => {
                    setQuestionReportEmails([
                      ...questionReportEmails,
                      newValue
                    ]);

                    saveQuestionReportEmails([
                      ...questionReportEmails,
                      newValue
                    ]);
                  }}
                  handleChange={(event: any, newValues: string[]) => {
                    setQuestionReportEmails(newValues);

                    saveQuestionReportEmails(newValues);
                  }}
                />
              </Grid>
              <Grid
                container
                direction="column"
                style={{
                  marginTop: space.L
                }}
              >
                <Typography variant="h300" paragraph>
                  Questions
                </Typography>
              </Grid>
              <Grid container direction="column">
                {questionsLoading && <Loading />}

                {!questionsLoading && questions?.length > 0 && (
                  <TableContainer>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Question</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {questions.map((value: any, index: number) => {
                          return (
                            <TableRow width="100%" key={index + 1}>
                              <TableCell
                                style={{
                                  width: "90%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.question?.question}
                                </Grid>
                              </TableCell>

                              <TableCell
                                style={{
                                  width: "10%"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <div className={classes.actionButton}>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        onClick={() => deleteQuestionRow(index)}
                                        size="large"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: space.LARGE }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Typography variant="h400" paragraph>
                    Sales Enablement Knowledge Base
                  </Typography>
                  <StyledButton
                    variant="primary"
                    size="medium"
                    onClick={contentIndexing}
                  >
                    Index Content
                  </StyledButton>
                </Grid>
                <Typography variant="bodym" paragraph>
                  These are the pages and files that our AI will index and use
                  for content suggestions to sales.
                </Typography>

                <Grid container direction="column">
                  <PathSelectionFilter
                    filterList={[{ name: "URL", value: "url" }]}
                    filters={publication?.indexPathConditions?.map(
                      (condition) => ({
                        ...condition,
                        filter: "url",
                        pathErrorMessage: ""
                      })
                    )}
                    onChange={(filters) => {
                      api.saveIndexPathConditions(
                        publicationId,
                        filters,
                        (res) => {
                          if (res.status !== 200) {
                            notify.show("Error saving content path", "error");
                          }
                        }
                      );
                    }}
                  />
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  style={{ paddingTop: space.XL }}
                >
                  <SearchBar
                    onChange={onSearchTextChange}
                    value={urlSearched}
                    onRequestSearch={onSearch}
                    onCancelSearch={cancelSearch}
                    className={classes.searchBar}
                    placeholder={"Search"}
                  />
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center"
                  }}
                >
                  <StyledButton
                    variant="textprimary"
                    size="medium"
                    style={{ marginRight: space.MEDIUM }}
                    onClick={openFileUpload}
                  >
                    Upload
                  </StyledButton>
                  {uploading && (
                    <CircularProgress
                      size={20}
                      color="inherit"
                      style={{ marginRight: 10 }}
                    />
                  )}
                  <input
                    id="myInput"
                    type="file"
                    ref={fileUploadRef}
                    style={{ display: "none" }}
                    accept=".pdf,.mp3,.mp4"
                    onChange={onFileChange}
                  />

                  <IconButton
                    onClick={(event: any) => toggleFilterOptions(event, true)}
                    size="large"
                  >
                    <FilterIcon />
                  </IconButton>
                </Grid>

                <Grid container direction="column">
                  {loading && <Loading marginTop={space.XL} />}

                  {!loading && (
                    <>
                      <StyledTable
                        tableWidth="100%"
                        tableContainerProps={{
                          style: { marginTop: space.SMALL }
                        }}
                        headerArray={[
                          {
                            value: "Name",
                            headerAlignment: "left",
                            style: { width: "70%" }
                          },
                          {
                            value: "Type",
                            headerAlignment: "left",
                            style: { width: "10%" }
                          },
                          {
                            value: "",
                            headerAlignment: "center",
                            style: { width: "20%" }
                          }
                        ]}
                        bodyArray={getTableBody()}
                      />
                      {!Boolean(indexedPages?.length) && (
                        <div style={{ textAlign: "center" }}>
                          <Typography variant="bodym" paragraph>
                            No URLs indexed for this workspace.
                          </Typography>
                        </div>
                      )}
                      <div>
                        <StyledPagination
                          rowsPerPageOptions={[10, 20, 50]}
                          count={totalCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={onPageChange}
                          onRowsPerPageChange={onRowsPerPageChange}
                        />
                      </div>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {showAddEditSampleDialog && (
            <StyledDialog
              open={showAddEditSampleDialog}
              title={selectedSampleId ? "Update field" : "Add field"}
              body={
                <div style={{ padding: `0 ${space.LARGE}` }}>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <Typography
                      variant="bodym"
                      paragraph
                      style={{ marginBottom: space.SMALL }}
                    >
                      Label
                    </Typography>

                    <StyledInput
                      autoFocus
                      type="text"
                      size="large"
                      name="label"
                      fullWidth
                      placeholder="Product Launch"
                      value={label}
                      onChange={onLabelChange}
                    />
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <Typography
                      variant="bodym"
                      paragraph
                      style={{ marginBottom: space.SMALL }}
                    >
                      Sample
                    </Typography>

                    <StyledInput
                      type="textarea"
                      multiline={true}
                      rows={3}
                      size="large"
                      name="sample"
                      fullWidth
                      value={sample}
                      onChange={onSampleChange}
                    />
                  </div>

                  <div style={{ marginBottom: space.MEDIUM }}>
                    <Typography
                      variant="bodym"
                      paragraph
                      style={{ marginBottom: space.SMALL }}
                    >
                      Channel
                    </Typography>

                    <StyledSelect
                      name="channel"
                      fullWidth
                      value={channel}
                      size="large"
                      onChange={onChannelChange}
                    >
                      {Object.values(channelList).map((channel, index) => {
                        return (
                          <StyledMenuItem value={channel} key={channel}>
                            <Grid container direction="row">
                              <Grid item xs={12}>
                                <Typography variant="bodym">
                                  {" "}
                                  {channel}
                                </Typography>
                              </Grid>
                            </Grid>
                          </StyledMenuItem>
                        );
                      })}
                    </StyledSelect>
                  </div>
                </div>
              }
              successButtonName={selectedSampleId ? "Update" : "Add"}
              successCallback={saveOrUpdateSample}
              cancelCallback={closeAddEditSampleDialog}
            />
          )}

          {showFilter && (
            <ClickAwayListener onClickAway={() => toggleFilterOptions(false)}>
              <Popper
                open={showFilter}
                placement="right-start"
                style={{ position: "absolute", zIndex: 999, width: "350px" }}
                anchorEl={filterButtonElement}
              >
                <Paper
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: space.SMALL,
                    boxShadow: shadow.MEDIUM
                  }}
                >
                  <Typography
                    variant="bodyl"
                    style={{
                      marginBottom: space.XS
                    }}
                  >
                    Type
                  </Typography>
                  <StyledAutocomplete
                    value={selectedTypes}
                    onChange={onTypeFiltersChange}
                    onInputChange={(event: any, value: any) => {
                      if (!value) {
                        onTypeFiltersChange(event, []);
                      }
                    }}
                    options={contentIndexTypes}
                    isCheckboxSelected={isTypeSelected}
                    showAvatar={false}
                  />
                </Paper>
              </Popper>
            </ClickAwayListener>
          )}
          {showAddEditLabelDialog && (
            <StyledDialog
              open={showAddEditLabelDialog}
              title={
                selectedAccountSegmentId
                  ? "Update your label"
                  : "Create your label"
              }
              maxWidth="md"
              body={
                <div style={{ padding: `0 ${space.MEDIUM}` }}>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInput
                      autoFocus
                      type="text"
                      size="large"
                      name="label"
                      label="Label Name"
                      fullWidth
                      placeholder="Internal Links"
                      value={accountSegmentLabel}
                      onChange={onAccountSegmentLabelChange}
                      helperText={accountSegmentLabelError}
                      error={accountSegmentLabelError}
                    />
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInputLabel
                      label="Related Content"
                      helperText="If an account reads this content or anything similar, we'll apply the
          above label."
                    />

                    <StyledTab
                      value={selectedTab}
                      tabs={labelTabs}
                      onChange={onTabChange}
                    />
                    <div style={{ marginTop: space.MEDIUM }}>
                      {selectedTab ===
                        labelTabsValue.SELECT_FROM_BLOG_POSTS && (
                        <Autocomplete
                          multiple
                          value={contents}
                          options={posts}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.title}
                          fullWidth
                          filterOptions={(x) => x}
                          onInputChange={(event, newInputValue) => {
                            setSearchKeyword(newInputValue);
                          }}
                          renderInput={(params) => (
                            <StyledInput
                              fullWidth
                              {...params}
                              size="small"
                              variant="outlined"
                            />
                          )}
                          renderOption={(props, option) => {
                            return (
                              <Box key={option._id} {...props}>
                                <StyledCheckbox
                                  size={"small"}
                                  checked={getCheckedFlag(option)}
                                  labelStyle={{ marginRight: 10 }}
                                />

                                <Grid container direction="column">
                                  <Typography
                                    variant="bodym"
                                    style={{ marginLeft: space.MEDIUM }}
                                  >
                                    {option?.title}
                                  </Typography>
                                </Grid>
                              </Box>
                            );
                          }}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => {
                              return (
                                <StyledUserChip
                                  key={index}
                                  label={`${option.title}`}
                                  {...getTagProps({ index })}
                                />
                              );
                            })
                          }
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setContents(newValue);
                            }
                          }}
                        />
                      )}
                      {selectedTab === labelTabsValue.DEFINE_A_PATH && (
                        <PathSelectionFilter
                          filterList={[{ name: "URL", value: "url" }]}
                          filters={filters}
                          onChange={onFilterChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              }
              successButtonName={selectedAccountSegmentId ? "Update" : "Save"}
              successCallback={saveOrUpdateAccountSegment}
              cancelCallback={closeAddEditLabelDialog}
            />
          )}
          {showAddEditPersonaDialog && (
            <StyledDialog
              open={showAddEditPersonaDialog}
              title={
                selectedPersonaId ? "Update a Persona" : "Create a Persona"
              }
              body={
                <div style={{ padding: `0 ${space.MEDIUM}` }}>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInput
                      autoFocus
                      type="text"
                      size="large"
                      name="buyer"
                      label="Buyer"
                      fullWidth
                      placeholder="VP Marketing"
                      value={buyer}
                      onChange={(e: any) => setBuyer(e.target.value)}
                    />
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInputLabel label="Priorities" />
                    {priorities.map((priority, index) => {
                      return (
                        <Grid
                          container
                          direction="row"
                          key={index}
                          className={clsx(
                            classes.section,
                            classes.sitesContainer
                          )}
                          style={{ marginBottom: space.MEDIUM }}
                        >
                          <Grid item xs={11}>
                            <StyledInput
                              fullWidth
                              autoFocus={index !== 0}
                              size="large"
                              value={priority}
                              placeholder="Understand ROI of marketing dollars spend"
                              onChange={(event: any) => {
                                let newPriorities = [...priorities];
                                newPriorities[index] = event.target.value;
                                setPriorities(newPriorities);
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={1}
                            style={{ paddingLeft: space.SMALL }}
                          >
                            <Tooltip title="Remove Priority">
                              <IconButton
                                onClick={() => {
                                  let newPriorities = [...priorities];
                                  newPriorities.splice(index, 1);
                                  setPriorities(newPriorities);
                                }}
                                className={classes.removeIcon}
                                size="large"
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <div style={{ marginTop: 10 }}>
                      <StyledButton
                        variant="textprimary"
                        onClick={() => {
                          setPriorities([...priorities, ""]);
                        }}
                        startIcon={
                          <AddCircleIcon className={classes.buttonIcon} />
                        }
                      >
                        Add Priority
                      </StyledButton>
                    </div>
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInputLabel label="Features" />

                    <StyledAutocomplete
                      value={buyerFeatures}
                      onChange={onBuyerFeatureChange}
                      options={features.map((feature: any) => {
                        return { userId: feature._id, name: feature.name };
                      })}
                      isCheckboxSelected={isFeatureSelected}
                      showAvatar={false}
                    />
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInputLabel
                      label="Buyer Titles"
                      helperText="If LinkedIn titles contain these words, we'll consider them to be in your ICP"
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        marginBottom: space.MEDIUM
                      }}
                    >
                      <div>
                        <StyledInput
                          type="text"
                          size="small"
                          name="baseBuyerTitle"
                          label="Base Title"
                          placeholder="Account Evangelist"
                          value={baseBuyerTitle}
                          onChange={(e: any) =>
                            setBaseBuyerTitle(e.target.value)
                          }
                        />
                      </div>
                      <StyledButton
                        variant="primary"
                        size="small"
                        disabled={!baseBuyerTitle}
                        onClick={() => suggestBuyerTitles()}
                      >
                        {loadingBuyerTitle && (
                          <CircularProgress
                            size={16}
                            color="inherit"
                            style={{ marginRight: space.XS }}
                          />
                        )}
                        {loadingBuyerTitle
                          ? "Generating..."
                          : "Generate Titles"}
                      </StyledButton>
                    </div>

                    <TagsInput
                      value={buyerTitles}
                      name="buyerTitles"
                      size="large"
                      suggestions={[]}
                      className={classes.width100}
                      addTag={(event: any, newValue: string) => {
                        setBuyerTitles([...buyerTitles, newValue]);
                      }}
                      handleChange={(event: any, newValues: string[]) => {
                        setBuyerTitles(newValues);
                      }}
                      capitalNotAllowed={true}
                    />
                  </div>
                </div>
              }
              successButtonName={selectedPersonaId ? "Update" : "Save"}
              successCallback={saveOrUpdatePersona}
              cancelCallback={closeAddEditPersonaDialog}
            />
          )}
          {showAddEditFeatureDialog && (
            <StyledDialog
              open={showAddEditFeatureDialog}
              title={
                selectedFeatureId ? "Update a Feature" : "Create a Feature"
              }
              body={
                <div style={{ padding: `0 ${space.MEDIUM}` }}>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInput
                      autoFocus
                      type="text"
                      size="large"
                      name="name"
                      label="Name"
                      fullWidth
                      placeholder="SEO Optimizer"
                      value={name}
                      onChange={(e: any) => setName(e.target.value)}
                    />
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInput
                      type="text"
                      size="large"
                      name="problem"
                      label="Problem"
                      fullWidth
                      placeholder="Unsure how to adapt to Google SGE and AI"
                      value={problem}
                      onChange={(e: any) => setProblem(e.target.value)}
                    />
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInput
                      type="text"
                      size="large"
                      name="uniqueness"
                      label="Uniqueness"
                      fullWidth
                      placeholder="Tools like Clearscope do not help you understand information and stop analysis at keyword level"
                      value={uniqueness}
                      onChange={(e: any) => setUniqueness(e.target.value)}
                    />
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInput
                      type="text"
                      size="large"
                      name="benefit"
                      label="Benefit"
                      fullWidth
                      placeholder="Helps you understand information gaps between you and competing pages"
                      value={benefit}
                      onChange={(e: any) => setBenefit(e.target.value)}
                    />
                  </div>
                </div>
              }
              successButtonName={selectedFeatureId ? "Update" : "Save"}
              successCallback={saveOrUpdateFeature}
              cancelCallback={closeAddEditFeatureDialog}
            />
          )}
        </div>

        <DemoVideoDialog location={location} />
      </Container>
    </div>
  );
}

export default withRouter(withStyles(styles)(AISettings));
