// @ts-nocheck
import React, { useState } from "react";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import { Typography, Grid } from "@mui/material";
import { designColors } from "../Themes/Colors";
import API from "../Services/Api";
import { notify } from "./CustomNotifications";

const HashnodeConnect = ({
  hashnodeTokenDetail,
  publicationId,
  onClose,
  onSave
}: any) => {
  const api = API.create();
  const [apiKey, setApiKey] = useState(
    hashnodeTokenDetail?.integrationToken
      ? hashnodeTokenDetail.integrationToken
      : null
  );
  const [username, setUsername] = useState(
    hashnodeTokenDetail?.username ? hashnodeTokenDetail.username : null
  );

  const [host, setHost] = useState(hashnodeTokenDetail?.host || null);

  const tokenInput = () => {
    return (
      <>
        <Typography
          variant="bodym"
          style={{ color: designColors.grayScale[60] }}
        >
          Go to your Hashnode Developer settings and copy or generate a new
          token, then paste it here
        </Typography>

        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Typography variant="h200">Your token</Typography>

          <StyledInput
            type="text"
            size="medium"
            fullWidth
            value={apiKey}
            placeholder="4a3f94d9-37b6-40b0-a222-3a3b8149b756"
            style={{ marginTop: 8 }}
            name="integration_token"
            onChange={(e: any) => setApiKey(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Typography variant="h200">Username</Typography>

          <StyledInput
            type="text"
            size="medium"
            fullWidth
            value={username}
            placeholder="letterdrop"
            style={{ marginTop: 8 }}
            name="Username"
            onChange={(e: any) => setUsername(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Typography variant="h200">Host</Typography>

          <StyledInput
            type="text"
            size="medium"
            fullWidth
            value={host}
            placeholder="acme.hashnode.dev"
            style={{ marginTop: 8 }}
            name="Host"
            onChange={(e: any) => setHost(e.target.value)}
          />
        </Grid>
      </>
    );
  };

  function saveAndClose() {
    api.saveHashnodeDetails(
      publicationId,
      apiKey,
      username,
      host,
      (res: any) => {
        if (res.status === 200) {
          onSave();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Oops something went wrong",
            "error"
          );
        }
      }
    );
  }

  return (
    <StyledDialog
      open={true}
      maxWidth="md"
      body={tokenInput()}
      title={<Typography variant="h400">Connecting to Hashnode</Typography>}
      successButtonName="Save"
      successCallback={saveAndClose}
      cancelCallback={onClose}
    />
  );
};

export default HashnodeConnect;
