// @ts-nocheck
import { Grid, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import "firebase/auth";
import React, { Suspense } from "react";

import Loading from "./Loading";

import styles from "../Components/styles/PublicationRateGraphStyle";

import OpenRateCardInfo from "../Components/OpenRateCardInfo";

import LineGraph from "./LineGraph";
import { formatNumber } from "../Utils/UserUtils";
import { designColors } from "../Themes/Colors";

import styled from "styled-components";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const StyledSVG = styled.svg`
  & path {
    fill: ${(props: any) => props.color};
  }
`;

const Page404 = React.lazy(() => import("../Containers/Page404"));
const SEARCH_TRAFFIC =
  "<b>Search Traffic</b> is the number of organic monthly visitors to this domain from Google searches, ie. people who search for something and end up on this website.";

class SearchTrafficGraph extends React.Component {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      openInfoCard: false,
      message: ""
    };
  }

  getLatestValue = (graphDataDetails: any) => {
    if (graphDataDetails?.length) {
      return graphDataDetails[graphDataDetails.length - 1].organic;
    }
    return;
  };

  CustomTooltip = ({ active, payload }: any) => {
    let { classes } = this.props;

    if (active && payload && payload[0] && payload[0].payload) {
      let organic = payload[0].payload.organic;
      let date = payload[0].payload.xaxis;
      let paid = payload[0].payload.paid;
      if (!date) {
        return "";
      }
      return (
        <Paper className={classes.tooltipPaper}>
          <Typography className={classes.tooltipLabel}>
            {`Organic traffic on ${date} is ${formatNumber(
              organic
            )}\nPaid traffic on ${date} is ${formatNumber(paid)} `}
          </Typography>
        </Paper>
      );
    }

    return null;
  };

  render() {
    let { classes, heading, graphDataDetails } = this.props;

    let { loading, error, openInfoCard, message } = this.state;
    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage="Looks like you are lost" />
        </Suspense>
      );
    }

    return (
      <div style={{ width: "100%" }}>
        <Grid container direction="column">
          <Typography variant="h300">
            {heading}

            <StyledSVG
              width="16"
              height="16"
              viewBox="0 0 16 16"
              color={designColors.grayScale[60]}
              className={classes.infoIcon}
              onClick={(event: any) => {
                event.preventDefault();
                this.setState({
                  openInfoCard: true,
                  message: SEARCH_TRAFFIC
                });
              }}
            >
              <InfoOutlinedIcon />
            </StyledSVG>
          </Typography>

          <Typography variant="h600" className={classes.countLabel}>
            {formatNumber(this.getLatestValue(graphDataDetails))}
          </Typography>

          <LineGraph
            graphData={graphDataDetails}
            dataKey="organic"
            comparisonDataKey="paid"
            tooltipData={this.CustomTooltip}
          />
        </Grid>

        <OpenRateCardInfo
          showPopup={openInfoCard}
          closeDialog={() => {
            this.setState({ openInfoCard: false });
          }}
          showDialogHeader={false}
          message={message}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SearchTrafficGraph);
