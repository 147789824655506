// @ts-nocheck
import { Tab, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import { designColors } from "../../Themes/Colors";

const exposureAndSize = {
  high: {
    large: "h300",
    medium: "h200"
  },
  low: {
    large: "bodyl",
    medium: "bodym"
  }
};
const iconSize = {
  large: 24,
  medium: 20
};

const borderSize = {
  high: 2,
  low: 1
};

function StyledTab({ tabs, size, exposure, indicatorColor, ...others }: any) {
  return (
    <Tabs
      sx={{
        "&.MuiTabs-root": {
          "& .MuiTab-root": {
            marginRight: "20px",

            minHeight: "0px",

            marginBottom: "-1px",

            typography: `${exposureAndSize[exposure][size]}`,

            color: designColors.grayScale[100],

            borderBottom: `${borderSize[exposure]}px solid ${designColors.grayScale[40]}`,

            padding: "0px 16px 15px 16px",
            "&:hover": {
              color: designColors.primary[50],

              borderBottom: `${borderSize[exposure]}px solid #4160ff80`,
              "& .MuiTab-iconWrapper": {
                color: designColors.grayScale[100],

                background: designColors.primary[0]
              }
            },
            "&.Mui-selected": {
              color: designColors.primary[50],

              borderBottom: `${borderSize[exposure]}px solid ${designColors.primary[50]}`,

              pointerEvents: "none",
              "& .MuiTab-iconWrapper": {
                color: designColors.white,

                background: designColors.primary[50]
              }
            },
            "& .MuiSvgIcon-root": {
              fontSize: iconSize[size]
            },
            "& .MuiTab-iconWrapper": {
              background: designColors.grayScale[5],

              padding: "4px 8px",

              borderRadius: "6px",

              typography: "bodys",

              color: designColors.grayScale[100]
            },
            "&.Mui-disabled": {
              color: designColors.grayScale[60]
            }
          },
          ".MuiTabs-indicator": {
            display: "none"
          },
          "& .MuiTabs-scrollButtons": {
            display: "flex",

            alignItems: "baseline"
          }
        }
      }}
      TabIndicatorProps={{
        style: {
          backgroundColor: indicatorColor
        }
      }}
      {...others}
    >
      {tabs.map((tab: any, index: any) => {
        return (
          <Tab
            disableRipple={true}
            key={index}
            value={tab.value}
            label={tab.label}
            disabled={tab.disabled}
            icon={
              tab.icon
                ? tab.icon
                : (tab.count || tab.count === 0) && (
                    <Typography>{tab.count}</Typography>
                  )
            }
            iconPosition={
              tab.iconPosition ? tab.iconPosition : tab.icon ? "start" : "end"
            }
          />
        );
      })}
    </Tabs>
  );
}

StyledTab.propTypes = {
  exposure: PropTypes.oneOf(["high", "low"]),
  size: PropTypes.oneOf(["medium", "large"])
};

StyledTab.defaultProps = {
  exposure: "high",
  size: "medium"
};

export default StyledTab;
