// @ts-nocheck
import colors from "../../Themes/Colors";
import { red, deepPurple, lightGreen } from "@mui/material/colors";
import { grey } from "@mui/material/colors";

const styles = (theme: any) => ({
  container: {
    padding: 0,
    paddingTop: 24
  },

  margin10: {
    margin: 10
  },

  width100: {
    width: "100%"
  },

  headerStyles: {
    height: "10%",
    [theme.breakpoints.up("xs")]: {
      padding: 10,
      paddingLeft: 25,
      marginTop: 10
    }
  },

  button: {
    color: colors.white
  },

  marginTop20: {
    marginTop: 20
  },

  marginTop12: {
    [theme.breakpoints.down("md")]: {
      marginTop: 12,
      marginBottom: 10
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 12
    }
  },

  marginRight40: {
    [theme.breakpoints.down("md")]: {
      marginRight: 0
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: 40
    }
  },

  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    width: "100%",
    color: colors.fontInput,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontInput
    }
  },

  logoImage: {
    width: 150,
    height: 150
  },

  saveGrid: {
    [theme.breakpoints.down("md")]: {
      marginTop: 10
    },
    margin: "auto auto"
  },

  editTemplateButton: {
    paddingLeft: 15
  },

  editor: {
    height: 500,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%"
    }
  },

  table: {
    border: "1px solid whitesmoke",
    borderRadius: 5
  },

  tableHeader: {
    borderBottom: "none"
  },

  subscriberTableCell: {
    borderBottom: "none",
    wordbreak: "break-word"
  },

  helpLink: {
    color: colors.primary,
    cursor: "pointer"
  },

  failedIcon: {
    marginLeft: 15,
    color: red[500]
  },

  checkIcon: {
    marginLeft: 15,
    color: lightGreen["A700"]
  },

  inProgressIcon: {
    marginLeft: 15,
    color: deepPurple[400]
  },

  subtext: {
    color: colors.fontSecondary,
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 3
  },

  marginAuto: {
    margin: "auto 0"
  },

  bold: {
    fontWeight: "bold"
  },

  overflowHidden: {
    overflow: "hidden",
    wordBreak: "break-word",
    textOverflow: "ellipsis",
    cursor: "pointer",
    userSelect: "all",
    padding: 10,
    "&:hover": {
      backgroundColor: grey[50],
      borderRadius: 6
    }
  },

  paidOnly: {
    color: colors.primary,
    marginRight: 25,
    alignItems: "center",
    display: "flex"
  },

  adjacentButton: {
    marginLeft: 10
  },

  subtitle: {
    color: colors.subtitle,
    marginBottom: 10,
    marginTop: 5
  },

  marginBottom20: {
    marginBottom: 20
  }
});
export default styles;
