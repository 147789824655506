// @ts-nocheck
import colors from "../../Themes/Colors";
const styles = (theme: any) => ({
  container: {
  padding: 0
},

  cardTitle: {
    marginTop: 10,
    fontWeight: "bold",
    fontSize: 20
  },

  cardSubtitle: {
    color: colors.fontSecondary
  },

  marginTop20: {
    marginTop: 20
  }
});

export default styles;
