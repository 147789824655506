// @ts-nocheck
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React from "react";

/**
 *
 * @param {*} headerArray //[{ value: "Position" }, { value: "Keyword" }]
 * @returns
 */
export default function StyledTable({
  headerArray,
  splitColumnHeader,
  bodyArray,
  tableWidth,
  groupColumnHeader,
  tableContainerProps = {}
}: any) {
  return (
    <TableContainer {...tableContainerProps}>
      <Table
        style={{
          width: tableWidth
        }}
      >
        <TableHead>
          {groupColumnHeader && (
            <TableRow>
              <TableCell align="center" colSpan={headerArray.length}>
                {groupColumnHeader}
              </TableCell>
            </TableRow>
          )}

          {splitColumnHeader && (
            <TableRow>
              {splitColumnHeader.map((header: any) => {
                return (
                  <TableCell
                    align={header.headerAlignment}
                    style={header.style}
                    colSpan={header.colSpan}
                  >
                    {header.value}
                  </TableCell>
                );
              })}
            </TableRow>
          )}

          <TableRow>
            {headerArray?.map((header: any) => {
              return (
                <TableCell align={header.headerAlignment} style={header.style}>
                  {header.value}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {bodyArray?.map((row: any) => (
            <TableRow
              key={row.id}
              onClick={() => {
                row.onClick?.();
              }}
              sx={{
                cursor: row.onClick ? "pointer" : "default"
              }}
              {...(row?.tableRowProps || {})}
            >
              {row.data.map((cellData: any) => {
                return (
                  <TableCell
                    align={cellData.cellAlignment}
                    style={cellData.style}
                    colspan={cellData.colspan}
                  >
                    {cellData.value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
