// @ts-nocheck
import colors, { designColors } from "../../Themes/Colors";

const styles = (theme: any) => ({
  item: {
    padding: "10px",
    margin: 0,
    "&:not(:last-child)": {
      borderBottom: `2px solid ${designColors.grayScale[5]}`
    },
    "&:last-child": {
      marginBottom: 6
    },
    cursor: "pointer",
    "&:hover": {
      background: colors.buttonHoverBG
    }
  },

  itemDate: {
    color: colors.fontSecondary
  },

  titleContainer: {
    position: "sticky",
    top: 0,
    backgroundColor: colors.white,
    zIndex: 2,
    padding: 15,
    borderBottom: `2px solid ${designColors.grayScale[5]}`
  },

  readButton: {
    marginTop: 5
  },

  emptyContainer: {
    padding: 20,
    textAlign: "center"
  }
});

export default styles;
