import React, { useState } from "react";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import { Typography, Grid, Box, Tooltip, IconButton } from "@mui/material";
import API from "../Services/Api";
import { space } from "../Config/theme";
import { notify } from "./CustomNotifications";
import { ContentCopy } from "@mui/icons-material";

const api = API.create();

type Props = {
  apolloApiKey: string;
  publicationId: string;
  onClose: () => void;
  onSave: () => void;
};

const ZoomInfoConnect: React.FC<Props> = ({ publicationId, onClose }) => {
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");
  const code = `
<script>
  if (!window._zi_ws) {
    window._zi_ws = {};
  }
  window._zi_ws.wsVisitorInfo = function (visitorInfo) {
    fetch("https://us-central1-zmtvapp.cloudfunctions.net/track-zi-websights", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        publicationId: "${publicationId}",
        url: window.location.href,
        visitorInfo: visitorInfo
      })
    });
  };
</script>
  `.trim();

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setTooltipText("Copied!");
    setTimeout(() => setTooltipText("Copy to clipboard"), 1500);
  };

  return (
    <StyledDialog
      open={true}
      maxWidth="sm"
      title={<Typography variant="h400">Connecting to ZoomInfo</Typography>}
      successButtonName={"Save"}
      cancelCallback={onClose}
      body={
        <>
          <Typography variant="bodym" component="p">
            1. Install ZoomInfo Script. refer{" "}
            <a
              href="https://tech-docs.zoominfo.com/zoominfo-script-implementation-guide.pdf"
              target="_blank"
              rel="noreferrer"
            >
              ZoomInfo Script Implementation Guide
            </a>
          </Typography>
          <Typography variant="bodym" component="p">
            2. Enable and Verify WebSights
          </Typography>
          <Typography variant="bodym" component="p">
            3. Copy the following code and paste it into your website's HTML to
            start tracking your website visitors.
          </Typography>
          <Box
            sx={{
              position: "relative",
              p: space.MEDIUM,
              mt: space.MEDIUM,
              borderRadius: "4px",
              bgcolor: "#f5f5f5",
              border: "1px solid #ddd"
            }}
          >
            <Typography
              component="pre"
              sx={{
                fontFamily: "monospace",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                m: 0,
                fontSize: "11px"
              }}
            >
              <code>{code}</code>
            </Typography>
            <Tooltip title={tooltipText} arrow>
              <IconButton
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8
                }}
                onClick={handleCopy}
              >
                <ContentCopy />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      }
    />
  );
};

export default ZoomInfoConnect;
