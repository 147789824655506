// @ts-nocheck
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  tableContainer: {
    margin: 0
  },

  tableCell: {
    verticalAlign: "baseline"
  },

  name: {
    marginLeft: 10
  },

  autoCompleteAvatar: {
    width: 20,
    height: 20
  },

  optional: {
    fontSize: 8,
    color: colors.fontSecondary,
    lineHeight: 0.5
  },

  mandatory: {
    color: colors.failureRed
  },

  verticalSeparator: {
    borderRight: "1px solid #BEBEC5",
    verticalAlign: "baseline"
  },

  headerCell: {
    minWidth: 250
  },

  headerCellNoBackGround: {
    minWidth: 250,
    "&:hover": {
      background: colors.white
    }
  },

  noBackground: {
    "&:hover": {
      background: colors.white
    }
  }
});

export default styles;
