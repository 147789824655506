// @ts-nocheck
import React, { Component } from "react";
import clsx from "clsx";
import {
  Avatar,
  colors,
  Divider,
  IconButton,
  Popover,
  Tooltip,
  Typography
} from "@mui/material";
import styles from "./styles/TopicCommentsStyle";
import { AvatarSize, space } from "../Config/theme";
import moment from "moment";
import { withStyles } from "@mui/styles";

import ViewFileAttachment from "./ViewFileAttachment";
import linkifyHtml from "linkify-html";
import { decode } from "html-entities";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { ReactComponent as CheckMarkFillIcon } from "../Images/icon24/check-mark-fill.svg";

import { ReactComponent as CheckMarkOutlineIcon } from "../Images/icon24/check-mark-outline.svg";

import { ReactComponent as TrashIcon } from "../Images/icon24/trash.svg";
import EditIcon from "@mui/icons-material/Edit";

import StyledDialog from "../design/components/StyledDialog";

import CommentsInput from "./CommentsInput";
import Api from "../Services/Api";
import CollaboratorApi from "../Services/CollaboratorCommentsAPI";
import { notify } from "./CustomNotifications";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { commentStatus } from "../Utils/Types";

import StyledChip from "../design/components/StyledChip";
import {
  getLoomConfigButton,
  replaceLoomUrlWithEmbed
} from "../Utils/LoomUtils";
import { designColors } from "../Themes/Colors";
import { getItemFromLocalStorage } from "../Utils/StorageHelper";

import { ANONYMOUS, ANONYMOUS_CLIENT_OBJECT } from "../Containers/Ideas";
const api = Api.create();
const collaboratorApi = CollaboratorApi.create();

class Comment extends Component {
  closeMoreOptions: any;
  editorRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      showMoreOptions: false,
      anchorEl: null,
      editMode: false,
      buttonId: `${props.commentId}_loom`,
      configureButton: null,
      loomInitialized: false,
      commentHTML: this.getCommentHtml(props.comment || "", props.isEdited)
    };
    this.editorRef = React.createRef();
  }

  getCommentHtml = (comment = "", isEdited: any) => {
    // Decoded comment passed to Linkify (Encoded during save by QuillDeltaToHtmlConverter)
    let commentHTML = linkifyHtml(decode(comment), {
      rel: "noopener noreferrer",
      target: "_blank"
    });

    return isEdited
      ? `${commentHTML}<span style='color:grey;font-size:12px'>(edited)</span>`
      : commentHTML;
  };

  componentDidMount = () => {
    this.embedLoomVideo();
  };

  embedLoomVideo = async () => {
    let { comment, isEdited } = this.props;
    let commentHTML = this.getCommentHtml(
      await replaceLoomUrlWithEmbed(comment),
      isEdited
    );
    this.setState({
      commentHTML
    });
  };

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.lastSelfCommentId !== this.props.lastSelfCommentId ||
      prevProps.enableEdit !== this.props.enableEdit
    ) {
      let { lastSelfCommentId, enableEdit, commentId } = this.props;

      if (lastSelfCommentId === commentId && enableEdit) {
        this.setState(
          {
            commentId: lastSelfCommentId,
            showMoreOptions: false,
            editMode: true
          },
          () => {
            this.getLoomJWS();
          }
        );
      }
    }

    if (prevProps.comment !== this.props.comment) {
      this.embedLoomVideo();
    }
  }

  calculateFromNow(date: any) {
    return moment(date).fromNow();
  }

  openMoreOptions = (event: any, index: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      showMoreOptions: true,
      anchorEl: event ? event.currentTarget : null
    });
  };

  drawButtonsGrid = (commentId: any) => {
    let { classes } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        }}
      >
        <IconButton
          className={classes.menuItem}
          onClick={() => {
            this.editComment(commentId);
          }}
          style={{
            display: "flex",
            justifyContent: "flex-start"
          }}
        >
          <EditIcon className={classes.menuIcon} />

          <Typography className={classes.menuText}>Edit</Typography>
        </IconButton>

        <IconButton
          className={classes.menuItem}
          onClick={(event) => this.deleteConfirmation(commentId)}
        >
          <TrashIcon className={classes.menuIcon} />

          <Typography className={classes.menuText}>Delete</Typography>
        </IconButton>
      </div>
    );
  };

  /**
   *  LOOM FUNCTION - START
   */
  getLoomJWS = async () => {
    let { postId } = this.props;
    let configureButton = await getLoomConfigButton(postId);

    if (!configureButton) {
      this.setState({
        configureButton: null,
        loomInitialized: false
      });
      return;
    }

    this.setState({ configureButton }, this.initializeLoom);
  };

  initializeLoom = async () => {
    let { configureButton, loomId } = this.props;
    if (!configureButton) {
      this.setState({
        loomInitialized: false
      });
      return;
    }

    const button = document.getElementById(this.state.buttonId);
    if (!button) {
      this.setState({
        loomInitialized: false
      });
      return;
    }

    const sdkButton = configureButton({ element: button });
    this.setState({ loomInitialized: true });

    sdkButton.on("insert-click", async (video: any) => {
      this.setState({
        editMode: false
      });
      if (loomId === "collaborator_loom_button") {
        collaboratorApi.updateComment(
          this.props.commentId,
          video.sharedUrl,
          [],
          null,
          (res: any) => {
            if (res.status === 200) {
              try {
                this.props.load(true);
              } catch (error) {}
            } else {
              this.updateCommentFailureCallback(video.sharedUrl);
            }
          }
        );
        return;
      }
      api.updateComment(
        this.props.commentId,
        video.sharedUrl,
        [],
        null,
        (res: any) => {
          if (res.status === 200) {
            try {
              this.props.load(true);
            } catch (error) {}
          } else {
            this.updateCommentFailureCallback(video.sharedUrl);
          }
        }
      );
    });
  };

  updateCommentFailureCallback = (sharedUrl: any) => {
    if (navigator?.clipboard?.writeText) {
      try {
        navigator.clipboard.writeText(sharedUrl);
        notify.show(
          "We were unable to embed your video. We copied the link to your video to the clipboard",
          "error"
        );
        return;
      } catch (error) {}
    }
    notify.show("We were unable to embed your video", "error");
  };

  editComment = (commentId: any) => {
    this.setState(
      {
        commentId: commentId,
        showMoreOptions: false,
        editMode: true
      },
      this.getLoomJWS
    );
  };

  updateComment = (comment: any, files: any, status: any) => {
    let { commentId, loomId, enableEdit } = this.props;
    if (!comment) {
      notify.show("Type in your comment", "error");
      return;
    }
    this.setState({
      editMode: status === commentStatus.DRAFT
    });
    if (enableEdit) {
      this.props.toggleCommentEditMode?.();
    }
    if (loomId === "collaborator_loom_button") {
      collaboratorApi.updateComment(
        commentId,
        comment,
        files,
        status,
        (res: any) => {
          if (res.status === 200) {
            if (status !== commentStatus.DRAFT) {
              this.editorRef?.current?.clearText?.();

              this.props.load(true);
            }
          } else {
            notify.show(
              res.status === 400
                ? res.data
                : "Couldn't save your comment. Please try again.",
              "error"
            );
          }
        }
      );
      return;
    }

    api.updateComment(commentId, comment, files, status, (res: any) => {
      if (res.status === 200) {
        if (status !== commentStatus.DRAFT) {
          this.props.load(true);
        }
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Couldn't save your comment. Please try again.",
          "error"
        );
      }
    });
  };

  deleteConfirmation = (commentId: any) => {
    this.setState({
      showDialog: true,
      commentId: commentId,
      showMoreOptions: false
    });
  };

  deleteComment = () => {
    let { commentId } = this.state;

    let { loomId } = this.props;
    this.setState({
      showDialog: false
    });
    if (loomId === "collaborator_loom_button") {
      collaboratorApi.deleteComment(commentId, (res: any) => {
        if (res.status === 200) {
          this.props.load(true);
        }
      });
      return;
    }
    api.deleteComment(commentId, (res: any) => {
      if (res.status === 200) {
        this.props.load(true);
      }
    });
  };

  render() {
    let {
      client,

      draftText,

      commentedOn,

      classes,

      files,

      currentClient,

      teammates,

      commentId,

      isDeleted,

      isParent,

      isAllComments,

      status,

      markCommentActive,

      resolveComment,

      isResolved,

      openComment,

      anonymousClient,

      replies
    } = this.props;
    let {
      showMoreOptions,

      anchorEl,

      showDialog,

      editMode,

      commentHTML,

      loomInitialized,

      buttonId
    } = this.state;

    if (isDeleted && !isParent) {
      return "";
    }

    return (
      <>
        <div
          className={
            isAllComments
              ? clsx(classes.commentViewSection, classes.commentHover)
              : classes.commentViewSection
          }
          style={{
            paddingLeft: isAllComments ? space.LARGE : 20,
            paddingRight: isAllComments ? space.LARGE : 20
          }}
        >
          {editMode && (
            <div>
              <CommentsInput
                ref={this.editorRef}
                comment={draftText}
                teammates={teammates}
                loomButtonId={buttonId}
                loomInitialized={loomInitialized}
                onAddComment={this.updateComment}
                files={files}
                autoFocus={true}
              />
            </div>
          )}
          {!editMode && !isDeleted && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    sx={{ ...AvatarSize.sm }}
                    src={client?.profilePic || ""}
                    style={{ opacity: isResolved && isAllComments ? 0.5 : 1 }}
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: space.SMALL
                    }}
                  >
                    <Typography variant="h200">
                      {client?.name ||
                        client?.email ||
                        anonymousClient?.name ||
                        anonymousClient?.email ||
                        ANONYMOUS}
                    </Typography>

                    <Typography variant="bodys">
                      {this.calculateFromNow(commentedOn)}
                    </Typography>
                  </div>
                </div>

                <div>
                  {isAllComments && status !== commentStatus.DRAFT && (
                    <Tooltip title={isResolved ? "Reopen" : "Resolve"}>
                      <IconButton
                        onClick={() => {
                          if (isResolved) {
                            markCommentActive(commentId);
                            return;
                          }
                          resolveComment(commentId);
                        }}
                      >
                        {isResolved ? (
                          <CheckMarkFillIcon />
                        ) : (
                          <CheckMarkOutlineIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}

                  {isAllComments && status === commentStatus.DRAFT && (
                    <StyledChip
                      size="small"
                      label="Draft"
                      style={{ marginLeft: space.MEDIUM }}
                    />
                  )}

                  {!isAllComments && currentClient === client?._id && (
                    <IconButton
                      onClick={(event) =>
                        this.openMoreOptions(event, commentId)
                      }
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )}
                </div>
              </div>

              <div
                style={{
                  opacity: isResolved && isAllComments ? 0.5 : 1,
                  marginLeft: !isAllComments ? "46px" : 0
                }}
                onClick={() => openComment?.(commentId, isResolved)}
              >
                <Typography
                  variant="bodym"
                  style={{ color: designColors.grayScale[100] }}
                >
                  <div
                    className={classes.commentText}
                    dangerouslySetInnerHTML={{
                      __html: commentHTML
                    }}
                  />
                </Typography>

                {Boolean(files?.length || isAllComments) && (
                  <div>
                    {Boolean(files?.length) && (
                      <ViewFileAttachment files={files} />
                    )}
                    {Boolean(replies?.length && isAllComments) && (
                      <Typography
                        variant="bodym"
                        style={{
                          color: designColors.grayScale[60],
                          display: "flex",
                          paddingTop: space.SMALL
                        }}
                      >
                        {`${replies.length} ${
                          replies.length === 1 ? "Reply" : "Replies"
                        }`}
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            </>
          )}

          {!editMode && isParent && isDeleted && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: space.MEDIUM
              }}
            >
              <DeleteOutlineOutlined
                style={{ margin: "0px 5px", color: colors.grey[500] }}
              />

              <Typography xs={12} variant="bodym" style={{ marginLeft: 5 }}>
                This message was deleted.
              </Typography>
            </div>
          )}

          <Popover
            id={"moreOption_" + commentId}
            open={showMoreOptions}
            anchorEl={anchorEl}
            onClose={this.closeMoreOptions}
            onBlur={() =>
              this.setState({
                showMoreOptions: false
              })
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            PaperProps={{
              className: classes.popover
            }}
          >
            {this.drawButtonsGrid(commentId)}
          </Popover>

          <StyledDialog
            open={showDialog}
            title="Are you sure?"
            body="Once deleted, you can't recover your comment"
            successButtonName="Yes, I'm sure "
            cancelButtonName="Cancel"
            successCallback={this.deleteComment}
            cancelCallback={() => {
              this.setState({
                showDialog: false,
                errorMessage: "",
                errorTitle: ""
              });
            }}
          />
        </div>
        {isAllComments && (
          <Divider
            style={{ marginLeft: space.LARGE, marginRight: space.LARGE }}
          />
        )}
      </>
    );
  }
}

export default withStyles(styles)(Comment);
