// @ts-nocheck
const styles = (theme: any) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap"
  },

  fileContainer: {
    position: "relative",
    width: 50,
    margin: 10,
    marginBottom: 0,
    borderRadius: 5
  },

  imageLink: {
    margin: 10,
    marginLeft: 0,
    height: 50
  },

  fileName: {
    textOverflow: "clip",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },

  imageStyle: {
    borderRadius: 5
  }
});

export default styles;
