// @ts-nocheck
import { Grid, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import React from "react";

import { withRouter } from "react-router";
import {
  Area,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from "recharts";
import colors, { designColors } from "../Themes/Colors";
import { formatForDisplayDate } from "../Utils/TimeUtils";
import { graphStatsType } from "../Utils/Types";
import { formatNumber } from "../Utils/UserUtils";
import styles from "./styles/LineGraphStyle";

let newCX: any;
let newCY: any;
export const CustomizedDot = (props: any) => {
  const { cx, cy, payload, value } = props;
  newCX = cx;
  newCY = cy;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={cx - 11}
      y={cy - 10}
      viewBox="0 0 18 18"
      width="22"
      height="22"
      fill="none"
      payload={payload}
      value={value}
    >
      <circle cx="9" cy="9" r="4" fill="url(#paint0_linear_467_5)" />

      <circle
        cx="9"
        cy="9"
        r="4"
        stroke="#4E73F8"
        stroke-opacity="0.24"
        stroke-width="5"
      />

      <g filter="url(#filter0_i_467_5)">
        <circle cx="9" cy="9" r="2" fill="white" />
      </g>

      <defs>
        <filter
          id="filter0_i_467_5"
          x="7"
          y="7"
          width="4"
          height="5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />

          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />

          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />

          <feOffset dy="1" />

          <feGaussianBlur stdDeviation="1" />

          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />

          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.24375 0 0 0 0 0.2715 0 0 0 0 0.9375 0 0 0 0.3 0"
          />

          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_467_5"
          />
        </filter>

        <linearGradient
          id="paint0_linear_467_5"
          x1="9"
          y1="5"
          x2="9"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={designColors.primary[50]} />

          <stop offset="1" stop-color={designColors.primary[50]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const CustomCursor = (props: any) => {
  let { width, height, points } = props;

  // hiding cursor for no data axis point
  if (points[1].y === newCY) {
    return null;
  }

  return (
    <g>
      <Line
        stroke={designColors.primary[100]}
        strokeWidth={2}
        width={width}
        height={height}
        points={[
          { x: newCX, y: newCY + 10 },
          { x: points[1].x, y: points[1].y }
        ]}
        strokeDasharray="4 4"
        dot={false}
      />
    </g>
  );
};

class LineGraph extends React.Component {
  container: any;
  graphRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      graphWidth: "100%"
    };
    this.graphRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount() {
    this.graphRef = null;
  }

  onWindowResize = () => {
    if (this.graphRef) {
      this.setState({
        graphWidth: Math.round(this.graphRef.offsetWidth)
      });
    }
  };

  CustomTooltip = ({ active, payload, label }: any) => {
    let { classes } = this.props;
    let currentTooltipText = "";
    let comparTooltipText = "";

    if (active && payload && (payload[0]?.value || payload[0]?.value === 0)) {
      currentTooltipText = `${formatNumber(
        payload[0].value
      )} ${this.getStatsTypeText(
        payload[0].value
      )} between from ${formatForDisplayDate(
        payload[0].payload.datePair.startDate
      )} to ${formatForDisplayDate(payload[0].payload.datePair.endDate)}`;
    }

    if (
      active &&
      payload?.length > 1 &&
      (payload[1]?.value || payload[1]?.value === 0)
    ) {
      comparTooltipText = `${formatNumber(
        payload[1].value
      )} ${this.getStatsTypeText(
        payload[1].value
      )} between from ${formatForDisplayDate(
        this.get30DaysBeforeDate(payload[1].payload.datePair.startDate)
      )} to ${formatForDisplayDate(
        this.get30DaysBeforeDate(payload[1].payload.datePair.endDate)
      )}`;
    }

    if (currentTooltipText) {
      return (
        <Paper className={classes.tooltipPaper}>
          <Typography variant="bodys" className={classes.tooltipLabel}>
            {currentTooltipText}
          </Typography>
          {comparTooltipText && (
            <Typography variant="bodys" className={classes.tooltipLabel}>
              {comparTooltipText}
            </Typography>
          )}
        </Paper>
      );
    }

    return null;
  };

  getStatsTypeText = (value = 0) => {
    let { statsType } = this.props;
    if (statsType === graphStatsType.PAGEVIEWS) {
      return `page view${value === 1 ? "" : "s"}`;
    }
    if (statsType === graphStatsType.EMAIL_OPENS) {
      return `email open${value === 1 ? "" : "s"}`;
    }

    return "";
  };

  // Comparing with 30 days before data
  get30DaysBeforeDate = (date: any) => {
    return moment(date).subtract(30, "days");
  };

  render() {
    let {
      classes,
      graphData,
      XAxisDataKey = "xaxis",
      XAxisIconDataKey = "xaxisicon",
      showIconAxis = false,
      dataKey = "count",
      comparisonDataKey = "compCount",
      tooltipData = this.CustomTooltip
    } = this.props;

    let { graphWidth } = this.state;
    if (graphData && graphData.length > 0) {
      return (
        <div
          container
          direction="column"
          className={classes.lineChart}
          ref={(el) => (this.graphRef = el)}
        >
          <ResponsiveContainer width={graphWidth} height="100%">
            <ComposedChart data={graphData}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="0%"
                    stopColor={designColors.primary[100]}
                    stopOpacity={0.25}
                  />

                  <stop
                    offset="100%"
                    stopColor={designColors.primary[100]}
                    stopOpacity={0.1}
                  />
                </linearGradient>
              </defs>

              <Line
                dot={false}
                layout="vertical"
                type={"monotoneX"}
                strokeWidth={2}
                dataKey={dataKey}
                stroke={designColors.primary[100]}
                fill={designColors.primary[100]}
              />

              <Line
                dot={false}
                layout="vertical"
                type={"monotoneX"}
                strokeWidth={2}
                dataKey={comparisonDataKey}
                strokeDasharray="5 5"
                stroke={designColors.primary[100]}
                fill={designColors.primary[100]}
              />

              <Area
                layout="vertical"
                type={"monotoneX"}
                dataKey={dataKey}
                stroke={designColors.primary[100]}
                strokeWidth={2}
                fill="url(#colorUv)"
                activeDot={<CustomizedDot />}
              />

              <XAxis
                dataKey={XAxisDataKey}
                style={{ fontSize: "12px" }}
                stroke={colors.graphAxisStroke}
                interval={"preserveStartEnd"}
                tickLine={false}
                type="category"
                xAxisId={0}
              />
              {showIconAxis && (
                <XAxis
                  dataKey={XAxisIconDataKey}
                  style={{ fontSize: "12px" }}
                  stroke={colors.graphAxisStroke}
                  interval={0}
                  tickLine={false}
                  axisLine={false}
                  type="category"
                  xAxisId={1}
                  tick={<CustomizedTick />}
                />
              )}

              <Tooltip cursor={<CustomCursor />} content={tooltipData} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      );
    } else {
      return (
        <div
          container
          direction="column"
          ref={(el) => (this.container = el)}
          className={classes.lineChart}
        >
          <ResponsiveContainer width={graphWidth} height="99%">
            <ComposedChart>
              <XAxis
                style={{ fontSize: "12px" }}
                stroke={colors.graphAxisStroke}
                tickLine={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      );
    }
  }
}

function CustomizedTick(props: any) {
  const { x, y, payload } = props;
  if (!payload.value) {
    return null;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={x - 6}
      y={y - 40}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#666"
    >
      <title>{payload.value}</title>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 3L10 9H2L6 3Z"
        fill="#6F767E"
      />
    </svg>
  );
}

export default withRouter(withStyles(styles)(LineGraph));
