// @ts-nocheck
import React, { useState } from "react";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import { Typography, Grid } from "@mui/material";
import { designColors } from "../Themes/Colors";
import { space } from "../Config/theme";
import GoogleApi from "../Services/GoogleSearchConsoleAPI";
import { notify } from "../Components/CustomNotifications";

const GoogleAnalyticsConnect = ({
  googleAnalyticsTokenDetail,
  publicationId,
  onClose,
  onSave
}: any) => {
  //property id consist of 9 digit number
  const propertyIdPattern = new RegExp(/^[\d+]{9}$/);
  const googleApi = GoogleApi.create();
  const [propertyId, setPropertyId] = useState(
    googleAnalyticsTokenDetail?.propertyId || null
  );
  const [helperText, setHelperText] = useState(null);

  const changePropertyId = (event: any) => {
    let propertyId = event.target.value;
    //if regex match no error else set error message
    if (propertyIdPattern.test(propertyId)) {
      setHelperText(null);
    } else {
      setHelperText("Enter a valid Property Id");
    }
    setPropertyId(propertyId);
  };

  const tokenInput = () => {
    return (
      <>
        <Typography
          variant="bodym"
          style={{ color: designColors.grayScale[60] }}
        >
          {
            "In GA4, go to Admin (bottom left) > Property Settings > Copy the Property ID from the top right."
          }
        </Typography>

        <Grid item xs={12} style={{ marginTop: space.MEDIUM }}>
          <Typography variant="h200">Property ID</Typography>

          <StyledInput
            type="text"
            size="medium"
            fullWidth
            value={propertyId}
            placeholder="250074136"
            style={{ marginTop: space.XS }}
            name="integration_token"
            helperText={helperText}
            error={helperText}
            onChange={changePropertyId}
          />
        </Grid>
      </>
    );
  };

  async function saveAndClose() {
    googleApi.savePropertyIdGA(publicationId, propertyId, (res: any) => {
      if (res.status === 200) {
        onSave();
      } else {
        setHelperText(
          res.status === 400
            ? res.data
            : "Something went wrong. Please contact support@letterdrop.com"
        );
      }
    });
  }

  return (
    <StyledDialog
      open={true}
      maxWidth="md"
      body={tokenInput()}
      title={
        <Typography variant="h400">Connecting to Google Analytics</Typography>
      }
      successButtonName={helperText ? "" : "Save"}
      successCallback={saveAndClose}
      cancelCallback={onClose}
    />
  );
};

export default GoogleAnalyticsConnect;
