// @ts-nocheck
import { Autocomplete, Typography } from "@mui/material";

import _ from "lodash";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { notify } from "../Components/CustomNotifications";
import { space } from "../Config/theme";
import Api from "../Services/Api";
import { designColors } from "../Themes/Colors";
import { getShortLocalNumber } from "../Utils/UserUtils";

const api = Api.create();

const header = {
  keyword: "Keywords Suggestion",
  volume: "Volume",
  difficulty: "Difficulty",
  header: true
};

const KeywordAutocomplete = forwardRef(
  (
    {
      postId = null,

      publicationId,

      onInputChange,

      onChange,

      onKeyPress,
      ...props
    },
    ref
  ) => {
    let [open, setOpen] = useState(false);

    const getKeywordSuggestionsDenounce = useRef(
      _.debounce(getKeywordSuggestions, 1000)
    );

    useImperativeHandle(ref, () => ({
      closeSuggestions: () => setOpen(false)
    }));

    const [keywordSuggestions, setKeywordSuggestions] = useState([]);

    function getKeywordSuggestions(value: any) {
      if (!value) {
        setKeywordSuggestions([]);
        return;
      }

      api.getKeywordSuggestions(value, postId, publicationId, (res: any) => {
        if (res.status === 200) {
          if (!res.data?.length) {
            setKeywordSuggestions([]);
            return;
          }

          setKeywordSuggestions([header, ...res.data]);
        } else {
          setKeywordSuggestions([]);
        }
      });
    }

    return (
      <Autocomplete
        {...props}
        freeSolo
        open={open}
        disableClearable
        autoSelect
        onBlur={() => setOpen(false)}
        options={keywordSuggestions}
        onKeyPress={(event) => {
          onKeyPress?.(event);
          if (event.key === "Enter") {
            event.target?.blur?.();
          }
        }}
        onChange={(event, value) => {
          onChange?.(event, value);

          event.target?.blur?.();
          setOpen(false);
        }}
        onInputChange={(event, value, reason) => {
          // Reset means loading already existing value
          if (reason !== "reset") {
            setOpen(true);
          }
          getKeywordSuggestionsDenounce.current(value);
          onInputChange?.(value || "");
        }}
        filterOptions={(options, params) => options}
        getOptionLabel={(option) => {
          return option.keyword || option || "";
        }}
        getOptionDisabled={(option) => option.header}
        renderOption={(props, option, { selected }) => {
          let { header, keyword, volume, difficulty } = option;

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: space.SMALL,
                alignItems: "center",
                opacity: 1
              }}
              {...props}
            >
              <div style={{ width: "60%", display: "flex" }}>
                <Typography
                  variant={header ? "h200" : "bodys"}
                  style={{
                    width: "fit-content",
                    background: header ? "inherit" : designColors.primary[0],
                    borderRadius: 6,
                    padding: `${space.XXS} ${space.XS}`,
                    color: header
                      ? designColors.grayScale[60]
                      : designColors.grayScale[100]
                  }}
                >
                  {keyword}
                </Typography>
              </div>

              <Typography
                variant={header ? "h200" : "bodys"}
                style={{
                  width: "20%",
                  textAlign: "center",
                  color: header
                    ? designColors.grayScale[60]
                    : designColors.grayScale[100]
                }}
              >
                {getShortLocalNumber(volume)}
              </Typography>

              <Typography
                variant={header ? "h200" : "bodys"}
                style={{
                  width: "20%",
                  textAlign: "center",
                  color: header
                    ? designColors.grayScale[60]
                    : designColors.grayScale[100]
                }}
              >
                {difficulty}
              </Typography>
            </div>
          );
        }}
      />
    );
  }
);

export default KeywordAutocomplete;
