// @ts-nocheck
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { withRouter } from "react-router";
import { notify } from "../Components/CustomNotifications";
import API from "../Services/Api";
import { getCurrentBrowserTimezone } from "../Utils/TimeUtils";
import styles from "./styles/TopicCommentBoxStyle";
import { uuid } from "uuidv4";

import CommentsInput from "./CommentsInput";
import { commentStatus } from "../Utils/Types";

const api = API.create();

class TopicCommentBox extends Component {
  editorRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      comment: props.draftComment?.draftDelta || "",
      addCommentInProgress: false,
      buttonId: `${props.topicId}_${uuid()}`,
      loomInitialized: false,
      id: props.draftComment?._id || null
    };
    this.editorRef = React.createRef();
  }

  componentDidMount = () => {
    this.initializeLoom();
  };

  componentDidUpdate = (prevProps: any) => {
    if (prevProps.configureButton !== this.props.configureButton) {
      this.initializeLoom();
    }

    // Update draft comment on draft comment prop change

    if (prevProps.draftComment?._id !== this.props.draftComment?._id) {
      this.setState({
        comment: this.props.draftComment?.draftDelta || "",

        id: this.props.draftComment?._id || null
      });
    }
  };

  initializeLoom = async () => {
    let { configureButton } = this.props;
    if (!configureButton) {
      this.setState({
        loomInitialized: false
      });
      return;
    }

    const button = document.getElementById(this.state.buttonId);
    if (!button) {
      this.setState({
        loomInitialized: false
      });
      return;
    }

    const sdkButton = configureButton({ element: button });
    this.setState({ loomInitialized: true });

    sdkButton.on("insert-click", async (video: any) => {
      api.saveTopicComment(
        this.props.topicId,
        video.sharedUrl,
        [],
        getCurrentBrowserTimezone(),
        commentStatus.PUBLISHED,
        null,

        this.props.anonymousClient,
        (res: any) => {
          if (res.status === 200) {
            try {
              this.props.load();
            } catch (error) {}
          } else {
            if (navigator?.clipboard?.writeText) {
              try {
                navigator.clipboard.writeText(video.sharedUrl);
                notify.show(
                  "We were unable to embed your video. We copied the link to your video to the clipboard",
                  "error"
                );
                return;
              } catch (error) {}
            }
            notify.show("We were unable to embed your video", "error");
          }
        }
      );
    });
  };

  handleChange = (event: any) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  resetComment = (comment = "") => {
    this.setState({
      comment: comment,
      addCommentInProgress: false,
      id: null
    });
  };

  saveComment = (comment: any, files: any, status: any) => {
    let { topicId, anonymousClient = "" } = this.props;

    let { id } = this.state;

    if (!comment) {
      notify.show("Type in your comment", "error");
      return;
    }

    this.setState({
      addCommentInProgress: true
    });

    api.saveTopicComment(
      topicId,
      comment,
      files,
      getCurrentBrowserTimezone(),
      status,
      id,
      anonymousClient,
      (res: any) => {
        if (res.status === 200) {
          if (status === commentStatus.DRAFT) {
            this.setState({
              id: res.data.commentId,
              addCommentInProgress: false
            });
            return;
          }
          this.resetComment();

          this.props.load();
          this.editorRef?.current?.clearText?.();
        }
        if (res.status === 400) {
          notify.show("Couldn't save your comment. Please try again.", "error");
        }
      }
    );
  };

  render() {
    let { classes, teammates } = this.props;

    let { comment, addCommentInProgress, buttonId, loomInitialized } =
      this.state;

    return (
      <div className={classes.container}>
        <CommentsInput
          ref={this.editorRef}
          comment={comment}
          teammates={teammates}
          loomButtonId={buttonId}
          loomInitialized={loomInitialized}
          addCommentInProgress={addCommentInProgress}
          onAddComment={this.saveComment}
          onUpArrow={this.props.onUpArrow}
          autoFocus={false}
          onEditorFocused={this.initializeLoom}
        />
      </div>
    );
  }
}

TopicCommentBox.propTypes = {
  profilePic: PropTypes.string.isRequired,
  topicId: PropTypes.string.isRequired
};

export default withRouter(withStyles(styles)(TopicCommentBox));
