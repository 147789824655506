// @ts-nocheck
import {
  Box,
  CircularProgress,
  Paper,
  Popper,
  Rating,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import styles from "./styles/CompositeSEOScorePopupStyle";

const stages = {
  UNAWARE: "Unaware",
  PROBLEM_AWARE: "Problem Aware",
  SOLUTION_AWARE: "Solution Aware",
  PRODUCT_AWARE: "Product Aware"
};

function CompositeSEOScorePopup({ seoScore, classes, ...props }: any) {
  const scores = [
    {
      name: "Keyword",
      text: seoScore.keyword
    },
    {
      name: "Information Coverage",
      score: seoScore.informationCoverageScore
    },
    {
      name: "Keyword Coverage",
      score: seoScore.keywordCoverageScore
    },
    {
      name: "Technical SEO",
      score: seoScore.technicalSEOScore
    },
    {
      name: "Conversion Likelihood",
      score: seoScore.conversionLikelihoodScore
    },
    {
      name: "Internal Links",
      text: String(seoScore.internalLinksCount.data),
      loading: seoScore.internalLinksCount.loading
    },
    {
      name: "Stage",
      text: seoScore.funnelStage || stages.UNAWARE
    }
  ];

  function getRatingText(score: any) {
    if (score <= 1) {
      return "Low";
    }

    if (score <= 3) {
      return "Medium";
    }

    return "High";
  }

  return (
    <Popper placement="right-start" {...props}>
      <Paper className={classes.root}>
        {scores.map(({ name, score, text, loading }, index) => (
          <Box className={classes.section} key={index}>
            <Typography variant="bodym" className={classes.scoreTitle}>
              {name}
            </Typography>
            {loading ? (
              <Box flexGrow={1} className={classes.funnelStage}>
                <CircularProgress size={24} color="inherit" />
              </Box>
            ) : (
              <>
                {Boolean(score || score === 0) && (
                  <Box className={classes.ratingSection}>
                    <Rating
                      name="read-only"
                      value={score}
                      readOnly
                      className={classes.rating}
                    />

                    <Typography variant="bodym" className={classes.ratingText}>
                      {getRatingText(score)}
                    </Typography>
                  </Box>
                )}
                {text && (
                  <Typography variant="bodym" className={classes.funnelStage}>
                    {text}
                  </Typography>
                )}
              </>
            )}
          </Box>
        ))}
      </Paper>
    </Popper>
  );
}

export default withStyles(styles)(CompositeSEOScorePopup);
