// @ts-nocheck
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  container: {
    padding: 0
  },

  dialogContainer: {
    padding: 0
  },

  input: {
    width: "100%",
    backgroundColor: colors.inputBackground,
    border: "none",
    color: colors.fontInput,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontInput
    }
  },

  paper: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    padding: 30
  },

  width40: {
    width: "40%"
  },

  amountLabel: {
    marginTop: 15,
    marginRight: 15
  },

  toggleLabel: {
    marginTop: 6,
    marginRight: 12
  },

  marginTop10: {
    marginTop: 10
  },

  toggleBlock: {
    marginTop: 20,
    display: "inline-flex"
  },

  height100: {
    height: "100%"
  },

  cardTitle: {
    fontWeight: "bold",
    fontSize: 20
  },

  marginTop20: {
    marginTop: 20
  },

  marginTop12: {
    [theme.breakpoints.down("md")]: {
      marginTop: 12,
      marginBottom: 10
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 12
    }
  },

  width100: {
    width: "100%"
  },

  marginRight40: {
    [theme.breakpoints.down("md")]: {
      marginRight: 0
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: 40
    }
  },

  button: {
    color: colors.white
  },

  logo: {
    width: 150,
    height: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  logoImage: {
    width: 150,
    height: 150
  },

  saveGrid: {
    [theme.breakpoints.down("md")]: {
      marginTop: 10
    },
    margin: "auto auto"
  },

  padding10: {
    padding: 10
  },

  subtext: {
    color: colors.fontSecondary,
    fontSize: 12,
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 3
  },

  domainContainer: {
    display: "flex",
    alignItems: "center",
    color: colors.fontSecondary,
    justifyContent: "flex-start"
  },

  characterCounter: {
    textAlign: "right",
    paddingTop: 10,
    color: colors.fontSecondary
  },

  marginTop15: {
    marginTop: 15
  }
});
export default styles;
