import React, { useState } from "react";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import { Typography, Grid } from "@mui/material";
import API from "../Services/Api";
import { space } from "../Config/theme";
import { notify } from "./CustomNotifications";

const api = API.create();

type Props = {
  clearbitKey: string;
  publicationId: string;
  onClose: () => void;
  onSave: () => void;
};

const ClearbitConnect: React.FC<Props> = ({
  clearbitKey,
  publicationId,
  onClose,
  onSave
}) => {
  const [apiKey, setApiKey] = useState<string>(clearbitKey ? clearbitKey : "");

  const isValidAPIKey = () => {
    return apiKey.startsWith("sk_");
  };

  const tokenInput = () => {
    return (
      <Grid item>
        <Typography variant="bodym">
          You can find your API key in Clearbit under Settings -&gt; Keys and
          Settings. Paste the <strong>Secret API Key</strong> starting with{" "}
          <em>sk_</em> here
        </Typography>
        <br />
        <br />
        <Typography variant="h200">API Key</Typography>

        <StyledInput
          type="text"
          size="medium"
          helperText={isValidAPIKey() ? "" : "Incorrect API Key"}
          error={!isValidAPIKey()}
          fullWidth
          value={apiKey}
          placeholder="sk_b44fe4128138173985ba4fe04d774v23"
          style={{ marginTop: space.XS }}
          name="apiKey"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setApiKey(event.target.value)
          }
        />
      </Grid>
    );
  };

  function saveAndClose() {
    if (!isValidAPIKey()) {
      return;
    }
    api.saveClearbitDetails(publicationId, apiKey, (res: any) => {
      if (res.status === 200) {
        onSave();
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Error while updating Clearbit details.",
          "error"
        );
      }
    });
  }

  return (
    <StyledDialog
      open={true}
      maxWidth="md"
      body={tokenInput()}
      title={<Typography variant="h400">Connecting to Clearbit</Typography>}
      successButtonName={"Save"}
      successCallback={saveAndClose}
      cancelCallback={onClose}
    />
  );
};

export default ClearbitConnect;
