// @ts-nocheck
import {
  Autocomplete,
  Avatar,
  InputAdornment,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import { useState } from "react";
import StyledButton from "../design/components/StyledButton";

import StyledDialog from "../design/components/StyledDialog";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TableRowsIcon from "@mui/icons-material/TableRows";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { designColors } from "../Themes/Colors";

import StyledInput from "../design/components/StyledInput";
import API from "../Services/Api";
import { notify } from "./CustomNotifications";
import StyledUserChip from "../design/components/StyledUserChip";

import StyledCheckbox from "../design/components/StyledCheckbox";

const api = API.create();

const steps = [
  {
    label: "Profile verification",
    description: `Go to your Medium settings and copy or generate a new token, then paste it here`
  },
  {
    label: "Select publications",
    description:
      "Choose your publications that you want to post to from Letterdrop"
  }
];

function MediumConnect({ publicationId, mediumHandle, onClose, onSave }: any) {
  const [activeStep, setActiveStep] = useState(0);
  const [integrationToken, setIntegrationToken] = useState(
    mediumHandle?.integrationToken || null
  );
  const [user, setMediumUser] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const [userAccounts, setUserAccounts] = useState(
    mediumHandle?.accounts || []
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleTokenChange = (event: any) => {
    let { value } = event.target;
    setIntegrationToken(value);
    setMediumUser(null);
  };

  const verifyToken = () => {
    setVerifying(true);
    api.verifyAndGetMediumDetails(
      publicationId,
      integrationToken,
      (res: any) => {
        if (res.status === 200) {
          setMediumUser(res.data);
          setVerifying(false);
        } else {
          notify.show(
            res.status === 400 ? res.data : "Unable to validate Medium token",
            "error"
          );
          setVerifying(false);
        }
      }
    );
  };

  function getIconComponent(index: any, props: any) {
    let { active, completed } = props;
    let icon = "";
    let color = designColors.grayScale[80];
    if (!active && !completed) {
      color = designColors.grayScale[60];
    } else if (completed) {
      color = designColors.primary[100];
    }

    switch (index) {
      case 0:
        icon = <AdminPanelSettingsIcon style={{ color: color }} />;
        break;
      case 1:
        icon = <TableRowsIcon style={{ color: color }} />;
        break;
      default:
        break;
    }
    return icon;
  }

  function checkIsSelected(option: any) {
    let exist = userAccounts.some((account: any) => {
      return option.id + "" === account.id;
    });
    return exist;
  }

  function getStepBody(index: any) {
    let body = "";
    switch (index) {
      case 0:
        body = (
          <div style={{ marginTop: 15 }}>
            <StyledInput
              type="text"
              size="medium"
              fullWidth
              value={integrationToken}
              placeholder="integration token"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {user?.isConnected ? (
                      <>
                        <Avatar
                          src={user.profilePicture}
                          style={{ width: 20, height: 20, marginRight: 5 }}
                        />

                        <Typography variant="h100">{user.name}</Typography>

                        <CheckCircleIcon
                          style={{
                            color: designColors.success[50],
                            marginLeft: 5,
                            fontSize: 14
                          }}
                        />
                      </>
                    ) : (
                      <StyledButton
                        variant={"textprimary"}
                        onClick={verifyToken}
                        disabled={verifying}
                      >
                        {verifying ? "Verifying" : "Verify"}
                      </StyledButton>
                    )}
                  </InputAdornment>
                )
              }}
              name="integration_token"
              onChange={handleTokenChange}
            />

            {user?.isConnected && (
              <StyledButton
                variant="primary"
                onClick={handleNext}
                size="small"
                style={{ marginTop: 15 }}
              >
                Continue
              </StyledButton>
            )}
          </div>
        );
        break;
      case 1:
        body = (
          <>
            <div style={{ marginTop: 15 }}>
              {user?.accounts?.length === 0 && (
                <>
                  <Typography variant="bodys" paragraph>
                    No publications found for the account
                  </Typography>
                </>
              )}

              {user?.accounts?.length > 0 && (
                <Autocomplete
                  multiple
                  limitTags={10}
                  disableCloseOnSelect
                  size="small"
                  value={userAccounts}
                  onChange={(event, newValue) => {
                    setUserAccounts(newValue);
                  }}
                  options={user?.accounts}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <StyledInput {...params} size="medium" variant="outlined" />
                  )}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <div key={option.id} {...props}>
                        <StyledCheckbox
                          size="small"
                          checked={checkIsSelected(option)}
                          labelStyle={{ marginRight: 10 }}
                        />

                        <Avatar
                          style={{
                            width: 18,
                            height: 18
                          }}
                          src={option?.imageUrl}
                        />

                        <Typography variant="bodys" style={{ marginLeft: 10 }}>
                          {option?.name}
                        </Typography>
                      </div>
                    );
                  }}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                      return (
                        <StyledUserChip
                          size="small"
                          key={index}
                          label={option.name}
                          {...getTagProps({ index })}
                          avatar={
                            <Avatar
                              src={option?.imageUrl}
                              style={{ width: 20, height: 20, marginRight: 5 }}
                            />
                          }
                        />
                      );
                    })
                  }
                />
              )}
            </div>

            <div style={{ marginTop: 15 }}>
              <StyledButton
                variant="secondary"
                size="small"
                disabled={index === 0}
                onClick={handleBack}
              >
                Back
              </StyledButton>
            </div>
          </>
        );
        break;
      default:
        break;
    }
    return body;
  }

  function getStepperBody() {
    return (
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        style={{ padding: 20 }}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={(props) => getIconComponent(index, props)}
              optional={
                user?.userId && index === 0 && activeStep !== 0 ? (
                  <Typography variant="caption">
                     connecting to {user.name}
                  </Typography>
                ) : null
              }
            >
              <Typography variant="bodyl">{step.label}</Typography>
            </StepLabel>

            <StepContent>
              <Typography
                variant="bodym"
                style={{ color: designColors.grayScale[60] }}
              >
                {step.description}
              </Typography>
              {getStepBody(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    );
  }

  function saveAndClose() {
    user.accounts = userAccounts;
    api.saveMediumDetails(publicationId, user, (res: any) => {
      if (res.status === 200) {
        onSave();
      }
    });
  }

  return (
    <StyledDialog
      open={true}
      maxWidth="md"
      body={getStepperBody()}
      title={<Typography variant="h400">Connecting to Medium</Typography>}
      successButtonName={user?.isConnected ? "Save" : null}
      successCallback={saveAndClose}
      cancelCallback={onClose}
    />
  );
}

export default MediumConnect;
