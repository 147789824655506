import * as React from "react";
import { forwardRef } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { space } from "../../Config/theme";
import { designColors } from "../../Themes/Colors";
import { ReactComponent as ChevronDownIcon } from "../../Images/icon24/chevron_down.svg";
import { CircularProgress, Divider } from "@mui/material";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${designColors.grayScale[40]}`,
  borderRadius: 6,
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  },
  "&.Mui-expanded": {
    borderColor: designColors.primary[50]
  }
}));

const AccordionSummary = styled(
  ({ loading, ...props }: { loading: boolean } & AccordionSummaryProps) => {
    return (
      <MuiAccordionSummary
        expandIcon={
          loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <ChevronDownIcon />
          )
        }
        {...props}
      />
    );
  }
)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: space.MEDIUM,
  padding: 0,
  minHeight: "unset",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)"
  },
  "& .MuiAccordionSummary-content": {
    margin: 0
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: space.MEDIUM
}));

const StyledAccordion = forwardRef<
  HTMLDivElement,
  Omit<AccordionProps, "children"> & {
    loading?: boolean;
    accordionSummary: JSX.Element;
    accordionDetails: JSX.Element;
    addDivider?: boolean;
  }
>(function (
  { loading, accordionSummary, accordionDetails, addDivider = false, ...other },
  ref
) {
  const [expanded, setExpanded] = React.useState<boolean>(
    loading || other.defaultExpanded || false
  );

  React.useEffect(() => {
    if (loading) {
      setExpanded(other.defaultExpanded || false);
    }
  }, [loading, other.defaultExpanded]);

  return (
    <Accordion
      ref={ref}
      TransitionProps={{ timeout: 250, unmountOnExit: true }}
      expanded={expanded}
      onChange={() => {
        if (loading && !expanded) {
          return;
        }
        setExpanded(!expanded);
      }}
      {...other}
    >
      <AccordionSummary loading={loading || false}>
        {accordionSummary}
      </AccordionSummary>

      {addDivider && <Divider style={{ margin: `0px ${space.MEDIUM}` }} />}

      <AccordionDetails>{accordionDetails}</AccordionDetails>
    </Accordion>
  );
});

export default StyledAccordion;
