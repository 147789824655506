// @ts-nocheck
import { FormControl, Grid, Container, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

import isURL from "validator/lib/isURL";
import StyledButton from "../design/components/StyledButton";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import API from "../Services/Api";
import { formTypes } from "../Utils/Types";
import { notify } from "./CustomNotifications";
import styles from "./styles/FeedbackSettingsStyles";

const api = API.create();

const DEFAULT_MESSAGE =
  "We're sad to see you go! 🙁 We'd love to understand why you're unsubscribing below. Let us know if there is anything we can do to improve our content. Thank you for the feedback!";
const TYPEFORM_PLACEHOLDER =
  "https://example.typeform.com/to/this-is-your-typeform-share-link";
const GOOGLE_FORM_PLACEHOLDER =
  "<iframe src='https://example.com' width='700' height='520' frameborder='0' marginheight='0' marginwidth='0'></iframe>";
const DEFAULT_GOOGLE_FORM = "docs.google.com";
const DEFAULT_TYPEFORM = "typeform.com";

class FeedbackSettings extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      message:
        props.publication.feedback && props.publication.feedback.message
          ? props.publication.feedback.message
          : "",
      formUrl:
        props.publication.feedback && props.publication.feedback.formUrl
          ? props.publication.feedback.formUrl
          : "",
      publication: props.publication,
      openConfirmDialog: false,
      formType:
        props.publication.feedback && props.publication.feedback.type
          ? props.publication.feedback.type
          : formTypes.TYPEFORM
    };
  }

  handleChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  save = () => {
    let { message, formUrl, formType } = this.state;

    if (!message) {
      notify.show(
        "Enter a message to show your readers when they unsubscribe",
        "error"
      );
      return;
    }

    if (!formUrl) {
      notify.show(
        formType === formTypes.TYPEFORM
          ? "Enter the Typeform URL for your feedback survey"
          : "Enter the embed code for your Google form feedback survey",
        "error"
      );
      return;
    }

    if (
      formType === formTypes.TYPEFORM &&
      (!isURL(formUrl) ||
        !this.extractHostname(formUrl).includes(DEFAULT_TYPEFORM))
    ) {
      notify.show("That doesn't seem like a Typeform link", "error");
      return;
    }

    let scriptRegex = /<script.*?>([\s\S]*?)<\/script>/gim;
    if (formType === formTypes.GOOGLEFORM && scriptRegex.test(formUrl)) {
      notify.show("Remove script tags from your code", "error");
      return;
    }

    var formSource = "";
    if (formType === formTypes.GOOGLEFORM) {
      formSource = formUrl.split('src="')[1];
      if (!formSource) {
        notify.show("The embed code has to be an iframe", "error");
        return;
      }
      formSource = formSource.split('"')[0];
    }

    if (
      formType === formTypes.GOOGLEFORM &&
      this.extractHostname(formSource) !== DEFAULT_GOOGLE_FORM
    ) {
      notify.show("Use a valid Google Form URL", "error");
      return;
    }

    this.saveFeedbackSettings();
  };

  extractHostname = (url: any) => {
    var hostname;

    if (url.indexOf("//") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }

    hostname = hostname.split(":")[0];
    hostname = hostname.split("?")[0];

    return hostname;
  };

  saveFeedbackSettings = () => {
    let { message, formUrl, publication, formType } = this.state;

    api.saveFeedbackSettings(
      publication._id,
      message,
      formUrl,
      formType,
      (res: any) => {}
    );
  };

  toggleConfirmDialog = () => {
    this.setState({ openConfirmDialog: !this.state.openConfirmDialog });
  };

  resetMessageAndUrl = () => {
    this.setState(
      { message: "", formUrl: "", formType: formTypes.TYPEFORM },
      () => {
        this.saveFeedbackSettings();
        this.toggleConfirmDialog();
      }
    );
  };

  handleFormChange = (e: any) => {
    this.setState({ formType: e.target.value });
  };

  render() {
    let { classes } = this.props;

    let { message, formUrl, openConfirmDialog, formType } = this.state;

    return (
      <Container className={classes.container}>
        <Typography variant="h400" paragraph>
          Unsubscribe Feedback
        </Typography>

        <Typography variant="bodym" paragraph className={classes.cardSubtitle}>
          Ask for feedback when readers unsubscribe
        </Typography>

        <Grid container direction="column" style={{ paddingTop: 10 }}>
          <Grid item style={{ paddingTop: 10 }}>
            <Typography variant="bodym" paragraph>
              Message you want your readers to see when they unsubscribe
            </Typography>

            <StyledInput
              multiline
              rows={3}
              size="medium"
              placeholder={DEFAULT_MESSAGE}
              name="message"
              className={classes.messageInput}
              value={message}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item style={{ paddingTop: 10, paddingBottom: 20 }}>
            <Typography variant="bodym" paragraph>
              {formType === formTypes.TYPEFORM
                ? "Link to Typeform"
                : "Embed code for Google Form"}
            </Typography>
            {formType === formTypes.TYPEFORM && (
              <Typography
                variant="bodys"
                paragraph
                className={classes.cardSubtitle}
              >
                We display your Typeform on a white background. Choose dark
                colors to make your text visible
              </Typography>
            )}

            <Grid container direction="row">
              <Grid item className={classes.select}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={clsx(classes.width100, classes.input)}
                >
                  <StyledSelect
                    value={formType}
                    name="formType"
                    onChange={this.handleFormChange}
                    className={clsx(classes.input)}
                  >
                    {Object.keys(formTypes).map((key, index) => (
                      <StyledMenuItem value={formTypes[key]} key={index}>
                        {formTypes[key]}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </Grid>

              <Grid item className={classes.width80}>
                <StyledInput
                  size="medium"
                  name="formUrl"
                  placeholder={
                    formType === formTypes.TYPEFORM
                      ? TYPEFORM_PLACEHOLDER
                      : GOOGLE_FORM_PLACEHOLDER
                  }
                  value={formUrl}
                  style={{ width: "100%" }}
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          spacing={1}
          direction="row"
          style={{ width: "100%" }}
          className={classes.saveGrid}
        >
          <Grid item>
            <StyledButton
              variant="textsecondary"
              style={{ margin: 10 }}
              onClick={this.toggleConfirmDialog}
            >
              Reset
            </StyledButton>
          </Grid>

          <Grid item>
            <StyledButton onClick={this.save}>Save</StyledButton>
          </Grid>
        </Grid>

        <StyledDialog
          open={openConfirmDialog}
          title="Are you sure?"
          body="This will reset your feedback form. You'll have to re-enter your
          message and form link"
          successButtonName="Reset"
          successCallback={this.resetMessageAndUrl}
          cancelCallback={this.toggleConfirmDialog}
        />
      </Container>
    );
  }
}

export default withStyles(styles)(FeedbackSettings);
