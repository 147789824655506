// @ts-nocheck
import { faMediumM } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Avatar,
  Container,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import firebase from "firebase/app";
import React, { Fragment } from "react";

import Loading from "../Components/Loading";

import MediumConnect from "../Components/MediumConnect";
import StyledButton from "../design/components/StyledButton";

import StyledCheckbox from "../design/components/StyledCheckbox";

import StyledInput from "../design/components/StyledInput";
import StyledUserChip from "../design/components/StyledUserChip";
import API from "../Services/Api";
import colors from "../Themes/Colors";
import ImagesList from "../Themes/Images";
import PageAccessContext from "../Utils/PageAccessContext";
import { socialMediaStatus } from "../Utils/Types";
import styles from "./styles/RedditShareStyle";

const Page404 = React.lazy(() => import("../Containers/Page404"));

const api = API.create();

class MediumShare extends React.Component {
  static contextType = PageAccessContext;
  constructor(props: any) {
    super(props);
    this.state = {
      id: props.match?.params?.id || props.postId,
      loading: true,
      error: false,
      errorMessage: "",
      mediumHandle: null,
      mediumPost: {},
      mediumAccounts: null,
      selectedAccounts: [],
      publication: null,
      post: null,
      promptMediumDialog: false
    };
  }

  componentDidMount = () => {
    this.load();
  };

  load = () => {
    let { id } = this.state;
    api.getMediumDetails(id, (res: any) => {
      if (res.status === 200) {
        let { publication, post, accounts, mediumPost, mediumHandle } =
          res.data;

        if (!mediumPost) {
          mediumPost = {
            selectedAccounts: []
          };
        }

        let ids = mediumPost.selectedAccounts.map((account: any) => {
          return account.id;
        });

        this.setState({
          loading: false,
          post,
          publication,
          mediumHandle,
          mediumAccounts: accounts,
          selectedAccounts:
            accounts.filter((account: any) => {
              if (ids.includes(account.id)) {
                account["status"] =
                  mediumPost?.selectedAccounts.find((mediumAccount: any) => {
                    return mediumAccount.id === account.id;
                  })?.status || "";
                return account;
              }
            }) || [],
          mediumPost
        });
      } else {
        this.setState({
          loading: false,
          error: true,
          errorMessage: res.data
        });
      }
    });
  };

  handleAccountChange = (newValue: any) => {
    let {
      allPublicationsSelected,

      mediumAccounts,

      selectedAccounts = []
    } = this.state;

    let allFlagSelected = newValue.some((value: any) => value.id === "-1");

    if (allFlagSelected) {
      allPublicationsSelected = allFlagSelected && !allPublicationsSelected;
      selectedAccounts = allPublicationsSelected ? mediumAccounts : [];
    } else {
      allPublicationsSelected = newValue.length === mediumAccounts.length;
      selectedAccounts = newValue;
    }

    this.setState(
      {
        selectedAccounts,
        allPublicationsSelected
      },
      this.savePost
    );
  };

  savePost = () => {
    let { mediumPost = {}, post, publication, selectedAccounts } = this.state;

    if (!post?._id) {
      return;
    }

    mediumPost["selectedAccounts"] = selectedAccounts;

    api.saveMediumPost(
      mediumPost,
      publication._id,
      post?._id || "",
      (res: any) => {
        if (res.status === 200) {
          mediumPost["_id"] = res.data.mediumPostId;
        }
        this.setState({ mediumPost });
      }
    );
  };

  getCheckedFlag = (option: any) => {
    let { allPublicationsSelected, selectedAccounts } = this.state;
    if (allPublicationsSelected) {
      return true;
    }
    let accounts = selectedAccounts || [];
    let exist = accounts.some((publication: any) => {
      return option.id + "" === publication.id;
    });
    return exist ? true : false;
  };

  handleMediumConnect = () => {
    this.setState({ promptMediumDialog: true });
  };

  toggleMediumDialog = () => {
    let { promptMediumDialog } = this.state;
    this.setState({ promptMediumDialog: !promptMediumDialog });
  };

  closeAndReload = () => {
    this.setState({ promptMediumDialog: false }, this.load);
  };

  render() {
    let { classes } = this.props;
    let {
      loading,

      error,

      errorMessage,

      mediumPost,

      mediumAccounts,

      selectedAccounts,

      publication,

      mediumHandle,

      promptMediumDialog
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Page404 errorMessage={errorMessage} />;
    }

    return (
      <Container style={{ padding: 0 }}>
        <Paper className={clsx(classes.paper)}>
          {!mediumAccounts || !mediumAccounts.length > 0 ? (
            <Grid
              container
              direction="row"
              className={classes.connectAccountSection}
            >
              <img
                src={ImagesList.socialMedia}
                className={classes.connectAccountImage}
                alt="Connect Medium account"
              />

              <Typography className={classes.connectAccountText}>
                Connect a Medium account to start posting
              </Typography>

              <StyledButton
                onClick={this.handleMediumConnect}
                style={{
                  background: colors.white,
                  color: colors.black,
                  marginTop: 25
                }}
                disabled={firebase.auth().currentUser ? false : true}
                startIcon={
                  <FontAwesomeIcon
                    icon={faMediumM}
                    title="Medium"
                    style={{
                      color: colors.black
                    }}
                  />
                }
              >
                Connect to Medium
              </StyledButton>
            </Grid>
          ) : (
            <Fragment>
              <div style={{ position: "relative" }}>
                {mediumPost?.status === socialMediaStatus.COMPLETED && (
                  <div className={classes.editableLabelDiv}>
                    <Typography className={classes.editableLabel}>
                      Medium can't be updated from here. Visit medium.com to
                      update your post.
                    </Typography>
                  </div>
                )}
              </div>

              <Grid container direction="column" className={classes.section}>
                <Typography className={classes.cardTitle}>
                  Account or Publication
                </Typography>

                <Typography className={classes.cardSubtitle}>
                  Where do you want to publish?
                </Typography>

                <Grid item>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    limitTags={3}
                    disabled={
                      mediumPost?.status === socialMediaStatus.COMPLETED
                    }
                    value={selectedAccounts}
                    onChange={(event, newValue) => {
                      this.handleAccountChange(newValue);
                    }}
                    options={[{ id: "-1", name: "All" }, ...mediumAccounts]}
                    sx={{
                      ".MuiAutocomplete-popupIndicatorOpen": {
                        transform: "none"
                      }
                    }}
                    getOptionDisabled={(option) => {
                      return option.status === socialMediaStatus.COMPLETED;
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <StyledInput
                        {...params}
                        size="large"
                        variant="outlined"
                      />
                    )}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <div key={option.id} {...props}>
                          <StyledCheckbox
                            size="small"
                            checked={this.getCheckedFlag(option)}
                            labelStyle={{ marginRight: 10 }}
                          />
                          {option.name !== "All" && (
                            <Avatar
                              style={{
                                width: 24,
                                height: 24
                              }}
                              src={option?.imageUrl}
                            />
                          )}

                          <Typography style={{ marginLeft: 10 }}>
                            {option?.name}
                          </Typography>
                        </div>
                      );
                    }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => {
                        return option.name === "All" ? (
                          <StyledUserChip
                            key={index}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ) : (
                          <StyledUserChip
                            key={index}
                            label={option.name}
                            {...getTagProps({ index })}
                            avatar={<Avatar src={option?.imageUrl} />}
                            disabled={
                              option.status === socialMediaStatus.COMPLETED
                            }
                          />
                        );
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Fragment>
          )}
        </Paper>
        {promptMediumDialog && (
          <MediumConnect
            mediumHandle={mediumHandle}
            publicationId={publication._id}
            onClose={this.toggleMediumDialog}
            onSave={this.closeAndReload}
          />
        )}
      </Container>
    );
  }
}

export default withStyles(styles)(MediumShare);
