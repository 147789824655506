import Autocomplete from "@mui/material/Autocomplete";
import * as React from "react";
import StyledChip from "../design/components/StyledChip";
import StyledInput from "../design/components/StyledInput";

type TagsInputProps = {
  placeholder?: string;
  value: string[];
  suggestions: { name?: string }[] | string[];
  handleChange: (e: any, newChips: string[]) => void;
  addTag: (e: any, newChip: string) => void;
  inputProps?: object;
  capitalNotAllowed?: boolean;
  size?: "small" | "medium" | "large";
};

export default function TagsInput({
  placeholder,
  value,
  suggestions,
  handleChange,
  addTag,
  inputProps = {},
  capitalNotAllowed = false,
  size = "medium"
}: TagsInputProps) {
  const [inputValue, setInputValue] = React.useState("");
  const flatSuggestions = React.useMemo(
    () =>
      suggestions.map((suggestion: any) =>
        suggestion.hasOwnProperty("name") ? suggestion.name : suggestion
      ),
    [suggestions]
  );

  const handleKeyDown = (e: any) => {
    const inputText = e.target.value;
    if (e.ctrlKey && e.key === "c" && !e.shiftKey) {
      if (!inputText && value) {
        e.preventDefault();
        e.defaultMuiPrevented = true;
        navigator.clipboard.writeText(value.join(",")).catch((err) => {});
      }
    } else if (e.ctrlKey && e.key === "v" && !e.shiftKey) {
      if (!inputText) {
        e.preventDefault();
        e.defaultMuiPrevented = true;
        navigator.clipboard
          .readText()
          .then((text) => {
            if (!text) return;
            if (capitalNotAllowed) text = text.toLowerCase();

            let textArray = text.split(",");

            if (flatSuggestions.length) {
              textArray = textArray.filter((str) => {
                return flatSuggestions.includes(str);
              });
            }

            handleChange(e, [...value, ...textArray]);
          })
          .catch((err) => {});
      }
    }
  };

  return (
    <Autocomplete
      multiple
      options={flatSuggestions}
      onChange={(event, newValue) => {
        setInputValue("");
        handleChange(event, newValue);
      }}
      onBlur={() => {
        setInputValue("");
        if (inputValue.trim().length) {
          addTag(null, inputValue);
        }
      }}
      getOptionLabel={(option) => option}
      value={value}
      onInputChange={(event, value) => {
        if (typeof value === "string" && !value.endsWith(",")) {
          setInputValue(capitalNotAllowed ? value.toLowerCase() : value);
        } else if (typeof value === "string" && value.endsWith(",")) {
          setInputValue("");
          if (value.length === 1) {
            return;
          }
          addTag(event, value.substring(0, value.length - 1));
        }
      }}
      size={size}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <StyledChip
            variant="gray"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      filterSelectedOptions
      freeSolo
      renderInput={(params) => {
        return (
          <StyledInput
            {...params}
            placeholder={placeholder}
            size={size}
            onKeyDown={handleKeyDown}
            {...(inputProps || {})}
          />
        );
      }}
      inputValue={inputValue}
    />
  );
}
