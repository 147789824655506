import { Grid, IconButton, Tooltip } from "@mui/material";
import React, { useCallback } from "react";
import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import { space } from "../Config/theme";
import StyledButton from "../design/components/StyledButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { pathOptions, pathOptionsLabel } from "../Utils/Types";
const _ = require("lodash");

const filterOptions = [
  { name: pathOptionsLabel[pathOptions.CONTAINS], value: pathOptions.CONTAINS },
  {
    name: pathOptionsLabel[pathOptions.DOES_NOT_CONTAIN],
    value: pathOptions.DOES_NOT_CONTAIN
  },
  {
    name: pathOptionsLabel[pathOptions.DOES_NOT_EQUAL],
    value: pathOptions.DOES_NOT_EQUAL
  },
  { name: pathOptionsLabel[pathOptions.EQUALS], value: pathOptions.EQUALS }
];
type Filter = {
  filter: string | null;
  path: string;
  option: string;
  pathErrorMessage: string;
};
interface Props {
  filterList: {
    name: string;
    value: string;
  }[];
  filters: Filter[];
  onChange: (filters: Filter[]) => void;
  filterLabel?: string;
}

export default function PathSelectionFilter(props: Props) {
  const [filters, setFilters] = React.useState(
    props.filters || [
      {
        filter: props.filterList.length ? props.filterList[0].value : "",
        path: "",
        option: pathOptions.CONTAINS,
        pathErrorMessage: ""
      }
    ]
  );

  const onChangeDebounce = useCallback(
    _.debounce((filters: any) => props.onChange(filters), 1000),
    [props.onChange]
  );

  const onFilterChange = (index: number, filter: string) => {
    let newFilters = filters;
    newFilters[index].filter = filter;
    setFilters([...newFilters]);
    onChangeDebounce([...newFilters]);
  };

  const onPathChange = (index: number, path: string) => {
    let newFilters = filters;
    //check if path includes URL safe characters
    if (path.match(/^[a-zA-Z0-9$-_.+!*'(),;/?:@#&=]+$/) || path === "") {
      newFilters[index].pathErrorMessage = "";
    } else {
      //show error message
      newFilters[index].pathErrorMessage =
        "Only use alphabets, numbers, and these characters: $ - _ . + ! * ' ( ), ; / ? : @ # = &";
    }
    newFilters[index].path = path;
    setFilters([...newFilters]);
    onChangeDebounce([...newFilters]);
  };

  const onOptionChange = (index: number, option: string) => {
    let newFilters = filters;
    newFilters[index].option = option;
    setFilters([...newFilters]);
    onChangeDebounce([...newFilters]);
  };

  return (
    <Grid container>
      {filters.map((filter, index) => {
        return (
          <Grid container item xs={12} style={{ marginBottom: space.MEDIUM }}>
            <Grid
              item
              xs={2}
              style={{ paddingLeft: space.SMALL, paddingRight: space.SMALL }}
            >
              <StyledSelect
                fullWidth
                value={filter.filter}
                onChange={(event: any) =>
                  onFilterChange(index, event.target.value)
                }
              >
                {props.filterList.map(
                  (filter: { name: string; value: string }, index: number) => {
                    return (
                      <StyledMenuItem key={index} value={filter.value}>
                        {filter.name}
                      </StyledMenuItem>
                    );
                  }
                )}
              </StyledSelect>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ paddingLeft: space.SMALL, paddingRight: space.SMALL }}
            >
              <StyledSelect
                fullWidth
                value={filter.option}
                onChange={(event: any) =>
                  onOptionChange(index, event.target.value)
                }
              >
                {filterOptions.map(
                  (filter: { name: string; value: string }, index: number) => {
                    return (
                      <StyledMenuItem key={index} value={filter.value}>
                        {filter.name}
                      </StyledMenuItem>
                    );
                  }
                )}
              </StyledSelect>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ paddingLeft: space.SMALL, paddingRight: space.SMALL }}
            >
              <StyledInput
                fullWidth
                size="medium"
                name="path"
                value={filter.path}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onPathChange(index, event.target.value)
                }
                placeholder="onboarding"
                helperText={filter?.pathErrorMessage}
                error={Boolean(filter?.pathErrorMessage)}
              />
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Remove Filter">
                <IconButton
                  onClick={() => {
                    let newFilters = filters;
                    newFilters.splice(index, 1);
                    setFilters([...newFilters]);
                    onChangeDebounce([...newFilters]);
                  }}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      })}

      <Grid item xs={12}>
        <StyledButton
          variant="textprimary"
          onClick={() => {
            let newFilters = [
              ...filters,
              {
                filter: props.filterList.length
                  ? props.filterList[0].value
                  : null,
                path: "",
                option: pathOptions.CONTAINS,
                pathErrorMessage: ""
              }
            ];
            setFilters(newFilters);
            onChangeDebounce(newFilters);
          }}
          startIcon={<AddCircleIcon />}
        >
          {props.filterLabel || "Add Path"}
        </StyledButton>
      </Grid>
    </Grid>
  );
}
