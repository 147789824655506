// @ts-nocheck
import StyledDialog from "./StyledDialog";

import { useHistory } from "react-router-dom";
import { useState } from "react";

export default function UpgradePlanDialog({
  open,
  title,
  body,
  successButtonName,
  successCallback,
  cancelCallback,
  showCloseIcon = true
}: any) {
  const history = useHistory();
  let [showDialog, setShowDialog] = useState(open);
  function openAccountScreen() {
    if (successCallback) {
      successCallback();
      return;
    }
    history.push("/account#plans");
  }
  function closeDialog() {
    setShowDialog(false);
    cancelCallback?.();
  }

  return (
    <StyledDialog
      open={showDialog}
      title={title || "Upgrade plan"}
      body={body || "Upgrade to a higher plan to use this feature."}
      successButtonName={successButtonName || "Upgrade"}
      successCallback={openAccountScreen}
      closeCallback={showCloseIcon ? closeDialog : null}
    />
  );
}
