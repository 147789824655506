// @ts-nocheck
import {
  faHeading,
  faTag,
  faAlignJustify,
  faLink,
  faTrash,
  faMinusCircle,
  faGlobeAmericas,
  faChartGantt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@mui/icons-material/Add";
import BarChartIcon from "@mui/icons-material/BarChart";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/EditOutlined";
import LockIcon from "@mui/icons-material/Lock";
import MailIcon from "@mui/icons-material/Mail";
import TaskIcon from "@mui/icons-material/Task";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { ReactComponent as Search } from "../Images/icon20/search.svg";
import StarIcon from "@mui/icons-material/StarOutline";
import {
  Avatar,
  Container,
  Grid,
  IconButton,
  Paper,
  Popover,
  Popper,
  Tooltip,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import React, { Suspense } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { notify } from "../Components/CustomNotifications";

import Loading from "../Components/Loading";

import SearchBar from "../design/components/SearchBar";
import API from "../Services/Api";
import Images from "../Themes/Images";
import PageAccessContext from "../Utils/PageAccessContext";
import {
  formatForDisplayDateTime,
  formatForDisplayDateTimeString,
  isAfterNow
} from "../Utils/TimeUtils";
import {
  pageAccess,
  postBulkActionTypes,
  postStatus,
  postType,
  publicationReviewedStatus,
  contentRefreshTypes,
  postFields,
  channelTypes,
  compositeSEOTypes,
  SortOrderEnum,
  type SeoScore
} from "../Utils/Types";
import { getSiteURL, isAccessDisabled, multiSelect } from "../Utils/UserUtils";
import styles from "./styles/PostsStyle";

import { Helmet } from "react-helmet";
import StyledButton from "../design/components/StyledButton";

import StyledDialog from "../design/components/StyledDialog";

import StyledChip from "../design/components/StyledChip";
import StyledTab from "../design/components/StyledTab";

import StyledCheckbox from "../design/components/StyledCheckbox";

import PostBulkAction from "../Components/PostBulkAction";
import UnstyledLink from "../design/components/UnstyledLink";

import { ReactComponent as FilterIcon } from "../Images/icon20/filterprojects.svg";

import { ReactComponent as SignalHighIcon } from "../Images/icon16/signal_high.svg";

import { ReactComponent as SignalMediumIcon } from "../Images/icon16/signal_medium.svg";

import { ReactComponent as SignalLowIcon } from "../Images/icon16/signal_low.svg";
import StyledInputLabel from "../design/components/StyledInputLabel";

import StyledAutocomplete from "../design/components/StyledAutocomplete";

import { isFieldOptional } from "../Components/BlogPostReviewSettings";
import qs from "query-string";
import { space } from "../Config/theme";
import CompositeSEOScorePopup from "../Components/CompositeSEOScorePopup";
import Sort from "@mui/icons-material/Sort";

const dialogTypes = {
  DELETE: "delete",
  UNPUBLISH: "unpublish",
  UNSCHEDULE: "unschedule"
};

const Page404 = React.lazy(() => import("./Page404"));

const api = API.create();
const PAGE_LIMIT = 15;
const AUTOCOMPLETE_ALL_OPTION_TEXT = "All";
const compositeSEOFilterOptions = [
  { type: compositeSEOTypes.LOW, name: "Low" },
  { type: compositeSEOTypes.MEDIUM, name: "Medium" },
  { type: compositeSEOTypes.HIGH, name: "High" }
];

class Posts extends React.Component {
  static contextType = PageAccessContext;
  internalLinksCountCache = {};
  constructor(props: any) {
    super(props);
    let params = props.match.params;

    let qsParse = qs.parse(this.props.location.search);
    this.state = {
      tabs: [],
      confirmDialogType: "",
      isPostDataLoading: true,
      loading: true,
      status: qsParse?.draft ? postStatus.DRAFT : postStatus.PUBLISHED,
      posts: [],
      publication: null,
      publicationId: params.id ? params.id : "",
      showDialog: false,
      dialogTitile: "",
      dialogMessage: "",
      deleteIndex: null,
      draftsCount: 0,
      scheduledPostCount: 0,
      inReviewPostCount: 0,
      disableWritePost: false,
      error: false,
      errorMessage: "",
      showMoreOptions: false,
      anchorEl: null,
      publicationInReview: false,
      page: 0,
      rowsPerPage: PAGE_LIMIT,
      hasNextPage: false,
      totalPosts: 0,
      replacePosts: false,
      searchKeyword: "",
      customDomain: "",
      clientId: null,
      showSearchBar: false,
      selectedPosts: [],
      showBulkActionDialog: false,
      postSelectionIndex: null,
      bulkActionType: null,
      showConfirmDialog: false,
      tagSuggestions: [],
      prevSelectedPostIndex: -1,
      currentSelectedPostIndex: -1,
      showFilter: false,
      filterElement: null,
      destinationFilters: [],
      compositeSEOFilters: [],
      destinationList: [],
      isAllDestinationsFilterChecked: false,
      isAllCompositeSEOFilterChecked: false,
      seoScore: null,
      showCompositeSEOScore: false,
      compositeSEOScoreAnchorEl: null,
      sortOrder: SortOrderEnum.DESC
    };
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(
        {
          confirmDialogType: "",
          isPostDataLoading: true,
          loading: true,
          status: postStatus.PUBLISHED,
          posts: [],
          publication: null,

          publicationId: this.props.match.params.id
            ? this.props.match.params.id
            : "",
          showDialog: false,
          dialogTitile: "",
          dialogMessage: "",
          deleteIndex: null,
          draftsCount: 0,
          scheduledPostCount: 0,
          inReviewPostCount: 0,
          disableWritePost: false,
          error: false,
          errorMessage: "",
          showMoreOptions: false,
          anchorEl: null,
          publicationInReview: false,
          page: 0,
          rowsPerPage: PAGE_LIMIT,
          hasNextPage: false,
          totalPosts: 0,
          replacePosts: false,
          searchKeyword: "",
          customDomain: "",
          clientId: null,
          showSearchBar: false,
          selectedPosts: [],
          showBulkActionDialog: false,
          postSelectionIndex: null,
          showFilter: false
        },
        this.load
      );
    }
  }

  toggleSearch = () => {
    this.setState((prevState, props) => ({
      showSearchBar: !prevState.showSearchBar
    }));
  };

  toggleFilter = (event: any) => {
    this.setState((prevState, props) => ({
      showFilter: !prevState.showFilter,
      filterElement: event ? event.target : null
    }));
  };

  loadMore = () => {
    this.setState(
      {
        replacePosts: true,
        page: 0,
        rowsPerPage: PAGE_LIMIT,
        isPostDataLoading: true
      },
      this.load
    );
  };

  load = () => {
    let {
      status,
      publicationId,
      rowsPerPage,
      page,
      posts,
      replacePosts,
      searchKeyword,
      destinationFilters,
      compositeSEOFilters,
      sortOrder
    } = this.state;

    let compositeSEOStatus = compositeSEOFilters.map((compositeSEO: any) => {
      return compositeSEO.type;
    });

    let destinationIds = destinationFilters.map((destination: any) => {
      return destination.userId;
    });
    if (replacePosts) {
      page = 0;
    }
    api.getPosts(
      status,
      publicationId,
      rowsPerPage,
      page * rowsPerPage,
      searchKeyword,
      destinationIds,
      compositeSEOStatus,
      sortOrder,
      (res: any) => {
        if (res.status === 200) {
          if (this.state.status !== status) {
            return;
          }
          let response = res.data;
          if (replacePosts) {
            posts = [];
          }
          if (response.success) {
            this.setState({
              posts: [...posts, ...res.data.posts.docs],
              publication: res.data.publication,
              loading: false,
              isPostDataLoading: false,
              scheduledPostCount: res.data.scheduledPostCount,
              draftsCount: res.data.draftsCount,
              inReviewPostCount: res.data.inReviewPostCount,
              publicationInReview:
                res.data.publication.reviewed ===
                publicationReviewedStatus.IN_REVIEW,
              hasNextPage: res.data.posts.hasNextPage,
              totalPosts: res.data.posts.totalDocs,
              page: page + 1,
              replacePosts: false,
              customDomain: res.data.customDomain,
              clientId: res.data.clientId,
              tabs: this.getTabsData(res.data),
              tagSuggestions: res.data.tagSuggestions
            });
            this.getPostDestinationList(res.data.publication);
          } else {
            this.setState({
              loading: false,
              isPostDataLoading: false,
              error: true
            });
          }
        } else {
          this.setState({
            loading: false,
            isPostDataLoading: false,
            error: true,
            errorMessage: res.data
          });
        }
      }
    );
  };

  handleSearchChange = (value: any) => {
    this.setState({ searchKeyword: value });
  };

  handleChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getTabsData = (postData: any) => {
    let {
      scheduledPostCount,
      draftsCount,
      inReviewPostCount,
      publishedPostsCount
    } = postData;
    let tabs = [
      {
        label: "Published",
        value: postStatus.PUBLISHED,
        count: publishedPostsCount
      }
    ];
    if (scheduledPostCount > 0) {
      tabs.push({
        label: "Scheduled",
        value: postStatus.SCHEDULED,
        count: scheduledPostCount
      });
    }
    if (inReviewPostCount > 0) {
      tabs.push({
        label: "In Review",
        value: postStatus.IN_REVIEW,
        count: inReviewPostCount
      });
    }
    if (draftsCount > 0) {
      tabs.push({
        label: "Drafts",
        value: postStatus.DRAFT,
        count: draftsCount
      });
    }
    return tabs;
  };

  setStatus = (event: any, newStatus: any) => {
    let { status } = this.state;
    if (status === newStatus) return;
    this.setState(
      {
        status: newStatus,
        replacePosts: true,
        page: 0,
        rowsPerPage: PAGE_LIMIT,
        isPostDataLoading: true,
        selectedPosts: []
      },
      this.load
    );
  };

  trackDraft = (index: number) => {
    let { publication, posts } = this.state;
    let post = posts[index];

    if (!post.title?.trim()) {
      notify.show(
        "Please enter the post title first to track the post",
        "error"
      );
      return;
    }

    if (post.topicId && post.topicId._id) {
      this.props.history.push(
        `/${publication._id}/projects/${post.topicId._id}`
      );
      return;
    }

    let channel = {
      name: "Website",
      type: channelTypes.BLOG,
      isBlog: true
    };

    if (post.destination) {
      for (let blog of publication.externalSite.blogs) {
        if (
          blog.destination === post.destination?.type &&
          blog.id === post.destination?.id
        ) {
          channel = {
            name: blog.name,
            type: blog.destination,
            id: blog.id,
            isBlog: true
          };
        }
      }
    }

    if (!post.destination && publication.externalSite?.blogs?.length) {
      channel = {
        name: publication.externalSite.blogs[0].name,
        type: publication.externalSite.blogs[0].destination,
        id: publication.externalSite.blogs[0].id,
        isBlog: true
      };
    }

    api.createTopicFromPost(
      post.title,
      publication._id,
      channel,
      post._id,
      (res: any) => {
        if (res.status === 200) {
          this.props.history.push(
            `/${publication._id}/calendar?topicId=${res.data.topic._id}&type=${res.data.topic.channel?.type}&postId=${post._id}&isNewTracking=true&backpage=projects`
          );
        }
      }
    );
  };

  openPost = (event: any, index: any) => {
    event.stopPropagation();

    let { publication, posts } = this.state;
    let post = posts[index];

    this.props.history.push(`/${publication._id}/posts/${post._id}`);
  };

  openStat = (event: any, index: any) => {
    event.stopPropagation();

    let { publication, posts } = this.state;
    if (posts[index].publishedElsewhere) {
      notify.show(
        "This post wasn’t published here so we don’t have stats",
        "error"
      );
      return;
    }
    let post = posts[index];

    this.props.history.push(
      publication?.sendBlogNewsletter
        ? `/${publication._id}/posts/${post._id}/emailstats`
        : `/${publication._id}/posts/${post._id}/stats`
    );
  };

  openPublicPost = (index: any) => {
    let { posts, publication } = this.state;

    let post = posts[index];
    if (post.isPrivate && !post.scheduledOn) {
      notify.show(
        "This was sent out as a newsletter. You can edit the post to publish it as a blog too.",
        "warning"
      );
      return;
    }
    if (post.status === postStatus.IN_REVIEW || post.scheduledOn) {
      this.props.history.push(`/${publication._id}/posts/${post._id}`);
      return;
    }
    if (post.status === postStatus.PUBLISHED) {
      if (posts[index].publishedElsewhere) {
        notify.show("This post was published elsewhere", "error");
        return;
      }
      let siteURL = getSiteURL(
        publication,
        true,
        "/" + post.url.url,
        post.destination
      );

      window.open(siteURL, "_blank");
    }
  };

  createDraft = () => {
    const { publication } = this.state;
    if (!publication) {
      notify.show("Couldn't find this workspace", "error");
      return;
    }
    api.saveAsDraft("", publication._id, (res: any) => {
      if (res.status === 200) {
        this.props.history.push(`/${publication._id}/posts/${res.data._id}`);
      } else {
        this.setState({
          disableWritePost: false
        });
      }
    });
  };

  deletConfirmation = (index: any) => {
    this.setState({
      showDialog: true,
      dialogTitle: "Are you sure?",
      dialogMessage: "Once deleted, you can't recover your post",
      deleteIndex: index,
      confirmDialogType: dialogTypes.DELETE,
      showMoreOptions: -1
    });
  };

  handleConfirmSuccess = () => {
    let { index, deleteIndex, confirmDialogType, posts } = this.state;
    if (confirmDialogType === dialogTypes.DELETE) {
      this.deletePost(deleteIndex);
      return;
    }
    if (confirmDialogType === dialogTypes.UNPUBLISH) {
      this.unpublishPost(index);
      return;
    }
    if (confirmDialogType === dialogTypes.UNSCHEDULE) {
      this.unschedulePost(index);
      return;
    }
  };

  duplicatePost = (index: any) => {
    let { posts, publicationId } = this.state;
    let postToDuplicate = posts[index];
    api.duplicatePost(postToDuplicate._id, (res: any) => {
      if (res.status === 200) {
        this.props.history.push(`/${publicationId}/posts/${res.data.post._id}`);
        return;
      } else {
        notify.show(
          res.status === 400 ? res.data : "Unable to duplicate post",
          "error"
        );
      }
    });
  };

  unpublishPost = (index: any) => {
    let { posts } = this.state;
    let postToRemove = posts[index];
    api.unpublishPost(postToRemove._id, (res: any) => {
      if (res.status === 200) {
        posts.splice(index, 1);
        this.setState({
          posts: posts,
          showMoreOptions: -1,
          showDialog: false
        });
        notify.show(
          "Unpublished! You can find this post in your drafts",
          "success"
        );
      }
    });
  };

  unschedulePost = (index: any) => {
    let { posts } = this.state;
    let postToRemove = posts[index];
    api.unschedulePost(postToRemove._id, (res: any) => {
      if (res.status === 200) {
        posts.splice(index, 1);
        this.setState({
          posts: posts,
          showMoreOptions: -1,
          showDialog: false
        });
        notify.show(
          "Unscheduled! You can find this post in your drafts",
          "success"
        );
      }
    });
  };

  deletePost = (deleteIndex: any) => {
    let { posts } = this.state;
    api.deletePost(posts[deleteIndex]._id, (res: any) => {
      if (res.status === 200) {
        this.setState(
          {
            replacePosts: true,
            showDialog: false,
            page: 0
          },
          this.load
        );
      }
    });
  };

  unpublishConfirmation = (index: any) => {
    this.setState({
      showMoreOptions: false,
      showDialog: true,
      dialogTitle: "Are you sure?",
      dialogMessage: "You want to unpublish your post",
      index: index,
      confirmDialogType: dialogTypes.UNPUBLISH
    });
  };

  unscheduleConfirmation = (index: any) => {
    this.setState({
      showMoreOptions: false,
      showDialog: true,
      dialogTitle: "Are you sure?",
      dialogMessage: "You want to unschedule your post",
      index: index,
      confirmDialogType: dialogTypes.UNSCHEDULE
    });
  };

  drawButtonsGrid = (
    index: any,
    featuredPost: any,
    isPrivate: any,
    status: any,
    post: any
  ) => {
    let { publication } = this.state;

    let { classes } = this.props;
    return (
      <Grid container direction="column" alignItems="flex-start">
        {!isAccessDisabled(
          publication._id,
          this.context,
          pageAccess.EDIT_POST
        ) &&
          (post.status === postStatus.IN_REVIEW ||
            post.scheduledOn ||
            this.getPostDestination(post.destination)) && (
            <div
              className={classes.menuItem}
              onClick={() => {
                this.openPublicPost(index);
              }}
            >
              {post.status === postStatus.IN_REVIEW || post.scheduledOn ? (
                <>
                  <EditIcon className={classes.menuIcon} />

                  <Typography className={classes.menuText}>Edit</Typography>
                </>
              ) : (
                this.getPostDestination(post.destination) && (
                  <>
                    <FontAwesomeIcon
                      className={classes.liveIcon}
                      icon={faGlobeAmericas}
                    />

                    <Typography className={classes.menuText}>
                      Go to live page
                    </Typography>
                  </>
                )
              )}
            </div>
          )}
        {!isAccessDisabled(
          publication._id,
          this.context,
          pageAccess.DUPLICATE_POST
        ) && (
          <div
            className={classes.menuItem}
            onClick={() => {
              this.duplicatePost(index);
            }}
          >
            <ContentCopyIcon className={classes.menuIcon} />

            <Typography
              className={classes.menuText}
              style={{ marginRight: space.SMALL }}
            >
              Duplicate
            </Typography>
          </div>
        )}
        {!isAccessDisabled(
          publication._id,
          this.context,
          pageAccess.DELETE_POST
        ) && (
          <div
            className={classes.menuItem}
            onClick={(event) => this.deletConfirmation(index)}
          >
            <DeleteIcon className={classes.menuIcon} />

            <Typography className={classes.menuText}>Delete</Typography>
          </div>
        )}
        {status === postStatus.PUBLISHED && (
          <div
            className={classes.menuItem}
            onClick={() => this.unpublishConfirmation(index)}
          >
            <RemoveCircleIcon className={classes.menuIcon} />

            <Typography className={classes.menuText}>Unpublish</Typography>
          </div>
        )}
        {status === postStatus.SCHEDULED && (
          <div
            className={classes.menuItem}
            onClick={() => this.unscheduleConfirmation(index)}
          >
            <RemoveCircleIcon className={classes.menuIcon} />

            <Typography className={classes.menuText}>Unschedule</Typography>
          </div>
        )}
        {status === postStatus.PUBLISHED &&
          !featuredPost &&
          !isPrivate &&
          !isAccessDisabled(
            publication._id,
            this.context,
            pageAccess.FEATURE_POST
          ) && (
            <div
              className={classes.menuItem}
              onClick={(event) => this.changeFeaturedPost(index, true)}
            >
              <StarIcon className={classes.menuIcon} />

              <Typography className={classes.menuText}>Feature</Typography>
            </div>
          )}
        {status === postStatus.PUBLISHED &&
          featuredPost &&
          !isAccessDisabled(
            publication._id,
            this.context,
            pageAccess.FEATURE_POST
          ) && (
            <div
              className={classes.menuItem}
              onClick={(event) => this.changeFeaturedPost(index, false)}
            >
              <StarIcon className={classes.menuIcon} />

              <Typography className={classes.menuText}>
                Don't feature post
              </Typography>
            </div>
          )}
      </Grid>
    );
  };

  changeFeaturedPost = (index: any, changeFlag: any) => {
    let { posts } = this.state;
    if (posts[index].publishedElsewhere) {
      notify.show(
        "This post wasn’t published here so we can’t feature it",
        "error"
      );
      return;
    }
    let post = posts[index];
    api.changeFeaturedPost(post._id, changeFlag, (res: any) => {
      if (res.status === 200) {
        this.closeMoreOptions();

        notify.show(
          (changeFlag ? "Marked" : "Removed") + " as featured post",
          "success"
        );

        post.featuredPost = changeFlag;
        if (changeFlag) {
          posts.map((eachPost: any, index: any) => {
            if (eachPost._id !== post._id) {
              eachPost.featuredPost = false;
            }
            return "";
          });
        }

        this.setState({
          posts
        });
      } else {
        notify.show(res.data, "error");
      }
    });
  };

  openPublications = () => {
    this.props.history.push("/workspaces");
  };

  openOverview = () => {
    let { publication } = this.state;

    this.props.history.push({
      pathname: `${publication._id}/overview`
    });
  };

  openSubscribers = () => {
    let { publication } = this.state;

    this.props.history.push({
      pathname: `/${publication._id}/subscribers`
    });
  };

  openTemplates = () => {
    let { publication } = this.state;
    if (publication._id) {
      this.props.history.push(`/${publication._id}/templates/`);
    }
  };

  getScheduledOnFormat = (scheduledOn: any) => {
    if (isAfterNow(scheduledOn)) {
      return "Scheduled for " + formatForDisplayDateTimeString(scheduledOn);
    } else {
      return formatForDisplayDateTime(scheduledOn);
    }
  };

  openMoreOptions = (event: any, index: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      showMoreOptions: index,
      anchorEl: event ? event.currentTarget : null
    });
  };

  closeMoreOptions = () => {
    this.setState({
      showMoreOptions: -1
    });
  };

  openPublicationStats = () => {
    let { publication } = this.state;
    if (publication._id) {
      this.props.history.push(`/${publication._id}/analytics`);
    }
  };

  closeSearchBox = () => {
    this.setState(
      {
        page: 0,
        replacePosts: true,
        searchKeyword: ""
      },
      this.load
    );
  };

  onCancelSearch = () => {
    this.setState(
      { searchKeyword: "", page: 0, replacePosts: true },
      this.load
    );
  };

  getPostDueInfo = (post: any) => {
    if (
      post.requiresApproval &&
      post.reviewDeadline &&
      post.status === postStatus.DRAFT
    ) {
      return "Due on " + formatForDisplayDateTime(post.reviewDeadline);
    }
    if (
      post.requiresApproval &&
      post.scheduledOn &&
      post.status === postStatus.IN_REVIEW
    ) {
      let text = post.contentStage ? `In ${post.contentStage}. ` : "";
      return text + "Approve by " + formatForDisplayDateTime(post.scheduledOn);
    }
    return false;
  };

  getPostDestination(destination: any) {
    let { publication } = this.state;
    for (let blog of publication.externalSite.blogs) {
      if (
        blog.destination === destination?.type &&
        blog.id === destination?.id
      ) {
        return blog.name;
      }
    }
    return null;
  }

  getStatisticsText(status: any, totalPosts: any) {
    let text = "post";
    if (status === postStatus.SCHEDULED) {
      return status + " ";
    } else if (status === postStatus.IN_REVIEW) {
      return "in review ";
    } else if (status === postStatus.DRAFT) {
      text = postStatus.DRAFT;
    } else {
      text = "post";
    }
    return text + (totalPosts === 1 ? "" : "s") + " ";
  }

  isPostSelected = (postId: any) => {
    return this.state.selectedPosts.some((post: any) => post._id === postId);
  };

  generatePostValue = (post: any) => {
    return {
      _id: post._id,
      title: post.title,
      metaDescription: post.metaDescription || "",
      thumbnail: post.thumbnail || "",
      metaTitle: post.metaTitle || "",
      url: post.url || {},
      tags: post.tags || [],
      status: post.status,
      scheduledOn: post.scheduledOn
    };
  };

  handlePostSelection = (event: any, post: any, index: any) => {
    let {
      posts,

      selectedPosts,

      prevSelectedPostIndex,

      currentSelectedPostIndex
    } = this.state;

    if (event.shiftKey) {
      if (prevSelectedPostIndex === -1) {
        prevSelectedPostIndex = index;
        this.setState({ prevSelectedPostIndex });
      } else {
        currentSelectedPostIndex = index;
        let selectedValue = multiSelect(
          posts,
          selectedPosts,
          prevSelectedPostIndex,
          currentSelectedPostIndex,
          event.target.checked,
          this.generatePostValue
        );
        this.setState({
          selectedPosts: selectedValue,
          prevSelectedPostIndex: currentSelectedPostIndex,
          currentSelectedPostIndex: -1
        });
        return;
      }
    } else {
      this.setState({
        prevSelectedPostIndex: index,
        currentSelectedPostIndex: -1
      });
    }
    //Insert post if selected and not exists in selected post
    if (event.target.checked && !this.isPostSelected(post._id)) {
      selectedPosts.push({
        _id: post._id,
        title: post.title,
        metaDescription: post.metaDescription || "",
        thumbnail: post.thumbnail || "",
        metaTitle: post.metaTitle || "",
        url: post.url || {},
        tags: post.tags || [],
        status: post.status,
        scheduledOn: post.scheduledOn
      });
    }

    //Remove post from selected list
    if (!event.target.checked) {
      let index = selectedPosts.findIndex(
        (selectedPost: any) => selectedPost._id === post._id
      );
      if (index >= 0) {
        selectedPosts.splice(index, 1);
      }
    }
    this.setState({
      selectedPosts
    });
  };

  //Cancel bottom bulk action bar
  cancelSelection = () => {
    this.setState({
      selectedPosts: []
    });
  };

  //Show confirm dialog or edit dialog based on type
  handleBulkAction = (bulkActionType: any) => {
    let { showConfirmDialog, showBulkActionDialog } = this.state;
    if (
      [
        postBulkActionTypes.UPDATE_TITLE,
        postBulkActionTypes.UPDATE_TAGS,
        postBulkActionTypes.UPDATE_URL,
        postBulkActionTypes.UPDATE_DESCRIPTION
      ].includes(bulkActionType)
    ) {
      showBulkActionDialog = true;
    }
    if (
      [postBulkActionTypes.UNPUBLISH, postBulkActionTypes.DELETE].includes(
        bulkActionType
      )
    ) {
      showConfirmDialog = true;
    }

    this.setState({
      bulkActionType,
      showBulkActionDialog,
      showConfirmDialog
    });
  };

  //Close bulk action dialog
  closeBulkAssignDialog = () => {
    this.setState({
      showBulkActionDialog: false
    });
  };

  //Delete or Update confirm dialog success
  handleBulkActionDialogSuccess = () => {
    let { publication, selectedPosts, bulkActionType } = this.state;
    api.postBulkAction(
      publication._id,
      selectedPosts,
      bulkActionType,
      (res: any) => {
        if (res.status === 200 && res.data.success) {
          this.onBulkActionSuccess();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Oops, something went wrong.",
            "error"
          );
        }
      }
    );
  };

  //Refresh data after bulk action
  onBulkActionSuccess = () => {
    this.setState(
      {
        showBulkActionDialog: false,
        showConfirmDialog: false,
        selectedPosts: [],
        page: 0,
        replacePosts: true
      },
      this.load
    );
  };

  getAuthor = (post: any) => {
    let { classes } = this.props;
    let author = post.externalAuthor || post.postAuthor;
    if (!author) {
      return;
    }
    if (
      !isFieldOptional(
        [
          postFields.authorNameMultiple.slug,
          postFields.authorNameSingle.slug,
          postFields.authorName.slug
        ],
        post.publication.externalSite,
        post.publication.webflow,
        post.destination
      )
    ) {
      return (
        <Typography variant="body2" className={classes.authorContainer}>
          <Avatar
            src={author.profilePic}
            className={classes.postAuthorAvatar}
          />
          {author.name || author.email || ""}
        </Typography>
      );
    }
    return;
  };

  getPostDestinationList(publication: any) {
    let postChannels = publication.externalSite.blogs.map((blog: any) => {
      return {
        userId: blog.id,
        name: blog.name,
        type:
          blog.destination.charAt(0).toUpperCase() + blog.destination.slice(1)
      };
    });
    this.setState({ destinationList: postChannels });
  }

  isDestinationSelected = (option: any, optionIndex: any) => {
    let { destinationFilters, isAllDestinationsFilterChecked } = this.state;
    if (isAllDestinationsFilterChecked) {
      return true;
    }

    return Boolean(
      destinationFilters.find(
        (destination: any) => destination.userId === option.userId
      )
    );
  };

  onDestinationFilterChange = (e: any, values: any) => {
    let {
      destinationFilters,

      destinationList,

      isAllDestinationsFilterChecked
    } = this.state;
    let allFlagSelected = values.find(
      (status: any) => status.name === AUTOCOMPLETE_ALL_OPTION_TEXT
    );
    if (allFlagSelected) {
      let populateAllAccounts = !isAllDestinationsFilterChecked;
      destinationFilters = populateAllAccounts ? destinationList : [];
      isAllDestinationsFilterChecked = populateAllAccounts;
    } else {
      destinationFilters = values;
      isAllDestinationsFilterChecked = values.length === destinationList.length;
    }
    this.setState({
      destinationFilters,
      isAllDestinationsFilterChecked
    });
  };

  onCompositeSEOFilterChange = (e: any, values: any) => {
    let { compositeSEOFilters, isAllCompositeSEOFilterChecked } = this.state;

    let allFlagSelected = values.find(
      (status: any) => status.name === AUTOCOMPLETE_ALL_OPTION_TEXT
    );
    if (allFlagSelected) {
      let populateAllAccounts = !isAllCompositeSEOFilterChecked;
      compositeSEOFilters = populateAllAccounts
        ? compositeSEOFilterOptions
        : [];
      isAllCompositeSEOFilterChecked = populateAllAccounts;
    } else {
      compositeSEOFilters = values;
      isAllCompositeSEOFilterChecked =
        values.length === compositeSEOFilterOptions.length;
    }

    this.setState({
      compositeSEOFilters,
      isAllCompositeSEOFilterChecked
    });
  };

  isCompositeSEOSelected = (option: any, optionIndex: any) => {
    let { compositeSEOFilters, isAllCompositeSEOFilterChecked } = this.state;
    if (isAllCompositeSEOFilterChecked) {
      return true;
    }

    return Boolean(
      compositeSEOFilters.find((status: any) => status.type === option.type)
    );
  };

  setCompositeSEOScore = (
    showCompositeSEOScore: boolean,
    compositeSEOScoreAnchorEl: HTMLElement | null,
    seoScore: SeoScore,
    postId: string
  ) => {
    const internalLinksCount = {
      data: seoScore?.["internalLinksCount"],
      loading: true
    };
    if (postId in this.internalLinksCountCache) {
      internalLinksCount.data = this.internalLinksCountCache[postId];
      internalLinksCount.loading = false;
    }
    this.setState(
      {
        showCompositeSEOScore,
        compositeSEOScoreAnchorEl,
        seoScore: seoScore
          ? {
              ...seoScore,
              internalLinksCount
            }
          : null
      },
      () => {
        if (postId in this.internalLinksCountCache || !seoScore) return;
        this.getInternalLinksCount(postId);
      }
    );
  };

  getInternalLinksCount = (postId: string) => {
    api.getUpdatedLinksCount(postId, (res: any) => {
      if (res.status === 200) {
        this.internalLinksCountCache[postId] = res.data.internalLinks.length;
        let { seoScore } = this.state;
        if (!seoScore) return;
        seoScore.internalLinksCount = {
          data: res.data.internalLinks.length,
          loading: false
        };
        this.setState({
          seoScore
        });
      }
    });
  };

  getLinksScore = (linksCount: any) => {
    switch (true) {
      case linksCount <= 1:
        return 1;
      case linksCount <= 3:
        return 3;
      default:
        return 5;
    }
  };

  getCompositeSEOSection = ({ compositeSEO, contentAnalyzed, _id }: any) => {
    if (!compositeSEO || !contentAnalyzed?.keyword) {
      return "";
    }

    compositeSEO["keyword"] = contentAnalyzed.keyword;

    let { classes } = this.props;
    let finalRating = compositeSEO.finalRating;

    let scoreIndicatorIcon = "";
    switch (true) {
      case finalRating === compositeSEOTypes.LOW:
        scoreIndicatorIcon = <SignalLowIcon />;
        break;
      case finalRating === compositeSEOTypes.MEDIUM:
        scoreIndicatorIcon = <SignalMediumIcon />;
        break;
      default:
        scoreIndicatorIcon = <SignalHighIcon />;
        break;
    }

    return (
      <span className={classes.compositeSeoSection}>
        <IconButton
          size="small"
          disableFocusRipple
          disableRipple
          style={{ height: "fit-content", padding: 0 }}
          onMouseEnter={(event) => {
            this.setCompositeSEOScore(
              true,
              event.currentTarget,
              compositeSEO,
              _id
            );
          }}
          onMouseLeave={(event) => {
            this.setCompositeSEOScore(false, null, null, null);
          }}
        >
          {scoreIndicatorIcon}
        </IconButton>
      </span>
    );
  };

  handleSortButtonClick = () => {
    this.setState(
      (prevState) => ({
        page: 0,
        replacePosts: true,
        sortOrder:
          prevState.sortOrder === SortOrderEnum.DESC
            ? SortOrderEnum.ASC
            : SortOrderEnum.DESC
      }),
      () => {
        this.load();
      }
    );
  };

  render() {
    let { classes } = this.props;
    let {
      searchKeyword,

      isPostDataLoading,

      loading,

      status,

      posts,

      publication,

      showDialog,

      dialogTitle,

      dialogMessage,

      disableWritePost,

      error,

      errorMessage,

      showMoreOptions,

      anchorEl,

      hasNextPage,

      clientId,

      showSearchBar,

      tabs,

      selectedPosts,

      showBulkActionDialog,

      postSelectionIndex,

      bulkActionType,

      showConfirmDialog,

      tagSuggestions,

      showFilter,

      filterElement,

      destinationFilters,

      compositeSEOFilters,

      destinationList,

      showCompositeSEOScore,

      compositeSEOScoreAnchorEl,

      seoScore
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage={errorMessage} />
        </Suspense>
      );
    }

    return (
      <div className={classes.container}>
        <Helmet>
          <title>
            Blog posts {publication && "- " + publication.name} | Letterdrop
          </title>
        </Helmet>

        <Container style={{ paddingTop: 24 }}>
          <Grid container direction="column" className={classes.grid}>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
              className={classes.sectionSelectorContainer}
            >
              <Grid item sm={12} className={classes.width100}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Typography
                      variant="h600"
                      paragraph
                      className={classes.pageTitle}
                    >
                      Your posts
                    </Typography>
                  </Grid>

                  <Grid item>
                    {!isAccessDisabled(
                      publication._id,
                      this.context,
                      pageAccess.CREATE_POST
                    ) && (
                      <StyledButton
                        variant="secondary"
                        className={classes.newPostButton}
                        onClick={() =>
                          this.setState(
                            {
                              disableWritePost: true
                            },

                            this.createDraft()
                          )
                        }
                        startIcon={<AddIcon />}
                        disabled={disableWritePost}
                      >
                        Write post
                      </StyledButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Problematic */}

            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
              className={classes.topSection}
            >
              <StyledTab value={status} tabs={tabs} onChange={this.setStatus} />

              <div>
                {!showSearchBar && (
                  <IconButton size={"large"} onClick={this.toggleSearch}>
                    <Search />
                  </IconButton>
                )}

                {showSearchBar && (
                  <SearchBar
                    value={searchKeyword}
                    onChange={this.handleSearchChange}
                    onCancelSearch={this.onCancelSearch}
                    onRequestSearch={this.loadMore}
                    placeholder="Search posts"
                    toggleSearch={this.toggleSearch}
                  />
                )}

                <IconButton
                  id="filter_icon"
                  size="large"
                  onClick={this.toggleFilter}
                >
                  <FilterIcon />
                </IconButton>

                <Tooltip title={"Sort"}>
                  <IconButton size="large" onClick={this.handleSortButtonClick}>
                    <Sort
                      size="small"
                      sx={{
                        fontSize: "20px",
                        transform:
                          this.state.sortOrder === SortOrderEnum.DESC
                            ? "rotate(0deg)"
                            : "rotate(180deg)"
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>

            {/* Problematic End*/}

            {isPostDataLoading && <Loading />}
            {!isPostDataLoading && (
              <Grid
                item
                container
                direction="column"
                xs={12}
                style={{ paddingTop: 15 }}
              >
                {status === postStatus.PUBLISHED && posts.length === 0 && (
                  <Grid item>
                    <Typography variant="h6" className={classes.errorMessage}>
                      {searchKeyword
                        ? `No posts found for "${searchKeyword}" in
                        published posts`
                        : "You don't have any published posts"}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.errorMessage}
                    >
                      Click on Write Post to start
                    </Typography>

                    <img
                      src={Images.getStarted}
                      className={classes.getStarted}
                      alt="get started"
                    />
                  </Grid>
                )}

                {status === postStatus.SCHEDULED && posts.length === 0 && (
                  <Grid item>
                    <Typography variant="h6" className={classes.errorMessage}>
                      {searchKeyword
                        ? `No posts found for "${searchKeyword}" in scheduled
                        posts`
                        : "You don't have any scheduled posts"}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.errorMessage}
                    >
                      Click on Write post to start
                    </Typography>

                    <img
                      src={Images.getStarted}
                      className={classes.getStarted}
                      alt="get started"
                    />
                  </Grid>
                )}

                {status === postStatus.DRAFT && posts.length === 0 && (
                  <Grid item>
                    <Typography variant="h6" className={classes.errorMessage}>
                      {searchKeyword
                        ? `No posts found for "${searchKeyword}" in drafts
                        posts`
                        : "You don't have any drafts posts"}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={classes.errorMessage}
                    >
                      Click on Write post to start
                    </Typography>

                    <img
                      src={Images.getStarted}
                      className={classes.getStarted}
                      alt="get started"
                    />
                  </Grid>
                )}

                {posts.length !== 0 && (
                  <InfiniteScroll
                    className={classes.scrollHeight}
                    dataLength={posts.length}
                    next={this.load}
                    hasMore={hasNextPage}
                    loader={<Loading />}
                  >
                    {posts.map((post: any, index: any) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          container
                          direction="row"
                          key={index}
                          onMouseEnter={() => {
                            this.setState({
                              postSelectionIndex: index
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({
                              postSelectionIndex: null
                            });
                          }}
                        >
                          <Grid item xs={8}>
                            <UnstyledLink
                              to={`/${publication._id}/posts/${post._id}`}
                            >
                              <Grid
                                item
                                container
                                direction="column"
                                xs={12}
                                className={clsx(
                                  classes.item,
                                  post.status !== postStatus.DRAFT &&
                                    classes.itemSelected
                                )}
                              >
                                <Typography
                                  variant="body1"
                                  className={classes.postTitle}
                                >
                                  {post.title ? post.title : "Untitled"}
                                  {this.getCompositeSEOSection(post)}
                                </Typography>

                                <Typography
                                  variant="body2"
                                  className={classes.labels}
                                >
                                  {this.getPostDueInfo(post)}
                                </Typography>
                                {this.getAuthor(post)}

                                <Grid
                                  container
                                  direction="row"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography
                                      variant="body2"
                                      className={classes.publishedOn}
                                    >
                                      {post.scheduledOn &&
                                        post.status === postStatus.PUBLISHED &&
                                        this.getScheduledOnFormat(
                                          post.scheduledOn
                                        )}
                                      {!post.scheduledOn &&
                                        post.status === postStatus.PUBLISHED &&
                                        formatForDisplayDateTime(
                                          post.publishedOn
                                        )}
                                      {!post.scheduledOn &&
                                        post.status !== postStatus.PUBLISHED &&
                                        formatForDisplayDateTime(
                                          post.modifiedOn
                                        )}

                                      {destinationList.length > 1 &&
                                        post.destination?.type && (
                                          <>
                                            {(post.scheduledOn ||
                                              post.publishedOn ||
                                              post.modifiedOn) &&
                                            post.destination?.type &&
                                            !this.getPostDueInfo(post)
                                              ? ` | `
                                              : ""}

                                            <span>
                                              {this.getPostDestination(
                                                post.destination
                                              )}
                                            </span>
                                          </>
                                        )}
                                    </Typography>
                                  </Grid>

                                  <Grid item>
                                    <Typography
                                      variant="body2"
                                      className={classes.publishedOn}
                                    >
                                      {post.type === postType.PAID && (
                                        <LockIcon
                                          className={classes.postStatusIcon}
                                        />
                                      )}
                                      {post.publishedElsewhere &&
                                        post.status ===
                                          postStatus.PUBLISHED && (
                                          <TaskIcon
                                            className={classes.postStatusIcon}
                                          />
                                        )}
                                      {post.isPrivate && (
                                        <MailIcon
                                          className={classes.postStatusIcon}
                                        />
                                      )}
                                      {post.featuredPost && (
                                        <StarIcon
                                          className={classes.starIcon}
                                        />
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <Grid style={{ display: "flex" }}>
                                  {post?.needContentRefresh && (
                                    <StyledChip
                                      variant="red"
                                      size="small"
                                      label={contentRefreshTypes.DECAYING_PAGES}
                                      length={15}
                                    />
                                  )}
                                  {post?.almostRankingContent && (
                                    <StyledChip
                                      variant="blue"
                                      size="small"
                                      label={contentRefreshTypes.ALMOST_RANKING}
                                      length={15}
                                    />
                                  )}
                                  {post?.splitPagesContent && (
                                    <StyledChip
                                      variant="orange"
                                      size="small"
                                      label={contentRefreshTypes.SPLIT_PAGES}
                                      length={15}
                                    />
                                  )}
                                  {post?.keywordCannibalizationContent && (
                                    <StyledChip
                                      variant="orange"
                                      size="small"
                                      label={
                                        contentRefreshTypes.KEYWORD_CANNIBALIZATION
                                      }
                                      length={15}
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </UnstyledLink>
                          </Grid>

                          <Grid item container direction="row-reverse" xs={4}>
                            <div
                              style={{
                                display: "block",
                                width: 40,
                                marginTop: 14
                              }}
                            >
                              {(selectedPosts.length ||
                                postSelectionIndex === index) && (
                                <StyledCheckbox
                                  checked={this.isPostSelected(post._id)}
                                  onClick={(event: any) =>
                                    this.handlePostSelection(event, post, index)
                                  }
                                  labelStyle={{
                                    margin: 0
                                  }}
                                />
                              )}
                            </div>
                            {(post.status === postStatus.PUBLISHED ||
                              post.status === postStatus.SCHEDULED) && (
                              <span className={classes.postMenuButton}>
                                <Tooltip title="Options">
                                  <IconButton
                                    onClick={(event) =>
                                      this.openMoreOptions(event, index)
                                    }
                                    size="large"
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </Tooltip>

                                <Popover
                                  id={"moreOption_" + index}
                                  open={showMoreOptions === index}
                                  anchorEl={anchorEl}
                                  onClose={this.closeMoreOptions}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center"
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                  }}
                                  PaperProps={{
                                    className: classes.popover
                                  }}
                                >
                                  {this.drawButtonsGrid(
                                    index,
                                    post.featuredPost,
                                    post.isPrivate,
                                    status,
                                    post
                                  )}
                                </Popover>
                              </span>
                            )}

                            {post.status === postStatus.PUBLISHED &&
                              !post.scheduledOn &&
                              !isAccessDisabled(
                                publication._id,
                                this.context,
                                pageAccess.POST_STATS
                              ) && (
                                <span className={classes.postMenuButton}>
                                  <Tooltip title="Stats">
                                    <IconButton
                                      onClick={(event) =>
                                        this.openStat(event, index)
                                      }
                                      size="large"
                                    >
                                      <BarChartIcon />
                                    </IconButton>
                                  </Tooltip>
                                </span>
                              )}

                            {(post.status === postStatus.DRAFT ||
                              post.status === postStatus.IN_REVIEW) &&
                              !isAccessDisabled(
                                publication._id,
                                this.context,
                                pageAccess.DELETE_POST
                              ) && (
                                <span className={classes.postMenuButton}>
                                  <Tooltip title="Delete post">
                                    <IconButton
                                      onClick={(event) =>
                                        this.deletConfirmation(index)
                                      }
                                      size="large"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </span>
                              )}
                            {[
                              postStatus.DRAFT,
                              postStatus.IN_REVIEW,
                              postStatus.SCHEDULED,
                              postStatus.PUBLISHED
                            ].includes(post.status) &&
                              !isAccessDisabled(
                                publication._id,
                                this.context,
                                pageAccess.EDIT_POST
                              ) && (
                                <span className={classes.postMenuButton}>
                                  <Tooltip
                                    title={
                                      post.status === postStatus.DRAFT
                                        ? "Track draft"
                                        : "Track post"
                                    }
                                  >
                                    <IconButton
                                      onClick={(event) =>
                                        this.trackDraft(index)
                                      }
                                      size="large"
                                    >
                                      <FontAwesomeIcon
                                        size="xs"
                                        icon={faChartGantt}
                                        rotation={0}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </span>
                              )}

                            {(post.status === postStatus.DRAFT ||
                              post.status === postStatus.IN_REVIEW) &&
                              (!isAccessDisabled(
                                publication._id,
                                this.context,
                                pageAccess.EDIT_POST
                              ) ||
                                clientId === post.postAuthor._id) && (
                                <span className={classes.postMenuButton}>
                                  <Tooltip title="Edit post">
                                    <IconButton
                                      onClick={(event) =>
                                        this.openPost(event, index)
                                      }
                                      size="large"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </span>
                              )}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </InfiniteScroll>
                )}
              </Grid>
            )}
          </Grid>
          {showFilter && (
            <Popover
              id="post_filter"
              open={showFilter}
              anchorEl={filterElement}
              onClose={this.toggleFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              PaperProps={{
                className: classes.filterPopover
              }}
              size="small"
            >
              <div className={classes.inputSection}>
                <StyledInputLabel label="Destination" />

                <StyledAutocomplete
                  value={destinationFilters}
                  options={destinationList}
                  onChange={this.onDestinationFilterChange}
                  isCheckboxSelected={this.isDestinationSelected}
                  showAvatar={false}
                />
              </div>

              <div className={classes.inputSection}>
                <StyledInputLabel label="SEO Score" />

                <StyledAutocomplete
                  value={compositeSEOFilters}
                  options={compositeSEOFilterOptions}
                  onChange={this.onCompositeSEOFilterChange}
                  isCheckboxSelected={this.isCompositeSEOSelected}
                  showAvatar={false}
                  showOptionType={false}
                />
              </div>

              <div className={classes.action}>
                <StyledButton
                  variant="primary"
                  size="medium"
                  onClick={() => {
                    this.setState({ replacePosts: true }, () => {
                      this.load();

                      this.toggleFilter();
                    });
                  }}
                >
                  Apply
                </StyledButton>
              </div>
            </Popover>
          )}

          <StyledDialog
            open={showDialog}
            title={dialogTitle}
            body={dialogMessage}
            successButtonName="Yes, I'm sure "
            cancelButtonName="Cancel"
            successCallback={this.handleConfirmSuccess}
            cancelCallback={() => {
              this.setState({
                showDialog: false,
                errorMessage: "",
                errorTitle: ""
              });
            }}
          />
          {!showBulkActionDialog && selectedPosts.length > 0 && (
            <Popper open={true} anchorEl={null} className={classes.popper}>
              <Paper className={classes.bulkActionPaper}>
                <Typography variant="bodym">
                  {`${selectedPosts.length} post${
                    selectedPosts.length === 1 ? "" : "s"
                  } selected to modify`}
                </Typography>

                <div>
                  <Tooltip title="Update Title">
                    <IconButton
                      onClick={() => {
                        this.handleBulkAction(postBulkActionTypes.UPDATE_TITLE);
                      }}
                      style={{
                        marginLeft: 10,
                        color: "white"
                      }}
                    >
                      <FontAwesomeIcon size="xs" icon={faHeading} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Update Tags">
                    <IconButton
                      onClick={() => {
                        this.handleBulkAction(postBulkActionTypes.UPDATE_TAGS);
                      }}
                      style={{
                        marginLeft: 10,
                        color: "white"
                      }}
                    >
                      <FontAwesomeIcon size="xs" icon={faTag} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Update Description">
                    <IconButton
                      onClick={() => {
                        this.handleBulkAction(
                          postBulkActionTypes.UPDATE_DESCRIPTION
                        );
                      }}
                      style={{
                        marginLeft: 10,
                        color: "white"
                      }}
                    >
                      <FontAwesomeIcon size="xs" icon={faAlignJustify} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Update URL">
                    <IconButton
                      onClick={() => {
                        this.handleBulkAction(postBulkActionTypes.UPDATE_URL);
                      }}
                      style={{
                        marginLeft: 10,
                        color: "white"
                      }}
                    >
                      <FontAwesomeIcon size="xs" icon={faLink} />
                    </IconButton>
                  </Tooltip>

                  {status === postStatus.PUBLISHED && (
                    <Tooltip title="Unpublish">
                      <IconButton
                        onClick={() => {
                          this.handleBulkAction(postBulkActionTypes.UNPUBLISH);
                        }}
                        style={{
                          marginLeft: 10,
                          color: "white"
                        }}
                      >
                        <FontAwesomeIcon size="xs" icon={faMinusCircle} />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => {
                        this.handleBulkAction(postBulkActionTypes.DELETE);
                      }}
                      style={{
                        marginLeft: 10,
                        color: "white"
                      }}
                    >
                      <FontAwesomeIcon size="xs" icon={faTrash} />
                    </IconButton>
                  </Tooltip>

                  <StyledButton
                    variant="textcontrastprimary"
                    onClick={this.cancelSelection}
                    style={{ marginLeft: 10 }}
                  >
                    Cancel
                  </StyledButton>
                </div>
              </Paper>
            </Popper>
          )}
          {showBulkActionDialog && (
            <PostBulkAction
              open={showBulkActionDialog}
              posts={selectedPosts}
              type={bulkActionType}
              onCloseDialog={this.closeBulkAssignDialog}
              publicationId={publication._id}
              onBulkActionSuccess={this.onBulkActionSuccess}
              tagSuggestions={tagSuggestions}
            />
          )}

          {showConfirmDialog && (
            <StyledDialog
              open={showConfirmDialog}
              title="Are you sure?"
              body={
                bulkActionType === postBulkActionTypes.DELETE
                  ? "Once deleted, you can't recover your posts"
                  : "You're about to unpublish your blog posts."
              }
              successButtonName={
                bulkActionType === postBulkActionTypes.DELETE
                  ? "Delete"
                  : "Unpublish"
              }
              cancelButtonName="Cancel"
              successCallback={this.handleBulkActionDialogSuccess}
              cancelCallback={() => {
                this.setState({
                  showConfirmDialog: false
                });
              }}
            />
          )}

          {showCompositeSEOScore && (
            <CompositeSEOScorePopup
              open={showCompositeSEOScore}
              anchorEl={compositeSEOScoreAnchorEl}
              seoScore={seoScore}
              onClose={() => {
                this.setCompositeSEOScore(false, null, null, null);
              }}
            />
          )}
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(Posts);
