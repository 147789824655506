// @ts-nocheck
import React from "react";
import { Typography, FormControlLabel, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import Styles from "./styles/StyledSelectableCardStyles";

import StyledCheckbox from "../design/components/StyledCheckbox";

import styled from "styled-components";

const StyledSVG = styled.svg`
  & path {
    fill: ${(props: any) => props.color};
  }
`;

const StyledSelectableCard = (props: any) => {
  let {
    id,
    classes,
    icon: Icon,
    title,
    description,
    checked,
    onChange
  } = props;
  return (
    <Grid container className={classes.container} onClick={() => onChange(id)}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <StyledSVG
          width="56"
          height="56"
          viewBox="-12 -12 40 40"
          color="blue"
          className={classes.icon}
        >
          <Icon />
        </StyledSVG>

        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
          }}
        >
          <Typography variant="h300">{title}</Typography>

          <Typography variant="bodys" className={classes.description}>
            {description}
          </Typography>
        </Grid>
      </Grid>

      <FormControlLabel
        control={<StyledCheckbox checked={checked} />}
        sx={{ margin: 0 }}
      />
    </Grid>
  );
};

export default withStyles(Styles)(StyledSelectableCard);
