import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import makeStyles from "@mui/styles/makeStyles";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import Skeleton from "@mui/material/Skeleton";
// @ts-ignore
import _ from "lodash";
import moment from "moment";

import RefreshIcon from "@mui/icons-material/Refresh";
import StyledDialog from "../design/components/StyledDialog";
import API from "../Services/Api";
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import { space } from "../Config/theme";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import { notify } from "./CustomNotifications";
import StyledButton from "../design/components/StyledButton";
import StyledTab from "../design/components/StyledTab";
import BetaText from "./BetaText";
import StyledInput from "../design/components/StyledInput";
import colors from "../Themes/Colors";
import StyledSwitch from "../design/components/StyledSwitch";
import StyledTable from "../design/components/StyledTable";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const api = API.create();

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: space.MEDIUM
  },
  formField: {
    marginTop: space.SMALL
  },
  divider: {
    marginTop: space.MEDIUM
  }
}));

const configurationTabs = {
  OPPORTUNITY: "Opportunity",
  CONTACT: "Contact"
};

interface ContactConfigurationProps {
  publicationId: string;
  handleClose: () => void;
}

interface ContactConfigurationRef {
  handleSave: () => void;
}

type MappingType = {
  [key: string]: { label: string; mapKey: string; isRequired: boolean };
};

const ContactConfiguration = forwardRef(
  (
    { publicationId, handleClose }: ContactConfigurationProps,
    ref: React.ForwardedRef<ContactConfigurationRef>
  ) => {
    const [loading, setLoading] = useState(true);
    const [shouldPushLinkedInActivity, setShouldPushLinkedInActivity] =
      useState(false);
    const [lastActivityPushOn, setLastActivityPushOn] = useState(null);
    const [isContactFieldsLoading, setIsContactFieldsLoading] = useState(true);
    const [errorDetails, setErrorDetails] = useState({
      error: false,
      message: ""
    });
    const [contactFields, setContactFields] = useState<
      { name: string; label: string; externalId: boolean }[]
    >([]);
    const [mapping, setMapping] = useState<MappingType>({});
    const emptyProperty = { name: "", label: "Select a property" };
    const [error, setError] = useState("");

    const setErrorMessage = (msg: string, timeout = 5000) => {
      setError(msg);
      if (timeout > 0) {
        setTimeout(() => setError(""), timeout);
      }
    };

    const loadContactFieldsFromSalesforce = () => {
      setIsContactFieldsLoading(true);
      api.getContactFieldsFromSalesforce(publicationId, (res) => {
        setIsContactFieldsLoading(false);
        if (res.status === 200) {
          setContactFields(res.data?.fields || []);
        } else {
          setErrorDetails({
            error: true,
            message: "Failed to fetch contact fields from Salesforce"
          });
        }
      });
    };

    const loadContactFieldMapping = () => {
      setLoading(true);
      api.getSalesforceConfig(publicationId, (res) => {
        setLoading(false);
        if (res.status === 200) {
          const {
            contactFieldMapping,
            linkedInActivityPush,
            lastActivityPushOn,
            lastErrorMessage,
            lastErroredAt
          } = res.data;
          setMapping(contactFieldMapping);
          setShouldPushLinkedInActivity(linkedInActivityPush);
          setLastActivityPushOn(lastActivityPushOn);
          if (lastErrorMessage) {
            setErrorMessage(lastErrorMessage, -1);
          }
        } else {
          setErrorDetails({
            error: true,
            message: "Failed to fetch contact field mapping"
          });
        }
      });
    };

    useEffect(() => {
      loadContactFieldsFromSalesforce();
      loadContactFieldMapping();
    }, []);

    useImperativeHandle(ref, () => ({
      handleSave
    }));

    const savePushToSalesforce = (val: boolean) => {
      api.setLinkedInActivityPushToSalesforce(publicationId, val, (res) => {
        if (res.status !== 200) {
          notify.show(
            "Failed to save LinkedIn activity push to Salesforce",
            "error"
          );
        }
      });
    };

    const handleSave = () => {
      const emptyMapLabel: string[] = [];
      const usedMapping: { [mapKey: string]: string } = {};
      const duplicateMapLabel: string[] = [];

      Object.keys(mapping).forEach((key) => {
        const { label, mapKey, isRequired } = mapping[key];

        if (!mapKey && isRequired) {
          emptyMapLabel.push(label);
          return;
        }

        if (!mapKey) return;

        if (usedMapping[mapKey]) {
          duplicateMapLabel.push(usedMapping[mapKey], label);
          return;
        }

        usedMapping[mapKey] = label;
      });

      if (emptyMapLabel.length) {
        let msg = emptyMapLabel.slice(0, 4).join(", ");
        msg += " can't be empty";
        setErrorMessage(msg);
        return;
      }

      if (duplicateMapLabel.length) {
        let msg = duplicateMapLabel.slice(0, 4).join(", ");
        msg += " have duplicate mapping";
        setErrorMessage(msg);
        return;
      }

      api.setSalesforceContactFieldMapping(publicationId, mapping, (res) => {
        if (res.status === 200) {
          handleClose();
        } else {
          notify.show(
            "Failed to save Details. Please try again later.",
            "error"
          );
        }
      });
    };

    if (!isContactFieldsLoading && errorDetails.error) {
      return <Alert severity="error">{errorDetails.message}</Alert>;
    }

    if (loading) {
      return <Skeleton width="100%" height={50} />;
    }

    return (
      <>
        <Grid
          item
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="bodym">
            Push LinkedIn activity to Salesforce Contacts
          </Typography>
          <Box display="flex" flexDirection="column">
            <StyledSwitch
              checked={shouldPushLinkedInActivity}
              onChange={(e: any) => {
                setShouldPushLinkedInActivity(e.target.checked);
                savePushToSalesforce(e.target.checked);
              }}
            />
            <Typography variant="bodys">
              {lastActivityPushOn &&
                `Last synced: ${moment(lastActivityPushOn).fromNow()}`}
            </Typography>
          </Box>
        </Grid>
        <Divider sx={{ marginTop: space.XS }} />
        <Grid
          item
          display="flex"
          justifyContent="space-between"
          my={space.MEDIUM}
          alignItems="center"
        >
          <Typography variant="bodym">
            Map Letterdrop fields to Salesforce contacts fields
          </Typography>

          <StyledButton
            variant="textprimary"
            startIcon={<RefreshIcon />}
            onClick={loadContactFieldsFromSalesforce}
            disabled={isContactFieldsLoading}
          >
            Refresh fields
          </StyledButton>
        </Grid>
        <Grid
          item
          display="flex"
          justifyContent="space-between"
          my={space.SMALL}
          alignItems="center"
        >
          <Typography variant="h300">Letterdrop Fields</Typography>
          <Typography variant="h300">Salesforce Fields</Typography>
        </Grid>
        {isContactFieldsLoading ? (
          <>
            {Array.from({ length: 4 }).map(() => (
              <Grid
                item
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={space.SMALL}
              >
                <Skeleton width="50%" height={60} />
                <Skeleton width="50%" height={60} />
              </Grid>
            ))}
          </>
        ) : (
          <Grid>
            {Object.keys(mapping).map((key) => {
              const { label, mapKey, isRequired } = mapping[key];
              return (
                <Box
                  key={key}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  marginBottom={space.MEDIUM}
                >
                  <Typography
                    variant="body2"
                    style={{
                      marginRight: 10,
                      width: "40%",
                      minWidth: "40%"
                    }}
                  >
                    {label}
                    {isRequired && " *"}
                  </Typography>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <Autocomplete
                      options={[emptyProperty, ...contactFields]}
                      value={
                        contactFields.find((p) => p.name === mapKey) ||
                        emptyProperty
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      // disabled={key === "vanityName"}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => {
                        const newMapping = _.cloneDeep(mapping);
                        newMapping[key].mapKey = value?.name || "";
                        setMapping(newMapping);
                      }}
                      size="medium"
                      fullWidth
                      renderOption={(params: any, option: any) => (
                        <StyledMenuItem {...params} key={option.name}>
                          {option.label}
                        </StyledMenuItem>
                      )}
                      renderInput={(params: any) => (
                        <StyledInput
                          {...params}
                          size="small"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              );
            })}
            <Grid
              item
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: space.SMALL,
                alignItems: "center"
              }}
            >
              {error && (
                <Typography variant="bodys" color={colors.failureRed}>
                  *{error}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </>
    );
  }
);

interface OpportunityConfigurationProps {
  publicationId: string;
  handleClose: () => void;
}

interface OpportunityConfigurationRef {
  handleSave: () => void;
}

const OpportunityConfiguration = forwardRef(
  (
    { publicationId, handleClose }: OpportunityConfigurationProps,
    ref: React.ForwardedRef<OpportunityConfigurationRef>
  ) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [errorDetails, setError] = useState({
      error: false,
      message: ""
    });
    const [selectionError, setSelectionError] = useState(false);
    const [pipelineField, setPipelineField] = useState("");
    const [pipelineFieldIndex, setPipelineFieldIndex] = useState<number>(-1);
    const [crmPipeline, setCrmPipeline] = useState("");
    const [pipelineFields, setPipelineFields] = useState<
      Array<{
        id: string;
        label: string;
        values: { id: string; label: string }[];
      }>
    >([]);
    const [pipelines, setPipelines] = useState<
      Array<{
        id: string;
        fieldName: string;
        name: string;
        dealStages: { closedwon: string; closedlost: string };
      }>
    >([]);
    const [openAddPipelineDialog, setOpenAddPipelineDialog] =
      useState<boolean>(false);
    const [stages, setStages] = useState<{ id: string; label: string }[]>([]);
    const [closedWonStage, setClosedWonStage] = useState<string>("");
    const [closedLostStage, setClosedLostStage] = useState<string>("");

    const load = () => {
      api.getPipelineFieldsFromSalesforce(publicationId, (res) => {
        setLoading(false);
        if (res.status === 200) {
          setPipelines(res.data?.pipelines || []);
          const pipelineFields = [];
          let stages = [];
          for (let field of res.data?.pipelineFields) {
            if (field.id === "StageName") {
              stages = field?.values || [];
            } else {
              pipelineFields.push(field);
            }
          }
          for (let index = 0; index < pipelineFields.length; index++) {
            const field = pipelineFields[index];
            if (field.id === res.data?.pipelineField) {
              setPipelineFieldIndex(index);
            }
          }
          setStages(stages);
          setPipelineFields(pipelineFields);
          setPipelineField(res.data?.pipelineField);
          if (
            pipelineFields.find((item) => item.id === res.data?.pipelineField)
          ) {
            setCrmPipeline(res.data?.crmPipeline);
          }
          if (
            stages.find(
              (item: any) => item.id === res.data?.dealStages?.closedwon
            )
          ) {
            setClosedWonStage(res.data?.dealStages?.closedwon);
          }
          if (
            stages.find(
              (item: any) => item.id === res.data?.dealStages?.closedlost
            )
          ) {
            setClosedLostStage(res.data?.dealStages?.closedlost);
          }
        } else {
          setError({
            error: true,
            message: "Failed to fetch data from Salesforce"
          });
        }
      });
    };

    useEffect(load, []);

    useImperativeHandle(ref, () => ({
      handleSave
    }));

    const onPipelineSave = () => {
      let newPipelines = [...pipelines];
      const closedwon = stages.find(
        (item) => item.id === closedWonStage
      )?.label;
      const closedlost = stages.find(
        (item) => item.id === closedLostStage
      )?.label;
      const pipelineName = pipelineFields[pipelineFieldIndex]?.values?.find(
        (item) => item.id === crmPipeline
      )?.label;
      if (
        !pipelineField ||
        !pipelineFields.find((item) => item.id === pipelineField) ||
        !closedWonStage ||
        !stages.find((item) => item.id === closedWonStage) ||
        !closedLostStage ||
        !stages.find((item) => item.id === closedLostStage) ||
        !crmPipeline ||
        !pipelineFields[pipelineFieldIndex]?.values?.find(
          (item) => item.id === crmPipeline
        )
      ) {
        setSelectionError(true);
        return;
      }

      newPipelines.push({
        id: crmPipeline,
        fieldName: pipelineField,
        name: pipelineName || "",
        dealStages: {
          closedwon: closedwon || "",
          closedlost: closedlost || ""
        }
      });
      setPipelines(newPipelines);
      setOpenAddPipelineDialog(false);
    };

    const handleSave = () => {
      api.setSalesforcePipelineFields(
        publicationId,
        pipelineField,
        pipelines,
        (res) => {
          if (res.status !== 200) {
            notify.show(
              "Failed to save Details. Please try again later.",
              "error"
            );
          }
        }
      );
    };

    useEffect(handleSave, [publicationId, pipelineField, pipelines]);

    const deletePipeline = (index: number) => {
      let newPipelines = [...pipelines];
      newPipelines.splice(index, 1);
      setPipelines(newPipelines);
    };

    const getTableBody = () => {
      return pipelines.map((pipeline: any, index: number) => {
        let data = [
          { value: pipeline.name, style: { width: "90%" } },
          {
            value: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <div>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => deletePipeline(index)}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ),
            style: { width: "10%" }
          }
        ];
        return {
          id: index,
          data
        };
      });
    };

    if (loading) {
      return <Skeleton width="100%" height={50} />;
    } else if (errorDetails.error) {
      return <Alert severity="error">{errorDetails.message}</Alert>;
    }

    return (
      <>
        <Typography variant="bodym">
          Select appropriate field and value for Opportunities that will be used
          for attribution
        </Typography>
        <FormControl className={classes.formControl} fullWidth>
          <Typography variant="bodym">
            Select the field that represents the Type of the Opportunity
          </Typography>
          <StyledSelect
            onChange={(event: any) => {
              setPipelineField(event.target.value);
              setPipelineFieldIndex(
                pipelineFields.findIndex(
                  (item) => item.id === event.target.value
                )
              );
              setSelectionError(false);
            }}
            placeholder="Select Field"
            value={pipelineField}
            className={classes.formField}
          >
            {pipelineFields.map((item) => (
              <StyledMenuItem key={item.id} value={item.id}>
                {item.label}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        <StyledTable
          tableWidth="100%"
          tableContainerProps={{
            style: { marginTop: space.SMALL }
          }}
          headerArray={[]}
          bodyArray={getTableBody()}
        />
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <StyledButton
            variant="textprimary"
            style={{ marginTop: space.SMALL }}
            onClick={() => setOpenAddPipelineDialog(true)}
            startIcon={<AddCircleIcon />}
          >
            Add Pipeline
          </StyledButton>
        </Grid>
        <StyledDialog
          open={openAddPipelineDialog}
          title="Add pipelines"
          body={
            <>
              {pipelineFieldIndex >= 0 && (
                <FormControl className={classes.formControl} fullWidth>
                  <Typography variant="bodym">
                    Select the types of Opportunities to use in revenue
                    attribution
                  </Typography>
                  <StyledSelect
                    onChange={(event: any) => {
                      setCrmPipeline(event.target.value);
                      setSelectionError(false);
                    }}
                    placeholder="Select Value"
                    value={crmPipeline}
                    className={classes.formField}
                  >
                    {pipelineFields[pipelineFieldIndex]?.values?.map((item) => (
                      <StyledMenuItem key={item.id} value={item.id}>
                        {item.label}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              )}
              <Divider className={classes.divider} />
              <FormControl className={classes.formControl} fullWidth>
                <Typography variant="bodym">
                  Select a stage corresponds to "Closed/Won" Opportunities
                </Typography>
                <StyledSelect
                  onChange={(event: any) => {
                    setClosedWonStage(event.target.value);
                    setSelectionError(false);
                  }}
                  placeholder="Select Stage"
                  value={closedWonStage}
                  className={classes.formField}
                >
                  {stages.map((item) => (
                    <StyledMenuItem key={item.id} value={item.id}>
                      {item.label}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                <Typography variant="bodym">
                  Select a stage corresponds to "Closed/Lost" Opportunities
                </Typography>
                <StyledSelect
                  onChange={(event: any) => {
                    setClosedLostStage(event.target.value);
                    setSelectionError(false);
                  }}
                  placeholder="Select Stage"
                  value={closedLostStage}
                  className={classes.formField}
                >
                  {stages.map((item) => (
                    <StyledMenuItem key={item.id} value={item.id}>
                      {item.label}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </>
          }
          successButtonName="Save"
          successCallback={onPipelineSave}
          cancelButtonName="Close"
          cancelCallback={() => setOpenAddPipelineDialog(false)}
        />
        {selectionError && (
          <Alert
            severity="warning"
            style={{
              marginTop: 10
            }}
          >
            Select all the fields
          </Alert>
        )}
      </>
    );
  }
);

export default function SalesforceCRMConfiguration({
  open,
  handleClose,
  publicationId
}: any) {
  const [currentTab, setCurrentTab] = useState(configurationTabs.OPPORTUNITY);
  const opportunityConfigurationRef = useRef<OpportunityConfigurationRef>(null);
  const contactConfigurationRef = useRef<ContactConfigurationRef>(null);

  function handleSave() {
    if (
      currentTab === configurationTabs.OPPORTUNITY &&
      opportunityConfigurationRef.current
    ) {
      handleClose();
      notify.show("Pipelines saved", "success");
    } else if (
      currentTab === configurationTabs.CONTACT &&
      contactConfigurationRef.current
    ) {
      contactConfigurationRef.current.handleSave();
    }
  }

  return (
    <>
      <StyledDialog
        open={open}
        title="Configure Salesforce CRM"
        body={
          <>
            <StyledTab
              // @ts-ignore
              value={currentTab}
              tabs={[
                {
                  label: "Opportunity",
                  value: configurationTabs.OPPORTUNITY
                },
                {
                  label: "Contact",
                  value: configurationTabs.CONTACT,
                  icon: <BetaText />,
                  iconPosition: "end"
                }
              ]}
              onChange={(event: any, value: any) => setCurrentTab(value)}
            />
            {currentTab === configurationTabs.OPPORTUNITY && (
              <OpportunityConfiguration
                publicationId={publicationId}
                handleClose={handleClose}
                ref={opportunityConfigurationRef}
              />
            )}
            {currentTab === configurationTabs.CONTACT && (
              <ContactConfiguration
                publicationId={publicationId}
                handleClose={handleClose}
                ref={contactConfigurationRef}
              />
            )}
          </>
        }
        successButtonName="Save"
        successCallback={handleSave}
        cancelButtonName="Close"
        cancelCallback={handleClose}
      />
    </>
  );
}
