// @ts-nocheck
import { Typography } from "@mui/material";
import React from "react";
import { designColors } from "../Themes/Colors";

export default function BetaText() {
  return (
    <Typography
      variant="bodym"
      style={{
        fontWeight: "bold",
        margin: "auto 0",
        color: designColors.primary[100],
        marginLeft: 10,
        verticalAlign: "super"
      }}
    >
      BETA
    </Typography>
  );
}
