import React, { useEffect, useState } from "react";
import { Divider, FormControl, Typography } from "@mui/material";
import StyledDialog from "../design/components/StyledDialog";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import colors, { designColors } from "../Themes/Colors";
import { Sample } from "./AIGeneratePost";
import { space } from "../Config/theme";
import { getShortVersionOfText } from "../Utils/UserUtils";
import Api from "../Services/Api";
import { AxiosResponse } from "axios";
import { channelTypes } from "../Utils/Types";

const api = Api.create();

type LinkedInVideoToPostConfirmDialogProps = {
  open: boolean;
  publicationId: string;
  linkedinPostIndex: number;
  onSuccess: (linkedinPostIndex: number, selectedSampleId: string) => void;
  onCancel: () => void;
};

export default function LinkedInVideoToPostConfirmDialog({
  open,
  publicationId,
  linkedinPostIndex,
  onSuccess,
  onCancel
}: LinkedInVideoToPostConfirmDialogProps) {
  const [selectedSampleId, setSelectedSampleId] = useState<string>("");
  const [selectedSample, setSelectedSample] = useState<string>("");
  const [selectedSampleLabel, setSelectedSampleLabel] = useState("");
  const [samples, setSamples] = useState<Array<Sample>>([]);

  useEffect(() => {
    try {
      api.getSamples(
        publicationId,
        channelTypes.LINKEDIN,
        (res: AxiosResponse) => {
          if (res.status === 200) {
            let allSamples = res?.data || [];
            setSamples(allSamples);
            setSelectedSample(allSamples?.[0]?.sample || "");
            setSelectedSampleId(allSamples?.[0]?._id?.toString() || "");
          }
        }
      );
    } catch (error) {}
  }, []);

  useEffect(() => {
    let selected = samples.find((s) => s._id === selectedSampleId);
    setSelectedSampleLabel(selected?.label || "");
  }, [selectedSampleId, samples]);

  return (
    <StyledDialog
      open={open}
      title="Draft a post from the video?"
      body={
        <div>
          <Typography variant="bodym">
            We can take the transcript from the video and generate a post for
            you.
          </Typography>
          <Divider sx={{ margin: `${space.MEDIUM} 0px` }} />
          <div>
            <div style={{ marginBottom: space.MEDIUM }}>
              <Typography variant="bodyl">Template</Typography>
              <br />
              <Typography
                style={{
                  color: colors.fontSecondary
                }}
                variant="bodym"
              >
                Use a template to format your post
              </Typography>
            </div>
            <FormControl
              variant="outlined"
              style={{
                display: "block",
                width: "100%"
              }}
              fullWidth
            >
              <StyledSelect
                value={selectedSampleId}
                defaultValue={selectedSampleId}
                renderValue={() => selectedSampleLabel}
                onChange={(event: any) => {
                  setSelectedSampleId(event.target.value);
                  setSelectedSample(
                    samples.find(
                      (sample: Sample) =>
                        sample?._id?.toString() === event.target.value
                    )?.sample || ""
                  );
                }}
                placeholder="Choose a sample"
                fullWidth
                disabled={!Boolean(samples.length)}
              >
                {samples.map((sample: Sample, index) => {
                  return (
                    <StyledMenuItem
                      key={index}
                      value={sample?._id?.toString()}
                      style={{ width: "100%" }}
                    >
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: 650
                        }}
                      >
                        <Typography
                          variant="bodym"
                          style={{
                            margin: 0,
                            marginLeft: 10,
                            display: "inline-flex",
                            verticalAlign: "bottom"
                          }}
                        >
                          {sample?.label || sample?.sample}
                        </Typography>
                        <br />
                        <Typography
                          variant="bodys"
                          style={{
                            color: designColors.grayScale[60],
                            marginTop: space.XXS
                          }}
                        >
                          {getShortVersionOfText(sample?.sample, 140)}
                        </Typography>
                      </div>
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </div>
        </div>
      }
      successButtonName="Yes, do it"
      successCallback={() => {
        onSuccess(linkedinPostIndex, selectedSampleId);
      }}
      cancelButtonName="Skip"
      cancelCallback={onCancel}
    />
  );
}
