import React from "react";

interface DocumentViewerProps {
  documentUrl: string;
}

interface ErrorComponentProps {
  error: Error;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ documentUrl }) => {
  const fileTypes = {
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    pdf: "application/pdf",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  };

  const CustomErrorComponent: React.FC<ErrorComponentProps> = ({ error }) => (
    <div>Unable to view this document: {error.message}</div>
  );

  return (
    <div
      style={{
        width: "100%",
        margin: "16px 0",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        overflow: "hidden"
      }}
    >
      <iframe
        style={{
          display: "block",
          width: "100%",
          height: "600px",
          border: "0"
        }}
        src={`https://docs.google.com/gview?url=${encodeURIComponent(
          documentUrl
        )}&embedded=true`}
        title="Document Viewer"
      ></iframe>
    </div>
  );
};

export default DocumentViewer;
