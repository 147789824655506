// @ts-nocheck
import CloseIcon from "@mui/icons-material/Close";

import { ReactComponent as SearchIcon } from "../../Images/icon20/search.svg";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import * as React from "react";

import StyledInput from "./StyledInput";

export default function SearchBar({
  placeholder = "Search",
  onRequestSearch,
  onCancelSearch,
  value,
  onChange,
  toggleSearch,
  style = {},
  inputProps = {},
  size = "large",
  ...others
}: any) {
  function handleKeyDownEvent(event: any) {
    event.which === 13 && onRequestSearch && onRequestSearch();
  }

  function handleAction(event: any) {
    if (!value) {
      return;
    }
    toggleSearch && toggleSearch();
    onCancelSearch && onCancelSearch();
  }

  function handleOnBlur(event: any) {
    if (!toggleSearch) {
      return;
    }

    if (value) {
      return;
    }

    toggleSearch();
  }

  return (
    <StyledInput
      placeholder={placeholder}
      onKeyDown={handleKeyDownEvent}
      value={value}
      onChange={(event: any) => onChange(event.target.value)}
      onBlur={handleOnBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size={size}
              onClick={handleAction}
              sx={{ right: "0px", position: "absolute" }}
            >
              {(value && <CloseIcon />) || <SearchIcon />}
            </IconButton>
          </InputAdornment>
        ),
        sx: { paddingRight: "40px" }
      }}
      style={style}
      size={size}
      {...inputProps}
      {...others}
    />
  );
}
