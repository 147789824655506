// @ts-nocheck
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Popover,
  Tooltip,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";
import { nanoid } from "nanoid";
import React, { Fragment } from "react";

import CopyToClipboard from "react-copy-to-clipboard";

import StyledInput from "../design/components/StyledInput";
import { notify } from "../Components/CustomNotifications";

import ImagePicker from "../Components/ImagePicker";

import Loading from "../Components/Loading";
import StyledButton from "../design/components/StyledButton";

import StyledDialog from "../design/components/StyledDialog";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import API from "../Services/Api";
import colors, { designColors } from "../Themes/Colors";
import ImagesList from "../Themes/Images";
import PageAccessContext from "../Utils/PageAccessContext";
import { uploadBlobImage } from "../Utils/Storage";
import { formatForTimeWithTimeZone } from "../Utils/TimeUtils";
import {
  datePickerDefault,
  INSTAGRAM_CAPTION_LIMIT,
  pageAccess,
  postStatus,
  socialMediaType,
  socialMediaStatus,
  URL_REPLACE_TEXT
} from "../Utils/Types";
import {
  formatNumber,
  getQueryParamsFromURL,
  hideOtherChatWidgets,
  isAccessDisabled,
  showOtherChatWidgets
} from "../Utils/UserUtils";
import styles from "./styles/InstagramShareStyle";

import { Helmet } from "react-helmet";

import CustomFlatPicker from "../Components/CustomFlatPicker";
import { space } from "../Config/theme";
import images from "../Themes/Images";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

const Page404 = React.lazy(() => import("../Containers/Page404"));

const _ = require("lodash");
const api = API.create();
const DEFAULT_INDEX = -1;

const INSTAGRAM = {
  POST_TO: "account",
  POST_CAPTION: "caption",
  PUBLISH_TYPE: "publishType"
};

const publishTypes = {
  NOW: "now",
  LATER: "later",
  WITH_POST: "with_post",
  TEN_MINUTES_LATER: "ten_minutes_later",
  THIRTY_MINUTES_LATER: "thirty_minutes_later",
  HOUR_LATER: "hour_later",
  DAY_LATER: "day_later"
};

const errors = {
  ASPECT_RATIO: 2207009,
  IMAGE_SIZE: 2207004,
  TAGS_COUNT: 2207040,
  HASHTAGS_COUNT: 2207010
};

class InstagramShare extends React.Component {
  static contextType = PageAccessContext;
  imagePickerRef: any;
  saveInstagramDetailsDebounce: any;
  constructor(props: any) {
    super(props);
    this.state = {
      id:
        props.match?.params?.instagramid ||
        props.match?.params?.id ||
        props.postId,
      post: null,
      publication: null,
      slug: null,
      instagramHandle: null,
      account: null,
      loading: true,
      scheduledOn: false,
      postInitialized: false,
      showDeleteConfirmDialog: false,
      showMultiImageDialog: false,
      openImagePicker: false,
      instagramPosts: [],
      backpage: getQueryParamsFromURL(window.location.search, "posts").backpage,
      agencyView: getQueryParamsFromURL(window.location.search, "posts")
        .agencyView,
      connectedToFacebook: false,
      openDatePicker: false,
      draftsCount: 0,
      showCopyConfirmation: false,
      copyIndex: DEFAULT_INDEX,
      scheduledPostDates: []
    };

    this.imagePickerRef = React.createRef();
  }

  componentDidMount = () => {
    this.load();
    this.saveInstagramDetailsDebounce = _.debounce(
      this.saveInstagramDetails,
      1000
    );
    setTimeout(() => {
      hideOtherChatWidgets();
    }, 3000);
  };

  componentWillUnmount() {
    showOtherChatWidgets();
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (this.props.match?.params.id !== prevProps.match?.params.id) {
      this.setState(
        {
          id: this.props.match?.params?.id || this.props.postId,
          post: null,
          publication: null,
          slug: null,
          instagramHandle: null,
          account: null,
          loading: true,
          scheduledOn: false,
          postInitialized: false,
          showDeleteConfirmDialog: false,
          showMultiImageDialog: false,
          openImagePicker: false,
          instagramPosts: [],
          backpage: getQueryParamsFromURL(window.location.search, "posts")
            .backpage,
          agencyView: getQueryParamsFromURL(window.location.search, "posts")
            .agencyView,
          connectedToFacebook: false,
          openDatePicker: false
        },
        this.load
      );
    }
  }

  refresh = () => {
    window.location.reload();
  };

  load = () => {
    let { id } = this.state;
    api.getInstagramDetails(id, (res: any) => {
      if (res.status === 200) {
        let {
          post,
          postUrl,
          publication,
          instagramHandle,
          instagramPosts,
          dedicatedPost,
          connectedToFacebook,
          draftsCount
        } = res.data;

        if (instagramPosts.length === 0) {
          instagramPosts.push(
            this.addInstagramPostRow(post, postUrl, publication)
          );
        }

        this.setState({
          post,
          slug: postUrl,
          instagramHandle,
          loading: false,
          publication,
          instagramPosts,
          dedicatedPost,
          connectedToFacebook,
          draftsCount
        });
      } else {
        this.setState({
          error: true,
          errorMessage:
            res.status === 400
              ? res.data
              : "Unable to fetch your workspace details"
        });
        return;
      }
    });
  };

  getDefaultCaptionText = (
    post: any,
    publication: any,
    postUrl: any,
    utm: any
  ) => {
    if (!post || !publication || !postUrl) {
      return;
    }

    return `We just published a blog post on ${post.title} at ${publication.name}. \n\nRead the full post here: ${URL_REPLACE_TEXT}`;
  };

  addInstagramPostRow = (post = null, postUrl = null, publication = null) => {
    let utm = nanoid(10);
    let newInstagramPost = {
      _id: null,
      account: null,
      images: [],
      post: post,
      postUrl: postUrl,
      publication: publication,
      caption: this.getDefaultCaptionText(post, publication, postUrl, utm),
      scheduledOn: null,

      publishType: post?._id ? publishTypes.WITH_POST : publishTypes.NOW,
      status: socialMediaStatus.DRAFT,
      utm
    };

    return newInstagramPost;
  };

  handlePostToAnotherAccount = () => {
    let { instagramPosts, post, publication, postUrl } = this.state;
    if (instagramPosts.length > 0 && instagramPosts[0]) {
      let lastPostIndex = instagramPosts.length - 1; //Fetch details from last post
      let utm = nanoid(10);
      let { account, accountName, images, scheduledOn, publishType, caption } =
        instagramPosts[lastPostIndex];
      let instagramPost = {
        _id: null,
        images: [...images],
        caption:
          caption ||
          this.getDefaultCaptionText(post, publication, postUrl, utm),
        scheduledOn,
        publishType,
        status: socialMediaStatus.DRAFT,
        utm
      };

      instagramPosts.push(instagramPost);
    } else {
      instagramPosts.push(this.addInstagramPostRow(post));
    }
    this.setState({
      instagramPosts
    });
  };

  handleContentChange = (event: any, index: any) => {
    let { instagramPosts } = this.state;
    let { name, value } = event.target;

    if (name === INSTAGRAM.POST_TO) {
      instagramPosts[index]["account"] = value.userId;
      instagramPosts[index]["accountName"] = value.name;
    }

    if (name === INSTAGRAM.POST_CAPTION) {
      if (value.length <= INSTAGRAM_CAPTION_LIMIT) {
        instagramPosts[index][INSTAGRAM.POST_CAPTION] = value;
      }
    }

    this.setState({ instagramPosts }, () => {
      if (name === INSTAGRAM.POST_CAPTION) {
        this.saveInstagramDetailsDebounce(index);
      } else {
        this.saveInstagramDetails(index);
      }
    });
  };

  onCopy = () => {
    notify.show("Copied to clipboard", "success");
  };

  getScheduleDate = (scheduledDate: any) => {
    let formattedScheduledDate = "";
    if (scheduledDate) {
      formattedScheduledDate = formatForTimeWithTimeZone(moment(scheduledDate));
    }
    //Reason to use &nbsp; instead of space
    //If we use space its getting trimmed in dropdown menu
    return formattedScheduledDate ? (
      <>
        on&nbsp;<b>{formattedScheduledDate.split("at")[0]}</b> at{" "}
        <b>{formattedScheduledDate.split("at")[1]}</b>
      </>
    ) : (
      ""
    );
  };

  handlePublishTypeChange = (event: any, index: any) => {
    let { instagramPosts } = this.state;
    if (event.target.value === publishTypes.LATER) {
      // handled by onClick - handlePublishLater
      return;
    } else {
      instagramPosts[index].scheduledOn = null;
      instagramPosts[index].publishType = event.target.value;
    }
    this.setState({ instagramPosts, instagramPostIndex: index }, () => {
      this.saveInstagramDetailsDebounce(index);
    });
  };

  getScheduledPostDates = () => {
    let { instagramPosts, publication, instagramPostIndex } = this.state;
    api.getScheduledPostDates(
      publication._id,
      instagramPosts[instagramPostIndex]?._id,
      instagramPosts[instagramPostIndex]?.account,
      socialMediaType.INSTAGRAM,
      (res: any) => {
        if (res.status === 200) {
          this.setState(
            { scheduledPostDates: res.data?.scheduledPostDates || [] },
            () => this.setState({ openDatePicker: true })
          );
        }
      }
    );
  };

  handlePublishLater = (index: any) => {
    let { instagramPosts } = this.state;
    this.setState({ instagramPostIndex: index }, () =>
      this.getScheduledPostDates(index)
    );
  };

  onScheduleDateChange = (date: any) => {
    let { instagramPosts, instagramPostIndex } = this.state;
    let currentDate = new Date();
    if (date > currentDate) {
      instagramPosts[instagramPostIndex].scheduledOn = date;
      instagramPosts[instagramPostIndex].publishType = publishTypes.LATER;
      this.setState({ instagramPosts }, () => {
        this.saveInstagramDetailsDebounce(instagramPostIndex);
      });
    }
  };

  toggleImagePicker = (index = null) => {
    let { openImagePicker } = this.state;
    this.setState({
      openImagePicker: !openImagePicker,
      instagramPostIndex: index
    });
  };

  onImageSelected = (imageUrl: any) => {
    let { instagramPosts, instagramPostIndex } = this.state;
    instagramPosts[instagramPostIndex].images = [imageUrl];
    this.setState({ instagramPosts }, () => {
      this.toggleImagePicker();
      this.saveInstagramDetailsDebounce(instagramPostIndex);
    });
  };

  onSelectFile = (file: any) => {
    this.setState({ uploadingImage: true, openImagePicker: false });
    const IMAGE_SIZE_LIMIT = 8 * 1024 * 1024; // 8MB Limit for image
    if (!file.type.includes("image")) {
      notify.show("Only images are allowed", "error");
    }

    if (file.size > IMAGE_SIZE_LIMIT) {
      notify.show("Upload a image that's smaller than 8 MB", "error");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      let imageUrl = "";
      if (
        reader.result?.startsWith("blob:") ||
        reader.result?.startsWith("data:")
      ) {
        imageUrl = await uploadBlobImage(
          reader.result,

          this.state.publication.domain
        );
      }
      if (!imageUrl) {
        return;
      }

      let { instagramPosts, instagramPostIndex } = this.state;

      instagramPosts[instagramPostIndex].images = [imageUrl];
      this.setState({ instagramPosts, uploadingImage: false }, () => {
        this.saveInstagramDetailsDebounce(instagramPostIndex);
      });
    });
    reader.readAsDataURL(file);
  };

  removeImage = (imageIndex: any, postIndex: any) => {
    let { instagramPosts } = this.state;
    instagramPosts[postIndex].images.splice(imageIndex, 1);
    this.setState({ instagramPosts });
  };

  saveInstagramDetails = (postIndex = null) => {
    let { id, instagramPosts, post } = this.state;

    if (postIndex === null) {
      return;
    }

    let savingPost = instagramPosts[postIndex];
    api.saveInstagramShareDetails(
      id,
      savingPost._id,
      savingPost.account,
      savingPost.accountName,
      savingPost.images,
      savingPost.caption,
      savingPost.publishType,
      savingPost.scheduledOn,
      savingPost.utm,
      (res: any) => {
        if (res.status === 200) {
          instagramPosts[postIndex]._id = res.data;
          this.setState({ instagramPosts });
        }
        if (res.status === 400) {
          notify.show("Something went wrong. Please try again.", "error");
        }
      }
    );
  };

  handlePostToInstagram = () => {
    let { postInitialized, publication } = this.state;
    let isPublishAccessDisabled = isAccessDisabled(
      publication._id,
      this.context,
      pageAccess.PUBLISH_POST
    );
    if (isPublishAccessDisabled) {
      notify.show("You don’t have the right permissions to publish", "error");
      return;
    }
    if (postInitialized) {
      return;
    }

    this.setState({ postInitialized: true }, () => {
      let { instagramPosts, post } = this.state;
      let valid = true;

      for (let instagramPost of instagramPosts) {
        if (!instagramPost.account) {
          valid = false;
          notify.show("Select Instagram account to share", "error");
          break;
        }
        if (instagramPost.images.length === 0) {
          valid = false;
          notify.show("Upload some image to share", "error");
          break;
        }
      }

      if (!valid) {
        this.setState({
          postInitialized: false
        });
        return;
      }

      api.shareToInstagram(
        publication._id,
        post?._id || null,
        instagramPosts,
        (res: any) => {
          if (res.status === 200) {
            let instagramPostResponses = res.data;
            let hasScheduled = false;
            let scheduledPost = null;
            let hasErrors = false;
            for (let instagramPostResponse of instagramPostResponses) {
              if (instagramPostResponse?.scheduledOn && !hasScheduled) {
                hasScheduled = true;
                scheduledPost = instagramPostResponse;
              }
              if (instagramPostResponse.error) {
                hasErrors = true;
              }
            }
            if (hasScheduled) {
              notify.show("Your Instagram post was scheduled", "success");
            }

            if (hasErrors) {
              notify.show(
                "Error while publishing your Instagram post.",
                "error"
              );
              this.setState({
                instagramPosts: instagramPostResponses,
                postInitialized: false
              });
              return;
            }
            this.gotoBackPageORClearFields(hasScheduled, scheduledPost);
          }
          if (res.status === 400) {
            notify.show("Oops! Something went wrong", "error");
          }
        }
      );
    });
  };

  gotoBackPageORClearFields = (hasScheduled: any, scheduledPost: any) => {
    let { publication, backpage, agencyView } = this.state;

    let { isReviewScreen } = this.props;

    if (agencyView) {
      window.location.href = `/${backpage}`;
      return;
    }

    if (hasScheduled && scheduledPost?._id) {
      let toUrl = `/${publication._id}/calendar/?mediaId=${
        scheduledPost._id
      }&createdOn=${new Date(scheduledPost.scheduledOn).toISOString()}`;

      this.props.history.push(toUrl);
      return;
    }

    if (!isReviewScreen) {
      this.props.history.push(`/${publication._id}/${backpage}`);
      return;
    }

    this.setState({ postInitialized: false }, this.load);
  };

  toggleDeleteConfirmDialog = (index: any) => {
    let { showDeleteConfirmDialog } = this.state;
    this.setState({
      showDeleteConfirmDialog: !showDeleteConfirmDialog,
      instagramPostIndex: index
    });
  };

  handleDialogClose = () => {
    this.setState({
      showDeleteConfirmDialog: false,
      instagramPostIndex: null
    });
  };

  deletePost = () => {
    let { publication, instagramPosts, instagramPostIndex } = this.state;
    let deletedRow = instagramPosts.slice(instagramPostIndex, 1);

    if (!deletedRow[0]?._id) {
      instagramPosts.splice(instagramPostIndex, 1);
      this.setState({
        instagramPosts,
        showDeleteConfirmDialog: false
      });
      return;
    }

    api.deleteInstagramPost(publication._id, deletedRow[0]._id, (res: any) => {
      if (res.status === 200) {
        instagramPosts.splice(instagramPostIndex, 1);
        this.setState({
          instagramPosts,
          showDeleteConfirmDialog: false,
          instagramPostIndex: null
        });
      }
      if (res.status === 400) {
        notify.show("Delete failed.", "error");
      }
    });
  };

  filterAccountById = (accounts: any, id: any) => {
    return accounts.filter((account: any) => {
      return account.userId === id;
    })[0];
  };

  getErrorText = (error: any) => {
    let { classes } = this.props;
    return (
      <Typography variant="caption" className={classes.errorText}>
        {error.message} {this.getErrorDescription(error)}
      </Typography>
    );
  };

  getErrorDescription = (error: any) => {
    if (!error.error_subcode) {
      return error.error_user_msg || "Something went wrong";
    }
    let message = error.error_user_msg || "Something went wrong";
    switch (error.error_subcode) {
      case errors.ASPECT_RATIO:
        message = "Your image needs to have a 1:1 or 1.91:1 aspect ratio.";
        break;
      case errors.IMAGE_SIZE:
        message = "Your image has to be smaller than 8 MB.";
        break;
      case errors.TAGS_COUNT:
        message = "You can't use more than 20 tags in your post.";
        break;
      case errors.HASHTAGS_COUNT:
        message = "You can't use more than 30 hashtags in your post.";
        break;
      default:
        break;
    }
    return message;
  };

  copyEverywhere = () => {
    let { instagramPosts, copyIndex } = this.state;

    for (let index = 0; index < instagramPosts.length; index++) {
      if (instagramPosts[index].status !== socialMediaStatus.COMPLETED) {
        instagramPosts[index].images = instagramPosts[copyIndex].images;
        instagramPosts[index].caption = instagramPosts[copyIndex].caption;
        instagramPosts[index].scheduledOn =
          instagramPosts[copyIndex].scheduledOn;
        instagramPosts[index].publishType =
          instagramPosts[copyIndex].publishType;
        this.setState({ instagramPosts }, () => {
          this.saveInstagramDetails(index);
        });
      }
    }

    this.toggleShowCopyConfirmation(DEFAULT_INDEX);
  };

  toggleShowCopyConfirmation = (index: any) => {
    let { showCopyConfirmation } = this.state;
    this.setState({
      showCopyConfirmation: !showCopyConfirmation,
      copyIndex: index
    });
  };

  render() {
    let { classes, isReviewScreen = false } = this.props;
    let {
      publication,

      instagramHandle,

      slug,

      loading,

      error,

      errorMessage,

      openImagePicker,

      postInitialized,

      showDeleteConfirmDialog,

      instagramPosts,

      scheduledOn,

      instagramPostIndex,

      uploadingImage = false,

      connectedToFacebook,

      openDatePicker,

      post,

      draftsCount,

      showCopyConfirmation,

      scheduledPostDates
    } = this.state;

    let hasInstagram = instagramHandle?.length > 0 || false;

    if (error) {
      return <Page404 errorMessage={errorMessage} />;
    }

    if (loading) {
      return <Loading />;
    }

    let showAdditionalPublishType =
      post &&
      ((post.status && post.status !== postStatus.PUBLISHED) ||
        post.scheduledOn);

    if (!connectedToFacebook || !hasInstagram) {
      return (
        <Container
          style={{
            padding: isReviewScreen ? 0 : "0px 24px 100px 24px"
          }}
        >
          {!isReviewScreen && (
            <>
              <Helmet>
                <title>
                  Instagram {publication && "- " + publication.name} |
                  Letterdrop
                </title>
              </Helmet>
            </>
          )}

          <Paper
            className={clsx(
              isReviewScreen ? classes.paper : classes.reducedPaperMargin
            )}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              className={classes.section}
            >
              {!isReviewScreen && (
                <Grid
                  item
                  className={classes.marginAuto}
                  style={{ display: "flex" }}
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    title={"instagram"}
                    style={{
                      color: colors.instagram,
                      fontSize: 24,
                      margin: "auto 0px"
                    }}
                  />

                  <Typography
                    variant="h500"
                    className={classes.headerText}
                    style={{ margin: "auto 10px" }}
                  >
                    Create a Instagram Post
                  </Typography>
                </Grid>
              )}

              <Grid
                item
                className={classes.marginAuto}
                style={{ padding: "50px 20px" }}
              >
                <img
                  src={ImagesList.socialMedia}
                  className={classes.connectAccountImage}
                  alt="Connect linkedin account"
                />
              </Grid>

              <Grid item className={classes.connectMessage}>
                <Typography>
                  {!connectedToFacebook
                    ? `Connect a Facebook account that has a Page linked to Instagram bussiness account to start posting to Instagram.`
                    : `Connect your Facebook Page to Instagram bussiness account to start posting to Instagram.`}
                </Typography>
              </Grid>

              <Grid item className={classes.marginAuto}>
                <StyledButton
                  onClick={this.refresh}
                  startIcon={<RefreshIcon />}
                >
                  Refresh
                </StyledButton>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      );
    }

    return (
      <Container
        style={{
          padding: isReviewScreen ? 0 : "24px 24px 100px 24px"
        }}
      >
        {!isReviewScreen && (
          <>
            <Helmet>
              <title>
                Instagram {publication && "- " + publication.name} | Letterdrop
              </title>
            </Helmet>
          </>
        )}

        <Paper
          className={clsx(
            !isReviewScreen ? classes.reducedPaperMargin : classes.paper
          )}
        >
          {!isReviewScreen && (
            <>
              <div className={classes.headerTitle}>
                <FontAwesomeIcon
                  icon={faInstagram}
                  title={"instagram"}
                  style={{
                    color: colors.instagram,
                    fontSize: 24,
                    margin: "auto 0px"
                  }}
                />

                <Typography
                  variant="h500"
                  className={classes.headerText}
                  style={{ margin: "auto 10px" }}
                >
                  Create a Instagram Post
                </Typography>
              </div>

              <div className={classes.header}>
                <div className={classes.draftDiv}>
                  <StyledButton
                    className={classes.instagramDraft}
                    variant="textsecondary"
                    size="small"
                    onClick={() => {
                      this.props.history.push(
                        `/${publication._id}/instagram/drafts`
                      );
                    }}
                  >
                    Drafts{" "}
                    <Typography
                      variant="h100"
                      style={{
                        marginLeft: 5,
                        color: designColors.grayScale[80]
                      }}
                    >
                      {draftsCount}
                    </Typography>
                  </StyledButton>
                </div>

                <div className={classes.postButtonSection}>
                  <StyledButton
                    startIcon={postInitialized ? "" : <SendIcon />}
                    className={classes.postButton}
                    onClick={this.handlePostToInstagram}
                  >
                    {postInitialized && (
                      <CircularProgress
                        size={20}
                        color="inherit"
                        className={classes.marginRight10}
                      />
                    )}
                    {postInitialized ? "Posting..." : "Post to Instagram"}
                  </StyledButton>
                </div>
              </div>
            </>
          )}

          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ display: "block" }}
          >
            {instagramPosts.map((instagramPost: any, postIndex: any) => {
              let error = instagramPost.error
                ? JSON.parse(instagramPost.error)
                : null;
              return (
                <Fragment key={postIndex}>
                  <div container>
                    {postIndex !== 0 && <Divider style={{ margin: 20 }} />}
                    {instagramPost.status === socialMediaStatus.COMPLETED && (
                      <div className={classes.editableLabelDiv}>
                        <Typography className={classes.editableLabel}>
                          This post is published and can’t be updated
                        </Typography>
                      </div>
                    )}

                    <Grid item className={classes.section}>
                      <Typography
                        variant="body1"
                        className={classes.sectionHeading}
                      >
                        Account
                      </Typography>

                      <Typography className={classes.sectionHelper}>
                        Which user's account do you want to post from?
                      </Typography>

                      <div>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.input}
                          size="medium"
                        >
                          <StyledSelect
                            name={INSTAGRAM.POST_TO}
                            value={
                              instagramHandle.filter((account: any) => {
                                return account.userId === instagramPost.account;
                              })[0]
                            }
                            size="large"
                            onChange={(event: any) =>
                              this.handleContentChange(event, postIndex)
                            }
                            placeholder="Choose an account"
                            disabled={
                              instagramPost.status ===
                              socialMediaStatus.COMPLETED
                            }
                            renderValue={(account: any) => {
                              return (
                                <div className={classes.selectedAccount}>
                                  <Avatar
                                    variant="circular"
                                    src={account?.profilePicture}
                                    alt="Instagram profile picture"
                                    className={classes.avatar}
                                  />

                                  <Typography className={classes.userName}>
                                    {account?.name}
                                  </Typography>
                                </div>
                              );
                            }}
                          >
                            {instagramHandle?.map(
                              (account: any, index: any) => {
                                return (
                                  <StyledMenuItem key={index} value={account}>
                                    <Avatar
                                      alt="linkedin profile picture"
                                      variant="circular"
                                      className={classes.avatar}
                                      src={account.profilePicture}
                                    />

                                    <Typography
                                      variant="body2"
                                      className={classes.itemName}
                                    >
                                      {account.name}
                                    </Typography>
                                  </StyledMenuItem>
                                );
                              }
                            )}
                          </StyledSelect>
                        </FormControl>
                      </div>
                    </Grid>

                    <Grid item className={classes.section}>
                      <Typography
                        variant="body1"
                        className={classes.sectionHeading}
                      >
                        Images
                      </Typography>

                      <Typography className={classes.sectionHelper}>
                        Choose an image or a video to post to your Instagram
                        feed.
                      </Typography>

                      <div className={classes.imagesGrid}>
                        {instagramPost.images.length === 0 && (
                          <div
                            className={classes.postImagePicker}
                            onClick={() => {
                              if (
                                uploadingImage &&
                                instagramPostIndex === postIndex
                              ) {
                                return;
                              }
                              this.toggleImagePicker(postIndex);
                            }}
                          >
                            {uploadingImage &&
                            instagramPostIndex === postIndex ? (
                              <CircularProgress style={{ margin: "0px 15%" }} />
                            ) : (
                              <Typography
                                variant="caption"
                                className={classes.uploadImageText}
                              >
                                <AddIcon
                                  fontSize="small"
                                  className={classes.addIcon}
                                />
                                Upload image
                              </Typography>
                            )}
                          </div>
                        )}

                        <div className={classes.postImageContainer}>
                          {instagramPost.images.map(
                            (image: any, imgIndex: any) => {
                              if (!image) {
                                return null;
                              }
                              return (
                                <div
                                  className={classes.postImage}
                                  key={imgIndex}
                                >
                                  <img
                                    alt="post cover"
                                    src={image}
                                    className={classes.threadImage}
                                    width={200}
                                    height={"auto"}
                                  />
                                  {instagramPost.status !==
                                    socialMediaStatus.COMPLETED && (
                                    <IconButton
                                      className={classes.closeIcon}
                                      onClick={() => {
                                        this.removeImage(imgIndex, postIndex);
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                      {error &&
                        (error.error_subcode === errors.ASPECT_RATIO ||
                          error.error_subcode === errors.IMAGE_SIZE) &&
                        this.getErrorText(error)}
                    </Grid>

                    <Grid item className={classes.section}>
                      <Typography
                        variant="body1"
                        className={classes.sectionHeading}
                      >
                        Caption
                      </Typography>

                      <Typography className={classes.sectionHelper}>
                        Add a caption for your image. Use{" "}
                        <code className={classes.hashtags}>#</code> to indicate
                        hashtags.
                      </Typography>

                      <StyledInput
                        multiline
                        maxRows={8}
                        minRows={5}
                        size="large"
                        fullWidth
                        name={INSTAGRAM.POST_CAPTION}
                        onChange={(event: any) =>
                          this.handleContentChange(event, postIndex)
                        }
                        className={classes.textAreaOutline}
                        value={instagramPost.caption}
                        disabled={
                          instagramPost.status === socialMediaStatus.COMPLETED
                        }
                      />

                      <Typography className={classes.charCount}>
                        {formatNumber(instagramPost.caption?.length || "0")} /{" "}
                        {formatNumber(INSTAGRAM_CAPTION_LIMIT)}
                      </Typography>
                      {error &&
                        (error.error_subcode === errors.TAGS_COUNT ||
                          error.error_subcode === errors.HASHTAGS_COUNT) &&
                        this.getErrorText(error)}
                    </Grid>
                    {slug && (
                      <Grid item className={classes.section}>
                        <Typography
                          variant="body1"
                          className={classes.sectionHeading}
                        >
                          Link to your post
                        </Typography>

                        <Typography className={classes.sectionHelper}>
                          Add this link into your Instagram bio and direct users
                          to it, or use {URL_REPLACE_TEXT}, and we'll replace it
                          with the final URL.
                        </Typography>

                        <InputBase
                          className={classes.postUrl}
                          value={
                            slug +
                            "?utm=" +
                            (instagramPost.utm ? instagramPost.utm : nanoid(10))
                          }
                          multiline={true}
                          contentEditable={false}
                          onFocus={(event) => event.target.select()}
                          fullWidth
                          endAdornment={
                            <InputAdornment position="end">
                              <CopyToClipboard
                                onCopy={() =>
                                  notify.show("Copied to clipboard", "success")
                                }
                                text={
                                  slug +
                                  "?utm=" +
                                  (instagramPost.utm
                                    ? instagramPost.utm
                                    : nanoid(10))
                                }
                              >
                                <IconButton>
                                  <FileCopyOutlinedIcon />
                                </IconButton>
                              </CopyToClipboard>
                            </InputAdornment>
                          }
                        />
                      </Grid>
                    )}

                    <Grid item className={classes.section}>
                      <Typography
                        variant="body1"
                        className={classes.sectionHeading}
                      >
                        When
                      </Typography>

                      <Typography className={classes.sectionHelper}>
                        Schedule when your Instagram post should go out
                      </Typography>

                      <div style={{ width: "100%" }}>
                        <FormControl
                          variant="outlined"
                          size="medium"
                          className={classes.scheduleDropDown}
                          fullWidth
                        >
                          <StyledSelect
                            name={INSTAGRAM.PUBLISH_TYPE}
                            value={
                              instagramPost.publishType || publishTypes.NOW
                            }
                            onChange={(event: any) =>
                              this.handlePublishTypeChange(event, postIndex)
                            }
                            size="large"
                            style={{ width: "100%" }}
                            disabled={
                              instagramPost.status ===
                              socialMediaStatus.COMPLETED
                            }
                          >
                            {!isReviewScreen && (
                              <StyledMenuItem value={publishTypes.NOW}>
                                Now
                              </StyledMenuItem>
                            )}

                            <StyledMenuItem
                              value={publishTypes.LATER}
                              onClick={() => this.handlePublishLater(postIndex)}
                            >
                              {instagramPost.publishType ===
                                publishTypes.LATER && instagramPost.scheduledOn
                                ? this.getScheduleDate(
                                    instagramPost.scheduledOn
                                  )
                                : "Later"}
                            </StyledMenuItem>
                            {isReviewScreen && (
                              <StyledMenuItem value={publishTypes.WITH_POST}>
                                Same as the blog post
                              </StyledMenuItem>
                            )}
                            {(showAdditionalPublishType ||
                              instagramPost.publishType ===
                                publishTypes.TEN_MINUTES_LATER) && (
                              <StyledMenuItem
                                value={publishTypes.TEN_MINUTES_LATER}
                              >
                                10 minutes later
                              </StyledMenuItem>
                            )}
                            {(showAdditionalPublishType ||
                              instagramPost.publishType ===
                                publishTypes.THIRTY_MINUTES_LATER) && (
                              <StyledMenuItem
                                value={publishTypes.THIRTY_MINUTES_LATER}
                              >
                                30 minutes later
                              </StyledMenuItem>
                            )}
                            {(showAdditionalPublishType ||
                              instagramPost.publishType ===
                                publishTypes.HOUR_LATER) && (
                              <StyledMenuItem value={publishTypes.HOUR_LATER}>
                                1 hour later
                              </StyledMenuItem>
                            )}
                            {(showAdditionalPublishType ||
                              instagramPost.publishType ===
                                publishTypes.DAY_LATER) && (
                              <StyledMenuItem value={publishTypes.DAY_LATER}>
                                A day later
                              </StyledMenuItem>
                            )}
                          </StyledSelect>
                        </FormControl>
                      </div>
                    </Grid>
                    {instagramPost.status !== socialMediaStatus.COMPLETED && (
                      <Grid
                        container
                        style={{
                          marginBottom: 20,
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <StyledButton
                          variant="destructivesecondary"
                          onClick={() =>
                            this.toggleDeleteConfirmDialog(postIndex)
                          }
                          color="secondary"
                        >
                          Delete draft
                        </StyledButton>

                        <Tooltip title="Copy everywhere">
                          <IconButton
                            onClick={() =>
                              this.toggleShowCopyConfirmation(postIndex)
                            }
                            style={{ marginRight: `${space.SMALL}` }}
                            size="large"
                          >
                            <ContentCopyOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </div>
                </Fragment>
              );
            })}
          </Grid>

          <div>
            <Divider />

            <Grid
              id="add_another_account"
              container={true}
              justifyContent="space-between"
              className={classes.anotherAccount}
            >
              <StyledButton
                variant="secondary"
                onClick={this.handlePostToAnotherAccount}
                startIcon={<AddCircleIcon />}
              >
                Post to {instagramPosts.length > 0 && "another"} account
              </StyledButton>
            </Grid>
          </div>

          <StyledDialog
            open={showDeleteConfirmDialog}
            title="Are you sure?"
            body="Once deleted, you can't recover your post"
            successButtonName="Yes, I'm sure"
            successCallback={this.deletePost}
            cancelCallback={this.handleDialogClose}
          />

          <Popover
            id="linkedin_image_picker"
            open={openImagePicker}
            anchorEl={<div />}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center"
            }}
            onClose={() => {
              if (this.imagePickerRef?.checkImageUploading?.()) {
                return;
              }
              this.setState({
                openImagePicker: false
              });
            }}
          >
            <ImagePicker
              unsplashRef={(ref: any) => {
                this.imagePickerRef = ref;
              }}
              onImageSelect={this.onImageSelected}
              onImageUpload={this.onSelectFile}
              postPictures={[]}
              showUnsplashPicker={true}
              forInstagram={true}
            />
          </Popover>

          <CustomFlatPicker
            publicationId={publication._id}
            open={openDatePicker}
            value={scheduledOn}
            disablePast={true}
            onClose={(date) => {
              this.setState(
                {
                  openDatePicker: false
                },

                this.onScheduleDateChange(date)
              );
            }}
            type={datePickerDefault.SOCIAL}
            scheduledDates={scheduledPostDates.map(
              (date: any) => new Date(date)
            )}
          />
        </Paper>

        <StyledDialog
          open={showCopyConfirmation}
          title="Do you want to copy this to other accounts?"
          body="We'll overwrite the body, time, and other details of other accounts."
          closeCallback={() => this.toggleShowCopyConfirmation(DEFAULT_INDEX)}
          cancelCallback={() => this.toggleShowCopyConfirmation(DEFAULT_INDEX)}
          successCallback={() => this.copyEverywhere()}
          successButtonName="OK"
          maxWidth="sm"
          fullWidth
        />
      </Container>
    );
  }
}

export default withStyles(styles)(InstagramShare);
