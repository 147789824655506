// @ts-nocheck
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import TwitterShare from "./TwitterShare";
import colors from "../Themes/Colors";
import { FACEBOOK_ALLOWED_DOMAINS, socialMediaType } from "../Utils/Types";
import Loading from "../Components/Loading";
import styles from "./styles/ShareToSocialStyle";
import FacebookShare from "./FacebookShare";
import RedditShare from "./RedditShare";
import LinkedInShare from "./LinkedInShare";
import InstagramShare from "./InstagramShare";
import StyledButton from "../design/components/StyledButton";
import MediumShare from "./MediumShare";
import DevtoShare from "./DevtoShare";
import HashnodeShare from "./HashnodeShare";
import { ReactComponent as MediumIcon } from "../Images/medium_outlined.svg";
import { ReactComponent as DevtoIcon } from "../Images/devto_outlined.svg";
import { ReactComponent as HashnodeIcon } from "../Images/hashnode_outlined.svg";
import { ReactComponent as TwitterIcon } from "../Images/twitter_outlined.svg";
import { ReactComponent as LinkedinIcon } from "../Images/linkedin_outlined.svg";
import { ReactComponent as FacebookIcon } from "../Images/facebook_outlined.svg";
import { ReactComponent as InstagramIcon } from "../Images/instagram_outlined.svg";
import { ReactComponent as RedditIcon } from "../Images/reddit_outlined.svg";
import styled from "styled-components";
import StyledTab from "../design/components/StyledTab";

const Page404 = React.lazy(() => import("../Containers/Page404"));

const StyledSVG = styled.svg`
  & path {
    fill: ${(props: any) => props.color};
  }
`;

let shareTo = [
  {
    type: socialMediaType.LINKED_IN,
    label: "LinkedIn",

    icon: <LinkedinIcon />,
    iconColor: colors.linkedin
  },
  {
    type: socialMediaType.TWITTER,
    label: "Twitter",

    icon: <TwitterIcon />,
    iconColor: colors.twitter
  },
  {
    type: socialMediaType.FACEBOOK,
    label: "Facebook",

    icon: <FacebookIcon />,
    iconColor: colors.facebook
  },
  {
    type: socialMediaType.INSTAGRAM,
    label: "Instagram",

    icon: <InstagramIcon />,
    iconColor: colors.instagram
  },
  {
    type: socialMediaType.REDDIT,
    label: "Reddit",

    icon: <RedditIcon />,
    iconColor: colors.reddit
  },
  {
    type: socialMediaType.MEDIUM,
    label: "Medium",

    icon: <MediumIcon />,
    iconColor: colors.black
  }
];

const devtoLabel = {
  type: socialMediaType.DEVTO,
  label: "Dev.to",

  icon: <DevtoIcon />,
  iconColor: colors.black
};

const hashnodeLabel = {
  type: socialMediaType.HASHNODE,
  label: "Hashnode",

  icon: <HashnodeIcon />,
  iconColor: colors.hashnode
};

let HTTP_PROTOCOL = process.env.REACT_APP_HTTP_PROTOCOL;
let REACT_APP_VERCEL_SERVER_URL = process.env.REACT_APP_VERCEL_SERVER_URL;

class ShareToSocial extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      errorMessage: "",
      post: props.post,
      selectedMedia: shareTo[0].type,
      socialMedias: null
    };

    //visible only if dev.to integrated
    if (
      props?.post?.publication?.devto?.integrationToken &&
      shareTo.indexOf(devtoLabel) < 0
    ) {
      shareTo.push(devtoLabel);
    }

    //visible only if hashnode integrated
    if (
      props?.post?.publication?.hashnode?.integrationToken &&
      shareTo.indexOf(hashnodeLabel) < 0
    ) {
      shareTo.push(hashnodeLabel);
    }
  }

  handleMediaChange = (event: any, media: any) => {
    this.setState({
      selectedMedia: media
    });
  };

  redirectToShare = () => {
    let { post } = this.state;
    window.open(
      `${HTTP_PROTOCOL}${REACT_APP_VERCEL_SERVER_URL}/share?postId=${post._id}`,
      "_blank"
    );
  };

  reload = () => {
    this.setState({ loading: true }, () => this.setState({ loading: false }));
  };

  render() {
    let { classes, showPostCoverPhoto, socialThumbnail } = this.props;
    let { loading, error, errorMessage, selectedMedia, post } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Page404 errorMessage={errorMessage} />;
    }

    return (
      <Grid container className={classes.container}>
        <Grid container>
          <Grid item xs={12} className={classes.reviewTitleContainer}>
            <Typography variant="h500" className={classes.reviewTitle}>
              Social Networks
            </Typography>

            <Typography variant="bodym" className={classes.postType}>
              Select and edit a post on your social network
            </Typography>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            xs={12}
            className={classes.shareHeaderContainer}
          >
            <Grid
              item
              className="scrollbar-hidden"
              style={{
                display: "flex",
                overflowX: "auto",
                maxWidth: "100%",
                width: "100%",
                flexBasis: "auto",
                justifyContent: "center"
              }}
              xs={9}
            >
              <StyledTab
                variant="scrollable"
                scrollButtons="none"
                sx={{ borderBottom: 1, borderColor: "divider" }}
                value={selectedMedia}
                indicatorColor={
                  shareTo.find((media) => selectedMedia === media.type)
                    ?.iconColor
                }
                tabs={shareTo
                  .map((media, index) => {
                    if (
                      [
                        socialMediaType.FACEBOOK,
                        socialMediaType.INSTAGRAM
                      ].includes(media.type) &&
                      !FACEBOOK_ALLOWED_DOMAINS.includes(
                        post.publication?.domain
                      )
                    ) {
                      return "";
                    }

                    return {
                      index: index,
                      label: (
                        <Typography
                          variant="h200"
                          color={
                            selectedMedia === media.type
                              ? media.iconColor
                              : colors.black
                          }
                        >
                          {media.label}
                        </Typography>
                      ),
                      value: media.type,
                      icon: (
                        <StyledSVG
                          height={20}
                          width={20}
                          color={
                            selectedMedia === media.type
                              ? media.iconColor
                              : colors.black
                          }
                          viewBox="0 0 20 20"
                        >
                          {media.icon}
                        </StyledSVG>
                      )
                    };
                  })
                  .filter(Boolean)}
                onChange={(event: any, value: any) =>
                  this.handleMediaChange(event, value)
                }
              />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ minHeight: "100vh" }}>
            {selectedMedia === socialMediaType.TWITTER && (
              <TwitterShare postId={post._id} isReviewScreen={true} />
            )}

            {selectedMedia === socialMediaType.LINKED_IN && (
              <LinkedInShare
                postId={post._id}
                post={post}
                thumbnail={socialThumbnail || post.thumbnail}
                showPostCoverPhoto={showPostCoverPhoto}
                isReviewScreen={true}
              />
            )}

            {selectedMedia === socialMediaType.FACEBOOK && (
              <FacebookShare postId={post._id} isReviewScreen={true} />
            )}

            {selectedMedia === socialMediaType.INSTAGRAM && (
              <InstagramShare postId={post._id} isReviewScreen={true} />
            )}

            {selectedMedia === socialMediaType.SHARE_AS_IMAGE && (
              <Container
                style={{
                  padding: 0
                }}
              >
                <Paper
                  style={{
                    backgroundColor: colors.card,
                    color: colors.fontPrimary,
                    borderRadius: 10,
                    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
                    padding: 50,
                    marginTop: 30
                  }}
                >
                  <div className={classes.section}>
                    <Typography className={classes.sectionTitle}>
                      Instagram and Whatsapp
                    </Typography>

                    <div className={classes.socialContainer}>
                      <StyledButton
                        onClick={this.redirectToShare}
                        startIcon={
                          <>
                            <FontAwesomeIcon
                              icon={faInstagram}
                              title="Instagram"
                              className={classes.iconButton}
                            />

                            <FontAwesomeIcon
                              icon={faWhatsapp}
                              title="Instagram"
                              className={classes.iconButton}
                            />
                          </>
                        }
                      >
                        Share as Image
                      </StyledButton>
                    </div>
                  </div>
                </Paper>
              </Container>
            )}

            {selectedMedia === socialMediaType.REDDIT && (
              <RedditShare postId={post._id} isReviewScreen={true} />
            )}

            {selectedMedia === socialMediaType.MEDIUM && (
              <MediumShare postId={post._id} isReviewScreen={true} />
            )}
            {selectedMedia === socialMediaType.DEVTO && (
              <DevtoShare
                postId={post._id}
                publicationId={post.publication._id}
                isReviewScreen={true}
              />
            )}
            {selectedMedia === socialMediaType.HASHNODE && (
              <HashnodeShare
                postId={post._id}
                publicationId={post.publication._id}
                isReviewScreen={true}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ShareToSocial);
