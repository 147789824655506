// @ts-nocheck
import { Grid, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { withStyles } from "@mui/styles";
import { loadStripe } from "@stripe/stripe-js/pure";
import firebase from "firebase";
import React, { Component } from "react";

import { Elements, StripeProvider } from "react-stripe-elements";
import StyledButton from "../design/components/StyledButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Api from "../Services/Api";

import CheckoutForm from "./CheckoutForm";

import Loading from "./Loading";
import styles from "./styles/OnboardingPaymentStyle";
import colors from "../Themes/Colors";

const api = Api.create();
const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

class OnboardingPayment extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      stripe: null,
      loading: true,

      email: firebase.auth().currentUser.email
    };
  }
  componentDidMount = async () => {
    let stripe = await loadStripe(STRIPE_API_KEY, {
      stripeAccount: this.props.publicationStripeAccountId
    });
    this.setState({ stripe }, () => this.checkUserHaveSavedCard());
  };

  checkUserHaveSavedCard = () => {
    let { checkCardInfo = true, publicationId } = this.props;
    if (!checkCardInfo) {
      this.setState({
        loading: false
      });
      return;
    }
    api.checkUserHaveSavedCard(publicationId, (res: any) => {
      if (res.status === 200 && res.data.haveCard) {
        this.props.next();
      } else {
        this.setState({
          loading: false
        });
      }
    });
  };

  handleChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    let { classes, hideStepper, value } = this.props;

    let { loading, stripe, email } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        style={{ padding: hideStepper ? "0px" : "5% 20% 0% 20%" }}
      >
        {!hideStepper && (
          <>
            <Grid item>
              <Typography variant="body1" className={classes.heading}>
                Let’s get started
              </Typography>
            </Grid>

            <Grid item className={classes.LinearProgress}>
              <LinearProgress variant="determinate" value={value} />
            </Grid>

            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              style={{ marginTop: 5 }}
            >
              <StyledButton
                variant="textsecondary"
                style={{ marginTop: 10 }}
                onClick={() => {
                  this.props.back();
                }}
                startIcon={
                  <NavigateBeforeIcon
                    fontSize="small"
                    style={{ color: colors.fontPrimary }}
                  />
                }
              >
                Back
              </StyledButton>
            </Grid>
          </>
        )}

        <div
          className={classes.form}
          style={{ marginTop: hideStepper ? 20 : 50 }}
        >
          <StripeProvider stripe={stripe}>
            <Elements>
              <CheckoutForm email={email} onCardSaved={this.props.next} />
            </Elements>
          </StripeProvider>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(OnboardingPayment);
