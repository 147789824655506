import { Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { space } from "../Config/theme";
import {
  IntentAccountPropertiesMapping,
  verifyIntentAccountMapping
} from "../Containers/HubSpotCallback";
import StyledDialog from "../design/components/StyledDialog";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import StyledTab from "../design/components/StyledTab";
import API from "../Services/Api";
import BetaText from "./BetaText";
import { notify } from "./CustomNotifications";
import StyledTable from "../design/components/StyledTable";
import CloseIcon from "@mui/icons-material/Close";
import StyledButton from "../design/components/StyledButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: space.MEDIUM
  },
  formField: {
    marginTop: space.SMALL
  },
  divider: {
    marginTop: space.MEDIUM
  }
}));

const api = API.create();

////////////////////////////////////////////////////

type MappingType = { [key: string]: { label: string; mapKey: string } };

interface ContactConfigurationProps {
  publicationId: string;
  handleClose: () => void;
}

interface ContactConfigurationRef {
  handleSave: () => void;
}

const ContactConfiguration = forwardRef<
  ContactConfigurationRef,
  ContactConfigurationProps
>(({ publicationId, handleClose }, ref) => {
  const [loading, setLoading] = useState(true);
  const [hubId, setHubId] = useState("");
  const [mapping, setMapping] = useState<MappingType>({});
  const [linkedInActivityPush, setLinkedInActivityPush] = useState(false);

  const [loadingAllProperties, setLoadingAllProperties] = useState(false);
  const [allContactsProperties, setAllContactsProperties] = useState<
    { name: string; label: string }[]
  >([]);

  const [errorDetails, setErrorDetails] = useState({
    error: false,
    message: ""
  });
  const [error, setError] = useState("");
  const setErrorMessage = (msg: string) => {
    setError(msg);
    setTimeout(() => setError(""), 5000);
  };

  const getAllContactsProperties = () => {
    if (!hubId) return;
    setLoadingAllProperties(true);
    api.getAllContactsPropertiesHubspotCRM(publicationId, hubId, (res) => {
      setLoadingAllProperties(false);
      if (res.status === 200) {
        setAllContactsProperties(res.data?.allContactsProperties || []);
      } else {
        setErrorDetails({
          error: true,
          message: "Failed to fetch contact properties from Hubspot"
        });
      }
    });
  };

  const getCRMcontactsConfig = () => {
    setLoading(true);
    api.getCRMcontactsConfigFromHubspot(publicationId, (res) => {
      setLoading(false);
      if (res.status === 200) {
        const { hubId, contactsPropertiesMapping, linkedInActivityPush } =
          res.data;
        setHubId(hubId);
        setMapping(contactsPropertiesMapping);
        setLinkedInActivityPush(linkedInActivityPush);
      } else {
        setErrorDetails({
          error: true,
          message: "Failed to fetch contact field mapping"
        });
      }
    });
  };

  useEffect(() => {
    getCRMcontactsConfig();
  }, []);

  useEffect(() => {
    getAllContactsProperties();
  }, [hubId]);

  useImperativeHandle(ref, () => ({
    handleSave
  }));

  const handleSave = () => {
    const errMsg = verifyIntentAccountMapping(mapping);
    setErrorMessage(errMsg);
    if (errMsg) return;

    api.saveCRMcontactsConfigFromHubspot(
      publicationId,
      hubId,
      mapping,
      linkedInActivityPush,
      (res) => {
        if (res.status === 200) {
          handleClose();
        } else {
          notify.show(
            "Failed to save Details. Please try again later.",
            "error"
          );
        }
      }
    );
  };

  if (!loadingAllProperties && errorDetails.error) {
    return <Alert severity="error">{errorDetails.message}</Alert>;
  }

  if (loading) {
    return <Skeleton width="100%" height={50} />;
  }

  return (
    <>
      <IntentAccountPropertiesMapping
        mapping={mapping}
        setMapping={setMapping}
        allProperties={allContactsProperties}
        refreshAllProperties={getAllContactsProperties}
        loadingAllProperties={loadingAllProperties}
        linkedInActivityPush={linkedInActivityPush}
        setLinkedInActivityPush={setLinkedInActivityPush}
        error={error}
      />
    </>
  );
});

////////////////////////////////////////////////////

interface DealConfigurationProps {
  publicationId: string;
  handleClose: () => void;
}

interface DealConfigurationRef {
  handleSave: () => void;
}

const DealConfiguration = forwardRef<
  DealConfigurationRef,
  DealConfigurationProps
>(({ publicationId, handleClose }, ref) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [errorDetails, setError] = useState({
    error: false,
    message: ""
  });
  const [selectionError, setSelectionError] = useState(false);
  const [pipeline, setPipeline] = useState("");
  const [pipelines, setPipelines] = useState<
    Array<{
      id: string;
      name: string;
      dealStages: {
        closedwon: string;
        closedlost: string;
      };
    }>
  >([]);
  const [availablePipelines, setAvailablePipelines] = useState<
    Array<{
      id: string;
      label: string;
      stages: {
        label: string;
        id: string;
      }[];
    }>
  >([]);
  const [stages, setStages] = useState<{ id: string; label: string }[]>([]);
  const [closedWonStage, setClosedWonStage] = useState<string>("");
  const [closedLostStage, setClosedLostStage] = useState<string>("");
  const [openAddPipelineDialog, setOpenAddPipelineDialog] =
    useState<boolean>(false);
  const load = () => {
    api.getPipelinesFromHubspot(publicationId, (res) => {
      setLoading(false);
      if (res.status === 200) {
        setPipeline(res.data?.current);
        setPipelines(res.data?.selectedPipelines);
        setAvailablePipelines(res.data?.availablePipelines);
        const stages = res.data?.availablePipelines?.find(
          (item: any) => item.id === res.data?.current
        )?.stages;
        setStages(stages || []);
        setClosedWonStage(
          stages.find(
            (stage: any) => stage?.label === res.data?.dealStages?.closedwon
          )?.id
        );
        setClosedLostStage(
          stages.find(
            (stage: any) => stage?.label === res.data?.dealStages?.closedlost
          )?.id
        );
      } else {
        setError({
          error: true,
          message: "Failed to fetch pipelines from Hubspot"
        });
      }
    });
  };

  useEffect(load, []);

  const handleChangePipeline = (event: any) => {
    setPipeline(event.target.value);
    setStages(
      availablePipelines.find((item) => item.id === event.target.value)
        ?.stages || []
    );
    setSelectionError(false);
  };

  const onPipelineSave = () => {
    let newPipelines = [...pipelines];
    const closedwon = stages.find((item) => item.id === closedWonStage)?.label;
    const closedlost = stages.find(
      (item) => item.id === closedLostStage
    )?.label;
    const pipelineName = availablePipelines.find(
      (item) => item.id === pipeline
    )?.label;
    if (
      !pipeline ||
      !availablePipelines.find((item) => item.id === pipeline) ||
      !closedwon ||
      !closedlost
    ) {
      setSelectionError(true);
      return;
    }

    newPipelines.push({
      id: pipeline,
      name: pipelineName || "",
      dealStages: {
        closedwon: closedwon || "",
        closedlost: closedlost || ""
      }
    });
    setPipelines(newPipelines);
    setOpenAddPipelineDialog(false);
  };

  const handleSave = () => {
    api.setHubspotPipeline(publicationId, pipelines, (res) => {
      if (res.status !== 200) {
        notify.show(
          "Failed to save pipelines. Please try again later.",
          "error"
        );
      }
    });
  };

  useEffect(handleSave, [publicationId, pipelines]);

  const deletePipeline = (index: number) => {
    let newPipelines = [...pipelines];
    newPipelines.splice(index, 1);
    setPipelines(newPipelines);
  };

  const getTableBody = () => {
    return pipelines.map((pipeline: any, index: number) => {
      let data = [
        { value: pipeline.name, style: { width: "90%" } },
        {
          value: (
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <div>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => deletePipeline(index)}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          ),
          style: { width: "10%" }
        }
      ];
      return {
        id: index,
        data
      };
    });
  };

  if (loading) {
    return <Skeleton width="100%" height={50} />;
  }
  if (errorDetails.error) {
    return <Alert severity="error">{errorDetails.message}</Alert>;
  }

  return (
    <>
      <StyledTable
        tableWidth="100%"
        tableContainerProps={{
          style: { marginTop: space.SMALL }
        }}
        headerArray={[]}
        bodyArray={getTableBody()}
      />
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <StyledButton
          variant="textprimary"
          style={{ marginTop: space.SMALL }}
          onClick={() => setOpenAddPipelineDialog(true)}
          startIcon={<AddCircleIcon />}
        >
          Add Pipeline
        </StyledButton>
      </Grid>
      <StyledDialog
        open={openAddPipelineDialog}
        title={"Add Pipeline"}
        body={
          <>
            <FormControl className={classes.formControl} fullWidth>
              <Typography variant="bodym">
                Select a pipeline that you want to use for content attribution
              </Typography>
              <StyledSelect
                onChange={handleChangePipeline}
                placeholder="Select Pipeline"
                value={pipeline}
                className={classes.formField}
              >
                {availablePipelines.map((item) => (
                  <StyledMenuItem key={item.id} value={item.id}>
                    {item.label}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
            <Divider className={classes.divider} />
            <FormControl className={classes.formControl} fullWidth>
              <Typography variant="bodym">
                Select a stage corresponds to "Closed/Won" Deals
              </Typography>
              <StyledSelect
                onChange={(event: any) => {
                  setClosedWonStage(event.target.value);
                  setSelectionError(false);
                }}
                placeholder="Select Stage"
                value={closedWonStage}
                className={classes.formField}
              >
                {stages.map((item) => (
                  <StyledMenuItem key={item.id} value={item.id}>
                    {item.label}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <Typography variant="bodym">
                Select a stage corresponds to "Closed/Lost" Deals
              </Typography>
              <StyledSelect
                onChange={(event: any) => {
                  setClosedLostStage(event.target.value);
                  setSelectionError(false);
                }}
                placeholder="Select Stage"
                value={closedLostStage}
                className={classes.formField}
              >
                {stages.map((item) => (
                  <StyledMenuItem key={item.id} value={item.id}>
                    {item.label}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </>
        }
        successButtonName={"Save"}
        successCallback={onPipelineSave}
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenAddPipelineDialog(false)}
      />
      {selectionError && (
        <Alert
          severity="warning"
          style={{
            marginTop: 10
          }}
        >
          Select all the fields
        </Alert>
      )}
    </>
  );
});

////////////////////////////////////////////////////

const configurationTabs = {
  DEAL: "Deal",
  CONTACT: "Contact"
};

export default function HusbpotCRMConfiguration({
  open,
  handleClose,
  publicationId
}: any) {
  const [currentTab, setCurrentTab] = useState(configurationTabs.DEAL);
  const dealConfigurationRef = useRef<DealConfigurationRef>(null);
  const contactConfigurationRef = useRef<ContactConfigurationRef>(null);

  function handleSave() {
    if (currentTab === configurationTabs.DEAL) {
      handleClose();
      notify.show("Pipelines saved", "success");
    } else if (
      currentTab === configurationTabs.CONTACT &&
      contactConfigurationRef.current
    ) {
      contactConfigurationRef.current.handleSave();
    }
  }

  return (
    <>
      <StyledDialog
        open={open}
        title="Configure Hubspot CRM"
        body={
          <>
            <StyledTab
              // @ts-ignore
              value={currentTab}
              tabs={[
                {
                  label: "Deal",
                  value: configurationTabs.DEAL
                },
                {
                  label: "Contact",
                  value: configurationTabs.CONTACT,
                  icon: <BetaText />,
                  iconPosition: "end"
                }
              ]}
              onChange={(event: any, value: any) => setCurrentTab(value)}
            />
            {currentTab === configurationTabs.DEAL && (
              <DealConfiguration
                publicationId={publicationId}
                handleClose={handleClose}
                ref={dealConfigurationRef}
              />
            )}
            {currentTab === configurationTabs.CONTACT && (
              <ContactConfiguration
                publicationId={publicationId}
                handleClose={handleClose}
                ref={contactConfigurationRef}
              />
            )}
          </>
        }
        successButtonName="Save"
        successCallback={handleSave}
        cancelButtonName="Close"
        cancelCallback={handleClose}
      />
    </>
  );
}
