// @ts-nocheck
import InnerHTML from "dangerously-set-html-content";

/**
 * Inner html will execute script also
 * It is a functional component
 * Dont use until you know what your doing because of script execution
 */

function CustomInnerHtmlText(props: any) {
  return <InnerHTML {...props} />;
}

export default CustomInnerHtmlText;
