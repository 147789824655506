// @ts-nocheck
import { withStyles } from "@mui/styles";
import {
  Autocomplete,
  Avatar,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid
} from "@mui/material";

import { ReactComponent as CalendarIcon } from "../Images/icon20/calendarColorGray40.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React from "react";
import Api from "../Services/Api";
import { notify } from "./CustomNotifications";

import Loading from "./Loading";
import styles from "./styles/BulkAssignTopicsStyle";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";

import StyledChip from "../design/components/StyledChip";
import { Box } from "@mui/system";
import { channelTypes, datePickerDefault, teamRoles } from "../Utils/Types";

import StyledSwitch from "../design/components/StyledSwitch";

import CustomFlatPicker from "./CustomFlatPicker";
import { Close } from "@mui/icons-material";
import StyledButton from "../design/components/StyledButton";
import { formatForHistoryPanel } from "../Utils/TimeUtils";
import { space } from "../Config/theme";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import colors from "../Themes/Colors";
import moment from "moment";
import { getChannelList } from "../Containers/Topic";
import PageAccessContext from "../Utils/PageAccessContext";
const api = Api.create();

class BulkCreateTopics extends React.Component {
  static contextType = PageAccessContext;
  constructor(props: any) {
    super(props);
    let defaultChannel = {
      name: "Website",
      type: channelTypes.BLOG
    };

    if (this.props.publication.externalSite.blogs.length) {
      defaultChannel = {
        name: this.props.publication.externalSite.blogs[0].name,

        type: this.props.publication.externalSite.blogs[0].destination,

        id: this.props.publication.externalSite.blogs[0].id,
        isBlog: true
      };
    }
    this.state = {
      loading: false,
      assignIdeas: false,
      showDatePicker: false,
      showInvalidDateDialog: false,
      ideas: [
        {
          channel: defaultChannel
        },
        {
          channel: defaultChannel
        }
      ],
      dateIndex: -1,

      channelList: [],
      defaultChannel
    };
  }

  componentDidMount(): void {
    this.setState({
      channelList: getChannelList(this.props.publication, this.context)
    });
  }

  selectChannel = (event: any, index: any) => {
    let { ideas, channelList } = this.state;
    let selectedChannel = channelList.find(
      (channel: any) => `${channel.name}-${channel.type}` === event.target.value
    );
    ideas[index]["channel"] = selectedChannel;
    if (
      [channelTypes.TWITTER, channelTypes.LINKEDIN].includes(
        selectedChannel?.type
      )
    ) {
      ideas[index]["keyword"] = "";
      ideas[index]["assignee"] = "";
      ideas[index]["disabled"] = true;
    } else {
      ideas[index]["disabled"] = false;
    }
    this.setState({
      ideas
    });
  };

  validateAndCreateTopics = () => {
    let { ideas, assignIdeas } = this.state;

    for (let idea of ideas) {
      if (!idea.title) {
        notify.show("Invalid title", "error");
        return;
      }
      if (assignIdeas && !idea.publishOn) {
        notify.show("Invalid Publish On date", "error");
        return;
      }
    }

    api.createAssignBulkTopics(
      this.props.publication._id,
      ideas,
      assignIdeas,
      (res: any) => {
        if (res.status === 200) {
          this.props.onBulkCreate();

          this.props.onCloseDialog();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Unable to assign your ideas",
            "error"
          );
        }
      }
    );
  };

  showDatePickerDialog = (value: any, index: any) => {
    this.setState({ showDatePicker: value, dateIndex: index });
  };

  setPublishDate = (date: any) => {
    let { ideas, dateIndex } = this.state;
    if (
      ideas[dateIndex]?.channel &&
      [channelTypes.BLOG, channelTypes.EMAIL].includes(
        ideas[dateIndex].channel.type
      )
    ) {
      if (this.props.minDate && moment(date).isBefore(this.props.minDate)) {
        this.setState({
          showInvalidDateDialog: true,
          showDatePicker: false,
          dateIndex: -1
        });
        return;
      }
    }
    ideas[dateIndex]["publishOn"] = date;
    this.setState({
      ideas,
      showDatePicker: false,
      dateIndex: -1
    });
  };

  onAssigneeChange = (newValue: any, index: any) => {
    let { ideas } = this.state;
    ideas[index]["assignee"] = newValue;
    this.setState({
      ideas
    });
  };

  toggleAssignIdeas = (event: any) => {
    this.setState({ assignIdeas: event.target.checked });
  };

  onTextChange = (index: any) => (e: any) => {
    let { name, value } = e.target;

    let { ideas } = this.state;
    ideas[index][name] = value;
    this.setState({
      ideas
    });
  };

  addNewRow = () => {
    const idea = {
      title: "",
      keyword: "",
      channel: {
        name: "Website",
        type: channelTypes.BLOG
      }
    };
    this.setState((prevState, props) => ({
      ideas: [...prevState.ideas, idea]
    }));
  };

  deleteRow = (index: any) => {
    let { ideas } = this.state;
    ideas.splice(index, 1);
    this.setState({ ideas });
  };

  render() {
    let { classes, open, onCloseDialog, teammates, publication } = this.props;
    let {
      loading,

      assignIdeas,

      showDatePicker,

      ideas,

      channelList,

      showInvalidDateDialog,

      defaultChannel
    } = this.state;

    return (
      <StyledDialog
        open={open}
        maxWidth="lg"
        title="Create ideas and assign"
        contentStyle={{ paddingTop: 0 }}
        body={
          <div>
            {loading && <Loading />}
            {!loading && (
              <div>
                <FormControlLabel
                  style={{ float: "right" }}
                  control={
                    <StyledSwitch
                      name={"assignIdeas"}
                      checked={assignIdeas}
                      onChange={this.toggleAssignIdeas}
                    />
                  }
                  label="Assign ideas"
                  componentsProps={{
                    typography: {
                      variant: "bodym"
                    }
                  }}
                />

                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.noBackground}></TableCell>

                      <TableCell className={classes.headerCellNoBackGround}>
                        Title<span className={classes.mandatory}>*</span>
                      </TableCell>

                      <TableCell className={classes.headerCellNoBackGround}>
                        Channel
                      </TableCell>

                      <TableCell className={classes.headerCellNoBackGround}>
                        Keyword
                      </TableCell>
                      {assignIdeas && (
                        <>
                          <TableCell className={classes.headerCellNoBackGround}>
                            Assignee
                          </TableCell>

                          <TableCell className={classes.headerCellNoBackGround}>
                            Publish On
                            <span className={classes.mandatory}>*</span>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {ideas.map((idea: any, index: any) => (
                      <TableRow key={index} className={classes.noBackground}>
                        <TableCell
                          style={{ width: 40, cursor: "pointer", padding: 0 }}
                        >
                          <IconButton
                            aria-label="close"
                            size="large"
                            onClick={() => this.deleteRow(index)}
                            style={{
                              cursor: "pointer"
                            }}
                          >
                            <Close />
                          </IconButton>
                        </TableCell>

                        <TableCell>
                          <StyledInput
                            height="30px"
                            onChange={this.onTextChange(index)}
                            fullWidth
                            value={ideas[index]?.title}
                            name="title"
                            size="large"
                          />
                        </TableCell>

                        <TableCell>
                          <StyledSelect
                            name="selectedChannel"
                            className={classes.marginTop10}
                            value={
                              ideas[index].channel
                                ? `${ideas[index].channel?.name}-${ideas[index].channel?.type}`
                                : `${defaultChannel.name}-${defaultChannel.type}`
                            }
                            style={{ width: "100%", height: 55 }}
                            onChange={(event: any) =>
                              this.selectChannel(event, index)
                            }
                          >
                            {Object.values(channelList).map(
                              (channel, index) => {
                                return (
                                  <StyledMenuItem
                                    value={`${channel.name}-${channel.type}`}
                                    key={channel.name}
                                  >
                                    <Grid container direction="row">
                                      <Grid item xs={12}>
                                        <Typography variant="bodym">
                                          {" "}
                                          {channel.name}
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={12}>
                                        <Typography
                                          variant="bodys"
                                          style={{
                                            color: colors.fontSecondary,
                                            textTransform: "capitalize"
                                          }}
                                        >
                                          {channel.type}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </StyledMenuItem>
                                );
                              }
                            )}
                          </StyledSelect>
                        </TableCell>

                        <TableCell>
                          <StyledInput
                            name="keyword"
                            height="30px"
                            value={ideas[index]?.keyword}
                            disabled={ideas[index]?.disabled ? true : false}
                            onChange={this.onTextChange(index)}
                            fullWidth
                            size="large"
                          />
                        </TableCell>
                        {assignIdeas && (
                          <>
                            <TableCell>
                              <Autocomplete
                                disabled={ideas[index]?.disabled ? true : false}
                                name="assignee"
                                value={ideas[index]?.assignee}
                                onChange={(event, newValue) => {
                                  this.onAssigneeChange(newValue, index);
                                }}
                                options={teammates}
                                getOptionLabel={(option) =>
                                  typeof option === "string"
                                    ? option
                                    : option.email
                                }
                                renderInput={(params) => (
                                  <StyledInput
                                    height="24px"
                                    fullWidth
                                    size="large"
                                    variant="outlined"
                                    {...params}
                                  />
                                )}
                                renderOption={(props, option, { selected }) => {
                                  return (
                                    <Box key={option._id} {...props}>
                                      <Avatar
                                        src={option.client?.profilePic}
                                        className={classes.autoCompleteAvatar}
                                      />

                                      <Typography className={classes.name}>
                                        {option.client.name || option.email}
                                      </Typography>
                                      {option.role === teamRoles.FREELANCER && (
                                        <StyledChip
                                          size="large"
                                          label="freelancer"
                                          className={classes.name}
                                        />
                                      )}
                                    </Box>
                                  );
                                }}
                              />
                            </TableCell>

                            <TableCell>
                              <StyledInput
                                height="30px"
                                value={
                                  ideas[index]?.publishOn
                                    ? formatForHistoryPanel(
                                        ideas[index]?.publishOn
                                      )
                                    : ""
                                }
                                name="publishOn"
                                fullWidth
                                size="large"
                                readOnly={true}
                                InputProps={{
                                  endAdornment: (
                                    <CalendarIcon alt="calendarIcon" />
                                  )
                                }}
                                onClick={(event: any) => {
                                  this.showDatePickerDialog(true, index);
                                }}
                              />

                              <CustomFlatPicker
                                publicationId={publication._id}
                                open={showDatePicker}
                                disablePast={true}
                                value={ideas[index]?.publishOn || moment()}
                                type={datePickerDefault.DEADLINE}
                                onClose={(date) => this.setPublishDate(date)}
                              />
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>

                  <TableRow className={classes.noBackground}>
                    <TableCell></TableCell>

                    <TableCell>
                      <StyledButton
                        variant="textprimary"
                        onClick={this.addNewRow}
                        startIcon={<AddCircleIcon />}
                        style={{ margin: `${space.SMALL} 0px` }}
                      >
                        Add Row
                      </StyledButton>
                    </TableCell>
                  </TableRow>
                </Table>
              </div>
            )}

            <StyledDialog
              open={showInvalidDateDialog}
              title="Invalid publish date"
              body={`Select a date after ${formatForHistoryPanel(
                this.props.minDate
              )}`}
              cancelButtonName="OK"
              cancelCallback={() => {
                this.setState({
                  showInvalidDateDialog: false
                });
              }}
            />
          </div>
        }
        successButtonName="Bulk Create"
        successCallback={this.validateAndCreateTopics}
        cancelCallback={onCloseDialog}
      />
    );
  }
}

export default withStyles(styles)(BulkCreateTopics);
