// @ts-nocheck
import React from "react";
import {
  Avatar,
  AvatarGroup,
  Button,
  Grid,
  Tooltip,
  Typography
} from "@mui/material";
import styles from "./styles/VoteStyle";
import { withStyles } from "@mui/styles";
import Api from "../Services/Api";

import { ReactComponent as TriangleUp } from "../Images/icon16/triangleup.svg";
import { designColors } from "../Themes/Colors";

import styled from "styled-components";
import clsx from "clsx";
import { AvatarSize } from "../Config/theme";
import { getItemFromLocalStorage } from "../Utils/StorageHelper";

import { ANONYMOUS_CLIENT_OBJECT } from "../Containers/Ideas";

const api = Api.create();
const StyledSVG = styled.svg`
  & path {
    fill: ${(props: any) => props.color};
  }
`;

class Vote extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      count: props.count,
      alreadyVoted: props.alreadyVoted,
      hoverUpvote: false,
      hoverDownvote: false,
      viewOnly: props.viewOnly,
      voting: false,
      upvoted: props.upvoted,
      upvotedNames: props.upvotedNames
    };
  }

  voteTopic = () => {
    this.setState({ voting: true }, () => {
      this.saveVote();
    });
  };

  saveVote = () => {
    api.voteTopic(this.props.id, this.props.anonymousClient, (res: any) => {
      if (res.status === 200) {
        this.setState({
          count: res.data.voteCount,
          alreadyVoted: res.data.alreadyVoted,
          voting: false,
          upvoted: res.data.upvotedDetails,
          upvotedNames: res.data.upvotedNames
        });
      } else {
        this.setState({ voting: false });
      }
    });
  };

  render() {
    let { id, anonymousClient, askAnonymousUsername, isUserLoggedIn, classes } =
      this.props;

    let { count, alreadyVoted, viewOnly, voting, upvotedNames, upvoted } =
      this.state;

    return (
      <div>
        <div
          key={id}
          className={classes.voteContainer}
          style={{ pointerEvents: viewOnly || voting ? "none" : "auto" }}
        >
          <Tooltip
            title={alreadyVoted ? "Downvote" : "Upvote"}
            className={classes.voteCountBox}
          >
            <Button
              className={clsx(
                classes.voteCountBox,
                alreadyVoted ? classes.voted : classes.notVoted
              )}
              onClick={
                isUserLoggedIn || anonymousClient
                  ? this.voteTopic
                  : askAnonymousUsername
              }
              fullWidth={false}
            >
              <StyledSVG
                color={
                  alreadyVoted
                    ? designColors.success[50]
                    : designColors.grayScale[50]
                }
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <TriangleUp />
              </StyledSVG>

              <Typography
                variant="h100"
                style={{
                  marginTop: 5,
                  color: alreadyVoted
                    ? designColors.success[50]
                    : designColors.grayScale[50]
                }}
              >
                {count}
              </Typography>
            </Button>
          </Tooltip>
        </div>
        {upvoted?.length > 0 && (
          <div width="100%">
            <AvatarGroup className={classes.avatarGroup}>
              <Tooltip title={upvotedNames}>
                <Grid className={classes.ltr}>
                  {upvoted.map((thumbsUpdetail: any, index: any) => {
                    return (
                      index < 2 && (
                        <div key={index}>
                          <Avatar
                            sx={{
                              ...AvatarSize.xs
                            }}
                            src={thumbsUpdetail?.client?.profilePic || ""}
                            className={classes.upvotedAvatar}
                            alt="logo"
                          />
                        </div>
                      )
                    );
                  })}

                  {upvoted.length > 2 && (
                    <Avatar
                      sx={{
                        ...AvatarSize.xs
                      }}
                    >
                      +{upvoted.length - 2}
                    </Avatar>
                  )}
                </Grid>
              </Tooltip>
            </AvatarGroup>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Vote);
