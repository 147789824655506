// @ts-nocheck
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  mobile: {
    width: 350,
    fontSize: "xx-small",
    marginTop: 20
  },

  desktop: {
    width: "100%",
    marginTop: 20
  },

  errorImage: {
    height: 300
  },

  error: {
    color: colors.fontSecondary,
    textAlign: "center"
  },

  postBody: {
    fontFamily: "Georgia, serif",
    color: colors.fontPrimary,
    fontSize: "21px",
    fontWeight: "400",
    marginTop: 40
  },

  coverImage: {
    width: "100%",
    maxHeight: 400,
    objectFit: "cover",
    objectPosition: "center",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    backgroundPosition: "center !important",
    marginBottom: 10
  },

  postTitleContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2%",
      paddingRight: "2%",
      paddingTop: 10
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "2%",
      paddingRight: "2%",
      paddingTop: 10
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "20%",
      paddingRight: "20%",
      paddingTop: 10
    }
  },

  postWidthContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2%",
      paddingRight: "2%"
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "2%",
      paddingRight: "2%"
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "20%",
      paddingRight: "20%"
    }
  },

  title: {
    fontWeight: "bolder",
    marginBottom: 10,
    wordWrap: "break-word",
    fontSize: 34,
    [theme.breakpoints.down("sm")]: {
      fontSize: 32
    }
  },

  subtitle: {
    color: colors.fontSecondary,
    marginBottom: 10,
    wordWrap: "break-word",
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18
    }
  },

  authorCredits: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center"
  },

  authorName: {
    color: colors.fontPrimary,
    marginLeft: 10,
    fontSize: 16,
    "&:hover": {
      cursor: "pointer"
    }
  },

  avatar: {
    objectFit: "cover",
    backgroundColor: "transparent",
    width: 24,
    height: 24,
    margin: "auto 0",
    "&:hover": {
      cursor: "pointer"
    }
  },

  readTimeAndDate: {
    padding: 0,
    marginTop: 8,
    marginLeft: 0
  },

  date: {
    color: colors.fontSecondary,
    textTransform: "uppercase",
    fontSize: 12,
    marginRight: 8
  },

  readTime: {
    color: colors.fontSecondary,
    textTransform: "uppercase",
    fontSize: 12
  }
});

export default styles;
