// @ts-nocheck
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import React, { Component } from "react";

import Loading from "./Loading";
import StyledButton from "../design/components/StyledButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { apiFetchStatus, InputFieldTypes } from "../Utils/Types";
import { notify } from "./CustomNotifications";
import styles from "./styles/CustomFieldsStyle";
import EditIcon from "@mui/icons-material/Edit";

import StyledDialog from "../design/components/StyledDialog";

import Topic from "../Containers/Topic";

class IdeaTemplates extends Component {
  imagePickerRef: any;
  topicRef: any;
  constructor(props: any) {
    super(props);

    let publication = props.publication;
    this.state = {
      publicationId: publication?._id || "",
      publication: "",
      selectedCustomFieldId: null,
      name: "",
      type: "",
      selectedIndex: null,
      defaultSyntaxHighlightingLanguage:
        publication?.defaultSyntaxHighlightingLanguage,
      showTopic: false,
      ideaTemplateId: "",
      labelSuggestions: [],
      currentIdeaTemplateInEdit: {},
      savingFiles: false
    };
    this.topicRef = React.createRef();
    this.imagePickerRef = React.createRef();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.fetchedCustomFields !== prevProps.fetchedCustomFields) {
      if (this.props.fetchedCustomFields === apiFetchStatus.FAIL) {
        notify.show("Oops, something went wrong.", "error");
      }
    }

    if (this.props.savedCustomFields !== prevProps.savedCustomFields) {
      if (this.props.savedCustomFields === apiFetchStatus.SUCCESS) {
        this.setState({
          nameError: "",
          typeError: "",
          minimumValueError: "",
          defaultValueError: ""
        });
      }

      if (this.props.savedCustomFields === apiFetchStatus.FAIL) {
        notify.show("Oops, something went wrong.", "error");
      }
    }
  }

  toggleShowTopic = () => {
    this.setState((prevState, props) => ({
      showTopic: !prevState.showTopic
    }));
  };

  addIdeaTemplate = () => {
    this.setState(
      {
        currentIdeaTemplateInEdit: null
      },
      () => {
        this.toggleShowTopic();
      }
    );
  };

  deleteIdeaTemplate = (index: any) => {
    let { ideaTemplates } = this.props;

    this.props.deleteIdeaTemplate(ideaTemplates[index]._id);
  };

  saving = () => {
    this.setState({ savingFiles: !this.state.savingFiles });
  };

  callSaveTopic = () => {
    this.topicRef.saveTopicData(true);
  };

  changeInputValue = (event: any) => {
    let { name, value, type } = event.target;

    if (value && type === InputFieldTypes.NUMBER) {
      value = Number(value);
    }

    this.setState({
      [name]: value
    });
  };

  changeFieldType = (event: any) => {
    this.setState({ type: event.target.value, defaultValue: "" });
  };

  editIdeaTemplate = (index: any) => {
    let { ideaTemplates } = this.props;
    this.setState(
      {
        selectedIndex: index,
        ideaTemplateId: ideaTemplates[index]?._id,
        currentIdeaTemplateInEdit: ideaTemplates[index]
      },
      () => {
        this.toggleShowTopic();
      }
    );
  };

  refreshIdeaTemplates = () => {
    this.props.getIdeaTemplates();
  };

  setDefaultIdeaTemplate = (index: any, isDefault: any) => {
    let { ideaTemplates } = this.props;

    this.props.setDefaultIdeaTemplate(ideaTemplates[index]._id, isDefault);
  };

  saveOrUpdate = () => {
    this.setState({
      selectedIndex: null
    });
  };

  render() {
    let { classes, fetchedCustomFields, ideaTemplates } = this.props;
    let loading = fetchedCustomFields !== apiFetchStatus.SUCCESS;
    let {
      publicationId,

      showTopic,

      ideaTemplateId,

      labelSuggestions,

      savingFiles,

      currentIdeaTemplateInEdit
    } = this.state;

    return (
      <Grid item xs={12} sm={12} md={12}>
        <Grid container direction="column">
          {loading && <Loading />}

          {!loading && ideaTemplates?.length > 0 && (
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Template Name</TableCell>

                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {ideaTemplates.map((value: any, index: any) => {
                    return (
                      <TableRow width="100%" key={index}>
                        <TableCell
                          style={{
                            width: "80%"
                          }}
                        >
                          {value.name}
                        </TableCell>

                        <TableCell
                          style={{
                            width: "20%",
                            display: "revert"
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row"
                            }}
                          >
                            <div
                              style={{
                                margin: "auto"
                              }}
                            >
                              {value.isDefault ? (
                                <StyledButton
                                  variant="textsecondary"
                                  onClick={() => {
                                    this.setDefaultIdeaTemplate(index, false);
                                  }}
                                >
                                  Default
                                </StyledButton>
                              ) : (
                                <StyledButton
                                  variant="textsecondary"
                                  onClick={() => {
                                    this.setDefaultIdeaTemplate(index, true);
                                  }}
                                >
                                  Set as Default
                                </StyledButton>
                              )}
                            </div>

                            <div className={classes.actionButton}>
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() => {
                                    this.editIdeaTemplate(index);
                                  }}
                                  size="large"
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </div>

                            <div className={classes.actionButton}>
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => this.deleteIdeaTemplate(index)}
                                  size="large"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Grid
            item
            container
            direction="row"
            className={clsx(classes.marginTop20, classes.justifyContent)}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <StyledButton
                variant="textprimary"
                className={classes.paddingLeft10}
                onClick={this.addIdeaTemplate}
                startIcon={<AddCircleIcon />}
              >
                Add Idea Template
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
        {showTopic && (
          <StyledDialog
            maxWidth="md"
            open={showTopic}
            title={<Typography variant="h500"> </Typography>}
            body={
              <Topic
                ref={(el: any) => {
                  this.topicRef = el;
                }}
                ideaTemplateId={ideaTemplateId}
                publicationId={publicationId}
                closeCallBack={this.toggleShowTopic}
                viewOnly={false}
                labelSuggestions={labelSuggestions}
                currentIdeaTemplateInEdit={currentIdeaTemplateInEdit}
                fromIdeaTemplates={true}
                refreshIdeaTemplates={this.refreshIdeaTemplates}
              />
            }
            successButtonName={savingFiles ? "Saving..." : "Save"}
            successCallback={this.callSaveTopic}
            cancelButtonName={"Cancel"}
            closeCallback={() => {
              this.refreshIdeaTemplates();
              this.toggleShowTopic();
            }}
          />
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(IdeaTemplates);
