// @ts-nocheck
import { Avatar, MenuItem, Popover, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";

import StyledInput from "./components/StyledInput";
import styles from "../Components/styles/TopicAssigneesAndDeadlinesStyle";

import { ReactComponent as SearchIcon } from "../Images/icon20/search.svg";

import StyledCheckbox from "./components/StyledCheckbox";
import { AvatarSize } from "../Config/theme";

function StyledSelectPopover({
  classes,
  open,
  anchorEl,
  variant,
  onClose,
  options,
  onClick,
  displayValue,
  onSearch,
  showAvatar,
  getAvatarUrl,
  isMultiSelector,
  isCheckboxChecked
}: any) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "left",

        horizontal: "bottom"
      }}
      transformOrigin={{
        vertical: "left",
        horizontal: "right"
      }}
      PaperProps={{
        className: classes.eventPopover
      }}
    >
      <StyledInput
        border={"none"}
        placeholder="Search..."
        InputProps={{
          endAdornment: <SearchIcon />
        }}
        onChange={onSearch}
      />

      {options.map((option: any, index: any) => (
        <MenuItem key={index} onClick={(event) => onClick(event, option)}>
          {isMultiSelector && (
            <StyledCheckbox
              size="small"
              checked={isCheckboxChecked(option)}
              labelStyle={{ marginRight: 10 }}
            />
          )}
          {showAvatar && (
            <Avatar src={getAvatarUrl(option)} style={AvatarSize.xs} />
          )}

          <Typography variant={variant} className={classes.authorName}>
            {displayValue(option)}
          </Typography>
        </MenuItem>
      ))}
    </Popover>
  );
}

export default withStyles(styles)(StyledSelectPopover);
