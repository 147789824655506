// @ts-nocheck
import { grey } from "@mui/material/colors";
import { space } from "../../Config/theme";
import colors from "../../Themes/Colors";

const styles = (themes: any) => ({
  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    marginTop: 0,
    padding: 0,
    width: "100%",
    color: colors.fontPrimary,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontPrimary
    }
  },

  menuItem: {
    width: "100%"
  },

  itemName: {
    marginLeft: space.SMALL,
    verticalAlign: "bottom",
    margin: space.XS,
    maxWidth: "480px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "wrap"
  },

  accountDropDown: {
    display: "block",
    width: "100%"
  }
});

export default styles;
