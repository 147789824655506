// @ts-nocheck
export const EXTRACTED_TERM_IDS = "EXTRACTED_TERM_IDS";

function isLocalStorageSupported() {
  return typeof Storage !== "undefined";
}

function isQuotaExceededError(err: any) {
  return (
    err instanceof DOMException &&
    // everything except Firefox
    (err.code === 22 ||
      // Firefox
      err.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      err.name === "QuotaExceededError" ||
      // Firefox
      err.name === "NS_ERROR_DOM_QUOTA_REACHED")
  );
}

export function getItemFromLocalStorage(key: any) {
  if (isLocalStorageSupported()) {
    return window.localStorage.getItem(key);
  } else {
    return "";
  }
}

export function removeAllExtractedTerms() {
  // get and remove all extracted terms in local storage
  let extractedTermIds =
    getItemFromLocalStorage(EXTRACTED_TERM_IDS)?.split(",") || [];
  if (extractedTermIds.length === 0) {
    //if no terms found remove all storage keys
    window.localStorage.clear();
  } else {
    for (let extractedTermId of extractedTermIds) {
      deleteItemInLocalStorage(extractedTermId);
    }
    deleteItemInLocalStorage(EXTRACTED_TERM_IDS);
  }
}

export function setItemInLocalStorage(key: any, value: any) {
  if (isLocalStorageSupported()) {
    try {
      window.localStorage.setItem(key, value);
    } catch (err) {
      if (isQuotaExceededError(err)) {
        removeAllExtractedTerms();
      } else {
        console.log(err);
      }
    }
  }
}

export function deleteItemInLocalStorage(key: any) {
  if (isLocalStorageSupported()) {
    window.localStorage.removeItem(key);
  }
}

export function getItemFromSessionStorage(key: any) {
  if (isLocalStorageSupported()) {
    return window.sessionStorage.getItem(key);
  } else {
    return "";
  }
}

export function setItemInSessionStorage(key: any, value: any) {
  if (isLocalStorageSupported()) {
    window.sessionStorage.setItem(key, value);
  }
}
