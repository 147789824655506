// @ts-nocheck
import colors, { designColors } from "../../Themes/Colors";

const styles = (theme: any) => ({
  voteContainer: {
    width: 64,
    height: 64
  },

  voteCountBox: {
    textAlign: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },

  voted: {
    border: `1px solid ${designColors.success[50]}`,
    background: designColors.success[5],
    "&:hover": {
      background: designColors.success[5]
    }
  },

  notVoted: {
    border: `1px solid ${designColors.grayScale[40]}`,
    background: colors.white,
    "&:hover": {
      background: colors.white
    }
  },

  voteCountNumber: {
    fontSize: 32,
    fontWeight: "bold",
    color: colors.primary
  },

  upvotedAvatar: {
    marginRight: -12,
    border: `2px solid ${colors.white}`,
    boxSizing: "border-box"
  },

  ltr: {
    direction: "ltr",
    display: "flex",
    marginTop: 8,
    alignItems: "center",
    minHeight: 30
  },

  moreNumber: {
    color: colors.fontSecondary,
    marginLeft: 16,
    marginTop: 2,
    wordBreak: "break-word"
  },

  avatarGroup: {
    display: "flex",
    flexDirection: "row"
  }
});
export default styles;
