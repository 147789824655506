// @ts-nocheck
import { space } from "../../Config/theme";
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  container: {
    padding: 0
  },

  cardSubtitle: {
    color: colors.fontSecondary
  },

  marginTop20: {
    marginTop: 20
  },

  paddingLeft10: {
    paddingLeft: 10
  },

  justifyContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },

  width40: {
    width: "40%"
  },

  width100: {
    width: "100%"
  },

  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    width: "100%",
    color: colors.fontInput,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontInput
    }
  },

  delete: {
    width: "9%",
    marginLeft: "1%"
  },

  fieldName: {
    width: "30%",
    marginRight: "1%"
  },

  fieldType: {
    width: "20%",
    marginRight: "1%"
  },

  heading: {
    marginBottom: 20
  },

  imageContainer: {
    display: "inline-flex",
    position: "relative",
    marginTop: space.MEDIUM,
    paddingRight: space.MEDIUM,
    width: 200,
    height: 200
  },

  imageFit: {
    width: "100%",
    objectFit: "cover"
  },

  imageIconContainer: {
    background: "white",
    padding: 0,
    marginTop: space.XS
  },

  closeIcon: {
    position: "absolute",
    top: -15,
    right: 5,
    background: colors.primary,
    color: colors.white,
    padding: space.XXS,
    "&:hover": {
      backgroundColor: colors.primary,
      color: colors.white
    }
  },

  defaultImageContainer: {
    display: "inline-flex",
    position: "relative",
    marginTop: space.MEDIUM,
    paddingRight: space.MEDIUM,
    width: 100,
    height: 75
  }
});
export default styles;
