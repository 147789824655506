// @ts-nocheck
const styles = (theme: any) => ({
  tableContainer: {
    margin: 0
  },

  tableCell: {
    verticalAlign: "baseline"
  }
});

export default styles;
