// @ts-nocheck
import Crop169Icon from "@mui/icons-material/Crop169";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import React, { Component } from "react";

import { withRouter } from "react-router";
import StyledButton from "../design/components/StyledButton";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";

import StyledSwitch from "../design/components/StyledSwitch";
import API from "../Services/Api";
import { uploadImage } from "../Utils/Storage";
import { imageTypes, optionsForLogo } from "../Utils/Types";
import { getAvatar, validateImageType } from "../Utils/UserUtils";
import { notify } from "./CustomNotifications";
import styles from "./styles/PublicationSettingsStyles";

const api = API.create();

const NAME_LIMIT = 100;
class PublicationSettings extends Component {
  uploadRef: any;
  constructor(props: any) {
    super(props);

    let { publication, fromSettings } = props;
    this.state = {
      logoOption: props.publication.logoOption,
      name: publication ? publication.name : "",
      email: publication ? publication.email : "",
      fromSettings: fromSettings,
      domain: publication ? publication.domain : "",
      id: publication ? publication._id : null,
      logo: publication && publication.logo ? publication.logo : getAvatar(),
      uploadLogoStatus: false,
      logoLocalPath: "",
      unsaved: [],
      employeeEmailDomain: publication ? publication.employeeEmailDomain : "",
      clientPublicationsDomain: this.props.clientPublicationsDomain
        ? this.props.clientPublicationsDomain
        : [],
      confirmSignup: publication.emailDetails
        ? publication.emailDetails.signupConfirmation
        : true,
      isSaving: false
    };

    let savedCopy = { ...this.state };

    this.state["savedCopy"] = savedCopy;
    this.uploadRef = React.createRef();
  }

  onImageChange = (event: any) => {
    event.persist();
    if (!validateImageType(event.target.files[0])) {
      notify.show("Upload a PNG, JPG, WEBP or GIF", "error");
      return;
    }
    this.setState(
      {
        logoLocalPath: "",
        uploadLogoStatus: true
      },
      async (file = event.target.files[0]) => {
        var logo = await uploadImage(file, this.props.publication.domain);
        this.setState(
          {
            logo: logo,
            uploadLogoStatus: false,
            logoLocalPath: URL.createObjectURL(file)
          },
          () => {
            this.savePublicationPicture();
          }
        );
      }
    );
  };

  handleChange = (event: any) => {
    let { savedCopy, unsaved } = this.state;
    let { name, value } = event.target;

    if (name === "domain") {
      const regex = /^[a-z0-9-_.]{0,29}$/;
      if (value === "" || regex.test(value)) {
        value = value.toLowerCase();
      } else {
        return;
      }
    }

    if (name === "employeeEmailDomain") {
      const regex = /^[a-z0-9-_.]{0,29}$/;
      if (value === "" || regex.test(value)) {
        value = value.toLowerCase();
      } else {
        return;
      }
    }

    if (name === "name") {
      if (value.length > NAME_LIMIT) {
        return;
      }
    }

    if (savedCopy[name] !== undefined) {
      if (savedCopy[name] !== value) {
        unsaved[name] = value;
      } else if (savedCopy[name] === value) {
        delete unsaved[name];
      }
    }

    this.setState({ [name]: value, unsaved }, () => {
      if (name === "frequency") {
        this.savePublicationField(name);
      }
      if (name === "employeeEmailDomain") {
        this.savePublicationField(name);
      }
    });
  };

  handleSubscribers = (e: any) => {
    var regex = /^$|\d+$/gm;

    if (!regex.test(e.target.value) || e.target.value === "0") {
      return;
    }
    this.setState({ subscribers: e.target.value });
  };

  generateSaveButton = (key: any) => {
    let { classes } = this.props;
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        container
        justifyContent="flex-end"
        spacing={1}
        direction="row"
        className={classes.saveGrid}
      >
        <Grid item>
          <StyledButton onClick={() => this.savePublicationField(key)}>
            Save
          </StyledButton>
        </Grid>

        <Grid item>
          <StyledButton
            variant="tertiary"
            onClick={() => this.revertKeyChange(key)}
          >
            Cancel
          </StyledButton>
        </Grid>
      </Grid>
    );
  };

  revertKeyChange = (key: any) => {
    let { savedCopy, unsaved } = this.state;
    delete unsaved[key];
    this.setState({ [key]: savedCopy[key], unsaved });
  };

  savePublicationField = (key: any) => {
    let { id, unsaved } = this.state;

    let value = this.state[key];
    api.savePublicationField(id, key, value, (res: any) => {
      if (res.status === 200) {
        let { object, value } = res.data;

        let savedCopy = this.state.savedCopy;
        savedCopy[object] = value;
        delete unsaved[key];
        this.setState({
          savedCopy,
          unsaved
        });

        if (this.props.next) {
          this.props.next(res.data);
        }
      }

      if (res.status === 400) {
        notify.show(
          res.data === "Someone already picked that domain"
            ? res.data
            : "Something went wrong. Please contact support@letterdrop.com",
          "error"
        );
      }
    });
  };

  savePublicationPicture = () => {
    let { id, logo } = this.state;
    api.savePublicationPicture(id, logo, (res: any) => {
      if (res.status === 400) {
        notify.show("Upload error. Try again.", "error");
      }
    });
  };

  savePublication = () => {
    let { name, domain, id, uploadLogoStatus, logo } = this.state;

    if (uploadLogoStatus) {
      notify.show(
        "Uploading your logo. Give it a few seconds and try again",
        "warning"
      );
    }

    if (!name.trim()) {
      notify.show("Name your workspace", "error");
      return;
    }

    if (!domain.trim()) {
      notify.show("Enter a domain", "error");
      return;
    }

    this.setState({ isSaving: true }, () => {
      api.savePublication(
        id,
        name,
        domain,
        logo,
        false,
        "",
        false,
        (res: any) => {
          if (res.status === 200) {
            let savedCopy = { ...this.state };
            this.setState({
              savedCopy,
              unsaved: []
            });

            if (this.props.next) {
              this.props.next(res.data);
            }
          } else {
            notify.show(
              res.data === "Someone already picked that domain"
                ? res.data
                : "Something went wrong. Please contact support@letterdrop.com",
              "error"
            );
          }
          this.setState({ isSaving: false });
        }
      );
    });
  };

  handleConfirmChange = () => {
    let id = this.props.publication._id ? this.props.publication._id : "-1";

    let { confirmSignup } = this.state;

    api.saveSignupViaEmail(id, !confirmSignup, (res: any) => {
      if (res.status === 200) {
        this.setState({ confirmSignup: !confirmSignup });
      }

      if (res.status === 400) {
        notify.show("Unable to save", "error");
      }
    });
  };

  render() {
    let { classes, isDialog, publication } = this.props;
    let {
      logoOption,

      id,

      name,

      employeeEmailDomain,

      fromSettings,

      domain,

      logo,

      unsaved,

      confirmSignup,

      isSaving
    } = this.state;
    return (
      <div
        className={clsx(
          (isDialog && classes.dialogContainer) || classes.container
        )}
      >
        <Container className={classes.container}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h400" paragraph>
                {id === "-1" ? "New workspace" : "Your workspace"}
              </Typography>
            </Grid>

            <Grid
              container
              direction="column"
              className={classes.padding10}
              alignItems="center"
              justifyContent="center"
            >
              <div className={classes.logo}>
                {logoOption === optionsForLogo.RECTANGLE ? (
                  <img
                    src={logo}
                    alt="publication logo"
                    height="auto"
                    width="150"
                  />
                ) : (
                  <Avatar
                    variant="rounded"
                    src={logo}
                    className={classes.logoImage}
                    alt="publication logo"
                  />
                )}
              </div>

              <input
                accept={imageTypes.join(",")}
                id="contained-button-file"
                style={{ display: "none" }}
                type="file"
                onChange={this.onImageChange}
                ref={this.uploadRef}
              />

              <StyledButton
                variant="textsecondary"
                className={classes.marginTop15}
                onClick={() => this.uploadRef.current.click()}
              >
                Upload workspace logo
              </StyledButton>
            </Grid>

            <Grid
              container
              direction="row"
              item
              className={classes.marginTop10}
            >
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.marginTop20}
              >
                <Grid item xs={12} sm={12} md={3}>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.marginTop12}
                  >
                    Name
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={5}>
                  <StyledInput
                    className={classes.input}
                    type="text"
                    size="medium"
                    placeholder="Piedpiper"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                  />
                </Grid>
                {unsaved["name"] && this.generateSaveButton("name")}

                <Grid item xs={12} sm={12} md={8}>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.characterCounter}
                  >
                    {name.length}/{NAME_LIMIT}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container xs={12} className={classes.marginTop20}>
                <Grid item xs={12} sm={12} md={3}>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.marginTop12}
                  >
                    Employee Email Domain
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={5}>
                  <StyledInput
                    className={classes.input}
                    type="text"
                    size="medium"
                    placeholder="example.com"
                    name="employeeEmailDomain"
                    value={employeeEmailDomain}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>

              {!fromSettings && (
                <Grid container direction="row" className={classes.margin10}>
                  <Grid item container xs={12} className={classes.marginTop20}>
                    <Grid item xs={12} sm={12} md={3}>
                      <Typography
                        variant="bodym"
                        paragraph
                        className={classes.marginTop12}
                      >
                        Double opt-in
                      </Typography>

                      <FormHelperText>
                        When a reader signs up, send them an email to confirm.
                        This prevents bots and fake sign-ups.
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={12} md={5}>
                      <div
                        className={clsx(classes.input, classes.marginBottom10)}
                      >
                        <FormControlLabel
                          control={
                            <StyledSwitch
                              checked={confirmSignup}
                              onChange={this.handleConfirmChange}
                            />
                          }
                          label=""
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!fromSettings && (
                <Grid item container xs={12} className={classes.marginTop20}>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-end"
                    xs={12}
                    className={classes.marginRight40}
                  >
                    <StyledButton
                      disabled={isSaving}
                      onClick={this.savePublication}
                    >
                      Next
                    </StyledButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(PublicationSettings));
