// @ts-nocheck
import { Avatar, Typography } from "@mui/material";
import * as React from "react";
import { stringToColor, getAvatarName } from "../../Utils/UserUtils";
import images from "../../Themes/Images";

export default function StyledAvatar({ alt = "", src, ...other }: any) {
  // Children: Extract first letter of first and last name from alt, without any special characters,
  // If image src is not available, then show the children
  let altText = getAvatarName(alt);

  return (
    <Avatar
      alt={alt}
      variant="circular"
      src={src || (altText ? "" : images.user)}
      sx={{
        bgcolor: altText ? stringToColor(alt) : "inherit"
      }}
      style={{
        display: "flex"
      }}
      {...other}
    >
      {(altText || "").toUpperCase()}
    </Avatar>
  );
}
