import React, { useState } from "react";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import { Typography, Grid } from "@mui/material";
import API from "../Services/Api";
import { space } from "../Config/theme";
import { notify } from "./CustomNotifications";

const api = API.create();

type Props = {
  apolloApiKey: string;
  publicationId: string;
  onClose: () => void;
  onSave: () => void;
};

const ApolloConnect: React.FC<Props> = ({
  apolloApiKey,
  publicationId,
  onClose,
  onSave
}) => {
  const [apiKey, setApiKey] = useState<string>(
    apolloApiKey ? apolloApiKey : ""
  );

  const tokenInput = () => {
    return (
      <Grid item>
        <Typography variant="bodym" component="p">
          You may request an API key{" "}
          <a
            href="https://app.apollo.io/#/settings/integrations/api"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
        </Typography>
        <br />
        <Typography variant="h200">API Key</Typography>
        <StyledInput
          type="text"
          size="medium"
          fullWidth
          value={apiKey}
          placeholder="KqSL4o4wAKFp6taqcG2R4B"
          style={{ marginTop: space.XS }}
          name="apiKey"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setApiKey(event.target.value)
          }
        />
      </Grid>
    );
  };

  function saveAndClose() {
    api.saveApolloDetails(publicationId, apiKey, (res: any) => {
      if (res.status === 200) {
        onSave();
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Error while updating Apollo details.",
          "error"
        );
      }
    });
  }

  return (
    <StyledDialog
      open={true}
      maxWidth="sm"
      body={tokenInput()}
      title={<Typography variant="h400">Connecting to Apollo</Typography>}
      successButtonName={"Save"}
      successCallback={saveAndClose}
      cancelCallback={onClose}
    />
  );
};

export default ApolloConnect;
