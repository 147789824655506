import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import FileCopy from "@mui/icons-material/FileCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Tooltip,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { Component, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import isURL from "validator/lib/isURL";
import { space } from "../Config/theme";
import StyledButton from "../design/components/StyledButton";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import API from "../Services/Api";
import colors from "../Themes/Colors";
import PageAccessContext from "../Utils/PageAccessContext";
import { notify } from "./CustomNotifications";
import styles from "./styles/WebhookStyles";

const api = API.create();

enum WebhookEndpointEnums {
  ENGAGED_LEAD = "engaged_lead",
  SCRAPED_LEAD = "scraped_lead",
  KEYWORD_MONITORED_POST = "keyword_monitored_post",
  POST_PUBLISHED = "post_published"
}

type WebhookEndpoint = {
  type: WebhookEndpointEnums;
  url: string;
  testing?: boolean;
};

type ClassProps = {
  publication: any;
  classes: Record<keyof ReturnType<typeof styles>, string>;
} & RouteComponentProps<{ id: string }>;

type ClassState = {
  apiKey: string;
  openCustomApiUrlDialog: boolean;
  engagedLeadUrls: string[];
  scrapedLeadUrls: string[];
  keywordMonitoredPostUrls: string[];
  publishedUrls: string[];
  showConfirmationDialog: boolean;
  webhookEndpoints: WebhookEndpoint[];
  savingWebhookEndpoints: boolean;
};

class Webhook extends Component<ClassProps, ClassState> {
  static contextType = PageAccessContext;
  constructor(props: ClassProps) {
    super(props);

    this.state = {
      apiKey: props.publication?.apiKey || "",
      openCustomApiUrlDialog: false,
      engagedLeadUrls: props.publication?.webhook?.engagedLeadUrls || [],
      scrapedLeadUrls: props.publication?.webhook?.scrapedLeadUrls || [],
      keywordMonitoredPostUrls:
        props.publication?.webhook?.keywordMonitoredPostUrls || [],
      publishedUrls: props.publication?.webhook?.publishedUrls || [],
      showConfirmationDialog: false,
      webhookEndpoints: [],
      savingWebhookEndpoints: false
    };
  }

  componentDidMount() {
    const { publication } = this.props;

    this.getWebhookEndpoint();

    if (!publication.apiKey) {
      this.refreshToken();
    }
  }

  refreshToken = () => {
    api.refreshToken(this.props.publication._id, (res) => {
      if (res.status === 200) {
        this.setState({ apiKey: res.data, showConfirmationDialog: false });
      }
    });
  };

  connectToCustomApi = () => {
    this.setState({ openCustomApiUrlDialog: true });
  };

  getWebhookEndpoint() {
    const {
      engagedLeadUrls,
      scrapedLeadUrls,
      keywordMonitoredPostUrls,
      publishedUrls
    } = this.state;

    const webhookEndpoints: WebhookEndpoint[] = [];

    engagedLeadUrls?.forEach((url) => {
      webhookEndpoints.push({
        type: WebhookEndpointEnums.ENGAGED_LEAD,
        url: url
      });
    });

    scrapedLeadUrls?.forEach((url) => {
      webhookEndpoints.push({
        type: WebhookEndpointEnums.SCRAPED_LEAD,
        url: url
      });
    });

    keywordMonitoredPostUrls?.forEach((url) => {
      webhookEndpoints.push({
        type: WebhookEndpointEnums.KEYWORD_MONITORED_POST,
        url: url
      });
    });

    publishedUrls?.forEach((url) => {
      webhookEndpoints.push({
        type: WebhookEndpointEnums.POST_PUBLISHED,
        url: url
      });
    });

    this.setState({ webhookEndpoints });
  }

  saveWebhookEndpoint: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const { webhookEndpoints, savingWebhookEndpoints } = this.state;

    if (savingWebhookEndpoints) return;

    const engagedLeadUrls: string[] = [];
    const scrapedLeadUrls: string[] = [];
    const keywordMonitoredPostUrls: string[] = [];
    const publishedUrls: string[] = [];

    for (let index = 0; index < webhookEndpoints.length; index++) {
      const { url, type } = webhookEndpoints[index];
      if (!isURL(url)) {
        notify.show(
          "Please enter valid url at position " + (index + 1),
          "error"
        );
        return;
      }

      if (type === WebhookEndpointEnums.ENGAGED_LEAD) {
        engagedLeadUrls.push(url);
      } else if (type === WebhookEndpointEnums.SCRAPED_LEAD) {
        scrapedLeadUrls.push(url);
      } else if (type === WebhookEndpointEnums.KEYWORD_MONITORED_POST) {
        keywordMonitoredPostUrls.push(url);
      } else if (type === WebhookEndpointEnums.POST_PUBLISHED) {
        publishedUrls.push(url);
      } else {
        notify.show(
          "Please select valid type at position " + (index + 1),
          "error"
        );
        return;
      }
    }

    this.setState({ savingWebhookEndpoints: true });

    api.saveWebhookUrls(
      this.props.publication._id,
      engagedLeadUrls,
      scrapedLeadUrls,
      keywordMonitoredPostUrls,
      publishedUrls,
      (res) => {
        this.setState({ savingWebhookEndpoints: false });
        if (res.status === 200) {
          this.setState(
            {
              openCustomApiUrlDialog: false,
              engagedLeadUrls,
              scrapedLeadUrls,
              keywordMonitoredPostUrls,
              publishedUrls
            },
            () => {
              notify.show("Saved", "success", 3000);

              if (!this.state.apiKey) {
                this.setState({ apiKey: res.data });
              }
            }
          );
        } else {
          notify.show(
            "We couldn't save your webhooks endpoints",
            "error",
            3000
          );
        }
      }
    );
  };

  testWebhookEndpoint(endpoint: WebhookEndpoint, index: number) {
    const { url, type, testing } = endpoint;

    if (testing) return;

    if (!isURL(url)) {
      notify.show("Please enter valid url", "error");
      return;
    }

    this.updateWebhookEndpoint(index, { testing: true });
    api.testWebhookUrl(this.props.publication._id, url, type, (res) => {
      this.updateWebhookEndpoint(index, { testing: false });
      if (res.status === 200) {
        notify.show("Successfully sent test event to webhook", "success", 3000);
      } else {
        notify.show("Failed to send test event to webhook", "error", 3000);
      }
    });
  }

  addWebhookEndpoint = () => {
    const { webhookEndpoints } = this.state;
    const lastEndpoint = webhookEndpoints[webhookEndpoints.length - 1];

    const newEndpoint = {
      type: lastEndpoint?.type || WebhookEndpointEnums.ENGAGED_LEAD,
      url: ""
    };

    this.setState({ webhookEndpoints: [...webhookEndpoints, newEndpoint] });
  };

  deleteWebhookEndpoint(index: number) {
    this.setState(({ webhookEndpoints }) => {
      webhookEndpoints.splice(index, 1);
      return { webhookEndpoints };
    });
  }

  updateWebhookEndpoint(index: number, data: Partial<WebhookEndpoint>) {
    this.setState(({ webhookEndpoints }) => {
      const endpoint = webhookEndpoints[index];
      if (endpoint) {
        webhookEndpoints[index] = { ...endpoint, ...data };
      }
      return { webhookEndpoints };
    });
  }

  cancelDialog = () => {
    this.setState({ showConfirmationDialog: false });
  };

  refreshApiKey = () => {
    this.setState({
      showConfirmationDialog: true
    });
  };

  render() {
    const {
      apiKey,
      openCustomApiUrlDialog,
      showConfirmationDialog,
      webhookEndpoints,
      savingWebhookEndpoints
    } = this.state;

    const { classes, publication } = this.props;

    return (
      <Fragment>
        <Container className={classes.container}>
          <Typography variant="h400" paragraph>
            API and Webhooks
          </Typography>

          <Grid
            container
            className={[classes.padding20, classes.width100].join(" ")}
          >
            <Grid item xs={12} className={classes.width100}>
              <Grid item container className={classes.embedCopyContainer}>
                <Grid item xs={12}>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.marginTop10}
                  >
                    API Key
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.cardSubtitle}
                  >
                    Use this in the api-key header to make API requests. Check
                    for this key in the api-key header to validate webhooks.
                  </Typography>
                </Grid>

                <Grid item container alignItems="center" xs={8}>
                  <InputBase
                    className={classes.apiKey}
                    value={apiKey ? apiKey : "Hit refresh to generate new keys"}
                    multiline={true}
                    contentEditable={false}
                    onFocus={(event) => event.target.select()}
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  container
                  alignItems="center"
                  style={{ paddingLeft: 10 }}
                >
                  <CopyToClipboard
                    onCopy={() => notify.show("Copied to clipboard", "success")}
                    text={apiKey}
                  >
                    <IconButton size="large">
                      <FileCopy />
                    </IconButton>
                  </CopyToClipboard>

                  <IconButton onClick={this.refreshApiKey} size="large">
                    <RefreshIcon />
                  </IconButton>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.marginTop20}
                  >
                    Webhook Endpoints
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.cardSubtitle}
                  >
                    We’ll send a POST request to these endpoints for events in
                    your workspace
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.marginBottom30}>
                <StyledButton
                  variant="textsecondary"
                  onClick={this.connectToCustomApi}
                >
                  Set up endpoints
                </StyledButton>
              </div>
            </Grid>
          </Grid>
        </Container>

        <StyledDialog
          open={openCustomApiUrlDialog}
          title="Webhook endpoints"
          body={
            <>
              {webhookEndpoints?.map((endpoint, index) => (
                <Box mt={index === 0 ? "-13px" : "0px"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={space.SMALL}
                  >
                    <Typography variant="bodym">
                      {index + 1}. Select event type and enter an endpoint to
                      hit when event occurs
                    </Typography>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => this.deleteWebhookEndpoint(index)}
                        size="large"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={space.SMALL}
                    mb={space.MEDIUM}
                  >
                    <FormControl fullWidth>
                      <StyledSelect
                        onChange={(e: any) => {
                          this.updateWebhookEndpoint(index, {
                            type: e.target.value
                          });
                        }}
                        size="medium"
                        placeholder="Select Stage"
                        value={endpoint.type}
                      >
                        {Object.values(WebhookEndpointEnums).map((item) => (
                          <StyledMenuItem key={item} value={item}>
                            {item === WebhookEndpointEnums.ENGAGED_LEAD
                              ? "New engaged lead"
                              : item === WebhookEndpointEnums.SCRAPED_LEAD
                              ? "New scraped lead"
                              : item ===
                                WebhookEndpointEnums.KEYWORD_MONITORED_POST
                              ? "New post from keyword monitoring"
                              : "Post published/updated/deleted"}
                          </StyledMenuItem>
                        ))}
                      </StyledSelect>
                    </FormControl>
                  </Box>

                  <Box display={"flex"} gap={space.SMALL} mb={space.LARGE}>
                    <StyledInput
                      autoFocus
                      className={classes.input}
                      type="text"
                      size="medium"
                      name={`endpointUrl${index}`}
                      placeholder="https://example.com/webhook-endpoint"
                      value={endpoint.url || ""}
                      onChange={(e) => {
                        this.updateWebhookEndpoint(index, {
                          url: e.target.value
                        });
                      }}
                    />
                    <div>
                      <StyledButton
                        size="medium"
                        variant="secondary"
                        onClick={() => {
                          this.testWebhookEndpoint(endpoint, index);
                        }}
                      >
                        {endpoint.testing && (
                          <CircularProgress
                            size={16}
                            color="inherit"
                            style={{ marginRight: space.XS }}
                          />
                        )}
                        Test
                      </StyledButton>
                    </div>
                  </Box>
                  <hr color={colors.horizontalRule} />
                </Box>
              ))}

              <StyledButton
                variant="textprimary"
                style={{ marginTop: webhookEndpoints?.length ? 16 : 0 }}
                onClick={this.addWebhookEndpoint}
                startIcon={<AddCircleIcon />}
              >
                Add webhook endpoint
              </StyledButton>
            </>
          }
          successButtonName={savingWebhookEndpoints ? "Saving..." : "Save"}
          successCallback={this.saveWebhookEndpoint}
          showSuccessButtonLoading={savingWebhookEndpoints}
          cancelCallback={() => {
            this.setState({ openCustomApiUrlDialog: false });
          }}
        />

        <StyledDialog
          open={showConfirmationDialog}
          title="Are you sure?"
          body="You'll have to replace your API key with the new one if you proceed"
          successButtonName="Yes"
          successCallback={this.refreshToken}
          cancelCallback={this.cancelDialog}
        />
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Webhook));
