// @ts-nocheck
import { space } from "../../Config/theme";
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  paper: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    padding: 10,
    marginTop: space.L
  },

  shareHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  iconContainer: {
    marginRight: space.MEDIUM,
    width: 50
  },

  section: {
    marginBottom: 50,
    flexDirection: "column",
    display: "flex"
  },

  sectionTitle: {
    fontSize: 22,
    fontWeight: 900
  },

  socialContainer: {
    marginBottom: space.MEDIUM,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    width: "100%"
  },

  iconButton: {
    height: 20,
    width: 20,
    marginLeft: 5,
    color: colors.white
  },

  generateButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 5
  },

  reviewTitleContainer: {
    flexDirection: "column",
    display: "flex"
  },

  reviewTitle: {
    marginBottom: space.SMALL
  },

  postType: {
    fontSize: 16,
    marginBottom: space.MEDIUM,
    color: colors.fontSecondary
  }
});

export default styles;
