import React, { useEffect, useState } from "react";
import { type PodcastType } from "../Utils/Types";
import AIApi from "../Services/AIApi";
import Loading from "./Loading";
import { Box, Grid } from "@mui/material";
import StyledDialog from "../design/components/StyledDialog";
import { notify } from "./CustomNotifications";

type TranscriptsDialogProps = {
  url: string;
  type: PodcastType;
  onClose: () => void;
};

const aiApi = AIApi.create();

export function TranscriptsDialog(props: TranscriptsDialogProps) {
  const { url, type, onClose } = props;
  const [transcripts, setTranscripts] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    aiApi.getPodcastTranscript(type, url, (res) => {
      setIsLoading(false);
      if (res.status !== 200) {
        setIsError(true);
      } else {
        setTranscripts(res.data);
      }
    });
  }, [type, url]);

  function copyTranscriptToClipboard() {
    navigator.clipboard.writeText(transcripts).then(() => {
      notify.show("Transcript copied to clipboard.", "success", 3000);
    });
  }

  return (
    <StyledDialog
      open={true}
      title="Raw Transcripts"
      body={
        <Grid sx={{ minHeight: "150px" }}>
          {isLoading && <Loading marginTop="10%" />}
          {isError && <Box>Something went wrong!</Box>}
          {!isLoading && !isError && (
            <Box sx={{ whiteSpace: "pre-wrap" }}>{transcripts}</Box>
          )}
        </Grid>
      }
      successButtonName="Copy to Clipboard"
      successCallback={copyTranscriptToClipboard}
      cancelCallback={onClose}
      cancelButtonName="Close"
    />
  );
}
