// @ts-nocheck
import React, { Component } from "react";
import Api from "../Services/Api";
import { notify } from "./CustomNotifications";

import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";
import styles from "./styles/AuthorStyle";
import { loadStripe } from "@stripe/stripe-js/pure";

import Loading from "./Loading";

const NOTIFICATION_DURATION = 3000;

let STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

const api = Api.create();

class PaidFeatureCheckout extends Component {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.openStripeCheckout();
  }

  getClientReferenceId = () => {
    return (
      (window.Rewardful && window.Rewardful.referral) ||
      "checkout_" + new Date().getTime()
    );
  };

  openStripeCheckout = async () => {
    const stripe = await loadStripe(STRIPE_API_KEY);

    let {
      plan,
      planId,
      client_reference_id = null,
      clientPreference = ""
    } = this.props;

    let clientReferenceId = client_reference_id || this.getClientReferenceId();

    api.getSessionId(
      plan,
      planId,
      clientReferenceId,
      clientPreference,
      (res: any) => {
        if (res.status === 200) {
          let sessionId = res.data;
          this.setState({
            loading: false
          });

          stripe
            .redirectToCheckout({
              sessionId: sessionId
            })
            .then(function (result) {
              console.log(result);
            });
        }

        if (res.status === 400) {
          this.setState({
            loading: false
          });
          notify.show(
            "Unable to create payment",
            "error",
            NOTIFICATION_DURATION
          );
          return;
        }
      }
    );
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return <div></div>;
  }
}

export default withRouter(withStyles(styles)(PaidFeatureCheckout));
