// @ts-nocheck
import React from "react";

import StyledDropZone from "react-drop-zone/dist/StyledDropZone";
import "react-drop-zone/dist/styles.css";
import { withStyles } from "@mui/styles";
import styles from "./styles/UploaderStyle";
import { LINKEDIN_ALLOWED_IMAGES, imageTypes } from "../Utils/Types";
import { notify } from "./CustomNotifications";
import { isJPGorPNG, validateImageType } from "../Utils/UserUtils";

import Loading from "./Loading";
import { Typography } from "@mui/material";

function Uploader({
  classes,
  onImageUpload,
  imageAspectRatio,
  forInstagram,
  allowMp4Video,
  uploading,
  isCoverPhoto = false,
  convertToWebp = false,
  allowDocuments = false,
  forLinkedIn = false
}: any) {
  const onFileChange = async (file: any) => {
    if (forLinkedIn) {
      let fileType = file?.type;
      if (
        fileType &&
        fileType.startsWith("image") &&
        !LINKEDIN_ALLOWED_IMAGES.includes(fileType)
      ) {
        notify.show("Upload a PNG, JPG, WEBP or GIF", "error");
        return;
      }
    }

    if (forInstagram && !isJPGorPNG(file)) {
      notify.show("Upload a PNG or JPG", "error");
      return;
    }
    if (!allowMp4Video && !validateImageType(file)) {
      notify.show("Upload a PNG, JPG, WEBP or GIF", "error");
      return;
    }
    onImageUpload(file);
  };

  function getAcceptedFileFormats() {
    if (allowDocuments) {
      let images = forLinkedIn ? LINKEDIN_ALLOWED_IMAGES.join(", ") : "image/*";
      return (
        images +
        ", application/pdf, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
      );
    }

    if (forLinkedIn) {
      return LINKEDIN_ALLOWED_IMAGES.join(", ");
    }
    if (forInstagram) {
      return "image/png,image/jpeg";
    }
    if (allowMp4Video) {
      return "image/*,video/mp4";
    }
    return imageTypes.join(",");
  }

  function getText() {
    if (allowDocuments) {
      return (
        <>
          <p>Upload an image or a video</p>
          {convertToWebp && (
            <Typography variant="bodym">
              Your images will be compressed, resized, and converted to webp
            </Typography>
          )}
        </>
      );
    }

    if (allowMp4Video) {
      return (
        <>
          <p>Upload an image or a video</p>
          {convertToWebp && (
            <Typography variant="bodym">
              Your images will be compressed, resized, and converted to webp
            </Typography>
          )}
        </>
      );
    }

    if (imageAspectRatio) {
      if (isCoverPhoto) {
        return (
          <>
            <p>Upload an image</p>
            {convertToWebp && (
              <Typography variant="bodym">
                Your images will be compressed, resized, and converted to webp
              </Typography>
            )}
          </>
        );
      }
      return (
        <>
          <p>
            Upload an image with a
            {imageAspectRatio ? imageAspectRatio : " 16:9 "} aspect ratio
          </p>
          {convertToWebp && (
            <Typography variant="bodym">
              Your images will be compressed, resized, and converted to webp
            </Typography>
          )}
        </>
      );
    }

    return (
      <>
        <p>Upload an image</p>
        {convertToWebp && (
          <Typography variant="bodym">
            Your images will be compressed, resized, and converted to webp
          </Typography>
        )}
      </>
    );
  }

  if (uploading) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Loading />

        <Typography className={classes.uploadingText}>Uploading...</Typography>
      </div>
    );
  }

  return (
    <div>
      <StyledDropZone
        accept={getAcceptedFileFormats()}
        className={classes.dropzone}
        onDrop={(file: any, text: any) => onFileChange(file)}
      >
        {getText()}
      </StyledDropZone>
    </div>
  );
}

export default withStyles(styles)(Uploader);
