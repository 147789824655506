// @ts-nocheck
import { space } from "../../Config/theme";
import colors from "../../Themes/Colors";
const styles = (theme: any) => ({
  description: {
    color: colors.fontSecondary,
    margin: "auto 0",
    fontFamily: "Poppins",
    "font-size": "14px"
  },

  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    border: "1px solid transparent",
    borderRadius: "4px",
    padding: space.XXS,
    width: "100%",
    "&:hover": {
      border: "1px solid blue",
      cursor: "pointer"
    }
  },

  icon: {
    background: `${colors.coverPhotoBackground}`,
    "border-radius": "8px",
    marginRight: space.SMALL,
    marginLeft: space.SMALL
  }
});

export default styles;
