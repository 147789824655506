// @ts-nocheck
import React from "react";
import API from "../Services/Api";

import Loading from "../Components/Loading";
import { LAST_MODIFIED_BY_LETTERDROP, appNotification } from "../Utils/Types";
import colors from "../Themes/Colors";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import StyledButton from "../design/components/StyledButton";
import Menu from "@mui/material/Menu";

import { withRouter } from "react-router";
import styles from "./styles/AppNotificationStyle";
import { withStyles } from "@mui/styles";
import { CometChat } from "@cometchat-pro/chat";
import { shadow } from "../Config/theme";
import { getNotificationTimeFormat, getRelativeTime } from "../Utils/TimeUtils";
import { handleSingular } from "../Utils/UserUtils";
import { notify } from "./CustomNotifications";

import CopyToClipboard from "react-copy-to-clipboard";
import Check from "@mui/icons-material/Check";

const api = API.create();
const COMETCHAT_APP_ID = process.env.REACT_APP_COMETCHAT_APP_ID;
let SERVER_URL = process.env.REACT_APP_SERVER_URL;

const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion("us")
  .build();
CometChat.init(COMETCHAT_APP_ID, appSetting).then(
  () => {},
  (error) => {
    console.log("Conversation initialization failed.");
  }
);

class AppNotification extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      publicationId: props.publicationId,
      cometchatId: props.cometchatId,
      notifications: [],
      loading: true
    };
  }

  componentDidMount() {
    api.getAppNotification(this.state.publicationId, (res: any) => {
      if (res.status === 200) {
        this.setState({
          notifications: res.data.appNotifications,
          loading: false
        });
      }
    });
  }

  getNotificationSentence = (notification: any) => {
    if (notification.type === appNotification.TOPIC_ASSIGNED) {
      return (
        <Typography variant="bodym">
          You've been assigned to write <b>{notification.text}</b>.
        </Typography>
      );
    } else if (
      notification.type === appNotification.CONTENT_MAP_REVIEW_REQUEST ||
      notification.type === appNotification.DRAFT_REVIEW_REQUEST
    ) {
      return (
        <Typography variant="bodym">
          You need to review <b>{notification.text}</b>.
        </Typography>
      );
    } else if (
      notification.type === appNotification.CONTENT_MAP_APPROVED ||
      notification.type === appNotification.DRAFT_APPROVED
    ) {
      return (
        <Typography variant="bodym">
          <b>{notification.text}</b> was approved.
        </Typography>
      );
    } else if (notification.type === appNotification.COMMENT_ON_TOPIC) {
      return (
        <Typography variant="bodym">
          New comment on <b>{notification.text}</b>.
        </Typography>
      );
    } else if (notification.type === appNotification.POST_PUBLISHED) {
      return (
        <Typography variant="bodym">
          <b>{notification.text}</b> was published by{" "}
          <b>
            {notification?.client?.name ||
              notification?.client?.email ||
              LAST_MODIFIED_BY_LETTERDROP}
          </b>
          .
        </Typography>
      );
    } else if (notification.type === appNotification.POST_UPDATED) {
      return (
        <Typography variant="bodym">
          <b>{notification.text}</b> was updated by{" "}
          <b>
            {notification?.client?.name ||
              notification?.client?.email ||
              LAST_MODIFIED_BY_LETTERDROP}
          </b>
          .
        </Typography>
      );
    } else if (
      notification.type === appNotification.POST_UPDATED_WEBHOOK_EVENT
    ) {
      return (
        <Typography variant="bodym">
          <b>{notification.text}</b> metadata was updated to match Webflow by{" "}
          <b>
            {notification?.client?.name ||
              notification?.client?.email ||
              LAST_MODIFIED_BY_LETTERDROP}
          </b>
          .
        </Typography>
      );
    } else if (notification.type === appNotification.POST_PREVIEWED) {
      return (
        <Typography variant="bodym">
          <b>{notification.text}</b> was previewed by{" "}
          <b>
            {notification?.client?.name ||
              notification?.client?.email ||
              LAST_MODIFIED_BY_LETTERDROP}
          </b>
          .
        </Typography>
      );
    } else if (notification.type === appNotification.POST_PREVIEW_DELETED) {
      return (
        <Typography variant="bodym">
          <b>{notification.text}</b> preview was deleted by{" "}
          <b>
            {notification?.client?.name ||
              notification?.client?.email ||
              LAST_MODIFIED_BY_LETTERDROP}
          </b>
          .
        </Typography>
      );
    } else if (notification.type === appNotification.POST_DELETED) {
      return (
        <Typography variant="bodym">
          <b>{notification.text}</b> was deleted by{" "}
          <b>
            {notification?.client?.name ||
              notification?.client?.email ||
              LAST_MODIFIED_BY_LETTERDROP}
          </b>
          .
        </Typography>
      );
    } else if (notification.type === appNotification.POST_UNPUBLISHED) {
      return (
        <Typography variant="bodym">
          <b>{notification.text}</b> was unpublished by{" "}
          <b>
            {notification?.client?.name ||
              notification?.client?.email ||
              LAST_MODIFIED_BY_LETTERDROP}
          </b>
          .
        </Typography>
      );
    } else if (
      notification.type === appNotification.POST_NOT_PUBLISHED_WEBFLOW
    ) {
      let { webflowError } = notification.additionalData;
      return (
        <Typography variant="bodym">
          We couldn't publish <b>{notification.text}</b> to Webflow. <br />
          Error:
          {" " + webflowError}
        </Typography>
      );
    } else if (
      notification.type === appNotification.DRAFT_POST_FROM_THEME_GENERATED
    ) {
      return (
        <Typography variant="bodym">
          We drafted content for <b>{notification.text}</b>. Take a look.
        </Typography>
      );
    } else if (notification.type === appNotification.SOCIAL_MEDIA_PUBLISHED) {
      return (
        <Typography variant="bodym">
          A <b>{this.getSocialMediaDetails(notification.url)}</b> was published
          {notification.text ? " on " + notification.text : ""}.
        </Typography>
      );
    } else if (notification.type === appNotification.SOCIAL_MEDIA_APPROVAL) {
      return (
        <Typography variant="bodym">
          You need to review a{" "}
          <b>{this.getSocialMediaDetails(notification.url)}</b>
        </Typography>
      );
    } else if (notification.type === appNotification.CONTENT_DUE) {
      let { stageName, time, date } = notification.additionalData;
      return (
        <Typography variant="bodym">
          <b>{notification.text}</b> is in <b>{stageName}</b> and due on{" "}
          <b>
            {time} {date}
          </b>
        </Typography>
      );
    } else if (notification.type === appNotification.CONTENT_OVER_DUE) {
      let { stageName, time, date } = notification.additionalData;
      return (
        <Typography variant="bodym">
          <b>{notification.text}</b> is overdue. It’s in <b>{stageName}</b> and
          was due on <b>{time}</b> <b>{date}</b>
        </Typography>
      );
    } else if (notification.type === appNotification.STATUS_CHANGED) {
      let { stageName, isMovedBack, dueDate } = notification.additionalData;
      return (
        <Typography variant="bodym">
          <b>{notification.text}</b>
          {isMovedBack ? " was sent back to " : " has entered "}
          <b>{stageName}</b> stage and is assigned to you.{" "}
          {dueDate ? "It’s due by " : ""}
          {dueDate ? <b>{dueDate}</b> : ""}
        </Typography>
      );
    } else if (notification.type === appNotification.LINK_SUGGESTIONS) {
      return <Typography variant="bodym">{notification.text}</Typography>;
    } else if (notification.type === appNotification.SUMMARY_GENERATED) {
      const { summary, type, url } = notification.additionalData;

      return (
        <Grid container>
          <Typography variant="bodym">{notification.text}</Typography>
          <Stack spacing={2} marginTop={2} direction="row">
            <CopyToClipboard
              onCopy={() => {
                notify.show("Summary copied to clipboard.", "success", 3000);
                this.props.setShowAppNotification(false);
              }}
              text={summary}
            >
              <StyledButton variant="textprimary" size="small">
                Copy Summary
              </StyledButton>
            </CopyToClipboard>
            <StyledButton
              onClick={() => this.props.openTranscript(type, url)}
              variant="textprimary"
              size="small"
            >
              Open Transcript
            </StyledButton>
          </Stack>
        </Grid>
      );
    } else if (notification.type === appNotification.SOCIAL_POST_GENERATED) {
      const { socialPost } = notification.additionalData;
      return (
        <Grid container>
          <Typography variant="bodym">{notification.text}</Typography>
          <Stack spacing={2} marginTop={2} direction="row">
            <CopyToClipboard
              onCopy={() => {
                notify.show(
                  "Social post copied to clipboard.",
                  "success",
                  3000
                );
                this.props.setShowAppNotification(false);
              }}
              text={socialPost}
            >
              <StyledButton variant="textprimary" size="small">
                Copy Social Post
              </StyledButton>
            </CopyToClipboard>
          </Stack>
        </Grid>
      );
    } else if (notification.type === appNotification.AI_GENERATION) {
      return (
        <Grid container>
          <Typography variant="bodym">
            <span dangerouslySetInnerHTML={{ __html: notification.text }} />.
          </Typography>
          <Stack spacing={2} marginTop={2} direction="row">
            <CopyToClipboard
              onCopy={() => {
                notify.show("Outline copied to clipboard.", "success", 3000);
                this.props.setShowAppNotification(false);
              }}
              text={notification.additionalData?.text}
            >
              <StyledButton variant="textprimary" size="small">
                Copy Outline
              </StyledButton>
            </CopyToClipboard>
          </Stack>
        </Grid>
      );
    } else if (
      notification.type === appNotification.VIDEO_UPLOADED_TO_YOUTUBE
    ) {
      return <Typography variant="bodym">{notification.text}</Typography>;
    } else if (notification.type === appNotification.VIDEO_READY_FOR_DOWNLOAD) {
      const { videoURL } = notification.additionalData;

      return (
        <Grid>
          <Typography variant="bodym">{notification.text}</Typography>
          <br />
          <StyledButton
            onClick={() => {
              window.location.href = `${SERVER_URL}/workflow/download?url=${videoURL}`;
            }}
            variant="textprimary"
            size="small"
          >
            Download Video
          </StyledButton>
        </Grid>
      );
    } else if (notification.type === appNotification.ERROR_NOTIFICATION) {
      return (
        <Typography variant="bodym">
          <span dangerouslySetInnerHTML={{ __html: notification.text }} />
        </Typography>
      );
    }
  };

  getSocialMediaDetails = (url: any) => {
    if (!url) {
      return "Social media post ";
    }

    if (url.includes("facebook")) {
      return "Facebook post";
    } else if (url.includes("twitter")) {
      return "Twitter post";
    } else if (url.includes("linkedin")) {
      return "LinkedIn post";
    } else if (url.includes("slack")) {
      return "Slack message";
    } else if (url.includes("reddit")) {
      return "Reddit post";
    } else if (url.includes("instagram")) {
      return "Instagram post";
    }
  };

  setNotificationRead = (notification: any) => {
    if (notification.read) {
      this.props.setShowAppNotification(false);
      return;
    }
    api.setAppNotificationRead(
      this.state.publicationId,
      notification._id,
      (res: any) => {
        if (res.status === 200) {
          this.props.updateNotificationCount?.();
        }
      }
    );
    this.props.setShowAppNotification(false);
  };

  openNotification = (notification: any) => {
    if (notification.url) {
      window.location.href = notification.url;
    }
  };

  markAllAsRead = () => {
    let { notifications } = this.state;

    api.setAllAppNotificationRead(this.state.publicationId, (res: any) => {
      if (res.status === 200) {
        notifications = notifications.filter(
          (notification: any) =>
            notification.type === appNotification.AI_GENERATION
        );
        this.setState({ notifications });

        this.props.setCount(0);

        this.props.setShowAppNotification(false);
      }
    });
  };

  openChatConversation = () => {
    window.location.href = `/${this.state.publicationId}/conversations`;
  };

  render() {
    let { notifications, loading } = this.state;
    let {
      appNotificationElement,

      showAppNotification,

      closeAppNotification,

      messages,

      classes
    } = this.props;

    return (
      <Menu
        anchorEl={appNotificationElement}
        open={showAppNotification}
        onClose={closeAppNotification}
        sx={{
          "& .MuiMenuItem-root.Mui-focusVisible": {
            backgroundColor: "transparent"
          },
          "& .MuiMenuItem-root": {
            "&:hover": {
              backgroundColor: colors.buttonHoverBG
            }
          }
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "10px",
            overflow: "auto",
            boxShadow: shadow.SMALL,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 18,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div style={{ width: 400 }}>
          <Grid container direction="row">
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              className={classes.titleContainer}
            >
              <Typography variant="h300">Notifications</Typography>

              <StyledButton
                variant="textsecondary"
                className={classes.readButton}
                onClick={this.markAllAsRead}
              >
                Mark all as read
              </StyledButton>
            </Grid>

            {loading && <Loading marginTop="1%" />}
            {!loading &&
              notifications.length === 0 &&
              messages?.length === 0 && (
                <Grid item xs={12} className={classes.emptyContainer}>
                  <Typography variant="bodym">
                    You've seen everything. No unread notifications.
                  </Typography>
                </Grid>
              )}
            {!loading &&
              messages?.length > 0 &&
              messages.map((chat: any, index: any) => {
                return (
                  <Grid
                    item
                    xs={12}
                    className={classes.item}
                    onClick={this.openChatConversation}
                  >
                    <Typography variant="bodym">
                      You have <b>{chat.count}</b> unread message
                      {handleSingular(chat.count)} from <b>{chat.name}</b>
                    </Typography>
                  </Grid>
                );
              })}
            {!loading &&
              notifications?.length !== 0 &&
              notifications.map((notification: any, index: any) => {
                return (
                  <Grid
                    key={index}
                    container
                    direction="column"
                    className={classes.item}
                    onClick={() => {
                      this.openNotification(notification);
                    }}
                    gap={2}
                    sx={{
                      position: "relative",
                      "&:hover .clear": {
                        display: "flex"
                      }
                    }}
                  >
                    <Grid item>
                      {this.getNotificationSentence(notification)}
                    </Grid>

                    <Typography variant="bodys" className="createdAt">
                      {notification.createdOn
                        ? getNotificationTimeFormat(notification.createdOn)
                        : ""}
                    </Typography>

                    <Grid
                      sx={{
                        position: "absolute",
                        right: 1,
                        top: 0,
                        height: "100%",
                        width: 50,
                        display: "none"
                      }}
                      container
                      alignItems="center"
                      justifyContent="center"
                      className="clear"
                    >
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setNotificationRead(notification);
                        }}
                      >
                        <Check />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </Menu>
    );
  }
}

export default withRouter(withStyles(styles)(AppNotification));
