// @ts-nocheck
import { faReddit } from "@fortawesome/free-brands-svg-icons";
import { faTerminal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import SendIcon from "@mui/icons-material/Send";
import firebase from "firebase/app";
import {
  Avatar,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Tooltip,
  Typography
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";
import { nanoid } from "nanoid";
import React, { Fragment } from "react";

import CopyToClipboard from "react-copy-to-clipboard";

import { Helmet } from "react-helmet";
import { notify } from "../Components/CustomNotifications";

import Loading from "../Components/Loading";
import StyledButton from "../design/components/StyledButton";

import StyledChip from "../design/components/StyledChip";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import API from "../Services/Api";
import POST_API from "../Services/Api";
import colors, { designColors } from "../Themes/Colors";
import ImagesList from "../Themes/Images";
import PageAccessContext from "../Utils/PageAccessContext";
import { formatForTimeWithTimeZone } from "../Utils/TimeUtils";
import {
  datePickerDefault,
  pageAccess,
  postStatus,
  redditPostStatus,
  redditPublishType,
  REDDIT_BODY_CHAR_LIMIT,
  REDDIT_TITLE_CHAR_LIMIT,
  socialMediaStatus,
  socialMediaType,
  generateSocialPostStatus,
  URL_REPLACE_TEXT
} from "../Utils/Types";
import {
  formatNumber,
  getQueryParamsFromURL,
  getScheduleDateFromURL,
  truncateStringAndAddEllipses,
  hideOtherChatWidgets,
  showOtherChatWidgets,
  isAccessDisabled
} from "../Utils/UserUtils";
import styles from "./styles/RedditShareStyle";

import CustomFlatPicker from "../Components/CustomFlatPicker";
import { ReactComponent as AskAIIcon } from "../Images/icon24/ask-ai.svg";
import { space } from "../Config/theme";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import AIInstructionsDialog from "../Components/AIInstructionsDialog";

const Page404 = React.lazy(() => import("../Containers/Page404"));

const _ = require("lodash");
const api = API.create();
const postApi = POST_API.create();
const DEFAULT_INDEX = -1;
class RedditShare extends React.Component {
  static contextType = PageAccessContext;
  fetchSocialPost: any;
  getSubredditsDebounce: any;
  saveRedditPostDebounce: any;
  constructor(props: any) {
    super(props);
    this.state = {
      id:
        props.match?.params?.redditid ||
        props.match?.params?.id ||
        props.postId,
      redditPostId: null,
      loading: true,
      error: false,
      errorMessage: "",
      redditPosts: [],
      openImagePicker: false,
      redditAccounts: null,
      postUrl: null,
      redditPostIndex: null,
      publication: null,
      post: null,
      shareInitialized: false,
      showDeleteConfirmDialog: false,
      deleteInitialized: false,
      backpage: getQueryParamsFromURL(window.location.search, "reddit/drafts")
        .backpage,
      agencyView: getQueryParamsFromURL(window.location.search, "posts")
        .agencyView,
      showDateTimePicker: false,
      scheduledOn: getScheduleDateFromURL(window.location.search, null),
      draftsCount: 0,
      socialPostText: "",
      prevSocialPostText: [],
      showCopyConfirmation: false,
      copyIndex: DEFAULT_INDEX,
      scheduledPostDates: [],
      instructions: "",
      showInstructionDialog: false
    };
    this.fetchSocialPost = [];
  }

  componentDidMount = () => {
    this.load();
    this.saveRedditPostDebounce = _.debounce(this.saveRedditPost, 1000);
    this.getSubredditsDebounce = _.debounce(this.getSubreddits, 1000);
    setTimeout(() => {
      hideOtherChatWidgets();
    }, 3000);
  };

  componentWillUnmount() {
    showOtherChatWidgets();
    this.fetchSocialPost?.forEach((interval: any) => {
      this.clearIntervalToFetchSocialPost(interval);
    });
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (this.props.match?.params.id !== prevProps.match?.params.id) {
      this.setState(
        {
          id: this.props.match?.params?.id || this.props.postId,
          redditPostId: null,
          loading: true,
          error: false,
          errorMessage: "",
          redditPosts: [],
          openImagePicker: false,
          redditAccounts: null,
          postUrl: null,
          redditPostIndex: null,
          publication: null,
          post: null,
          shareInitialized: false,
          showDeleteConfirmDialog: false,
          deleteInitialized: false,
          backpage: getQueryParamsFromURL(
            window.location.search,
            "reddit/drafts"
          ).backpage,
          agencyView: getQueryParamsFromURL(window.location.search, "posts")
            .agencyView,
          showDateTimePicker: false,
          scheduledOn: getScheduleDateFromURL(window.location.search, null),
          instructions: "",
          showInstructionDialog: false
        },
        this.load
      );
    }
  }

  load = () => {
    let { id } = this.state;
    api.getRedditDetails(id, (res: any) => {
      if (res.status === 200) {
        let {
          post,
          redditPosts,
          postUrl,
          publication,
          reddits,
          redditPostId,
          draftsCount
        } = res.data;

        redditPosts = this.getFormattedRedditPosts(
          redditPosts,
          post,
          reddits,
          postUrl,
          publication
        );

        this.setState({
          loading: false,
          postUrl,
          post,
          publication,
          redditAccounts: reddits,
          redditPosts,
          redditPostId,
          draftsCount,
          instructions: publication?.linkedInCustomPrompt || ""
        });
      } else {
        this.setState({
          loading: false,
          error: true,
          errorMessage: res.data
        });
      }
    });
  };

  getFormattedRedditPosts = (
    redditPosts: any,
    post: any,
    redditAccounts: any,
    postUrl: any,
    publication: any
  ) => {
    if (!redditPosts || !redditPosts.length) {
      let { scheduledOn, socialPostText } = this.state;
      let utm = nanoid(10);
      let postBody =
        socialPostText ||
        this.getRedditBodyText(post, postUrl, publication, utm);
      return [
        {
          text: postBody,
          title: post
            ? truncateStringAndAddEllipses(post.title, REDDIT_TITLE_CHAR_LIMIT)
            : "",
          scheduledDate: scheduledOn,
          selectedAccount: null,
          publishType: scheduledOn
            ? redditPublishType.LATER
            : post
            ? redditPublishType.WITH_POST
            : redditPublishType.NOW,
          subreddits: [],
          utm,
          socialPostLoading: false
        }
      ];
    }

    let formattedRedditPosts: any = [];
    redditPosts.forEach((eachRedditPost: any) => {
      let redditPost = {
        redditPostId: eachRedditPost._id,
        title: eachRedditPost.title,
        text: eachRedditPost.text,
        scheduledDate: eachRedditPost.scheduledOn || null,
        selectedAccount: this.getRedditAccountByUserId(
          redditAccounts,
          eachRedditPost
        ),
        publishType: eachRedditPost.publishType,
        status: eachRedditPost.status,
        subreddits: eachRedditPost.subreddits || [],
        utm: eachRedditPost.utm || nanoid(10),
        socialPostLoading: false
      };
      formattedRedditPosts.push(redditPost);
    });
    return formattedRedditPosts;
  };

  getRedditBodyText = (post: any, postUrl: any, publication: any, utm: any) => {
    let text = "";
    if (post && postUrl) {
      text = `We just published a blog post on ${post.title} at ${publication.name}.\nRead the full post here: ${URL_REPLACE_TEXT}`;
    }
    return text;
  };

  getRedditAccountByUserId = (redditAccounts: any, redditPost: any) => {
    if (redditPost.status === redditPostStatus.COMPLETED) {
      return {
        userId: redditPost.userId,
        profilePicture: redditPost.redditProfilePic,
        fullName: redditPost.redditUserName
      };
    }

    if (!redditAccounts || !redditAccounts.length) {
      return "";
    }
    let index = redditAccounts.findIndex((reddit: any) => {
      return reddit.userId === redditPost.userId;
    });
    if (index === -1) {
      return "";
    }
    return redditAccounts[index];
  };

  handleAccountChange = (event: any, redditPostIndex: any) => {
    let { redditPosts } = this.state;
    redditPosts[redditPostIndex].selectedAccount = event.target.value;
    redditPosts[redditPostIndex].subreddits = [];
    this.setState({ redditPosts }, () => {
      this.saveRedditPost(redditPostIndex);
    });
  };

  handleRedditTitleChange = (event: any, redditPostIndex: any) => {
    let { redditPosts } = this.state;

    if (event.target.value.length > REDDIT_TITLE_CHAR_LIMIT) {
      return;
    }
    redditPosts[redditPostIndex].title = event.target.value;
    this.setState({ redditPosts }, () => {
      this.saveRedditPostDebounce(redditPostIndex);
    });
  };

  handleRedditBodyChange = (value: any, redditPostIndex: any) => {
    let { redditPosts } = this.state;
    if (value.length > REDDIT_BODY_CHAR_LIMIT) {
      return;
    }

    redditPosts[redditPostIndex].text = value;
    redditPosts[redditPostIndex].socialPostLoading = false;

    //stop AI post generation if user types
    this.setState({ redditPosts }, () => {
      this.saveRedditPostDebounce(redditPostIndex);
    });
  };

  handlePublishTypeChange = (event: any, redditPostIndex: any) => {
    if (event.target.value === redditPublishType.LATER) {
      // handled by onClick - handlePublishLater
      return;
    }

    let { redditPosts } = this.state;
    redditPosts[redditPostIndex].scheduledDate = null;
    redditPosts[redditPostIndex].publishType = event.target.value;
    this.setState({ redditPosts, redditPostIndex }, () => {
      this.saveRedditPost(redditPostIndex);
    });
  };

  getScheduledPostDates = () => {
    let { redditPosts, publication, redditPostIndex } = this.state;
    api.getScheduledPostDates(
      publication._id,
      redditPosts[redditPostIndex]?.redditPostId,
      redditPosts[redditPostIndex]?.selectedAccount.userId,
      socialMediaType.REDDIT,
      (res: any) => {
        if (res.status === 200) {
          this.setState(
            { scheduledPostDates: res.data?.scheduledPostDates || [] },
            () => this.setState({ showDateTimePicker: true })
          );
        }
      }
    );
  };

  handlePublishLater = (index: any) => {
    let { redditPosts } = this.state;
    this.setState({ redditPostIndex: index }, () =>
      this.getScheduledPostDates(index)
    );
  };

  connectToReddit = () => {
    let { publication, post, scheduledOn } = this.state;
    let fallbackPage = window.location.href.includes("review")
      ? "posts/" + post._id + "/review"
      : "reddit";
    api.connectToReddit(publication._id, fallbackPage, post?._id, {
      scheduledOn: scheduledOn
    });
  };

  saveRedditPost = (redditPostIndex = null) => {
    let { redditPosts, post, publication } = this.state;
    if (!redditPostIndex && redditPostIndex !== 0) {
      return;
    }

    let {
      redditPostId = "",
      text,
      title,
      selectedAccount,
      publishType,
      scheduledDate,
      subreddits,
      saveInitialized,
      utm
    } = redditPosts[redditPostIndex];

    if (!redditPostId && saveInitialized) {
      return;
    }
    //Initial save dont have id, If save called multiple times
    //It will created multiple entries in DB
    redditPosts[redditPostIndex].saveInitialized = !redditPostId ? true : false;
    this.setState({ redditPosts }, () => {
      api.saveRedditPost(
        redditPostId,
        publication._id,
        post?._id || "",
        title,
        text,
        selectedAccount?.userId || "",
        selectedAccount?.profilePicture || "",
        selectedAccount?.fullName || "",
        publishType,
        scheduledDate,
        subreddits,
        utm,
        (res: any) => {
          if (res.status === 200 && !redditPostId) {
            redditPosts[redditPostIndex].redditPostId = res.data.redditPostId;
          }
          redditPosts[redditPostIndex].saveInitialized = false;
          this.setState({ redditPosts });
        }
      );
    });
  };

  handleShareToReddit = () => {
    let { publication, redditPosts, shareInitialized } = this.state;
    let isPublishAccessDisabled = isAccessDisabled(
      publication._id,
      this.context,
      pageAccess.PUBLISH_POST
    );
    if (isPublishAccessDisabled) {
      notify.show("You don’t have the right permissions to publish", "error");
      return;
    }
    if (shareInitialized) {
      return;
    }
    this.setState({ shareInitialized: true }, () => {
      let valid = true;
      for (let redditPost of redditPosts) {
        if (!redditPost.selectedAccount) {
          valid = false;
          notify.show("Select Reddit account to share", "error");
          break;
        }

        if (!redditPost.subreddits || !redditPost.subreddits.length) {
          valid = false;
          notify.show("Select Subreddit to share", "error");
          break;
        }

        if (!redditPost.title) {
          valid = false;
          notify.show("Title your post before share", "error");
          break;
        }

        if (!redditPost.text) {
          valid = false;
          notify.show("Write something before share", "error");
          break;
        }
      }
      if (!valid) {
        this.setState({
          shareInitialized: false
        });
        return;
      }
      this.scheduleRedditPosts();
    });
  };

  scheduleRedditPosts = () => {
    let { redditPosts, post, publication } = this.state;
    api.scheduleRedditPosts(
      publication._id,
      post?._id || "",
      redditPosts,
      (res: any) => {
        if (res.status === 200) {
          let redditPostResponses = res.data;
          let hasScheduled = false;
          let rateLimitExceeded = false;
          for (let redditPostResponse of redditPostResponses) {
            if (redditPostResponse?.scheduledDate && !hasScheduled) {
              hasScheduled = true;
            }
            if (redditPostResponse?.reachedRateLimit) {
              rateLimitExceeded = true;
            }
            if (redditPostResponse?.url) {
              for (let key in redditPostResponse.url) {
                setTimeout(window.open(redditPostResponse.url[key]), 1000);
              }
            }
          }
          if (hasScheduled) {
            notify.show("Your Reddit post was scheduled", "success");
          }
          if (rateLimitExceeded) {
            notify.show(
              "You've reached the ratelimit. Don't worry, the post has been scheduled, please check back shortly.",
              "error"
            );
          }
          this.gotoBackPageORClearFields(redditPostResponses);
          return;
        } else {
          this.setState({
            shareInitialized: false
          });
          notify.show("Oops, something went wrong.", "error");
        }
      }
    );
  };

  gotoBackPageORClearFields = (redditPostResponses = []) => {
    let { isReviewScreen } = this.props;

    let { publication, backpage, redditPostId, agencyView } = this.state;

    if (agencyView) {
      window.location.href = `/${backpage}`;
      return;
    }

    let scheduledPosts = redditPostResponses.filter(
      (post) => post?.scheduledDate
    );

    if (scheduledPosts[0]?._id && !isReviewScreen) {
      let toUrl = `/${publication._id}/calendar/?mediaId=${
        scheduledPosts[0]._id
      }&createdOn=${new Date(scheduledPosts[0].scheduledDate).toISOString()}`;

      this.props.history.push(toUrl);
      return;
    }

    if (redditPostId) {
      this.props.history.push(`/${publication._id}/${backpage}`);
      return;
    }

    this.setState({ shareInitialized: false }, this.load);
  };

  getScheduleDate = (scheduledDate: any) => {
    let formattedScheduledDate = "";
    if (scheduledDate) {
      formattedScheduledDate = formatForTimeWithTimeZone(moment(scheduledDate));
    }
    //Reason to use &nbsp; instead of space
    //If we use space its getting trimmed in dropdown menu
    return formattedScheduledDate ? (
      <>
        on&nbsp;<b>{formattedScheduledDate.split("at")[0]}</b>&nbsp;at&nbsp;
        <b>{formattedScheduledDate.split("at")[1]}</b>
      </>
    ) : (
      ""
    );
  };

  onScheduleDateChange = (date: any) => {
    let { redditPosts, redditPostIndex } = this.state;
    let currentDate = new Date();
    if (date > currentDate) {
      redditPosts[redditPostIndex].scheduledDate = date;
      redditPosts[redditPostIndex].publishType = redditPublishType.LATER;
      this.setState({ redditPosts }, () =>
        this.saveRedditPost(redditPostIndex)
      );
    }
  };

  handlePostToAnotherAccount = () => {
    let { redditPosts, post, redditAccounts, postUrl, publication } =
      this.state;
    if (redditPosts?.length && redditPosts[0]) {
      let lastPostIndex = redditPosts.length - 1; //Fetch details from last post
      let redditPost = { ...redditPosts[lastPostIndex] };
      let utm = nanoid(10);
      let postBody =
        redditPost.text ||
        this.getRedditBodyText(post, postUrl, publication, utm);
      redditPost.text = postBody;
      redditPost.redditPostId = null;
      redditPost.selectedAccount = null;
      redditPost.status = redditPostStatus.DRAFT;
      redditPost.utm = utm;
      redditPosts.push(redditPost);
    } else {
      redditPosts = this.getFormattedRedditPosts(
        redditPosts,
        post,
        redditAccounts,
        postUrl,
        publication
      );
    }

    this.setState({
      redditPosts
    });
  };

  setIntervalToFetchSocialPost = (index: any) => {
    if (this.fetchSocialPost[index]) {
      return;
    }
    this.generateSocialPost(index, true);

    this.fetchSocialPost[index] = setInterval(() => {
      this.generateSocialPost(index, false);
    }, 5000);
  };

  clearIntervalToFetchSocialPost = (index: any) => {
    clearInterval(this.fetchSocialPost[index]);
    this.fetchSocialPost[index] = null;
  };

  generateSocialPost = (index: any, regenerate: any) => {
    let { post, redditPosts, prevSocialPostText, instructions, publication } =
      this.state;
    redditPosts[index].socialPostLoading = true;
    this.setState({
      redditPosts
    });
    postApi.generateSocialPostFromBlog(
      publication._id,
      post._id,
      post.htmlText,
      regenerate,
      socialMediaType.LINKED_IN,
      instructions,
      redditPosts[index]?.selectedAccount?.userId || "",
      [], // samples
      (res: any) => {
        if (res.status === 200) {
          let socialPost = res.data.socialPost;

          if (socialPost?.status !== generateSocialPostStatus.COMPLETED) {
            return;
          }

          this.clearIntervalToFetchSocialPost(index);
          redditPosts[index].socialPostLoading = false;
          this.setState({
            redditPosts
          });

          if (!socialPost?.body) {
            return;
          }

          this.setState({
            prevSocialPostText: [
              ...prevSocialPostText,
              redditPosts[index]?.text || ""
            ],
            socialPostText: socialPost.body
          });
          this.handleRedditBodyChange(socialPost.body, index);
        } else {
          redditPosts[index].socialPostLoading = false;
          this.setState({
            redditPosts
          });

          notify.show(
            res.status === 400
              ? res.data
              : "Oops, something went wrong. Try again.",
            "error"
          );
        }
      }
    );
  };

  showConfirmDialog = (redditPostIndex = null) => {
    this.setState({
      redditPostIndex,
      showDeleteConfirmDialog: true
    });
  };

  deleteRedditPost = () => {
    //Handles double click

    if (this.state.deleteInitialized) {
      return;
    }

    this.setState({ deleteInitialized: true }, () => {
      let {
        redditPosts,

        publication,

        post,

        redditPostIndex,

        redditPostId,

        backpage
      } = this.state;
      if (!redditPosts[redditPostIndex].redditPostId) {
        redditPosts.splice(redditPostIndex, 1);
        this.setState({
          redditPosts,
          showDeleteConfirmDialog: false,
          deleteInitialized: false
        });
        return;
      }
      api.deleteRedditPost(
        publication._id,
        redditPosts[redditPostIndex].redditPostId,
        post?._id || "",
        (res: any) => {
          if (res.status === 200) {
            redditPosts.splice(redditPostIndex, 1);
            this.setState({
              redditPosts,
              showDeleteConfirmDialog: false,
              deleteInitialized: false
            });
            if (redditPostId) {
              this.props.history.push(`/${publication._id}/${backpage}`);
              return;
            }
            return;
          }
          this.setState({
            showDeleteConfirmDialog: false,
            deleteInitialized: false
          });
          notify.show(
            res.status === 400 ? res.data : "Oops, something went wrong.",
            "error"
          );
        }
      );
    });
  };

  handleDialogClose = () => {
    this.setState({
      showDeleteConfirmDialog: false,
      redditPostIndex: null
    });
  };

  handleSubreddditSelection = (value: any, redditPostIndex: any) => {
    let { redditPosts } = this.state;
    redditPosts[redditPostIndex].subreddits = value;
    this.setState({ redditPosts }, () => {
      this.saveRedditPost(redditPostIndex);
    });
  };

  searchSubReddits = (event: any, redditPostIndex: any) => {
    let { redditPosts } = this.state;
    if (!event?.target?.value) {
      return;
    }
    if (!redditPosts[redditPostIndex]?.selectedAccount?.userId) {
      notify.show("Select an account to choose subreddits", "error");
      return;
    }
    this.getSubredditsDebounce(event.target.value, redditPostIndex);
  };

  getSubreddits = (searchQuery: any, redditPostIndex: any) => {
    let { publication, redditPosts, post } = this.state;
    let userId = redditPosts[redditPostIndex]?.selectedAccount?.userId;
    api.getSubreddits(
      publication._id,
      searchQuery,
      userId,
      post?._id || "",
      (res: any) => {
        if (res.status === 200) {
          if (
            res.data &&
            redditPosts &&
            redditPosts[redditPostIndex]?.selectedAccount
          ) {
            redditPosts[redditPostIndex].selectedAccount.subreddits = res.data;
          }
          this.setState({
            redditPosts
          });
          return;
        }
        notify.show(
          res.status === 400 ? res.data : "Unable to fetch your subreddits.",
          "error"
        );
      }
    );
  };

  copyEverywhere = (redditPostIndex: any) => {
    let { redditPosts, copyIndex } = this.state;
    for (let index = 0; index < redditPosts.length; index++) {
      if (redditPosts[index].status !== socialMediaStatus.COMPLETED) {
        redditPosts[index].title = redditPosts[copyIndex].title;
        redditPosts[index].publishType = redditPosts[copyIndex].publishType;
        redditPosts[index].scheduledDate = redditPosts[copyIndex].scheduledDate;
        redditPosts[index].text = redditPosts[copyIndex].text;
        this.setState({ index }, () => {
          this.saveRedditPost(index);
        });
      }
    }
    this.toggleShowCopyConfirmation(DEFAULT_INDEX);
  };

  toggleShowCopyConfirmation = (index: any) => {
    let { showCopyConfirmation } = this.state;
    this.setState({
      showCopyConfirmation: !showCopyConfirmation,
      copyIndex: index
    });
  };

  showInstruction = (showInstructionDialog: any) => {
    this.setState({
      showInstructionDialog
    });
  };

  onSaveInstructions = (instructions: any) => {
    this.setState({
      instructions: instructions.trim(),
      showInstructionDialog: false
    });
  };

  renderRedditPost = (reddit: any, redditPostIndex: any) => {
    let { classes, isReviewScreen } = this.props;

    let { postUrl, redditAccounts, post, publication } = this.state;
    let showAdditionalPublishType =
      post &&
      ((post.status && post.status !== postStatus.PUBLISHED) ||
        post.scheduledOn);

    return (
      <Fragment key={redditPostIndex}>
        <div style={{ position: "relative" }}>
          {redditPostIndex !== 0 && <Divider style={{ margin: 20 }} />}
          {reddit.status === redditPostStatus.COMPLETED && (
            <div className={classes.editableLabelDiv}>
              <Typography className={classes.editableLabel}>
                This post is published and can’t be updated
              </Typography>
            </div>
          )}
        </div>

        <Grid
          container
          direction="column"
          className={
            isReviewScreen ? classes.sectionReviewScreen : classes.section
          }
          style={{ marginTop: redditPostIndex !== 0 ? 30 : 0 }}
        >
          <Grid
            container
            id="Account"
            xs={12}
            sm={12}
            md={6}
            className={classes.accountSection}
          >
            <Typography className={classes.cardTitle}>Account</Typography>

            <Typography className={classes.cardSubtitle}>
              Which user’s account do you want to post from?
            </Typography>

            <Grid item>
              <FormControl
                variant="outlined"
                size="medium"
                fullWidth
                className={classes.input}
              >
                <StyledSelect
                  value={reddit.selectedAccount || ""}
                  onChange={(event: any) =>
                    this.handleAccountChange(event, redditPostIndex)
                  }
                  size="large"
                  disabled={reddit.status === redditPostStatus.COMPLETED}
                  className={classes.input}
                  renderValue={() => {
                    return (
                      <div className={classes.selectedAccount}>
                        <Avatar
                          variant="circular"
                          src={reddit.selectedAccount?.profilePicture}
                          alt="Reddit profile picture"
                          className={classes.avatar}
                        />

                        <Typography className={classes.userName}>
                          {reddit.selectedAccount?.fullName}
                        </Typography>
                      </div>
                    );
                  }}
                >
                  {redditAccounts.map((reddit: any) => {
                    return (
                      <StyledMenuItem value={reddit} key={reddit}>
                        <Avatar
                          variant="circular"
                          src={reddit.profilePicture}
                          alt="Reddit profile picture"
                          className={classes.avatar}
                        />

                        <Typography className={classes.userName}>
                          {reddit.fullName}
                        </Typography>
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            id="Subreddits"
            xs={12}
            sm={12}
            md={6}
            className={classes.accountSection}
          >
            <Typography className={classes.cardTitle}>Subreddits</Typography>

            <Typography className={classes.cardSubtitle}>
              What subreddits do you want to post to?
            </Typography>

            <Grid item>
              <Autocomplete
                options={reddit.selectedAccount?.subreddits || []}
                disabled={reddit.status === redditPostStatus.COMPLETED}
                value={reddit.subreddits || []}
                multiple
                onInputChange={(event) => {
                  this.searchSubReddits(event, redditPostIndex);
                }}
                onChange={(event, value) => {
                  this.handleSubreddditSelection(value, redditPostIndex);
                }}
                fullWidth
                renderInput={(params) => (
                  <StyledInput {...params} size="large" variant="outlined" />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <StyledChip
                      variant="gray"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
          </Grid>

          <Grid container id="Title" xs={12} className={classes.accountSection}>
            <Typography className={classes.cardTitle}>Title</Typography>

            <Typography className={classes.cardSubtitle}>
              Enter the title of your Reddit post.
            </Typography>

            <Grid item>
              <StyledInput
                placeholder="Your Reddit post title"
                size="large"
                disabled={reddit.status === redditPostStatus.COMPLETED}
                fullWidth
                name="title"
                value={reddit.title || ""}
                onChange={(event: any) =>
                  this.handleRedditTitleChange(event, redditPostIndex)
                }
                className={classes.textContainer}
              />

              <Typography className={classes.charCount}>
                {formatNumber(reddit.title.length)} /{" "}
                {formatNumber(REDDIT_TITLE_CHAR_LIMIT)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container id="Body" xs={12} className={classes.accountSection}>
            <Typography className={classes.cardTitle}>Body</Typography>

            <Typography className={classes.cardSubtitle}>
              Enter the body of your Reddit post.
            </Typography>

            <Grid item>
              <Grid container>
                <Grid item style={{ width: "100%" }}>
                  <StyledInput
                    placeholder="Start writing your Reddit post body"
                    multiline
                    disabled={reddit.status === redditPostStatus.COMPLETED}
                    maxRows={8}
                    minRows={5}
                    name="body"
                    value={reddit.text || ""}
                    onChange={(event: any) =>
                      this.handleRedditBodyChange(
                        event.target.value,
                        redditPostIndex
                      )
                    }
                    onKeyDown={(e: any) => {
                      // if ctrl + z pressed
                      if (e.ctrlKey && e.keyCode === 90) {
                        let { prevSocialPostText } = this.state;
                        if (prevSocialPostText.length) {
                          let oldValue = prevSocialPostText.pop();
                          this.setState({ prevSocialPostText });
                          this.handleRedditBodyChange(
                            oldValue,
                            redditPostIndex
                          );
                        }
                      }
                    }}
                    className={classes.textAreaOutline}
                  />
                </Grid>
                {isReviewScreen && (
                  <Grid
                    item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    {reddit?.socialPostLoading ? (
                      <CircularProgress
                        style={{ marginLeft: 20, marginRight: -60 }}
                        size={25}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: space.SMALL,
                          marginRight: -60
                        }}
                      >
                        <Tooltip title="Generate Social Post">
                          <IconButton
                            size="large"
                            onClick={() =>
                              this.setIntervalToFetchSocialPost(redditPostIndex)
                            }
                          >
                            <AskAIIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Instructions">
                          <IconButton
                            size="large"
                            style={{
                              marginTop: space.XXS
                            }}
                            onClick={() => this.showInstruction(true)}
                          >
                            <FontAwesomeIcon
                              icon={faTerminal}
                              color={colors.iconColor}
                              style={{ width: 24, height: 24 }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </Grid>
                )}
              </Grid>

              <Typography className={classes.charCount}>
                {formatNumber(reddit.text.length)} /{" "}
                {formatNumber(REDDIT_BODY_CHAR_LIMIT)}
              </Typography>
            </Grid>
          </Grid>

          {postUrl && (
            <Grid
              container
              id="Post"
              xs={12}
              sm={12}
              md={6}
              className={classes.accountSection}
            >
              <Typography className={classes.cardTitle}>
                Link to your post
              </Typography>

              <Typography className={classes.cardSubtitle}>
                Copy this link into the comments of your post, or use{" "}
                {URL_REPLACE_TEXT}, and we'll replace it with the final URL.
              </Typography>

              <Grid item>
                <InputBase
                  className={classes.postUrl}
                  value={postUrl + "?utm=" + reddit.utm}
                  multiline={true}
                  contentEditable={false}
                  onFocus={(event) => event.target.select()}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <CopyToClipboard
                        onCopy={() =>
                          notify.show("Copied to clipboard", "success")
                        }
                        text={postUrl + "?utm=" + reddit.utm}
                      >
                        <IconButton size="large">
                          <FileCopyOutlinedIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          )}

          <Grid
            container
            id="publish_type"
            xs={12}
            sm={12}
            md={6}
            className={classes.accountSection}
            style={{ marginBottom: 20 }}
          >
            <Typography className={classes.cardTitle}>When</Typography>

            <Typography className={classes.cardSubtitle}>
              Schedule when your Reddit post should go out{" "}
              {post && (
                <Tooltip title="Scheduled time change will only reflect after post is updated">
                  <InfoIcon className={classes.errorIcon} />
                </Tooltip>
              )}
            </Typography>

            <Grid item>
              <FormControl
                variant="outlined"
                size="medium"
                fullWidth
                className={classes.input}
              >
                <StyledSelect
                  value={reddit.publishType}
                  disabled={reddit.status === redditPostStatus.COMPLETED}
                  onChange={(event: any) =>
                    this.handlePublishTypeChange(event, redditPostIndex)
                  }
                  size="large"
                  className={classes.input}
                >
                  {!post && (
                    <StyledMenuItem value={redditPublishType.NOW}>
                      Now
                    </StyledMenuItem>
                  )}

                  <StyledMenuItem
                    value={redditPublishType.LATER}
                    onClick={() => {
                      this.handlePublishLater(redditPostIndex);
                    }}
                  >
                    {reddit.publishType === redditPublishType.LATER &&
                    reddit.scheduledDate
                      ? this.getScheduleDate(reddit.scheduledDate)
                      : "Later"}
                  </StyledMenuItem>
                  {post && (
                    <StyledMenuItem value={redditPublishType.WITH_POST}>
                      Same time as the blog post
                    </StyledMenuItem>
                  )}
                  {(showAdditionalPublishType ||
                    reddit.publishType ===
                      redditPublishType.TEN_MINUTES_LATER) && (
                    <StyledMenuItem value={redditPublishType.TEN_MINUTES_LATER}>
                      10 minutes later
                    </StyledMenuItem>
                  )}
                  {(showAdditionalPublishType ||
                    reddit.publishType ===
                      redditPublishType.THIRTY_MINUTES_LATER) && (
                    <StyledMenuItem
                      value={redditPublishType.THIRTY_MINUTES_LATER}
                    >
                      30 minutes later
                    </StyledMenuItem>
                  )}
                  {(showAdditionalPublishType ||
                    reddit.publishType === redditPublishType.HOUR_LATER) && (
                    <StyledMenuItem value={redditPublishType.HOUR_LATER}>
                      1 hour later
                    </StyledMenuItem>
                  )}
                  {(showAdditionalPublishType ||
                    reddit.publishType === redditPublishType.DAY_LATER) && (
                    <StyledMenuItem value={redditPublishType.DAY_LATER}>
                      A day later
                    </StyledMenuItem>
                  )}
                </StyledSelect>
              </FormControl>
            </Grid>
          </Grid>
          {reddit.status !== redditPostStatus.COMPLETED && (
            <Grid
              container
              style={{
                marginBottom: 20,
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <StyledButton
                variant="destructivesecondary"
                onClick={() => this.showConfirmDialog(redditPostIndex)}
                color="secondary"
              >
                Delete draft
              </StyledButton>

              <Tooltip title="Copy everywhere">
                <IconButton
                  onClick={() =>
                    this.toggleShowCopyConfirmation(redditPostIndex)
                  }
                  style={{ marginRight: `${space.SMALL}` }}
                  size="large"
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  };

  render() {
    let { classes, isReviewScreen = false, postId } = this.props;
    let {
      loading,

      error,

      errorMessage,

      redditPosts,

      redditAccounts,

      showDateTimePicker,

      shareInitialized,

      publication,

      showDeleteConfirmDialog,

      redditPostId,

      redditPostIndex,

      draftsCount,

      showCopyConfirmation,

      scheduledPostDates,

      showInstructionDialog,

      instructions
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Page404 errorMessage={errorMessage} />;
    }

    return (
      <Container
        style={{
          padding: isReviewScreen ? 0 : "24px 24px 100px 24px"
        }}
      >
        {!isReviewScreen && (
          <>
            <Helmet>
              <title>
                Reddit {publication && "- " + publication.name} | Letterdrop
              </title>
            </Helmet>
          </>
        )}

        <Paper
          className={clsx(
            isReviewScreen ? classes.paper : classes.reducedPaperMargin
          )}
        >
          {!isReviewScreen && (
            <>
              <div className={classes.headerTitle}>
                <FontAwesomeIcon
                  icon={faReddit}
                  className={classes.redditIcon}
                />

                <Typography variant="h500" className={classes.headerText}>
                  Create a Reddit Post
                </Typography>
              </div>

              <div className={classes.header}>
                {redditAccounts &&
                  redditAccounts.length > 0 &&
                  redditPosts?.length > 0 && (
                    <>
                      <div className={classes.draftDiv}>
                        <StyledButton
                          className={classes.redditDraft}
                          variant="textsecondary"
                          size="small"
                          onClick={() => {
                            this.props.history.push(
                              `/${publication._id}/reddit/drafts`
                            );
                          }}
                        >
                          Drafts{" "}
                          <Typography
                            variant="h100"
                            style={{
                              marginLeft: 5,
                              color: designColors.grayScale[80]
                            }}
                          >
                            {draftsCount}
                          </Typography>
                        </StyledButton>
                      </div>

                      <div className={classes.shareToReddit}>
                        <StyledButton
                          onClick={this.handleShareToReddit}
                          className={classes.shareToRedditButton}
                          startIcon={
                            shareInitialized ? "" : <SendIcon size="small" />
                          }
                        >
                          {shareInitialized && (
                            <CircularProgress
                              size={20}
                              color="inherit"
                              style={{ marginRight: 10 }}
                            />
                          )}
                          {shareInitialized ? "Posting..." : "Post to Reddit"}
                        </StyledButton>
                      </div>
                    </>
                  )}
              </div>
            </>
          )}

          {!redditAccounts || !redditAccounts.length ? (
            <Grid
              container
              direction="row"
              className={classes.connectAccountSection}
            >
              <img
                src={ImagesList.socialMedia}
                className={classes.connectAccountImage}
                alt="Connect Reddit account"
              />

              <Typography className={classes.connectAccountText}>
                Connect a Reddit account to start posting
              </Typography>

              <StyledButton
                onClick={this.connectToReddit}
                style={{
                  background: colors.white,
                  color: colors.black,
                  marginTop: 25
                }}
                disabled={firebase.auth().currentUser ? false : true}
                startIcon={
                  <FontAwesomeIcon
                    icon={faReddit}
                    title="Reddit"
                    style={{
                      color: colors.reddit
                    }}
                  />
                }
              >
                Connect to Reddit
              </StyledButton>
            </Grid>
          ) : (
            <Fragment>
              {redditPosts.map((reddit: any, redditPostIndex: any) => {
                return this.renderRedditPost(reddit, redditPostIndex);
              })}

              <Divider />
              {!redditPostId && (
                <Grid
                  id="add_another_account"
                  container
                  justifyContent="space-between"
                  className={classes.anotherAccount}
                >
                  <StyledButton
                    variant="secondary"
                    onClick={this.handlePostToAnotherAccount}
                    startIcon={<AddCircleIcon />}
                  >
                    {!redditPosts?.length
                      ? "Post to account"
                      : "Post to another account"}
                  </StyledButton>
                </Grid>
              )}
            </Fragment>
          )}

          <CustomFlatPicker
            publicationId={publication._id}
            open={showDateTimePicker}
            value={redditPosts[redditPostIndex]?.scheduledDate}
            disablePast={true}
            onClose={(date) => {
              this.setState(
                {
                  showDateTimePicker: false
                },

                this.onScheduleDateChange(date)
              );
            }}
            type={datePickerDefault.SOCIAL}
            scheduledDates={scheduledPostDates.map(
              (date: any) => new Date(date)
            )}
          />

          <StyledDialog
            open={showDeleteConfirmDialog}
            title="Are you sure?"
            body="Once deleted, you can't recover your post"
            successButtonName="Yes, I'm sure"
            successCallback={this.deleteRedditPost}
            cancelCallback={() => {
              this.setState({
                showDeleteConfirmDialog: false
              });
            }}
          />

          <StyledDialog
            open={showCopyConfirmation}
            title="Do you want to copy this to other accounts?"
            body="We'll overwrite the body, time, and other details of other accounts."
            closeCallback={() => this.toggleShowCopyConfirmation(DEFAULT_INDEX)}
            cancelCallback={() =>
              this.toggleShowCopyConfirmation(DEFAULT_INDEX)
            }
            successCallback={() => this.copyEverywhere()}
            successButtonName="OK"
            maxWidth="sm"
            fullWidth
          />
          {showInstructionDialog && (
            <AIInstructionsDialog
              open={showInstructionDialog}
              value={instructions}
              onSaveInstructions={this.onSaveInstructions}
              onClose={() => this.showInstruction(false)}
              publicationId={publication._id}
              postId={postId}
            />
          )}
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(RedditShare);
