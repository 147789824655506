// @ts-nocheck
import ReorderIcon from "@mui/icons-material/Reorder";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import MuiTableCell from "@mui/material/TableCell";
import { withStyles } from "@mui/styles";
import React from "react";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { notify } from "../Components/CustomNotifications";

import StyledChip from "../design/components/StyledChip";
import StyledPagination from "../design/components/StyledPagination";
import Api from "../Services/Api";
import colors from "../Themes/Colors";
import { formatForDisplayDateTime } from "../Utils/TimeUtils";
import styles from "./styles/TopicsTableViewStyle";
import { customFieldDataType, sortDirection } from "../Utils/Types";

const api = Api.create();

const TableCell = withStyles({
  root: {
    padding: "10px 10px"
  }
})(MuiTableCell);

class TopicsTableView extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      topics: this.props.topics,
      customFields: []
    };
  }

  componentDidMount = () => {
    this.getCustomFields(this.props.publicationId);
  };

  getCustomFields = (publicationId: any) => {
    api.getProjectCustomFields(publicationId, (res: any) => {
      if (res.status === 200) {
        this.setState({
          customFields: res.data?.fields ? res.data.fields : []
        });
      } else {
        notify.show("Oops, something went wrong.", "error");
      }
    });
  };

  formatNumber = (number: any) => {
    return number.toLocaleString();
  };

  handleDragEnd = (result: any, provided: any) => {
    if (!result.destination) {
      return;
    }

    let sourceIndex = result.source.index;
    let destinationIndex = result.destination.index;
    if (sourceIndex === destinationIndex) {
      return;
    }

    let { temp, updateQueries } = this.rearragePositionAndGetUpdateQuery(
      sourceIndex,
      destinationIndex
    );

    api.setTopicPosition(updateQueries, (res: any) => {
      if (res.status === 200) {
        this.setState({ topics: temp });
        return;
      }
      notify.show("Unable to set the topic position", "error");
    });
  };

  rearragePositionAndGetUpdateQuery = (
    sourceIndex: any,
    destinationIndex: any
  ) => {
    let { topics } = this.state;
    let updateQueries = [];
    let temp = [...topics];

    if (sourceIndex < destinationIndex) {
      let sourceTopic = { ...temp[sourceIndex] };
      let destinationTopic = { ...temp[destinationIndex] };
      for (let index = sourceIndex; index < destinationIndex; index++) {
        let currentPosition = { ...temp[index] }.position;
        temp[index] = { ...temp[index + 1] };
        temp[index].position = currentPosition;

        let updateQuery = {
          updateOne: {
            filter: {
              _id: temp[index]._id
            },
            update: { $set: { position: temp[index].position } }
          }
        };
        updateQueries.push(updateQuery);
      }
      temp[destinationIndex] = sourceTopic;
      temp[destinationIndex].position = destinationTopic.position;
      updateQueries.push({
        updateOne: {
          filter: {
            _id: temp[destinationIndex]._id
          },
          update: { $set: { position: temp[destinationIndex].position } }
        }
      });
    } else {
      let sourceTopic = { ...temp[sourceIndex] };
      let destinationTopic = { ...temp[destinationIndex] };
      for (let index = sourceIndex; destinationIndex < index; index--) {
        let currentPosition = { ...temp[index] }.position;
        temp[index] = { ...temp[index - 1] };
        temp[index].position = currentPosition;

        let updateQuery = {
          updateOne: {
            filter: {
              _id: temp[index]._id
            },
            update: { $set: { position: temp[index].position } }
          }
        };
        updateQueries.push(updateQuery);
      }
      temp[destinationIndex] = sourceTopic;
      temp[destinationIndex].position = destinationTopic.position;
      updateQueries.push({
        updateOne: {
          filter: {
            _id: temp[destinationIndex]._id
          },
          update: { $set: { position: temp[destinationIndex].position } }
        }
      });
    }

    return { temp, updateQueries };
  };

  handleCreatedOnSort = () => {
    var createdOnSort = this.props.createdOnSort;

    if (createdOnSort === sortDirection.ASCENDING || createdOnSort === "") {
      createdOnSort = sortDirection.DESCENDING;
    } else {
      createdOnSort = sortDirection.ASCENDING;
    }

    this.props.handleSort("createdOnSort", createdOnSort);
  };

  handleThumbsUpCountSort = () => {
    var thumbsUpCountSort = this.props.thumbsUpCountSort;

    if (
      thumbsUpCountSort === sortDirection.ASCENDING ||
      thumbsUpCountSort === ""
    ) {
      thumbsUpCountSort = sortDirection.DESCENDING;
    } else {
      thumbsUpCountSort = sortDirection.ASCENDING;
    }

    this.props.handleSort("thumbsUpCountSort", thumbsUpCountSort);
  };

  handleSearchVolumeSort = () => {
    var searchVolumeSort = this.props.searchVolumeSort;

    if (
      searchVolumeSort === sortDirection.ASCENDING ||
      searchVolumeSort === ""
    ) {
      searchVolumeSort = sortDirection.DESCENDING;
    } else {
      searchVolumeSort = sortDirection.ASCENDING;
    }

    this.props.handleSort("searchVolumeSort", searchVolumeSort);
  };

  handleDifficultySort = () => {
    var difficultySort = this.props.difficultySort;

    if (difficultySort === sortDirection.ASCENDING || difficultySort === "") {
      difficultySort = sortDirection.DESCENDING;
    } else {
      difficultySort = sortDirection.ASCENDING;
    }

    this.props.handleSort("difficultySort", difficultySort);
  };

  handleDomainAuthoritySort = () => {
    var domainAuthoritySort = this.props.domainAuthoritySort;

    if (
      domainAuthoritySort === sortDirection.ASCENDING ||
      domainAuthoritySort === ""
    ) {
      domainAuthoritySort = sortDirection.DESCENDING;
    } else {
      domainAuthoritySort = sortDirection.ASCENDING;
    }

    this.props.handleSort("domainAuthoritySort", domainAuthoritySort);
  };

  sortByFunnelStage = () => {
    let funnelStageSort = this.props.funnelStageSort;

    if (funnelStageSort === sortDirection.ASCENDING || funnelStageSort === "") {
      funnelStageSort = sortDirection.DESCENDING;
    } else {
      funnelStageSort = sortDirection.ASCENDING;
    }

    this.props.handleSort("funnelStageSort", funnelStageSort);
  };

  sortByConversionLikelihood = () => {
    let conversionLikelihoodSort = this.props.conversionLikelihoodSort;

    if (
      conversionLikelihoodSort === sortDirection.ASCENDING ||
      conversionLikelihoodSort === ""
    ) {
      conversionLikelihoodSort = sortDirection.DESCENDING;
    } else {
      conversionLikelihoodSort = sortDirection.ASCENDING;
    }

    this.props.handleSort("conversionLikelihoodSort", conversionLikelihoodSort);
  };

  sortByIntent = () => {
    let searchIntentSort = this.props.searchIntentSort;

    if (
      searchIntentSort === sortDirection.ASCENDING ||
      searchIntentSort === ""
    ) {
      searchIntentSort = sortDirection.DESCENDING;
    } else {
      searchIntentSort = sortDirection.ASCENDING;
    }

    this.props.handleSort("searchIntentSort", searchIntentSort);
  };

  sortByFormat = () => {
    let topFormatsSort = this.props.topFormatsSort;

    if (topFormatsSort === sortDirection.ASCENDING || topFormatsSort === "") {
      topFormatsSort = sortDirection.DESCENDING;
    } else {
      topFormatsSort = sortDirection.ASCENDING;
    }

    this.props.handleSort("topFormatsSort", topFormatsSort);
  };

  sortByColumn = (customSortByField: any, customSortDirection: any) => {
    customSortDirection = customSortDirection
      ? customSortDirection === sortDirection.ASCENDING
        ? sortDirection.DESCENDING
        : sortDirection.ASCENDING
      : sortDirection.DESCENDING;

    this.props.customFieldSort(customSortByField, customSortDirection);
  };

  render() {
    let {
      classes,

      page,

      rowsPerPage,

      totalTopics,

      difficultySort,

      createdOnSort,

      thumbsUpCountSort,

      searchVolumeSort,

      domainAuthoritySort,

      searchIntentSort,

      funnelStageSort,

      conversionLikelihoodSort,

      topFormatsSort,

      customSortDirection,

      customSortByField,

      topicDetails
    } = this.props;

    let { topics, customFields } = this.state;
    return (
      <div>
        <StyledPagination
          count={totalTopics}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.props.handleChangePage}
          onRowsPerPageChange={this.props.handleChangeRowsPerPage}
        />

        <div className={classes.tableViewContainer}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />

                  <TableCell>Idea</TableCell>

                  <TableCell>
                    <TableSortLabel
                      direction={
                        createdOnSort ? createdOnSort : sortDirection.DESCENDING
                      }
                      onClick={this.handleCreatedOnSort}
                    >
                      Idea Suggested On
                    </TableSortLabel>
                  </TableCell>

                  <TableCell>Keyword</TableCell>

                  <TableCell className={classes.headerStyleWithNoWrap}>
                    Created By
                  </TableCell>

                  <TableCell className={classes.headerStyleWithNoWrap}>
                    Owner
                  </TableCell>

                  <TableCell>Label</TableCell>

                  <TableCell>
                    <TableSortLabel
                      direction={
                        thumbsUpCountSort
                          ? thumbsUpCountSort
                          : sortDirection.DESCENDING
                      }
                      onClick={this.handleThumbsUpCountSort}
                    >
                      Upvotes
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.headerStyleWithNoWrap}>
                    <TableSortLabel
                      direction={
                        searchVolumeSort
                          ? searchVolumeSort
                          : sortDirection.DESCENDING
                      }
                      onClick={this.handleSearchVolumeSort}
                    >
                      Search Volume
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.headerStyleWithNoWrap}>
                    <TableSortLabel
                      direction={
                        difficultySort
                          ? difficultySort
                          : sortDirection.DESCENDING
                      }
                      onClick={this.handleDifficultySort}
                    >
                      SEO Difficulty
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.headerStyleWithNoWrap}>
                    Top Competing Page
                  </TableCell>

                  <TableCell className={classes.headerStyleWithNoWrap}>
                    <TableSortLabel
                      direction={
                        domainAuthoritySort
                          ? domainAuthoritySort
                          : sortDirection.DESCENDING
                      }
                      onClick={this.handleDomainAuthoritySort}
                    >
                      Domain Authority
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.headerStyleWithNoWrap}>
                    <TableSortLabel
                      direction={funnelStageSort || sortDirection.DESCENDING}
                      onClick={this.sortByFunnelStage}
                    >
                      Funnel Stage
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.headerStyleWithNoWrap}>
                    <TableSortLabel
                      direction={
                        conversionLikelihoodSort || sortDirection.DESCENDING
                      }
                      onClick={this.sortByConversionLikelihood}
                    >
                      Conversion Likelihood
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.headerStyleWithNoWrap}>
                    <TableSortLabel
                      direction={searchIntentSort || sortDirection.DESCENDING}
                      onClick={this.sortByIntent}
                    >
                      Search Intent
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.headerStyleWithNoWrap}>
                    <TableSortLabel
                      direction={topFormatsSort || sortDirection.DESCENDING}
                      onClick={this.sortByFormat}
                    >
                      Top Formats
                    </TableSortLabel>
                  </TableCell>
                  {customFields.map((customField: any) => {
                    return (
                      <TableCell className={classes.headerStyleWithNoWrap}>
                        {customField.type === customFieldDataType.NUMBER ||
                        customField.type === customFieldDataType.DATE ? (
                          <TableSortLabel
                            active={customSortByField === customField.name}
                            direction={
                              customSortDirection
                                ? customSortDirection
                                : sortDirection.DESCENDING
                            }
                            onClick={() =>
                              this.sortByColumn(
                                customField.name,
                                customSortDirection
                              )
                            }
                          >
                            {customField.name}
                          </TableSortLabel>
                        ) : (
                          `${customField.name}`
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(droppableProvided: any) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {topics.map((topic: any, index: any) => {
                        return (
                          <Draggable
                            key={topic._id}
                            draggableId={topic._id}
                            index={index}
                          >
                            {(draggableProvided: any, snapshot: any) => {
                              return (
                                <TableRow
                                  key={index}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  style={{
                                    ...draggableProvided.draggableProps.style,
                                    background: snapshot.isDragging
                                      ? "rgba(245,245,245, 0.75)"
                                      : "none"
                                  }}
                                >
                                  <TableCell>
                                    <div {...draggableProvided.dragHandleProps}>
                                      <ReorderIcon
                                        fontSize="small"
                                        style={{
                                          paddingTop: 5,
                                          color: colors.fontSecondary
                                        }}
                                      />
                                    </div>
                                  </TableCell>

                                  <TableCell>
                                    <div className={classes.titleCell}>
                                      {" "}
                                      {topic.title}
                                    </div>
                                  </TableCell>

                                  <TableCell className={classes.dateCell}>
                                    {formatForDisplayDateTime(topic.createdOn)}
                                  </TableCell>

                                  <TableCell className={classes.dateCell}>
                                    {topic.keyword ? topic.keyword : "-"}
                                  </TableCell>

                                  <TableCell>
                                    {topicDetails[topic._id]?.suggestedBy
                                      .name ||
                                      topicDetails[topic._id]?.suggestedBy
                                        .email}
                                  </TableCell>

                                  <TableCell>
                                    {topicDetails[topic._id]?.author?.name ||
                                      topicDetails[topic._id]?.author?.email ||
                                      "-"}
                                  </TableCell>

                                  <TableCell>
                                    {topic.labels && topic.labels.length > 0
                                      ? topic.labels.map(
                                          (label: any, key: any) => {
                                            return (
                                              <StyledChip
                                                variant="gray"
                                                key={key}
                                                label={label}
                                              />
                                            );
                                          }
                                        )
                                      : "-"}
                                  </TableCell>

                                  <TableCell>
                                    {topicDetails[topic._id]?.thumbsUpCount ||
                                      "-"}
                                  </TableCell>

                                  <TableCell>
                                    {topic.keywordSearchResult?.result &&
                                    topic.keywordSearchResult.result
                                      ?.searchVolume
                                      ? this.formatNumber(
                                          topic.keywordSearchResult.result
                                            .searchVolume
                                        )
                                      : "-"}
                                  </TableCell>

                                  <TableCell>
                                    {topic.keywordSearchResult?.result &&
                                    topic.keywordSearchResult.result
                                      ?.keywordDifficulty
                                      ? topic.keywordSearchResult.result.keywordDifficulty.toFixed(
                                          0
                                        )
                                      : "-"}
                                  </TableCell>

                                  <TableCell>
                                    {topic.keywordSearchResult?.result &&
                                    topic.keywordSearchResult.result
                                      ?.organicPosts &&
                                    topic.keywordSearchResult.result
                                      ?.organicPosts.length > 0 ? (
                                      <a
                                        href={
                                          topic.keywordSearchResult.result
                                            .organicPosts[0].url
                                        }
                                        target="_blank"
                                        className={classes.link}
                                        rel="noreferrer"
                                      >
                                        {
                                          topic.keywordSearchResult.result
                                            .organicPosts[0].url
                                        }
                                      </a>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>

                                  <TableCell>
                                    {topic.keywordSearchResult?.result &&
                                    topic.keywordSearchResult.result
                                      ?.domainAuthority
                                      ? topic.keywordSearchResult.result
                                          .domainAuthority
                                      : "-"}
                                  </TableCell>

                                  <TableCell>
                                    {topic?.funnelStage || "-"}
                                  </TableCell>

                                  <TableCell>
                                    {topic?.conversionLikelihood || "-"}
                                  </TableCell>

                                  <TableCell>
                                    {topic?.searchIntent || "-"}
                                  </TableCell>

                                  <TableCell>
                                    {topic?.topFormats || "-"}
                                  </TableCell>
                                  {customFields.map((customField: any) => {
                                    let projectFieldIndex =
                                      topic?.projectFields?.findIndex(
                                        (projectField: any) =>
                                          projectField.name === customField.name
                                      );

                                    return (
                                      <TableCell className={classes.dateCell}>
                                        {projectFieldIndex !== undefined &&
                                        topic?.projectFields[projectFieldIndex]
                                          ? customField.type ===
                                            customFieldDataType.DATE
                                            ? formatForDisplayDateTime(
                                                topic.projectFields[
                                                  projectFieldIndex
                                                ].value
                                              )
                                            : `${topic.projectFields[projectFieldIndex].value}`
                                          : "-"}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TopicsTableView);
