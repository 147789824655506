// @ts-nocheck
import { Container, Divider, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { Component, Suspense } from "react";
import { notify } from "../Components/CustomNotifications";

import { withRouter } from "react-router-dom";

import Loading from "../Components/Loading";
import API from "../Services/Api";
import styles from "./styles/SettingsStyles";
import "firebase/auth";

import TeamManagement from "../Components/TeamManagement";

import Notifications from "../Components/Notifications";
import qs from "query-string";
import { clientPlanTypes, pageAccess } from "../Utils/Types";
import PageAccessContext from "../Utils/PageAccessContext";

import { Helmet } from "react-helmet";
import { isAccessDisabled } from "../Utils/UserUtils";

import UpgradePlanDialog from "../design/components/UpgradePlanDialog";
import { getPublicationPlan } from "../Utils/AccessUtils";
import PublicationSettings from "../Components/PublicationSettings";

const api = API.create();

const Page404 = React.lazy(() => import("./Page404"));
class Settings extends Component {
  static contextType = PageAccessContext;
  notificationsRef: any;
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      publication: null,
      publicationId: props.match.params.id ? props.match.params.id : "",
      error: false,
      errorMessage: "",
      haveAccessToEdit: false,
      showUpgradeDialog: false
    };

    this.notificationsRef = React.createRef();
  }

  componentDidMount() {
    let qsParse = qs.parse(this.props.location.search);
    if (qsParse.message && qsParse.type) {
      notify.show(qsParse.message, qsParse.type);
    }
    this.load();
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(
        {
          loading: true,
          publication: null,

          publicationId: this.props.match.params.id
            ? this.props.match.params.id
            : "",
          error: false,
          errorMessage: "",
          haveAccessToEdit: false
        },
        this.load
      );
    }
  }

  load = () => {
    let { publicationId } = this.state;
    api.getPublicationById(publicationId, (res: any) => {
      if (res.status === 200) {
        let clientPlan = getPublicationPlan(this.context, publicationId);
        if (clientPlan === clientPlanTypes.FREE) {
          this.setState({
            showUpgradeDialog: true,
            error: true,
            errorMessage: "Upgrade to a higher plan to access admin settings"
          });
        }
        let publication = res.data.publication;

        this.setState({
          loading: false,
          publication: publication,
          haveAccessToEdit: !isAccessDisabled(
            publicationId,
            this.context,
            pageAccess.EDIT_SETTINGS
          )
        });
        setTimeout(() => {
          if (
            this.props.location.hash === "#notifications" &&
            this.notificationsRef
          ) {
            this.notificationsRef.current.scrollIntoView({
              block: "start",
              inline: "center",
              behavior: "smooth"
            });
          }
        }, 300);
      } else {
        this.setState({
          error: true,
          errorMessage: res.data
        });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const {
      loading,

      error,

      errorMessage,

      publication,

      haveAccessToEdit,

      showUpgradeDialog
    } = this.state;

    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage={errorMessage} />

          <UpgradePlanDialog
            open={showUpgradeDialog}
            body={"Upgrade to a higher plan to access admin settings"}
          />
        </Suspense>
      );
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <div className={classes.container}>
        <Helmet>
          <title>
            Admin Settings {publication && "- " + publication.name} | Letterdrop
          </title>
        </Helmet>

        <Container>
          <Typography variant="h600" paragraph style={{ paddingTop: 24 }}>
            Admin Settings
          </Typography>

          <div className={classes.innerContainer}>
            <Grid container direction="row" justify="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
              >
                <TeamManagement publication={publication} />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid
                item
                xs={12}
                style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
              >
                <PublicationSettings
                  publication={publication}
                  fromSettings={true}
                />

                <Divider className={classes.divider} />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
                ref={this.notificationsRef}
              >
                <Notifications publication={publication} />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Settings));
