// @ts-nocheck
var firebaseConfig = {
  apiKey: "AIzaSyDYn53-94yFP5bkrXzwepNS5u5pmKTfewM",
  authDomain: "login.letterdrop.com",
  databaseURL: "https://zmtvapp.firebaseio.com",
  projectId: "zmtvapp",
  storageBucket: "cdn.letterdrop.co",
  messagingSenderId: "657739975143",
  appId: "1:657739975143:web:e1b6c34d8574a53b449542"
};

export default firebaseConfig;