// @ts-nocheck
import { Grid, Container, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import StyledButton from "../design/components/StyledButton";

import StyledInput from "../design/components/StyledInput";
import API from "../Services/Api";
import { notify } from "./CustomNotifications";
import styles from "./styles/EmailDetailsStyle";

const api = API.create();

class EmailDetails extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      fromName: props.emailDetails ? props.emailDetails.fromName : "",
      physicalAddress: props.emailDetails
        ? props.emailDetails.physicalAddress
        : "",
      copyright: props.emailDetails ? props.emailDetails.copyright : "",
      emailFooter: props.emailDetails ? props.emailDetails.emailFooter : "",
      unsaved: []
    };
    let savedCopy = { ...this.state };

    this.state["savedCopy"] = savedCopy;
  }

  handleChange = (event: any) => {
    let { savedCopy, unsaved } = this.state;
    let { name, value } = event.target;

    if (savedCopy[name] !== undefined) {
      if (savedCopy[name] !== value) {
        unsaved[name] = value;
      } else if (savedCopy[name] === value) {
        delete unsaved[name];
      }
    }

    this.setState({ [name]: value, unsaved }, () => {
      if (name === "frequency" || name === "category") {
        this.save();
      }
    });
  };

  save = () => {
    let id = this.props.id ? this.props.id : "-1";

    let { fromName, physicalAddress, copyright, emailFooter } = this.state;

    if (!fromName) {
      notify.show("Enter the name to send emails from", "error");
      return;
    }

    if (!physicalAddress) {
      notify.show("Enter a physical address to comply with spam laws", "error");
      return;
    }

    if (!copyright) {
      notify.show("Enter who owns copyright to your work", "error");
      return;
    }

    api.saveEmailDetails(
      id,
      fromName,
      physicalAddress,
      copyright,
      emailFooter,
      (res: any) => {
        if (res.status === 200) {
          let savedCopy = { ...this.state };
          this.setState({
            savedCopy,
            unsaved: []
          });
        }
      }
    );
  };

  saveEmailDetailField = (object: any) => {
    let id = this.props.id ? this.props.id : "-1";

    let value = this.state[object];
    api.saveEmailDetailsField(id, object, value, (res: any) => {
      if (res.status === 200) {
        let { object, value } = res.data;

        let { savedCopy, unsaved } = this.state;
        delete unsaved[object];
        savedCopy[object] = value;
        this.setState({ [object]: savedCopy[object], unsaved });
      }
    });
  };

  generateSaveButton = (key: any) => {
    let { classes } = this.props;
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        container
        justifyContent="flex-end"
        spacing={1}
        direction="row"
        className={classes.saveGrid}
      >
        <Grid item>
          <StyledButton onClick={() => this.saveEmailDetailField(key)}>
            Save
          </StyledButton>
        </Grid>

        <Grid item>
          <StyledButton
            variant="tertiary"
            onClick={() => this.revertKeyChange(key)}
          >
            Cancel
          </StyledButton>
        </Grid>
      </Grid>
    );
  };

  revertKeyChange = (key: any) => {
    let { savedCopy, unsaved } = this.state;
    delete unsaved[key];
    this.setState({ [key]: savedCopy[key], unsaved });
  };

  render() {
    let { classes } = this.props;

    let { fromName, physicalAddress, copyright, emailFooter, unsaved } =
      this.state;

    return (
      <Container className={classes.container}>
        <Grid container direction="column">
          <Grid item container direction="row" justifyContent="space-between">
            <Typography variant="h400" paragraph>
              Email Footer
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              variant="bodym"
              paragraph
              className={classes.cardSubtitle}
            >
              Share your contact info in your email footers to comply with
              international spam laws
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" className={classes.margin10}>
          <Grid item container xs={12} className={classes.marginTop20}>
            <Grid item xs={12} sm={12} md={3} className={classes.marginAuto}>
              <Typography variant="bodym">Physical office address</Typography>
            </Grid>

            <Grid item xs={11} sm={11} md={5}>
              <StyledInput
                className={classes.input}
                multiline
                rows={2}
                minRows={2}
                size="medium"
                placeholder="Your business address"
                name="physicalAddress"
                value={physicalAddress}
                onChange={this.handleChange}
              />
            </Grid>
            {(unsaved["physicalAddress"] ||
              unsaved["physicalAddress"] === "") &&
              this.generateSaveButton("physicalAddress")}
          </Grid>
        </Grid>

        <Grid container direction="row" className={classes.margin10}>
          <Grid item container xs={12} className={classes.marginTop20}>
            <Grid item xs={12} sm={12} md={3} className={classes.marginAuto}>
              <Typography variant="bodym" className={classes.marginTop12}>
                Copyright Owner
              </Typography>
            </Grid>

            <Grid item xs={11} sm={11} md={5}>
              <StyledInput
                className={classes.input}
                size="medium"
                placeholder="Piedpiper"
                name="copyright"
                value={copyright}
                onChange={this.handleChange}
              />
            </Grid>
            {(unsaved["copyright"] || unsaved["copyright"] === "") &&
              this.generateSaveButton("copyright")}
          </Grid>
        </Grid>

        <Grid container direction="row" className={classes.margin10}>
          <Grid item container xs={12} className={classes.marginTop20}>
            <Grid item xs={12} sm={12} md={3} className={classes.marginAuto}>
              <Typography variant="bodym" className={classes.marginTop12}>
                Email Footer
              </Typography>
            </Grid>

            <Grid item xs={11} sm={11} md={5}>
              <StyledInput
                className={classes.input}
                multiline
                minRows={2}
                rows={2}
                size="medium"
                placeholder="Email footer"
                name="emailFooter"
                value={emailFooter}
                onChange={this.handleChange}
              />
            </Grid>
            {(unsaved["emailFooter"] || unsaved["emailFooter"] === "") &&
              this.generateSaveButton("emailFooter")}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles)(EmailDetails);
