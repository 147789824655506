// @ts-nocheck
import colors from "../../Themes/Colors";
import { space } from "../../Config/theme";

const styles = (theme: any) => ({
  emailBannerContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20
  },

  tagsText: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: space.MEDIUM,
    color: colors.fontSecondary,
    fontSize: 14
  },

  section: {
    marginBottom: 50,
    flexDirection: "column",
    display: "flex"
  },

  sectionTitle: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: space.SMALL
  },

  divider: {
    marginTop: space.LARGE
  },

  inputField: {
    width: "100%"
  },

  newListButtonContainer: {
    display: "flex"
  },

  createNewList: {
    marginLeft: 5
  },

  subCountCaption: {
    color: colors.fontSecondary,
    display: "block"
  },

  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    marginTop: 0,
    width: "100%",
    color: colors.fontPrimary,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontPrimary
    }
  },

  previewButtonContainer: {
    marginTop: space.SMALL,
    marginBottom: space.SMALL
  },

  marginBottom20: {
    marginBottom: 20
  }
});
export default styles;
