// @ts-nocheck
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles/ImagePickerStyle";

import UnsplashPicker from "./UnsplashPicker";

import Uploader from "./Uploader";
import { withStyles } from "@mui/styles";
import { Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StyledTab from "../design/components/StyledTab";

import PostPhotoPicker from "./PostPhotoPicker";

const uploadType = {
  UNSPLASH: "unsplash",
  UPLOAD: "upload",
  FROM_POST: "from post"
};
function ImagePicker({
  onImageSelect,
  onImageUpload,
  onCloseButtonClick,
  postPictures,
  imageAspectRatio,
  showUnsplashPicker,
  forInstagram,
  unsplashRef,
  allowMp4Video = false,
  uploading = false,
  isCoverPhoto = false,
  convertToWebp,
  allowDocuments = false,
  forLinkedIn = false,
  classes
}: any) {
  const [value, setValue] = React.useState(
    showUnsplashPicker && !allowDocuments
      ? uploadType.UNSPLASH
      : uploadType.UPLOAD
  );
  const getTabs = () => {
    let tabs = [];
    if (showUnsplashPicker) {
      tabs.push({ label: "Gallery", value: uploadType.UNSPLASH });
    }
    tabs.push({ label: "Upload", value: uploadType.UPLOAD });
    if (postPictures.length) {
      tabs.push({ label: "Photos from post", value: uploadType.FROM_POST });
    }
    return tabs;
  };

  return (
    <Paper className={classes.root}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div className={classes.header}>
          <Grid item xs={10}>
            <StyledTab
              value={value}
              exposure="low"
              tabs={getTabs()}
              onChange={(event: any, newValue: any) => {
                setValue(newValue);
              }}
            />
          </Grid>

          <Grid item xs={2} className={classes.gridRTL}>
            {onCloseButtonClick && (
              <IconButton
                className={classes.closeButtonContainer}
                onClick={onCloseButtonClick}
                aria-label="close"
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </Grid>
        </div>

        <div style={{ flexGrow: 1, padding: 24, paddingTop: 0 }}>
          {value === uploadType.UNSPLASH && (
            <UnsplashPicker
              ref={unsplashRef}
              onImageSelect={onImageSelect}
              convertToWebp={convertToWebp}
            />
          )}
          {value === uploadType.UPLOAD && (
            <Uploader
              onImageUpload={onImageUpload}
              imageAspectRatio={imageAspectRatio}
              forInstagram={forInstagram}
              allowMp4Video={allowMp4Video}
              uploading={uploading}
              isCoverPhoto={isCoverPhoto}
              convertToWebp={convertToWebp}
              allowDocuments={allowDocuments}
              forLinkedIn={forLinkedIn}
            />
          )}
          {value === uploadType.FROM_POST && (
            <PostPhotoPicker
              onImageSelect={onImageSelect}
              postPictures={postPictures}
            />
          )}
        </div>
      </div>
    </Paper>
  );
}

ImagePicker.propTypes = {
  onImageSelect: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  postPictures: PropTypes.array
};

export default withStyles(styles)(ImagePicker);
