// @ts-nocheck
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  container: {
    padding: 0
  },

  cardSubtitle: {
    color: colors.fontSecondary
  },

  switch: {
    margin: "auto",
    textAlign: "center"
  }
});
export default styles;
