// @ts-nocheck
import firebase from "firebase/app";
import PropTypes from "prop-types";
import React from "react";

import { Redirect, Route } from "react-router-dom";

const CustomRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      render={(props: any) => {
        let { isProtected, path } = rest;

        if (path === "/") {
          return <Redirect to={"/login"} />;
        }

        if (path === "/embedsubscribe/:id" || path === "/embedposts/:id") {
          window.location.href = `${process.env.REACT_APP_HTTP_PROTOCOL}${process.env.REACT_APP_VERCEL_SERVER_URL}${props.location.pathname}${props.location.search}`;
          return;
        }

        if (!isProtected) {
          return <Component {...props} />;
        }

        if (firebase.auth().currentUser !== null) {
          return <Component {...props} />;
        }

        return <Redirect to={"/login?next_url=" + window.location.href} />;
      }}
      {...rest}
    />
  );
};

CustomRoute.propTypes = {
  isProtected: PropTypes.bool
};

CustomRoute.defaultProps = {
  isProtected: false
};

export default CustomRoute;
