import { createStyles } from "@mui/styles";
import Colors from "../../Themes/Colors";
import { grey } from "@mui/material/colors";
import { Theme } from "@mui/material";

const styles = (theme: Theme) =>
  createStyles({
    parentContainer: {
      backgroundColor: Colors.backgroundColor,
      height: "100%"
    },

    container: {
      padding: 0
    },

    marginTop10: {
      marginTop: 10
    },

    marginBottom30: {
      marginBottom: 30
    },

    width100: {
      width: "100%"
    },

    padding20: {
      padding: "10px px"
    },

    headerStyle: {
      fontWeight: "bold",
      fontSize: 20
    },

    sectionTitle: {
      fontSize: 18,
      color: Colors.fontSecondary,
      marginTop: 18
    },

    cardSubtitle: {
      color: Colors.fontSecondary
    },

    input: {
      width: "100%",
      backgroundColor: Colors.inputBackground,
      border: "none",
      color: Colors.fontInput,
      "&:focus": {
        backgroundColor: Colors.inputFocusBackground,
        border: "none",
        color: Colors.fontInput
      }
    },

    apiKey: {
      backgroundColor: grey[100],
      color: Colors.fontSecondary,
      borderRadius: 5,
      padding: 10,
      fontFamily: "Inconsolata",
      width: "100%"
    },

    marginTop20: {
      marginTop: 20
    },

    actionStyles: {
      margin: "0 15px 15px"
    },

    paidOnly: {
      color: Colors.primary,
      marginRight: 25,
      alignItems: "center",
      display: "flex"
    },

    flexDirectionRow: {
      display: "flex",
      flexDirection: "row"
    },

    justifyContent: {},

    embedCopyContainer: {}
  });

export default styles;
