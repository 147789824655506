// @ts-nocheck
import { space } from "../../Config/theme";
import { designColors } from "../../Themes/Colors";

const styles = (theme: any) => ({
  hover: {
    cursor: "pointer",
    padding: `${space.XS} ${space.XS}`,
    border: "1px solid transparent",
    "&:hover": {
      border: `1px solid ${designColors.grayScale[40]}`,
      borderRadius: 6
    },
    "&:focus": {
      outline: `1px solid ${designColors.primary[100]}`,
      borderRadius: 6
    }
  },

  authorName: {
    wordBreak: "break-word",
    paddingLeft: 8,
    margin: "auto 0px"
  },

  eventPopover: {
    padding: "12px"
  },

  linkInput: {
    flex: 1,
    marginRight: space.XS,
    wordWrap: "break-word",
    overflow: "hidden"
  },

  linkContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  divider: {
    margin: `${space.XS} 0px ${space.XS} 0px`
  }
});

export default styles;
