// @ts-nocheck
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ImageIcon from "@mui/icons-material/Image";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  InputAdornment,
  CircularProgress,
  InputBase,
  Popover,
  FormHelperText
} from "@mui/material";
import { withStyles } from "@mui/styles";
import "firebase/auth";
import moment from "moment";
import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import TagsInput from "../Components/TagsInput";
import StyledButton from "../design/components/StyledButton";
import StyledInput from "../design/components/StyledInput";
import StyledDialog from "../design/components/StyledDialog";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import StyledSwitch from "../design/components/StyledSwitch";
import { ReactComponent as CalendarGrayIcon } from "../Images/icon20/calendarColorGray40.svg";
import {
  formatForTimeWithTimeZone,
  formatForGoogleThumbnailPreview,
  getTimestampByTimezone
} from "../Utils/TimeUtils";
import {
  getTextFromHtml,
  handleSingular,
  isJSON,
  truncateStringAndAddEllipses,
  validateCustomField
} from "../Utils/UserUtils";
import {
  blogDestinations,
  customFieldDataType,
  CUSTOM_FIELD,
  datePickerDefault,
  postStatus,
  selectedPostFile,
  socialMediaType,
  publicationType,
  postFields
} from "../Utils/Types";
import BlogPostPreviewDialog from "./BlogPostPreviewDialog";
import CustomFlatPicker from "./CustomFlatPicker";
import styles from "./styles/BlogPostReviewSettingsStyle";
import StyledChip from "../design/components/StyledChip";
import colors, { designColors } from "../Themes/Colors";
import Loading from "./Loading";
import CloseIcon from "@mui/icons-material/Close";
import { googleThumbnailPreviewDetails } from "../Containers/Review";
import { isFreePlan } from "../Utils/UserUtils";
import PageAccessContext from "../Utils/PageAccessContext";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import API from "../Services/Api";
import { notify } from "./CustomNotifications";
import { space } from "../Config/theme";
import StyledTab from "../design/components/StyledTab";
import { uploadBlobImage } from "../Utils/Storage";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopy from "@mui/icons-material/FileCopy";
// import { ReactComponent as CheckCircleIcon } from "../Images/check_circle.svg";

const Editor = React.lazy(() => import("../Components/Editor"));
const thumbnailPreviewTypes = {
  SOCIAL: "social",
  GOOGLE: "google"
};
const INVALID_DATE = "Invalid Date";

// Using this format for custom fields component,
// Id is need to scroll to the component if any validation error occurs
export const CUSTOM_FIELD_ID = "custom_field_id_";

const api = API.create();

function BlogPostReviewSettings(props: any, ref: any) {
  let {
    classes,
    post,
    coverImage,
    socialTitle,
    tags,
    publishedOn,
    status,
    autocompleteTags,
    showPostCoverPhoto,
    socialThumbnail,
    postURL,
    onTagsChange,
    updatePublishedOn,
    showPostCoverPhotoToggle,
    openImagePicker,
    onSlugChange,
    onTitleChange,
    onDescriptionChange,
    publication,
    slugWarning,
    slugWarningText,
    isKeywordPresent,
    titleWarning,
    descriptionWarning,
    keyword,
    destination,
    onDestinationChange,
    customFields,
    onCustomFieldChange,
    webflowReferences,
    customFieldReferences,
    previewPostInWebflow,
    isWebflowPreviewLoaderVisible,
    defaultSyntaxHighlightingLanguage,
    setDefaultSyntaxHighlightingLanguage,
    customFieldImageURL,
    customFieldImageName,
    shortenSlug,
    reduceSlugWordCount,
    clientTimezone,
    scrollToSlug,
    slug,
    socialDescription,
    nativeFields
  } = props;

  let [showPreviewDialog, setShowPreviewDialog] = useState(false);
  let [showPreviewMenu, setShowPreviewMenu] = useState(false);
  let [showDatePickerForCustomField, setShowDatePickerForCustomField] =
    useState(false);
  let [thumbnailPreviewType, setThumbnailPreviewType] = useState(
    thumbnailPreviewTypes.SOCIAL
  );
  let [eventElement, setEventElement] = useState("null");
  let [imageIndex, setImageIndex] = useState(-1);
  let [showPublishDatePicker, setShowPublishDatePicker] = useState(false);
  let [
    showOverrideDescriptionConfirmation,
    setShowOverrideDescriptionConfirmation
  ] = useState(false);
  let [slugLoading, setSlugLoading] = useState(false);
  let [socialDescriptionLoading, setSocialDescriptionLoading] = useState(false);
  let [prevSlug, setPrevSlug] = useState([]);
  let [prevSocialDescription, setPrevSocialDescription] = useState([]);
  let [webflowFieldNameMap, setWebflowFieldNameMap] = useState({});
  let [internalFieldNameMap, setInternalFieldNameMap] = useState({});
  let [showOverrideSlugConfirmation, setShowOverrideSlugConfirmation] =
    useState(false);
  let [aiGeneratedSlug, setAiGeneratedSlug] = useState("");
  let [aiGeneratedMetaDescription, setAiGeneratedMetaDescription] =
    useState("");
  let [customFieldsValidationErrors, setCustomFieldsValidationErrors] =
    useState({});

  let context = useContext(PageAccessContext);
  const slugRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (scrollToSlug) {
        slugRef.current?.scrollIntoView({
          block: "center",
          inline: "center",
          behavior: "smooth"
        });
      }
    }, 300);
  }, []); // This is equivalent to componentDidMount so dependency array should be empty

  useEffect(() => {
    let webflowFieldData = [];
    let webflowDetails = getWebflowDetails(
      post.publication.externalSite,
      post.publication.webflow,
      post.destination
    );
    if (webflowDetails && Object.keys(webflowDetails).length !== 0) {
      setWebflowFieldNameMap({});
      let { _id, collectionId, siteId } = webflowDetails;
      let publicationId = post.publication._id;
      api.getCollectionFields(
        publicationId,
        collectionId,
        _id,
        siteId,
        (res: any) => {
          if (res.status === 200) {
            webflowFieldData = res.data;
            if (webflowFieldData) {
              for (let i = 0; i < webflowFieldData.length; i++) {
                let field = webflowFieldData[i];
                setWebflowFieldNameMap((webflowFieldNameMap) => {
                  return {
                    ...webflowFieldNameMap,
                    [field["slug"]]: field["displayName"]
                  };
                });
              }
            }
          }
        }
      );
    }
  }, [post?.destination]);

  useEffect(() => {
    let mappedFields = getWebflowDetails(
      post.publication.externalSite,
      post.publication.webflow,
      post.destination
    )?.fieldsMapping;

    if (mappedFields) {
      setInternalFieldNameMap({});
      Object.keys(mappedFields).forEach((key) => {
        setInternalFieldNameMap((internalFieldNameMap) => {
          return { ...internalFieldNameMap, [mappedFields[key]]: key };
        });
      });
    }
  }, [post?.destination]);

  //To set image selected from picker to custom field
  useEffect(() => {
    if (imageIndex > -1) {
      handleCustomFieldsChange(
        customFieldImageURL,
        imageIndex,
        customFieldImageName
      );
    }
  }, [customFieldImageURL]);

  function handleDestinationChange(blogId: any) {
    let index = post.publication.externalSite.blogs.findIndex(
      (blog: any) => blog.id === blogId
    );

    let { destination, id } = post.publication.externalSite.blogs[index];

    onDestinationChange({
      type: destination,
      id
    });
  }

  function showWebflowPreview(destinationId: any, withoutEmbeds: any) {
    previewPostInWebflow(destinationId, withoutEmbeds);
  }

  function toggleImagePicker(event: any, index: any) {
    setImageIndex(index);
    openImagePicker(event);
    setAiGeneratedSlug("");
  }

  useEffect(() => {
    if (!aiGeneratedSlug) {
      return;
    }

    setShowOverrideSlugConfirmation(true); // Show dialog if meta description is dirty (edited)
  }, [aiGeneratedSlug]); // We need to run this code only when aiGeneratedSlug changes, so dependency array should have only aiGeneratedSlug

  function generateSlug() {
    setSlugLoading(true);
    api.generateSlug(post._id, post.title, keyword, (res: any) => {
      if (res.status === 200) {
        setSlugLoading(false);

        if (!res.data) {
          return;
        }

        setAiGeneratedSlug(res.data);
        return;
      }

      notify.show(
        res.status === 400
          ? res.data
          : "Oops, we were unable to generate your slug. Try again.",
        "error"
      );
    });
  }

  function replaceSlug(newSlug: any) {
    setPrevSlug([...prevSlug, slug]);
    setShowOverrideSlugConfirmation(false);
    setAiGeneratedSlug("");
    onSlugChange(newSlug);
  }

  useEffect(() => {
    if (!aiGeneratedMetaDescription) {
      return;
    }

    // else show confirmation dialog
    setShowOverrideDescriptionConfirmation(true); // Show dialog if meta description is dirty (edited)
  }, [aiGeneratedMetaDescription]); // we need to run this code only when aiGeneratedMetaDescription changes, so dependency array should have only aiGeneratedMetaDescription

  function generateMetaDescription() {
    setSocialDescriptionLoading(true);

    api.generateMetaDescription(
      post._id,
      post.title,
      keyword,
      post.plainText,
      (res: any) => {
        if (res.status === 200) {
          setSocialDescriptionLoading(false);
          if (!res.data) {
            return;
          }

          setAiGeneratedMetaDescription(res.data);
          return;
        }
        setSocialDescriptionLoading(false);
        notify.show(
          res.status === 400
            ? res.data
            : "Oops, we were unable to generate your meta description. Try again.",
          "error"
        );
      }
    );
  }

  function overrideWithAIGeneratedMetaDescription(description: any) {
    setPrevSocialDescription([...prevSocialDescription, socialDescription]);
    onDescriptionChange(description);
    setShowOverrideDescriptionConfirmation(false);
    setAiGeneratedMetaDescription("");
  }

  async function handleCustomFieldsChange(
    value: any,
    index: any,
    fileName: string = ""
  ) {
    if (value && customFields[index]?.type === customFieldDataType.NUMBER) {
      value = Number(value);
    }
    if (value && customFields[index]?.type === customFieldDataType.IMAGE) {
      if (value?.startsWith("blob:") || value?.startsWith("data:")) {
        value = await uploadBlobImage(value, post.publication.domain, fileName);
      }
    }
    customFields[index].value = value;
    onCustomFieldChange(customFields);
  }

  function handleDatePicking(event: any) {
    setShowDatePickerForCustomField(true);
    setEventElement(event.currentTarget);
  }

  function handlePreviewButtonClick(event: any) {
    if (
      // There's just a single webflow destination (by default selected - no dropdown selection shown)
      (post.publication?.externalSite?.blogs?.length === 1 &&
        post.publication?.externalSite?.blogs[0]?.destination ===
          blogDestinations.WEBFLOW) ||
      // The user selected a webflow destination from the dropdown or post destination is webflow type
      post?.destination?.type === blogDestinations.WEBFLOW
    ) {
      setShowPreviewMenu(true);
      setEventElement(event.target);
      // setShowPreviewDialog(true);
    } else {
      setShowPreviewDialog(true);
    }
  }

  React.useImperativeHandle(ref, () => ({
    handlePreviewButtonClick,
    setCustomFieldsValidationErrors
  }));

  function handleCustomListFieldValueChange(newValues: any, index: any) {
    customFields[index].value = newValues;
    onCustomFieldChange(customFields);
  }

  function addCustomListFieldValues(newValue: any, index: any) {
    let values = customFields[index].value || [];
    handleCustomListFieldValueChange([...values, newValue], index);
  }

  function isBlogPostDestinationSelected() {
    // If any one condition is satisfied show blog settings
    // external sites length is less than 1
    // already destination selected
    // post is published already
    return (
      post.publication?.externalSite?.blogs?.length <= 1 ||
      destination?.id ||
      (status === postStatus.PUBLISHED && !post.scheduledOn)
    );
  }

  function getGoogleThumbnailPreviewDescription(
    socialDescription: any,
    publishedOn: any
  ) {
    let descriptionDate = publishedOn || moment();

    let googleThumbnailDescription =
      formatForGoogleThumbnailPreview(descriptionDate) +
      " — " +
      socialDescription;

    return truncateStringAndAddEllipses(
      googleThumbnailDescription,
      googleThumbnailPreviewDetails.DESCRIPTION_CHAR_LIMIT
    );
  }

  function getGoogleFormattedURL(postLink: any) {
    // Replacing the / with right caret (›) in the URL pathname
    try {
      let newURL = new URL(postLink);
      return newURL.origin + newURL.pathname.replaceAll("/", " › ");
    } catch (e) {}
    return "";
  }

  function getEditorValue(value: any) {
    try {
      if (value) {
        return JSON.parse(value);
      }
    } catch (error) {}
    return value;
  }

  // Value can be delta or html
  function getWordCount(value: string) {
    try {
      if (isJSON(value)) {
        value = JSON.parse(value);
      }

      if (typeof value === "object") {
        const text = value.ops
          ?.map((op) => op.insert)
          .join("")
          .trim();
        return text?.length > 0 ? text.split(/\s+/)?.filter(Boolean).length : 0;
      }

      let text = getTextFromHtml(value);
      return text?.length > 0 ? text.split(/\s+/)?.filter(Boolean).length : 0;
    } catch (error) {}
    return 0;
  }

  function showDatePickerForPublishedDate(event: any) {
    setShowPublishDatePicker(true);
    setEventElement(event.target);
  }

  function onPublishDateChanged(date: any) {
    setShowPublishDatePicker(false);
    //Handle invalid date
    if (date.toString() === INVALID_DATE) {
      return;
    }
    updatePublishedOn(date);
  }

  function onPreviewMenuClick(withoutEmbeds = false) {
    setShowPreviewMenu(false);
    showWebflowPreview(
      post?.destination?.id || post.publication?.externalSite?.blogs[0]?.id,
      withoutEmbeds
    );
  }

  function customFieldDetails(
    name: string,
    required: boolean = false,
    helpText: string = ""
  ) {
    return (
      <>
        <Typography className={classes.postTitle}>
          {name}
          {required && (
            <span style={{ color: designColors.alert[50] }}>{` *`}</span>
          )}
        </Typography>
        {helpText && (
          <Typography
            variant="bodym"
            paragraph
            style={{ color: colors.subtitle }}
          >
            {helpText}
          </Typography>
        )}
      </>
    );
  }

  function onBlurCustomField(customField: any) {
    if (!customField) {
      return;
    }

    let errorMessage = validateCustomField(
      customField.type,
      customField.value,
      customField.required,
      customField.minimumValue,
      customField.maximumValue
    );

    setCustomFieldsValidationErrors({
      ...customFieldsValidationErrors,
      [customField.name]: errorMessage
    });

    return !Boolean(errorMessage);
  }

  function getHelperTextProps(customField: any) {
    return {
      helperText: customFieldsValidationErrors[customField?.name] || "",
      error: Boolean(customFieldsValidationErrors[customField?.name]),
      onBlur: () => onBlurCustomField(customField)
    };
  }

  function getFormHelperTextView(name: string) {
    if (!customFieldsValidationErrors[name]) {
      return "";
    }

    return (
      <FormHelperText
        style={{
          marginLeft: 14,
          color: colors.helperText
        }}
      >
        {customFieldsValidationErrors[name]}
      </FormHelperText>
    );
  }

  function getTagsElement() {
    return customFieldReferences?.tags ? (
      <Autocomplete
        multiple
        options={customFieldReferences?.tags}
        getOptionLabel={(option) =>
          option.value?.name || option?.name || option
        }
        size="medium"
        value={tags}
        onChange={(event, newValues) => {
          onTagsChange(newValues.map((option) => option?.slug || option));
        }}
        renderInput={(params) => (
          <StyledInput
            {...params}
            variant="outlined"
            {...getHelperTextProps(
              nativeFields.find((field) => field.id === postFields.tags.slug)
            )}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((tag, index) =>
            customFieldReferences?.tags.map(
              (option: any) =>
                tag === option.slug && (
                  <StyledChip
                    variant="gray"
                    label={option.value?.name || option?.name}
                    {...getTagProps({ index })}
                  />
                )
            )
          )
        }
      />
    ) : (
      <TagsInput
        value={tags}
        suggestions={autocompleteTags}
        placeholder="Add Tags"
        addTag={(event: any, value: any) => {
          onTagsChange([...tags, value]);
        }}
        inputProps={getHelperTextProps(
          nativeFields.find((field) => field.id === postFields.tags.slug)
        )}
        size="medium"
        handleChange={(event: any, newValues: any) => {
          onTagsChange(newValues);
        }}
      />
    );
  }

  return (
    <Grid container ref={ref}>
      {post.publication?.externalSite?.blogs?.length > 1 &&
        !(
          status === postStatus.PUBLISHED &&
          !post.scheduledOn &&
          !destination?.id
        ) && ( //Hide when post is published but dont have destination
          <div className={classes.section}>
            <Typography className={classes.sectionTitle}>
              Destination
            </Typography>

            <Typography className={classes.tagsText}>
              Where do you want to publish this blog post?
            </Typography>

            <div className={classes.inputField}>
              <FormControl
                variant="outlined"
                size="large"
                fullWidth
                className={classes.input}
              >
                <StyledSelect
                  value={destination?.id || ""}
                  fullWidth
                  size="xxl"
                  disabled={
                    (status === postStatus.PUBLISHED && !post.scheduledOn) ||
                    isWebflowPreviewLoaderVisible
                  }
                  className={classes.input}
                  onChange={(event: any) =>
                    handleDestinationChange(event.target.value)
                  }
                >
                  {post.publication.externalSite.blogs.map(
                    (blog: any, index: any) => {
                      return (
                        <StyledMenuItem
                          key={blog.id}
                          name={selectedPostFile.EMAIL_BANNER}
                          value={blog.id}
                          style={{ display: "block" }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: 650
                            }}
                          >
                            {blog.name || ""}
                          </div>

                          <div style={{ textTransform: "capitalize" }}>
                            <Typography
                              variant="bodys"
                              style={{ color: designColors.grayScale[60] }}
                            >
                              {blog?.destinationLabel || blog?.destination}
                            </Typography>
                          </div>
                        </StyledMenuItem>
                      );
                    }
                  )}
                </StyledSelect>
              </FormControl>
            </div>
          </div>
        )}
      {isBlogPostDestinationSelected() && (
        <>
          {!isFieldOptional(
            [postFields.tags.slug, postFields.tag.slug],
            post.publication.externalSite,
            post.publication.webflow,
            post.destination
          ) && (
            <div className={classes.section} id={postFields.tags.slug}>
              {customFieldDetails(
                internalFieldNameMap["tag"] &&
                  destination?.type === blogDestinations.WEBFLOW
                  ? webflowFieldNameMap[internalFieldNameMap["tag"]]
                  : "Tags",
                nativeFields.find((field) => field.id === postFields.tags.slug)
                  ?.required || false,
                ""
              )}

              <Grid item className={classes.tagsText}>
                <Typography className={classes.font14}>
                  Add tags to this post to index it on your blog
                </Typography>
              </Grid>

              <div className={classes.inputField}>{getTagsElement()}</div>

              <Divider className={classes.divider} />
            </div>
          )}
          {status === postStatus.PUBLISHED &&
            post.publishedOn &&
            post?.destination?.type !== blogDestinations.HUBSPOT &&
            !isFieldOptional(
              [postFields.publishedOn.slug, postFields.publishedOnString.slug],
              post.publication.externalSite,
              post.publication.webflow,
              post.destination
            ) && (
              <div className={classes.section}>
                <Typography className={classes.sectionTitle}>
                  {internalFieldNameMap["published_on"]
                    ? webflowFieldNameMap[internalFieldNameMap["published_on"]]
                    : "Published On"}
                </Typography>

                <Grid item className={classes.tagsText}>
                  <Typography>Set the date displayed on your post</Typography>
                </Grid>

                <div className={classes.inputField}>
                  <StyledInput
                    fullWidth
                    size="large"
                    readOnly={true}
                    value={getTimestampByTimezone(
                      moment(publishedOn),
                      clientTimezone
                    )}
                    InputProps={{
                      endAdornment: <CalendarGrayIcon />
                    }}
                    onClick={showDatePickerForPublishedDate}
                    inputProps={{
                      placeholder: "Pick a date"
                    }}
                  />

                  <CustomFlatPicker
                    publicationId={publication._id}
                    anchorEl={eventElement}
                    open={showPublishDatePicker}
                    disablePast={false}
                    value={publishedOn}
                    onClose={onPublishDateChanged}
                    type={datePickerDefault.DEADLINE}
                  />
                </div>

                <Divider className={classes.divider} />
              </div>
            )}

          {!isFieldOptional(
            [postFields.coverImage.slug],
            post.publication.externalSite,
            post.publication.webflow,
            post.destination
          ) && (
            <div className={classes.section} id={postFields.coverImage.slug}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                {customFieldDetails(
                  internalFieldNameMap["cover_image"]
                    ? webflowFieldNameMap[internalFieldNameMap["cover_image"]]
                    : "Cover Photo",
                  nativeFields.find(
                    (field) => field.id === postFields.coverImage.slug
                  )?.required || false,
                  ""
                )}

                <FormControlLabel
                  control={<StyledSwitch size="large" fullWidth />}
                  checked={showPostCoverPhoto}
                  onChange={showPostCoverPhotoToggle}
                />
              </div>

              <Typography className={classes.tagsText}>
                Show cover photo at the top of your blog post
              </Typography>

              {showPostCoverPhoto && (
                <div>
                  <Grid item className={classes.coverPhotoContainer}>
                    <div className={classes.coverPhoto}>
                      {coverImage && (
                        <img
                          id="target"
                          alt="Cover"
                          src={coverImage}
                          className={classes.coverPhoto}
                        />
                      )}
                    </div>

                    <div className={classes.uploadOverlayContainer}>
                      <StyledButton
                        name={selectedPostFile.POST_COVER}
                        variant="secondary"
                        size={"medium"}
                        startIcon={<ImageIcon />}
                        onClick={openImagePicker}
                      >
                        Select file
                      </StyledButton>
                    </div>
                  </Grid>
                </div>
              )}
              {getFormHelperTextView(postFields.coverImage.name)}
            </div>
          )}

          <div className={classes.section} ref={slugRef}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.postTitle}>
                {internalFieldNameMap["slug"]
                  ? webflowFieldNameMap[internalFieldNameMap["slug"]]
                  : "Slug"}
              </Typography>

              <div className={classes.urlContainer}>
                <StyledInput
                  fullWidth
                  value={slug}
                  placeholder="not-all-those-who-wander-are-lost"
                  onChange={(event: any) =>
                    onSlugChange(event.target.value, slugLoading)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography style={{ fontSize: 14 }}>
                          {postURL}
                        </Typography>
                      </InputAdornment>
                    )
                  }}
                  onKeyDown={(e: any) => {
                    //when pressed ctrl+z undo generated post
                    if (e.ctrlKey && e.keyCode === 90) {
                      //we take previous slug data and replace the generated post
                      if (prevSlug.length) {
                        let oldValue = prevSlug.pop();
                        setPrevSlug(prevSlug);
                        onSlugChange(oldValue, slugLoading);
                      }
                    }
                  }}
                />
                {slugLoading ? (
                  <CircularProgress
                    style={{ marginLeft: space.LARGE, marginRight: -60 }}
                    size={25}
                  />
                ) : (
                  <Tooltip title="Generate Slug">
                    <IconButton
                      size="large"
                      className={classes.aiIcon}
                      onClick={() => generateSlug()}
                    >
                      <AutoFixHighOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              {slugWarning !== null && (
                <div className={classes.warningContainer}>
                  {slugWarning ? (
                    <ErrorIcon className={classes.warningIcon} />
                  ) : (
                    <CheckCircleIcon className={classes.checkIcon} />
                  )}

                  <Typography
                    className={
                      slugWarning ? classes.warningText : classes.checkText
                    }
                  >
                    {slugWarningText}
                  </Typography>
                </div>
              )}

              <div className={classes.warningContainer}>
                {!reduceSlugWordCount ? (
                  <CheckCircleIcon className={classes.checkIcon} />
                ) : (
                  <ErrorIcon className={classes.warningIcon} />
                )}

                <Typography
                  className={
                    !reduceSlugWordCount
                      ? classes.checkText
                      : classes.warningText
                  }
                >
                  {`Try to keep your slug to five words or less for readability`}
                </Typography>
              </div>

              <div className={classes.warningContainer}>
                {!shortenSlug ? (
                  <CheckCircleIcon className={classes.checkIcon} />
                ) : (
                  <ErrorIcon className={classes.warningIcon} />
                )}

                <Typography
                  className={
                    !shortenSlug ? classes.checkText : classes.warningText
                  }
                >
                  {`Try to keep your slug short so that it doesn’t get cut off`}
                </Typography>
              </div>

              {customFieldDetails(
                internalFieldNameMap["meta_title"]
                  ? webflowFieldNameMap[internalFieldNameMap["meta_title"]]
                  : "Meta Title",
                nativeFields.find((field) => {
                  return field.id === postFields.metaTitle.slug;
                })?.required || false,
                ""
              )}

              <StyledInput
                id={postFields.metaTitle.slug}
                value={socialTitle}
                className={classes.metaTitle}
                fullWidth
                placeholder="Title"
                onChange={onTitleChange}
                {...getHelperTextProps(
                  nativeFields.find((field) => {
                    return field.id === postFields.metaTitle.slug;
                  })
                )}
              />
              {titleWarning && (
                <div className={classes.warningContainer}>
                  <ErrorIcon className={classes.warningIcon} />

                  <Typography className={classes.warningText}>
                    Your Meta Title is too long and will be cut off on Google
                  </Typography>
                </div>
              )}

              <div className={classes.metaDescriptionDiv}>
                {customFieldDetails(
                  internalFieldNameMap["meta_description"]
                    ? webflowFieldNameMap[
                        internalFieldNameMap["meta_description"]
                      ]
                    : "Meta Description",
                  nativeFields.find(
                    (field) => field.id === postFields.metaDescription.slug
                  )?.required || false,
                  ""
                )}

                <div className={classes.urlContainer}>
                  <StyledInput
                    id={postFields.metaDescription.slug}
                    value={socialDescription}
                    fullWidth
                    minRows={3}
                    {...getHelperTextProps(
                      nativeFields.find(
                        (field) => field.id === postFields.metaDescription.slug
                      )
                    )}
                    multiline={true}
                    placeholder="Description"
                    onChange={(event: any) =>
                      onDescriptionChange(event.target.value)
                    }
                    onKeyDown={(e: any) => {
                      //when pressed ctrl+z undo generated post
                      if (e.ctrlKey && e.keyCode === 90) {
                        //we take previous description data and replace the generated post
                        if (prevSocialDescription.length) {
                          let oldValue = prevSocialDescription.pop();
                          setPrevSocialDescription(prevSocialDescription);
                          onDescriptionChange(oldValue);
                        }
                      }
                    }}
                  />
                  {socialDescriptionLoading ? (
                    <CircularProgress
                      style={{ marginLeft: space.LARGE, marginRight: -60 }}
                      size={25}
                    />
                  ) : (
                    <Tooltip title="Generate Meta Description">
                      <IconButton
                        size="large"
                        className={classes.aiIcon}
                        onClick={() => generateMetaDescription()}
                      >
                        <AutoFixHighOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
              {isKeywordPresent !== null && (
                <div className={classes.warningContainer}>
                  {isKeywordPresent ? (
                    <CheckCircleIcon className={classes.checkIcon} />
                  ) : (
                    <ErrorIcon className={classes.warningIcon} />
                  )}

                  <Typography
                    className={
                      isKeywordPresent ? classes.checkText : classes.warningText
                    }
                  >
                    {`Include the primary keyword, "${keyword}", in your
                        description`}
                  </Typography>
                </div>
              )}
              {descriptionWarning && (
                <div className={classes.warningContainer}>
                  <ErrorIcon className={classes.warningIcon} />

                  <Typography className={classes.warningText}>
                    Your Meta Description is too long and will be cut off on
                    Google
                  </Typography>
                </div>
              )}
            </Grid>

            <Divider className={classes.divider} />
          </div>

          <div className={classes.section}>
            {customFieldDetails(
              internalFieldNameMap["thumbnail"]
                ? webflowFieldNameMap[internalFieldNameMap["thumbnail"]]
                : "Thumbnail Preview",
              nativeFields.find(
                (field) => field.id === postFields.thumbnail.slug
              )?.required || false,
              ""
            )}

            <Typography className={classes.tagsText}>
              This is how your post will look like on Google, Twitter, LinkedIn
              and other sites
            </Typography>

            <div className={classes.thumbnailPreviewButton}>
              <StyledTab
                value={thumbnailPreviewType}
                tabs={[
                  {
                    label: "Social",
                    value: thumbnailPreviewTypes.SOCIAL
                  },
                  {
                    label: "Google",
                    value: thumbnailPreviewTypes.GOOGLE
                  }
                ]}
                onChange={(event: any, value: any) =>
                  setThumbnailPreviewType(value)
                }
              />
            </div>

            {thumbnailPreviewType === thumbnailPreviewTypes.SOCIAL && (
              <div
                className={classes.imageBorder}
                id={postFields.thumbnail.slug}
              >
                <Grid item className={classes.coverPhotoContainer}>
                  <div className={classes.coverPhoto}>
                    {(coverImage || socialThumbnail) && (
                      <img
                        id="target"
                        alt="Social Thumbnail"
                        src={socialThumbnail || coverImage}
                        className={classes.coverPhoto}
                      />
                    )}
                  </div>

                  <div className={classes.uploadOverlayContainer}>
                    <StyledButton
                      name={selectedPostFile.SOCIAL_THUMBNAIL}
                      variant="secondary"
                      size={"medium"}
                      startIcon={<ImageIcon />}
                      onClick={openImagePicker}
                    >
                      Choose thumbnail
                    </StyledButton>
                  </div>
                </Grid>

                <Grid item xs={12} className={classes.thumbnailPreviewInfo}>
                  <Typography
                    variant="subtitle1"
                    className={classes.thumbnailPreviewMetaTitleText}
                  >
                    {socialTitle}
                  </Typography>

                  <Typography
                    className={classes.thumbnailPreviewMetaDescriptionText}
                  >
                    {socialDescription}
                  </Typography>

                  <div className={classes.urlContainer}>
                    <Typography className={classes.thumbnailPreviewUrlText}>
                      {postURL + slug}
                    </Typography>
                  </div>
                </Grid>
              </div>
            )}
            {getFormHelperTextView(postFields.thumbnail.name)}
            {thumbnailPreviewType === thumbnailPreviewTypes.GOOGLE && (
              <div className={classes.imageBorder}>
                <div className={classes.googlePreviewDiv}>
                  <Grid item style={{}}>
                    <Typography
                      className={classes.googleTitleText}
                      style={{
                        color: colors.googleThumbnailURLFontColor,
                        fontSize:
                          googleThumbnailPreviewDetails.DESCRIPTION_FONT_SIZE
                      }}
                    >
                      {getGoogleFormattedURL(postURL + slug)}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography className={classes.googleTitleText}>
                      {socialTitle}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography className={classes.googleDescriptionText}>
                      {getGoogleThumbnailPreviewDescription(
                        socialDescription,
                        post.publishedOn
                      )}
                    </Typography>
                  </Grid>
                </div>
              </div>
            )}

            <Divider className={classes.divider} />
          </div>
          {customFields?.length > 0 && (
            <div className={classes.section}>
              <Typography
                className={classes.sectionTitle}
                style={{ marginBottom: 20 }}
              >
                Custom Fields
              </Typography>

              {customFields.map((customField: any, index: any) => {
                //Post can have old custom fields data
                //Check custom field config and show latest fields only

                let customFieldIndex =
                  post.publication?.blogCustomFields?.findIndex(
                    (field: any) => field.name === customField?.name
                  );
                if (customFieldIndex === -1) {
                  return "";
                }

                return (
                  <div
                    className={classes.customFieldSection}
                    id={`${CUSTOM_FIELD_ID}${customField?.name}`}
                  >
                    <Grid item md={12} className={classes.inputField}>
                      {customField?.type === customFieldDataType.BOOLEAN && (
                        <>
                          <FormControlLabel
                            variant="bodyl"
                            control={
                              <StyledSwitch
                                size="large"
                                fullWidth
                                checked={customField?.value || false}
                                onChange={(event: any) => {
                                  handleCustomFieldsChange(
                                    !customField?.value,
                                    index
                                  );
                                }}
                              />
                            }
                            label={
                              <>
                                <Typography
                                  className={classes.postTitle}
                                  style={{ margin: 0 }}
                                >
                                  {customField?.name}
                                  {customField?.required && (
                                    <span
                                      style={{ color: designColors.alert[50] }}
                                    >{` *`}</span>
                                  )}
                                </Typography>
                              </>
                            }
                          />
                          {customField?.helpText && (
                            <div style={{ marginLeft: 52, marginTop: 0 }}>
                              <Typography
                                variant="bodym"
                                paragraph
                                style={{ color: colors.subtitle }}
                              >
                                {customField?.helpText}
                              </Typography>
                            </div>
                          )}
                        </>
                      )}

                      {customField?.type === customFieldDataType.TEXT && (
                        <>
                          {customFieldDetails(
                            customField?.name,
                            customField?.required,
                            customField?.helpText
                          )}

                          {webflowReferences?.hasOwnProperty(
                            customField?.name + CUSTOM_FIELD
                          ) ? (
                            <Autocomplete
                              options={
                                webflowReferences[
                                  customField?.name + CUSTOM_FIELD
                                ] || []
                              }
                              getOptionLabel={(option) =>
                                option.value?.name || option
                              }
                              size="medium"
                              value={
                                webflowReferences?.[
                                  customField?.name + CUSTOM_FIELD
                                ]?.find(
                                  (option: any) =>
                                    customField?.value === option.slug
                                )?.value?.name ||
                                customField?.value ||
                                ""
                              }
                              onChange={(event, value) => {
                                handleCustomFieldsChange(
                                  value?.slug || value,
                                  index
                                );
                              }}
                              renderInput={(params) => (
                                <StyledInput
                                  {...params}
                                  variant="outlined"
                                  {...getHelperTextProps(customField)}
                                />
                              )}
                            />
                          ) : (
                            <>
                              <StyledInput
                                fullWidth
                                size="large"
                                value={customField?.value}
                                minRows={3}
                                InputProps={{
                                  inputProps: {
                                    min: customField?.minimumValue ?? null,
                                    max: customField?.maximumValue ?? null
                                  }
                                }}
                                multiline
                                {...getHelperTextProps(customField)}
                                onChange={(event: any) => {
                                  handleCustomFieldsChange(
                                    event.target.value,
                                    index
                                  );
                                }}
                              />
                              {customField?.value && (
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-end"
                                >
                                  <Typography
                                    variant="bodys"
                                    style={{
                                      marginTop: space.XXS,
                                      display: "inline-flex",
                                      color: colors.fontSecondary,
                                      fontStyle: "italic"
                                    }}
                                  >
                                    {`${
                                      customField?.value.length
                                    } character${handleSingular(
                                      customField?.value.length
                                    )}`}
                                  </Typography>
                                </Grid>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {customField?.type === customFieldDataType.DATE && (
                        <>
                          {customFieldDetails(
                            customField?.name,
                            customField?.required,
                            customField?.helpText
                          )}

                          <StyledInput
                            fullWidth
                            size="large"
                            readOnly={true}
                            value={
                              customField?.value
                                ? formatForTimeWithTimeZone(
                                    moment(customField?.value)
                                  )
                                : formatForTimeWithTimeZone(moment())
                            }
                            InputProps={{
                              endAdornment: <CalendarGrayIcon />
                            }}
                            {...getHelperTextProps(customField)}
                            onClick={handleDatePicking}
                            inputProps={{
                              placeholder: "Pick a date"
                            }}
                          />

                          <CustomFlatPicker
                            publicationId={post.publication._id}
                            anchorEl={eventElement}
                            open={showDatePickerForCustomField}
                            disablePast={false}
                            value={customField?.value}
                            onClose={(date) => {
                              setShowDatePickerForCustomField(false);
                              //Handle invalid date
                              if (date.toString() === INVALID_DATE) {
                                return;
                              }
                              handleCustomFieldsChange(date, index);
                            }}
                            type={datePickerDefault.DEADLINE}
                          />
                        </>
                      )}
                      {customField?.type === customFieldDataType.IMAGE && (
                        <>
                          {customFieldDetails(
                            customField?.name,
                            customField?.required,
                            customField?.helpText
                          )}
                          {customField?.value ? (
                            <Grid item className={classes.imageContainer}>
                              <img
                                alt={`${customField?.name}`}
                                src={customField?.value}
                                className={classes.imageFit}
                              />

                              <IconButton
                                className={classes.closeIcon}
                                onClick={(event) => {
                                  handleCustomFieldsChange("", index);
                                }}
                                size="large"
                              >
                                <CloseIcon />
                              </IconButton>
                            </Grid>
                          ) : (
                            <Grid item className={classes.imageIconContainer}>
                              <Tooltip title="Upload image">
                                <IconButton
                                  name={selectedPostFile.CUSTOM_IMAGE}
                                  onClick={(event) =>
                                    toggleImagePicker(event, index)
                                  }
                                  size="large"
                                >
                                  <ImageIcon />
                                </IconButton>
                              </Tooltip>
                              {getFormHelperTextView(customField?.name)}
                            </Grid>
                          )}
                        </>
                      )}
                      {customField?.type === customFieldDataType.NUMBER && (
                        <>
                          {customFieldDetails(
                            customField?.name,
                            customField?.required,
                            customField?.helpText
                          )}

                          <StyledInput
                            fullWidth
                            size="large"
                            type="number"
                            {...getHelperTextProps(customField)}
                            value={customField?.value || ""}
                            InputProps={{
                              inputProps: {
                                min: customField?.minimumValue ?? null,
                                max: customField?.maximumValue ?? null
                              }
                            }}
                            onChange={(event: any) => {
                              handleCustomFieldsChange(
                                event.target.value,
                                index
                              );
                            }}
                          />
                        </>
                      )}

                      {customField?.type ===
                        customFieldDataType.MULTI_SELECT_LIST && (
                        <>
                          {customFieldDetails(
                            customField?.name,
                            customField?.required,
                            customField?.helpText
                          )}
                          {customFieldReferences?.hasOwnProperty(
                            customField?.name + CUSTOM_FIELD
                          ) ? (
                            <Autocomplete
                              multiple
                              options={
                                customFieldReferences[
                                  customField?.name + CUSTOM_FIELD
                                ]
                              }
                              getOptionLabel={(option) =>
                                option.value?.name || option?.name || option
                              }
                              size="medium"
                              value={customField?.value || []}
                              onChange={(event, newValues) => {
                                handleCustomListFieldValueChange(
                                  newValues.map(
                                    (option) => option?.slug || option
                                  ),
                                  index
                                );
                              }}
                              renderInput={(params) => (
                                <StyledInput
                                  {...params}
                                  variant="outlined"
                                  {...getHelperTextProps(customField)}
                                />
                              )}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((tag, index) =>
                                  customFieldReferences?.[
                                    customField?.name + CUSTOM_FIELD
                                  ].map(
                                    (option) =>
                                      tag === option.slug && (
                                        <StyledChip
                                          variant="gray"
                                          label={
                                            option.value?.name ||
                                            option?.name ||
                                            option
                                          }
                                          {...getTagProps({ index })}
                                        />
                                      )
                                  )
                                )
                              }
                            />
                          ) : (
                            <>
                              <TagsInput
                                value={customField?.value || []}
                                fullWidth
                                size="medium"
                                suggestions={customField?.allowedValue || []}
                                addTag={(event: any, newValue: any) =>
                                  addCustomListFieldValues(newValue, index)
                                }
                                handleChange={(event: any, newValues: any) =>
                                  handleCustomListFieldValueChange(
                                    newValues,
                                    index
                                  )
                                }
                              />
                              {getFormHelperTextView(customField?.name)}
                            </>
                          )}
                        </>
                      )}
                      {customField?.type ===
                        customFieldDataType.SINGLE_SELECT_LIST && (
                        <>
                          {customFieldDetails(
                            customField?.name,
                            customField?.required,
                            customField?.helpText
                          )}
                          {webflowReferences?.hasOwnProperty(
                            customField?.name + CUSTOM_FIELD
                          ) ? (
                            <Autocomplete
                              options={
                                webflowReferences[
                                  customField?.name + CUSTOM_FIELD
                                ]
                              }
                              getOptionLabel={(option) =>
                                option.value?.name || option
                              }
                              size="medium"
                              value={
                                webflowReferences?.[
                                  customField?.name + CUSTOM_FIELD
                                ].find(
                                  (option: any) =>
                                    customField?.value === option.slug
                                )?.value?.name ||
                                customField?.value ||
                                ""
                              }
                              onChange={(event, newValue) => {
                                handleCustomListFieldValueChange(
                                  newValue?.slug || newValue,
                                  index
                                );
                              }}
                              renderInput={(params) => (
                                <StyledInput
                                  {...params}
                                  variant="outlined"
                                  {...getHelperTextProps(customField)}
                                />
                              )}
                            />
                          ) : (
                            <>
                              <StyledSelect
                                value={customField?.value}
                                size="large"
                                fullWidth
                                onChange={(event: any) => {
                                  handleCustomFieldsChange(
                                    event.target.value,
                                    index
                                  );
                                }}
                              >
                                {customField?.allowedValue?.map((item: any) => (
                                  <StyledMenuItem value={item}>
                                    {item?.name || item}
                                  </StyledMenuItem>
                                ))}
                              </StyledSelect>
                              {getFormHelperTextView(customField?.name)}
                            </>
                          )}
                        </>
                      )}
                      {customField?.type === customFieldDataType.URL && (
                        <>
                          {customFieldDetails(
                            customField?.name,
                            customField?.required,
                            customField?.helpText
                          )}

                          <StyledInput
                            fullWidth
                            size="large"
                            value={customField?.value || ""}
                            {...getHelperTextProps(customField)}
                            onChange={(event: any) => {
                              handleCustomFieldsChange(
                                event.target.value,
                                index
                              );
                            }}
                          />
                        </>
                      )}

                      {customField?.type === customFieldDataType.RICH_TEXT && (
                        <>
                          {customFieldDetails(
                            customField?.name,
                            customField?.required,
                            customField?.helpText
                          )}

                          <div>
                            <Suspense fallback={<Loading />}>
                              <Editor
                                value={getEditorValue(customField?.value)}
                                isFromCustomField={true}
                                handleEditorChange={(
                                  value: any,
                                  editorHTML: any
                                ) => {
                                  handleCustomFieldsChange(
                                    JSON.stringify(value),
                                    index,

                                    editorHTML
                                  );
                                }}
                                isCollaborativePost={false}
                                background={
                                  publication.theme?.color || colors.primary
                                }
                                showCustomWidgetOption={true}
                                publicationId={publication._id}
                                fromPost={false}
                                rtl={false}
                                isPaidPublication={
                                  publication.type === publicationType.PAID
                                }
                                publicationDomain={publication.domain}
                                id={post._id}
                                isAnchorSupported={false}
                                postURL={`${postURL}${slug}`}
                                defaultSyntaxHighlightingLanguage={
                                  defaultSyntaxHighlightingLanguage
                                }
                                setDefaultSyntaxHighlightingLanguage={
                                  setDefaultSyntaxHighlightingLanguage
                                }
                                readOnly={isFreePlan(context, publication._id)}
                                disableAutoFocus={true}
                              />
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                              >
                                <Typography
                                  variant="bodys"
                                  style={{
                                    marginTop: `-${space.MEDIUM}`,
                                    display: "inline-flex",
                                    color: colors.fontSecondary,
                                    fontStyle: "italic"
                                  }}
                                >
                                  {`${getWordCount(
                                    customField?.value
                                  )} word${handleSingular(
                                    getWordCount(customField?.value)
                                  )}`}
                                </Typography>
                              </Grid>
                              {getFormHelperTextView(customField?.name)}
                            </Suspense>
                          </div>
                        </>
                      )}
                    </Grid>
                  </div>
                );
              })}
            </div>
          )}
          {showPreviewDialog && (
            <BlogPostPreviewDialog
              postId={post._id}
              showPopup={showPreviewDialog}
              closeDialog={() => {
                setShowPreviewDialog(false);
              }}
            />
          )}
          {showPreviewMenu && (
            <Popover
              id="preview_menu"
              open={showPreviewMenu}
              anchorEl={eventElement}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              onClose={() => {
                setShowPreviewMenu(false);

                setEventElement(null);
              }}
              PaperProps={{
                className: classes.popover
              }}
            >
              <>
                <div
                  onClick={() => {
                    onPreviewMenuClick(false);
                  }}
                  className={classes.menuItem}
                >
                  <Typography className={classes.menuText}>
                    Preview with embeds
                  </Typography>
                </div>

                <div
                  onClick={() => {
                    onPreviewMenuClick(true);
                  }}
                  className={classes.menuItem}
                >
                  <Typography className={classes.menuText}>
                    Preview without embeds
                  </Typography>
                </div>
              </>
            </Popover>
          )}

          {showOverrideSlugConfirmation && (
            <StyledDialog
              open={showOverrideSlugConfirmation}
              title="Replace slug?"
              body={
                <Grid container direction="row">
                  <Grid item xs={12} style={{ marginBottom: space.SMALL }}>
                    <Typography variant="bodym">
                      Here's an AI-generated slug. Do you want to use it?
                    </Typography>
                  </Grid>

                  <Grid item xs={11}>
                    <InputBase
                      className={classes.metaDescriptionPreview}
                      value={aiGeneratedSlug}
                      multiline={true}
                      contentEditable={false}
                      onFocus={(event) => event.target.select()}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={1} style={{ paddingLeft: space.XXS }}>
                    <CopyToClipboard
                      onCopy={() =>
                        notify.show("Copied to clipboard", "success")
                      }
                      text={aiGeneratedSlug}
                    >
                      <IconButton size="large">
                        <FileCopy />
                      </IconButton>
                    </CopyToClipboard>
                  </Grid>
                </Grid>
              }
              successButtonName="Yes"
              cancelButtonName="No"
              successCallback={() => replaceSlug(aiGeneratedSlug)}
              cancelCallback={() => {
                setShowOverrideSlugConfirmation(false);
                setAiGeneratedSlug("");
              }}
            />
          )}
          {showOverrideDescriptionConfirmation && (
            <StyledDialog
              open={showOverrideDescriptionConfirmation}
              title="Replace meta description?"
              body={
                <Grid container direction="row">
                  <Grid item xs={12} style={{ marginBottom: space.SMALL }}>
                    <Typography variant="bodym">
                      Here's an AI-generated meta description. Do you want to
                      use it?
                    </Typography>
                  </Grid>

                  <Grid item xs={11}>
                    <InputBase
                      className={classes.metaDescriptionPreview}
                      value={aiGeneratedMetaDescription}
                      multiline={true}
                      contentEditable={false}
                      onFocus={(event) => event.target.select()}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={1} style={{ paddingLeft: space.XXS }}>
                    <CopyToClipboard
                      onCopy={() =>
                        notify.show("Copied to clipboard", "success")
                      }
                      text={aiGeneratedMetaDescription}
                    >
                      <IconButton size="large">
                        <FileCopy />
                      </IconButton>
                    </CopyToClipboard>
                  </Grid>
                </Grid>
              }
              successButtonName="Yes"
              cancelButtonName="No"
              successCallback={() =>
                overrideWithAIGeneratedMetaDescription(
                  aiGeneratedMetaDescription
                )
              }
              cancelCallback={() => {
                setShowOverrideDescriptionConfirmation(false);
                setAiGeneratedMetaDescription("");
              }}
            />
          )}
        </>
      )}
    </Grid>
  );
}

//check if destination is webflow
export function isDestinationWebflow(externalSite: any, destination = "") {
  //if multiple destination present, we can check destination type

  if (destination?.type === socialMediaType.WEBFLOW) {
    return true;
  }
  //if only one destination present, we will check if it is webflow
  if (externalSite?.blogs?.length === 1) {
    let { destination } = externalSite?.blogs[0];
    return destination === socialMediaType.WEBFLOW;
  }
  return false;
}

//get webflow mapped fields
export function getWebflowDetails(
  externalSite: any,
  webflow: any,
  destination = ""
) {
  //if destination data present we will get fields from destination

  if (destination?.type === socialMediaType.WEBFLOW) {
    return webflow.find(
      (webflowItem: any) => webflowItem.collectionId === destination?.id
    );
  }
  //if no destination present, then there is only one destination
  if (
    isDestinationWebflow(externalSite, destination) &&
    externalSite?.blogs?.length === 1
  ) {
    let collectionId = externalSite?.blogs[0]?.id;
    return webflow.find(
      (webflowItem: any) => webflowItem.collectionId === collectionId
    );
  }
  return {};
}

//get key of an object by value
export function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

//check if letterdrop default field is mapped to webflow
export function checkLetterdropFieldMapped(
  fields: any,
  externalSite: any,
  webflow: any,
  destination = ""
) {
  let webflowMappedFields = getWebflowDetails(
    externalSite,
    webflow,
    destination
  )?.fieldsMapping;

  //if post is in published/scheduled state, then when the destination webflow is deleted, we will not have webflowMappedFields
  if (!webflowMappedFields) {
    return true;
  }

  return fields.some((field: any) => {
    let key = getKeyByValue(webflowMappedFields, field);

    return webflowMappedFields[key] ? true : false;
  });
}

//check if this field is optional for webflow destination
export function isFieldOptional(
  fields: any,
  externalSite: any,
  webflow: any,
  destination = ""
) {
  return (
    isDestinationWebflow(externalSite, destination) &&
    !checkLetterdropFieldMapped(fields, externalSite, webflow, destination)
  );
}

export default withStyles(styles)(React.forwardRef(BlogPostReviewSettings));
