// @ts-nocheck
import React, { Component } from "react";
import styles from "./styles/ConfirmationDialogStyles";
import { withStyles } from "@mui/styles";
import parse from "html-react-parser";

import StyledDialog from "../design/components/StyledDialog";

class OpenRateCardInfo extends Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    let { showPopup, message, showDialogHeader = true } = this.props;

    return (
      <StyledDialog
        open={showPopup}
        title={showDialogHeader && "Glossary"}
        body={parse(message)}
        successButtonName="OK"
        successCallback={this.props.closeDialog}
      />
    );
  }
}

export default withStyles(styles)(OpenRateCardInfo);
