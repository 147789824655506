// @ts-nocheck
import colors, { designColors } from "../../Themes/Colors";
import { grey } from "@mui/material/colors";

const styles = (theme: any) => ({
  cancelButton: {
    marginRight: 20
  },

  cardTitle: {
    fontSize: 20
  },

  subtitle: {
    marginTop: 8,
    color: colors.fontSecondary
  },

  dateSubtitle: {
    color: designColors.grayScale[60]
  },

  topicContainer: {
    paddingLeft: 30,
    paddingRight: 30
  },

  buttonsContainer: {
    paddingTop: 14,
    paddingBottom: 16,
    borderBottom: `2px solid ${designColors.grayScale[5]}`
  },

  fontSecondary: {
    color: colors.fontSecondary
  },

  titleInput: {
    width: "100%",
    marginTop: 10,
    "&::placeholder": {
      color: grey[300]
    }
  },

  marginTop10: {
    marginTop: 10
  },

  marginTop20: {
    marginTop: 20
  },

  section: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column"
  },

  loadingDiv: {
    marginTop: "-20%"
  },

  editorContainer: {
    marginTop: 10,
    maxHeight: 440,
    paddingBottom: 20
  },

  tagChip: {
    margin: "0px 5px 5px 0px",
    borderRadius: 5,
    backgroundColor: colors.whiteTheme,
    color: colors.fontSecondary,
    fontSize: "16px",
    height: 30,
    maxWidth: 250
  },

  text: {
    color: designColors.grayScale[100]
  },

  statMinWidth: {
    minWidth: 70
  },

  link: {
    wordBreak: "break-word",
    textDecorationColor: colors.white,
    color: colors.secondary,
    "&:hover": {
      textDecorationColor: colors.secondary
    }
  },

  lineItem: {
    marginTop: 10
  },

  topSection: {
    marginTop: 12
  },

  authorAvatar: {},

  keywordSEODetails: {
    padding: "16px 24px",
    background: designColors.grayScale[0],
    display: "flex",
    flexDirection: "column"
  },

  commentsHeading: {
    fontSize: 12,
    color: designColors.grayScale[80],
    display: "flex"
  },

  titleSection: {
    marginTop: 24,
    display: "flex",
    flexDirection: "column"
  },

  domainAuthText: {
    fontStyle: "italic",
    color: colors.fontSecondary
  },

  suggestedAndVotesSection: {
    paddingBottom: 16,
    borderBottom: `1px solid ${designColors.grayScale[5]}`
  },

  suggested: {
    display: "flex",
    alignItems: "center"
  },

  name: {
    paddingLeft: 10
  },

  postedOnSection: {
    marginLeft: 42,
    display: "flex",
    flexDirection: "column"
  },

  commentsCount: {
    display: "flex",
    alignItems: "center",
    marginTop: 12
  },

  upvotesAvatarSection: {
    display: "flex",
    paddingTop: 12,
    width: "max-content"
  },

  commentsSection: {
    paddingBottom: 32,
    marginTop: 32,
    paddingTop: 32,
    borderTop: `1px solid ${designColors.grayScale[5]}`
  },

  activitySection: {
    marginTop: 32,
    paddingTop: 32,
    borderTop: `1px solid ${designColors.grayScale[5]}`
  },

  discussionText: {
    marginTop: 20
  },

  keyWord: {
    marginTop: 10,
    color: designColors.success[50]
  },

  upvotedSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    background: designColors.primary[50],
    borderRadius: 6,
    color: colors.white
  },

  bodym: {
    //**bodym**
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    letterSpacing: "0.002em"
  },

  monthlySearchLabel: {
    display: "flex",
    justifyContent: "center",
    minWidth: 70
  },

  avatar: {
    width: 20,
    height: 20
  },

  eventPopover: {
    padding: "10px"
  },

  authorName: {
    wordBreak: "break-word",
    paddingLeft: 10,
    margin: "auto 0px"
  },

  timeline: {
    maxWidth: "100px",
    paddingLeft: "0px",
    paddingTop: "5px",
    fontSize: 12
  },

  timelineDot: {
    margin: "8px 0",
    fontSize: 12
  },

  displayFlex: {
    display: "flex"
  },

  hover: {
    cursor: "pointer",
    padding: "10px 10px",
    border: "1px solid transparent",
    "&:hover": {
      border: `1px solid ${designColors.grayScale[40]}`,
      borderRadius: 6
    }
  },

  description: {
    width: "100%",
    marginTop: 10,
    whiteSpace: "break-spaces",
    "& iframe": {
      width: "100%",
      height: "auto",
      aspectRatio: 16 / 9,
      margin: "15px 0px"
    }
  }
});
export default styles;
