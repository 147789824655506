import WestIcon from "@mui/icons-material/West";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { RouteComponentProps, withRouter } from "react-router";
import LinkedInPreview from "../Components/LinkedInPreview";
import LinkedInTemplateDropdown from "../Components/LinkedInTemplateDropdown";
import Loading from "../Components/Loading";
import UrlPreview from "../Components/UrlPreview";
import { space } from "../Config/theme";
import StyledAccordion from "../design/components/StyledAccordion";
import StyledButton from "../design/components/StyledButton";
import StyledCheckbox from "../design/components/StyledCheckbox";
import StyledInput from "../design/components/StyledInput";
import { ReactComponent as Logo } from "../Images/letterdrop_logo_with_text.svg";
import API from "../Services/Api";
import { designColors } from "../Themes/Colors";
import { linkedInTemplates } from "../Utils/SuggestedTemplatesList";
import { replaceAll } from "../Utils/UserUtils";
import styles from "./styles/SocialSellingDemoStyles";

const api = API.create();

const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"];
const selectionSteps = {
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3,
  STEP_4: 4,
  STEP_5: 5
};

const TooltipCustom = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "14px"
  }
}));

interface LinkedinPostInterface {
  _id: string;
  insight: string;
  insightAdvice: string;
  insightReasoning: string;
  insightContext: string;
  repAnswer: string;
  useRepAnswer?: boolean;
  aiAnswer: string;
  useAiAnswer?: boolean;
  referenceContent?: {
    useReference?: boolean;
    content?: string;
    url?: string;
  }[];
  callTitle?: string;
  body: string;
  isInsightDataUpdated?: boolean;
  referenceTemplate?: string;
  referenceTemplateId?: string;
}

type SocialSellingDemoProps = {
  classes: Record<keyof ReturnType<typeof styles>, string>;
} & RouteComponentProps<{ id: string }>;

type SocialSellingDemoState = {
  activeStep: number;
  loading: boolean;
  callTranscript: string;
  savedCallTranscript?: string;
  companyName: string;
  savedCompanyName?: string;
  companyDetails: string;
  allSpeakerTags: { label: string; checked: boolean }[];
  isBaseDataChanged?: boolean;
  linkedinPosts: LinkedinPostInterface[];
  selectedLinkedinPost: LinkedinPostInterface | null;
  defaultAllSamples: { label: string; sample: string; _id: string }[];
};

class SocialSellingDemo extends React.Component<
  SocialSellingDemoProps,
  SocialSellingDemoState
> {
  constructor(props: SocialSellingDemoProps) {
    super(props);

    this.state = {
      activeStep: 1,
      loading: false,
      callTranscript: "",
      savedCallTranscript: "",
      companyName: "",
      savedCompanyName: "",
      companyDetails: "",
      allSpeakerTags: [],
      isBaseDataChanged: false,
      linkedinPosts: [],
      selectedLinkedinPost: null,
      defaultAllSamples: linkedInTemplates.map((t) => ({ ...t, _id: t.label }))
    };
  }

  extractSpeakerTagFromTranscript = () => {
    const {
      activeStep,
      loading,
      callTranscript,
      savedCallTranscript,
      allSpeakerTags
    } = this.state;

    if (loading || activeStep !== 1 || !callTranscript) {
      return;
    }

    if (callTranscript === savedCallTranscript && allSpeakerTags.length) {
      this.setState({ activeStep: 2 });
      return;
    }

    this.setState({ loading: true });

    api.extractSpeakerTagFromTranscript({ callTranscript }, (res) => {
      this.setState({
        loading: false,
        activeStep: 2,
        savedCallTranscript: callTranscript,
        companyName: res.data?.companyName || "",
        savedCompanyName: res.data?.companyName || "",
        companyDetails: res.data?.companyDetails || "",
        allSpeakerTags: (res.data?.speakerTags || []).map((tag: string) => ({
          label: tag,
          checked: false
        })),
        isBaseDataChanged: true
      });
    });
  };

  generateCompanyDetails = () => {
    const {
      activeStep,
      loading,
      allSpeakerTags,
      companyName,
      savedCompanyName,
      companyDetails
    } = this.state;

    if (loading || activeStep !== 2 || !companyName || !allSpeakerTags.length) {
      return;
    }

    if (companyName === savedCompanyName && companyDetails) {
      this.setState({ activeStep: 3 });
      return;
    }

    this.setState({ loading: true });

    api.generateCompanyDetails({ companyName }, (res) => {
      this.setState({
        loading: false,
        activeStep: 3,
        savedCompanyName: companyName,
        companyDetails: res.data?.companyDetails || "",
        isBaseDataChanged: true
      });
    });
  };

  generateLinkedinPostsFromTranscript = () => {
    const {
      activeStep,
      loading,
      callTranscript,
      companyName,
      companyDetails,
      allSpeakerTags,
      isBaseDataChanged,
      linkedinPosts
    } = this.state;

    if (
      loading ||
      activeStep !== 3 ||
      !callTranscript ||
      !companyName ||
      !companyDetails ||
      !allSpeakerTags.length
    ) {
      return;
    }

    if (!isBaseDataChanged && linkedinPosts.length) {
      this.setState({ activeStep: 4 });
      return;
    }

    this.setState({ loading: true });

    const customers = allSpeakerTags
      .filter((tag) => !tag.checked)
      .map((tag) => tag.label);

    const sales_rep = allSpeakerTags
      .filter((tag) => tag.checked)
      .map((tag) => tag.label);

    const bodyData = {
      callTranscript,
      participants: { customers, sales_rep },
      companyName,
      companyDetails
    };

    api.generateLinkedinPostsFromTranscript(bodyData, (res) => {
      this.setState({
        loading: false,
        activeStep: 4,
        isBaseDataChanged: false,
        linkedinPosts: (res.data?.linkedinPosts || []).map(
          (post: any): LinkedinPostInterface => ({
            _id: post.insight,
            insight: post.insight,
            insightAdvice: post.insight_advice,
            insightReasoning: post.insight_reasoning,
            insightContext: post.insight_context,
            repAnswer: post.rep_answer,
            useRepAnswer: post.useRepAnswer || false,
            aiAnswer: post.ai_answer,
            useAiAnswer: post.useAiAnswer || false,
            referenceContent: post.reference_content || [],
            callTitle: post.call_title || "",
            body: post.linkedin_draft
          })
        )
      });
    });
  };

  regenerateLinkedinPostFromTranscript = () => {
    const {
      activeStep,
      loading,
      callTranscript,
      allSpeakerTags,
      selectedLinkedinPost
    } = this.state;

    if (
      loading ||
      (activeStep !== 4 && activeStep !== 5) ||
      !callTranscript ||
      !selectedLinkedinPost
    ) {
      return;
    }

    if (activeStep === 4 && selectedLinkedinPost.body) {
      this.setState({ activeStep: 5 });
      return;
    }

    if (activeStep === 5 && !selectedLinkedinPost.isInsightDataUpdated) {
      return;
    }

    this.setState({ loading: true });

    const customers = allSpeakerTags
      .filter((tag) => !tag.checked)
      .map((tag) => tag.label);

    const sales_rep = allSpeakerTags
      .filter((tag) => tag.checked)
      .map((tag) => tag.label);

    const bodyData = {
      callTranscript,
      participants: { customers, sales_rep },
      insightMetadata: {
        insight: selectedLinkedinPost.insight,
        insight_reasoning: selectedLinkedinPost.insightReasoning,
        insight_context: selectedLinkedinPost.insightContext,
        insight_advice: selectedLinkedinPost.insightAdvice,
        sales_rep_answer: selectedLinkedinPost.repAnswer,
        useRepAnswer: selectedLinkedinPost.useRepAnswer,
        ai_answer: selectedLinkedinPost.aiAnswer,
        useAiAnswer: selectedLinkedinPost.useAiAnswer,
        reference_template: selectedLinkedinPost.referenceTemplate
      }
    };

    api.regenerateLinkedinPostFromTranscript(bodyData, (res) => {
      const linkedinPost = res.data?.linkedinPost || selectedLinkedinPost.body;
      selectedLinkedinPost.body = linkedinPost;
      selectedLinkedinPost.isInsightDataUpdated = !res.data?.success;

      this.setState({
        loading: false,
        activeStep: 5,
        selectedLinkedinPost: selectedLinkedinPost
      });
    });
  };

  render() {
    const {
      activeStep,
      loading,
      callTranscript,
      companyName,
      companyDetails,
      allSpeakerTags,
      linkedinPosts,
      selectedLinkedinPost,
      defaultAllSamples
    } = this.state;

    const genrateFromTranscriptData = {
      activeStep,
      loading,
      callTranscript,
      companyName,
      companyDetails,
      allSpeakerTags,
      linkedinPosts,
      selectedLinkedinPost,
      defaultAllSamples
    };

    return (
      <Box
        style={{ width: "100%" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box style={{ marginTop: space.LARGE }}>
          <Logo
            style={{ height: 40, width: 177, cursor: "pointer" }}
            onClick={() => {
              this.props.history.push("/");
            }}
          />
        </Box>
        <Box
          style={{ margin: space.LARGE, width: "80%", maxWidth: "1200px" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <PostFromTranscript
            data={genrateFromTranscriptData}
            setData={(d) => this.setState(d)}
            classes={this.props.classes}
            extractSpeakerTagFromTranscript={
              this.extractSpeakerTagFromTranscript
            }
            generateCompanyDetails={this.generateCompanyDetails}
            generateLinkedinPostsFromTranscript={
              this.generateLinkedinPostsFromTranscript
            }
            regenerateLinkedinPostFromTranscript={
              this.regenerateLinkedinPostFromTranscript
            }
          />
        </Box>
      </Box>
    );
  }
}

interface PostFromTranscriptProps {
  data: SocialSellingDemoState;
  setData: <K extends keyof SocialSellingDemoState>(
    arg: Pick<SocialSellingDemoState, K>
  ) => void;
  classes: Record<keyof ReturnType<typeof styles>, string>;
  extractSpeakerTagFromTranscript: () => void;
  generateCompanyDetails: () => void;
  generateLinkedinPostsFromTranscript: () => void;
  regenerateLinkedinPostFromTranscript: () => void;
}

function PostFromTranscript(props: PostFromTranscriptProps) {
  const {
    data,
    setData,
    classes,
    extractSpeakerTagFromTranscript,
    generateCompanyDetails,
    generateLinkedinPostsFromTranscript,
    regenerateLinkedinPostFromTranscript
  } = props;

  const {
    activeStep,
    loading,
    callTranscript,
    companyName,
    companyDetails,
    allSpeakerTags,
    linkedinPosts,
    selectedLinkedinPost,
    defaultAllSamples
  } = data;

  const handleBack = () => {
    if (activeStep === 4) {
      setData({ selectedLinkedinPost: null });
    }

    setData({ activeStep: activeStep - 1 });
  };

  const salesRepSelected = allSpeakerTags.some((tag) => tag.checked);

  return (
    <>
      <Stepper
        activeStep={activeStep - 1}
        style={{ width: "100%", marginBottom: space.MEDIUM }}
      >
        {steps.map((label, index) => {
          return (
            <Step
              key={label}
              sx={{
                "&.MuiStep-root:first-child": {
                  paddingLeft: "0"
                },
                "&.MuiStep-root:last-child": {
                  paddingRight: "0"
                }
              }}
            >
              <StepLabel
                sx={{
                  "&.MuiStepLabel-root": {
                    ".MuiStepLabel-iconContainer": {
                      paddingRight: "0"
                    }
                  }
                }}
              ></StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div style={{ width: "100%" }}>
        {activeStep === selectionSteps.STEP_1 && (
          <>
            <Typography variant="h200" component={"h4"} mb={space.XS}>
              Paste a transcript from a call with a customer{" "}
            </Typography>
            <StyledInput
              fullWidth
              value={callTranscript}
              multiline={true}
              rows={20}
              size="large"
              style={{ marginBottom: space.MEDIUM }}
              onChange={(event) => {
                setData({ callTranscript: event.target.value });
              }}
            />
          </>
        )}

        {activeStep === selectionSteps.STEP_2 && (
          <>
            <Typography variant="h200" component={"h4"} mb={space.XS}>
              Name of your company
            </Typography>
            <StyledInput
              fullWidth
              value={companyName}
              size="large"
              style={{ marginBottom: space.MEDIUM }}
              onChange={(event) => {
                setData({ companyName: event.target.value });
              }}
            />
            <Typography variant="h200" component={"h4"} mb={space.XS}>
              Who is on your team or internal to your company?
            </Typography>
            {allSpeakerTags.map((tag, index) => (
              <div>
                <StyledCheckbox
                  onChange={() => {
                    allSpeakerTags[index].checked =
                      !allSpeakerTags[index].checked;
                    setData({
                      allSpeakerTags,
                      isBaseDataChanged: true
                    });
                  }}
                  checked={tag.checked}
                  label={tag.label}
                  labelStyle={{ marginTop: 0, marginBottom: 0 }}
                />
              </div>
            ))}
            {!allSpeakerTags.length && (
              <div style={{ marginBottom: space.MEDIUM }}>
                <Typography variant="bodym">
                  No speakers found. Please modify or re-analyze the transcript
                </Typography>
              </div>
            )}
            <div style={{ marginBottom: space.MEDIUM }}></div>
          </>
        )}

        {activeStep === selectionSteps.STEP_3 && (
          <>
            <Typography variant="h200" component={"h4"} mb={space.XS}>
              Name of your company
            </Typography>
            <StyledInput
              fullWidth
              value={companyName}
              size="large"
              style={{ marginBottom: space.MEDIUM }}
              disabled={true}
              onChange={(event) => {}}
            />
            <Typography variant="h200" component={"h4"} mb={space.XS}>
              What does your company do?
            </Typography>
            <StyledInput
              fullWidth={true}
              value={companyDetails}
              multiline={true}
              rows={16}
              size="large"
              style={{ marginBottom: space.MEDIUM }}
              onChange={(event) => {
                setData({
                  companyDetails: event.target.value,
                  isBaseDataChanged: true
                });
              }}
            />
          </>
        )}

        {activeStep === selectionSteps.STEP_4 && (
          <div style={{ marginBottom: space.MEDIUM }}>
            <Typography
              variant="h300"
              component="h3"
              style={{
                display: "flex",
                alignItems: "center",
                gap: space.SMALL,
                marginRight: `-${space.LARGE}`,
                paddingRight: space.LARGE
              }}
            >
              Select an idea to generate the LinkedIn post
            </Typography>
            <Divider sx={{ my: space.SMALL }} />
            {renderLinkedinPostIdeasList({
              suggestions: linkedinPosts,
              selectedSuggestion: selectedLinkedinPost,
              setSelectedSuggestion: (suggestion) => {
                setData({ selectedLinkedinPost: suggestion });
              },
              hasNextPage: false,
              getNextPage: () => {},
              classes
            })}
          </div>
        )}

        {activeStep === selectionSteps.STEP_5 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            <div style={{ marginRight: space.LARGE }}>
              <LinkedInPreview
                linkedInPost={{ body: selectedLinkedinPost?.body }}
                isTemplatePreview
              />
            </div>
            <div style={{ flexGrow: 2 }}>
              {renderCallMetaData({
                callMetaData: selectedLinkedinPost,
                showBackIcon: false,
                handleBackIconClick: () => {},
                handleSalesRepAnswerInput: (event) => {
                  let repAnswer = event.target.value;
                  if (!selectedLinkedinPost) {
                    return;
                  }
                  selectedLinkedinPost.repAnswer = repAnswer;
                  selectedLinkedinPost.useRepAnswer = repAnswer ? true : false;
                  selectedLinkedinPost.isInsightDataUpdated = true;
                  setData({ selectedLinkedinPost });
                },
                handleAiAnswerCheckbox: (event) => {
                  if (!selectedLinkedinPost) {
                    return;
                  }
                  selectedLinkedinPost.useAiAnswer = event.target.checked;
                  selectedLinkedinPost.isInsightDataUpdated = true;
                  setData({ selectedLinkedinPost });
                },
                handleReferenceContentCheckbox: (event, index) => {
                  if (!selectedLinkedinPost?.referenceContent?.[index]) {
                    return;
                  }
                  selectedLinkedinPost.referenceContent[index].useReference =
                    event.target.checked;
                  selectedLinkedinPost.isInsightDataUpdated = true;
                  setData({ selectedLinkedinPost });
                }
              })}

              <Typography
                variant="h200"
                component="h4"
                mt={space.MEDIUM}
                mb={space.XS}
              >
                Template used
              </Typography>
              <LinkedInTemplateDropdown
                publicationId={""}
                notFetchAllSamples={true}
                initialSamples={defaultAllSamples}
                initialSelectedSample={
                  selectedLinkedinPost?.referenceTemplate || ""
                }
                initialSelectedSampleId={
                  selectedLinkedinPost?.referenceTemplateId || ""
                }
                onChange={(event, samples) => {
                  if (!selectedLinkedinPost) {
                    return;
                  }

                  let sample = samples.find(
                    (sample) => sample._id === event.target.value
                  );

                  selectedLinkedinPost.referenceTemplate = sample?.sample;
                  selectedLinkedinPost.referenceTemplateId = sample?._id;
                  selectedLinkedinPost.isInsightDataUpdated = true;
                  setData({ selectedLinkedinPost });
                }}
                suggestedSampleIds={[]}
              />

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent={"space-between"}
                mt={space.LARGE}
              >
                <StyledButton
                  variant="secondary"
                  disabled={loading || activeStep === 1}
                  onClick={handleBack}
                >
                  Back
                </StyledButton>
                <StyledButton
                  onClick={regenerateLinkedinPostFromTranscript}
                  disabled={!selectedLinkedinPost?.isInsightDataUpdated}
                  style={{ marginLeft: "auto" }}
                >
                  {loading && (
                    <CircularProgress
                      size={20}
                      style={{
                        color: designColors.white,
                        marginRight: space.XS
                      }}
                    />
                  )}
                  {loading ? "Regenerating..." : "Regenerate"}
                </StyledButton>
              </Box>
            </div>
          </div>
        )}

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={"space-between"}
        >
          {(activeStep === selectionSteps.STEP_2 ||
            activeStep === selectionSteps.STEP_3 ||
            activeStep === selectionSteps.STEP_4) && (
            <StyledButton
              variant="secondary"
              disabled={loading || activeStep === 1}
              onClick={handleBack}
            >
              Back
            </StyledButton>
          )}

          {activeStep === selectionSteps.STEP_1 && (
            <StyledButton
              onClick={extractSpeakerTagFromTranscript}
              disabled={!callTranscript}
              style={{ marginLeft: "auto" }}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ color: designColors.white, marginRight: space.XS }}
                />
              )}
              {loading ? "Analyzing..." : "Analyze"}
            </StyledButton>
          )}
          {activeStep === selectionSteps.STEP_2 && (
            <StyledButton
              onClick={generateCompanyDetails}
              disabled={!companyName || !salesRepSelected}
              style={{ marginLeft: "auto" }}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ color: designColors.white, marginRight: space.XS }}
                />
              )}
              {loading ? "Next..." : "Next"}
            </StyledButton>
          )}
          {activeStep === selectionSteps.STEP_3 && (
            <StyledButton
              onClick={generateLinkedinPostsFromTranscript}
              disabled={!companyName || !companyDetails || !salesRepSelected}
              style={{ marginLeft: "auto" }}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ color: designColors.white, marginRight: space.XS }}
                />
              )}
              {loading ? "Extracting..." : "Extract Ideas"}
            </StyledButton>
          )}
          {activeStep === selectionSteps.STEP_4 && (
            <StyledButton
              onClick={regenerateLinkedinPostFromTranscript}
              disabled={!selectedLinkedinPost}
              style={{ marginLeft: "auto" }}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ color: designColors.white, marginRight: space.XS }}
                />
              )}
              {loading ? "Generating..." : "Generate"}
            </StyledButton>
          )}
        </Box>
      </div>
    </>
  );
}

interface RenderLinkedinPostIdeasListProps {
  suggestions: LinkedinPostInterface[];
  selectedSuggestion: LinkedinPostInterface | null;
  setSelectedSuggestion: (arg: LinkedinPostInterface | null) => void;
  hasNextPage: boolean;
  getNextPage: () => void;
  classes: Record<keyof ReturnType<typeof styles>, string>;
}

const renderLinkedinPostIdeasList = ({
  suggestions,
  selectedSuggestion,
  setSelectedSuggestion,
  hasNextPage,
  getNextPage,
  classes
}: RenderLinkedinPostIdeasListProps) => {
  return (
    <div
      id="scrollableDivLinkedinPostIdeas"
      style={{
        overflowY: "auto",
        maxHeight: "calc(100vh - 260px)",
        marginRight: `-${space.LARGE}`,
        paddingRight: space.LARGE
      }}
    >
      <InfiniteScroll
        className={classes.scrollHeight}
        dataLength={suggestions?.length || 0}
        next={getNextPage}
        hasMore={hasNextPage}
        loader={<Loading />}
        scrollableTarget="scrollableDivLinkedinPostIdeas"
      >
        {Boolean(suggestions?.length) ? (
          suggestions.map((suggestion) => {
            return (
              <div
                className={clsx(
                  classes.suggestion,
                  selectedSuggestion?._id !== suggestion._id &&
                    classes.suggestionHover
                )}
                style={{
                  marginBottom: space.XXS,
                  background:
                    selectedSuggestion?._id === suggestion._id
                      ? designColors.grayScale[5]
                      : "inherit"
                }}
                onClick={() => {
                  setSelectedSuggestion(suggestion);
                }}
              >
                {suggestion.callTitle && (
                  <Typography
                    variant="bodyl"
                    style={{
                      marginBottom: space.XS
                    }}
                  >
                    From <b>"{suggestion.callTitle}"</b>
                  </Typography>
                )}

                <Typography
                  variant="bodym"
                  style={{
                    marginBottom: space.XS,
                    fontWeight: 600
                  }}
                >
                  {suggestion.insight}
                </Typography>
                <Typography variant="bodym" style={{ marginBottom: space.XS }}>
                  {suggestion.insightContext}
                </Typography>

                {selectedSuggestion?._id === suggestion._id && (
                  <>
                    {suggestion.insightAdvice && (
                      <Typography
                        variant="bodym"
                        style={{ marginBottom: space.XS }}
                      >
                        <b>Advice: </b>
                        {suggestion.insightAdvice}
                      </Typography>
                    )}
                    {suggestion.insightReasoning && (
                      <Typography
                        variant="bodym"
                        style={{ marginBottom: space.XS }}
                      >
                        <b>Why: </b>
                        {suggestion.insightReasoning}
                      </Typography>
                    )}
                  </>
                )}
              </div>
            );
          })
        ) : (
          <Typography
            variant="bodym"
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            No Data
          </Typography>
        )}
      </InfiniteScroll>
    </div>
  );
};

interface RenderCallMetaDataProps {
  callMetaData: LinkedinPostInterface | null;
  disableMaxHeight?: boolean;
  showBackIcon: boolean;
  handleBackIconClick: () => void;
  handleSalesRepAnswerInput: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleAiAnswerCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleReferenceContentCheckbox: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
}

export const renderCallMetaData = ({
  callMetaData,
  disableMaxHeight,
  showBackIcon,
  handleBackIconClick,
  handleSalesRepAnswerInput,
  handleAiAnswerCheckbox,
  handleReferenceContentCheckbox
}: RenderCallMetaDataProps) => {
  return (
    <div>
      <Typography
        variant="h300"
        component="h3"
        style={{
          display: "flex",
          alignItems: "center",
          gap: space.SMALL,
          marginRight: `-${space.LARGE}`,
          paddingRight: space.LARGE
        }}
      >
        {showBackIcon && (
          <TooltipCustom title="Show Ideas">
            <IconButton onClick={handleBackIconClick}>
              <WestIcon fontSize="large" style={{ fontSize: "24px" }} />
            </IconButton>
          </TooltipCustom>
        )}
        What was used to generate this LinkedIn post
      </Typography>
      <Divider sx={{ my: space.SMALL }} />
      <Box
        style={
          disableMaxHeight
            ? {}
            : {
                overflowY: "auto",
                maxHeight: "calc(100vh - 350px)",
                marginRight: `-${space.LARGE}`,
                paddingRight: space.LARGE
              }
        }
      >
        <Typography variant="h200" component="h4" mb={space.XS}>
          Question
        </Typography>
        <Typography variant="bodym">{callMetaData?.insight || "NA"}</Typography>
        <Typography
          variant="h200"
          component="h4"
          mt={space.MEDIUM}
          mb={space.XS}
        >
          Answer from Sales Rep
        </Typography>
        <StyledInput
          fullWidth
          value={callMetaData?.repAnswer || ""}
          placeholder="NA"
          multiline={true}
          rows={8}
          size="medium"
          onChange={(event) => {
            handleSalesRepAnswerInput(event);
          }}
        />
        {Boolean(callMetaData?.referenceContent?.length) && (
          <div>
            <Typography
              variant="h200"
              component="h4"
              mt={space.MEDIUM}
              mb={space.XS}
            >
              Answers from Content
            </Typography>
            <Divider style={{ marginTop: space.SMALL }} />

            {false && Boolean(callMetaData?.aiAnswer) && (
              <>
                <StyledAccordion
                  sx={{
                    border: "none",
                    padding: 0,
                    margin: `${space.MEDIUM} 0 0 0 !important`,
                    "& .MuiAccordionSummary-root": {
                      margin: 0
                    },
                    "& .MuiAccordionDetails-root": {
                      padding: "0px !important",
                      paddingTop: `${space.MEDIUM} !important`
                    }
                  }}
                  accordionSummary={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: space.XXS
                      }}
                    >
                      <StyledCheckbox
                        checked={callMetaData?.useAiAnswer}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleAiAnswerCheckbox(event);
                        }}
                      />
                      <Typography variant="bodym">Best Guess Answer</Typography>
                    </div>
                  }
                  accordionDetails={
                    <Typography
                      variant="bodym"
                      pt={space.MEDIUM}
                      dangerouslySetInnerHTML={{
                        __html: replaceAll(
                          callMetaData?.aiAnswer || "",
                          "\n",
                          "<br />"
                        )
                      }}
                    />
                  }
                />
                <Divider style={{ marginTop: space.SMALL }} />
              </>
            )}

            {callMetaData?.referenceContent?.map((content, index) => (
              <>
                <StyledAccordion
                  sx={{
                    border: "none",
                    padding: 0,
                    margin: `${space.MEDIUM} 0 0 0 !important`,
                    "& .MuiAccordionSummary-root": {
                      margin: 0
                    },
                    "& .MuiAccordionDetails-root": {
                      padding: "0px !important",
                      paddingTop: `${space.MEDIUM} !important`
                    }
                  }}
                  accordionSummary={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: space.XXS
                      }}
                    >
                      <StyledCheckbox
                        checked={content?.useReference}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleReferenceContentCheckbox(event, index);
                        }}
                      />
                      <Typography variant="bodym">
                        From <UrlPreview url={content?.url || ""} />
                      </Typography>
                    </div>
                  }
                  accordionDetails={
                    <Typography
                      variant="bodym"
                      pt={space.MEDIUM}
                      dangerouslySetInnerHTML={{
                        __html: replaceAll(
                          content?.content || "",
                          "\n",
                          "<br />"
                        )
                      }}
                    />
                  }
                />
                <Divider style={{ marginTop: space.SMALL }} />
              </>
            ))}
          </div>
        )}
      </Box>
    </div>
  );
};

export default withRouter(withStyles(styles)(SocialSellingDemo));
