// @ts-nocheck
import colors, { designColors } from "../../Themes/Colors";
const styles = (theme: any) => ({
  cardTitle: {
    fontWeight: "bold",
    fontSize: 16,
    margin: "20px  0px",
    paddingLeft: 10
  },

  tooltipPaper: {
    padding: "5px 10px",
    borderRadius: 6,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    backgroundColor: designColors.grayScale[80],
    whiteSpace: "pre-wrap"
  },

  tooltipLabel: {
    color: colors.white
  },

  infoIcon: {
    height: 25,
    width: 25,
    paddingLeft: 5,
    cursor: "pointer",
    verticalAlign: "middle"
  },

  countLabel: {
    marginBottom: "20px",
    paddingLeft: 10
  }
});
export default styles;
