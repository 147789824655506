// @ts-nocheck
import { red } from "@mui/material/colors";
import colors from "../../Themes/Colors";

const style = (theme: any) => ({
  container: {
    display: "flex",
    width: 430, // To match with stripe card input width
    flexDirection: "column",
    justifyContent: "center"
  },

  error: {
    color: red[400],
    fontSize: 14,
    textAlign: "center"
  },

  cardForm: {
    backgroundColor: colors.inputBackground,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    border: "none",
    width: 400,
    padding: 15,
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 5,
    color: colors.fontInput,
    fontSize: 16,
    letterSpacing: "0.025em",
    boxSizing: "unset !important",
    [theme.breakpoints.down("md")]: {
      width: 400
    }
  },

  button: {
    marginTop: 20
  }
});

export default style;
