// @ts-nocheck
import React, { useState, useEffect } from "react";

import Loading from "../Components/Loading";
import styles from "./styles/RedditShareStyle";
import { withStyles } from "@mui/styles";
import { Container, Paper } from "@mui/material";
import clsx from "clsx";
import API from "../Services/Api";

import StyledSwitch from "../design/components/StyledSwitch";

const api = API.create();

const Page404 = React.lazy(() => import("../Containers/Page404"));

const DevtoShare = ({
  postId,
  publicationId,
  isReviewScreen,
  classes
}: any) => {
  const [loading, setLoading] = useState(true);
  const [sharePost, setSharePost] = useState(false);
  const [error, setError] = useState(null);
  const [devtoPostId, setDevtoPostId] = useState(null);

  useEffect(() => {
    api.getDevtoPost(publicationId, postId, (res: any) => {
      if (res.status === 200) {
        setLoading(false);
        setDevtoPostId(res.data.devtoPostId);
        if (res.data?.devtoPostId) {
          setSharePost(true);
        }
      } else {
        setError(res.status === 400 ? res.data : "Oops something went wrong");
        setLoading(false);
      }
    });
  }, []);

  const changeToggle = () => {
    if (!sharePost) {
      api.saveDevtoPost(devtoPostId, publicationId, postId, (res: any) => {
        if (res.status === 200) {
          setDevtoPostId(res.data.devtoPostId);
          setSharePost(!sharePost);
        }
      });
    } else {
      api.deleteDevtoPost(devtoPostId, publicationId, postId, (res: any) => {
        if (res.status === 200) {
          setDevtoPostId(null);
          setSharePost(!sharePost);
        }
      });
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Page404 errorMessage={error} />;
  }
  return (
    <Container style={{ padding: 0 }}>
      <Paper className={clsx(classes.paper)}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 12
          }}
        >
          <StyledSwitch
            checked={sharePost}
            onChange={changeToggle}
            label="Share this post to Dev.to"
            labelPlacement="end"
          />
        </div>
      </Paper>
    </Container>
  );
};

export default withStyles(styles)(DevtoShare);
