// @ts-nocheck
import { Grid, Container, Typography } from "@mui/material";
import React from "react";

import { withRouter } from "react-router";
import { space } from "../Config/theme";

import StyledSwitch from "../design/components/StyledSwitch";
import API from "../Services/Api";
import { notify } from "./CustomNotifications";

const api = API.create();

class NewsletterSettings extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      newsletterEnabled: props.publication.sendBlogNewsletter || false,
      publication: props.publication
    };
  }

  updateSetting = () => {
    let { newsletterEnabled } = this.state;
    this.setState({ newsletterEnabled: !newsletterEnabled }, () => {
      this.toggleSendBlogNewsletter();
    });
  };

  toggleSendBlogNewsletter = () => {
    let { newsletterEnabled, publication } = this.state;
    api.toggleSendBlogNewsletter(
      newsletterEnabled,
      publication._id,
      (res: any) => {
        if (res.status !== 200) {
          this.setState({ newsletterEnabled: !newsletterEnabled });
          notify.show(
            res.status === 400
              ? res.data
              : "Oops, something went wrong. Contact support@letterdrop.com",
            "error"
          );
        }
      }
    );
  };

  render() {
    let { newsletterEnabled } = this.state;

    return (
      <Container style={{ padding: 0 }}>
        <Typography variant="h400" paragraph>
          Newsletter
        </Typography>

        <Grid item container xs={12} style={{ marginTop: `${space.XS}` }}>
          <Grid item xs={12} sm={12} md={3} style={{ margin: "auto 0px" }}>
            <Typography variant="bodym"> Send blogs as newsletters</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <StyledSwitch
              checked={newsletterEnabled}
              onChange={this.updateSetting}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(NewsletterSettings);
