// @ts-nocheck
import { Quill } from "react-quill";

const Embed = Quill.import("blots/embed");

class MentionBlot extends Embed {
  static create(data: any) {
    const node = super.create();
    const denotationChar = document.createElement("span");
    denotationChar.className = "ql-mention-denotation-char";
    node.appendChild(denotationChar);
    //denotationChar is @ indicates that it is a user mention, include @ in the value.
    node.innerHTML +=
      data.denotationChar === "@" ? `@${data.value}` : data.value;
    return MentionBlot.setDataValues(node, data);
  }

  static setDataValues(element: any, data: any) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  static value(domNode: any) {
    return domNode.dataset;
  }
}

MentionBlot.blotName = "mention";
MentionBlot.tagName = "span";
MentionBlot.className = "mention";

export default MentionBlot;
