// @ts-nocheck
import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import style from "../Containers/styles/KeywordResearchStyle";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableContainer,
  TablePagination
} from "@mui/material";
import MuiTableCell from "@mui/material/TableCell";
import Skeleton from "@mui/material/Skeleton";
import { formatNumber } from "../Utils/UserUtils";
import API from "../Services/Api";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StyledPagination from "../design/components/StyledPagination";

const api = API.create();

const TableBodyCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

const DefaultPageSize = 10;

class TopPages extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      domain: props.domain,
      topPages: props.topPages,
      topPagesPage: 0,
      topPagesRowsPerPage: DefaultPageSize,
      topPagesSize: props.topPagesSize,
      topPagesLoading: props.topPagesLoading
    };
  }

  componentDidUpdate(prevProps: any) {
    let { domain, topPages, topPagesSize, topPagesLoading } = this.props;
    if (
      prevProps.domain !== domain ||
      prevProps.topPages !== topPages ||
      prevProps.topPagesLoading !== topPagesLoading
    ) {
      this.setState({
        domain: domain,
        topPages: topPages,
        topPagesSize: topPagesSize,
        topPagesLoading: topPagesLoading
      });
    }
  }

  doPaginationForTopPages = () => {
    let { domain, topPagesPage, topPagesRowsPerPage } = this.state;
    api.getTopPagesWithPagination(
      domain,
      topPagesPage,
      topPagesRowsPerPage,
      (res: any) => {
        if (res.status === 200) {
          // this.props.getBacklinks(res.data.topPages);
          this.setState({
            topPages: res.data.topPages,
            topPagesSize: res.data.topPagesSize
          });
        }
      }
    );
  };

  handleChangePage = (event: any, newPage: any) => {
    this.setState({ topPagesPage: newPage }, () => {
      this.doPaginationForTopPages();
    });
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState(
      {
        topPagesRowsPerPage: event.target.value,
        topPagesPage: 0,
        loading: true
      },
      () => {
        this.doPaginationForTopPages();
      }
    );
  };

  cardSkeleton = () => {
    let { classes } = this.props;

    return <Skeleton variant="rectangular" className={classes.cardSkeleton} />;
  };

  render() {
    let { classes } = this.props;
    let {
      topPages,

      topPagesLoading,

      topPagesSize,

      topPagesPage,

      topPagesRowsPerPage
    } = this.state;

    return (
      <div className={classes.keywordIdeasCard}>
        <Typography variant="body1" className={classes.heading}>
          Top Pages
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.firstCell}>
                  <Typography variant="body2" className={classes.heading}>
                    Title
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="body2" className={classes.heading}>
                    <span>
                      Estimated Visits
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        fontSize="small"
                        onClick={this.props.showEstimatedVisits}
                      />
                    </span>
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="body2" className={classes.heading}>
                    Referring Domains
                    <InfoOutlinedIcon
                      className={classes.infoIcon}
                      fontSize="small"
                      onClick={this.props.showReferringDomain}
                    />
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="body2" className={classes.heading}>
                    Backlinks
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="body2" className={classes.heading}>
                    Keywords it’s on First Page For
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {topPages && topPages.length > 0 ? (
              <TableBody>
                {topPages?.map((page: any, index: any) => {
                  return (
                    <TableRow scope="row" key={index}>
                      <TableBodyCell className={classes.firstCell}>
                        <a
                          href={page.url}
                          target="_blank"
                          className={classes.link}
                          rel="noreferrer"
                        >
                          <Typography variant="body2">
                            {page.title ? page.title : page.url}
                          </Typography>
                          {page.title && (
                            <Typography
                              variant="caption"
                              className={classes.pageURL}
                            >
                              {page.url}
                            </Typography>
                          )}
                        </a>
                      </TableBodyCell>

                      <TableBodyCell>
                        {(page.metricsUpdated &&
                          (page.estimatedVisits
                            ? formatNumber(Math.ceil(page.estimatedVisits))
                            : "N/A")) ||
                          this.cardSkeleton()}
                      </TableBodyCell>

                      <TableBodyCell>
                        {(page.metricsUpdated &&
                          (page.referringDomains
                            ? formatNumber(page.referringDomains)
                            : "N/A")) ||
                          this.cardSkeleton()}
                      </TableBodyCell>

                      <TableBodyCell>
                        {(page.metricsUpdated &&
                          (page.backlinksCount
                            ? formatNumber(page.backlinksCount)
                            : "N/A")) ||
                          this.cardSkeleton()}
                      </TableBodyCell>

                      <TableBodyCell>
                        {page.pos_1 ? formatNumber(page.pos_1) : "N/A"}
                      </TableBodyCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : topPagesLoading ? (
              <TableBody>
                <TableRow scope="row">
                  <TableBodyCell colSpan={5} className={classes.firstCell}>
                    {this.cardSkeleton()}
                  </TableBodyCell>
                </TableRow>

                <TableRow scope="row">
                  <TableBodyCell colSpan={5} className={classes.firstCell}>
                    {this.cardSkeleton()}
                  </TableBodyCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <TableRow scope="row">
                  <TableBodyCell colSpan={5}>
                    <Typography
                      variant="body1"
                      className={classes.errorMessage}
                    >
                      We couldn't find any top Pages.
                    </Typography>
                  </TableBodyCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {topPages && topPages.length > 0 && (
          <StyledPagination
            count={topPagesSize}
            rowsPerPage={topPagesRowsPerPage}
            page={topPagesPage}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        )}
      </div>
    );
  }
}

export default withStyles(style)(TopPages);
