// @ts-nocheck
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Avatar,
  Grid,
  IconButton,
  Container,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  InputAdornment
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { arrayMove, List } from "react-movable";

import { withRouter } from "react-router";
import StyledButton from "../design/components/StyledButton";

import StyledChip from "../design/components/StyledChip";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import API from "../Services/Api";
import colors from "../Themes/Colors";
import {
  contentMapFieldTypes,
  apiFetchStatus,
  topicStatus,
  channelTypes
} from "../Utils/Types";

import ContentStages from "./ContentStages";
import { notify } from "./CustomNotifications";

import CustomFields from "./CustomFields";
import styles from "./styles/ContentMapTemplateStyle";

import IdeaTemplates from "./IdeaTemplates";

const _ = require("lodash");

const api = API.create();

const DEADLINES_NOT_REQUIRED = [topicStatus.SCHEDULED, topicStatus.PUBLISHED];

const MIN_DEADLINE_DAYS = 0;
const MAX_DEADLINE_DAYS = 30;

class ContentWorkflow extends React.Component {
  deadlinesRef: any;
  constructor(props: any) {
    super(props);

    let contentMapEnabled = this.isStageEnabled(
      props.publication.contentStages,
      topicStatus.CONTENT_MAP_DRAFT
    );

    let stages = this.getContentStages(contentMapEnabled);

    this.state = {
      showConfirmationDialog: false,
      publicationId: props.publication._id ? props.publication._id : "",
      generateOutlineAndArticleByAI:
        props.publication?.generateOutlineAndArticleByAI,
      templateId: props.publication.contentMapTemplateId,
      styleGuide: props.publication.styleGuide
        ? props.publication.styleGuide
        : "",
      showStyleGuideResetDialog: false,
      unsaved: [],
      authors: [],
      defaultAuthor: null,
      showAddRow: false,
      contentMapFields: props.publication.contentMapFields
        ? props.publication.contentMapFields
        : [],
      type: contentMapFieldTypes.INSTRUCTION,
      label: "",
      placeholder: "",
      showDeleteConfirmation: false,
      deleteFieldIndex: null,
      editFieldIndex: null,
      contentMapEnabled,
      customFields: [],
      fetchedCustomFields: apiFetchStatus.IDLE,
      savedCustomFields: apiFetchStatus.IDLE,
      stages,
      originalStages: _.cloneDeep(stages), // Used to revert deadlines when user click cancel changes
      showDeadlineDaysSaveButtons: false
    };
    let savedCopy = { ...this.state };

    this.state["savedCopy"] = savedCopy;
    this.deadlinesRef = React.createRef();
  }

  componentDidMount = () => {
    this.getCustomProjectFields();
    this.loadAuthors();
  };

  isStageEnabled = (contentStagesConfig: any, status: any) => {
    return contentStagesConfig.some((contentStage: any) =>
      contentStage.stages.some(
        (stage: any) =>
          stage.internalContentStageName === status && stage.required
      )
    );
  };

  // Get content stage deadlines from publication
  getContentStages = (contentMapEnabled: any) => {
    let { contentStages } = this.props.publication;

    let stages: any = [];
    contentStages.forEach((contentStage: any) => {
      contentStage.stages.forEach((stage: any) => {
        // If outline is disabled, do not include deadlines.
        if (
          [
            topicStatus.CONTENT_MAP_DRAFT,
            topicStatus.CONTENT_MAP_IN_REVIEW
          ].includes(stage.internalContentStageName) &&
          !contentMapEnabled
        ) {
          return;
        }

        stages.push({
          name: stage.name,
          internalContentStageName: stage.internalContentStageName,
          deadlineDays: stage.deadlineDays
        });
      });
    });

    return stages;
  };

  getCustomProjectFields = () => {
    let { publicationId } = this.state;
    this.setState({
      savedCustomFields: apiFetchStatus.FETCH
    });
    api.getProjectCustomFields(publicationId, (res: any) => {
      if (res.status === 200) {
        this.setState({
          customFields: res.data?.fields ? res.data.fields : [],
          fetchedCustomFields: apiFetchStatus.SUCCESS
        });
      } else {
        this.setState({
          fetchedCustomFields: apiFetchStatus.FAIL
        });
      }
    });
  };

  saveCustomProjectFields = (customFields: any) => {
    let { publicationId } = this.state;
    this.setState({
      savedCustomFields: apiFetchStatus.FETCH
    });
    api.saveProjectCustomFields(publicationId, customFields, (res: any) => {
      if (res.status === 200) {
        this.setState({
          customFields: res.data,
          savedCustomFields: apiFetchStatus.SUCCESS
        });
      } else {
        this.setState({
          savedCustomFields: apiFetchStatus.FAIL
        });
      }
    });
  };

  loadAuthors = () => {
    let { publicationId } = this.state;

    api.getAuthorsAndDefaultAuthor(publicationId, (res: any) => {
      if (res.status === 200) {
        this.setState({
          authors: res.data.teammatesAndAuthors,
          defaultAuthor: res.data.defaultAuthor
        });
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Oops. We had some trouble loading your data. Try again.",
          "error"
        );
      }
    });
  };

  // Set Default Author from dropdown change
  defaultAuthorChange = (defaultAuthorValue: any) => {
    let { publicationId } = this.state;
    let defaultAuthorId = defaultAuthorValue?._id;

    api.saveDefaultAuthor(publicationId, defaultAuthorId, (res: any) => {
      if (res.status === 200) {
        this.setState({
          defaultAuthor: defaultAuthorValue || null
        });
      } else {
        notify.show(
          "Oops. We had some trouble setting up the Default Author. Try again.",
          "error"
        );
      }
    });
  };

  resetContentMapTemplate = () => {
    let { publicationId } = this.state;
    api.resetContentMapTemplate(publicationId, (res: any) => {
      if (res.status === 200) {
        this.setState({ contentMapFields: res.data.contentMapFields });
        this.toggleConfirmationDialog();
      } else if (res.status === 400) {
        notify.show(res.data, "error");
      }
    });
  };

  toggleConfirmationDialog = () => {
    let { showConfirmationDialog } = this.state;
    this.setState({
      showConfirmationDialog: !showConfirmationDialog
    });
  };

  toggleDeleteConfirmation = (index: any) => {
    let { showDeleteConfirmation } = this.state;
    this.setState({
      showDeleteConfirmation: !showDeleteConfirmation,
      deleteFieldIndex: index
    });
  };

  resetStyleGuide = () => {
    let { publicationId, savedCopy } = this.state;
    api.resetStyleGuide(publicationId, (res: any) => {
      if (res.status === 200) {
        savedCopy["styleGuide"] = res.data.styleGuide;
        this.setState({ styleGuide: res.data.styleGuide, savedCopy });
        this.toggleStyleGuideResetDialog();
      } else if (res.status === 400) {
        notify.show(res.data, "error");
      }
    });
  };

  toggleStyleGuideResetDialog = () => {
    let { showStyleGuideResetDialog } = this.state;
    this.setState({
      showStyleGuideResetDialog: !showStyleGuideResetDialog
    });
  };

  generateSaveButtonForStyleGuide = (key: any) => {
    let { classes } = this.props;
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={2}
        lg={2}
        container
        justifyContent="flex-end"
        direction="row"
        className={classes.saveGrid}
      >
        <Grid item>
          <StyledButton onClick={() => this.saveStyleGuide()}>
            Save
          </StyledButton>
        </Grid>

        <Grid item style={{ marginLeft: 10 }}>
          <StyledButton
            variant="tertiary"
            onClick={() => this.revertStyleGuideChange()}
          >
            Cancel
          </StyledButton>
        </Grid>
      </Grid>
    );
  };

  revertStyleGuideChange = () => {
    let { savedCopy, unsaved } = this.state;
    delete unsaved["styleGuide"];
    this.setState({ styleGuide: savedCopy["styleGuide"], unsaved });
  };

  handleChange = (event: any) => {
    let { savedCopy, unsaved } = this.state;
    let { name, value } = event.target;

    if (savedCopy[name] !== value) {
      unsaved[name] = value;
    } else if (savedCopy[name] === value) {
      delete unsaved[name];
    }

    this.setState({ [name]: value, unsaved });
  };

  saveStyleGuide = () => {
    let { publicationId, styleGuide } = this.state;
    api.saveStyleGuide(publicationId, styleGuide, (res: any) => {
      if (res.status === 200) {
        let { savedCopy, unsaved } = this.state;
        delete unsaved["styleGuide"];
        savedCopy["styleGuide"] = res.data.styleGuide;
        this.setState({ styleGuide: savedCopy["styleGuide"], unsaved });
      } else {
        notify.show(res.data, "error");
      }
    });
  };

  toggleShowAddRow = () => {
    let { showAddRow } = this.state;
    this.setState({
      showAddRow: !showAddRow,
      editContentMapField: false,
      editFieldIndex: null
    });
  };

  showEditDialog = (index: any, value: any) => {
    let { showAddRow } = this.state;
    this.setState({
      showAddRow: !showAddRow,
      editContentMapField: true,
      editFieldIndex: index,
      type: value.type,
      instruction: value.instruction,
      label: value.label,
      placeholder: value.placeholder
    });
  };

  instructionFieldClicked = () => {
    this.setState({ type: contentMapFieldTypes.INSTRUCTION });
    this.clearDialogFiledsData();
  };

  inputFieldClicked = () => {
    this.setState({ type: contentMapFieldTypes.INPUT });
    this.clearDialogFiledsData();
  };

  handleFieldsChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  clearDialogFiledsData = () => {
    this.setState({
      instruction: "",
      label: "",
      placeholder: ""
    });
  };

  addContentMapFields = () => {
    let { type, instruction, label, placeholder, publicationId } = this.state;
    if (type === contentMapFieldTypes.INPUT && !label) {
      notify.show("Enter title to save", "error");
      return;
    }
    if (type === contentMapFieldTypes.INSTRUCTION && !instruction) {
      notify.show("Enter instruction to save", "error");
      return;
    }
    api.addContentMapFields(
      publicationId,
      type,
      instruction,
      label,
      placeholder,
      (res: any) => {
        if (res.status === 200) {
          this.setState(
            {
              contentMapFields: res.data,
              instruction: "",
              label: "",
              placeholder: ""
            },
            this.toggleShowAddRow
          );
        } else {
          notify.show("Unable to add field", "error");
        }
      }
    );
  };

  saveContentMapFields = () => {
    let {
      type,

      instruction,

      label,

      placeholder,

      contentMapFields,

      editFieldIndex,

      publicationId
    } = this.state;

    if (type === contentMapFieldTypes.INPUT && !label) {
      notify.show("Enter title to save", "error");
      return;
    }
    if (type === contentMapFieldTypes.INSTRUCTION && !instruction) {
      notify.show("Enter instruction to save", "error");
      return;
    }

    contentMapFields[editFieldIndex]["type"] = type;
    contentMapFields[editFieldIndex]["instruction"] = instruction;
    contentMapFields[editFieldIndex]["label"] = label;
    contentMapFields[editFieldIndex]["placeholder"] = placeholder;

    api.savePublicationContentMapFields(
      publicationId,
      contentMapFields,
      (res: any) => {
        if (res.status === 200) {
          this.setState(
            {
              contentMapFields: res.data,
              instruction: "",
              label: "",
              placeholder: "",
              editFieldIndex: null,
              editContentMapField: false
            },
            this.toggleShowAddRow
          );
        }
      }
    );
  };

  removeContentMapField = () => {
    let { contentMapFields, deleteFieldIndex, publicationId } = this.state;
    if (deleteFieldIndex != null) {
      contentMapFields.splice(deleteFieldIndex, 1);
      api.savePublicationContentMapFields(
        publicationId,
        contentMapFields,
        (res: any) => {
          if (res.status === 200) {
            this.setState({
              contentMapFields: contentMapFields,
              showDeleteConfirmation: false,
              deleteFieldIndex: null
            });
          }
        }
      );
    }
  };

  fieldsRearranged = (contentMapFields: any) => {
    this.setState(
      { contentMapFields: contentMapFields },
      this.savePublicationContentMapFields
    );
  };

  savePublicationContentMapFields = () => {
    let { publicationId, contentMapFields } = this.state;
    api.savePublicationContentMapFields(
      publicationId,
      contentMapFields,
      (res: any) => {}
    );
  };

  // Handling deadline change
  deadlineDaysChange = (index: any, value: any) => {
    let { stages } = this.state;
    stages[index].deadlineDays = value;
    this.setState({ stages, showDeadlineDaysSaveButtons: true });
  };

  // Validate input and save deadlines
  saveContentStagesDeadlines = () => {
    let { stages, publicationId } = this.state;

    // Validation min and max deadline days
    for (let stage of stages) {
      if (stage.deadlineDays < MIN_DEADLINE_DAYS) {
        notify.show(
          `You need at least ${MIN_DEADLINE_DAYS} day between stages.`,
          "error"
        );
        return;
      }
      if (stage.deadlineDays > MAX_DEADLINE_DAYS) {
        notify.show(
          `You can set a maximum of ${MAX_DEADLINE_DAYS} days between stages.`,
          "error"
        );
        return;
      }
    }

    api.saveContentStagesDeadlines(publicationId, stages, (res: any) => {
      if (res.status === 200) {
        this.setState({
          showDeadlineDaysSaveButtons: false,
          originalStages: _.cloneDeep(stages)
        });
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Oops. We couldn't save your change. Try again.",
          "error"
        );
      }
    });
  };

  // Clear unsaved deadline when user clicks cancel
  revertDeadlineChange = () => {
    let { originalStages } = this.state;
    this.setState({
      stages: _.cloneDeep(originalStages),
      showDeadlineDaysSaveButtons: false
    });
  };

  render() {
    let {
      classes,

      publication,

      ideaTemplates,

      getIdeaTemplates,

      deleteIdeaTemplate,

      setDefaultIdeaTemplate
    } = this.props;
    let {
      showConfirmationDialog,

      styleGuide,

      unsaved,

      showStyleGuideResetDialog,

      contentMapFields,

      showAddRow,

      type,

      instruction,

      label,

      placeholder,

      showDeleteConfirmation,

      editContentMapField,

      contentMapEnabled,

      publicationId,

      customFields,

      fetchedCustomFields,

      savedCustomFields,

      authors,

      showDeadlineDaysSaveButtons,

      stages,

      defaultAuthor
    } = this.state;

    return (
      <Container className={classes.container}>
        <Grid container direction="column">
          <Typography variant="h400" paragraph>
            Content Workflow
          </Typography>

          <Typography variant="bodym" className={classes.cardSubtitle}>
            Tailor your content workflow to your team's needs
          </Typography>

          <Grid item container xs={12} className={classes.horizontalSpacingL}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="bodyl"
                paragraph
                className={classes.subTitle}
              >
                STYLE GUIDE
              </Typography>

              <Typography
                variant="bodym"
                paragraph
                className={classes.subtitleDescription}
              >
                A Style Guide helps writers understand your brand voice,
                preferred grammar, and writing style. We’ve provided one for
                you, but you can also copy and modify it to suit your business
              </Typography>
            </Grid>

            <Grid item xs={7} sm={7} md={7}>
              <StyledInput
                className={classes.input}
                type="text"
                size="medium"
                placeholder="Enter a link to your style guide"
                name="styleGuide"
                value={styleGuide}
                onChange={this.handleChange}
              />
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              style={{ marginLeft: 10, marginTop: 2 }}
            >
              <StyledButton
                style={{ margin: 10 }}
                variant="textsecondary"
                onClick={this.toggleStyleGuideResetDialog}
              >
                Reset
              </StyledButton>
            </Grid>
            {(unsaved["styleGuide"] || unsaved["styleGuide"] === "") &&
              this.generateSaveButtonForStyleGuide("styleGuide")}

            <StyledDialog
              open={showStyleGuideResetDialog}
              title="Are you sure?"
              body="You’re resetting your style guide to the default one provided by Letterdrop."
              successButtonName="Yes, I'm sure"
              successCallback={this.resetStyleGuide}
              cancelCallback={this.toggleStyleGuideResetDialog}
            />
          </Grid>

          <Grid item container xs={12} className={classes.horizontalSpacingL}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="body1" className={classes.subTitle}>
                DEFAULT AUTHOR
              </Typography>

              <Typography
                variant="body2"
                className={classes.subtitleDescription}
              >
                We'll use this author by default for every new blog post you
                draft.
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Autocomplete
                options={authors}
                getOptionLabel={(option) => option?.name || option?.email || ""}
                size="large"
                value={defaultAuthor}
                onChange={(event, newValue) =>
                  this.defaultAuthorChange(newValue)
                }
                renderInput={(params) => (
                  <StyledInput
                    {...params}
                    variant="outlined"
                    placeholder="Richard Hendricks"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment
                            position="start"
                            className={classes.defaultAuthorAdornment}
                          >
                            {defaultAuthor && defaultAuthor.profilePic && (
                              <Avatar
                                src={defaultAuthor.profilePic}
                                className={classes.defaultAuthorAvatar}
                              />
                            )}
                          </InputAdornment>
                        </>
                      )
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => {
                  return (
                    <Box key={option._id} {...props}>
                      <Avatar
                        src={option && option.profilePic}
                        className={classes.autompleteAvatar}
                      />

                      <Typography style={{ marginLeft: 10 }}>
                        {option.name || option.email}
                      </Typography>
                    </Box>
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} className={classes.horizontalSpacingL}>
            <Grid item xs={12} sm={12} md={12} ref={this.deadlinesRef}>
              <Typography
                variant="bodyl"
                paragraph
                className={classes.subTitle}
              >
                DEADLINES
              </Typography>

              <Typography
                variant="bodym"
                paragraph
                className={classes.subtitleDescription}
              >
                Set default number of business days to give writers before
                publishing.
              </Typography>
            </Grid>

            <div style={{ width: "100%" }}>
              {stages.map((stage: any, index: any) => {
                if (
                  DEADLINES_NOT_REQUIRED.includes(
                    stage.internalContentStageName
                  )
                ) {
                  return "";
                }
                return (
                  <div style={{ marginBottom: 16 }}>
                    <Typography style={{ marginBottom: 8 }}>
                      {stage.name}
                    </Typography>

                    <StyledInput
                      className={classes.input}
                      type="number"
                      size="medium"
                      placeholder="2"
                      style={{ width: "50%" }}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      value={stage.deadlineDays}
                      onChange={(event: any) => {
                        this.deadlineDaysChange(index, event.target.value);
                      }}
                    />
                  </div>
                );
              })}
              {showDeadlineDaysSaveButtons && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "auto 12px",
                    justifyContent: "flex-end"
                  }}
                >
                  <StyledButton
                    onClick={this.saveContentStagesDeadlines}
                    size="medium"
                  >
                    Save
                  </StyledButton>

                  <div style={{ marginLeft: 10 }}>
                    <StyledButton
                      variant="tertiary"
                      size="medium"
                      onClick={this.revertDeadlineChange}
                    >
                      Cancel
                    </StyledButton>
                  </div>
                </div>
              )}
            </div>

            <StyledDialog
              open={showStyleGuideResetDialog}
              title="Are you sure?"
              body="You’re resetting your style guide to the default one provided by Letterdrop."
              successButtonName="Yes, I'm sure"
              successCallback={this.resetStyleGuide}
              cancelCallback={this.toggleStyleGuideResetDialog}
            />
          </Grid>

          <Grid item className={classes.horizontalSpacingL}>
            <Typography variant="bodyl" paragraph className={classes.subTitle}>
              OUTLINE
            </Typography>

            <Typography
              variant="bodym"
              paragraph
              className={classes.subtitleDescription}
            >
              Add instructions and fields for writers to fill out in your
              outline.
            </Typography>
          </Grid>
          {contentMapEnabled && (
            <>
              <Grid item>
                <hr color={colors.horizontalRule}></hr>
              </Grid>

              <List
                values={contentMapFields}
                onChange={({ oldIndex, newIndex }) =>
                  this.fieldsRearranged(
                    arrayMove(contentMapFields, oldIndex, newIndex)
                  )
                }
                renderList={({ children, props }) => (
                  <div {...props}>{children}</div>
                )}
                renderItem={({ value, index, props }) => {
                  return (
                    <Grid
                      item
                      container
                      direction="row"
                      key={value._id}
                      className={classes.marginTop30}
                      {...props}
                    >
                      <>
                        <Grid item xs={2} container direction="row">
                          <DragIndicatorIcon className={classes.draggable} />

                          <span>
                            {value.type === contentMapFieldTypes.INSTRUCTION ? (
                              <StyledChip label="Instruction" variant="blue" />
                            ) : (
                              <StyledChip variant="green" label="Input" />
                            )}
                          </span>
                        </Grid>

                        <Grid item xs={8}>
                          {value.type === contentMapFieldTypes.INSTRUCTION ? (
                            <Typography variant="bodym" paragraph>
                              {value.instruction}
                            </Typography>
                          ) : (
                            <>
                              <Grid item>
                                <Typography variant="bodym" paragraph>
                                  {value.label}
                                </Typography>
                              </Grid>

                              <Grid item className={classes.marginTop10}>
                                <StyledInput
                                  className={classes.width100}
                                  size="medium"
                                  placeholder={value.placeholder}
                                  disabled
                                  multiline
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={2}
                          className={classes.closeButtonContainer}
                        >
                          <Grid item>
                            <Tooltip title="Edit field">
                              <IconButton
                                onClick={() => {
                                  this.showEditDialog(index, value);
                                }}
                                size="large"
                              >
                                <EditIcon className={classes.menuIcon} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete field">
                              <IconButton
                                onClick={() => {
                                  this.toggleDeleteConfirmation(index);
                                }}
                                size="large"
                              >
                                <CloseIcon
                                  size="small"
                                  className={classes.closeIcon}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </>
                    </Grid>
                  );
                }}
              />

              <Grid item container justifyContent="space-between">
                <StyledButton
                  variant="textprimary"
                  className={classes.horizontalSpacingL}
                  startIcon={<AddCircleIcon />}
                  onClick={this.toggleShowAddRow}
                >
                  Add row
                </StyledButton>

                <StyledButton
                  className={classes.horizontalSpacingL}
                  onClick={this.toggleConfirmationDialog}
                >
                  Reset to default
                </StyledButton>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <hr color={colors.horizontalRule} />
              </Grid>
            </>
          )}

          <Grid
            item
            container
            xs={12}
            className={classes.horizontalSpacingL}
            id="project-fields"
          >
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="bodyl"
                paragraph
                className={classes.subTitle}
              >
                PROJECT FIELDS
              </Typography>

              <Typography
                variant="bodym"
                paragraph
                className={classes.subtitleDescription}
              >
                Define your own field for projects. Every time you create a new
                content idea, you can request and track these fields.
              </Typography>
            </Grid>

            <CustomFields
              publication={publication}
              customFields={customFields}
              fetchedCustomFields={fetchedCustomFields}
              savedCustomFields={savedCustomFields}
              saveCustomFields={this.saveCustomProjectFields}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: 20 }}>
            <hr color={colors.horizontalRule} />

            <div className={classes.heading} style={{ marginTop: 20 }}>
              <Typography variant="h400" paragraph>
                Idea Templates
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Create templates for your ideas and content briefs. You can
                automatically populate new ideas from a template with one click.
              </Typography>
            </div>

            <IdeaTemplates
              publication={publication}
              ideaTemplates={ideaTemplates}
              fetchedCustomFields={fetchedCustomFields}
              getIdeaTemplates={getIdeaTemplates}
              deleteIdeaTemplate={deleteIdeaTemplate}
              setDefaultIdeaTemplate={setDefaultIdeaTemplate}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: 20 }}>
            <hr color={colors.horizontalRule} />

            <ContentStages publicationId={publicationId} />
          </Grid>
        </Grid>

        <StyledDialog
          open={showAddRow}
          body={
            <>
              <Typography variant="h400" paragraph>
                {editContentMapField ? "Edit outline" : "Add row to outline"}
              </Typography>

              <Typography variant="bodym" paragraph>
                Add a set of instructions or accept a text input from the
                writer.
              </Typography>

              <div className={classes.secondaryButtons}>
                <ToggleButtonGroup value={type}>
                  <ToggleButton
                    value={contentMapFieldTypes.INSTRUCTION}
                    onClick={this.instructionFieldClicked}
                  >
                    Instruction
                  </ToggleButton>

                  <ToggleButton
                    value={contentMapFieldTypes.INPUT}
                    onClick={this.inputFieldClicked}
                  >
                    Input
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              {type === contentMapFieldTypes.INSTRUCTION && (
                <div className={classes.dialogBody}>
                  <Typography variant="bodym" paragraph>
                    Instruction
                  </Typography>

                  <StyledInput
                    autoFocus
                    size="medium"
                    className={classes.instruction}
                    inputProps={{ color: colors.fontSecondary }}
                    name="instruction"
                    value={instruction}
                    onChange={this.handleFieldsChange}
                    placeholder="Our target audience is small D2C business owners without the resources to hire an engineering team to build out a full ecommerce site."
                    disableUnderline={true}
                    minRows={3}
                    multiline
                  />
                </div>
              )}
              {type === contentMapFieldTypes.INPUT && (
                <div className={classes.dialogBody}>
                  <Grid container direction="row">
                    <Grid item container xs={12}>
                      <Grid item xs={3}>
                        <Typography
                          variant="bodym"
                          paragraph
                          className={classes.marginTop8}
                        >
                          Label
                        </Typography>
                      </Grid>

                      <Grid item xs={9}>
                        <StyledInput
                          autoFocus
                          size="medium"
                          className={classes.width100}
                          name="label"
                          value={label}
                          onChange={this.handleFieldsChange}
                          placeholder="Title"
                          disableUnderline={true}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.horizontalSpacingL}
                    >
                      <Grid item xs={3}>
                        <Typography
                          variant="bodym"
                          paragraph
                          className={classes.marginTop8}
                        >
                          Placeholder
                        </Typography>
                      </Grid>

                      <Grid item xs={9}>
                        <StyledInput
                          className={classes.width100}
                          size="medium"
                          name="placeholder"
                          value={placeholder}
                          onChange={this.handleFieldsChange}
                          placeholder="Placeholder"
                          disableUnderline={true}
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}
            </>
          }
          successButtonName="Save"
          cancelCallback={this.toggleShowAddRow}
          successCallback={
            editContentMapField
              ? this.saveContentMapFields
              : this.addContentMapFields
          }
        />

        <StyledDialog
          open={showConfirmationDialog}
          title="Are you sure?"
          body="You’re resetting your outline template to the default one provided by Letterdrop."
          successButtonName="Yes, I'm sure"
          successCallback={this.resetContentMapTemplate}
          cancelCallback={this.toggleConfirmationDialog}
        />

        <StyledDialog
          open={showDeleteConfirmation}
          title="Delete field"
          body="Are you sure?"
          successButtonName="Yes, I'm sure"
          successCallback={this.removeContentMapField}
          cancelCallback={this.toggleDeleteConfirmation}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(ContentWorkflow));
