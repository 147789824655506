// @ts-nocheck
import { Link } from "react-router-dom";

export default function UnstyledLink({ style, children, ...other }: any) {
  return (
    <Link
      {...other}
      style={{
        ...style,

        display: "flex",

        textDecoration: "none",

        margin: "auto 0px",

        color: "unset"
      }}
    >
      {children}
    </Link>
  );
}
