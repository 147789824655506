// @ts-nocheck
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Container,
  Popover,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

import { TwitterPicker } from "react-color";
import StyledButton from "../design/components/StyledButton";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";

import StyledSwitch from "../design/components/StyledSwitch";
import API from "../Services/Api";
import colors from "../Themes/Colors";
import { paletteColors } from "../Utils/Types";
import { addBlockquoteStyle } from "../Utils/UserUtils";
import { notify } from "./CustomNotifications";

import PaidFeatureCheckout from "./PaidFeatureCheckout";
import styles from "./styles/HomePageCustomizationStyle";

const api = API.create();

const DEFAULT_EMAIL_FONT = "Helvetica, sans-serif";

class HomePageCustomization extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      publicationId: props.publication._id ? props.publication._id : "",
      displayBackgroundColorPicker: false,
      displayAccentColorPicker: false,
      accentColor:
        props.publication.theme && props.publication.theme.color
          ? props.publication.theme.color
          : colors.primary,
      showAccentConfirmationDialog: false,
      emailTitleFont:
        props.publication.theme && props.publication.theme.emailTitleFont
          ? props.publication.theme.emailTitleFont
          : DEFAULT_EMAIL_FONT,
      emailBodyFont:
        props.publication.theme && props.publication.theme.emailBodyFont
          ? props.publication.theme.emailBodyFont
          : DEFAULT_EMAIL_FONT,
      showCheckout: false,
      subscribeText: props.publication.subscribeText,
      showSaveButton: false,
      isAudioPostEnabled: props.publication.isAudioPostEnabled
    };
  }

  handleAccentColorClick = () => {
    this.setState({
      displayAccentColorPicker: true
    });
  };

  handleAccentColorChange = (color: any) => {
    api.saveAccentColor(this.state.publicationId, color.hex, (res: any) => {
      if (res.status === 200) {
        addBlockquoteStyle(color.hex);

        this.setState({
          accentColor: color.hex,
          displayAccentColorPicker: false
        });
      }

      if (res.status === 400) {
        notify.show("That's a great color... but we couldn't save it", "error");
        this.setState({
          displayAccentColorPicker: false
        });
      }
    });
  };

  resetAccent = () => {
    api.saveAccentColor(this.state.publicationId, null, (res: any) => {
      if (res.status === 200) {
        this.setState({
          showAccentConfirmationDialog: false,
          accentColor: colors.primary
        });
      }

      if (res.status === 400) {
        this.cancelAccentDialog();
        notify.show("Oops, we couldn't reset your accent color", "error");
      }
    });
  };

  handleChange = (event: any) => {
    let { name, value } = event.target;

    if (value.length > 20) {
      return;
    }

    this.setState({ [name]: value, showSaveButton: true });
  };

  cancelAccentDialog = () => {
    this.setState({
      showAccentConfirmationDialog: false
    });
  };

  handleFontChange = (event: any) => {
    let { name, value } = event.target;

    api.saveFontChanges(name, value, this.state.publicationId, (res: any) => {
      if (res.status === 200) {
        this.setState({
          [name]: res.data
        });
      }
      if (res.status === 400) {
        notify.show(
          "Couldn't save your font. Contact support@letterdrop.com",
          "error"
        );
      }
    });
  };

  resetEmailFont = () => {
    api.saveFontChanges(
      "emailTitleFont",
      DEFAULT_EMAIL_FONT,

      this.state.publicationId,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            emailTitleFont: DEFAULT_EMAIL_FONT
          });
        }
        if (res.status === 400) {
          notify.show(
            "Couldn't reset your font. Contact support@letterdrop.com",
            "error"
          );
        }
      }
    );
    api.saveFontChanges(
      "emailBodyFont",
      DEFAULT_EMAIL_FONT,

      this.state.publicationId,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            emailBodyFont: DEFAULT_EMAIL_FONT
          });
        }
        if (res.status === 400) {
          notify.show(
            "Couldn't reset your font. Contact support@letterdrop.com",
            "error"
          );
        }
      }
    );
  };

  gotoCheckout = () => {
    this.setState({ showCheckout: true });
  };

  revertSubscribeText = () => {
    this.setState({
      subscribeText: this.props.publication.subscribeText,
      showSaveButton: false
    });
  };

  saveSubscribeText = () => {
    let { publicationId, subscribeText } = this.state;
    api.saveSubscribeText(publicationId, subscribeText, (res: any) => {
      if (res.status === 200) {
        this.setState({ showSaveButton: false });
      }

      if (res.status === 400) {
        notify.show("Unable to save subscribe text. Please try again", "error");
      }
    });
  };

  handleAudioPostsToggle = () => {
    let { isAudioPostEnabled, publicationId } = this.state;
    api.saveAudioPostsToggle(publicationId, !isAudioPostEnabled, (res: any) => {
      if (res.status === 200) {
        this.setState({ isAudioPostEnabled: !isAudioPostEnabled });
      }
      if (res.status === 400) {
        notify.show("Couldn't save your setting", "error");
      }
    });
  };

  render() {
    let { classes } = this.props;
    let {
      accentColor,

      displayAccentColorPicker,

      showAccentConfirmationDialog,

      emailTitleFont,

      emailBodyFont,

      showCheckout,

      publicationId,

      subscribeText,

      showSaveButton,

      isAudioPostEnabled
    } = this.state;

    return (
      <Container className={classes.container}>
        <div>
          <Typography variant="h400" paragraph>
            Customize your workspace
          </Typography>
        </div>

        <Grid container direction="row">
          <Grid item container xs={12} className={classes.row}>
            <Grid item sm={12} md={4} className={classes.marginAuto}>
              <Typography variant="bodym">Accent Color</Typography>
            </Grid>

            <Grid item sm={12} md={8} className={classes.marginAuto}>
              <Grid
                item
                container
                direction="row"
                className={classes.marginAuto}
              >
                <div
                  className={classes.color}
                  style={{
                    background: accentColor
                  }}
                  ref="accent"
                  onClick={this.handleAccentColorClick}
                />

                <Popover
                  id="color"
                  open={displayAccentColorPicker}
                  onClose={() =>
                    this.setState({
                      displayBackgroundColorPicker: false
                    })
                  }
                  anchorEl={this.refs.accent}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                  style={{ marginTop: 20 }}
                >
                  <TwitterPicker
                    color={accentColor}
                    onChange={this.handleAccentColorChange}
                    colors={paletteColors}
                  />
                </Popover>

                <span className={classes.hexColorCode}>{accentColor}</span>

                <StyledButton
                  variant="textsecondary"
                  className={clsx(classes.resetButton)}
                  onClick={() =>
                    this.setState({ showAccentConfirmationDialog: true })
                  }
                >
                  Reset color to default
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} className={classes.row}>
            <Grid item sm={12} md={4} className={classes.marginAuto}>
              <Typography variant="bodym">Choose your email font</Typography>
            </Grid>

            <Grid item xs={4} sm={4} md={8} className={classes.marginAuto}>
              <Grid
                container
                direction="row"
                className={classes.marginAuto}
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={5}>
                  <Typography variant="bodym" className={classes.marginAuto}>
                    Title
                  </Typography>

                  <FormControl
                    variant="outlined"
                    size="small"
                    className={clsx(classes.width100, classes.input)}
                    margin="dense"
                  >
                    <StyledSelect
                      name="emailTitleFont"
                      value={emailTitleFont}
                      onChange={this.handleFontChange}
                      className={clsx(classes.input)}
                      style={{ fontFamily: `${emailTitleFont}` }}
                    >
                      <StyledMenuItem aria-label="None" value="" />

                      <StyledMenuItem
                        value={"Arial, sans-serif"}
                        style={{
                          fontFamily: "Arial, sans-serif",
                          fontSize: 18
                        }}
                      >
                        Arial
                      </StyledMenuItem>

                      <StyledMenuItem
                        value={"Georgia, serif"}
                        style={{ fontFamily: "Georgia, serif", fontSize: 18 }}
                      >
                        Georgia
                      </StyledMenuItem>

                      <StyledMenuItem
                        value={"Helvetica, sans-serif"}
                        style={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: 18
                        }}
                      >
                        Helvetica
                      </StyledMenuItem>

                      <StyledMenuItem
                        value={"Times New Roman, Times, serif"}
                        style={{
                          fontFamily: "Times New Roman, Times, serif",
                          fontSize: 18
                        }}
                      >
                        Times New Roman
                      </StyledMenuItem>

                      <StyledMenuItem
                        value={"Verdana, sans-serif"}
                        style={{
                          fontFamily: "Verdana, sans-serif",
                          fontSize: 18
                        }}
                      >
                        Verdana
                      </StyledMenuItem>
                    </StyledSelect>

                    <FormHelperText style={{ margin: 0 }}>
                      We'll use this for titles in your emails
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  className={classes.marginAuto}
                >
                  <Typography variant="bodym" className={classes.marginAuto}>
                    Body
                  </Typography>

                  <FormControl
                    variant="outlined"
                    size="small"
                    className={clsx(classes.width100, classes.input)}
                    margin="dense"
                  >
                    <StyledSelect
                      name="emailBodyFont"
                      value={emailBodyFont}
                      onChange={this.handleFontChange}
                      className={clsx(classes.input)}
                      style={{ fontFamily: `${emailBodyFont}` }}
                    >
                      <StyledMenuItem aria-label="None" value="" />

                      <StyledMenuItem
                        value={"Arial, sans-serif"}
                        style={{
                          fontFamily: "Arial, sans-serif",
                          fontSize: 18
                        }}
                      >
                        Arial
                      </StyledMenuItem>

                      <StyledMenuItem
                        value={"Georgia, serif"}
                        style={{ fontFamily: "Georgia, serif", fontSize: 18 }}
                      >
                        Georgia
                      </StyledMenuItem>

                      <StyledMenuItem
                        value={"Helvetica, sans-serif"}
                        style={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: 18
                        }}
                      >
                        Helvetica
                      </StyledMenuItem>

                      <StyledMenuItem
                        value={"Times New Roman, Times, serif"}
                        style={{
                          fontFamily: "Times New Roman, Times, serif",
                          fontSize: 18
                        }}
                      >
                        Times New Roman
                      </StyledMenuItem>

                      <StyledMenuItem
                        value={"Verdana, sans-serif"}
                        style={{
                          fontFamily: "Verdana, sans-serif",
                          fontSize: 18
                        }}
                      >
                        Verdana
                      </StyledMenuItem>
                    </StyledSelect>

                    <FormHelperText style={{ margin: 0 }}>
                      We'll use this for the main body in your emails
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <StyledButton
                    variant="textsecondary"
                    onClick={this.resetEmailFont}
                    className={classes.emailFontResetButton}
                  >
                    Reset
                  </StyledButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} className={classes.row}>
            <Grid item container xs={12} className={classes.row}>
              <Grid item sm={6} md={4} className={classes.marginAuto}>
                <Typography variant="bodym">Subscribe button text</Typography>
              </Grid>

              <Grid item sm={6} md={4} className={classes.marginAuto}>
                <div className={classes.input}>
                  <StyledInput
                    value={subscribeText}
                    onChange={this.handleChange}
                    name="subscribeText"
                    size="medium"
                    fullWidth
                  />
                </div>
              </Grid>
              {showSaveButton && (
                <Grid
                  item
                  xs={4}
                  container
                  justifyContent="flex-end"
                  spacing={1}
                  direction="row"
                  className={classes.saveGrid}
                >
                  <Grid item>
                    <StyledButton onClick={this.saveSubscribeText}>
                      Save
                    </StyledButton>
                  </Grid>

                  <Grid item>
                    <StyledButton
                      variant="tertiary"
                      onClick={this.revertSubscribeText}
                    >
                      Cancel
                    </StyledButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12} className={classes.row}>
            <Grid item sm={6} md={4} className={classes.marginAuto}>
              <Grid item container direction="row" alignItems="center">
                <Grid item>
                  <Typography variant="bodym">Audio posts</Typography>
                </Grid>
              </Grid>

              <FormHelperText style={{ paddingRight: 10 }}>
                Add AI generated audio narration to your posts
              </FormHelperText>
            </Grid>

            <Grid item sm={6} md={8} className={classes.marginAuto}>
              <div className={clsx(classes.input, classes.marginLeft10)}>
                <FormControlLabel
                  control={
                    <StyledSwitch
                      checked={isAudioPostEnabled}
                      onChange={this.handleAudioPostsToggle}
                    />
                  }
                  label=""
                />
              </div>
            </Grid>
          </Grid>

          <StyledDialog
            open={showAccentConfirmationDialog}
            title="Are you sure?"
            body="Reset your button accent color to a trustworthy blue. Scientifically proven to calm the nerves and make people want to give you their email. I'm not kidding."
            successButtonName="Yes"
            successCallback={this.resetAccent}
            cancelCallback={this.cancelAccentDialog}
          />
        </Grid>

        {showCheckout && <PaidFeatureCheckout publicationId={publicationId} />}
      </Container>
    );
  }
}

export default withStyles(styles)(HomePageCustomization);
