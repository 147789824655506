// @ts-nocheck
import { amber, grey } from "@mui/material/colors";
import { shadow, space } from "../../Config/theme";
import colors, { designColors } from "../../Themes/Colors";

const styles = (theme: any) => ({
  section: {
    padding: "30px 0px"
  },

  sectionHeading: {
    fontWeight: "900",
    fontSize: 16,
    fontFamily: "Poppins"
  },

  sectionHelper: {
    color: colors.fontSecondary,
    marginBottom: space.MEDIUM,
    fontSize: 14,
    fontFamily: "Poppins"
  },

  accountDropDown: {
    display: "block",
    width: "100%"
  },

  paper: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    boxShadow: "none",
    padding: "20px 0px"
  },

  previewPaper: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    boxShadow: shadow.MEDIUM,
    padding: "16px 0px",
    marginTop: 30
  },
  previewPaperSubSection: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    boxShadow: shadow.MEDIUM,
    padding: space.MEDIUM,
    marginTop: space.L,
    width: "min-content"
  },

  externalSharePaper: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderColor: grey[300],
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 10,
    marginTop: space.SMALL,
    paddingTop: 10,
    overflow: "hidden",
    width: "100%"
  },

  reducedPaperMargin: {
    boxShadow: "none"
  },

  headerBar: {
    width: "100%",
    marginBottom: space.SMALL,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  header: {
    width: "100%",
    marginBottom: space.SMALL,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },

  draftDiv: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 10
    }
  },

  linkedInDraft: {
    position: "absolute"
  },

  headerText: {
    fontWeight: "bold",
    color: colors.black,
    margin: "5px 10px auto 10px"
  },

  accountSection: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 50
  },

  mentionText: {
    color: colors.primary,
    textDecoration: "none"
  },

  urlContainer: {
    display: "flex",
    marginBottom: space.SMALL,
    marginTop: space.SMALL,
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },

  postUrl: {
    backgroundColor: grey[100],
    color: colors.fontSecondary,
    borderRadius: 5,
    padding: 10,
    paddingRight: 3,
    fontFamily: "Inconsolata",
    width: "100%",
    minHeight: 50,
    fontSize: 18,
    marginTop: 10
  },

  anotherAccount: {
    padding: "30px 0px"
  },

  avatar: {
    width: 30,
    height: 30
  },

  avatarAutocomplete: {
    display: "flex"
  },

  itemName: {
    marginLeft: 10,
    display: "inline-flex",
    verticalAlign: "bottom",
    margin: "10px"
  },

  threadImageContainer: {
    width: "fit-content",
    position: "relative",
    marginTop: 20,
    paddingRight: 20,
    marginLeft: 10
  },

  threadImage: {
    width: "100%",
    objectFit: "cover"
  },

  closeIcon: {
    position: "absolute",
    top: -15,
    right: 5,
    background: colors.primary,
    color: colors.white,
    padding: 4,
    "&:hover": {
      backgroundColor: colors.primary,
      color: colors.white
    }
  },

  connectAccountImage: {
    width: 250,
    height: 250,
    marginBottom: 50
  },

  charCount: {
    textAlign: "right",
    color: colors.fontSecondary,
    marginTop: space.XXS,
    fontSize: 14
  },

  marginAuto: {
    margin: "auto"
  },

  connectMessage: {
    color: colors.fontSecondary,
    margin: "auto",
    marginBottom: 15
  },

  linkedInButtonIcon: {
    paddingLeft: 5,
    margin: "auto 0px",
    color: colors.white
  },

  headerIcon: {
    color: colors.linkedin,
    fontSize: 26
  },

  postButtonSection: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 10
    }
  },

  postButton: {
    position: "absolute",
    right: 5,
    color: colors.white
  },

  marginRight10: {
    marginRight: 10
  },

  menuItem: {
    width: "100%"
  },

  mentions: {
    color: colors.primary
  },

  hashtags: {
    color: colors.red300,
    padding: "0px 2px",
    backgroundColor: colors.hover
  },

  contentBody: {
    display: "block"
  },

  imagePickerIcon: {
    color: colors.fontSecondary
  },

  comments: {
    display: "block"
  },

  scheduleDropDown: {
    width: "100%"
  },

  resetIcon: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: space.SMALL
  },

  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    marginTop: 0,
    padding: 0,
    width: "100%",
    color: colors.fontPrimary,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontPrimary
    }
  },

  textAreaOutline: {
    width: "100%",
    resize: "vertical",
    display: "block",
    marginTop: space.SMALL
  },

  editableLabel: {
    color: colors.fontSecondary,
    fontSize: 14
  },

  editableLabelDiv: {
    padding: "20px 20px 20px 0px"
  },

  getApproveButton: {
    position: "absolute",
    right: 165
  },

  previewCommentorProfileText: {
    color: grey[600],
    fontWeight: "normal"
  },

  previewProfileText: {
    color: grey[600],
    fontWeight: "normal",
    marginLeft: space.SMALL
  },

  item: {
    width: "100%",
    display: "flex",
    marginTop: space.SMALL
  },

  repostItem: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 10
  },

  commentSection: {
    display: "flex",
    flexDirection: "column",
    marginLeft: space.SMALL,
    background: colors.linkedInCommentBackground,
    padding: 15,
    borderRadius: 5,
    width: "80%"
  },

  commentPreviewSection: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    background: colors.linkedInCommentBackground,
    padding: 15,
    borderRadius: 5
  },

  profileSection: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: `0px ${space.MEDIUM}`
  },

  previewTextImageCollapsed: {
    fontSize: "14px",
    position: "relative",
    width: "508px",
    maxHeight: "64px",
    lineHeight: "21px",
    overflow: "hidden",
    wordBreak: "break-word",
    fontFamily:
      "-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif"
  },

  previewTextCollpased: {
    fontSize: "14px",
    width: "508px",
    position: "relative",
    maxHeight: "106px",
    lineHeight: "21px",
    overflow: "hidden",
    wordBreak: "break-word",
    fontFamily: "sans-serif"
  },

  previewText: {
    fontSize: "14px",
    position: "relative",
    width: "508px",
    wordBreak: "break-word",
    fontFamily: "sans-serif"
  },

  previewCommentText: {
    fontSize: "14px",
    width: "436px",
    wordBreak: "break-word",
    fontFamily: "sans-serif"
  },

  expandDiv: {
    float: "right",
    paddingLeft: space.XS,
    background: colors.white,
    position: "absolute",
    bottom: 0,
    right: 3,
    color: colors.linkedInSeeMore,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      color: colors.linkedInSeeMoreHover
    }
  },

  collapseDiv: {
    float: "right",
    background: colors.white,
    color: colors.linkedInSeeMore,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      color: colors.linkedInSeeMoreHover
    }
  },

  isCollapsedAlert: {
    color: amber[500],
    fontSize: 12
  },

  alertAction: {
    "& .MuiAlert-action": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    }
  },
  copyPost: {
    backgroundColor: grey[100],
    color: colors.fontSecondary,
    borderRadius: 6,
    padding: space.SMALL,
    width: "100%"
  },
  suggestion: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 4,
    padding: space.SMALL,
    background: "unset"
  },
  suggestionHover: {
    "&:hover": {
      background: `${designColors.grayScale[0]} !important`,
      cursor: "pointer"
    }
  }
});

export default styles;
