// @ts-nocheck
import {
  blue,
  grey,
  green,
  red,
  amber,
  lightGreen
} from "@mui/material/colors";

export const designColors = {
  white: "#FFFFFF",
  primary: {
    100: "#2C27FE",
    50: "#4160FF",
    20: "#5B96FF",
    15: "#8D95FF",
    10: "#A2DAFF",
    5: "#CFF6FF",
    0: "#EEF3FF" //#E5FDFF
  },
  grayScale: {
    100: "#1A1D1F",
    80: "#50505E",
    60: "#838391",
    40: "#BEBEC5",
    10: "#D9D9DD",
    5: "#EAEAEB",
    0: "#F2F2F3",
    select: "#F4F4F4"
  },
  success: {
    100: "#116942",
    50: "#1BA769",
    5: "#DEF2E9"
  },
  warning: {
    100: "#A64F21",
    50: "#E86825",
    5: "#FFE1BE"
  },
  alert: {
    100: "#9F1B1F",
    50: "#DE1C22",
    5: "#FFE0E1"
  },
  graphPrimary: {
    100: "#2C27FE",
    60: "#807DFE",
    40: "#B2A7F5",
    10: "#D5D4FF",
    0: "#EEF3FF"
  }
};

const colors = {
  primary: "#0F79FE",
  darkModePrimary: "#80D8FF",
  secondary: "#212121",
  card: "#FFF",
  backgroundColor: "#FFF",
  darkerBackgroundColor: "#FCFDFF",
  inputBackground: "#FFF",
  inputFocusBackground: "#FFF",
  formBackground: "#f5f7ff",
  fontPrimary: "#0C293A",
  fontSecondary: grey[600],
  fontInput: grey[800],
  link: "#358cba",
  white: "#FFFFFF",
  seoHeading: "#202532",
  usageDescription: "#6F767E",
  hover: grey[50],
  selectedItem: blue[400],
  selectHover: blue[50],
  black: "#000000",
  graphAxisStroke: "#bdbdbd",
  linkedin: "#2866C2",
  facebook: "#4267B2",
  messenger: "#0078FF",
  telegram: "#0088cc",
  whatsapp: "#25D366",
  hackerNews: "#ff6600",
  twitter: "#1DA1F2",
  donate: "#37D67A",
  reddit: "#FF4500",
  notion: "#1DA1F2",
  discord: "#7289DA",
  github: "#24292e",
  spotify: "#1DB954",
  youtube: "#FF0000",
  instagram: "#C13584",
  slack: "#ECB22E",
  hashnode: "#2962FF",
  email: "#448AFF",
  redTheme: "#F47373",
  blueTheme: "#40c4ff",
  greenTheme: "#37D67A",
  orangeTheme: "#ff9800",
  yellowTheme: "#fdd835",
  pinkTheme: "#f06292",
  purpleTheme: "#ab47bc",
  whiteTheme: "#f5f5f5",
  defaultImageTitleColor: "#080F36",
  dimGrey: "#646464",
  defaultImageFontColor: "#080F36",
  defaultImageBackgroundColor: "#F0EDE2",
  defaultImageFooterColor: "#FFFFFF",
  defaultImageFooterFontColor: "#000000",
  imageBackgroundColor1: "#282828",
  imageFooterFontColor1: "#080F36",
  imageBackgroundColor2: "#DDDEF5",
  imageFontColor2: "#030A2C",
  imageBackgroundColor3: "#182432",
  imageFontColor3: "#CFEB27",
  imageBackgroundColor4: "#F4F4F4",
  imageFontColor4: "#152238",
  mapStrokeColor: "#E3E3E3",
  mapFillColor: "#EAEAEC",
  lightGreen: "#DAF2BD",
  darkGreen: "#6EAF1F",
  successGreen: green[500],
  failureRed: red[600],
  warningAmber: amber[800],
  darkWarning: amber[900],
  warningRed: "#7F0000",
  queryBuilderBackgroundColor: "#FAFAFA",
  queryBuilderBorderColor: "#BDBDBD",
  planSelectBackgroundColor: "#F8F8F8",
  subtitle: "#757575",
  overlay: "#0000004d",
  trendingUpGreen: "#36CC96",
  trendingDownRed: "#EC6969",
  overviewBackground: "#F7FAFC",
  dashboardBlue: "#007AFF",
  newPercentage: "#36CC96",
  negativePercentage: "#F44336",
  percentageBackground: "#E4FFD7",
  negativePercentageBackground: "#FFF1F1",
  dashboardBorder: "#BEBEBE",
  writeIconBackground: "#EDF7FF",
  writeIconFont: "#7EBEF2",
  darkRed: "#F44336",
  reviewIconFont: "#E38C8C",
  reviewBackgroundColor: "#FFF6F6",
  amberChipFont: amber[700],
  amberChipBackground: amber[50],
  greenChipFont: green[700],
  greenChipBackground: green[50],
  disabled: "#00000042",
  red300: red[300],
  iconGrey: "#5F6368",
  circularBarColor: "#78DC49",
  horizontalRule: "#E3E3E3",
  errorBackground: red[50],
  oldDataGraph: "#B2A7F5",
  graphArea: "#D7EAFF",
  comment: "#7D7D7D",
  lowEngagment: "#ABA9FF",
  mediumEngagement: "#807DFE",
  highEngagement: "#4160FF",
  noEngagement: "#D5D4FF",
  nonPostEvent: amber[700],
  lineGraph: "#7ECCFE",
  hubspot: "#F2795A",
  codePink: "#E01E5A",
  lowScore: grey[400],
  unreadBG: green[400],
  unreadActionBG: lightGreen[50],
  unreadText: lightGreen[600],
  tweetSplitter: "#F48C9F",
  inActive: "#C4C4C4",
  salesforce: "#48a1e0",
  textAreaOutline: "rgba(0, 0, 0, 0.23)",
  contentMapTextField: "#cccecf",
  loom: "#625df5",
  helperText: "#d32f2f",
  alertBlue: "#E5F6FD",
  linkedInCommentBackground: "#F2F2F2",
  linkedInEmbedBackground: "#EEF3F8",
  linkedInSeeMore: "#8c8c8c",
  linkedInSeeMoreHover: "#0a66c2",
  //content readability stylesheet
  longSentenceBackground: "#F5F5AB",
  veryLongSentenceBackground: "#FFDDDD",
  grammarMistakeBorder: "#0099FF",
  spellingMistakeBorder: "#FF0000",
  passiveVoiceBorder: "#0000CC",
  adverbBorder: "#CC9900",
  clicheBorder: "#00CC00",
  paperBorder: "#0000001f",

  //Research Search Results generator
  h2TagBackground: "#0F79FE",
  h3TagBackground: "#5ca9fa",
  h4TagBackground: "#91c6ff",

  //Mui Theme
  buttonText: "#000000de",
  buttonHoverBG: "#0000000a",
  buttonBorder: "#0000003b",
  primaryButtonBorder: "#0f79fe80",
  primaryButtonBG: "#0f79fe0a",
  buttonContainerBG: "#e0e0e0",
  buttonContainerHoverBG: "#d5d5d5",

  // StyledDialog
  dialogActionShadow: "#E4E5E7",
  dialogGridShadow: "#E1E3E5",

  // Thumbnail preview in Post Review Page
  thumbnailDescriptionFontColor: "#0000008a",
  googleThumbnailDescriptionFontColor: "#4d5156",
  googleThumbnailTitleFontColor: "#1a0dab",
  googleThumbnailURLFontColor: "#5f6368",

  textHighlight: "#443CB3",
  iconColor: "#0000008a",
  coverPhotoBackground: "#F5F7FE",
  disabledBorder: "#F2F2F3",
  disabledAIIcon: "#D5D4FF",
  keywordSuggestionHeader: "#949494",
  statsDatePickerOutlineBorder: "#ddd",
  statsDatePickerOutlineColor: "#555",
  arcadeDarkBlack: "#111827",
  arcadeGray: "#e5e7eb",
  arcadeLightGray: "#E8E8E8",
  arcadeDarkGray: "#6b7280",
  arcadeDarkWhite: "#f9fafb",
  arcadeSuccessGreen: "#1f9850",
  arcadeLightGreen: "#dbf5e6",
  arcadeDarkGreen: "#b8e9cc",

  // Post Review Page - keyword coverage - term chips
  orangeTermChipBackground: "#feebc8",
  orangeTermChipFont: "#7b341e",
  orangeTermChipCountBackground: "#fffaf0",
  redTermChipBackground: "#fff5f5",
  redTermChipFont: "#822727",
  redTermChipCountBackground: "#fff",
  greenTermChipBackground: "#c6f6d5",
  greenTermChipFont: "#22543d",
  greenTermChipCountBackground: "#f0fff4",

  userName: "#6F767E"
};

export default colors;
