// @ts-nocheck
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  Grid,
  IconButton,
  Container,
  Tooltip,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import React, { Component, Fragment } from "react";

import { withRouter } from "react-router";

import isURL from "validator/lib/isURL";
import StyledButton from "../design/components/StyledButton";

import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import Api from "../Services/Api";
import { socialLinkType } from "../Utils/Types";
import { notify } from "./CustomNotifications";
import styles from "./styles/SocialLinksStyle";

const api = Api.create();

class SocialLinks extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      publication: { ...this.props.publication },
      socialLinks: [],

      publicationId: this.props.publication._id,
      unsaved: false
    };
  }

  componentDidMount = () => {
    let { socialLinks, publication } = this.state;
    if (publication.socialLinks) {
      publication.socialLinks.map((socialLink: any) => {
        socialLinks.push({ ...socialLink });
      });
    }
    this.setState({
      socialLinks: socialLinks
    });
  };

  handleAddSocial = () => {
    let { socialLinks } = this.state;
    for (let socialLink of socialLinks) {
      if (!socialLink.url) {
        notify.show(`Enter url for ${socialLink.type}`, "error");
        return;
      }
    }
    socialLinks.push({ url: "", type: socialLinkType.DISCORD });
    this.setState({
      socialLinks,
      unsaved: true
    });
  };

  deleteSocialLink = (index: any) => {
    let { socialLinks } = this.state;
    socialLinks.splice(index, 1);
    this.setState({
      socialLinks,
      unsaved: true
    });
  };

  handleLinkChange = (event: any, index: any) => {
    let { socialLinks } = this.state;
    socialLinks[index].url = event.target.value.trim();
    this.setState({
      socialLinks,
      unsaved: true
    });
  };

  handleSocialLinkTypeChange = (event: any, index: any) => {
    let { socialLinks } = this.state;
    socialLinks[index].type = event.target.value;
    this.setState({
      socialLinks,
      unsaved: true
    });
  };

  saveSocialLinks = () => {
    let { socialLinks, publicationId } = this.state;
    let newSocialLinks: any = [];
    for (let socialLink of socialLinks) {
      if (!socialLink.url) {
        notify.show(`Enter a link for ${socialLink.type}`, "error");
        return;
      } else if (!isURL(socialLink.url, { require_protocol: true })) {
        notify.show("That doesn't look like a valid URL", "error");
        return;
      }
      if (!newSocialLinks.includes(socialLink.type)) {
        newSocialLinks.push(socialLink.type);
      } else {
        notify.show(
          `Looks like you have 2 links for ${socialLink.type}`,
          "error"
        );
        return;
      }
    }
    api.saveSocialLinks(publicationId, socialLinks, (res: any) => {
      if (res.status === 200) {
        let socialLinks: any = [];
        if (res.data.socialLinks) {
          res.data.socialLinks.map((socialLink: any) => {
            socialLinks.push({ ...socialLink });
          });
        }
        this.setState({
          publication: res.data,
          socialLinks: socialLinks,
          unsaved: false
        });
      } else {
        notify.show(res.data, "error");
      }
    });
  };

  cancelChanges = () => {
    let { publication } = this.state;
    let socialLinks: any = [];
    if (publication.socialLinks) {
      publication.socialLinks.map((socialLink: any) => {
        socialLinks.push({ ...socialLink });
      });
    }
    this.setState({
      socialLinks,
      unsaved: false
    });
  };

  generateSaveButton = () => {
    let { classes } = this.props;
    return (
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        container
        justifyContent="flex-end"
        spacing={1}
        direction="row"
        className={classes.saveGrid}
      >
        <Grid item>
          <StyledButton onClick={this.saveSocialLinks}>Save</StyledButton>
        </Grid>

        <Grid item>
          <StyledButton variant="tertiary" onClick={() => this.cancelChanges()}>
            Cancel
          </StyledButton>
        </Grid>
      </Grid>
    );
  };

  render() {
    let { classes } = this.props;

    let { socialLinks, unsaved } = this.state;
    return (
      <Fragment>
        <Container className={classes.container}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h400" paragraph>
                Social Links
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="bodym"
                paragraph
                className={classes.cardSubtitle}
              >
                Add links to your social media on your main page and email
                footer
              </Typography>
            </Grid>
            {socialLinks.map((socialLink: any, index: any) => {
              return (
                <Grid
                  container
                  direction="row"
                  className={classes.marginTop20}
                  key={index}
                >
                  <Grid
                    item
                    className={clsx(classes.width60, classes.justifyContent)}
                  >
                    <StyledInput
                      size="medium"
                      name="formUrl"
                      placeholder="https://letterdrop.com"
                      value={socialLink.url}
                      className={classes.width100}
                      onChange={(event: any) =>
                        this.handleLinkChange(event, index)
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    className={clsx(classes.select, classes.justifyContent)}
                  >
                    <FormControl
                      variant="outlined"
                      size="small"
                      className={clsx(classes.width100, classes.input)}
                    >
                      <StyledSelect
                        value={socialLink.type}
                        name="socialLinkType"
                        onChange={(event: any) =>
                          this.handleSocialLinkTypeChange(event, index)
                        }
                        className={clsx(classes.input)}
                      >
                        {Object.keys(socialLinkType).map((key, index) => (
                          <StyledMenuItem
                            value={socialLinkType[key]}
                            key={index}
                          >
                            {socialLinkType[key]}
                          </StyledMenuItem>
                        ))}
                      </StyledSelect>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    className={clsx(classes.justifyContent, classes.delete)}
                  >
                    <span className={classes.justifyContent}>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => this.deleteSocialLink(index)}
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </Grid>
                </Grid>
              );
            })}

            <Grid
              item
              container
              direction="row"
              className={clsx(classes.marginTop20, classes.justifyContent)}
            >
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <StyledButton
                  variant="textprimary"
                  className={classes.paddingLeft10}
                  onClick={this.handleAddSocial}
                  startIcon={<AddCircleIcon />}
                >
                  Add link
                </StyledButton>
              </Grid>
              {unsaved && this.generateSaveButton()}
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(SocialLinks));
