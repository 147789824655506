// @ts-nocheck
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import qs from "query-string";
import { CardMedia, Dialog } from "@mui/material";

function DemoVideoDialog({ location }: any) {
  const [demoVideo, setDemoVideo] = useState("");

  useEffect(() => {
    let qsParse = qs.parse(location.search);

    if (qsParse.demo) {
      setDemoVideo(qsParse.demo);
    }
  }, []);

  if (!demoVideo) {
    return null;
  }

  return (
    <Dialog
      open={Boolean(demoVideo)}
      onClose={() => {
        setDemoVideo("");
      }}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div
        style={{
          position: "relative",
          paddingBottom: "calc(56.25% + 41px)",
          height: "600px",
          width: "1200px"
        }}
      >
        <iframe
          src={`${demoVideo}?embed`}
          frameborder="0"
          loading="lazy"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            colorScheme: "light"
          }}
          title="Arcade Demo"
        ></iframe>
      </div>

      <div
        style={{
          height: 16,
          width: 16,
          color: "#fff",
          position: "fixed",
          right: 50,
          top: 30
        }}
      >
        <CloseIcon
          onClick={() => {
            setDemoVideo("");
          }}
        />
      </div>
    </Dialog>
  );
}

export default DemoVideoDialog;
