// @ts-nocheck
import colors, { designColors } from "../../Themes/Colors";
import { grey } from "@mui/material/colors";
import { shadow, space } from "../../Config/theme";

import { googleThumbnailPreviewDetails } from "../../Containers/Review";
import { height } from "@fortawesome/free-brands-svg-icons/faFacebook";

const styles = (theme: any) => ({
  coverPhoto: {
    width: "100%",
    objectFit: "cover",
    borderRadius: 5,
    height: "100%",
    aspectRatio: 800 / 418, //1.91
    border: `1px dashed ${designColors.primary[50]}`,
    backgroundColor: `${colors.coverPhotoBackground}`,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    [theme.breakpoints.down("md")]: {
      left: 0,
      position: "relative",
      maxHeight: 300
    },
    [theme.breakpoints.only("sm")]: {
      marginLeft: "0%",
      marginRight: "0%",
      width: "100%"
    }
  },

  thumbnailPhoto: {
    width: "100%",
    objectFit: "cover",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    height: "100%",
    aspectRatio: 800 / 418, //1.91

    [theme.breakpoints.down("md")]: {
      left: 0,
      position: "relative",
      maxHeight: 300
    },
    [theme.breakpoints.only("sm")]: {
      marginLeft: "0%",
      marginRight: "0%",
      width: "100%"
    }
  },

  coverPhotoContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    aspectRatio: 800 / 418 // 1.91
  },

  coverPhotoBG: {
    position: "absolute",
    zIndex: -1,
    top: 60,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },

  choosePhotoButton: {
    backgroundColor: grey[50],
    border: 0,
    fontWeight: "bolder",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center"
  },

  sectionTitle: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: space.XS
  },

  customFieldSection: {
    marginBottom: 20,
    flexDirection: "column",
    display: "flex",
    width: "100%"
  },

  customFieldTitle: {
    marginBottom: 8
  },

  inputField: {
    width: "100%"
  },

  input: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: "18.5px",
      paddingBottom: "14.5px "
    }
  },

  uploadOverlayContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    opacity: 1,
    width: "100%",
    objectFit: "contain"
  },

  section: {
    marginBottom: space.LARGE,
    flexDirection: "column",
    display: "flex",
    width: "100%"
  },

  tagsText: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: space.MEDIUM,
    color: colors.fontSecondary,
    fontSize: 14
  },

  postUrlText: {
    color: colors.thumbnailDescriptionFontColor,
    marginRight: 10
  },

  thumbnailPreviewUrlText: {
    color: colors.thumbnailDescriptionFontColor,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    fontSize: googleThumbnailPreviewDetails.DESCRIPTION_FONT_SIZE
  },

  thumbnailPreviewMetaTitleText: {
    textAlign: "left",
    fontWeight: "bold",
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    marginBottom: 5 // Spacing same as Google
  },

  thumbnailPreviewMetaDescriptionText: {
    textAlign: "left",
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    marginBottom: 5 // Spacing same as Google
  },

  urlContainer: {
    display: "flex",
    marginBottom: space.SMALL,
    marginTop: space.SMALL,
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },

  divider: {
    marginTop: space.LARGE
  },

  postTitle: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Poppins",
    textAlign: "left",
    marginBottom: space.XS
  },

  metaTitle: {
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: space.SMALL
  },

  googleDescriptionText: {
    width: googleThumbnailPreviewDetails.TITLE_MAX_WIDTH,
    display: "block",
    letterSpacing: "normal",
    color: colors.googleThumbnailDescriptionFontColor,
    textOverflow: "ellipsis",
    inlineSize: googleThumbnailPreviewDetails.TITLE_MAX_WIDTH,
    overflowWrap: "break-word",
    fontSize: googleThumbnailPreviewDetails.DESCRIPTION_FONT_SIZE,
    fontFamily: "arial, sans-serif"
  },

  googleTitleText: {
    width: googleThumbnailPreviewDetails.TITLE_MAX_WIDTH,
    display: "block",
    letterSpacing: "normal",
    color: colors.googleThumbnailTitleFontColor,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: googleThumbnailPreviewDetails.TITLE_FONT_SIZE,
    fontFamily: "arial, sans-serif"
  },

  metaDescriptionDiv: {
    marginBottom: space.SMALL
  },

  googlePreviewDiv: {
    alignItems: "left",
    padding: space.SMALL
  },

  positionRelative: {
    position: "relative"
  },

  imageBorder: {
    border: "1px solid #DCDCDC",
    borderRadius: 10,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)"
  },

  padding10: {
    padding: 10
  },

  thumbnailPreviewInfo: {
    padding: 10,
    background: grey[100],
    overflowWrap: "break-word"
  },

  warningContainer: {
    display: "flex",
    margin: "10px 0px"
  },

  warningIcon: {
    color: colors.darkWarning
  },

  warningText: {
    fontSize: 14,
    margin: "auto 10px"
  },

  checkIcon: {
    color: colors.successGreen
  },

  checkText: {
    fontSize: 14,
    margin: "auto 10px"
  },

  previewButton: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },

  thumbnailPreviewButton: {
    width: "100%",
    display: "flex",
    marginBottom: space.MEDIUM
  },

  imageContainer: {
    display: "inline-flex",
    position: "relative",
    marginTop: space.MEDIUM,
    paddingRight: space.MEDIUM,
    width: 200,
    height: 100
  },

  imageFit: {
    width: "100%",
    objectFit: "cover"
  },

  imageIconContainer: {
    background: "white",
    padding: 0,
    marginTop: space.XS
  },

  closeIcon: {
    position: "absolute",
    top: -15,
    right: 5,
    background: colors.primary,
    color: colors.white,
    padding: space.XXS,
    "&:hover": {
      backgroundColor: colors.primary,
      color: colors.white
    }
  },

  font14: {
    fontSize: 14,
    color: colors.fontSecondary
  },

  metaDescriptionPreview: {
    backgroundColor: grey[100],
    color: colors.fontSecondary,
    borderRadius: 5,
    padding: 10,
    fontSize: 14,
    width: "100%"
  },

  popover: {
    boxShadow: shadow.MEDIUM,
    padding: space.SMALL,
    borderRadius: space.XXS,
    marginTop: space.SMALL
  },

  menuText: {
    paddingLeft: space.XXS,
    fontSize: "inherit"
  },

  menuItem: {
    display: "flex",
    color: colors.black,
    width: "100%",
    padding: `${space.SMALL} 2px`,
    borderRadius: space.XXS,
    "&:hover": {
      background: colors.hover,
      cursor: "pointer"
    }
  },
  aiIcon: {
    marginLeft: space.SMALL,
    marginRight: -60,
    color: designColors.primary[50]
  }
});
export default styles;
