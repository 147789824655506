// @ts-nocheck
import { shadow, space } from "../../Config/theme";

const styles = (theme: any) => ({
  root: {
    padding: space.MEDIUM,
    display: "flex",
    flexDirection: "column",
    gap: space.SMALL,
    justifyContent: "center",
    width: 425,
    boxShadow: shadow.SMALL
  },

  section: {
    display: "flex",
    alignItems: "center"
  },

  ratingSection: {
    display: "flex",
    gap: space.SMALL,
    alignItems: "center",
    width: "50%"
  },

  rating: {
    width: "70%"
  },

  ratingText: {
    width: "30%",
    textAlign: "end"
  },

  scoreTitle: {
    width: "50%"
  },

  funnelStage: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-end"
  }
});
export default styles;
