// @ts-nocheck
import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import style from "../Containers/styles/KeywordResearchStyle";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableContainer,
  TablePagination
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { formatNumber } from "../Utils/UserUtils";
import colors from "../Themes/Colors";
import API from "../Services/Api";
import { KeywordDataSource } from "../Utils/Types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StyledPagination from "../design/components/StyledPagination";

const api = API.create();

class TopKeywords extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      domain: props.domain,
      topKeywords: props.topKeywords,
      topKeywordPage: 0,
      topKeywordRowsPerPage: 10,
      topKeywordsDataSize: props.topKeywordsDataSize,
      loading: props.loading
    };
  }

  componentDidUpdate(prevProps: any) {
    let { domain, topKeywords, topKeywordsDataSize, loading } = this.props;
    if (
      prevProps.domain !== domain ||
      prevProps.topKeywords !== topKeywords ||
      prevProps.loading !== loading
    ) {
      this.setState({
        domain: domain,
        topKeywords: topKeywords,
        topKeywordsDataSize: topKeywordsDataSize,
        loading: loading
      });
    }
  }

  doPaginationForTopKeyword = () => {
    let { domain, topKeywordPage, topKeywordRowsPerPage } = this.state;
    api.getTopKeywordsWithPagination(
      domain,
      topKeywordPage,
      topKeywordRowsPerPage,
      (res: any) => {
        if (res.status === 200) {
          this.setState({ topKeywords: res.data });
        }
      }
    );
  };

  handleChangeKeywordPage = (event: any, newPage: any) => {
    this.setState({ topKeywordPage: newPage }, this.doPaginationForTopKeyword);
  };

  handleChangeKeywordRowsPerPage = (event: any) => {
    this.setState(
      {
        topKeywordRowsPerPage: event.target.value,
        topKeywordPage: 0,
        loading: true
      },
      this.doPaginationForTopKeyword
    );
  };

  cardSkeleton = () => {
    let { classes } = this.props;

    return <Skeleton variant="rectangular" className={classes.cardSkeleton} />;
  };

  calculateLastSixMonthTrend = (monthlySearches: any, searchVolume: any) => {
    let totalSearchVolume = 0;
    let monthCount = 0;
    for (let eachMonth of monthlySearches) {
      totalSearchVolume += eachMonth.search_volume;
      monthCount = monthCount + 1;
      if (monthCount === 6) {
        break;
      }
    }
    let averageSearchVolume = totalSearchVolume / monthCount;

    let percentageOfChange = (
      ((searchVolume - averageSearchVolume) / searchVolume) *
      100
    ).toFixed(1);
    return percentageOfChange;
  };

  getSemrushTrend = (topKeyword: any) => {
    if (!topKeyword.trends) {
      return null;
    }
    let trends = topKeyword.trends.split(",");
    if (trends.length === 0) {
      return null;
    }

    return (trends[trends.length - 1] * 100).toFixed(0);
  };

  render() {
    let { classes, fetchKeyworDatafrom } = this.props;
    let {
      topKeywords,

      topKeywordsDataSize,

      topKeywordRowsPerPage,

      topKeywordPage,

      loading
    } = this.state;

    return (
      <div className={classes.keywordIdeasCard}>
        <Typography variant="body1" className={classes.heading}>
          Top Keywords
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.firstCell}>Keyword</TableCell>

                <TableCell>Searches/month</TableCell>

                <TableCell>
                  SEO Difficulty
                  <InfoOutlinedIcon
                    className={classes.infoIcon}
                    fontSize="small"
                    onClick={this.props.showSEODifficulty}
                  />
                </TableCell>

                <TableCell>
                  Position
                  <InfoOutlinedIcon
                    className={classes.infoIcon}
                    fontSize="small"
                    onClick={this.props.showPosition}
                  />
                </TableCell>

                <TableCell>
                  {fetchKeyworDatafrom === KeywordDataSource.SEMRUSH ? (
                    <>
                      Traffic (%)
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        fontSize="small"
                        onClick={this.props.showTraffic}
                      />
                    </>
                  ) : (
                    <>
                      Estimated Visits
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        fontSize="small"
                        onClick={this.props.showEstimatedVisits}
                      />
                    </>
                  )}
                </TableCell>

                <TableCell>
                  {fetchKeyworDatafrom === KeywordDataSource.SEMRUSH
                    ? "Trend"
                    : "6-Month Trend"}
                </TableCell>
              </TableRow>
            </TableHead>
            {topKeywords && topKeywords.length > 0 ? (
              <TableBody>
                {topKeywords?.map((topKeyword: any, index: any) => {
                  let trend =
                    fetchKeyworDatafrom === KeywordDataSource.SEMRUSH
                      ? this.getSemrushTrend(topKeyword)
                      : topKeyword.monthlySearches &&
                        topKeyword.monthlySearches.length > 0
                      ? this.calculateLastSixMonthTrend(
                          topKeyword.monthlySearches,
                          topKeyword.searchVolume
                        )
                      : null;
                  return (
                    <TableRow scope="row" key={index}>
                      <TableCell className={classes.firstCell}>
                        <Typography
                          className={classes.topKeyword}
                          onClick={() => {
                            this.props.doResearchByKeyword(topKeyword.keyword);
                          }}
                        >
                          {topKeyword.keyword}
                        </Typography>
                        {topKeyword.url && (
                          <a
                            href={topKeyword.url}
                            target="_blank"
                            className={classes.link}
                            rel="noreferrer"
                          >
                            <Typography
                              variant="caption"
                              className={classes.pageURL}
                            >
                              {topKeyword.url}
                            </Typography>
                          </a>
                        )}
                      </TableCell>

                      <TableCell>
                        {topKeyword.searchVolume
                          ? formatNumber(topKeyword.searchVolume)
                          : "N/A"}
                      </TableCell>

                      <TableCell>
                        {topKeyword.keywordDifficulty
                          ? topKeyword.keywordDifficulty
                          : "N/A"}
                      </TableCell>

                      <TableCell>
                        {topKeyword.position
                          ? formatNumber(topKeyword.position)
                          : "N/A"}
                      </TableCell>
                      {(fetchKeyworDatafrom === KeywordDataSource.SEMRUSH && (
                        <TableCell>
                          {topKeyword.traffic ? topKeyword.traffic : "N/A"}
                        </TableCell>
                      )) || (
                        <TableCell>
                          {topKeyword.estimatedVisits
                            ? formatNumber(
                                Math.round(topKeyword.estimatedVisits)
                              )
                            : "N/A"}
                        </TableCell>
                      )}

                      <TableCell>
                        {trend && (
                          <Typography
                            variant="body2"
                            style={{
                              color:
                                trend < 0
                                  ? colors.negativePercentage
                                  : colors.newPercentage,
                              background:
                                trend < 0
                                  ? colors.negativePercentageBackground
                                  : colors.percentageBackground
                            }}
                            className={classes.percentage}
                          >
                            {trend}%
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : loading ? (
              <TableBody>
                <TableRow scope="row">
                  <TableCell colSpan={6} className={classes.firstCell}>
                    {this.cardSkeleton()}
                  </TableCell>
                </TableRow>

                <TableRow scope="row">
                  <TableCell colSpan={6} className={classes.firstCell}>
                    {this.cardSkeleton()}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <TableRow scope="row">
                  <TableCell colSpan={5}>
                    <Typography
                      variant="body1"
                      className={classes.errorMessage}
                    >
                      We couldn't find any data. Try another keyword.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {topKeywords && topKeywords.length > 0 && (
          <StyledPagination
            count={topKeywordsDataSize}
            rowsPerPage={topKeywordRowsPerPage}
            page={topKeywordPage}
            onPageChange={this.handleChangeKeywordPage}
            onRowsPerPageChange={this.handleChangeKeywordRowsPerPage}
          />
        )}
      </div>
    );
  }
}

export default withStyles(style)(TopKeywords);
