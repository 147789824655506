// @ts-nocheck
import React, { Component, Suspense } from "react";
import { Container, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

import { withRouter } from "react-router";
import styles from "./styles/Page404Style";

import Header from "../Components/Header";

import Lottie from "react-lottie";

import { Helmet } from "react-helmet";
import StyledButton from "../design/components/StyledButton";

class Page404 extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      animation: <div />
    };
  }

  async componentDidMount() {
    let error = await import("../Animations/404.json");
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: error.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    this.setState({
      animation: (
        <Lottie options={defaultOptions} isStopped={false} isPaused={false} />
      )
    });
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes, errorMessage } = this.props;

    let { animation } = this.state;

    return (
      <Container maxWidth="lg" className={classes.container}>
        <Helmet>
          <title>404 | Letterdrop</title>
        </Helmet>

        <div className={classes.main}>
          <Typography variant="h4" gutterBottom className={classes.title}>
            {errorMessage ? errorMessage : "Uh oh... looks like you're lost!"}
          </Typography>

          <Suspense fallback={<div />}>
            <div className={classes.animation}>{animation}</div>
          </Suspense>

          <StyledButton className={classes.back} onClick={this.goBack}>
            Go back
          </StyledButton>
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(Page404));
