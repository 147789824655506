// @ts-nocheck
import { space } from "../../Config/theme";

const styles = (theme: any) => ({
  root: {
    width: 612,
    height: 412,
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      width: 500
    },
    [theme.breakpoints.down("sm")]: {
      width: 400
    }
  },

  header: {
    display: "flex",
    paddingTop: 16
  },

  label: {
    paddingRight: 10,
    cursor: "pointer",
    display: "table-cell",
    textAlign: "center"
  },

  selected: {
    color: theme.palette.primary.light
  },

  closeButtonContainer: {
    padding: 0
  },

  gridRTL: {
    paddingRight:space.LARGE,
    paddingLeft:space.LARGE,
    direction: "rtl"
  }
});

export default styles;
