// @ts-nocheck
import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import style from "../Containers/styles/KeywordResearchStyle";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableSortLabel,
  TableRow,
  Typography,
  TableContainer
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { formatNumber } from "../Utils/UserUtils";
import colors from "../Themes/Colors";
import API from "../Services/Api";
import { KeywordDataSource } from "../Utils/Types";
import StyledPagination from "../design/components/StyledPagination";
import StyledTab from "../design/components/StyledTab";

const api = API.create();

const DefaultPageSize = 10;

const tabOptions = {
  QUESTIONS: 0,
  KEYWORD_VARIATIONS: 1,
  RELATED_KEYWORDS: 2
};

const orderByHeader = {
  SEARCHES_MONTH: "searchVolume"
};

class RelatedKeywords extends Component {
  fetchKeywordDifficulty: any;
  constructor(props: any) {
    super(props);
    this.state = {
      searchKeyword: props.searchKeyword,
      relatedKeywords: [],
      currentPage: 0,
      rowsPerPage: DefaultPageSize,
      totalRecords: 10000,
      loading: true,
      selectedTab: tabOptions.QUESTIONS,
      isFetchingKD: false,
      publicationId: props.publicationId,
      order: "desc",
      orderBy: orderByHeader.SEARCHES_MONTH
    };
  }
  componentDidMount = () => {
    this.getKeywordQuestions();
  };

  componentDidUpdate(prevProps: any) {
    let { searchKeyword, loading } = this.props;
    if (prevProps.loading !== loading) {
      this.setState(
        {
          searchKeyword: searchKeyword,
          selectedTab: tabOptions.QUESTIONS,
          loading: true
        },

        this.getKeywordQuestions()
      );
    }
  }

  handleTabChange = (event: any, newValue: any) => {
    this.setState(
      {
        selectedTab: newValue,
        currentPage: 0,
        rowsPerPage: DefaultPageSize,
        loading: true,
        relatedKeywords: [],
        isFetchingKD: false
      },
      () => {
        if (newValue === tabOptions.RELATED_KEYWORDS) {
          this.doPaginationForRelatedKeyword();
        } else if (newValue === tabOptions.QUESTIONS) {
          this.getKeywordQuestions();
        } else if (newValue === tabOptions.KEYWORD_VARIATIONS) {
          this.getKeywordVariations();
        }
      }
    );
  };

  doPaginationForRelatedKeyword = () => {
    let {
      searchKeyword,
      currentPage,
      rowsPerPage,
      publicationId,
      orderBy,
      order
    } = this.state;
    api.getKeywordRelated(
      searchKeyword,
      currentPage,
      rowsPerPage,
      orderBy + "_" + order,
      publicationId,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            relatedKeywords: res.data.keywordRelated,
            isFetchingKD: res.data.isFetchingKeywordDifficulty
          });
          if (res.data.isFetchingKeywordDifficulty) {
            this.setIntervalToFetchKeywordDifficulty();
          } else {
            this.clearIntervalToFetchKeywordDifficulty();
          }
        }
      }
    );
  };

  getKeywordQuestions = () => {
    let {
      searchKeyword,
      currentPage,
      rowsPerPage,
      publicationId,
      orderBy,
      order
    } = this.state;
    api.getKeywordQuestions(
      searchKeyword,
      currentPage,
      rowsPerPage,
      orderBy + "_" + order,
      publicationId,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            relatedKeywords: res.data.keywordQuestions,
            isFetchingKD: res.data.isFetchingKeywordDifficulty
          });
          if (res.data.isFetchingKeywordDifficulty) {
            this.setIntervalToFetchKeywordDifficulty();
          } else {
            this.clearIntervalToFetchKeywordDifficulty();
          }
        }
      }
    );
  };

  getKeywordVariations = () => {
    let {
      searchKeyword,
      currentPage,
      rowsPerPage,
      publicationId,
      orderBy,
      order
    } = this.state;
    api.getKeywordVariations(
      searchKeyword,
      currentPage,
      rowsPerPage,
      orderBy + "_" + order,
      publicationId,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            relatedKeywords: res.data.keywordVariations,
            isFetchingKD: res.data.isFetchingKeywordDifficulty
          });
          if (res.data.isFetchingKeywordDifficulty) {
            this.setIntervalToFetchKeywordDifficulty();
          } else {
            this.clearIntervalToFetchKeywordDifficulty();
          }
        }
      }
    );
  };

  setIntervalToFetchKeywordDifficulty = () => {
    if (this.fetchKeywordDifficulty) {
      return;
    }
    this.fetchKeywordDifficulty = setInterval(() => {
      if (this.state.selectedTab === tabOptions.RELATED_KEYWORDS) {
        this.doPaginationForRelatedKeyword();
      } else if (this.state.selectedTab === tabOptions.QUESTIONS) {
        this.getKeywordQuestions();
      } else if (this.state.selectedTab === tabOptions.KEYWORD_VARIATIONS) {
        this.getKeywordVariations();
      }
    }, 5000);
  };

  clearIntervalToFetchKeywordDifficulty = () => {
    if (this.fetchKeywordDifficulty) {
      clearInterval(this.fetchKeywordDifficulty);
      delete this.fetchKeywordDifficulty;
    }
  };

  handleChangePage = (event: any, newPage: any) => {
    this.setState({ currentPage: newPage }, () => {
      if (this.state.selectedTab === tabOptions.RELATED_KEYWORDS) {
        this.doPaginationForRelatedKeyword();
      } else if (this.state.selectedTab === tabOptions.QUESTIONS) {
        this.getKeywordQuestions();
      } else if (this.state.selectedTab === tabOptions.KEYWORD_VARIATIONS) {
        this.getKeywordVariations();
      }
    });
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState(
      {
        rowsPerPage: event.target.value,
        currentPage: 0,
        loading: true
      },
      () => {
        if (this.state.selectedTab === tabOptions.RELATED_KEYWORDS) {
          this.doPaginationForRelatedKeyword();
        } else if (this.state.selectedTab === tabOptions.QUESTIONS) {
          this.getKeywordQuestions();
        } else if (this.state.selectedTab === tabOptions.KEYWORD_VARIATIONS) {
          this.getKeywordVariations();
        }
      }
    );
  };

  handleSort = (tableHeaderName: any) => {
    let { order, orderBy } = this.state;
    if (orderBy === tableHeaderName) {
      order = order === "asc" ? "desc" : "asc";
    } else {
      orderBy = tableHeaderName;
      order = "desc";
    }
    this.setState(
      {
        orderBy,
        order
      },
      () => {
        if (this.state.selectedTab === tabOptions.RELATED_KEYWORDS) {
          this.doPaginationForRelatedKeyword();
        } else if (this.state.selectedTab === tabOptions.QUESTIONS) {
          this.getKeywordQuestions();
        } else if (this.state.selectedTab === tabOptions.KEYWORD_VARIATIONS) {
          this.getKeywordVariations();
        }
      }
    );
  };

  cardSkeleton = () => {
    let { classes } = this.props;

    return <Skeleton variant="rectangular" className={classes.cardSkeleton} />;
  };

  calculateLastSixMonthTrend = (monthlySearches: any, searchVolume: any) => {
    let totalSearchVolume = 0;
    let monthCount = 0;
    for (let eachMonth of monthlySearches) {
      totalSearchVolume += eachMonth.search_volume;
      monthCount = monthCount + 1;
      if (monthCount === 6) {
        break;
      }
    }
    let averageSearchVolume = totalSearchVolume / monthCount;

    let percentageOfChange = (
      ((searchVolume - averageSearchVolume) / searchVolume) *
      100
    ).toFixed(1);
    return percentageOfChange;
  };

  getSemrushTrend = (topKeyword: any) => {
    if (!topKeyword.trends) {
      return null;
    }
    let trends = topKeyword.trends.split(",");
    if (trends.length === 0) {
      return null;
    }

    let lastMonth = parseFloat(trends[11]);
    let sixMonthsAgo = parseFloat(trends[5]);

    if (!lastMonth || !sixMonthsAgo) {
      return null;
    }
    return (((lastMonth - sixMonthsAgo) / sixMonthsAgo) * 100).toFixed(0);
  };

  render() {
    let { classes, fetchKeyworDatafrom } = this.props;
    let {
      relatedKeywords,

      totalRecords,

      rowsPerPage,

      currentPage,

      loading,

      selectedTab,

      order,

      orderBy
    } = this.state;

    return (
      <div className={classes.keywordIdeasCard}>
        <StyledTab
          style={{ padding: 10 }}
          value={selectedTab}
          onChange={this.handleTabChange}
          tabs={[
            { label: "Questions" },
            { label: "Keyword Variations" },
            { label: "Related Keywords" }
          ]}
        />

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.firstCell}>Keyword</TableCell>

                <TableCell>
                  <TableSortLabel
                    active={orderBy === orderByHeader.SEARCHES_MONTH}
                    direction={
                      orderBy === orderByHeader.SEARCHES_MONTH ? order : "asc"
                    }
                    onClick={() =>
                      this.handleSort(orderByHeader.SEARCHES_MONTH)
                    }
                  >
                    <Typography variant="body1" className={classes.heading}>
                      Searches/month
                    </Typography>
                  </TableSortLabel>
                </TableCell>

                <TableCell>SEO Difficulty</TableCell>

                <TableCell>Paid Difficulty</TableCell>

                <TableCell>Cost Per Click</TableCell>

                <TableCell>
                  {fetchKeyworDatafrom === KeywordDataSource.SEMRUSH
                    ? "Trend"
                    : "6-Month Trend"}
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow scope="row">
                  <TableCell colSpan={6} className={classes.firstCell}>
                    {this.cardSkeleton()}
                  </TableCell>
                </TableRow>

                <TableRow scope="row">
                  <TableCell colSpan={6} className={classes.firstCell}>
                    {this.cardSkeleton()}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : relatedKeywords && relatedKeywords.length > 0 ? (
              <TableBody>
                {relatedKeywords.map((relatedKeyword: any, index: any) => {
                  let trend =
                    fetchKeyworDatafrom === KeywordDataSource.SEMRUSH
                      ? this.getSemrushTrend(relatedKeyword)
                      : relatedKeyword.monthlySearches &&
                        relatedKeyword.monthlySearches.length > 0
                      ? this.calculateLastSixMonthTrend(
                          relatedKeyword.monthlySearches,
                          relatedKeyword.searchVolume
                        )
                      : null;
                  let keyword = relatedKeyword.keyword;
                  if (selectedTab === tabOptions.QUESTIONS) {
                    keyword = relatedKeyword.question;
                  } else if (selectedTab === tabOptions.KEYWORD_VARIATIONS) {
                    keyword = relatedKeyword.keywordVariation;
                  }
                  return (
                    <TableRow scope="row" key={index}>
                      <TableCell className={classes.firstCell}>
                        <Typography
                          className={classes.topKeyword}
                          onClick={() => {
                            this.props.doResearchByKeyword(
                              relatedKeyword.keyword
                            );
                          }}
                        >
                          {keyword}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        {formatNumber(relatedKeyword.searchVolume)}
                      </TableCell>

                      <TableCell>
                        {relatedKeyword.fetchingKeywordDifficulty
                          ? this.cardSkeleton()
                          : relatedKeyword.keywordDifficulty
                          ? relatedKeyword.keywordDifficulty
                          : "N/A"}
                      </TableCell>

                      <TableCell>
                        {Math.round(relatedKeyword.competition * 100)}
                      </TableCell>

                      <TableCell>${relatedKeyword.cpc.toFixed(2)}</TableCell>

                      <TableCell>
                        {(trend && (
                          <Typography
                            variant="body2"
                            style={{
                              color:
                                trend < 0
                                  ? colors.negativePercentage
                                  : colors.newPercentage,
                              background:
                                trend < 0
                                  ? colors.negativePercentageBackground
                                  : colors.percentageBackground
                            }}
                            className={classes.percentage}
                          >
                            {trend}%
                          </Typography>
                        )) || (
                          <Typography
                            variant="body2"
                            className={classes.percentage}
                          >
                            N/A
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow scope="row">
                  <TableCell colSpan={6}>
                    <Typography
                      variant="body1"
                      className={classes.errorMessage}
                    >
                      We couldn't find any data. Try another keyword.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {relatedKeywords && relatedKeywords.length > 0 && (
          <StyledPagination
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        )}
      </div>
    );
  }
}

export default withStyles(style)(RelatedKeywords);
