// @ts-nocheck
import { shadow, space } from "../../Config/theme";
import colors, { designColors } from "../../Themes/Colors";
import { grey } from "@mui/material/colors";

const styles = (theme: any) => ({
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },

  container: {
    paddingLeft: 0,
    paddingBottom: 100,
    backgroundColor: colors.backgroundColor,
    color: colors.fontPrimary
  },

  grid: {
    // paddingLeft: "15%",
    // paddingRight: "15%",
    // paddingTop: 60,
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: "7%",
    //   paddingRight: "7%"
    // }
  },

  width100: {
    width: "100%"
  },

  pageTitle: {
    color: colors.fontPrimary,
    fontWeight: "bolder",
    marginTop: 5
  },

  newTopicButton: {
    margin: 10
  },

  textButton: {
    marginRight: 10
  },

  buttonIcon: {
    height: "0.9em",
    marginRight: 5
  },

  newTopicButtonText: {
    fontSize: 14
  },

  flexFlow: {
    flexFlow: "row"
  },

  publicationTitleContainer: {
    marginTop: 8,
    marginBottom: 8
  },

  logoImage: {
    height: 20,
    width: 20,
    marginRight: 5,
    marginTop: -5
  },

  publicationTitle: {
    color: colors.fontPrimary,
    fontSize: "1.2em",
    overflowWrap: "anywhere",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },

  publicationTitleOverflow: {
    overflow: "hidden",
    marginRight: 20
  },

  item: {
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 5,
    "&:hover": {
      paddingTop: 20,
      paddingBottom: 20,
      borderRadius: 5
    }
  },

  topicTitle: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    fontWeight: "bolder"
  },

  createdOn: {
    color: colors.fontSecondary
  },

  description: {
    color: colors.subtitle,
    marginRight: 20,
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word"
  },

  authorContainer: {
    paddingLeft: 24
  },

  expandMoreIcon: {
    padding: 2
  },

  commentsHeading: {
    fontSize: 12,
    color: designColors.grayScale[80],
    display: "flex",
    margin: "auto",
    marginLeft: 8,
    marginRight: 24,
    "&:hover": {
      color: designColors.primary[50]
    }
  },

  morePopover: {
    boxShadow: shadow.LARGE,
    padding: 10,
    borderRadius: 6,
    marginTop: 10
  },

  menuItem: {
    display: "flex",
    color: "black",
    width: "100%",
    paddingTop: space.SMALL,
    paddingBottom: space.SMALL,
    paddingLeft: space.XXS,
    paddingRight: space.XXS,
    borderRadius: 5,
    userSelect: "none",
    "&:hover": {
      background: colors.hover,
      cursor: "pointer"
    }
  },

  topSection: {
    marginTop: 10
  },

  sectionHeading: {
    margin: "auto 0px",
    paddingBottom: 10,
    marginRight: 15,
    cursor: "pointer",
    position: "relative",
    padding: "6px 0 7px;",
    [theme.breakpoints.down("md")]: {
      marginRight: 10
    }
  },

  unselectedStatus: {
    "&::after": {
      background: ["none repeat scroll 0 0 transparent", "#000"],
      bottom: "0",
      content: '""',
      display: "block",
      height: "2px",
      left: "50%",
      position: "absolute",
      transition: "width 0.3s ease 0s, left 0.3s ease 0s",
      width: "0"
    },
    "&:hover::after": {
      borderBottom: "2px solid " + colors.selectHover,
      width: "100%",
      left: 0
    }
  },

  selectedStatus: {
    borderBottom: "2px solid " + colors.selectedItem
  },

  getStarted: {
    height: 400,
    width: "100%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  },

  errorMessage: {
    paddingTop: 10,
    color: colors.fontSecondary,
    textAlign: "center"
  },

  topicMenuButton: {
    paddingLeft: 15
  },

  popover: {
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    padding: 10,
    borderRadius: 10,
    marginTop: 10
  },

  menuText: {
    paddingLeft: 3,
    fontSize: "inherit"
  },

 
  menuItem: {
    display: "flex",
    color: "black",
    width: "100%",
    padding: "10px 2px 10px 2px",
    borderRadius: 5,
    "&:hover": {
      background: colors.hover,
      cursor: "pointer"
    }
  },

  menuIcon: {
    height: 18,
    margin: "auto 0px",
    marginRight: 7,
    color: grey[700]
  },

  closeIcon: {
    color: colors.fontSecondary
  },

  scrollHeight: {
    height: "auto",
    overflow: "hidden !important"
  },

  paddingRight15: {
    paddingRight: 15
  },

  commentsContainer: {
    paddingLeft: 20
  },

  pageStatistics: {
    color: colors.fontSecondary,
    fontSize: "0.90rem"
  },

  number: {
    color: colors.fontPrimary,
    fontWeight: "bolder"
  },

  authorAvatar: {
    marginRight: 24
  },

  upvotedLabel: {
    color: colors.fontSecondary,
    fontSize: 12,
    marginLeft: 10,
    marginTop: 2
  },

  dialogPaper: {
    minHeight: "95vh",
    maxHeight: "95vh"
  },

  lineItem1: {
    margin: "10px 0px"
  },

  lineItem: {
    marginBottom: 10
  },

  statMinWidth: {
    minWidth: 70
  },

  text: {
    color: colors.fontSecondary
  },

  progressBar: {
    width: 32,
    height: 32,
    marginLeft: 15,
    cursor: "pointer"
  },

  tooltip: {
    backgroundColor: colors.white,
    boxShadow: "0 2px 7px 0px rgb(0 0 0 / 10%)",
    borderRadius: "5px !important",
    opacity: "1 !important",
    cursor: "auto"
  },

  marginLeft10: {
    marginLeft: 10
  },

  tootltipData: { marginTop: 10, marginBottom: 10 },

  tagChip: {
    margin: "5px 5px 5px 0px",
    borderRadius: 5,
    backgroundColor: colors.whiteTheme,
    color: colors.fontSecondary,
    fontSize: "12px",
    height: 22,
    maxWidth: 250
  },

  link: {
    textDecorationColor: colors.white,
    color: colors.secondary,
    "&:hover": {
      textDecorationColor: colors.secondary
    },
    wordBreak: "break-word"
  },

  marginTop12: {
    marginTop: 12
  },

  input: {
    width: "100%",
    backgroundColor: colors.inputBackground,
    border: "none",
    color: colors.fontInput,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontInput
    }
  },

  sortLabel: {
    marginLeft: 30,
    marginTop: 6
  },

  filterLabel: {
    marginTop: 6,
    marginBottom: 15
  },

  arrangeItems: {
    display: "inline-flex"
  },

  autocompleteAvatar: {
    width: 20,
    height: 20
  },

  filterContainer: {
    paddingLeft: 24,
    // paddingRight: 24,
    marginBottom: 50,
    marginTop: 12
  },

  filterAutocomplete: {
    paddingLeft: 12
  },

  filterButtonSection: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 24,
    marginBottom: 12
  },

  searchBar: {
    display: "flex",
    alignItems: "center"
  },

  topicTable: {
    width: "100%",
    marginTop: 10
  },

  topicActionSection: {
    display: "flex",
    flexDirection: "row-reverse",
    height: "fit-content",
    alignItems: "center"
  },

  commentSkeleton: {
    margin: "0 20px",
    marginTop: 3
  },

  headerSkeleton: {
    padding: "0 8px 16px 16px",
    borderBottom: `2px solid  ${grey[200]}`
  },

  countSkeleton: {
    padding: "0 16px 16px 0",
    borderBottom: `2px solid  ${grey[200]}`
  },

  labelSkeleton: {
    padding: "0 4px",
    marginTop: 3
  },

  popper: {
    bottom: 0,
    left: "50% !important",
    width: "50%",
    top: "unset !important",
    transform: "translate(-50%, -50%)",
    zIndex: 5000
  },

  bulkAssignIdeasPaper: {
    padding: "10px 16px",
    borderRadius: 6,
    background: designColors.primary[50],
    color: colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
});

export default styles;
