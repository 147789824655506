// @ts-nocheck
import { Button, type ButtonProps } from "@mui/material";
import { designColors } from "../../Themes/Colors";
import PropTypes from "prop-types";
import React from "react";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    destructiveprimary: true;
    destructivesecondary: true;
    successprimary: true;
    successsecondary: true;
    contrastprimary: true;
    contrastsecondary: true;
    textprimary: true;
    textsecondary: true;
    textdestructive: true;
    textsuccess: true;
    textcontrastprimary: true;
  }
}

type StyledButtonProps = ButtonProps & {
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "destructiveprimary"
    | "destructivesecondary"
    | "successprimary"
    | "successsecondary"
    | "contrastprimary"
    | "contrastsecondary"
    | "textprimary"
    | "textsecondary"
    | "textdestructive"
    | "textsuccess"
    | "textcontrastprimary";
};

function StyledButton({
  size,
  children,
  variant,
  ...others
}: StyledButtonProps) {
  return (
    <Button
      disableRipple={variant.startsWith("text")}
      variant={variant}
      size={size}
      sx={{
        "&.MuiButton-root": {
          boxShadow: "none",

          borderRadius: "6px"
        },
        "&.MuiButton-primary": {
          color: designColors.white,

          backgroundColor: designColors.primary[50],

          boxShadow: "none",
          "&:hover": { backgroundColor: designColors.primary[100] },
          "&.Mui-disabled": {
            color: designColors.grayScale[60],

            backgroundColor: designColors.grayScale[5]
          }
        },
        "&.MuiButton-secondary": {
          color: designColors.primary[50],

          background: designColors.white,

          border: `1px solid ${designColors.primary[50]}`,

          boxSizing: "border-box",
          "&:hover": {
            backgroundColor: designColors.primary[0],

            color: designColors.primary[100],

            border: `1px solid ${designColors.primary[100]}`
          },
          "&.Mui-disabled": {
            border: `1px solid ${designColors.grayScale[40]}`,

            color: designColors.grayScale[60]
          }
        },
        "&.MuiButton-tertiary": {
          color: designColors.grayScale[100],

          background: designColors.white,

          outline: `1px solid ${designColors.grayScale[40]}`,
          "&:hover": {
            outline: `1px solid ${designColors.grayScale[100]}`,

            backgroundColor: designColors.white
          },
          "&.Mui-disabled": {
            outline: `1px solid ${designColors.grayScale[40]}`,

            color: designColors.grayScale[60]
          }
        },
        "&.MuiButton-destructiveprimary": {
          color: designColors.white,

          backgroundColor: designColors.alert[50],

          boxShadow: "none",
          "&:hover": { backgroundColor: designColors.alert[100] },
          "&.Mui-disabled": {
            color: designColors.grayScale[60],

            backgroundColor: designColors.grayScale[5]
          }
        },
        "&.MuiButton-destructivesecondary": {
          color: designColors.alert[50],

          background: designColors.white,

          outline: `1px solid ${designColors.alert[50]}`,

          boxSizing: "border-box",

          borderRadius: "6px",
          "&:hover": {
            outline: `1px solid ${designColors.alert[50]}`,

            backgroundColor: designColors.alert[5]
          },
          "&.Mui-disabled": {
            outline: `1px solid ${designColors.grayScale[40]}`,

            color: designColors.grayScale[60]
          }
        },
        "&.MuiButton-successprimary": {
          color: designColors.white,

          backgroundColor: designColors.success[50],

          boxShadow: "none",
          "&:hover": { backgroundColor: designColors.success[100] },
          "&.Mui-disabled": {
            color: designColors.grayScale[60],

            backgroundColor: designColors.grayScale[5]
          }
        },
        "&.MuiButton-successsecondary": {
          color: designColors.success[50],

          background: designColors.white,

          outline: `1px solid ${designColors.success[50]}`,

          boxSizing: "border-box",

          borderRadius: "6px",
          "&:hover": {
            backgroundColor: designColors.success[5]
          },
          "&.Mui-disabled": {
            outline: `1px solid ${designColors.grayScale[40]}`,

            color: designColors.grayScale[60]
          }
        },
        "&.MuiButton-contrastprimary": {
          color: designColors.primary[100],

          background: designColors.white,

          borderRadius: "6px",
          "&:hover": {
            backgroundColor: designColors.primary[0]
          },
          "&.Mui-disabled": {
            color: designColors.white,

            opacity: "0.4",

            background: "rgba(255, 255, 255, 0.4)"
          }
        },
        "&.MuiButton-contrastsecondary": {
          color: designColors.grayScale[100],

          background: designColors.white,

          borderRadius: "6px",
          "&.Mui-disabled": {
            outline: `1px solid rgba(255, 255, 255, 0.4)`,

            color: designColors.white,

            opacity: "0.4",

            background: designColors.grayScale[100]
          }
        },
        "&.MuiButton-textprimary": {
          color: designColors.primary[50],

          borderRadius: "0px",

          boxSizing: "border-box",

          border: `1px solid transparent`,

          padding: "0px !important",

          minWidth: "0px",
          "&:hover": {
            background: "none",

            padding: "0px !important",

            color: designColors.primary[100],

            borderBottom: `1px solid ${designColors.primary[100]}`
          },
          "& .MuiButton-startIcon": {
            alignItems: "center",

            marginRight: "8px",

            padding: "0px 0px 2px 0px",

            height: "16px",

            width: "16px"
          },
          "&.Mui-disabled": {
            color: designColors.grayScale[60]
          },
          "&.MuiButton-sizeSmall": {
            typography: "h100",

            height: "16px",

            padding: "0px"
          },
          "&.MuiButton-sizeMedium": {
            typography: "h200",

            height: "20px",

            padding: "0px"
          },
          "&.MuiButton-sizeLarge": {
            typography: "h300",

            height: "24px",

            padding: "0px"
          }
        },
        "&.MuiButton-textsecondary": {
          color: designColors.grayScale[100],

          borderRadius: "0px",

          boxSizing: "border-box",

          border: `1px solid transparent`,

          padding: "0px !important",

          minWidth: "0px",
          "&:hover": {
            background: "none",

            padding: "0px !important",

            borderBottom: `1px solid ${designColors.grayScale[100]}`
          },
          "& .MuiButton-startIcon": {
            alignItems: "center",

            marginRight: "8px",

            padding: "0px 0px 2px 0px",

            height: "16px",

            width: "16px"
          },
          "&.Mui-disabled": {
            color: designColors.grayScale[60]
          },
          "&.MuiButton-sizeSmall": {
            typography: "h100",

            height: "16px",

            padding: "0px"
          },
          "&.MuiButton-sizeMedium": {
            typography: "h200",

            height: "20px",

            padding: "0px"
          },
          "&.MuiButton-sizeLarge": {
            typography: "h300",

            height: "24px",

            padding: "0px"
          }
        },
        "&.MuiButton-textdestructive": {
          color: designColors.alert[50],

          borderRadius: "0px",

          boxSizing: "border-box",

          border: `1px solid transparent`,

          padding: "0px !important",

          minWidth: "0px",
          "&:hover": {
            background: "none",

            padding: "0px !important",

            borderBottom: `1px solid ${designColors.alert[50]}`
          },
          "& .MuiButton-startIcon": {
            alignItems: "center",

            marginRight: "8px",

            padding: "0px 0px 2px 0px",

            height: "16px",

            width: "16px"
          },
          "&.Mui-disabled": {
            color: designColors.grayScale[60]
          },
          "&.MuiButton-sizeSmall": {
            typography: "h100",

            height: "16px",

            padding: "0px"
          },
          "&.MuiButton-sizeMedium": {
            typography: "h200",

            height: "20px",

            padding: "0px"
          },
          "&.MuiButton-sizeLarge": {
            typography: "h300",

            height: "24px",

            padding: "0px"
          }
        },
        "&.MuiButton-textsuccess": {
          color: designColors.success[50],

          borderRadius: "0px",

          boxSizing: "border-box",

          border: `1px solid transparent`,

          padding: "0px !important",

          minWidth: "0px",
          "&:hover": {
            background: "none",

            padding: "0px !important",

            borderBottom: `1px solid ${designColors.success[50]}`
          },
          "& .MuiButton-startIcon": {
            alignItems: "center",

            marginRight: "8px",

            padding: "0px 0px 2px 0px",

            height: "16px",

            width: "16px"
          },
          "&.Mui-disabled": {
            color: designColors.grayScale[60]
          },
          "&.MuiButton-sizeSmall": {
            typography: "h100",

            height: "16px",

            padding: "0px"
          },
          "&.MuiButton-sizeMedium": {
            typography: "h200",

            height: "20px",

            padding: "0px"
          },
          "&.MuiButton-sizeLarge": {
            typography: "h300",

            height: "24px",

            padding: "0px"
          }
        },
        "&.MuiButton-textcontrastprimary": {
          color: designColors.white,

          borderRadius: "0px",

          boxSizing: "border-box",

          border: `1px solid transparent`,

          padding: "0px !important",

          minWidth: "0px",
          "&:hover": {
            background: "none",

            padding: "0px !important",

            borderBottom: `1px solid ${designColors.white}`
          },
          "& .MuiButton-startIcon": {
            alignItems: "center",

            marginRight: "8px",

            padding: "0px 0px 2px 0px",

            height: "16px",

            width: "16px"
          },
          "&.Mui-disabled": {
            color: designColors.white,

            opacity: "0.4"
          },
          "&.MuiButton-sizeSmall": {
            typography: "h100",

            height: "16px",

            padding: "0px"
          },
          "&.MuiButton-sizeMedium": {
            typography: "h200",

            height: "20px",

            padding: "0px"
          },
          "&.MuiButton-sizeLarge": {
            typography: "h300",

            height: "24px",

            padding: "0px"
          }
        },
        "&.MuiButton-sizeSmall": {
          typography: "h100",

          height: "32px",

          padding: "8px 12px"
        },
        "&.MuiButton-sizeMedium": {
          typography: "h200",

          height: "40px",

          padding: "10px 20px"
        },
        "&.MuiButton-sizeLarge": {
          typography: "h300",

          height: "48px",

          padding: "12px 24px"
        },
        "& .MuiButton-iconSizeSmall": {
          "&.MuiButton-startIcon": {
            alignItems: "center",

            marginRight: "8px",

            height: "16px",

            width: "16px",

            fontSize: "16px"
          },
          "&.MuiButton-endIcon": {
            alignItems: "center",

            marginLeft: "8px",

            height: "16px",

            width: "16px",

            fontSize: "16px"
          },
          "& .MuiSvgIcon-root": {
            fontSize: "16px"
          }
        },
        "& .MuiButton-iconSizeMedium": {
          "&.MuiButton-startIcon": {
            alignItems: "center",

            marginRight: "10px",

            height: "20px",

            width: "20px"
          },
          "&.MuiButton-endIcon": {
            alignItems: "center",

            marginLeft: "10px",

            height: "20px",

            width: "20px"
          },
          "& .MuiSvgIcon-root": {
            fontSize: "20px"
          }
        },
        "& .MuiButton-iconSizeLarge": {
          "&.MuiButton-startIcon": {
            alignItems: "center",

            marginRight: "12px",

            height: "24px",

            width: "24px"
          },
          "&.MuiButton-endIcon": {
            alignItems: "center",

            marginLeft: "12px",

            height: "24px",

            width: "24px"
          },
          "& .MuiSvgIcon-root": {
            fontSize: "24px"
          }
        }
      }}
      {...others}
    >
      {children}
    </Button>
  );
}

StyledButton.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "destructiveprimary",
    "destructivesecondary",
    "successprimary",
    "successsecondary",
    "contrastprimary",
    "contrastsecondary",
    "textprimary",
    "textsecondary",
    "textdestructive",
    "textsuccess",
    "textcontrastprimary"
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"])
};

StyledButton.defaultProps = {
  variant: "primary",
  size: "small"
};

export default StyledButton;
