import { Theme } from "@mui/material";
import { space } from "../../Config/theme";
import { designColors } from "../../Themes/Colors";
import { createStyles } from "@mui/styles";

const styles = (theme: Theme) =>
  createStyles({
    suggestion: {
      display: "flex",
      flexDirection: "column",
      borderRadius: 4,
      padding: space.SMALL,
      background: "unset"
    },
    suggestionHover: {
      "&:hover": {
        background: `${designColors.grayScale[0]} !important`,
        cursor: "pointer"
      }
    },
    scrollHeight: {
      height: "auto",
      overflow: "hidden"
    },
    repAnswer: {
      cursor: "pointer",
      fontSize: "14px",
      marginLeft: "2px",
      padding: space.SMALL,
      maxHeight: "150px",
      overflowY: "auto",
      border: "1px solid transparent",
      "&:hover": {
        border: `1px solid ${designColors.grayScale[40]} !important`,
        borderRadius: 6
      },
      "&:focus": {
        outline: `1px solid ${designColors.primary[100]} !important`,
        borderRadius: 6
      }
    }
  });

export default styles;
