// @ts-nocheck
import colors, { designColors } from "../../Themes/Colors";

const styles = (theme: any) => ({
  title: {
    marginTop: 8,
    marginBottom: 8,
    color: colors.fontSecondary
  },

  subtitle: {
    margin: "8px 0px 16px 0px"
  },

  draggable: {
    color: colors.fontSecondary,
    "&:hover": {
      cursor: "move"
    }
  },

  draggableItem: {
    marginTop: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 35,
    padding: 8,
    borderRadius: 3,
    width: "100%",
    backgroundColor: designColors.grayScale[0]
  },

  sectionHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  stageName: {
    marginLeft: 16,
    display: "flex",
    alignItems: "center"
  },

  disabledStageName: {
    marginLeft: 16,
    display: "flex",
    alignItems: "center",
    color: colors.fontSecondary
  }
});
export default styles;
