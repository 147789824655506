// @ts-nocheck
import AddIcon from "@mui/icons-material/Add";
import { FormControl, Divider, Grid, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { withStyles } from "@mui/styles";
import "firebase/auth";
import React from "react";
import StyledButton from "../design/components/StyledButton";

import StyledCheckbox from "../design/components/StyledCheckbox";

import StyledChip from "../design/components/StyledChip";

import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import {
  emailBannerType,
  postStatus,
  postVariant,
  selectedPostFile
} from "../Utils/Types";
import styles from "./styles/NewsletterReviewSettingsStyle";

function NewsletterReviewSettings(props: any) {
  let {
    classes,
    post,
    showPostCoverPhoto,
    publicationInReview,
    subscriberLists,
    selectedLists,
    selectedPostVariant,
    selectedEmailBannerType,
    onListChange,
    prevSelectedLists,
    onEmailBannerTypeChange,
    openEmailPreviewDialog,
    emailBannerRef,
    handleTeamMemberChange,
    handleSenderNameChange,
    replyTo,
    teamMembers,
    senderName
  } = props;

  return (
    <Grid container className={classes.emailBannerContainer}>
      {subscriberLists.length > 0 && (
        <div className={classes.section}>
          <Typography className={classes.sectionTitle}>Recipients</Typography>

          <Typography className={classes.tagsText}>
            Who do you want to email this to?
          </Typography>

          <Grid item md={12} className={classes.inputField}>
            <Autocomplete
              fullWidth
              value={selectedLists}
              multiple
              options={subscriberLists}
              disableCloseOnSelect
              getOptionDisabled={
                post.status === postStatus.PUBLISHED && !post.scheduledOn
                  ? (option) => {
                      if (selectedLists[0]?.isRootList) {
                        return !option.isRootList;
                      }
                      let index = prevSelectedLists.findIndex((item: any) => {
                        return option.name === item.name;
                      });
                      if (index === -1) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  : selectedLists[0]?.isRootList &&
                    ((option) => !option.isRootList)
              }
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) => {
                return option._id === value._id;
              }}
              onChange={onListChange}
              renderOption={(props, option, { selected }) => {
                if (option._id === -99) {
                  return (
                    <div {...props}>
                      <div className={classes.newListButtonContainer}>
                        <AddIcon color={"primary"} />

                        <Typography className={classes.createNewList}>
                          {option.name}
                        </Typography>
                      </div>
                    </div>
                  );
                }
                return (
                  <div {...props}>
                    <StyledCheckbox
                      checked={selected}
                      label={
                        <Typography>
                          {option.name}

                          <Typography
                            variant="caption"
                            className={classes.subCountCaption}
                          >{`${option.subscriberCount} subscriber${
                            option.subscriberCount === 1 ? "" : "s"
                          }`}</Typography>
                        </Typography>
                      }
                    />
                  </div>
                );
              }}
              size="large"
              renderInput={(params) => (
                <StyledInput {...params} variant="outlined" size="large" />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <StyledChip
                    label={option.name}
                    variant="gray"
                    {...getTagProps({ index })}
                    disabled={prevSelectedLists.indexOf(option) !== -1}
                  />
                ))
              }
            />
          </Grid>

          <Divider className={classes.divider} />
        </div>
      )}

      <div className={classes.section}>
        <Typography className={classes.sectionTitle}>Sender Name</Typography>

        <Typography className={classes.marginBottom20}>
          This will be used in the from field on your emails
        </Typography>

        <StyledInput
          title={
            post.status === postStatus.PUBLISHED
              ? "Can not change after publish"
              : "Sender name"
          }
          fullWidth
          value={senderName}
          size="large"
          onChange={handleSenderNameChange}
          disabled={post.status === postStatus.PUBLISHED && post.publishedOn}
        />

        <Divider className={classes.divider} />
      </div>

      <div className={classes.section}>
        <Typography className={classes.sectionTitle}>Email banner</Typography>

        <Typography className={classes.marginBottom20}>
          This is the banner image at the top of your emails
        </Typography>

        <Grid item md={12} className={classes.inputField}>
          <FormControl
            variant="outlined"
            size="large"
            className={classes.input}
          >
            <StyledSelect
              value={selectedEmailBannerType}
              name={selectedPostFile.EMAIL_BANNER}
              onChange={onEmailBannerTypeChange}
              className={classes.input}
              ref={emailBannerRef}
              size="large"
            >
              <StyledMenuItem value={emailBannerType.NO_EMAIL_BANNER}>
                No email banner
              </StyledMenuItem>

              <StyledMenuItem
                name={selectedPostFile.EMAIL_BANNER}
                value={emailBannerType.UPLOAD_EMAIL_BANNER}
              >
                Upload an email banner
              </StyledMenuItem>
              {selectedPostVariant === postVariant.BOTH &&
                showPostCoverPhoto && (
                  <StyledMenuItem value={emailBannerType.CROPPED_COVER_PHOTO}>
                    Use a cropped version of cover photo
                  </StyledMenuItem>
                )}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Divider className={classes.divider} />
      </div>
      {teamMembers && teamMembers.length > 1 && (
        <div className={classes.section}>
          <Typography className={classes.sectionTitle}>
            Who receives replies to your email?
          </Typography>

          <Typography className={classes.marginBottom20}>
            This can be different from the author or sender
          </Typography>

          <Grid item md={12} className={classes.inputField}>
            <FormControl
              variant="outlined"
              size="large"
              className={classes.input}
            >
              <StyledSelect
                value={replyTo}
                size="large"
                onChange={handleTeamMemberChange}
                className={classes.input}
              >
                {teamMembers.map((member: any) => (
                  <StyledMenuItem key={member.email} value={member.email}>
                    {member.email}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Grid>

          <Divider className={classes.divider} />
        </div>
      )}
    </Grid>
  );
}

export default withStyles(styles)(NewsletterReviewSettings);
