// @ts-nocheck
let styles = (theme: any) => ({
  externalSitesListContainer: {
    width: "100%"
  },

  table: {
    width: "100%"
  },

  actionButton: {
    paddingLeft: 15
  }
});

export default styles;
