// @ts-nocheck
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  paper: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    padding: 30,
    margin: "10px 0px"
  },

  margin10: {
    marginTop: 10
  },

  marginTop20: {
    marginTop: 20
  },

  cardTitle: {
    fontWeight: "bold",
    fontSize: 20
  },

  marginTop12: {
    [theme.breakpoints.down('md')]: {
      marginTop: 12,
      marginBottom: 10
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 12
    }
  },

  tableContainer: {
    marginTop: 10
  },

  avatar: {
    height: 30,
    width: 30
  },

  chipContainer: {
    marginTop: 10
  },

  inviteChip: {
    marginRight: 15
  },

  headerCell: {
    wordbreak: "break-word"
  },

  teamCell: {
    wordbreak: "break-word"
  },

  addTeamContainer: {
    padding: 10
  },

  noDataContainer: {
    marginTop: 10,
    textAlign: "center"
  },

  articleLink: {
    textDecoration: "none"
  },

  infoIcon: {
    marginBottom: -5,
    marginLeft: 10,
    right: 0,
    top: 0,
    cursor: "pointer"
  },

  infoLink: {
    textDecoration: "none",
    color: colors.fontSecondary,
    "&:hover": {
      textDecoration: "underline"
    }
  }
});
export default styles;
