// @ts-nocheck
import React, { Fragment } from "react";

import SearchBar from "../design/components/SearchBar";
import { withStyles } from "@mui/styles";
import style from "./styles/UnsplashPickerStyle";
import { Grid, Typography } from "@mui/material";

import Loading from "./Loading";
import API from "../Services/Api";
import { uploadBlobImage } from "../Utils/Storage";

const api = API.create();

class UnsplashPicker extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      query: "",
      photos: [],
      loading: true,
      uploading: false,
      error: false,
      errorMessage: ""
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    let { query } = this.state;
    this.setState({ loading: true });

    api.searchUnsplash(query || "gradient", (res: any) => {
      if (res.status === 200) {
        this.setState({
          loading: false,
          photos: res.data || [],
          error: !res.data.length,
          errorMessage: "We didn't find any pictures matching your search"
        });
      } else {
        this.setState({
          loading: false,
          error: true,
          errorMessage:
            "Oops, Unsplash doesn't seem to want to share with us. Contact support@letterdrop.com"
        });
      }
    });
  };

  imageSelected = (photo: any) => {
    let { convertToWebp } = this.props;
    api.markAsDownload(photo.links.download_location, (res: any) => {});
    this.setState({ uploading: true }, async () => {
      let url = null;
      try {
        let name = photo.tags?.[0].title || "";
        url = await uploadBlobImage(
          photo.urls.regular,
          "", // publication domain
          name,
          convertToWebp
        );
      } catch (error) {}

      this.setState({ uploading: false });
      url = url || photo.urls.regular;

      this.props.onImageSelect(url);
    });
  };

  checkImageUploading = () => {
    return this.state.uploading;
  };

  handleQueryChange = (query: any) => {
    this.setState({
      query
    });
  };

  searchBar = () => {
    let { classes } = this.props;
    return (
      <SearchBar
        onChange={this.handleQueryChange}
        value={this.state.query}
        onRequestSearch={this.load}
        placeholder="Search for more photos"
        onCancelSearch={() => this.handleQueryChange("")}
        className={classes.searchBar}
        size="small"
        style={{
          marginBottom: 10,
          width: "100%"
        }}
      />
    );
  };

  render() {
    let { classes } = this.props;

    let { loading, uploading, photos, error, errorMessage } = this.state;

    if (error) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {this.searchBar()}

          <Typography variant="bodym" style={{ marginTop: 100 }}>
            {errorMessage}
          </Typography>
        </div>
      );
    }

    if (loading || uploading) {
      return (
        <>
          {this.searchBar()}

          <Loading />
          {uploading && (
            <Typography className={classes.uploadingText}>
              Uploading...
            </Typography>
          )}
        </>
      );
    }

    return (
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          margin: 0
        }}
      >
        {this.searchBar()}

        <Grid
          container
          item
          direction="row"
          style={{ padding: 0, width: "100%", margin: 0 }}
          spacing={2}
        >
          {photos.map((photo: any, index: any) => {
            return (
              <Grid
                item
                xs={3}
                key={index}
                style={{ padding: "0px 4px 8px 4px", marginTop: 24 }}
              >
                <div>
                  <img
                    src={photo.urls.regular}
                    className={classes.image}
                    alt={photo.alt_description}
                    onClick={() => this.imageSelected(photo)}
                  />

                  <Typography
                    variant="bodys"
                    paragraph
                    className={classes.photographer}
                  >
                    By{" "}
                    <a
                      className={classes.link}
                      href={
                        photo.user.links.html +
                        "?utm_source=letterdrop&utm_medium=referral"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {photo.user.name}
                    </a>
                  </Typography>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(style)(UnsplashPicker);
