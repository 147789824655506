// @ts-nocheck
import { Autocomplete, Avatar, Box, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

import _ from "lodash";
import React from "react";
import { AvatarSize, space } from "../../Config/theme";

import StyledCheckbox from "./StyledCheckbox";

import StyledInput from "./StyledInput";
import StyledUserChip from "./StyledUserChip";
import styles from "../../Containers/styles/LinkedInShareStyle";
import colors from "../../Themes/Colors";

export const AUTOCOMPLETE_ALL_OPTION_TEXT = "All";

function StyledAutocomplete({
  value,
  disabled,
  options,
  onChange,
  onInputChange = () => {},
  isCheckboxSelected,
  optionIndex,
  classes,
  showAvatar,
  showOptionType = true,
  getDynamicOptions = false,
  ...others
}: any) {
  const selectedOptions = options.filter((obj2: any) =>
    value?.some((obj1: any) => _.isEqual(obj1, obj2))
  );

  const handleKeyDown = (e: any) => {
    const inputText = e.target.value;
    if (e.ctrlKey && e.key === "c" && !e.shiftKey) {
      if (!inputText && selectedOptions) {
        e.preventDefault();
        e.defaultMuiPrevented = true;
        navigator.clipboard
          .writeText(selectedOptions.map((opt) => opt.name).join(","))
          .catch((err) => {});
      }
    } else if (e.ctrlKey && e.key === "v" && !e.shiftKey) {
      if (!inputText) {
        e.preventDefault();
        e.defaultMuiPrevented = true;
        navigator.clipboard
          .readText()
          .then((text) => {
            if (!text) return;
            const textArray = text.split(",");

            let newItems = [];
            if (options.length) {
              newItems = options.filter((opt1) => {
                if (selectedOptions.some((opt2) => _.isEqual(opt1, opt2))) {
                  return false; // Already included in list
                }
                return textArray.includes(opt1.name);
              });
            }

            onChange(e, [...selectedOptions, ...newItems], optionIndex);
          })
          .catch((err) => {});
      }
    }
  };

  return (
    <Autocomplete
      multiple
      value={selectedOptions}
      disabled={disabled}
      onChange={(event, values) => onChange(event, values, optionIndex)}
      onInputChange={(event, values, reason) =>
        onInputChange(event, reason === "reset" ? "" : values, optionIndex)
      }
      {...(getDynamicOptions && { filterOptions: (x) => x })}
      fullWidth
      options={[
        {
          userId: -1,
          name: AUTOCOMPLETE_ALL_OPTION_TEXT
        },
        ...options
      ]}
      disableCloseOnSelect
      getOptionLabel={(option) => {
        return option.name;
      }}
      renderInput={(params) => (
        <StyledInput
          fullWidth
          {...params}
          size="small"
          variant="outlined"
          onKeyDown={handleKeyDown}
        />
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <Box key={option.userId} {...props}>
            <StyledCheckbox
              size={"small"}
              checked={isCheckboxSelected(option, optionIndex)}
              labelStyle={{ marginRight: 10 }}
            />

            {option.name !== AUTOCOMPLETE_ALL_OPTION_TEXT && showAvatar && (
              <Avatar
                sx={{ ...AvatarSize.sm }}
                className={classes.avatarAutocomplete}
                src={option?.profilePicture}
              />
            )}
            <Grid container direction="column">
              <Typography variant="bodym" style={{ marginLeft: space.MEDIUM }}>
                {option?.name}
              </Typography>

              {showOptionType && option?.type && (
                <Typography
                  variant="bodys"
                  style={{
                    color: colors.fontSecondary,
                    marginLeft: space.MEDIUM
                  }}
                >
                  {option?.type}
                </Typography>
              )}
            </Grid>
          </Box>
        );
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          return option.name === AUTOCOMPLETE_ALL_OPTION_TEXT ? (
            <StyledUserChip
              key={index}
              label={option.name}
              {...getTagProps({ index })}
            />
          ) : (
            <StyledUserChip
              key={index}
              label={`${option.name}${
                showOptionType && option?.type ? ` (${option?.type})` : ""
              }`}
              {...getTagProps({ index })}
              avatar={
                showAvatar ? (
                  <Avatar
                    sx={{ ...AvatarSize.sm }}
                    className={classes.avatarAutocomplete}
                    src={option?.profilePicture}
                  />
                ) : null
              }
            />
          );
        })
      }
      {...others}
    />
  );
}

export default withStyles(styles)(StyledAutocomplete);
