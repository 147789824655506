// @ts-nocheck
import {
  FormControlLabel,
  Grid,
  IconButton,
  Container,
  Tooltip,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

import { withRouter } from "react-router";
import API from "../Services/Api";
import styles from "./styles/EmailDetailsStyle";
import { notify } from "./CustomNotifications";
import StyledButton from "../design/components/StyledButton";

import StyledDialog from "../design/components/StyledDialog";

import StyledSwitch from "../design/components/StyledSwitch";

const api = API.create();

class EmailTemplate extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      showConfirmationDialog: false,
      sendWelcomeEmail: props.publication.welcomeEmail.sendWelcomeEmail
        ? props.publication.welcomeEmail.sendWelcomeEmail
        : false,
      publicationId: props.publication._id ? props.publication._id : "",
      templateId: props.publication.welcomeEmail.templateId
    };
  }

  save = () => {
    let { publicationId, sendWelcomeEmail } = this.state;
    api.enableWelcomeEmail(sendWelcomeEmail, publicationId, (res: any) => {});
  };

  handleConfirmChange = () => {
    let { sendWelcomeEmail } = this.state;
    this.setState(
      {
        sendWelcomeEmail: !sendWelcomeEmail
      },
      () => {
        this.save();
      }
    );
  };

  openEmailTemplate = () => {
    let { templateId, sendWelcomeEmail, publicationId } = this.state;
    if (!sendWelcomeEmail) {
      notify.show("Turn on welcome emails first", "error");
      return;
    }

    this.props.history.push(`/${publicationId}/templates/${templateId}`);
  };

  resetWelcomeEmail = () => {
    let { publicationId } = this.state;
    api.resetWelcomeEmail(publicationId, (res: any) => {
      if (res.status === 200) {
        this.toggleConfirmationDialog();
      } else if (res.status === 400) {
        notify.show(res.data, "error");
      }
    });
  };

  toggleConfirmationDialog = () => {
    let { showConfirmationDialog } = this.state;
    this.setState({
      showConfirmationDialog: !showConfirmationDialog
    });
  };

  render() {
    let { classes } = this.props;

    let { sendWelcomeEmail, showConfirmationDialog } = this.state;

    return (
      <Container className={classes.container}>
        <Typography variant="h400" paragraph>
          Automatic emails
        </Typography>

        <Typography variant="bodym" paragraph className={classes.cardSubtitle}>
          Send a welcome email to new subscribers
        </Typography>

        <Grid container direction="row" style={{ paddingTop: 20 }}>
          <Grid item xs={8} sm={8} md={3}>
            <Typography
              variant="bodym"
              paragraph
              className={classes.marginTop12}
            >
              Welcome email
            </Typography>
          </Grid>

          <Grid item xs={4} sm={4} md={5}>
            <div className={clsx(classes.input, classes.marginBottom10)}>
              <FormControlLabel
                control={
                  <StyledSwitch
                    checked={sendWelcomeEmail}
                    onChange={this.handleConfirmChange}
                  />
                }
                label=""
              />

              <IconButton
                onClick={this.openEmailTemplate}
                className={classes.editTemplateButton}
                size="large"
              >
                <Tooltip title="Edit email template">
                  <EditIcon />
                </Tooltip>
              </IconButton>

              <StyledButton
                style={{ margin: 10 }}
                variant="textsecondary"
                onClick={this.toggleConfirmationDialog}
              >
                Reset
              </StyledButton>

              <StyledDialog
                open={showConfirmationDialog}
                title="Are you sure?"
                body="We can't undo this once you've reset."
                successButtonName="Yes, I'm sure"
                successCallback={this.resetWelcomeEmail}
                cancelCallback={this.toggleConfirmationDialog}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(EmailTemplate));
