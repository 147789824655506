// @ts-nocheck
import colors, { designColors } from "../../Themes/Colors";

const styles = (theme: any) => ({
  quillInput: {
    width: "100%",
    backgroundColor: colors.inputBackground,
    border: `1px solid ${designColors.grayScale[40]}`,
    color: colors.fontInput,
    minHeight: 40,
    borderRadius: 6,
    "&:focus-within": {
      backgroundColor: colors.inputFocusBackground,
      border: `1px solid ${designColors.primary[100]}`,
      color: colors.fontInput
    }
  }
});

export default styles;
