// @ts-nocheck
import Tooltip from "@mui/material/Tooltip";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { withStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useMemo, useState } from "react";
import firebase from "firebase/app";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  NavItem,
  NavItemText,
  NavSubItem
} from "../design/components/NavigationBlocks";
import WorkspaceSelector from "../design/components/WorkspaceSelector";

import { ReactComponent as ChevronDownIcon } from "../Images/icon24/chevron_down.svg";

import { ReactComponent as ChevronUpIcon } from "../Images/icon24/chevron_up.svg";

import { ReactComponent as SubMeuIcon } from "../Images/nav/submenuitem.svg";

import { ReactComponent as SubMenuLastItemIcon } from "../Images/nav/submenulastiem.svg";

import { ReactComponent as ArrowLeft } from "../Images/icon24/arrowleft.svg";

import { ReactComponent as ArrowRight } from "../Images/icon24/arrowright.svg";
import { designColors } from "../Themes/Colors";
import PageAccessContext from "../Utils/PageAccessContext";
import styles from "./styles/NavigationDrawerStyle";
import { IconButton } from "@mui/material";

import _ from "lodash";
import StyledButton from "../design/components/StyledButton";
import { space } from "../Config/theme";
import { redirectToLoginPage } from "../Utils/UserUtils";
var pathToRegexp = require("path-to-regexp");

export const drawerWidth = 280;
export const collapsedWidth = 96;

function Navigation({
  businessOptions,
  agencyOptions,
  showPublicationCreate,
  classes
}: any) {
  let { id } = useParams();
  let { pathname } = useLocation();
  let context = useContext(PageAccessContext);
  let [openFlags, setOpenFlags] = useState({});
  let [navOptions, setNavOptions] = useState([]);
  let [collapse, setCollapse] = useState(false);
  let [autoCollapse, setAutoCollapse] = useState(false);
  let [agencyView, setAgencyView] = useState(pathname.startsWith("/agency"));
  const isUserLoggedIn = firebase.auth().currentUser ? true : false;
  const prefix = agencyView ? "agency" : id;

  const publications = useMemo(() => {
    let temPubs = context.publications || [];
    let index = temPubs.findIndex(
      (publication: any) => publication._id === "-1"
    );
    if (agencyView && index === -1) {
      temPubs.splice(0, 0, {
        _id: "-1",
        client: "null",
        name: "All Workspaces",
        logo: ""
      });
    } else if (!agencyView && index > -1) {
      temPubs.splice(index, 1);
    }

    return temPubs;
  }, [agencyView, context.publications]);

  const currentPublication = useMemo(() => {
    if (!agencyView && !pathname.startsWith("/agency")) {
      let index = publications.findIndex(
        (publication: any) => publication._id === id
      );
      return publications[index];
    } else {
      return { _id: "-1" };
    }
  }, [agencyView, publications, id]);

  //const navOptions = useMemo(() => {}, [id]);
  useEffect(() => {
    if (pathname.startsWith("/agency")) {
      setAgencyView(true);
      return setNavOptions(agencyOptions);
    } else {
      setAgencyView(false);
    }

    let index = publications.findIndex(
      (publication: any) => publication._id === id
    );

    return setNavOptions(businessOptions);
  }, [
    agencyOptions,
    businessOptions,
    id,
    pathname,
    publications,
    isUserLoggedIn
  ]);

  // let [currentPublication, setPublication] = useState(id);
  let history = useHistory();

  useEffect(() => {
    // to toggle the option open when a url is directly visited.
    // for ex: calendar is behind a menu. So if any one opens url for calendar it should show that section open
    for (let index = 0; index < navOptions.length; index++) {
      if (!navOptions[index].children) {
        continue;
      }

      let matches = navOptions[index].children.some((option: any) => {
        return agencyView
          ? pathname.startsWith(`/agency${option.url}`)
          : pathname.startsWith(`/${id}${option.url}`);
      });

      if (matches) {
        setOpenFlags({ [index]: true });
        break;
      }
    }
  }, [agencyView, id, navOptions, pathname]);

  function handleExpandMenu(index: any) {
    let copy = { ...openFlags };

    // close others
    for (let key of Object.keys(copy)) {
      if (key !== index.toString()) {
        copy[key] = false;
      }
    }

    //if the component is in collapsed state
    if (collapsed) {
      setCollapse(false);
      setAutoCollapse(false);

      copy[index] = true;
      setOpenFlags(copy);
      return;
    }

    // if doesn't exists enable it to true else invert the flag

    if (!copy[index]) {
      copy[index] = true;
    } else {
      copy[index] = !copy[index];
    }

    setOpenFlags(copy);
    // if(copy[index] === true && navOptions[index].url) {
    //   history.push(`/${id}${navOptions[index].url}`)
    // }
  }

  function handlePublicationChange(event: any) {
    if (event.target.value === undefined) {
      // call method to create workspace;
      // showPublicationCreate(true);
      return;
    }
    if (event.target.value === "-1") {
      return;
    }
    let publicationId = event.target.value;

    let urlToNavigate = "";
    for (let index = 0; index < businessOptions.length; index++) {
      if (businessOptions[index].url) {
        let url = `/${id}${businessOptions[index].url}`;
        if (pathname.startsWith(url)) {
          urlToNavigate = `/${publicationId}${businessOptions[index].url}`;
          break;
        }
      }

      if (businessOptions[index].children) {
        let matches = businessOptions[index].children.findIndex(
          (option: any) => {
            return pathname.startsWith(`/${id}${option.url}`);
          }
        );

        if (matches !== -1) {
          urlToNavigate = `/${publicationId}${businessOptions[index].children[matches].url}`;
          break;
        }
      }
    }

    if (urlToNavigate) {
      history.push(urlToNavigate);
    } else {
      history.push(`/${publicationId}/posts`);
    }

    // handle for other cases like user switches when in post editor
  }

  const autoCollapseNavBar = React.useMemo(() => {
    if (collapse) {
      return true;
    }
    let pathToCollapseNavigation = [
      pathToRegexp(`/:id/posts/:id`),
      pathToRegexp(`/:id/posts/:id/preamble`),
      pathToRegexp(`/:id/posts/:id/footer`),
      pathToRegexp(`/:id/posts/:id/review`),
      pathToRegexp(`/:id/templates/:id`),
      pathToRegexp(`/:id/templates/:id/footer`),
      pathToRegexp(`/:id/templates/:id/review`),
      pathToRegexp(`/:id/campaigns/:id`),
      pathToRegexp(`/:id/campaigns/:id/review`),
      pathToRegexp(`/:id/topics/:id/contentmap`),
      pathToRegexp(`/:id/campaign-templates/:id`)
    ];
    let autoCollapse = false;
    for (let paths of pathToCollapseNavigation) {
      if (paths.exec(pathname)) {
        autoCollapse = true;
        break;
      }
    }

    return autoCollapse;
  }, [collapse, pathname]);

  useEffect(() => {
    setAutoCollapse(autoCollapseNavBar);
  }, [autoCollapseNavBar]);

  const checkSubmenuIsSelected = (index: any) => {
    let selected = false;

    navOptions[index].children.map((child: any) => {
      if (pathname.startsWith(`/${id}${child.url}`)) {
        selected = true;
      }
      if (pathname.startsWith(`/agency${child.url}`)) {
        selected = true;
      }
      return "";
    });
    return selected;
  };

  const collapsed = React.useMemo(() => {
    return collapse || autoCollapse;
  }, [collapse, autoCollapse]);

  return (
    <Drawer
      sx={{
        width: collapsed ? collapsedWidth : drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: collapsed ? collapsedWidth : drawerWidth,
          boxSizing: "border-box",
          // p: "24px",
          backgroundColor: "#FCFCFC", // TODO :: Ask the background
          transition: "width 0.7s",
          transitionTimingFunction: "ease-out"
        },
        transition: "width 0.7s",
        transitionTimingFunction: "ease-out"
      }}
      variant="permanent"
      anchor="left"
    >
      <List className={classes.listSection}>
        <ListItem className={classes.listItem}>
          {isUserLoggedIn ? (
            <WorkspaceSelector
              value={currentPublication?._id}
              fullWidth
              collapse={collapsed}
              onChange={handlePublicationChange}
              publications={publications}
              currentPublicationId={id}
              agencyView={agencyView}
              showPublicationCreate={() => showPublicationCreate(true)}
            />
          ) : (
            <StyledButton
              variant="textprimary"
              size="medium"
              onClick={redirectToLoginPage}
              style={{ marginBottom: space.MEDIUM, borderBottom: "none" }}
            >
              Sign up for Letterdrop
            </StyledButton>
          )}
        </ListItem>

        <Divider
          style={{
            marginBottom: 48,
            borderTop: `1px solid ${
              collapsed ? "transparent" : designColors.grayScale[5]
            }`,
            borderColor: `${
              collapsed ? "transparent" : designColors.grayScale[5]
            }`
          }}
        />

        {navOptions.map((navOption, index) => {
          if (navOption.hide?.(currentPublication)) {
            return "";
          }
          if (navOption.children) {
            return (
              <Tooltip title={collapsed ? navOption.name : ""}>
                <div
                  key={index}
                  className={classes.navigationItemWrapper}
                  style={{
                    flexDirection: collapsed ? "inherit" : "column"
                  }}
                >
                  <NavItem
                    id={`${_.camelCase(navOption.name)}MenuItem`}
                    key={index}
                    icon={navOption.icon}
                    collapse={collapsed}
                    onClick={() => handleExpandMenu(index)}
                    selected={collapsed ? checkSubmenuIsSelected(index) : false}
                  >
                    <NavItemText
                      className={
                        collapsed
                          ? classes.collapsedNaviItemText
                          : classes.naviItemText
                      }
                    >
                      <Typography variant="h200">{navOption.name}</Typography>
                    </NavItemText>

                    {openFlags[index] ? (
                      <ChevronUpIcon
                        className={
                          collapsed
                            ? classes.collapsedNaviItemText
                            : classes.naviItemText
                        }
                      />
                    ) : (
                      <ChevronDownIcon
                        className={
                          collapsed
                            ? classes.collapsedNaviItemText
                            : classes.naviItemText
                        }
                      />
                    )}
                  </NavItem>
                  {!collapsed && (
                    <Collapse
                      in={openFlags[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {navOption.children
                          .filter((option) =>
                            option.hide
                              ? !option.hide(currentPublication)
                              : true
                          )
                          .map((child: any, childIndex: any) => {
                            return (
                              <NavSubItem
                                id={`${_.camelCase(child.name)}SubMenuItem`}
                                key={childIndex}
                                component={Link}
                                to={`/${prefix}` + child.url}
                                icon={
                                  childIndex ===
                                  navOption.children.length - 1 ? (
                                    <SubMenuLastItemIcon />
                                  ) : (
                                    <SubMeuIcon />
                                  )
                                }
                                selected={pathname.startsWith(
                                  `/${prefix}${child.url}`
                                )}
                              >
                                <NavItemText>
                                  <Typography variant="h200">
                                    {child.name}
                                  </Typography>
                                </NavItemText>
                              </NavSubItem>
                            );
                          })}
                      </List>
                    </Collapse>
                  )}
                </div>
              </Tooltip>
            );
          }
          return (
            <Tooltip title={collapsed ? navOption.name : ""}>
              <div key={index} className={classes.navigationItemWrapper}>
                <NavItem
                  id={`${_.camelCase(navOption.name)}MenuItem`}
                  collapse={collapsed}
                  component={Link}
                  to={`/${prefix}${navOption.url}`}
                  icon={navOption.icon}
                  selected={pathname.startsWith(`/${prefix}${navOption.url}`)}
                >
                  <NavItemText
                    className={
                      collapsed
                        ? classes.collapsedNaviItemText
                        : classes.naviItemText
                    }
                  >
                    <Typography variant="h200">{navOption.name}</Typography>
                  </NavItemText>
                </NavItem>
              </div>
            </Tooltip>
          );
        })}
      </List>

      <div
        className={classes.collapseIconSection}
        style={{
          justifyContent: collapsed ? "center" : "flex-end"
        }}
      >
        <Tooltip title={collapsed ? "Expand" : "Collapse"}>
          <IconButton
            disableRipple
            disableTouchRipple
            onClick={() => {
              setCollapse(!autoCollapse);
              setAutoCollapse(!autoCollapse);
            }}
            className={classes.collapseIcon}
          >
            {collapsed ? <ArrowRight /> : <ArrowLeft />}
          </IconButton>
        </Tooltip>
      </div>
    </Drawer>
  );
}

export default withStyles(styles)(Navigation);
