// @ts-nocheck
import colors from "../../Themes/Colors";
import { grey } from "@mui/material/colors";
import { space } from "../../Config/theme";

const styles = (theme: any) => ({
  container: {
    padding: 0,
    paddingTop: 24
  },

  cardTitle: {
    marginTop: 10,
    fontWeight: "bold",
    fontSize: 20
  },

  cardSubtitle: {
    color: colors.fontSecondary
  },

  inputContainer: {
    marginTop: 20
  },

  code: {
    backgroundColor: grey[100],
    color: colors.codePink,
    borderRadius: 2,
    padding: "3px 5px 3px 5px",
    fontFamily: "Inconsolata",
    width: "100%"
  },

  embedCopyContainer: {
    marginTop: 20
  },

  input: {
    width: "100%"
  },

  input50: {
    width: "50%"
  },

  copyInput: {
    backgroundColor: grey[100],
    color: colors.fontSecondary,
    borderRadius: 5,
    padding: 10,
    fontFamily: "Inconsolata",
    width: "100%"
  },

  saveGrid: {
    [theme.breakpoints.down("md")]: {
      marginTop: `${space.SMALL}`
    },
    margin: "auto"
  },

  divider: {
    margin: "30px 0px"
  },

  subTitle: {
    marginBottom: 10,
    color: colors.fontSecondary
  }
});

export default styles;
