// @ts-nocheck
import React from "react";
import "react-drop-zone/dist/styles.css";
import { Grid, Typography } from "@mui/material";
import colors from "../Themes/Colors";

function PostPhotoPicker({ onImageSelect, postPictures, classes }: any) {
  return (
    <div>
      <Grid container direction="row" style={{ flexGrow: 1 }} spacing={2}>
        {postPictures.map((photo: any, index: any) => {
          return (
            <Grid item xs={4} key={index}>
              <img
                src={photo}
                style={{
                  width: "100%",
                  height: 100,
                  backgroundSize: "cover",
                  cursor: "pointer"
                }}
                onClick={() => onImageSelect(photo)}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default PostPhotoPicker;
