// @ts-nocheck
import { Alert } from "@mui/material";
import { space } from "../../Config/theme";
import StyledButton from "./StyledButton";
import UnstyledLink from "./UnstyledLink";

export default function UpgradeAlert() {
  return (
    <Alert
      severity="info"
      action={
        <UnstyledLink to={"/account"}>
          <StyledButton size="small">UPGRADE</StyledButton>
        </UnstyledLink>
      }
      style={{ margin: `0px ${space.XS}` }}
    >
      This plan doesn't support publishing
    </Alert>
  );
}
