// @ts-nocheck
import {
  faFacebook,
  faHubspot,
  faLinkedinIn,
  faReddit,
  faSalesforce,
  faTwitter,
  faWordpress,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Container,
  Tooltip,
  Typography,
  IconButton,
  InputBase,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Divider,
  Autocomplete,
  Box,
  Badge
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CopyToClipboard from "react-copy-to-clipboard";
import Alert from "@mui/material/Alert";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import isURL from "validator/lib/isURL";
import { notify } from "../Components/CustomNotifications";
import StyledButton from "../design/components/StyledButton";
import StyledInput from "../design/components/StyledInput";
import StyledDialog from "../design/components/StyledDialog";
import StyledSwitch from "../design/components/StyledSwitch";
import API from "../Services/Api";
import GoogleApi from "../Services/GoogleSearchConsoleAPI";
import SalesforceAPI from "../Services/SalesforceAPI";
import SlackApi from "../Services/SlackApi";
import colors, { designColors } from "../Themes/Colors";
import images from "../Themes/Images";
import {
  CHROME_EXTENSION_URL,
  FACEBOOK_ALLOWED_DOMAINS,
  linkedInPermissions as linkedInPermissionTypes,
  modulesAccess,
  SalesCallIntegrationEnum,
  socialMediaType
} from "../Utils/Types";
import SelectSlackChannel from "./SelectSlackChannel";
import styles from "./styles/ConnectedAccountsStyle";
import MediumConnect from "./MediumConnect";
import DevtoConnect from "./DevtoConnect";
import GoogleAnalyticsConnect from "./GoogleAnalyticsConnect";
import HashnodeConnect from "./HashnodeConnect";
import { ReactComponent as GoogleIcon } from "../Images/google.svg";
import querystring from "query-string";
import HubSpotConnect from "./HubSpotConnect";
import { space } from "../Config/theme";
import { FileCopy } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import GongConnectDialog from "./GongConnectDialog";
import {
  features,
  hasAccess,
  isLimitExceeded,
  isModuleDisabled
} from "../Utils/AccessUtils";
import PageAccessContext from "../Utils/PageAccessContext";
import StyledAutocomplete, {
  AUTOCOMPLETE_ALL_OPTION_TEXT
} from "../design/components/StyledAutocomplete";
import { uuid } from "uuidv4";
import { AxiosResponse } from "axios";
import StyledChip from "../design/components/StyledChip";
import ClearbitConnect from "./ClearbitConnect";
import SalesCallConnectDialog from "./SalesCallConnectDialog";
import HusbpotCRMConfiguration from "./HubspotCRMConfiguration";
import SalesforceCRMConfiguration from "./SalesforceCRMConfiguration";
import ApolloConnect from "./ApolloConnect";
import { getApolloSequenceStorageKey } from "../Utils/UserUtils";
import { ReactComponent as ChromeIcon } from "../Images/chrome_icon.svg";
import { getDifferenceFromNow } from "../Utils/TimeUtils";
import SocialSellingRoles from "./SocialSellingRoles";
import { styled } from "@mui/material/styles";
import ZoomInfoConnect from "./ZoomInfoConnect";

const api = API.create();
const slackApi = SlackApi.create();
const googleApi = GoogleApi.create();
const salesforceApi = SalesforceAPI.create();
const prepopulateAccountAccess = [
  socialMediaType.WEBFLOW,
  socialMediaType.MEDIUM,
  socialMediaType.HUBSPOT
];
const WEBFLOW_VERSION = "v2";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""'
    }
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0
    }
  }
}));

let FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
class ConnectedAccounts extends React.Component {
  static contextType = PageAccessContext;
  blogHostingRef: any;
  wordpressConnectRef: any;
  internalSlackRef: any;
  onGongConnected: any;
  onSalesCallConnected: any;
  constructor(props: any) {
    super(props);
    this.state = {
      publicationId: props.publication._id ? props.publication._id : "",
      twitter: null,
      linkedin: null,
      facebook: null,
      slack: null,
      webflow: null,
      wordpress: null,
      medium: null,
      devto: null,
      hashnode: null,
      hubspot: null,
      hubspotCRM: null,
      salesforce: null,
      gsc: null,
      ga: null,
      youtube: null,
      clearbit: null,
      reddit: null,
      zoom: null,
      openSlackChannelDialog: false,
      openHubspotCRMDialog: false,
      openSalesforceCRMDialog: false,
      promptMediumDialog: false,
      promptDevtoDialog: false,
      promptGoogleAnalyticsDialog: false,
      promptHashnodeDialog: false,
      promptClearBitDialog: false,
      promptApolloDialog: false,
      promptZoomInfoDialog: false,
      showAccountsDialog: false,
      showConnectToGongDialog: false,
      showConnectToSalesCallDialog: false,
      integrationType: "",
      showCompanyDetailsDialog: false,
      accounts: [],
      publishToSocialFromPreviousPost: null,
      savingAutoPublish: false,
      newMediumToken: null,
      mediumErrorMessage: "",
      showHubSpotConfig: "",
      selectedHubspotIndex: 0,
      showWordPressConnectDialog: false,
      wordPressClientKey: "",
      wordPressClientSecret: "",
      wordpressUsername: "",
      wordpressApplicationPassword: "",
      wordPressURL: "",
      linkedinExternalConnectUrl: `${FRONTEND_URL}/${props.publication._id}/linkedin/connect/external`,
      showLinkedInOrgDialog: false,
      linkedInOrgs: [],
      isAllOrgsFilterChecked: false,
      selectedOrgs: [],
      showLinkedInPermissionDialog: false,
      linkedInPermissions: {
        like: true,
        comment: true,
        post: true,
        extension: true
      },
      showLinkedInReconnectDialog: false,
      reconnectAccountId: "",
      isCRM: false,
      showImportConfirmationDialog: false,
      importAccountType: "",
      importParam: "",
      socialSellingRoles: {}
    };

    this.internalSlackRef = React.createRef();
    this.blogHostingRef = React.createRef();
    this.wordpressConnectRef = React.createRef();
  }

  componentDidMount() {
    let { ga4, zoom } = querystring.parse(this.props.location.search);
    if (ga4) {
      this.toggleGoogleAnalyticsDialog();
    }
    if (zoom && zoom === "true") {
      notify.show("Zoom connected successfully", "success");
    } else if (zoom && zoom === "false") {
      notify.show("Failed to connect Zoom", "error");
    }
    this.load();
  }

  load = () => {
    api.loadConnectedAccounts(this.state.publicationId, (res: any) => {
      if (res.status === 200) {
        this.setAccountDetails(res);
        setTimeout(() => {
          if (
            this.props.location.hash === "#internalslack" &&
            this.internalSlackRef
          ) {
            this.internalSlackRef.current.scrollIntoView({
              block: "start",
              inline: "center",
              behavior: "smooth"
            });
            return;
          }
          if (
            this.props.location.hash === "#bloghosting" &&
            this.blogHostingRef
          ) {
            this.blogHostingRef.current.scrollIntoView({
              block: "start",
              inline: "center",
              behavior: "smooth"
            });
          }
          if (
            this.props.location.hash === "#wordpress" &&
            this.wordpressConnectRef
          ) {
            this.wordpressConnectRef.current.scrollIntoView({
              block: "start",
              inline: "center",
              behavior: "smooth"
            });
          }
          if (this.props.location.hash === "#linkedin") {
            this.setState({
              showAccountsDialog: true,
              accountType: socialMediaType.LINKED_IN,
              accounts: this.state.linkedin?.accounts || [],
              title: "Connected LinkedIn Accounts"
            });
          }
        }, 300);

        this.getLinkedInPermissions();
      }
      if (res.status === 400) {
      }
    });
  };

  getLinkedInPermissions = () => {
    let { publication }: any = this.props;
    if (!publication?.linkedin?.permissions) return;

    let url = new URL(
      `${FRONTEND_URL}/${this.props.publication._id}/linkedin/connect/external`
    );
    let params = new URLSearchParams(url.search);

    if (!publication.linkedin.permissions.comment) {
      params.append(linkedInPermissionTypes.COMMENT, "false");
    }
    if (!publication.linkedin.permissions.post) {
      params.append(linkedInPermissionTypes.POST, "false");
    }
    if (!publication.linkedin.permissions.extension) {
      params.append(linkedInPermissionTypes.EXTENSION, "false");
    }
    url.search = params.toString();
    this.setState({
      showLinkedInPermissionDialog: false,
      linkedinExternalConnectUrl: url.href,
      linkedInPermissions: publication.linkedin.permissions
    });
  };

  saveLinkedInPermissions = () => {
    let { publicationId, linkedInPermissions }: any = this.state;
    api.saveLinkedInPermissions(
      publicationId,
      linkedInPermissions,
      (res: any) => {
        if (res.status === 200) {
          let url = new URL(
            `${FRONTEND_URL}/${this.props.publication._id}/linkedin/connect/external`
          );
          let params = new URLSearchParams(url.search);

          if (!linkedInPermissions.comment) {
            params.append(linkedInPermissionTypes.COMMENT, "false");
          }
          if (!linkedInPermissions.post) {
            params.append(linkedInPermissionTypes.POST, "false");
          }
          if (!linkedInPermissions.extension) {
            params.append(linkedInPermissionTypes.EXTENSION, "false");
          }
          url.search = params.toString();

          this.setState({
            showLinkedInPermissionDialog: false,
            linkedinExternalConnectUrl: url
          });
        } else {
          notify.show(
            "Oops, we didn't save your LinkedIn permission settings",
            "error"
          );
        }
      }
    );
  };

  setAccountDetails = (res: any) => {
    let {
      connectedAccounts,
      publishToSocialFromPreviousPost,
      socialSellingRoles
    } = res.data;
    this.setState(
      {
        twitter: connectedAccounts.twitterHandle,
        linkedin: connectedAccounts.linkedInHandle,
        facebook: connectedAccounts.facebookHandle,
        slack: connectedAccounts.slackHandle,
        webflow: connectedAccounts.webflowHandle,
        wordpress: connectedAccounts.wordpressHandle,
        medium: connectedAccounts.mediumHandle,
        devto: connectedAccounts.devtoTokenDetail,
        hashnode: connectedAccounts.hashnodeTokenDetail,
        hubspot: connectedAccounts.hubspotHandler,
        hubspotCRM: connectedAccounts.hubspotCRMHandler,
        salesforce: connectedAccounts.salesforceHandle,
        gsc: connectedAccounts.gscHandle,
        clearbit: connectedAccounts.clearbitHandle,
        apollo: connectedAccounts.apolloHandle,
        zoom: connectedAccounts.zoomHandle,
        ga: connectedAccounts.gaHandle,
        youtube: connectedAccounts.youtubeHandle,
        gong: connectedAccounts.gongHandle,
        salesCallIntegration: connectedAccounts.salesCallIntegrationObject,
        reddit: connectedAccounts.redditHandle,
        savingAutoPublish: false,
        accounts: this.getDialogAccounts(res),
        publishToSocialFromPreviousPost,
        socialSellingRoles: socialSellingRoles || {}
      },
      () => {
        this.setLinkedInOrgData(res);
      }
    );
  };

  toggleSlackChannelDialog = () => {
    let { openSlackChannelDialog } = this.state;
    this.setState({
      openSlackChannelDialog: !openSlackChannelDialog
    });
  };

  toggleHubspotCRMDialog = () => {
    this.setState((prevState) => ({
      openHubspotCRMDialog: !prevState.openHubspotCRMDialog
    }));
  };

  toggleSalesforceCRMDialog = () => {
    this.setState((prevState) => ({
      openSalesforceCRMDialog: !prevState.openSalesforceCRMDialog
    }));
  };

  handleTwitterConnect = () => {
    let { publicationId, twitter } = this.state;

    if (
      isLimitExceeded(
        features.SOCIAL_MEDIA_ACCOUNTS,
        this.context,
        publicationId,
        twitter.twitterAccounts?.length || 0
      )
    ) {
      this.props.showUpgradeDialog(
        "Upgrade to a higher plan to connect more Twitter accounts"
      );
      return;
    }

    api.connectToTwitter(this.state.publicationId, "integrationsettings");
  };

  toggleShowAccounts = (accountType: string = "", isCRM: boolean = false) => {
    let {
      showAccountsDialog,
      twitter,
      reddit,
      linkedin,
      medium,
      facebook,
      webflow,
      hubspot,
      hubspotCRM
    } = this.state;
    let title = "Connected Accounts";
    let accounts = [];
    if (accountType === socialMediaType.TWITTER) {
      title = "Connected Twitter Accounts";
      accounts = twitter?.twitterAccounts || [];
    } else if (accountType === socialMediaType.FACEBOOK) {
      title = "Connected Facebook Account";
      accounts = facebook ? [facebook] : [];
    } else if (accountType === socialMediaType.REDDIT) {
      title = "Connected Reddit Accounts";
      accounts = reddit?.redditAccounts || [];
    } else if (accountType === socialMediaType.LINKED_IN) {
      title = "Connected LinkedIn Accounts";
      accounts = linkedin?.accounts || [];
    } else if (accountType === socialMediaType.MEDIUM) {
      title = "Connected Medium Accounts";
      accounts = medium?.authors || [];
    } else if (accountType === socialMediaType.WEBFLOW) {
      title = "Connected Webflow Collections";
      accounts = webflow?.accounts || [];
    } else if (accountType === socialMediaType.HUBSPOT) {
      title = isCRM ? "Connected HubSpot CRM" : "Connected HubSpot Blogs";
      accounts = isCRM ? hubspotCRM.accounts : hubspot?.accounts || [];
    }
    this.setState({
      showAccountsDialog: !showAccountsDialog,
      accountType,
      title,
      accounts,
      isCRM
    });
  };

  getDialogAccounts = (res: any) => {
    try {
      let { accountType, isCRM } = this.state;
      if (accountType === socialMediaType.TWITTER) {
        return res.data.connectedAccounts?.twitterHandle?.twitterAccounts || [];
      }
      if (accountType === socialMediaType.FACEBOOK) {
        return res.data.connectedAccounts?.facebookHandle
          ? [res.data.connectedAccounts?.facebookHandle]
          : [];
      }
      if (accountType === socialMediaType.REDDIT) {
        return res.data.connectedAccounts?.redditHandle?.redditAccounts || [];
      }
      if (accountType === socialMediaType.LINKED_IN) {
        return res.data.connectedAccounts?.linkedInHandle?.accounts || [];
      }
      if (accountType === socialMediaType.WEBFLOW) {
        return res.data.connectedAccounts?.webflowHandle?.accounts || [];
      }
      if (accountType === socialMediaType.HUBSPOT) {
        return (
          (isCRM
            ? res.data.connectedAccounts?.hubspotCRMHandler?.accounts
            : res.data.connectedAccounts?.hubspotHandler?.accounts) || []
        );
      }
      if (accountType === socialMediaType.MEDIUM) {
        return res.data.connectedAccounts?.mediumHandle?.authors || [];
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  handleLinkedInConnect = (
    isReconnect: boolean = false,
    reconnectAccountId: string = ""
  ) => {
    let { publicationId } = this.state;

    if (isReconnect) {
      this.setState({
        showLinkedInReconnectDialog: true,
        reconnectAccountId
      });

      return;
    }
    let connectedAccountsSize = this.getConnectedLinkedInAccountsSize();
    if (
      isLimitExceeded(
        features.SOCIAL_MEDIA_ACCOUNTS,
        this.context,
        publicationId,
        connectedAccountsSize
      )
    ) {
      this.props.showUpgradeDialog(
        "Upgrade to a higher plan to connect more LinkedIn accounts"
      );
      return;
    }

    api.connectToLinkedIn(publicationId);
  };

  getConnectedLinkedInAccountsSize() {
    let { linkedin } = this.state;
    return (
      (linkedin?.accounts?.length || 0) + (linkedin?.organizations?.length || 0)
    );
  }

  handleChromeExtensionDownload = () => {
    window.open(CHROME_EXTENSION_URL, "_blank");
  };

  handleFacebookConnect = () => {
    let { publication } = this.props;

    let { facebook } = this.state;

    if (
      isLimitExceeded(
        features.SOCIAL_MEDIA_ACCOUNTS,
        this.context,
        publication._id,
        facebook?.isConnected ? 1 : 0 // we allow one facebook account per publication only
      )
    ) {
      this.props.showUpgradeDialog(
        "Upgrade to a higher plan to connect more Facebook accounts"
      );
      return;
    }

    api.connectToFacebook(this.state.publicationId);
  };

  showGongPopup = (isNewConnection = false) => {
    let { publication } = this.props;

    if (
      isNewConnection &&
      !hasAccess(
        features.GONG_CONTENT_SUGGESTIONS,
        this.context,
        publication._id
      )
    ) {
      this.props.showUpgradeDialog(
        "Upgrade to a higher plan to connect your Gong account"
      );
      return;
    }

    if (isNewConnection && !publication?.companyDetails) {
      this.setState({
        showCompanyDetailsDialog: true
      });
      return;
    }

    this.setState({
      showConnectToGongDialog: true
    });
  };

  showSalesCallPopup = (
    isNewConnection: boolean = false,
    integrationType: SalesCallIntegrationEnum
  ) => {
    let { publication } = this.props;

    if (
      isNewConnection &&
      !hasAccess(
        features.GONG_CONTENT_SUGGESTIONS,
        this.context,
        publication._id
      )
    ) {
      this.props.showUpgradeDialog(
        "Upgrade to a higher plan to connect your Gong account"
      );
      return;
    }

    if (isNewConnection && !publication?.companyDetails) {
      this.setState({
        showCompanyDetailsDialog: true
      });
      return;
    }

    this.setState({
      showConnectToSalesCallDialog: true,
      integrationType
    });
  };

  initiateZoomIntegration = () => {
    let { publication } = this.props;
    const { zoom } = this.state;
    window.open(zoom.authUrl, "_self");
  };

  disconnectZoomIntegration = () => {
    let { publication } = this.props;
    let { salesCallIntegration } = this.state;

    api.connectToSalesCall(
      publication._id,
      "",
      "",
      salesCallIntegration?.integrationType,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.setState({
            salesCallIntegration: {
              ...salesCallIntegration,
              isConnected: false
            }
          });
        } else {
          notify.show(
            res.status === 400
              ? res.data
              : "Oops! We couldn't disconnect your account. Try again..",
            "error"
          );
        }
      }
    );
  };

  initiateGrainIntegration = () => {
    let { publication } = this.props;
    api.initiateGrainIntegration(publication._id, "integrationsettings");
  };

  disconnectGrainIntegration = () => {
    let { publication } = this.props;
    let { salesCallIntegration } = this.state;

    api.connectToSalesCall(
      publication._id,
      "",
      "",
      salesCallIntegration?.integrationType,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          salesCallIntegration.isConnected = false;
          this.setState({ salesCallIntegration });
        } else {
          notify.show(
            res.status === 400
              ? res.data
              : "Oops! We couldn't disconnect your account. Try again..",
            "error"
          );
        }
      }
    );
  };

  closeGongPopup = () => {
    this.setState(
      {
        showConnectToGongDialog: false
      },

      this.load()
    );
  };

  closeSalesCallPopup = () => {
    this.setState(
      {
        showConnectToSalesCallDialog: false,
        integrationType: ""
      },

      this.load()
    );
  };

  closeCompanyDetailsDialog = () => {
    this.setState({
      showCompanyDetailsDialog: false
    });
  };

  redirectToCompanyDetails = () => {
    let { publication } = this.props;

    this.props.history.push(`/${publication._id}/aisettings#companydetails`);
  };

  disconnectAccount = (
    accountType: any,
    userId: any,
    isCRM: boolean = false
  ) => {
    let { accounts, webflow } = this.state;
    api.disconnectAccount(
      this.state.publicationId,
      accountType,
      userId,
      isCRM,
      (res: any) => {
        if (res.status === 200) {
          if (accountType === socialMediaType.APOLLO) {
            const storageKey = getApolloSequenceStorageKey(
              this.state.publicationId
            );
            localStorage.removeItem(storageKey);
          }
          if (accountType === socialMediaType.SALESFORCE) {
            let { salesforce } = this.state;
            const REMOTE_SITE_SETTINGS_URL =
              salesforce.instanceURL +
              "/lightning/setup/SecurityRemoteProxy/home";
            notify.show(
              `Salesforce disconnected. Check out this page to make sure: ${REMOTE_SITE_SETTINGS_URL}`,
              "info"
            );
          }

          if (userId && accountType === socialMediaType.WEBFLOW) {
            let accountIndex = accounts.findIndex(
              (account: any) => account.collectionId === userId
            );
            if (accountIndex !== -1) {
              accounts.splice(accountIndex, 1);
            }

            accountIndex = webflow.accounts.findIndex(
              (account: any) => account.collectionId === userId
            );
            if (accountIndex !== -1) {
              webflow.accounts.splice(accountIndex, 1);
            }
            this.setState({ accounts, webflow });
            return;
          }

          if (userId && accountType !== socialMediaType.SLACK) {
            let accountIndex = accounts.findIndex(
              (account: any) => account.userId === userId
            );
            if (accountIndex !== -1) {
              accounts.splice(accountIndex, 1);
            }

            this.setState({ accounts }, this.load());
            return;
          }

          this.load();
        }
        if (res.status === 400) {
          notify.show(res.data, "error");
        }
      }
    );
  };

  togglePrePopulateSocialAccountsFromLastPost = (
    accountType: any,
    value: any
  ) => {
    if (this.state.savingAutoPublish) {
      notify.show("Saving is in progress", "warning");
    }

    let { publishToSocialFromPreviousPost } = this.state;
    this.setState({ savingAutoPublish: true }, () => {
      api.savePublishToSocialFromPreviousPost(
        this.state.publicationId,
        accountType,
        !value,
        (res: any) => {
          if (res.status === 200) {
            publishToSocialFromPreviousPost[accountType] = !value;
            this.setState({
              savingAutoPublish: false,
              publishToSocialFromPreviousPost
            });
          } else {
            this.setState({ savingAutoPublish: false });
          }
        }
      );
    });
  };

  connectToSlack = () => {
    let { publication } = this.props;
    slackApi.install(
      publication._id,
      true,
      "integrationsettings",
      null,
      (res: any) => {
        if (res.status === 200) {
          window.location.href = res.data;
        } else {
          notify.show(
            "Something went wrong. Please contact support@letterdrop.com",
            "error"
          );
        }
      }
    );
  };

  connectToGSC = () => {
    let { publication } = this.props;
    const redirectURI = FRONTEND_URL + "/gsccallback";
    googleApi.getOAuthDetails(publication._id, redirectURI, (res: any) => {
      if (res.status === 200) {
        window.location.href = res.data;
      } else {
        notify.show(
          "Something went wrong. Please contact support@letterdrop.com",
          "error"
        );
      }
    });
  };

  connectToYouTube = () => {
    let { publication } = this.props;
    const redirectURI = FRONTEND_URL + "/youtubecallback";

    googleApi.getYoutubeOAuthDetails(
      publication._id,
      redirectURI,
      (res: any) => {
        if (res.status === 200) {
          window.location.href = res.data;
        } else {
          notify.show(
            "Something went wrong. Please contact support@letterdrop.com",
            "error"
          );
        }
      }
    );
  };

  handleWebflowConnect = (
    webflowCollectionId: string = "",
    reconnect: boolean = false
  ) => {
    let { publication } = this.props;

    if (webflowCollectionId) {
      window.open(
        FRONTEND_URL +
          "/webflowcallback?state=" +
          publication._id +
          "&currentstep=2&isremap=true&collectionid=" +
          webflowCollectionId,
        "_self"
      );
      return;
    }

    api.connectToWebflow(publication._id, reconnect);
  };

  importFromWebflow = (webflowCollectionId: string) => {
    let { publication } = this.props;
    api.importFromWebflow(publication._id, webflowCollectionId, (res: any) => {
      if (res.status === 200) {
        notify.show(res.data, "success");
      } else {
        let errorMessage =
          res.status === 400 ? res.data : "Webflow post import failed";
        notify.show(errorMessage, "error");
      }
    });
  };

  importFromHubspot = (hubspotBlogId: string) => {
    let { publication } = this.props;

    api.importFromHubspot(publication._id, hubspotBlogId, (res: any) => {
      if (res.status === 200) {
        notify.show(res.data, "success");
      } else {
        let errorMessage =
          res.status === 400 ? res.data : "Hubspot post import failed";
        notify.show(errorMessage, "error");
      }
    });
  };

  importFromWordpress = () => {
    let { publication } = this.props;

    api.importFromWordpress(publication._id, (res: any) => {
      if (res.status === 200) {
        notify.show(res.data, "success");
      } else {
        let errorMessage =
          res.status === 400 ? res.data : "Wordpress post import failed";
        notify.show(errorMessage, "error");
      }
    });
  };

  addMediumAuthorConnect = () => {
    let { publication } = this.props;

    let { newMediumToken, medium } = this.state;

    if (
      isLimitExceeded(
        features.SOCIAL_MEDIA_ACCOUNTS,
        this.context,
        publication._id,
        medium.accounts?.length || 0
      )
    ) {
      this.props.showUpgradeDialog(
        "Upgrade to a higher plan to connect more Medium accounts"
      );
      return;
    }

    api.saveMediumAuthor(publication._id, newMediumToken, (res: any) => {
      if (res.status === 200) {
        this.setState({ newMediumToken: "", mediumErrorMessage: "" });
        this.load();
      } else {
        this.setState({
          mediumErrorMessage:
            res.status === 400 ? res.data : "Oops, something went wrong."
        });
      }
    });
  };

  sendWPIntegrationRequest = () => {
    let { publication } = this.props;
    api.sendWPIntegrationRequest(publication._id, (res: any) => {
      if (res.status === 200) {
        notify.show(
          "We'll email you shortly to setup your WordPress integration",
          "success"
        );
        return;
      }
      if (res.status === 400) {
        notify.show(res.data, "error");
        return;
      }
      notify.show("Please contact support@letterdrop.com", "error");
    });
  };

  handleMediumConnect = () => {
    let { publication } = this.props;

    let { medium } = this.state;

    if (
      isLimitExceeded(
        features.SOCIAL_MEDIA_ACCOUNTS,
        this.context,
        publication._id,
        medium.accounts?.length || 0
      )
    ) {
      this.props.showUpgradeDialog(
        "Upgrade to a higher plan to connect more Medium accounts"
      );
      return;
    }

    this.setState({ promptMediumDialog: true });
  };

  handleHubSpotConnect = (isCRM) => {
    api.connectToHubSpot(this.state.publicationId, isCRM);
  };

  handleSalesForceConnect = () => {
    salesforceApi.connect(this.state.publicationId);
  };

  handleRedditConnect = () => {
    let { publicationId, reddit } = this.state;

    if (
      isLimitExceeded(
        features.SOCIAL_MEDIA_ACCOUNTS,
        this.context,
        publicationId,
        reddit?.redditAccounts?.length || 0
      )
    ) {
      this.props.showUpgradeDialog(
        "Upgrade to a higher plan to connect more Reddit accounts"
      );
      return;
    }

    api.connectToReddit(publicationId, "integrationsettings");
  };

  handleAccountConnect = (
    accountType: any,
    isReconnect: boolean = false,
    reconnectAccountId: string = "",
    isCRM: boolean = false
  ) => {
    if (accountType === socialMediaType.TWITTER) {
      this.handleTwitterConnect();
      return;
    }

    if (accountType === socialMediaType.REDDIT) {
      this.handleRedditConnect();
      return;
    }

    if (accountType === socialMediaType.LINKED_IN) {
      this.handleLinkedInConnect(isReconnect, reconnectAccountId);
      return;
    }

    if (accountType === socialMediaType.WEBFLOW) {
      this.connectToBlog(accountType, false, isReconnect);
      return;
    }

    if (accountType === socialMediaType.HUBSPOT) {
      this.connectToBlog(accountType, isCRM);
      return;
    }

    if (accountType === socialMediaType.MEDIUM) {
      this.addMediumAuthorConnect();
      return;
    }
  };

  getSalesForceInstanceName = (url: any) => {
    let instanceURL = new URL(url);
    const instanceName = instanceURL.hostname.substring(
      0,
      instanceURL.hostname.indexOf(".salesforce.com")
    );

    return instanceName;
  };

  getLabelForAutoPostToSocial = (accountType: any) => {
    if (accountType === socialMediaType.TWITTER) {
      return `Remember Twitter accounts you publish blog posts to. Automatically publish to them in the future`;
    } else if (accountType === socialMediaType.FACEBOOK) {
      return `Remember Facebook accounts you publish blog posts to. Automatically publish to them in the future`;
    } else if (accountType === socialMediaType.REDDIT) {
      return `Remember Reddit accounts you publish blog posts to. Automatically publish to them in the future`;
    } else if (accountType === socialMediaType.LINKED_IN) {
      return `Remember LinkedIn accounts you publish blog posts to. Automatically publish to them in the future`;
    }
    return "";
  };

  toggleMediumDialog = () => {
    this.setState({ promptMediumDialog: !this.state.promptMediumDialog });
  };

  toggleDevtoDialog = () => {
    this.setState({ promptDevtoDialog: !this.state.promptDevtoDialog });
  };

  toggleClearBitDialog = () => {
    this.setState({ promptClearBitDialog: !this.state.promptClearBitDialog });
  };

  toggleApolloDialog = () => {
    this.setState({ promptApolloDialog: !this.state.promptApolloDialog });
  };

  toggleZoomInfoDialog = () => {
    this.setState({ promptZoomInfoDialog: !this.state.promptZoomInfoDialog });
  };

  toggleGoogleAnalyticsDialog = () => {
    this.setState({
      promptGoogleAnalyticsDialog: !this.state.promptGoogleAnalyticsDialog
    });
  };

  oauthGoogle = () => {
    let { publication } = this.props;
    const redirectURI = FRONTEND_URL + "/gacallback";
    googleApi.getGAOAuthDetails(publication._id, redirectURI, (res: any) => {
      if (res.status === 200) {
        window.location.href = res.data;
      } else {
        notify.show(
          "Something went wrong. Please contact support@letterdrop.com",
          "error"
        );
      }
    });
  };

  toggleHashnodeDialog = () => {
    this.setState({ promptHashnodeDialog: !this.state.promptHashnodeDialog });
  };

  closeAndReload = () => {
    this.setState({ promptMediumDialog: false }, this.load);
  };

  closeDevtoDialogAndReload = () => {
    this.setState({ promptDevtoDialog: false }, this.load);
  };

  closeClearBitDialogAndReload = () => {
    this.setState({ promptClearBitDialog: false }, this.load);
  };

  closeApolloDialogAndReload = () => {
    this.setState({ promptApolloDialog: false }, this.load);
  };

  closeGoogleAnalyticsDialogAndReload = () => {
    this.setState({ promptGoogleAnalyticsDialog: false }, this.load);
  };

  closeHashnodeDialogAndReload = () => {
    this.setState({ promptHashnodeDialog: false }, this.load);
  };

  showHubSpotConfigDialog = (index: any) => {
    this.setState({
      showHubSpotConfig: true,
      selectedHubspotIndex: index,
      showAccountsDialog: false
    });
  };

  closeHubspotConfigDialog = (reload = false) => {
    this.setState(
      {
        showHubSpotConfig: false
      },
      () => {
        if (reload) {
          this.load();
        }
      }
    );
  };

  connectToBlog = (
    type: string,
    isCRM: boolean = false,
    reconnect: boolean = false
  ) => {
    let { publicationId } = this.state;

    let connectedBlogsSize = this.getConnectedBlogsSize();
    if (
      isLimitExceeded(
        features.BLOG_DESTINATIONS,
        this.context,
        publicationId,
        connectedBlogsSize
      )
    ) {
      this.props.showUpgradeDialog(
        "Upgrade to a higher plan to connect more blogs"
      );
      return;
    }

    if (type === socialMediaType.WEBFLOW) {
      this.handleWebflowConnect(null, reconnect);
      return;
    }

    if (type === socialMediaType.WORDPRESS) {
      this.connectToWordPress();
      return;
    }

    if (type === socialMediaType.HUBSPOT) {
      this.handleHubSpotConnect(isCRM);
      return;
    }
  };

  connectToWordPress = () => {
    let { wordPressURL, publicationId } = this.state;
    if (!wordPressURL || (wordPressURL && !isURL(wordPressURL))) {
      notify.show(
        "Please fill all the fields for wordpress connection",
        "error"
      );
      return;
    }

    // Add https if not present
    if (
      !wordPressURL.startsWith("https://") &&
      !wordPressURL.startsWith("http://")
    ) {
      wordPressURL = "https://" + wordPressURL;
    }

    // Remove trailing slash
    if (wordPressURL.endsWith("/")) {
      wordPressURL = wordPressURL.slice(0, -1);
    }

    let successURL = `${FRONTEND_URL}/wordpresscallback?publicationId=${publicationId}`;
    let newAppId = uuid();
    window.location.href = `${wordPressURL}/wp-admin/authorize-application.php?app_name=letterdrop-${newAppId}&app_id=${newAppId}&success_url=${successURL}`;
  };

  getConnectedBlogsSize = () => {
    let { webflow, wordpress, hubspot } = this.state;

    let connectedAccounts = [wordpress, hubspot].filter(
      (blog) => blog?.isConnected
    ).length;

    if (webflow?.isConnected && webflow.accounts?.length) {
      connectedAccounts += webflow.accounts.length;
    }

    return connectedAccounts;
  };

  setLinkedInOrgDialog = () => {
    this.setState({ showLinkedInOrgDialog: true });
  };

  setLinkedInOrgData = (res: any) => {
    let { linkedInOrgs, selectedOrgs } = this.state;
    linkedInOrgs =
      res?.data?.connectedAccounts?.linkedInHandle?.organizations?.map(
        (org: any) => {
          return {
            name: org.name,
            isActive: org.isActive,
            orgId: org.userId
          };
        }
      );
    selectedOrgs = linkedInOrgs?.filter((org: any) => {
      if (org.isActive) {
        return org;
      }
    });
    this.setState({
      linkedInOrgs,
      selectedOrgs
    });
  };

  onLinkedInOrgSelected = (e: any, values: any) => {
    let { selectedOrgs, linkedInOrgs, isAllOrgsFilterChecked } = this.state;
    let allFlagSelected = values.some(
      (assignee: any) => assignee.name === AUTOCOMPLETE_ALL_OPTION_TEXT
    );
    if (allFlagSelected) {
      let populateAllAccounts = !isAllOrgsFilterChecked;
      selectedOrgs = populateAllAccounts ? linkedInOrgs : [];
      linkedInOrgs.forEach((org: any) => {
        org.isActive = true;
      });
      isAllOrgsFilterChecked = populateAllAccounts;
    } else {
      selectedOrgs = values;
      linkedInOrgs.forEach((org: any) => {
        const matchingOrgIndex = values.findIndex((value: any) => {
          return value.orgId === org.orgId;
        });
        org["isActive"] = matchingOrgIndex !== -1; // If values array contains org, means it is selected so set the value to true, else false
      });
      isAllOrgsFilterChecked = values.length === linkedInOrgs.length;
    }

    this.setState({
      selectedOrgs,
      linkedInOrgs,
      isAllOrgsFilterChecked
    });
  };

  isLinkedInOrgChecked = (option: any, optionIndex: any) => {
    let { selectedOrgs, isAllOrgsFilterChecked } = this.state;
    if (isAllOrgsFilterChecked) {
      return true;
    }
    return selectedOrgs.some((org: any) => org.orgId === option.orgId);
  };

  updateActiveLinkedInOrgs = () => {
    let { publicationId, linkedInOrgs } = this.state;
    this.setState({
      showLinkedInOrgDialog: false
    });
    api.updateLinkedInOrgs(publicationId, linkedInOrgs, (res: any) => {
      if (res.status === 200) {
        return;
      }
      if (res.status === 400) {
        notify.show(res.data || "Something went wrong", "error");
        return;
      }
      notify.show("Linkedin organizations update failed", "error");
    });
  };

  sendReconnectRequest = (userId) => {
    let { publicationId } = this.state;
    this.setState({
      showLinkedInReconnectDialog: false,
      reconnectAccountId: ""
    });
    api.sendReconnectRequest(publicationId, userId, (res: AxiosResponse) => {
      if (res.status === 200) {
        notify.show("Request sent", "success");
      } else {
        notify.show("Oops, we were unable to send reconnect request.", "error");
      }
    });
  };

  handleChipChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValues: string[],
    accountId: string
  ) => {
    let { publicationId } = this.state;
    api.updateLinkedInAccountGroup(
      publicationId,
      accountId,
      newValues,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.setState({ linkedin: res.data });
        }
        if (res.status === 400) {
          notify.show(res.data, "error");
        }
      }
    );
  };

  displayImportConfirmationDialog = (
    importAccountType: string,
    importParam: string = ""
  ) => {
    this.setState({
      showImportConfirmationDialog: true,
      importAccountType,
      importParam
    });
  };

  importFromSource = () => {
    let { importAccountType, importParam } = this.state;
    if (importAccountType === socialMediaType.WEBFLOW) {
      this.importFromWebflow(importParam);
    }
    if (importAccountType === socialMediaType.HUBSPOT) {
      this.importFromHubspot(importParam);
    }
    if (importAccountType === socialMediaType.WORDPRESS) {
      this.importFromWordpress();
    }
    this.setState({
      showImportConfirmationDialog: false,
      importAccountType: "",
      importParam: ""
    });
  };

  getAvatar = (accountType, account) => {
    let { classes } = this.props;
    if (
      accountType === socialMediaType.LINKED_IN &&
      account.chromeExtension?.installed &&
      account.chromeExtension?.updatedAt &&
      getDifferenceFromNow(account.chromeExtension?.updatedAt) <= 3
    ) {
      return (
        <Tooltip title="Extension installed" placement="right">
          <StyledBadge
            sx={{
              "& .MuiBadge-badge": {
                height: 8,
                width: 8,
                borderRadius: "50%"
              }
            }}
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            variant="dot"
          >
            <Avatar
              src={account.profilePicture}
              className={classes.twitterAvatar}
            />
          </StyledBadge>
        </Tooltip>
      );
    }

    return (
      <Avatar src={account.profilePicture} className={classes.twitterAvatar} />
    );
  };

  render() {
    let { classes, publication } = this.props;
    let {
      twitter,
      linkedin,
      slack,
      webflow,
      wordpress,
      facebook,
      medium,
      devto,
      hashnode,
      hubspot,
      hubspotCRM,
      gsc,
      clearbit,
      apollo,
      zoom,
      ga,
      youtube,
      gong,
      salesCallIntegration,
      openSlackChannelDialog,
      publicationId,
      showAccountsDialog,
      title,
      accounts,
      reddit,
      accountType,
      salesforce,
      publishToSocialFromPreviousPost,
      promptMediumDialog,
      promptDevtoDialog,
      promptClearBitDialog,
      promptApolloDialog,
      promptZoomInfoDialog,
      promptGoogleAnalyticsDialog,
      promptHashnodeDialog,
      newMediumToken,
      mediumErrorMessage,
      showHubSpotConfig,
      selectedHubspotIndex,
      linkedinExternalConnectUrl,
      showConnectToGongDialog,
      showConnectToSalesCallDialog,
      integrationType,
      showCompanyDetailsDialog,
      wordPressURL,
      showWordPressConnectDialog,
      showLinkedInOrgDialog,
      linkedInOrgs,
      selectedOrgs,
      linkedInPermissions,
      showLinkedInPermissionDialog,
      reconnectAccountId,
      showLinkedInReconnectDialog,
      isCRM,
      showImportConfirmationDialog,
      openHubspotCRMDialog,
      openSalesforceCRMDialog,
      socialSellingRoles
    } = this.state;

    return (
      <Container style={{ padding: 0 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          spacing={4}
          className={classes.accountsContainer}
        >
          {!isModuleDisabled(
            this.context,
            publicationId,
            modulesAccess.SOCIAL
          ) && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  variant="bodyl"
                  paragraph
                  className={classes.subTitle}
                >
                  SOCIAL SHARING
                </Typography>
              </Grid>

              <Grid item container xs={12}>
                <Grid
                  item
                  xs={6}
                  ms={6}
                  md={8}
                  className={classes.accountContainer}
                >
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.section}
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className={clsx(classes.socialIcon, classes.twitter)}
                    />
                    Twitter
                  </Typography>

                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.cardSubtitle}
                  >
                    Automatically create Twitter threads from posts
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  className={classes.connectButtonContainer}
                >
                  {twitter?.isConnected ? (
                    <StyledButton
                      variant="textsecondary"
                      onClick={() =>
                        this.toggleShowAccounts(socialMediaType.TWITTER)
                      }
                    >
                      Connected Accounts
                    </StyledButton>
                  ) : (
                    <StyledButton
                      variant="tertiary"
                      onClick={this.handleTwitterConnect}
                    >
                      Connect
                    </StyledButton>
                  )}
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Grid
                  item
                  xs={6}
                  ms={6}
                  md={8}
                  className={classes.accountContainer}
                >
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.section}
                  >
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      className={clsx(classes.socialIcon, classes.linkedin)}
                    />
                    LinkedIn
                  </Typography>

                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.cardSubtitle}
                  >
                    Automatically cross-post to LinkedIn
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  className={classes.connectButtonContainer}
                >
                  {linkedin?.accounts.length > 0 ? (
                    <StyledButton
                      onClick={() =>
                        this.toggleShowAccounts(socialMediaType.LINKED_IN)
                      }
                      variant="textsecondary"
                    >
                      Connected Accounts
                    </StyledButton>
                  ) : (
                    <StyledButton
                      variant="tertiary"
                      onClick={() => this.handleLinkedInConnect()}
                    >
                      Connect
                    </StyledButton>
                  )}
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Grid
                  item
                  xs={6}
                  ms={6}
                  md={8}
                  className={classes.accountContainer}
                >
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.section}
                  >
                    <img
                      src={`${FRONTEND_URL}/resources/images/google_chrome.svg`}
                      className={clsx(classes.socialIcon)}
                      alt="chrome-icon"
                    />
                    Social Selling Chrome Extension
                  </Typography>

                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.cardSubtitle}
                  >
                    Create content natively in LinkedIn and track buyer
                    interactions
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  className={classes.connectButtonContainer}
                >
                  <StyledButton
                    variant="tertiary"
                    onClick={this.handleChromeExtensionDownload}
                  >
                    Download
                  </StyledButton>
                </Grid>
              </Grid>

              {FACEBOOK_ALLOWED_DOMAINS.includes(publication?.domain) && (
                <Grid item container xs={12}>
                  <Grid
                    item
                    xs={6}
                    ms={6}
                    md={8}
                    className={classes.accountContainer}
                  >
                    <Typography
                      variant="bodym"
                      paragraph
                      className={classes.section}
                    >
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className={clsx(classes.socialIcon, classes.twitter)}
                      />
                      Facebook
                    </Typography>

                    <Typography
                      variant="bodym"
                      paragraph
                      className={classes.cardSubtitle}
                    >
                      Automatically publish posts on your page or in groups
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={4}
                    className={classes.connectButtonContainer}
                  >
                    {facebook?.isConnected ? (
                      <StyledButton
                        onClick={() =>
                          this.toggleShowAccounts(socialMediaType.FACEBOOK)
                        }
                        variant="textsecondary"
                      >
                        Connected Accounts
                      </StyledButton>
                    ) : (
                      <StyledButton
                        variant="tertiary"
                        onClick={this.handleFacebookConnect}
                      >
                        Connect
                      </StyledButton>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid item container xs={12}>
                <Grid
                  item
                  xs={6}
                  ms={6}
                  md={8}
                  className={classes.accountContainer}
                >
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.section}
                  >
                    <img
                      src={`${FRONTEND_URL}/resources/images/medium.svg`}
                      className={clsx(classes.socialIcon, classes.medium)}
                      alt="slack-icon"
                    />
                    Medium
                  </Typography>

                  <Typography variant="bodym" className={classes.cardSubtitle}>
                    Automatically publish posts to your blog on Medium
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  className={classes.connectButtonContainer}
                >
                  {medium?.isConnected ? (
                    <Fragment>
                      <StyledButton
                        onClick={() =>
                          this.disconnectAccount(socialMediaType.MEDIUM)
                        }
                        variant="textsecondary"
                        className={classes.textButton}
                      >
                        Disconnect
                      </StyledButton>

                      <StyledButton
                        onClick={() =>
                          this.toggleShowAccounts(socialMediaType.MEDIUM)
                        }
                        variant="textsecondary"
                        className={classes.textButton}
                      >
                        Connected Accounts
                      </StyledButton>
                    </Fragment>
                  ) : (
                    <StyledButton
                      variant="tertiary"
                      onClick={this.handleMediumConnect}
                    >
                      Connect
                    </StyledButton>
                  )}
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Grid
                  item
                  xs={6}
                  ms={6}
                  md={8}
                  className={classes.accountContainer}
                >
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.section}
                  >
                    <img
                      src={images.devtoIcon}
                      className={clsx(classes.socialIcon)}
                      alt="devto-icon"
                    />
                    Dev.to
                  </Typography>

                  <Typography variant="bodym" className={classes.cardSubtitle}>
                    Automatically publish posts to your blog on Dev.to
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  className={classes.connectButtonContainer}
                >
                  {devto?.isConnected ? (
                    <Fragment>
                      <StyledButton
                        onClick={() =>
                          this.disconnectAccount(socialMediaType.DEVTO)
                        }
                        variant="textsecondary"
                        className={classes.textButton}
                      >
                        Disconnect
                      </StyledButton>

                      <StyledButton
                        onClick={this.toggleDevtoDialog}
                        variant="tertiary"
                      >
                        Change Account
                      </StyledButton>
                    </Fragment>
                  ) : (
                    <StyledButton
                      variant="tertiary"
                      onClick={this.toggleDevtoDialog}
                    >
                      Connect
                    </StyledButton>
                  )}
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Grid
                  item
                  xs={6}
                  ms={6}
                  md={8}
                  className={classes.accountContainer}
                >
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.section}
                  >
                    <img
                      src={images.hashnodeIcon}
                      className={clsx(classes.socialIcon)}
                      alt="hashnode-icon"
                    />
                    Hashnode
                  </Typography>

                  <Typography variant="bodym" className={classes.cardSubtitle}>
                    Automatically publish posts to your blog on Hashnode
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  className={classes.connectButtonContainer}
                >
                  {hashnode?.isConnected ? (
                    <Fragment>
                      <StyledButton
                        onClick={() =>
                          this.disconnectAccount(socialMediaType.HASHNODE)
                        }
                        variant="textsecondary"
                        className={classes.textButton}
                      >
                        Disconnect
                      </StyledButton>

                      <StyledButton
                        onClick={this.toggleHashnodeDialog}
                        variant="tertiary"
                      >
                        Change Account
                      </StyledButton>
                    </Fragment>
                  ) : (
                    <StyledButton
                      variant="tertiary"
                      onClick={this.toggleHashnodeDialog}
                    >
                      Connect
                    </StyledButton>
                  )}
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Grid
                  item
                  xs={6}
                  ms={6}
                  md={8}
                  className={classes.accountContainer}
                >
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.section}
                  >
                    <FontAwesomeIcon
                      icon={faReddit}
                      className={clsx(classes.socialIcon, classes.reddit)}
                    />
                    Reddit
                  </Typography>

                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.cardSubtitle}
                  >
                    Automatically publish posts to a subreddit
                  </Typography>
                  {reddit?.isConnected && reddit.userName && (
                    <Typography
                      variant="bodys"
                      paragraph
                      className={classes.connectedAccount}
                    >
                      {`Connected to ${reddit?.userName}`}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  className={classes.connectButtonContainer}
                >
                  {reddit?.isConnected ? (
                    <StyledButton
                      onClick={() =>
                        this.toggleShowAccounts(socialMediaType.REDDIT)
                      }
                      variant="textsecondary"
                    >
                      Connected Accounts
                    </StyledButton>
                  ) : (
                    <StyledButton
                      variant="tertiary"
                      onClick={this.handleRedditConnect}
                    >
                      Connect
                    </StyledButton>
                  )}
                </Grid>
              </Grid>
            </>
          )}

          {!isModuleDisabled(
            this.context,
            publicationId,
            modulesAccess.SEO
          ) && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  variant="bodyl"
                  paragraph
                  className={classes.subTitle}
                  ref={this.blogHostingRef}
                >
                  BLOG HOSTING
                </Typography>
              </Grid>

              <Grid item container xs={12}>
                <Grid
                  item
                  xs={6}
                  ms={6}
                  md={8}
                  className={classes.accountContainer}
                >
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.section}
                  >
                    <img
                      src={`${FRONTEND_URL}/resources/images/webflow.svg`}
                      className={clsx(classes.socialIcon, classes.slack)}
                      alt="slack-icon"
                    />
                    Webflow
                  </Typography>

                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.cardSubtitle}
                  >
                    Automatically publish posts to your blog on Webflow
                  </Typography>
                  {webflow?.isConnected && (
                    <Typography
                      variant="bodys"
                      paragraph
                      className={classes.connectedAccount}
                    >
                      {`Connected to ${webflow.accounts.map(
                        (account: any, index: any) =>
                          index === 0 ? account.name : ` ${account.name}`
                      )}`}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  className={classes.connectButtonContainer}
                >
                  {webflow?.isConnected ? (
                    <StyledButton
                      onClick={() =>
                        this.toggleShowAccounts(socialMediaType.WEBFLOW)
                      }
                      variant="textsecondary"
                    >
                      Connected Collections
                    </StyledButton>
                  ) : (
                    <StyledButton
                      variant="tertiary"
                      onClick={() =>
                        this.connectToBlog(socialMediaType.WEBFLOW)
                      }
                    >
                      Connect
                    </StyledButton>
                  )}
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Grid
                  item
                  xs={6}
                  ms={6}
                  md={8}
                  className={classes.accountContainer}
                >
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.section}
                    ref={this.wordpressConnectRef}
                  >
                    <FontAwesomeIcon
                      icon={faWordpress}
                      className={clsx(classes.socialIcon, classes.twitter)}
                    />
                    Wordpress
                  </Typography>

                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.cardSubtitle}
                  >
                    Automatically publish posts to your Wordpress
                  </Typography>
                  {wordpress?.isConnected && wordpress?.siteUrl && (
                    <Typography
                      variant="bodys"
                      paragraph
                      className={classes.connectedAccount}
                    >
                      {`Connected to ${wordpress.siteUrl}`}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  className={classes.connectButtonContainer}
                >
                  {wordpress?.isConnected ? (
                    <Fragment>
                      <StyledButton
                        onClick={() =>
                          this.disconnectAccount(socialMediaType.WORDPRESS)
                        }
                        variant="textsecondary"
                        className={classes.textButton}
                        style={{ marginRight: space.SMALL }}
                      >
                        Disconnect
                      </StyledButton>
                      <StyledButton
                        onClick={() =>
                          this.displayImportConfirmationDialog(
                            socialMediaType.WORDPRESS
                          )
                        }
                        variant="textsecondary"
                        className={classes.textButton}
                        style={{ marginRight: space.SMALL }}
                      >
                        Import
                      </StyledButton>
                      <StyledButton
                        variant="tertiary"
                        onClick={() => {
                          this.setState({
                            showWordPressConnectDialog: true
                          });
                        }}
                      >
                        Change Account
                      </StyledButton>
                    </Fragment>
                  ) : (
                    <StyledButton
                      variant="tertiary"
                      onClick={() => {
                        this.setState({
                          showWordPressConnectDialog: true
                        });
                      }}
                    >
                      Connect
                    </StyledButton>
                  )}
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Grid
                  item
                  xs={6}
                  ms={6}
                  md={8}
                  className={classes.accountContainer}
                >
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.section}
                  >
                    <FontAwesomeIcon
                      icon={faHubspot}
                      className={clsx(classes.socialIcon, classes.hubspot)}
                    />
                    HubSpot
                  </Typography>

                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.cardSubtitle}
                  >
                    Automatically publish posts to your blog on HubSpot
                  </Typography>

                  {hubspot?.isConnected && (
                    <Typography
                      variant="bodys"
                      paragraph
                      className={classes.connectedAccount}
                    >
                      {`Connected to ${hubspot.accounts
                        .map((account: any) => account.name)
                        ?.join(", ")}`}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  className={classes.connectButtonContainer}
                >
                  {hubspot?.isConnected ? (
                    <StyledButton
                      onClick={() =>
                        this.toggleShowAccounts(socialMediaType.HUBSPOT)
                      }
                      variant="textsecondary"
                    >
                      Connected Blogs
                    </StyledButton>
                  ) : (
                    <StyledButton
                      variant="tertiary"
                      onClick={() =>
                        this.connectToBlog(socialMediaType.HUBSPOT)
                      }
                    >
                      Connect
                    </StyledButton>
                  )}
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="bodyl" paragraph className={classes.subTitle}>
              INTERNAL
            </Typography>
          </Grid>

          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <FontAwesomeIcon
                  icon={faSalesforce}
                  className={clsx(classes.socialIcon, classes.salesforce)}
                />
                Salesforce
              </Typography>

              <Typography
                variant="bodym"
                paragraph
                className={classes.cardSubtitle}
              >
                Connect deals to understand what content is driving pipeline and
                revenue
              </Typography>
              {salesforce?.isConnected && salesforce?.instanceURL && (
                <Typography
                  variant="bodys"
                  paragraph
                  className={classes.connectedAccount}
                >
                  {`Connected to ${this.getSalesForceInstanceName(
                    salesforce.instanceURL
                  )}`}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {salesforce?.isConnected ? (
                <Fragment>
                  <StyledButton
                    onClick={this.toggleSalesforceCRMDialog}
                    variant="textsecondary"
                    className={classes.textButton}
                  >
                    Configure
                  </StyledButton>
                  <StyledButton
                    onClick={() =>
                      this.disconnectAccount(socialMediaType.SALESFORCE)
                    }
                    variant="textsecondary"
                    className={classes.textButton}
                  >
                    Disconnect
                  </StyledButton>
                  {/* <Button onClick={this.handleSalesForceConnect} variant="text">
                    Change Account
                  </Button> */}
                </Fragment>
              ) : (
                <StyledButton
                  variant="tertiary"
                  onClick={this.handleSalesForceConnect}
                >
                  Connect
                </StyledButton>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <FontAwesomeIcon
                  icon={faHubspot}
                  className={clsx(classes.socialIcon, classes.hubspot)}
                />
                HubSpot CRM
              </Typography>

              <Typography
                variant="bodym"
                paragraph
                className={classes.cardSubtitle}
              >
                Sales insights from HubSpot
              </Typography>
              {hubspotCRM?.isConnected && (
                <Typography
                  variant="bodys"
                  paragraph
                  className={classes.connectedAccount}
                >
                  {`Connected to ${hubspotCRM.accounts[0]?.name}`}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {hubspotCRM?.isConnected ? (
                <>
                  <StyledButton
                    onClick={this.toggleHubspotCRMDialog}
                    variant="textsecondary"
                    className={classes.textButton}
                  >
                    Configure
                  </StyledButton>
                  <StyledButton
                    onClick={() =>
                      this.disconnectAccount(
                        socialMediaType.HUBSPOT,
                        hubspotCRM.accounts[0]?.userId,
                        true
                      )
                    }
                    variant="textsecondary"
                    className={classes.textButton}
                  >
                    Disconnect
                  </StyledButton>
                </>
              ) : (
                <StyledButton
                  variant="tertiary"
                  onClick={() =>
                    this.connectToBlog(socialMediaType.HUBSPOT, true)
                  }
                >
                  Connect
                </StyledButton>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
              ref={this.internalSlackRef}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <img
                  src={`${FRONTEND_URL}/resources/images/slack.svg`}
                  className={clsx(classes.socialIcon, classes.slack)}
                  alt="slack-icon"
                />
                Slack
              </Typography>

              <Typography
                variant="bodym"
                paragraph
                className={classes.cardSubtitle}
              >
                Let your teammates submit content ideas straight from Slack
              </Typography>
              {slack?.isConnected && slack?.workspaceName && (
                <Typography
                  variant="bodys"
                  paragraph
                  className={classes.connectedAccount}
                >
                  {`Connected to ${slack.workspaceName}`}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {slack?.isConnected ? (
                <Fragment>
                  <StyledButton
                    variant="textsecondary"
                    onClick={this.toggleSlackChannelDialog}
                    className={classes.textButton}
                  >
                    Configure
                  </StyledButton>

                  <StyledButton
                    onClick={() =>
                      this.disconnectAccount(socialMediaType.SLACK)
                    }
                    variant="textsecondary"
                    className={classes.textButton}
                  >
                    Disconnect
                  </StyledButton>

                  <StyledButton
                    onClick={this.connectToSlack}
                    variant="tertiary"
                  >
                    Change Account
                  </StyledButton>
                </Fragment>
              ) : (
                <StyledButton variant="tertiary" onClick={this.connectToSlack}>
                  Connect
                </StyledButton>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <img
                  src={`/resources/images/gong.png`}
                  className={clsx(classes.socialIcon, classes.slack)}
                  alt="google-search-console-icon"
                />
                Gong
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Suggest relevant content and collateral to share to your sales
                team after calls. Extract marketing insights from sales calls.
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {salesCallIntegration?.isConnected &&
              salesCallIntegration?.integrationType ===
                SalesCallIntegrationEnum.GONG ? (
                <StyledButton
                  onClick={(event: any) =>
                    this.showSalesCallPopup(
                      false,
                      SalesCallIntegrationEnum.GONG
                    )
                  }
                  variant="textsecondary"
                  className={classes.textButton}
                >
                  Connected Accounts
                </StyledButton>
              ) : (
                <Tooltip
                  title={
                    salesCallIntegration?.isConnected &&
                    salesCallIntegration?.integrationType !==
                      SalesCallIntegrationEnum.GONG
                      ? "you can't connect more than 1 sales call monitoring integration"
                      : ""
                  }
                >
                  <span>
                    <StyledButton
                      variant="tertiary"
                      onClick={() =>
                        this.showSalesCallPopup(
                          true,
                          SalesCallIntegrationEnum.GONG
                        )
                      }
                      disabled={
                        salesCallIntegration?.isConnected &&
                        salesCallIntegration?.integrationType !==
                          SalesCallIntegrationEnum.GONG
                      }
                    >
                      Connect
                    </StyledButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <img
                  src={`/resources/images/firefliesai.png`}
                  className={clsx(classes.socialIcon, classes.slack)}
                  alt="fireflies-ai-icon"
                />
                Fireflies
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Suggest relevant content and collateral to share to your sales
                team after calls. Extract marketing insights from sales calls.
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {salesCallIntegration?.isConnected &&
              salesCallIntegration?.integrationType ===
                SalesCallIntegrationEnum.FIREFLIES ? (
                <StyledButton
                  onClick={(event: any) =>
                    this.showSalesCallPopup(
                      false,
                      SalesCallIntegrationEnum.FIREFLIES
                    )
                  }
                  variant="textsecondary"
                  className={classes.textButton}
                >
                  Connected Accounts
                </StyledButton>
              ) : (
                <Tooltip
                  title={
                    gong?.isConnected ||
                    (salesCallIntegration?.isConnected &&
                      salesCallIntegration?.integrationType !==
                        SalesCallIntegrationEnum.FIREFLIES)
                      ? "you can't connect more than 1 sales call monitoring integration"
                      : ""
                  }
                >
                  <span>
                    <StyledButton
                      variant="tertiary"
                      onClick={() =>
                        this.showSalesCallPopup(
                          true,
                          SalesCallIntegrationEnum.FIREFLIES
                        )
                      }
                      disabled={
                        gong?.isConnected ||
                        (salesCallIntegration?.isConnected &&
                          salesCallIntegration?.integrationType !==
                            SalesCallIntegrationEnum.FIREFLIES)
                      }
                    >
                      Connect
                    </StyledButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <img
                  src={`/resources/images/chorusai.png`}
                  className={clsx(classes.socialIcon, classes.slack)}
                  alt="chorus-ai-icon"
                />
                Chorus
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Suggest relevant content and collateral to share to your sales
                team after calls. Extract marketing insights from sales calls.
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {salesCallIntegration?.isConnected &&
              salesCallIntegration?.integrationType ===
                SalesCallIntegrationEnum.CHORUS ? (
                <StyledButton
                  onClick={(event: any) =>
                    this.showSalesCallPopup(
                      false,
                      SalesCallIntegrationEnum.CHORUS
                    )
                  }
                  variant="textsecondary"
                  className={classes.textButton}
                >
                  Connected Accounts
                </StyledButton>
              ) : (
                <Tooltip
                  title={
                    gong?.isConnected ||
                    (salesCallIntegration?.isConnected &&
                      salesCallIntegration?.integrationType !==
                        SalesCallIntegrationEnum.CHORUS)
                      ? "you can't connect more than 1 sales call monitoring integration"
                      : ""
                  }
                >
                  <span>
                    <StyledButton
                      variant="tertiary"
                      onClick={() =>
                        this.showSalesCallPopup(
                          true,
                          SalesCallIntegrationEnum.CHORUS
                        )
                      }
                      disabled={
                        gong?.isConnected ||
                        (salesCallIntegration?.isConnected &&
                          salesCallIntegration?.integrationType !==
                            SalesCallIntegrationEnum.CHORUS)
                      }
                    >
                      Connect
                    </StyledButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <img
                  src={`/resources/images/clariai.png`}
                  className={clsx(classes.socialIcon, classes.slack)}
                  alt="clari-ai-icon"
                />
                Clari
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Suggest relevant content and collateral to share to your sales
                team after calls. Extract marketing insights from sales calls.
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {salesCallIntegration?.isConnected &&
              salesCallIntegration?.integrationType ===
                SalesCallIntegrationEnum.CLARI ? (
                <StyledButton
                  onClick={(event: any) =>
                    this.showSalesCallPopup(
                      false,
                      SalesCallIntegrationEnum.CLARI
                    )
                  }
                  variant="textsecondary"
                  className={classes.textButton}
                >
                  Connected Accounts
                </StyledButton>
              ) : (
                <Tooltip
                  title={
                    gong?.isConnected ||
                    (salesCallIntegration?.isConnected &&
                      salesCallIntegration?.integrationType !==
                        SalesCallIntegrationEnum.CLARI)
                      ? "you can't connect more than 1 sales call monitoring integration"
                      : ""
                  }
                >
                  <span>
                    <StyledButton
                      variant="tertiary"
                      onClick={() =>
                        this.showSalesCallPopup(
                          true,
                          SalesCallIntegrationEnum.CLARI
                        )
                      }
                      disabled={
                        gong?.isConnected ||
                        (salesCallIntegration?.isConnected &&
                          salesCallIntegration?.integrationType !==
                            SalesCallIntegrationEnum.CLARI)
                      }
                    >
                      Connect
                    </StyledButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <img
                  src={`/resources/images/grain.png`}
                  className={clsx(classes.socialIcon, classes.slack)}
                  alt="grain-icon"
                />
                Grain
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Suggest relevant content and collateral to share to your sales
                team after calls. Extract marketing insights from sales calls.
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {salesCallIntegration?.isConnected &&
              salesCallIntegration?.integrationType ===
                SalesCallIntegrationEnum.GRAIN ? (
                <StyledButton
                  onClick={this.disconnectGrainIntegration}
                  variant="textsecondary"
                  className={classes.textButton}
                >
                  Disconnect
                </StyledButton>
              ) : (
                <Tooltip
                  title={
                    salesCallIntegration?.isConnected &&
                    salesCallIntegration?.integrationType !==
                      SalesCallIntegrationEnum.GRAIN
                      ? "you can't connect more than 1 sales call monitoring integration"
                      : ""
                  }
                >
                  <span>
                    <StyledButton
                      variant="tertiary"
                      onClick={this.initiateGrainIntegration}
                      disabled={
                        salesCallIntegration?.isConnected &&
                        salesCallIntegration?.integrationType !==
                          SalesCallIntegrationEnum.GRAIN
                      }
                    >
                      Connect
                    </StyledButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <img
                  src={images.zoomIcon}
                  className={clsx(classes.socialIcon, classes.slack)}
                  alt="zoom-icon"
                />
                Zoom
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Suggest relevant content and collateral to share to your sales
                team after calls. Extract marketing insights from sales calls
                recordings
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {salesCallIntegration?.isConnected &&
              salesCallIntegration?.integrationType ===
                SalesCallIntegrationEnum.ZOOM ? (
                <StyledButton
                  onClick={this.disconnectZoomIntegration}
                  variant="textsecondary"
                  className={classes.textButton}
                >
                  Disconnect
                </StyledButton>
              ) : (
                <Tooltip
                  title={
                    salesCallIntegration?.isConnected &&
                    salesCallIntegration?.integrationType !==
                      SalesCallIntegrationEnum.ZOOM
                      ? "you can't connect more than 1 sales call monitoring integration"
                      : ""
                  }
                >
                  <span>
                    <StyledButton
                      variant="tertiary"
                      onClick={this.initiateZoomIntegration}
                      disabled={
                        salesCallIntegration?.isConnected &&
                        salesCallIntegration?.integrationType !==
                          SalesCallIntegrationEnum.ZOOM
                      }
                    >
                      Connect
                    </StyledButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <FontAwesomeIcon
                  icon={faYoutube}
                  className={clsx(classes.socialIcon)}
                  style={{ color: colors.youtube }}
                />
                YouTube
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Publish video interviews to YouTube
              </Typography>
              <FormHelperText>
                By signing in, you agree to{" "}
                <a href="https://www.youtube.com/t/terms">
                  YouTube's Terms of Service
                </a>
                .
              </FormHelperText>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {youtube?.isConnected ? (
                <Fragment>
                  <StyledButton
                    onClick={() =>
                      this.disconnectAccount(socialMediaType.YOUTUBE)
                    }
                    variant="textsecondary"
                    className={classes.textButton}
                  >
                    Disconnect
                  </StyledButton>
                </Fragment>
              ) : (
                <StyledButton
                  variant="tertiary"
                  onClick={this.connectToYouTube}
                  startIcon={<GoogleIcon />}
                >
                  Sign in with Google
                </StyledButton>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <img
                  src={`/resources/images/google_analytics.svg`}
                  className={clsx(classes.socialIcon, classes.slack)}
                  alt="google-search-console-icon"
                />
                Google Analytics (GA4)
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Pull in page views, sessions, trends and get automated alerts
                when pages need your attention for a content refresh.
              </Typography>
              {ga?.isConnected &&
                (ga?.connectedTo ? (
                  <Typography
                    variant="bodys"
                    paragraph
                    className={classes.connectedAccount}
                  >
                    Connected to {ga?.connectedTo}
                  </Typography>
                ) : (
                  <Typography
                    variant="bodys"
                    paragraph
                    className={classes.connectedAccount}
                  >
                    Not connected
                  </Typography>
                ))}
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {ga?.isConnected ? (
                <Fragment>
                  <StyledButton
                    onClick={() =>
                      this.disconnectAccount(socialMediaType.GOOGLE_ANALYTICS)
                    }
                    variant="textsecondary"
                    className={classes.textButton}
                  >
                    Disconnect
                  </StyledButton>

                  <StyledButton
                    onClick={this.toggleGoogleAnalyticsDialog}
                    variant="tertiary"
                  >
                    Change Account
                  </StyledButton>
                </Fragment>
              ) : (
                <StyledButton
                  variant="tertiary"
                  onClick={this.oauthGoogle}
                  startIcon={<GoogleIcon />}
                >
                  Sign in with Google
                </StyledButton>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <img
                  src={`/resources/images/google_search_console.svg`}
                  className={clsx(classes.socialIcon, classes.slack)}
                  alt="google-search-console-icon"
                />
                Google Search Console
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Amplify your stats and automatically request page indexing for
                new posts
              </Typography>
              {gsc?.isConnected &&
                (gsc.siteURL ? (
                  <Typography
                    variant="bodys"
                    paragraph
                    className={classes.connectedAccount}
                  >
                    Connected to {gsc.siteURL.replace("sc-domain:", "")}
                  </Typography>
                ) : (
                  <Typography
                    variant="bodys"
                    paragraph
                    className={classes.connectedAccount}
                  >
                    Not connected
                  </Typography>
                ))}
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {gsc?.isConnected ? (
                <Fragment>
                  <StyledButton
                    onClick={() =>
                      this.disconnectAccount(
                        socialMediaType.GOOGLE_SEARCH_CONSOLE
                      )
                    }
                    variant="textsecondary"
                    className={classes.textButton}
                  >
                    Disconnect
                  </StyledButton>

                  <StyledButton onClick={this.connectToGSC} variant="tertiary">
                    Change Account
                  </StyledButton>
                </Fragment>
              ) : (
                <StyledButton
                  variant="tertiary"
                  onClick={this.connectToGSC}
                  startIcon={<GoogleIcon />}
                >
                  Sign in with Google
                </StyledButton>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <img
                  src={images.clearBitIcon}
                  className={clsx(classes.socialIcon)}
                  alt="clearbit-icon"
                />
                Clearbit
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Identify accounts consuming content on your site. Needed
                alongside CRM for revenue attribution.
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {clearbit?.isConnected ? (
                <Fragment>
                  <StyledButton
                    onClick={() =>
                      this.disconnectAccount(socialMediaType.CLEARBIT)
                    }
                    variant="textsecondary"
                    className={classes.textButton}
                  >
                    Disconnect
                  </StyledButton>

                  <StyledButton
                    onClick={this.toggleClearBitDialog}
                    variant="tertiary"
                  >
                    Change Account
                  </StyledButton>
                </Fragment>
              ) : (
                <StyledButton
                  variant="tertiary"
                  onClick={this.toggleClearBitDialog}
                >
                  Connect
                </StyledButton>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <img
                  src={images.apolloIcon}
                  className={clsx(classes.socialIcon)}
                  alt="clearbit-icon"
                />
                Apollo
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Push accounts engaged with your content directly into Apollo for
                streamlined lead management and targeted sales outreach.
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              {apollo?.isConnected ? (
                <Fragment>
                  <StyledButton
                    onClick={() =>
                      this.disconnectAccount(socialMediaType.APOLLO)
                    }
                    variant="textsecondary"
                    className={classes.textButton}
                  >
                    Disconnect
                  </StyledButton>

                  <StyledButton
                    onClick={this.toggleApolloDialog}
                    variant="tertiary"
                  >
                    Change Account
                  </StyledButton>
                </Fragment>
              ) : (
                <StyledButton
                  variant="tertiary"
                  onClick={this.toggleApolloDialog}
                >
                  Connect
                </StyledButton>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              ms={6}
              md={8}
              className={classes.accountContainer}
            >
              <Typography variant="bodym" paragraph className={classes.section}>
                <img
                  src={images.zoomInfoIcon}
                  className={clsx(classes.socialIcon)}
                  alt="clearbit-icon"
                />
                ZoomInfo
              </Typography>

              <Typography variant="bodym" className={classes.cardSubtitle}>
                Track identified website visitors using ZoomInfo's B2B data
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              className={classes.connectButtonContainer}
            >
              <StyledButton
                variant="tertiary"
                onClick={this.toggleZoomInfoDialog}
              >
                Connect
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
        {showConnectToSalesCallDialog && (
          <SalesCallConnectDialog
            open={showConnectToSalesCallDialog}
            integrationType={integrationType}
            isSalesCallConnected={
              salesCallIntegration?.isConnected &&
              salesCallIntegration?.integrationType === integrationType
            }
            sendCallAnalysisMail={salesCallIntegration?.sendCallAnalysisMail}
            showConnectedAccount={true}
            publicationId={publicationId}
            disconnectSalesCall={this.closeSalesCallPopup}
            closeSalesCallConnectDialog={this.closeSalesCallPopup}
          />
        )}
        {showCompanyDetailsDialog && (
          <StyledDialog
            open={showCompanyDetailsDialog}
            maxWidth="md"
            body="Add a description of what your company does, who you serve, and it's offering/features. This will help us extract the right information from your call transcripts."
            title={<Typography variant="h400">Add Company Details</Typography>}
            successButtonName={"Confirm"}
            successCallback={this.redirectToCompanyDetails}
            cancelCallback={this.closeCompanyDetailsDialog}
          />
        )}
        {showAccountsDialog && (
          <StyledDialog
            open={showAccountsDialog}
            maxWidth="md"
            body={
              <Grid container direction="column" className={classes.padding10}>
                {!prepopulateAccountAccess.includes(accountType) && (
                  <>
                    <Grid
                      item
                      container
                      direction="row"
                      className={classes.marginTop20}
                    >
                      <Grid item xs={10}>
                        <Typography variant="bodym">
                          {this.getLabelForAutoPostToSocial(accountType)}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        container
                        justifyContent="flex-end"
                        xs={2}
                        style={{
                          alignItems: "center"
                        }}
                      >
                        <StyledSwitch
                          checked={publishToSocialFromPreviousPost[accountType]}
                          onChange={() =>
                            this.togglePrePopulateSocialAccountsFromLastPost(
                              accountType,
                              publishToSocialFromPreviousPost[accountType]
                            )
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item>
                      <hr
                        color={colors.horizontalRule}
                        className={classes.marginTop20}
                      ></hr>
                    </Grid>
                  </>
                )}

                <Grid item>
                  {accounts && accounts.length > 0 && (
                    <>
                      {accountType === socialMediaType.LINKED_IN &&
                        linkedInOrgs.length > 0 && (
                          <Grid
                            container
                            direction="row"
                            className={classes.linkedinOrgContainer}
                          >
                            <StyledButton
                              onClick={() => this.setLinkedInOrgDialog()}
                              variant="textsecondary"
                              className={classes.textButton}
                            >
                              <FontAwesomeIcon
                                icon={faBuilding}
                                className={clsx(classes.orgIcon)}
                              />
                              Organizations
                            </StyledButton>
                          </Grid>
                        )}
                      {accounts.map((account: any, index: any) => {
                        return (
                          <Grid
                            container
                            key={index}
                            direction="row"
                            className={classes.connectedAccountsContainer}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid
                              item
                              sm={
                                accountType === socialMediaType.LINKED_IN
                                  ? 6
                                  : [
                                      socialMediaType.WEBFLOW,
                                      socialMediaType.HUBSPOT
                                    ].includes(accountType)
                                  ? 8
                                  : 9
                              }
                              className={classes.accountNameContainer}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row"
                                }}
                              >
                                {![
                                  socialMediaType.WEBFLOW,
                                  socialMediaType.HUBSPOT
                                ].includes(accountType) &&
                                  this.getAvatar(accountType, account)}
                                <Tooltip
                                  title={account?.permissions?.join(", ")}
                                  placement="right"
                                  disableHoverListener={
                                    accountType !== socialMediaType.LINKED_IN
                                  }
                                >
                                  <Typography
                                    variant="bodym"
                                    style={{
                                      paddingLeft: space.SMALL,
                                      color:
                                        !account.connected &&
                                        accountType ===
                                          socialMediaType.LINKED_IN
                                          ? colors.fontSecondary
                                          : "inherit"
                                    }}
                                  >
                                    {account.name}
                                    {account.siteName && (
                                      <>
                                        <br />
                                        <Typography
                                          variant="bodys"
                                          color={designColors.grayScale[60]}
                                        >
                                          {account.siteName}
                                        </Typography>
                                      </>
                                    )}
                                  </Typography>
                                </Tooltip>
                              </div>
                              {!account.connected &&
                                accountType === socialMediaType.LINKED_IN && (
                                  <Tooltip title="Login expired">
                                    <ErrorIcon className={classes.errorIcon} />
                                  </Tooltip>
                                )}
                            </Grid>
                            <Grid
                              item
                              container
                              justifyContent="flex-end"
                              sm={
                                accountType === socialMediaType.LINKED_IN
                                  ? 6
                                  : [
                                      socialMediaType.WEBFLOW,
                                      socialMediaType.HUBSPOT
                                    ].includes(accountType)
                                  ? 4
                                  : 3
                              }
                              style={{ display: "flex" }}
                            >
                              <Grid
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                {(accountType === socialMediaType.WEBFLOW ||
                                  (accountType === socialMediaType.HUBSPOT &&
                                    !isCRM)) && (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    style={{ marginLeft: space.SMALL }}
                                    onClick={() =>
                                      accountType === socialMediaType.WEBFLOW
                                        ? this.displayImportConfirmationDialog(
                                            accountType,
                                            account.collectionId
                                          )
                                        : this.displayImportConfirmationDialog(
                                            accountType,
                                            hubspot.accounts[index].blog.id
                                          )
                                    }
                                  >
                                    Import
                                  </Button>
                                )}
                                {[socialMediaType.WEBFLOW].includes(
                                  accountType
                                ) &&
                                  account?.version === WEBFLOW_VERSION && (
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      style={{ marginLeft: space.SMALL }}
                                      onClick={() =>
                                        this.handleWebflowConnect(
                                          account.collectionId
                                        )
                                      }
                                    >
                                      Remap
                                    </Button>
                                  )}

                                {[socialMediaType.HUBSPOT].includes(
                                  accountType
                                ) &&
                                  !isCRM && (
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      style={{ marginLeft: space.SMALL }}
                                      onClick={() =>
                                        this.showHubSpotConfigDialog(index)
                                      }
                                    >
                                      Configure
                                    </Button>
                                  )}

                                {((!account.connected &&
                                  accountType === socialMediaType.LINKED_IN) ||
                                  (accountType === socialMediaType.WEBFLOW &&
                                    account?.version !== WEBFLOW_VERSION)) && (
                                  <>
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      onClick={() =>
                                        this.handleAccountConnect(
                                          accountType,
                                          true,
                                          account.userId
                                        )
                                      }
                                      style={{ marginLeft: space.SMALL }}
                                    >
                                      Reconnect
                                    </Button>
                                  </>
                                )}

                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="secondary"
                                  style={{ marginLeft: space.SMALL }}
                                  onClick={() =>
                                    this.disconnectAccount(
                                      accountType,
                                      account.userId || account.collectionId,
                                      account.isCRM
                                    )
                                  }
                                >
                                  Disconnect
                                </Button>
                              </Grid>
                            </Grid>
                            {accountType === socialMediaType.LINKED_IN && (
                              <Grid
                                item
                                container
                                style={{
                                  marginTop: space.SMALL,
                                  marginBottom: space.SMALL,
                                  alignItems: "center"
                                }}
                              >
                                <Grid
                                  item
                                  sm={6}
                                  style={{
                                    display: "flex",
                                    margin: "0 auto",
                                    alignItems: "center",
                                    minHeight: 40
                                  }}
                                >
                                  <ShowOrAddGroup
                                    value={account.group}
                                    options={linkedin.groups}
                                    handleChipChange={(event, newValues) => {
                                      this.handleChipChange(
                                        event,
                                        newValues,
                                        account.userId
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  sm={6}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    paddingLeft: "5%"
                                  }}
                                >
                                  <SocialSellingRoles
                                    value={
                                      socialSellingRoles[account.vanityName] ||
                                      []
                                    }
                                    vanityName={account.vanityName}
                                    publicationId={publicationId}
                                    onChange={(roles) => {
                                      socialSellingRoles[account.vanityName] =
                                        roles || [];
                                      this.setState({ socialSellingRoles });
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        );
                      })}
                    </>
                  )}
                  {accountType === socialMediaType.MEDIUM && (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.connectedAccountsContainer}
                    >
                      <Grid
                        item
                        sm={9}
                        className={classes.accountNameContainer}
                      >
                        <StyledInput
                          type="text"
                          size="medium"
                          fullWidth
                          value={newMediumToken}
                          placeholder="2e1c186bbbb63831924684c57418ef1a6120bc5ea147e851bb434a22bb2e99a98"
                          name="newMediumToken"
                          helperText={mediumErrorMessage}
                          error={mediumErrorMessage}
                          onChange={(e: any) =>
                            this.setState({ newMediumToken: e.target.value })
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        container
                        justifyContent="flex-end"
                        sm={3}
                        style={{ display: "flex" }}
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => this.handleAccountConnect(accountType)}
                        >
                          Add new account
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {accountType !== socialMediaType.FACEBOOK &&
                  accountType !== socialMediaType.MEDIUM &&
                  !isCRM && (
                    <>
                      <Grid item>
                        <StyledButton
                          variant="textprimary"
                          onClick={() =>
                            this.handleAccountConnect(
                              accountType,
                              false,
                              "",
                              isCRM
                            )
                          }
                          startIcon={<AddCircleIcon />}
                        >
                          {accountType === socialMediaType.WEBFLOW
                            ? "Add new collection"
                            : "Add new account"}
                        </StyledButton>
                        {/* <Button

                          size="small"
                          className={classes.addTwitterButton}
                          startIcon={<AddCircleIcon color="primary" />}
                          onClick={() => this.handleAccountConnect(accountType)}
                        >
                          {accountType === socialMediaType.WEBFLOW
                            ? "Add new collection"
                            : "Add new account"}
                        </Button> */}
                      </Grid>
                      {accountType === socialMediaType.LINKED_IN &&
                        !isLimitExceeded(
                          features.SOCIAL_MEDIA_ACCOUNTS,
                          this.context,
                          publicationId,
                          this.getConnectedLinkedInAccountsSize()
                        ) && (
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              marginTop: 20
                            }}
                          >
                            <Grid item xs={12}>
                              <Divider
                                style={{
                                  marginBottom: space.SMALL
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="h200" paragraph>
                                Employee Advocacy
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="bodym" paragraph>
                                Share this link with your co-workers to post,
                                comment, and like on their behalf. Here is a{" "}
                                <a
                                  href="https://letterdrop.notion.site/How-to-ask-people-to-connect-LinkedIn-to-Letterdrop-ee1aa83612ae4068b1aeb5b79e0dc2d5"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  guide
                                </a>{" "}
                                on how to get internal buy-in within your
                                company. Note that LinkedIn may occassionally
                                disconnect accounts as they roll out security
                                updates and you will need to request a reconnect
                                when that happens.
                              </Typography>
                            </Grid>

                            <Grid container xs={12} alignItems="center">
                              <Grid item xs={10}>
                                <InputBase
                                  style={{
                                    backgroundColor: grey[100],
                                    color: colors.fontSecondary,
                                    borderRadius: 5,
                                    padding: space.SMALL,
                                    fontFamily: "Inconsolata",
                                    width: "100%"
                                  }}
                                  value={linkedinExternalConnectUrl}
                                  contentEditable={false}
                                  onFocus={(event) => event.target.select()}
                                  fullWidth
                                />
                              </Grid>

                              <Grid
                                item
                                xs={1}
                                style={{ paddingLeft: space.XXS }}
                              >
                                <CopyToClipboard
                                  onCopy={true}
                                  text={linkedinExternalConnectUrl}
                                >
                                  <IconButton size="large">
                                    <FileCopy />
                                  </IconButton>
                                </CopyToClipboard>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                style={{ paddingLeft: space.XXS }}
                              >
                                <IconButton
                                  onClick={() => {
                                    this.setState({
                                      showLinkedInPermissionDialog: true
                                    });
                                  }}
                                  edge="start"
                                  size="large"
                                >
                                  <SettingsIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                    </>
                  )}
              </Grid>
            }
            title={<Typography variant="h400">{title}</Typography>}
            closeCallback={this.toggleShowAccounts}
          />
        )}
        <StyledDialog
          open={showImportConfirmationDialog}
          title="Are you sure?"
          body="This will initiate an import. It will override and update existing posts if they have the same slug."
          successButtonName="Yes, I'm sure "
          cancelButtonName="Cancel"
          successCallback={this.importFromSource}
          cancelCallback={() => {
            this.setState({
              showImportConfirmationDialog: false,
              importAccountType: "",
              importParam: ""
            });
          }}
        />

        {openSlackChannelDialog && (
          <SelectSlackChannel
            open={openSlackChannelDialog}
            publicationId={publicationId}
            handleClose={this.toggleSlackChannelDialog}
          />
        )}
        {openHubspotCRMDialog && (
          <HusbpotCRMConfiguration
            open={openHubspotCRMDialog}
            publicationId={publicationId}
            handleClose={this.toggleHubspotCRMDialog}
          />
        )}
        {openSalesforceCRMDialog && (
          <SalesforceCRMConfiguration
            open={openSalesforceCRMDialog}
            publicationId={publicationId}
            handleClose={this.toggleSalesforceCRMDialog}
          />
        )}

        {promptMediumDialog && (
          <MediumConnect
            mediumHandle={medium}
            publicationId={publicationId}
            onClose={this.toggleMediumDialog}
            onSave={this.closeAndReload}
          />
        )}
        {promptDevtoDialog && (
          <DevtoConnect
            devtoTokenDetail={devto}
            publicationId={publicationId}
            onClose={this.toggleDevtoDialog}
            onSave={this.closeDevtoDialogAndReload}
          />
        )}
        {promptClearBitDialog && (
          <ClearbitConnect
            clearbitKey={clearbit?.apiKey || ""}
            publicationId={publicationId}
            onClose={this.toggleClearBitDialog}
            onSave={this.closeClearBitDialogAndReload}
          />
        )}
        {promptApolloDialog && (
          <ApolloConnect
            apolloApiKey={apollo?.apiKey || ""}
            publicationId={publicationId}
            onClose={this.toggleApolloDialog}
            onSave={this.closeApolloDialogAndReload}
          />
        )}
        {promptZoomInfoDialog && (
          <ZoomInfoConnect
            publicationId={publicationId}
            onClose={this.toggleZoomInfoDialog}
          />
        )}
        {promptHashnodeDialog && (
          <HashnodeConnect
            hashnodeTokenDetail={hashnode}
            publicationId={publicationId}
            onClose={this.toggleHashnodeDialog}
            onSave={this.closeHashnodeDialogAndReload}
          />
        )}
        {promptGoogleAnalyticsDialog && (
          <GoogleAnalyticsConnect
            googleAnalyticsTokenDetail={ga}
            publicationId={publicationId}
            onClose={this.toggleGoogleAnalyticsDialog}
            onSave={this.closeGoogleAnalyticsDialogAndReload}
          />
        )}
        {showHubSpotConfig && (
          <HubSpotConnect
            open={showHubSpotConfig}
            publicationId={publicationId}
            blogs={hubspot.accounts[selectedHubspotIndex].blogs}
            syncPosts={hubspot.accounts[selectedHubspotIndex].syncPosts}
            selectedBlog={hubspot.accounts[selectedHubspotIndex].blog}
            hubspotId={hubspot.accounts[selectedHubspotIndex]._id}
            onClose={this.closeHubspotConfigDialog}
            onSuccess={() => this.closeHubspotConfigDialog(true)}
          />
        )}

        {showWordPressConnectDialog && (
          <StyledDialog
            open={showWordPressConnectDialog}
            body={
              <Grid container direction="column">
                <Grid style={{ marginBottom: space.MEDIUM }}>
                  <Typography>WordPress Site URL</Typography>
                  <Typography
                    variant="bodys"
                    style={{ color: designColors.grayScale[60] }}
                  >
                    Make sure you're signed into WordPress as an admin before
                    proceeding. You will be directed to WordPress to create an
                    application password for Letterdrop.
                  </Typography>

                  <StyledInput
                    autoFocus
                    style={{ width: "100%", marginTop: 10 }}
                    size="medium"
                    placeholder="https://acme.wpengine.com"
                    value={wordPressURL}
                    onChange={(event: any) => {
                      this.setState({
                        wordPressURL: event.target.value
                      });
                    }}
                    error={wordPressURL && !isURL(wordPressURL)}
                  />

                  <div
                    style={{
                      height: space.XXS
                    }}
                  >
                    <Typography
                      variant="bodys"
                      style={{
                        color: designColors.alert[50]
                      }}
                    >
                      {wordPressURL && !isURL(wordPressURL)
                        ? "Enter a valid URL"
                        : ""}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            }
            title="Connect to WordPress"
            successButtonName={"Create password"}
            successCallback={() => {
              this.connectToBlog(socialMediaType.WORDPRESS);
            }}
            cancelCallback={() => {
              this.setState({
                showWordPressConnectDialog: false
              });
            }}
            disableSuccessButton={
              !wordPressURL || (wordPressURL && !isURL(wordPressURL))
            }
          />
        )}

        {showLinkedInPermissionDialog && (
          <StyledDialog
            open={this.state.showLinkedInPermissionDialog}
            body={
              <Grid container direction="column">
                <Grid style={{ marginBottom: space.MEDIUM }}>
                  <Typography variant="bodym">
                    What would you like to do on behalf of people who connect
                    their LinkedIn? We'll only allow these actions for anyone
                    who signs up with the link.
                  </Typography>
                </Grid>

                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">Linkedin Permissions</FormLabel>
                  <FormGroup style={{ justifyContent: "space-between" }}>
                    <FormControlLabel
                      control={
                        <StyledSwitch
                          checked={linkedInPermissions.like}
                          onChange={() => {
                            this.setState({
                              linkedInPermissions: {
                                ...linkedInPermissions,
                                like: !linkedInPermissions.like
                              }
                            });
                          }}
                          name="like"
                          disabled={true}
                        />
                      }
                      label={
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography variant="bodyl">
                            Like on their behalf with approval
                          </Typography>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <StyledSwitch
                          checked={linkedInPermissions.comment}
                          onChange={() => {
                            this.setState({
                              linkedInPermissions: {
                                ...linkedInPermissions,
                                comment: !linkedInPermissions.comment
                              }
                            });
                          }}
                          name="comment"
                        />
                      }
                      label={
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography variant="bodyl">
                            Comment on their behalf with approval
                          </Typography>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <StyledSwitch
                          checked={linkedInPermissions.post}
                          onChange={() => {
                            this.setState({
                              linkedInPermissions: {
                                ...linkedInPermissions,
                                post: !linkedInPermissions.post
                              }
                            });
                          }}
                          name="post"
                        />
                      }
                      label={
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography variant="bodyl">
                            Post on their behalf with approval
                          </Typography>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <StyledSwitch
                          checked={linkedInPermissions.extension}
                          onChange={() => {
                            this.setState({
                              linkedInPermissions: {
                                ...linkedInPermissions,
                                extension: !linkedInPermissions.extension
                              }
                            });
                          }}
                          name="extension"
                        />
                      }
                      label={
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography variant="bodyl">
                            Request Chrome extension download
                          </Typography>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            }
            title={
              <Typography variant="h400">
                LinkedIn Advocacy Permissions
              </Typography>
            }
            successButtonName="Save"
            successCallback={() => {
              this.saveLinkedInPermissions();
            }}
            cancelCallback={() => {
              this.setState({
                showLinkedInPermissionDialog: false
              });
            }}
          />
        )}

        {showLinkedInOrgDialog && (
          <StyledDialog
            open={showLinkedInOrgDialog}
            body={
              <Grid container direction="column">
                <Typography
                  variant="bodys"
                  style={{ paddingBottom: space.MEDIUM }}
                >
                  Organizations that can post and like.
                </Typography>

                <StyledAutocomplete
                  value={selectedOrgs}
                  onChange={this.onLinkedInOrgSelected}
                  options={linkedInOrgs}
                  isCheckboxSelected={this.isLinkedInOrgChecked}
                  showAvatar={false}
                />
              </Grid>
            }
            title={
              <Typography variant="h400">Allowed Organizations</Typography>
            }
            successButtonName={"Save"}
            successCallback={() => {
              this.updateActiveLinkedInOrgs();
            }}
            cancelCallback={() => {
              this.setState({
                showLinkedInOrgDialog: false
              });
            }}
          />
        )}

        {showLinkedInReconnectDialog && (
          <StyledDialog
            open={showLinkedInReconnectDialog}
            maxWidth="md"
            body={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: space.MEDIUM
                  }}
                >
                  <Typography variant="bodym">
                    {`If you're ${
                      linkedin?.accounts?.find(
                        (linkedinAccount) =>
                          linkedinAccount.userId === reconnectAccountId
                      )?.name || ""
                    }, click here to reconnect:`}{" "}
                  </Typography>
                  <StyledButton
                    variant="outlined"
                    size="small"
                    onClick={() => this.handleLinkedInConnect()}
                  >
                    Reconnect
                  </StyledButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography variant="bodym">
                    {`If you're not , you can send them an email requesting them to reconnect:`}{" "}
                  </Typography>
                  <StyledButton
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      this.sendReconnectRequest(reconnectAccountId);
                    }}
                  >
                    Send email
                  </StyledButton>
                </div>
              </div>
            }
            title="Reconnect"
            cancelCallback={() => {
              this.setState({
                showLinkedInReconnectDialog: false,
                reconnectAccountId: ""
              });
            }}
          />
        )}
      </Container>
    );
  }
}

interface ShowOrAddGroupProps {
  value?: string[];
  options: string[];
  handleChipChange: (event: any, selectedData: string[]) => void;
}

interface ShowOrAddGroupState {
  selectedData: string[];
  focused: boolean;
}

class ShowOrAddGroup extends React.Component<
  ShowOrAddGroupProps,
  ShowOrAddGroupState
> {
  inputRef: RefObject<HTMLInputElement | null> = React.createRef();
  constructor(props: ShowOrAddGroupProps) {
    super(props);
    this.state = {
      selectedData: props.value || [],
      focused: false
    };
  }

  handleClick = () => {
    this.setState({ focused: true }, () => this.inputRef?.current?.focus());
  };

  handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newSelectedData: string[]
  ) => {
    if (newSelectedData !== null) {
      this.setState({ selectedData: newSelectedData }, (event) =>
        this.props.handleChipChange(event, newSelectedData)
      );
    }
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  render() {
    const { selectedData, focused } = this.state;

    return (
      <div style={{ verticalAlign: "middle", width: "100%" }}>
        {!focused &&
          (selectedData.length === 0 ? (
            <Tooltip title="Click to add group">
              <div>
                <StyledButton
                  startIcon={<AddIcon />}
                  variant="textsecondary"
                  onClick={this.handleClick}
                  style={{ cursor: "pointer" }}
                >
                  Add Group
                </StyledButton>
              </div>
            </Tooltip>
          ) : (
            <>
              {selectedData.map((chip) => (
                <StyledChip
                  key={chip}
                  label={chip}
                  onDelete={() => {
                    const newSelectedData = selectedData.filter(
                      (item) => item !== chip
                    );
                    this.setState({ selectedData: newSelectedData }, (event) =>
                      this.props.handleChipChange(event, newSelectedData)
                    );
                  }}
                />
              ))}
              <Tooltip title="Click to add/edit group">
                <IconButton onClick={this.handleClick} size="small">
                  <AddIcon
                    style={{ verticalAlign: "middle", cursor: "pointer" }}
                  />
                </IconButton>
              </Tooltip>
            </>
          ))}
        {focused && (
          <Autocomplete
            style={{ alignSelf: "stretch", width: "100%" }}
            multiple
            fullWidth
            freeSolo
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                padding: 0
              }
            }}
            value={selectedData}
            onChange={this.handleChange}
            options={this.props.options}
            renderInput={(
              params: React.InputHTMLAttributes<HTMLInputElement>
            ) => (
              <StyledInput
                {...params}
                inputRef={this.inputRef}
                onBlur={this.handleBlur}
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <StyledChip
                  label={option}
                  key={option}
                  variant="gray"
                  {...getTagProps({ index })}
                  onDelete={() => {
                    const newSelectedData = selectedData.filter(
                      (item) => item !== option
                    );
                    this.setState({ selectedData: newSelectedData }, (event) =>
                      this.props.handleChipChange(event, newSelectedData)
                    );
                  }}
                />
              ))
            }
          />
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ConnectedAccounts));
