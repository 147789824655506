// @ts-nocheck
import { grey } from "@mui/material/colors";
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  container: {
    padding: 0
  },

  cardTitle: {
    fontWeight: "bold",
    fontSize: 20
  },

  row: {
    margin: "0 auto",
    padding: "10px 0px"
  },

  emailFontResetButton: {
    marginTop: 45,
    marginLeft: 10
  },

  blogFontResetButton: {
    marginTop: 15,
    marginLeft: 10
  },

  color: {
    width: 15,
    height: 15,
    borderRadius: "2px",
    cursor: "pointer",
    margin: "auto 10px",
    background: "rgb(0,0,0,0)",
    border: "1px solid rgb(128, 123, 123)"
  },

  hexColorCode: {
    color: colors.fontSecondary,
    margin: "auto 0"
  },

  marginAuto: {
    margin: "auto 0"
  },

  input: {
    width: "100%",
    backgroundColor: colors.inputBackground,
    border: "none",
    color: colors.fontInput,
    fontSize: 16,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontInput
    }
  },

  width100: {
    width: "100%"
  },

  resetButton: {
    marginLeft: 20,
    marginTop: 5
  },

  paidOnly: {
    color: colors.primary,
    marginLeft: 5,
    fontSize: 12
  },

  marginLeft10: {
    marginLeft: 10
  },

  reset: {
    marginLeft: 8
  },

  heading: {
    fontWeight: 700,
    padding: "10px 0px"
  },

  subHeading: {
    padding: "10px 0px"
  },

  code: {
    fontFamily: "Inconsolata",
    background: grey[50],
    marginBottom: 10
  },

  injectionHeading: {
    display: "inline-flex"
  },

  toggle: {
    marginLeft: 20
  },

  requireLogin: {
    display: "flex",
    margin: "auto 0",
    flexDirection: "row",
    alignItems: "center"
  }
});
export default styles;
