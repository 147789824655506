// @ts-nocheck
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { designColors } from "../../Themes/Colors";

export function NavItem({ children, icon, collapse = false, ...other }: any) {
  return (
    <ListItemButton
      
      disableRipple
      
      disableTouchRipple
      {...other}
      
      sx={{
        borderRadius: "12px",
        
        color: "#6F767E",
        
        padding: "12px",
        
        width: collapse ? "fit-content" : "100%",
        
        marginBottom: "8px",
        "&.Mui-selected": {
          color: designColors.grayScale[100]
        }
      }}
    >
      {icon && (
        <ListItemIcon
          
          sx={{
            minWidth: "24px"
          }}
        >
          {icon}
        </ListItemIcon>
      )}
      
      {children}
    </ListItemButton>
  );
}

export function NavSubItem({ children, icon, ...other }: any) {
  return (
    <ListItem disablePadding>
      {icon && (
        <ListItemIcon
          
          style={{
            minWidth: "34px"
          }}
        >
          {icon}
        </ListItemIcon>
      )}

      <NavItem
        
        disableRipple
        
        disableTouchRipple
        
        style={{
          marginBottom: "0px",
          
          height: "40px"
        }}
        
        {...other}
      >
        {children}
      </NavItem>
    </ListItem>
  );
}

export function NavItemText({ children, ...other }: any) {
  return (
    <ListItemText
      {...other}
      
      sx={{
        "&.MuiListItemText-root": {
          marginTop: "0px",
          
          marginBottom: "0px"
        }
      }}
      
      primary={children}
    ></ListItemText>
  );
}
