// @ts-nocheck
import { Component, Suspense } from "react";
import { withStyles } from "@mui/styles";
import style from "./styles/KeywordResearchStyle";
import { Autocomplete, Container, Grid, Typography } from "@mui/material";

import SearchBar from "../design/components/SearchBar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import API from "../Services/Api";
import Skeleton from "@mui/material/Skeleton";
import { notify } from "../Components/CustomNotifications";
import { formatNumber } from "../Utils/UserUtils";
import images from "../Themes/Images";

import OrganicPosts from "../Components/OrganicPosts";

import RelatedKeywords from "../Components/RelatedKeywords";

import TopKeywords from "../Components/TopKeywords";

import TopPages from "../Components/TopPages";
import { KeywordDataSource, keywordResearchType } from "../Utils/Types";

import { Helmet } from "react-helmet";

import StyledDialog from "../design/components/StyledDialog";

import SearchTrafficGraph from "../Components/SearchTrafficGraph";

import countryList from "react-select-country-list";

import StyledInput from "../design/components/StyledInput";

import Page404 from "./Page404";

import UpgradePlanDialog from "../design/components/UpgradePlanDialog";
const psl = require("psl");

const api = API.create();
const DefaultPageSize = 5;

const fetchKeyworDatafrom = KeywordDataSource.SEMRUSH;

const DIFFICULTY =
  "Difficulty is how hard it is to get your blog post to rank for this keyword. It depends on the domain authority, page authority, and content quality.";
const CPC =
  "CPC or cost-per-click is the average cost of a click for this keyword if you paid for search ads. Your ads might cost a different amount based on your site’s domain authority and ad quality.";

const DOMAIN_RANK =
  "Domain Rank is where this domain stands in terms of traffic as compared to other domains.";

const BACKLINKS =
  "Backlinks are the number of web pages that link to this domain. The more high-quality websites that link to you, the more reputable your website is in Google’s eyes. More quality backlinks boost your ranking on Google.";

const ESTIMATED_VISITS =
  "Estimated Visits is the number of organic monthly visitors to this domain from Google searches, ie. people who search for something and end up on this website.";

const DOMAIN_AUTHORITY =
  "Domain Authority is a score that tells you how likely a website is to rank on Google. Given two equally useful pages, the one with a higher score will rank higher in search results. This also applies to Google Ads. The better the domain authority, the less you need to pay to big against other advertisers for a top spot in Google search results.";

const SEO_DIFFICULTY =
  "SEO Difficulty represents how hard it is for your website to rank for this search. The higher the number, the more competitive.";

const TRAFFIC =
  "Traffic is the estimated number of visitors to this page from this search on Google.";

const POSITION =
  "Position is the position of the page in Google search results. Ideally, your page is in the top 3, or at least top 10 to see any meaningful traffic. Focus on rewriting pages between 10 and 20 to rank them in the top 10.";

const REFERRING_DOMAIN =
  "Referring Domain is the website that is linking to your site. Google gives you a higher domain authority when other high domain authority sites link to you.";

const default_location = { value: "US", label: "United States" };

class KeywordResearch extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      searchKeyword: "",
      researchType: "",
      keywordAnalysisData: null,
      relatedKeywords: [],
      organicPosts: [],
      relatedKeywordsDataSize: 0,
      showPopup: false,
      message: "",
      loading: false,
      organicPostsLoading: false,
      fetchingBacklinks: false,
      domainAnalysisData: null,
      domainTopPages: [],
      domainTopPagesSize: 0,
      domainTopPagesLoading: false,
      domainRankLoading: false,
      domainBacklinkLoading: false,
      domainTopKeywords: [],
      domainTopKeywordsDataSize: 0,
      domainTopKeywordsLoading: false,
      graphData: [],
      options: countryList().getData(),
      location: default_location,

      publicationId: this.props.match.params.id,
      upgradeRequired: false
    };
  }

  componentDidMount() {
    api.getKeywordDataLocation(this.state.publicationId, (res: any) => {
      if (res.status === 200) {
        if (res.data.upgradeRequired) {
          this.setState({
            upgradeRequired: true
          });
          return;
        }

        this.setState({
          location: res.data.location.keywordDataLocation || default_location
        });
      }
    });
  }

  handleSearchChange = (value: any) => {
    this.setState({ searchKeyword: value });
  };

  handleResearch = () => {
    let { searchKeyword } = this.state;

    if (!searchKeyword) {
      notify.show("Enter a keyword or domain to research", "error");
      return;
    }
    // To check whether it is valid domain
    if (searchKeyword.includes(".") && psl.isValid(searchKeyword)) {
      this.doDomainAnalysis();
    } else {
      this.doKeywordResearch();
    }
  };

  doResearchByKeyword = (keyword: any) => {
    this.setState({ searchKeyword: keyword }, this.handleResearch);
  };

  doKeywordResearch = () => {
    let { searchKeyword } = this.state;
    this.setState(
      {
        loading: true,
        organicPostsLoading: true,
        researchType: keywordResearchType.KEYWORD,
        keywordAnalysisData: {},
        relatedKeywords: [],
        organicPosts: []
      },
      () => {
        api.startFetchingKeywordData(
          searchKeyword,
          0,
          DefaultPageSize,

          this.state.publicationId,
          (res: any) => {
            if (res.status === 200) {
              if (!res.data.isCachedResult) {
                this.getOrganicPosts();
              }
              this.setState({
                keywordAnalysisData: res.data.keywordSearchResult,
                relatedKeywords: res.data.keywordSearchResult
                  .relatedKeywordsData
                  ? res.data.keywordSearchResult.relatedKeywordsData
                  : [],
                organicPosts: res.data.keywordSearchResult.organicPosts
                  ? res.data.keywordSearchResult.organicPosts
                  : [],
                relatedKeywordsDataSize:
                  res.data.keywordSearchResult.relatedKeywordsDataSize,
                loading: false,
                organicPostsLoading: !res.data.isCachedResult
              });
            } else {
              this.setState({
                keywordAnalysisData: {},
                loading: false,
                organicPostsLoading: false
              });
            }
          }
        );
      }
    );
  };

  doDomainAnalysis = () => {
    let { searchKeyword } = this.state;
    this.setState(
      {
        researchType: keywordResearchType.DOMAIN,
        domainRankLoading: true,
        domainBacklinkLoading: true,
        domainTopPagesLoading: true,
        domainTopKeywordsLoading: true,
        loading: true,
        domainAnalysisData: {},
        domainTopKeywords: [],
        domainTopPages: [],
        graphData: []
      },
      () => {
        api.getKeywordsAndTrafficForDomain(
          searchKeyword,
          0,
          DefaultPageSize,

          this.state.publicationId,
          (res: any) => {
            if (res.status === 200) {
              if (!res.data.isCachedResult) {
                if (fetchKeyworDatafrom === KeywordDataSource.SEMRUSH) {
                  this.getTopKeywords();
                }
                if (fetchKeyworDatafrom === KeywordDataSource.DATAFORSEO) {
                  this.getDomainRank();
                  this.getDomainBacklinksCount();
                }
                this.getTopPages();
              }
              this.setState({
                domainAnalysisData: res.data.domainAnalysisResult,
                domainTopPages: res.data.domainAnalysisResult?.topPages || null,
                domainTopPagesSize:
                  res.data.domainAnalysisResult?.topPagesSize || null,
                domainTopKeywords:
                  res.data.domainAnalysisResult?.topKeywords || null,
                domainTopKeywordsDataSize:
                  res.data.domainAnalysisResult?.keywordsDataSize || null,
                loading: false,
                domainTopPagesLoading: !res.data.isCachedResult,
                domainTopKeywordsLoading: !res.data.isCachedResult,
                domainRankLoading: !res.data.isCachedResult,
                domainBacklinkLoading: !res.data.isCachedResult,
                graphData: res.data.domainAnalysisResult?.graphData || []
              });
            } else {
              this.setState({
                domainRankLoading: false,
                domainBacklinkLoading: false,
                loading: false
              });
            }
          }
        );
      }
    );
  };

  getOrganicPosts = () => {
    let { searchKeyword, publicationId } = this.state;
    api.getOrganicPosts(searchKeyword, publicationId, (res: any) => {
      if (res.status === 200) {
        this.setState(
          {
            organicPosts: res.data,
            organicPostsLoading: false,
            fetchingBacklinks: true
          },
          () => this.getBacklinks(res.data)
        );
      } else {
        this.setState({ organicPostsLoading: false });
      }
    });
  };

  getBacklinks = (organicPosts: any) => {
    let { searchKeyword, researchType, publicationId } = this.state;
    for (let postIndex = 0; postIndex < organicPosts.length; postIndex++) {
      if (organicPosts[postIndex].metricsUpdated) {
        continue;
      }
      api.getBacklinksForOrganicPost(
        organicPosts[postIndex].url,
        searchKeyword,
        researchType,
        organicPosts[postIndex].domain,
        publicationId,
        (res: any) => {
          if (res.status === 200) {
            organicPosts[postIndex]["backlinksCount"] = res.data.backlinksCount;
            organicPosts[postIndex]["metricsUpdated"] = true;
            if (fetchKeyworDatafrom === KeywordDataSource.SEMRUSH) {
              organicPosts[postIndex]["referringDomains"] =
                res.data.referringDomains;
              organicPosts[postIndex]["estimatedVisits"] =
                res.data.estimatedVisits;
            }
            this.setState({ organicPosts: organicPosts });
          }
          if (postIndex === organicPosts.length - 1) {
            this.setState({ fetchingBacklinks: false });
          }
        }
      );
    }
  };

  getDomainRank = () => {
    let { searchKeyword, publicationId } = this.state;
    api.getDomainRank(searchKeyword, publicationId, (res: any) => {
      if (res.status === 200) {
        let { domainAnalysisData } = this.state;
        domainAnalysisData["domainRank"] = res.data.domainRank;
        this.setState({
          domainAnalysisData: domainAnalysisData,
          domainRankLoading: false
        });
      } else {
        this.setState({
          domainRankLoading: false
        });
      }
    });
  };

  getDomainBacklinksCount = () => {
    let { searchKeyword, publicationId } = this.state;
    api.getDomainBacklinks(searchKeyword, publicationId, (res: any) => {
      if (res.status === 200) {
        let { domainAnalysisData } = this.state;
        domainAnalysisData["backlinksCount"] = res.data.backlinksCount;
        this.setState({
          domainAnalysisData: domainAnalysisData,
          domainBacklinkLoading: false
        });
      } else {
        this.setState({
          domainBacklinkLoading: false
        });
      }
    });
  };

  getTopKeywords = () => {
    api.getTopKeywordsForDomain(
      this.state.searchKeyword,
      0,
      DefaultPageSize,

      this.state.publicationId,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            domainTopKeywords: res.data.topKeywords,
            domainTopKeywordsDataSize: res.data.topKeywordsDataSize,
            domainTopKeywordsLoading: false
          });
        } else {
          this.setState({
            domainTopKeywordsLoading: false
          });
        }
      }
    );
  };

  getTopPages = () => {
    api.getTopPagesForDomain(
      this.state.searchKeyword,
      0,
      DefaultPageSize,

      this.state.publicationId,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            domainTopPages: res.data.topPages,
            domainTopPagesSize: res.data.topPagesSize,
            domainTopPagesLoading: false
          });
        } else {
          this.setState({
            domainTopPagesLoading: false
          });
        }
      }
    );
  };

  cancelSearch = () => {
    this.setState({
      searchKeyword: "",
      keywordAnalysisData: null,
      relatedKeywords: [],
      organicPosts: [],
      researchType: "",
      domainAnalysisData: null,
      domainTopKeywords: [],
      domainTopPages: [],
      loading: false,
      domainRankLoading: false,
      domainBacklinkLoading: false
    });
  };

  cardSkeleton = () => {
    let { classes } = this.props;

    return <Skeleton variant="rectangular" className={classes.cardSkeleton} />;
  };

  toggleInfoPopup = (message: any) => {
    let { showPopup } = this.state;
    this.setState({ showPopup: !showPopup, message: message });
  };
  changeCountry = (event: any, value: any) => {
    value = value || default_location;
    this.setState({ location: value });

    api.saveKeywordDataLocation(
      this.state.publicationId,
      value,
      (res: any) => {}
    );
  };
  render() {
    let { classes } = this.props;
    let {
      searchKeyword,

      researchType,

      loading,

      keywordAnalysisData,

      relatedKeywords,

      organicPosts,

      relatedKeywordsDataSize,

      showPopup,

      message,

      organicPostsLoading,

      fetchingBacklinks,

      domainAnalysisData,

      domainTopPages,

      domainTopPagesSize,

      domainTopPagesLoading,

      domainRankLoading,

      domainBacklinkLoading,

      domainTopKeywords,

      domainTopKeywordsDataSize,

      domainTopKeywordsLoading,

      graphData,

      options,

      location,

      publicationId,

      upgradeRequired
    } = this.state;

    if (upgradeRequired) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage="Upgrade to a higher plan to access keyword research." />

          <UpgradePlanDialog
            open={true}
            body="Upgrade to a higher plan to access keyword research."
          />
        </Suspense>
      );
    }

    return (
      <Container>
        <Helmet>
          <title>Keyword Research | Letterdrop</title>
        </Helmet>

        <Grid container direction="column" style={{ paddingTop: 24 }}>
          <Grid item xs={3} className={classes.selectBox}>
            <Autocomplete
              onChange={(event, newValue) =>
                this.changeCountry(event, newValue)
              }
              options={options}
              value={location}
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value;
              }}
              getOptionLabel={(option) => (option.label ? option.label : "")}
              renderInput={(params) => (
                <StyledInput
                  className={classes.selectWidth}
                  {...params}
                  size="medium"
                  placeholder="Select location"
                />
              )}
            />
          </Grid>

          <Grid item style={{ textAlign: "center" }}>
            <SearchBar
              onChange={this.handleSearchChange}
              value={searchKeyword}
              placeholder={"Enter a keyword or domain"}
              onRequestSearch={this.handleResearch}
              onCancelSearch={this.cancelSearch}
              style={{ width: "100%" }}
            />
          </Grid>
          {!researchType && (
            <div className={classes.zeroState}>
              <Typography variant="h6" className={classes.zeroStateText}>
                Research what your audience is searching
              </Typography>

              <img
                src={images.research}
                className={classes.researchImage}
                alt="keyword research"
              />
            </div>
          )}
          {researchType === keywordResearchType.DOMAIN && (
            <>
              <Grid item container direction="row" style={{ marginTop: 20 }}>
                <Grid item xs={12} sm={3} md={2} className={classes.card}>
                  <div className={classes.topCardPadding}>
                    <Typography variant="body2" className={classes.cardTitle}>
                      Keywords
                    </Typography>
                    {domainAnalysisData?.keywordsCount ? (
                      <Typography variant="h6" className={classes.cardValue}>
                        {formatNumber(domainAnalysisData.keywordsCount)}
                      </Typography>
                    ) : loading ? (
                      this.cardSkeleton()
                    ) : (
                      <Typography variant="h6" className={classes.cardValue}>
                        N/A
                      </Typography>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={3} md={2} className={classes.card}>
                  <div className={classes.topCardPadding}>
                    <Typography variant="body2" className={classes.cardTitle}>
                      Domain Authority
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        fontSize="small"
                        onClick={() => {
                          this.toggleInfoPopup(DOMAIN_AUTHORITY);
                        }}
                      />
                    </Typography>
                    {domainAnalysisData?.domainAuthority ? (
                      <Typography variant="h6" className={classes.cardValue}>
                        {formatNumber(domainAnalysisData.domainAuthority)}
                      </Typography>
                    ) : loading ? (
                      this.cardSkeleton()
                    ) : (
                      <Typography variant="h6" className={classes.cardValue}>
                        N/A
                      </Typography>
                    )}
                  </div>
                </Grid>
                {/* <Grid item xs={12} sm={3} md={2} className={classes.card}>
                  <div className={classes.topCardPadding}>
                    <Typography variant="body2" className={classes.cardTitle}>
                      Estimated Visits
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        fontSize="small"
                        onClick={() => {
                          this.toggleInfoPopup(ESTIMATED_VISITS);
                        }}
                      />
                    </Typography>
                    {domainAnalysisData.estimatedVisits ? (
                      <Typography variant="h6" className={classes.cardValue}>
                        {formatNumber(
                          Math.round(domainAnalysisData.estimatedVisits)
                        )}
                      </Typography>
                    ) : loading ? (
                      this.cardSkeleton()
                    ) : (
                      <Typography variant="h6" className={classes.cardValue}>
                        N/A
                      </Typography>
                    )}
                  </div>
                </Grid> */}

                {/* <Grid item xs={12} sm={3} md={2} className={classes.card}>
                  <div className={classes.topCardPadding}>
                    <Typography variant="body2" className={classes.cardTitle}>
                      Domain Rank
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        fontSize="small"
                        onClick={() => {
                          this.toggleInfoPopup(DOMAIN_RANK);
                        }}
                      />
                    </Typography>
                    {domainAnalysisData.domainRank ? (
                      <Typography variant="h6" className={classes.cardValue}>
                        {formatNumber(domainAnalysisData.domainRank)}
                      </Typography>
                    ) : domainRankLoading ? (
                      this.cardSkeleton()
                    ) : (
                      <Typography variant="h6" className={classes.cardValue}>
                        N/A
                      </Typography>
                    )}
                  </div>
                </Grid> */}

                <Grid item xs={12} sm={3} md={2} className={classes.card}>
                  <div className={classes.topCardPadding}>
                    <Typography variant="body2" className={classes.cardTitle}>
                      Backlinks
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        fontSize="small"
                        onClick={() => {
                          this.toggleInfoPopup(BACKLINKS);
                        }}
                      />
                    </Typography>
                    {domainAnalysisData?.backlinksCount ? (
                      <Typography variant="h6" className={classes.cardValue}>
                        {formatNumber(domainAnalysisData.backlinksCount)}
                      </Typography>
                    ) : domainBacklinkLoading ? (
                      this.cardSkeleton()
                    ) : (
                      <Typography variant="h6" className={classes.cardValue}>
                        N/A
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
              {graphData?.length > 0 && (
                <Grid item style={{ marginTop: 30 }}>
                  <SearchTrafficGraph
                    heading="Search Traffic"
                    prefixAvgSymbol=""
                    graphDataDetails={graphData}
                  />
                </Grid>
              )}

              <Grid item style={{ marginTop: 30 }}>
                <TopKeywords
                  domain={searchKeyword}
                  searchKeyword={searchKeyword}
                  topKeywords={domainTopKeywords}
                  topKeywordsDataSize={domainTopKeywordsDataSize}
                  loading={
                    fetchKeyworDatafrom === KeywordDataSource.SEMRUSH
                      ? domainTopKeywordsLoading
                      : loading
                  }
                  doResearchByKeyword={this.doResearchByKeyword}
                  fetchKeyworDatafrom={fetchKeyworDatafrom}
                  showPosition={() => {
                    this.toggleInfoPopup(POSITION);
                  }}
                  showEstimatedVisits={() => {
                    this.toggleInfoPopup(ESTIMATED_VISITS);
                  }}
                  showTraffic={() => {
                    this.toggleInfoPopup(TRAFFIC);
                  }}
                  showSEODifficulty={() => {
                    this.toggleInfoPopup(SEO_DIFFICULTY);
                  }}
                />
              </Grid>

              <Grid item style={{ marginTop: 10, marginBottom: 70 }}>
                <TopPages
                  domain={searchKeyword}
                  topPagesSize={domainTopPagesSize}
                  topPages={domainTopPages}
                  topPagesLoading={domainTopPagesLoading}
                  getBacklinks={this.getBacklinks}
                  showEstimatedVisits={() => {
                    this.toggleInfoPopup(ESTIMATED_VISITS);
                  }}
                  showReferringDomain={() => {
                    this.toggleInfoPopup(REFERRING_DOMAIN);
                  }}
                />
              </Grid>
            </>
          )}

          {researchType === keywordResearchType.KEYWORD && (
            <>
              <Grid item container direction="row" style={{ marginTop: 20 }}>
                <Grid item xs={12} sm={3} md={2} className={classes.card}>
                  <div className={classes.topCardPadding}>
                    <Typography variant="body2" className={classes.cardTitle}>
                      Searches per month
                    </Typography>
                    {keywordAnalysisData.searchVolume ? (
                      <Typography variant="h6" className={classes.cardValue}>
                        {formatNumber(keywordAnalysisData.searchVolume)}
                      </Typography>
                    ) : loading ? (
                      this.cardSkeleton()
                    ) : (
                      <Typography variant="h6" className={classes.cardValue}>
                        N/A
                      </Typography>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={3} md={2} className={classes.card}>
                  <div className={classes.topCardPadding}>
                    <Typography variant="body2" className={classes.cardTitle}>
                      SEO Difficulty
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        fontSize="small"
                        onClick={() => {
                          this.toggleInfoPopup(SEO_DIFFICULTY);
                        }}
                      />
                    </Typography>
                    {keywordAnalysisData.keywordDifficulty ? (
                      <Typography variant="h6" className={classes.cardValue}>
                        {formatNumber(keywordAnalysisData.keywordDifficulty)}%
                      </Typography>
                    ) : loading ? (
                      this.cardSkeleton()
                    ) : (
                      <Typography variant="h6" className={classes.cardValue}>
                        N/A
                      </Typography>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={3} md={2} className={classes.card}>
                  <div className={classes.topCardPadding}>
                    <Typography variant="body2" className={classes.cardTitle}>
                      Paid Difficulty
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        fontSize="small"
                        onClick={() => {
                          this.toggleInfoPopup(DIFFICULTY);
                        }}
                      />
                    </Typography>
                    {keywordAnalysisData.competition ? (
                      <Typography variant="h6" className={classes.cardValue}>
                        {Math.round(keywordAnalysisData.competition * 100)}%
                      </Typography>
                    ) : loading ? (
                      this.cardSkeleton()
                    ) : (
                      <Typography variant="h6" className={classes.cardValue}>
                        N/A
                      </Typography>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={3} md={2} className={classes.card}>
                  <div className={classes.topCardPadding}>
                    <Typography variant="body2" className={classes.cardTitle}>
                      Cost to buy click
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        fontSize="small"
                        onClick={() => {
                          this.toggleInfoPopup(CPC);
                        }}
                      />
                    </Typography>
                    {keywordAnalysisData.cpc ? (
                      <Typography variant="h6" className={classes.cardValue}>
                        {"$" + keywordAnalysisData.cpc.toFixed(2)}
                      </Typography>
                    ) : loading ? (
                      this.cardSkeleton()
                    ) : (
                      <Typography variant="h6" className={classes.cardValue}>
                        N/A
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid item style={{ marginTop: 30, maxWidth: "100%" }}>
                <Typography variant="body1" className={classes.heading}>
                  You might also be interested in
                </Typography>

                <RelatedKeywords
                  searchKeyword={searchKeyword}
                  relatedKeywords={relatedKeywords}
                  relatedKeywordsDataSize={relatedKeywordsDataSize}
                  loading={loading}
                  doResearchByKeyword={this.doResearchByKeyword}
                  fetchKeyworDatafrom={fetchKeyworDatafrom}
                  publicationId={publicationId}
                />
              </Grid>

              <Grid
                item
                style={{ marginTop: 10, marginBottom: 30, maxWidth: "100%" }}
              >
                <OrganicPosts
                  fetchingBacklinks={fetchingBacklinks}
                  organicPosts={organicPosts}
                  organicPostsLoading={organicPostsLoading}
                  showEstimatedVisits={() => {
                    this.toggleInfoPopup(ESTIMATED_VISITS);
                  }}
                  showReferringDomain={() => {
                    this.toggleInfoPopup(REFERRING_DOMAIN);
                  }}
                  showBacklinks={() => {
                    this.toggleInfoPopup(BACKLINKS);
                  }}
                  showDomainAuthority={() => {
                    this.toggleInfoPopup(DOMAIN_AUTHORITY);
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>

        <StyledDialog
          open={showPopup}
          title=""
          body={message}
          successButtonName="OK"
          successCallback={() => {
            this.toggleInfoPopup("");
          }}
        />
      </Container>
    );
  }
}

export default withStyles(style)(KeywordResearch);
