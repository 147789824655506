// @ts-nocheck
import { FormControlLabel, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import "firebase/auth";
import moment from "moment";
import React from "react";

import TagsInput from "./TagsInput";

import StyledInput from "../design/components/StyledInput";

import StyledSwitch from "../design/components/StyledSwitch";

import { ReactComponent as CalendarGrayIcon } from "../Images/icon20/calendarColorGray40.svg";
import { formatForTimeWithTimeZone } from "../Utils/TimeUtils";
import { customFieldDataType } from "../Utils/Types";
import styles from "./styles/ViewProjectCustomFieldsStyle";

import StyledChip from "../design/components/StyledChip";

import CustomFlatPicker from "./CustomFlatPicker";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";

function ProjectCustomFieldInputs({
  customFields,
  classes,
  onCustomFieldChange,
  publicationId
}: any) {
  let [showDatePickerForPublish, setShowDatePickerForPublish] =
    React.useState(false);
  let [eventElement, setEventElement] = React.useState("");

  function handleCustomFieldsChange(value: any, index: any) {
    if (value && customFields[index].type === customFieldDataType.NUMBER) {
      value = Number(value);
    }
    customFields[index].value = value;
    onCustomFieldChange(customFields);
  }

  function handleDatePicking(event: any) {
    setShowDatePickerForPublish(true);
    setEventElement(event.currentTarget);
  }

  function handleCustomListFieldValueChange(newValues: any, index: any) {
    customFields[index].value = newValues;
    onCustomFieldChange(customFields);
  }

  function addCustomListFieldValues(newValue: any, index: any) {
    let values = customFields[index].value || [];
    handleCustomListFieldValueChange([...values, newValue], index);
  }

  function helperText(index: any) {
    let { type, value, minimumValue, maximumValue } = customFields[index];
    const MAX_ERROR = `This needs to be smaller than ${maximumValue}`;
    const MIN_ERROR = `This needs to be bigger than ${minimumValue}`;

    if (type !== customFieldDataType.NUMBER) {
      return;
    }
    if (!isNaN(parseInt(minimumValue)) && value < minimumValue) {
      return MIN_ERROR;
    }
    if (!isNaN(parseInt(maximumValue)) && value > maximumValue) {
      return MAX_ERROR;
    }
  }

  return (
    <>
      {customFields.map((customField: any, index: any) => {
        return (
          <Grid item className={classes.section}>
            <Typography variant="h300">{customField.name}</Typography>

            <div className={classes.marginTop10}>
              {customField.type === customFieldDataType.BOOLEAN && (
                <StyledSwitch
                  size="large"
                  fullWidth
                  checked={customField.value}
                  onChange={(event: any) => {
                    handleCustomFieldsChange(!customField.value, index);
                  }}
                />
              )}
              {customField.type === customFieldDataType.TEXT && (
                <StyledInput
                  fullWidth
                  size="large"
                  value={customField.value}
                  minRows={3}
                  multiline
                  onChange={(event: any) => {
                    handleCustomFieldsChange(event.target.value, index);
                  }}
                />
              )}
              {customField.type === customFieldDataType.URL && (
                <StyledInput
                  fullWidth
                  size="large"
                  value={customField.value}
                  onChange={(event: any) => {
                    handleCustomFieldsChange(event.target.value, index);
                  }}
                />
              )}
              {customField.type === customFieldDataType.DATE && (
                <>
                  <StyledInput
                    fullWidth
                    size="large"
                    readOnly={true}
                    value={
                      customField.value
                        ? formatForTimeWithTimeZone(moment(customField.value))
                        : formatForTimeWithTimeZone(moment())
                    }
                    InputProps={{
                      endAdornment: <CalendarGrayIcon />
                    }}
                    onClick={handleDatePicking}
                    inputProps={{
                      placeholder: "Pick a date"
                    }}
                  />

                  <CustomFlatPicker
                    publicationId={publicationId}
                    anchorEl={eventElement}
                    open={showDatePickerForPublish}
                    disablePast={false}
                    value={customField.value}
                    onClose={(date) => {
                      //Handle invalid date
                      if (date.toString() === "Invalid Date") {
                        setShowDatePickerForPublish(false);
                        return;
                      }
                      setShowDatePickerForPublish(false);
                      handleCustomFieldsChange(date, index);
                    }}
                  />
                </>
              )}
              {customField.type === customFieldDataType.NUMBER && (
                <StyledInput
                  fullWidth
                  size="large"
                  type="number"
                  value={customField.value}
                  helperText={helperText(index)}
                  error={helperText(index)}
                  onChange={(event: any) => {
                    handleCustomFieldsChange(event.target.value, index);
                  }}
                />
              )}

              {customField.type === customFieldDataType.MULTI_SELECT_LIST && (
                <TagsInput
                  value={customField.value || []}
                  fullWidth
                  size="medium"
                  suggestions={customField.allowedValue || []}
                  addTag={(event: any, newValue: any) =>
                    addCustomListFieldValues(newValue, index)
                  }
                  handleChange={(event: any, newValues: any) =>
                    handleCustomListFieldValueChange(newValues, index)
                  }
                />
              )}

              {customField.type === customFieldDataType.SINGLE_SELECT_LIST && (
                <StyledSelect
                  value={customField.value || []}
                  name="defaultValue"
                  size="large"
                  style={{ width: "100%" }}
                  onChange={(event: any) => {
                    handleCustomFieldsChange(event.target.value, index);
                  }}
                >
                  {customField.allowedValue.map((item: any) => (
                    <StyledMenuItem value={item}>{item}</StyledMenuItem>
                  ))}
                </StyledSelect>
              )}
            </div>
          </Grid>
        );
      })}
    </>
  );
}

export default withStyles(styles)(ProjectCustomFieldInputs);
