// @ts-nocheck
import Chip, { ChipProps } from "@mui/material/Chip";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { ReactComponent as DeleteIcon12 } from "../../Images/icon12/close.svg";

import { ReactComponent as DeleteIcon16 } from "../../Images/icon16/close.svg";

import { ReactComponent as DeleteIcon20 } from "../../Images/icon20/close.svg";
import { designColors } from "../../Themes/Colors";

interface StyledChipProps extends ChipProps {
  label: string;
  variant: "blue" | "gray" | "stroked" | "green" | "orange" | "red";
  size: "small" | "medium" | "large";
  textColor?: string;
  length?: number;
  truncateString?: boolean;
}

function StyledChip({
  label,
  variant,
  size,
  textColor,
  length = 10,
  truncateString = false,
  ...other
}: StyledChipProps) {
  let icon = useMemo(() => {
    if (size === "small") return <DeleteIcon12 />;

    if (size === "medium") return <DeleteIcon16 />;

    if (size === "large") return <DeleteIcon20 />;

    return <DeleteIcon12 />;
  }, [size]);

  function truncateStringAndAddEllipses() {
    if (typeof label !== "string") {
      return label;
    }
    if (!truncateString) {
      return label;
    }

    if (!length || label?.trim().length <= length) {
      return label;
    }

    return label?.trim().substring(0, length) + "...";
  }

  return (
    <Chip
      variant={variant}
      label={truncateStringAndAddEllipses()}
      size={size}
      deleteIcon={icon}
      sx={{
        "&.MuiChip-root": {
          borderRadius: "6px",
          padding: "4px 8px 4px 8px",
          margin: "2px",
          color: textColor ? textColor : designColors.grayScale[100]
        },
        "&.MuiChip-sizeSmall": {
          typography: "bodys",
          height: "24px"
        },
        "&.MuiChip-sizeMedium": {
          typography: "bodym",
          height: "32px"
        },
        "&.MuiChip-sizeLarge": {
          typography: "bodyl",
          height: "40px"
        },
        "& .MuiChip-label": {
          padding: 0
        },
        "& .MuiChip-icon": {
          marginLeft: "5px",
          marginRight: "9px"
        },
        "& .MuiChip-deleteIcon": {
          marginRight: "2px",
          marginLeft: "10px"
        },
        "&.MuiChip-blue": {
          backgroundColor: designColors.primary[0]
        },
        "&.MuiChip-gray": {
          backgroundColor: designColors.grayScale[5]
        },
        "&.MuiChip-stroked": {
          backgroundColor: designColors.white,
          outline: "1px solid " + designColors.grayScale[40]
        },
        "&.MuiChip-green": {
          backgroundColor: designColors.success[5]
        },
        "&.MuiChip-orange": {
          backgroundColor: designColors.warning[5]
        },
        "&.MuiChip-red": {
          backgroundColor: designColors.alert[5]
        },
        "&.MuiChip-default": {
          backgroundColor: designColors.primary[0]
        }
      }}
      {...other}
    />
  );
}

StyledChip.propTypes = {
  variant: PropTypes.oneOf([
    "blue",
    "gray",
    "stroked",
    "green",
    "orange",
    "red"
  ]),
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  length: PropTypes.number,
  disabled: PropTypes.bool
};

StyledChip.defaultProps = {
  variant: "blue",
  size: "small"
};

export default StyledChip;
