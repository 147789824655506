// @ts-nocheck
import colors from "../../Themes/Colors";
import { grey } from '@mui/material/colors';

const styles = (theme: any) => ({
  cardSubtitle: {
    color: colors.fontSecondary
  },

  input: {
    backgroundColor: grey[100],
    color: colors.fontSecondary,
    borderRadius: 5,
    padding: 10,
    fontFamily: "Inconsolata",
    width: "100%"
  },

  copyContainer: {
    marginTop: 40
  }
});

export default styles;
