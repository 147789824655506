// @ts-nocheck
import { CircularProgress, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import colors, { designColors } from "../../Themes/Colors";
import StyledButton from "./StyledButton";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import { space } from "../../Config/theme";
function StyledDialog({
  open,
  title,
  body,
  successButtonName,
  cancelButtonName,
  secondaryButtonName,
  successCallback,
  cancelCallback,
  optionCallback,
  closeCallback,
  additionalButtons,
  hideSecondaryButton,
  disableSuccessButton,
  alignOptionAndCancelButtonToLeft,
  contentStyle,
  isAutoFocused,
  showSuccessButtonLoading,
  disableOnClose = false,
  ...others
}: any) {
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (disableOnClose) {
          return;
        }
        if (closeCallback) {
          closeCallback();
          return;
        }
        if (cancelCallback) {
          cancelCallback();
          return;
        }
      }}
      sx={{
        "& .MuiPaper-root": {
          background: designColors.white,
          borderRadius: "8px",
          boxShadow:
            "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.2)"
        }
      }}
      fullWidth
      {...others}
    >
      {title && (
        <Grid
          container
          direction="row"
          alignContent="space-between"
          style={{
            boxShadow: "inset 0px -1px 0px " + colors.dialogGridShadow
          }}
        >
          <Grid item xs={10}>
            <DialogTitle
              sx={{
                "&.MuiDialogTitle-root": {
                  typography: "bodyl",
                  padding: "20px 20px"
                }
              }}
            >
              {title}
            </DialogTitle>
          </Grid>
          {!disableOnClose && (cancelCallback || closeCallback) && (
            <Grid
              item
              xs={2}
              style={{ padding: "20px 24px 20px 0px", direction: "rtl" }}
            >
              <IconButton
                onClick={cancelCallback ? cancelCallback : closeCallback}
                aria-label="close"
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
      {!title && (cancelCallback || closeCallback) ? (
        <DialogContent
          sx={{
            "&.MuiDialogContent-root": {
              padding: "20px",
              typography: "bodym"
            }
          }}
        >
          <Grid container direction="row" alignContent="space-between">
            <Grid item xs={11}>
              {body}
            </Grid>

            <Grid
              item
              xs={1}
              style={{ padding: "0px 4px 0px 0px", direction: "rtl" }}
            >
              <IconButton
                onClick={cancelCallback ? cancelCallback : closeCallback}
                aria-label="close"
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            "&.MuiDialogContent-root": {
              padding: "20px",
              typography: "bodym"
            }
          }}
          style={contentStyle}
        >
          {body}
        </DialogContent>
      )}
      {(successCallback ||
        cancelCallback ||
        secondaryButtonName ||
        additionalButtons) &&
        !alignOptionAndCancelButtonToLeft && (
          <DialogActions
            sx={{
              "&.MuiDialogActions-root": {
                height: "72px",
                boxShadow: "inset 0px 1px 0px " + colors.dialogActionShadow,
                paddingRight: "16px"
              }
            }}
          >
            {cancelCallback && (
              <StyledButton
                variant="secondary"
                size="medium"
                onClick={cancelCallback}
              >
                {cancelButtonName ? cancelButtonName : "Cancel"}
              </StyledButton>
            )}
            {additionalButtons && additionalButtons}
            {secondaryButtonName && optionCallback && !hideSecondaryButton && (
              <StyledButton
                variant="secondary"
                size="medium"
                onClick={optionCallback}
              >
                {secondaryButtonName}
              </StyledButton>
            )}
            {successCallback && successButtonName && (
              <StyledButton
                id="successButton"
                size="medium"
                disabled={disableSuccessButton}
                onClick={successCallback}
              >
                {showSuccessButtonLoading && (
                  <CircularProgress
                    size={20}
                    color="inherit"
                    style={{ marginRight: space.XS }}
                  />
                )}
                {successButtonName}
              </StyledButton>
            )}
          </DialogActions>
        )}
      {(successCallback ||
        cancelCallback ||
        secondaryButtonName ||
        additionalButtons) &&
        alignOptionAndCancelButtonToLeft && (
          <DialogActions
            sx={{
              "&.MuiDialogActions-root": {
                boxShadow: "inset 0px 1px 0px " + colors.dialogActionShadow,
                padding: "16px",
                display: "inline-block"
              }
            }}
          >
            {additionalButtons && additionalButtons}
            {secondaryButtonName && optionCallback && !hideSecondaryButton && (
              <StyledButton
                variant="secondary"
                size="medium"
                onClick={optionCallback}
              >
                {secondaryButtonName}
              </StyledButton>
            )}
            {cancelCallback && (
              <StyledButton
                variant="secondary"
                size="medium"
                onClick={cancelCallback}
              >
                {cancelButtonName ? cancelButtonName : "Cancel"}
              </StyledButton>
            )}
            {successCallback && successButtonName && (
              <StyledButton
                size="medium"
                id="successButton"
                onClick={successCallback}
                disabled={disableSuccessButton}
                style={{
                  float: "right"
                }}
              >
                {showSuccessButtonLoading && (
                  <CircularProgress
                    size={20}
                    color="inherit"
                    style={{ marginRight: space.XS }}
                  />
                )}
                {successButtonName}
              </StyledButton>
            )}
          </DialogActions>
        )}
    </Dialog>
  );
}

export default StyledDialog;
