// @ts-nocheck
import { FormControlLabel, Grid, Container, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";

import { withRouter } from "react-router";
import API from "../Services/Api";
import styles from "./styles/DoubleOptInStyle";
import { notify } from "./CustomNotifications";

import StyledSwitch from "../design/components/StyledSwitch";

const api = API.create();

class DoubleOptIn extends React.Component {
  constructor(props: any) {
    super(props);

    let { publication } = this.props;
    this.state = {
      publicationId: publication._id ? publication._id : "",
      confirmSignup: publication.emailDetails
        ? publication.emailDetails.signupConfirmation
        : true
    };
  }

  handleConfirmChange = () => {
    let { publicationId, confirmSignup } = this.state;
    api.saveSignupViaEmail(publicationId, !confirmSignup, (res: any) => {
      if (res.status === 200) {
        this.setState({ confirmSignup: !confirmSignup });
      }

      if (res.status === 400) {
        notify.show("Unable to save", "error");
      }
    });
  };

  render() {
    let { classes } = this.props;

    let { confirmSignup } = this.state;

    return (
      <Container className={classes.container}>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={11}>
            <Typography variant="h400" paragraph>
              Double opt-in
            </Typography>

            <Typography
              variant="bodym"
              paragraph
              className={classes.cardSubtitle}
            >
              When a reader signs up, send them an email to confirm. This
              prevents bots and fake sign-ups.
            </Typography>
          </Grid>

          <Grid item xs={1} className={classes.switch}>
            <FormControlLabel
              control={
                <StyledSwitch
                  checked={confirmSignup}
                  onChange={this.handleConfirmChange}
                />
              }
              label=""
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(DoubleOptIn));
