// @ts-nocheck
import React, { Component, Fragment } from "react";
import { Grid, Typography, FormControlLabel, Container } from "@mui/material";
import { withStyles } from "@mui/styles";
import styles from "./styles/NotificationsStyle";
import Api from "../Services/Api";
import { notify } from "./CustomNotifications";

import StyledSwitch from "../design/components/StyledSwitch";
import { isModuleDisabled } from "../Utils/AccessUtils";
import { modulesAccess } from "../Utils/Types";
import PageAccessContext from "../Utils/PageAccessContext";

const api = Api.create();

class Notifications extends Component {
  static contextType = PageAccessContext;
  constructor(props: any) {
    super(props);
    this.state = {
      publicationId: this.props.publication._id,
      newSubscriber: this.props.publication.emailNotifications.newSubscriber,
      publishedPost: this.props.publication.emailNotifications.publishedPost,
      dailyReport: this.props.publication.emailNotifications.dailyReport,
      postStatsUpdate:
        this.props.publication.emailNotifications.postStatsUpdate,
      monthlySummary: this.props.publication.emailNotifications.monthlySummary,
      recycleLinkedInPostSuggestion:
        this.props.publication.emailNotifications
          ?.recycleLinkedInPostSuggestion ?? true,
      sendEmailToIdeaSuggesterAndUpvoter:
        this.props.publication?.emailNotifications
          ?.sendEmailToIdeaSuggesterAndUpvoter ?? true,
      projectStatusReport:
        this.props.publication.emailNotifications.projectStatusReport,
      seoOpportunities:
        this.props.publication.emailNotifications.seoOpportunities
    };
  }

  toggleEmailNotifications = (event: any) => {
    let notificationType = event.target.name;
    let isActive = event.target.checked;
    this.setState({ [notificationType]: event.target.checked }, () => {
      this.saveEmailNotifications(notificationType, isActive);
    });
  };

  saveEmailNotifications = (notificationType: any, isActive: any) => {
    let { publicationId } = this.state;
    api.saveEmailNotifications(
      publicationId,
      notificationType,
      isActive,
      (res: any) => {
        if (res.status !== 200) {
          this.setState({
            [notificationType]: !isActive
          });
          notify.show(
            "Oops, something went wrong while saving your notification settings",
            "error"
          );
        }
      }
    );
  };

  render() {
    let { classes } = this.props;
    let {
      dailyReport,
      newSubscriber,
      publishedPost,
      postStatsUpdate,
      recycleLinkedInPostSuggestion,
      sendEmailToIdeaSuggesterAndUpvoter,
      monthlySummary,
      projectStatusReport,
      publicationId,
      seoOpportunities
    } = this.state;
    return (
      <Fragment>
        <Container className={classes.container}>
          <div style={{ marginBottom: 20 }}>
            <Typography variant="h400" paragraph>
              Notifications
            </Typography>

            <Typography
              variant="bodym"
              paragraph
              className={classes.cardSubtitle}
            >
              Choose which email notifications you receive
            </Typography>
          </div>

          <Grid item container xs={12} className={classes.marginTop20}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name={"dailyReport"}
                  checked={dailyReport}
                  onChange={this.toggleEmailNotifications}
                />
              }
              label="Get a daily report on new subscribers"
              componentsProps={{
                typography: {
                  variant: "bodym"
                }
              }}
            />
          </Grid>

          <Grid item container xs={12} className={classes.marginTop20}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name={"newSubscriber"}
                  checked={newSubscriber}
                  onChange={this.toggleEmailNotifications}
                />
              }
              label="Receive an email when a new person subscribes"
              componentsProps={{
                typography: {
                  variant: "bodym"
                }
              }}
            />
          </Grid>

          <Grid item container xs={12} className={classes.marginTop20}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name={"publishedPost"}
                  checked={publishedPost}
                  onChange={this.toggleEmailNotifications}
                />
              }
              label="Get alerts when content is published or updated or unpublished or deleted"
              componentsProps={{
                typography: {
                  variant: "bodym"
                }
              }}
            />
          </Grid>

          {!isModuleDisabled(
            this.context,
            publicationId,
            modulesAccess.SEO
          ) && (
            <Grid item container xs={12} className={classes.marginTop20}>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name={"postStatsUpdate"}
                    checked={postStatsUpdate}
                    onChange={this.toggleEmailNotifications}
                  />
                }
                label="Review your post stats after a day"
                componentsProps={{
                  typography: {
                    variant: "bodym"
                  }
                }}
              />
            </Grid>
          )}

          <Grid item container xs={12} className={classes.marginTop20}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name={"monthlySummary"}
                  checked={monthlySummary}
                  onChange={this.toggleEmailNotifications}
                />
              }
              label="Monthly summary"
              componentsProps={{
                typography: {
                  variant: "bodym"
                }
              }}
            />
          </Grid>

          {!isModuleDisabled(
            this.context,
            publicationId,
            modulesAccess.CONTENT_OPERATIONS
          ) && (
            <Grid item container xs={12} className={classes.marginTop20}>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name={"sendEmailToIdeaSuggesterAndUpvoter"}
                    checked={sendEmailToIdeaSuggesterAndUpvoter}
                    onChange={this.toggleEmailNotifications}
                  />
                }
                label="Let people know when an idea they suggested or upvoted is published live"
                componentsProps={{
                  typography: {
                    variant: "bodym"
                  }
                }}
              />
            </Grid>
          )}

          {!isModuleDisabled(
            this.context,
            publicationId,
            modulesAccess.SOCIAL
          ) && (
            <Grid item container xs={12} className={classes.marginTop20}>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name={"recycleLinkedInPostSuggestion"}
                    checked={recycleLinkedInPostSuggestion}
                    onChange={this.toggleEmailNotifications}
                  />
                }
                label="Nudge to recycle old LinkedIn posts"
                componentsProps={{
                  typography: {
                    variant: "bodym"
                  }
                }}
              />
            </Grid>
          )}

          {!isModuleDisabled(
            this.context,
            publicationId,
            modulesAccess.CONTENT_OPERATIONS
          ) && (
            <Grid item container xs={12} className={classes.marginTop20}>
              <FormControlLabel
                control={
                  <StyledSwitch
                    name={"projectStatusReport"}
                    checked={projectStatusReport}
                    onChange={this.toggleEmailNotifications}
                  />
                }
                label="Notify when projects are assigned, are overdue, or change stages"
                componentsProps={{
                  typography: {
                    variant: "bodym"
                  }
                }}
              />
            </Grid>
          )}

          <Grid item container xs={12} className={classes.marginTop20}>
            <FormControlLabel
              control={
                <StyledSwitch
                  name={"seoOpportunities"}
                  checked={seoOpportunities}
                  onChange={this.toggleEmailNotifications}
                />
              }
              label="Weekly SEO opportunities report"
              componentsProps={{
                typography: {
                  variant: "bodym"
                }
              }}
            />
          </Grid>
        </Container>
      </Fragment>
    );
  }
}
export default withStyles(styles)(Notifications);
