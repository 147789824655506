// @ts-nocheck
import React from "react";
import { Grid } from "@mui/material";

import TopicCommentBox from "./TopicCommentBox";
import styles from "./styles/TopicCommentsStyle";
import { withStyles } from "@mui/styles";
import Api from "../Services/Api";
import Skeleton from "@mui/material/Skeleton";
import { replaceLoomUrlWithEmbed } from "../Utils/LoomUtils";
import { isSupported, setup } from "@loomhq/record-sdk";

import Comment from "./Comment";
import { redirectToLoginPage } from "../Utils/UserUtils";

const api = Api.create();

class TopicComments extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      comments: [],
      configureButton: null,
      teammates: [],
      currentClient: null,
      draftComment: null,
      enableCommentEdit: false,
      lastSelfCommentId: null
    };
  }

  componentDidMount = () => {
    this.load();
  };

  load = () => {
    let { topicId, publicationId } = this.props;

    api.getTopicComments(topicId, publicationId, async (res: any) => {
      if (res.status === 200) {
        if (res.data?.loginRequired) {
          redirectToLoginPage();
          return;
        }
        let comments = await this.formateComments(res.data.comments || []);
        let configureButton = null;
        const { supported } = await isSupported();

        if (supported && res.data.loomJWS) {
          configureButton = (
            await setup({
              jws: res.data.loomJWS
            })
          ).configureButton;
        }

        this.setState({
          comments,
          configureButton,
          loading: false,
          teammates: res.data.teammates || [],
          currentClient: res.data.currentClient,
          draftComment: res.data.draftComment
        });
        if (res.data?.currentClient) {
          this.setLastSelfComment(
            comments,
            res.data?.currentClient?._id || null
          );
        }

        this.props.onCommentCountChange?.(res.data.comments.length || 0);
      }
    });
  };

  setLastSelfComment = (comments: any, currentClient: any) => {
    for (
      let commentIndex = comments.length - 1;
      commentIndex >= 0;
      commentIndex--
    ) {
      let comment = comments[commentIndex];
      if (comment?.client?._id === currentClient && !comment.isDeleted) {
        this.setState({ lastSelfCommentId: comment._id });
        return;
      }
    }
  };

  formateComments = async (comments = []) => {
    for (let comment of comments) {
      try {
        comment.comment = await replaceLoomUrlWithEmbed(comment.comment, {
          width: 500
        });
      } catch (error) {}
    }

    return comments;
  };

  toggleCommentEditMode = () => {
    let { enableCommentEdit } = this.state;
    this.setState({ enableCommentEdit: !enableCommentEdit });
  };

  editMyLastComment = () => {
    this.setState({ enableCommentEdit: true });
  };

  render() {
    let { topicId, profilePic, classes, anonymousClientObject } = this.props;
    let {
      comments,

      configureButton,

      loading,

      teammates,

      currentClient,

      draftComment,

      enableCommentEdit,

      lastSelfCommentId
    } = this.state;
    const CommentSkeleton = () => (
      <Grid container direction="column">
        <Grid item container direction="row" className={classes.marginTop30}>
          <Grid item className={classes.userLogo} xs={1}>
            <Skeleton variant="circular" height={40} width={40} />
          </Grid>

          <Grid
            item
            xs={9}
            container
            direction="column"
            className={classes.comment}
          >
            <Grid item>
              <Skeleton height={25} />
            </Grid>

            <Grid item>
              <Skeleton height={30} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    return (
      <Grid container direction="column">
        {(loading && <CommentSkeleton />) ||
          (comments.length > 0 &&
            comments.map((comment: any, key: any) => {
              return (
                <Comment
                  client={comment.client}
                  commentedOn={comment.commentedOn}
                  comment={comment.comment}
                  draftText={comment.draftDelta}
                  files={comment.files || []}
                  currentClient={currentClient?._id || null}
                  anonymousClient={comment?.anonymousClient}
                  key={key}
                  teammates={teammates}
                  loomId="topic_loom_button"
                  commentId={comment._id}
                  load={this.load}
                  configureButton={configureButton}
                  isEdited={comment.isEdited}
                  isDeleted={comment.isDeleted}
                  lastSelfCommentId={lastSelfCommentId}
                  enableEdit={enableCommentEdit}
                  toggleCommentEditMode={this.toggleCommentEditMode}
                />
              );
            }))}

        <Grid item>
          <TopicCommentBox
            topicId={topicId}
            load={this.load}
            profilePic={profilePic}
            configureButton={configureButton}
            teammates={teammates}
            draftComment={draftComment}
            onUpArrow={this.editMyLastComment}
            anonymousClient={anonymousClientObject || null}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TopicComments);
