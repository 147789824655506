// @ts-nocheck
import { Grid } from "@mui/material";
import React from "react";
import ReactLoading from "react-loading";
import colors from "../Themes/Colors";

function Loading({ marginTop }: any) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item style={{ marginTop: marginTop ? marginTop : "20%" }}>
        <ReactLoading
          type="bubbles"
          color={colors.primary}
          height={50}
          width={50}
        />
      </Grid>
    </Grid>
  );
}

export default Loading;
