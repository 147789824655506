// @ts-nocheck
import Colors from "../../Themes/Colors";
import { blue, grey } from "@mui/material/colors";
import { space } from "../../Config/theme";

const useStyles = {
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  logoText: {
    marginTop: 70,
    marginBottom: 30,
    height: 60
  },
  input: {
    color: "black",
    borderColor: grey[400]
  },
  header: {
    color: "black"
  },
  error: {
    color: "red"
  },
  iconButton: {
    height: 16,
    width: 16
  },
  loginButton: {
    color: grey[900],
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: grey[100]
    },
    marginBottom: 10
  },
  form: {
    margin: "20px",
    flex: "1 1 auto",
    width: "60%"
  },
  submit: {
    marginTop: "25px",
    marginBottom: "25px"
  },
  margin10: {
    marginTop: 10,
    marginBottom: 10
  },
  centerContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  container: {
    backgroundColor: "white",
    height: "100vh"
  },
  innerContainer: {
    height: "100%"
  },
  bottomLink: {
    cursor: "pointer",
    textAlign: "center",
    marginTop: "10px",
    color: blue[700],
    "&:hover": {
      color: blue[900]
    }
  },
  redirectingLabel: {
    marginTop: 40,
    textAlign: "center",
    color: Colors.white,
    fontSize: 20
  },
  relativePosition: {
    position: "relative"
  },
  loadingStyle: {
    color: Colors.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  marginLeft20: {
    marginLeft: 20
  },
  emailLink: {
    marginLeft: space.XXS
  }
};

export default useStyles;
