// @ts-nocheck
import { withStyles } from "@mui/styles";
import React, { useEffect } from "react";
import styles from "./styles/StyledQuillInputStyle";
import ReactQuill from "react-quill";
import "../quill.bubble.comment.css";

const StyledQuillInput = React.forwardRef((props, ref) => {
  let {
    classes,

    value,

    saveInputFunction,

    placeholder,

    modules,

    onKeyUp,

    outerDivStyle
  } = props;

  let [editorRef, setEditorRef] = React.useState(null);

  useEffect(() => {
    editorRef
      ?.getEditor?.()
      ?.container?.addEventListener?.("click", (evt: any) => {
        let node = evt.target;

        if (!node) {
          return;
        }

        // when the node is wrapped inside anchor tag, i.e., parent or grand parent or great grand parent can be an anchor
        // traverse upto 5 elements (based on testing inputs) to find the anchor and show anchor tooltip
        if (node.tagName !== "A") {
          let parentTraversed = 0;
          while (node?.tagName !== "A" || parentTraversed !== 5) {
            node = node?.parentNode;
            if (!node || node?.tagName === "A") {
              break;
            }
            parentTraversed++;
          }
        }

        if (node?.tagName !== "A" || !node?.getAttribute("href")) {
          return;
        }

        window.open(node.getAttribute("href"), "_blank");
      });
  }, [editorRef]);

  return (
    <div className={classes.quillInput} style={outerDivStyle}>
      <ReactQuill
        ref={(el) => {
          setEditorRef(el);

          ref(el);
        }}
        value={value}
        theme="bubble"
        onChange={saveInputFunction}
        style={{
          padding: 0,
          width: "100%",
          minHeight: "inherit"
        }}
        placeholder={placeholder}
        modules={modules}
        onKeyUp={onKeyUp}
      />
    </div>
  );
});

export default withStyles(styles)(StyledQuillInput);
