// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./quill.bubble.css";
import "./quillImageUploader.css";
import { PostHogProvider } from "posthog-js/react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/app";
import "@firebase/messaging";
import config from "./Config/firebaseKeys";
const replaceAll = require("string.prototype.replaceall");

replaceAll.shim();

firebase.initializeApp(config);

ReactDOM.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_POSTHOG_KEY}
    options={{
      api_host: process.env.REACT_APP_POSTHOG_HOST
    }}
  >
    <App />
  </PostHogProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister({});
