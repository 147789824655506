// @ts-nocheck
import { Tooltip, IconButton, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import styles from "./styles/ExternalSitesListStyle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { blogDestinations } from "../Utils/Types";
import { designColors } from "../Themes/Colors";

function ExternalSitesList({ classes, blogs, onEdit, onDelete }: any) {
  return (
    <div className={classes.externalSitesListContainer}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Destination</TableCell>

              <TableCell>URL of blog's home page</TableCell>

              <TableCell>Path to a blog post</TableCell>

              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {blogs.map((blog: any, index: any) => {
              return (
                <TableRow key={index} width="100%">
                  <TableCell style={{ width: "26%" }}>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 500
                      }}
                    >
                      {blog.name || ""}
                    </div>

                    <div style={{ textTransform: "capitalize" }}>
                      <Typography
                        variant="bodys"
                        style={{
                          color: designColors.grayScale[60],
                          fontSize: 12
                        }}
                      >
                        {blog?.destinationLabel || blog?.destination}
                      </Typography>
                    </div>
                  </TableCell>

                  <TableCell style={{ width: "32%" }}>
                    {blog.blogHomePath}
                  </TableCell>

                  <TableCell style={{ width: "32%" }}>
                    {blog.blogPostBasePath}
                  </TableCell>

                  <TableCell style={{ width: "10%", display: "flex" }}>
                    <div className={classes.actionButton}>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => {
                            onEdit(blog.id);
                          }}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </div>

                    <div className={classes.actionButton}>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => onDelete(blog._id)}
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default withStyles(styles)(ExternalSitesList);
