// @ts-nocheck
import React from "react";
import { withStyles } from "@mui/styles";
import { Tooltip, Typography } from "@mui/material";

import { withRouter } from "react-router";
import styles from "./styles/ViewFileAttachmentStyle";
import { uploadFileTypes, defaultImageUrl } from "../Utils/Types";

class ViewFileAttachment extends React.Component {
  render() {
    let { files, classes } = this.props;
    return (
      <div className={classes.container}>
        {files.map((file: any, index: any) => {
          let srcUrl = defaultImageUrl.DOCS;
          if (file.type.includes(uploadFileTypes.IMAGE)) {
            srcUrl = file.url;
          } else if (file.type.includes(uploadFileTypes.VIDEO)) {
            srcUrl = defaultImageUrl.VIDEO;
          } else if (file.type.includes(uploadFileTypes.PDF)) {
            srcUrl = defaultImageUrl.PDF;
          } else if (
            file.type.includes(uploadFileTypes.CSV) ||
            file.type.includes(uploadFileTypes.EXCEL)
          ) {
            srcUrl = defaultImageUrl.EXCEL;
          }
          return (
            <Tooltip title={file.name} key={index}>
              <div
                className={classes.fileContainer}
                style={{ marginLeft: index === 0 ? 0 : 10 }}
              >
                <a
                  href={file.url}
                  className={classes.imageLink}
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                >
                  <img
                    width="50"
                    height="50"
                    className={classes.imageStyle}
                    src={srcUrl}
                    alt={file.name}
                  />
                </a>

                <Typography variant="bodys" className={classes.fileName}>
                  {file.name}
                </Typography>
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ViewFileAttachment));
