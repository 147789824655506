// @ts-nocheck
import { grey } from "@mui/material/colors";
import colors, { designColors } from "../../Themes/Colors";

const styles = (theme: any) => ({
  leftSection: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start"
    }
  },

  rightSection: {
    display: "flex",
    alignItems: "center"
  },

  saveLabel: {
    color: designColors.grayScale[60],
    margin: "auto"
  },

  approvalLabel: {
    color: designColors.grayScale[80],
    margin: "auto",
    marginRight: 16,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    }
  },

  popover: {
    boxShadow: "0 2px 7px 0px rgba(0,0,0,.1)",
    padding: 10,
    borderRadius: 10,
    marginTop: 10
  },

  menuItem: {
    display: "flex",
    color: "black",
    width: "100%",
    padding: "10px 15px",
    borderRadius: 5,
    "&:hover": {
      background: designColors.grayScale[0],
      cursor: "pointer"
    }
  },

  menuIcon: {
    height: 18,
    margin: "auto 0px",
    marginRight: 7,
    color: grey[700]
  },

  nextButton: {
    marginLeft: 24,
    width: "max-content"
  },

  verticalLine: {
    width: 2,
    background: designColors.grayScale[5],
    height: 35,
    marginLeft: 25,
    marginRight: 10
  },

  reviewHeaderText: {
    marginRight: 16,
    color: designColors.grayScale[100]
  },

  backButton: {
    marginRight: 32,
    background: "transparent"
  },

  marginLeft10:{
    marginLeft: 10
  }
});
export default styles;
