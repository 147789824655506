// @ts-nocheck
import { grey } from "@mui/material/colors";
import colors from "../../Themes/Colors";
import { space } from "../../Config/theme";

const styles = (theme: any) => ({
  paper: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    boxShadow: "none",
    marginTop: space.L
  },

  reducedPaperMargin: {
    boxShadow: "none"
  },

  headerTitle: {
    width: "100%",
    marginBottom: space.MEDIUM,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  header: {
    width: "100%",
    marginBottom: space.MEDIUM,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  shareToReddit: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: space.SMALL
    }
  },

  shareToRedditButton: {
    position: "absolute",
    right: 5,
    background: colors.primary,
    color: colors.white
  },

  draftDiv: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 10
    }
  },

  redditDraft: {
    position: "absolute"
  },

  redditIcon: {
    color: colors.reddit,
    fontSize: 30
  },

  headerText: {
    fontWeight: "bold",
    color: colors.black,
    marginLeft: 10
  },

  accountSection: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 50
  },

  cardTitle: {
    fontWeight: "900",
    fontSize: 16,
    fontFamily: "Poppins"
  },

  cardSubtitle: {
    color: colors.fontSecondary,
    marginBottom: space.MEDIUM,
    fontSize: 14,
    fontFamily: "Poppins"
  },

  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    marginTop: 0,
    padding: 0,
    width: "100%",
    color: colors.fontPrimary,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontPrimary
    }
  },

  postUrl: {
    backgroundColor: grey[100],
    color: colors.fontSecondary,
    borderRadius: 5,
    padding: 10,
    paddingRight: 3,
    fontFamily: "Inconsolata",
    width: "100%",
    minHeight: 50,
    fontSize: 18
  },

  anotherAccount: {
    padding: "30px 0px"
  },

  avatar: {
    width: 30,
    height: 30
  },

  userName: {
    marginLeft: 10
  },

  selectedAccount: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    height: 20
  },

  connectAccountSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px 20px",
    flexDirection: "column"
  },

  connectAccountText: {
    color: colors.fontSecondary
  },

  redditCloseIcon: {
    right: 20,
    position: "absolute"
  },

  connectAccountImage: {
    width: 250,
    height: 250,
    marginBottom: 50
  },

  charCount: {
    textAlign: "right",
    color: colors.fontSecondary,
    marginTop: 10,
    fontSize: 14
  },

  errorIcon: {
    height: 18,
    width: 20,
    verticalAlign: "middle"
  },

  textAreaOutline: {
    width: "100%",
    resize: "vertical"
  },

  editableLabel: {
    color: colors.fontSecondary,
    fontSize: 14
  },

  editableLabelDiv: {
    padding: "20px 40px"
  }
});

export default styles;
