// @ts-nocheck
import { Component } from "react";

import { matchPath } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { Container } from "@mui/material";
import API from "../Services/Api";
const api = API.create();

const excludedRoutes = [
  "/:id/linkedin/comment/:linkedinPostId/:linkedinUserId",
  "/programmatic-seo"
];

class DeviceCompatibilityBanner extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      isMobile: false,
      isException: false
    };
  }

  componentDidMount() {
    if (
      /**
       * https://v5.reactrouter.com/web/api/matchPath
       */
      matchPath(window.location.pathname, {
        path: excludedRoutes,
        exact: true,
        strict: true
      })
    ) {
      this.setState({
        isException: true
      });
    }

    api.checkIsMobile((res: any) => {
      if (res.status === 200) {
        this.setState({
          isMobile: res.data.isMobile
        });
      }
    });
  }
  render() {
    let { isMobile, isException } = this.state;
    return isMobile && !isException ? (
      <Container>
        <MuiAlert
          elevation={6}
          severity="warning"
          style={{ textAlign: "center" }}
        >
          We're not mobile-friendly yet. Please view in desktop mode.
        </MuiAlert>
      </Container>
    ) : (
      ""
    );
  }
}

export default DeviceCompatibilityBanner;
